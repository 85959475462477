import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { WithRouter } from "react-router";
import { connect } from "react-redux";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Select from 'react-select'
import '../style.css';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import XLSX from "xlsx-color";
import moment from 'moment';
const headerTblStyle={ color: 'black' };
const exportToExcel=(columns, data) => {
    debugger;
    const header=["Sr.No", "CheckListType","CheckListTitle"];
    const headertemp=["Sr.No","Type", "Title"];
    debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else if (col=="CheckListType") {
                obj["Type"]=row[col];
            }
            else if (col=="CheckListTitle") {
                obj["Title"]=row[col];
            }
            else {
                obj[col]=row[col];
            }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols']=[
        { width: 6 },
        { width: 20 },
        { width: 20 },

    ];
    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;



    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "CheckList");
    XLSX.writeFile(wb, "CheckList_"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}
class CheckList extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [], CheckListTitle: "", CheckListID: "",CheckListTypeErrMsg:"",
            CheckListTypeData: [{ label: "Task", value: "Task" },
            { label: "Defect", value: "Defect" },
            ],
            CheckListType: { 'label': '--Select Type--', 'value': '--Select Type--' },
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'center' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'Status', width: '12%', field: 'IsActive', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<BootstrapSwitchButton onlabel='Yes' offlabel='No' onChange={this.onStatusChange(rowData.CheckListID)} checked={rowData.IsActive} size="md" onstyle="success" offstyle="danger" />) } },
                { title: 'Type', width: '10%', field: 'CheckListType', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CheckListType}</p>) } },
                { title: 'Title', width: '70%', field: 'CheckListTitle', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CheckListTitle}</p>) } },

            ]
        }
    }
    allowOnlyCharacters=(event) => {
        const keyCode=event.keyCode||event.which;
        const keyValue=String.fromCharCode(keyCode);
        if (!new RegExp("^[a-zA-Z ]+$").test(keyValue)) event.preventDefault();
        return;
    };
    componentDidMount() {
        this.GetCheckList();
    }
    GetCheckList() {
        var CompanyID="";
        var ClientID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "CheckListID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "IsActive": "",
                "IsDelete": "false"
            });
            var config={
                method: 'POST',
                url: services.API_URL+"CheckList/getCheckList",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                if (response.data.status) {
                    this.setState({ data: response.data.data })
                }
                else {

                }

            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }
    EditCheckList(id) {
        var CompanyID="";
        var ClientID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        var data=JSON.stringify({
            "CheckListID": id,
            "CompanyID": CompanyID,
            "ClientID": ClientID
        });
        var config={
            method: 'POST',
            url: services.API_URL+"CheckList/getCheckList",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            this.setState({
                CheckListID: response.data.data[0].CheckListID,
                CheckListType: { 'label': response.data.data[0].CheckListType, 'value': response.data.data[0].CheckListType },
                CheckListTitle: response.data.data[0].CheckListTitle
            });
        }, error => { })
    }
    CheckAlert(id, checklisttitle) {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                var CompanyID="";
                var ClientID="";
                var UserID="";
                var UserType="";
                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                var data=JSON.stringify({
                    "CheckListID": id,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "CheckListTitle": checklisttitle,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+"CheckList/removeCheckList",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        this.GetCheckList();
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }
    ClearData=(e) => {
        debugger;
        this.setState({ CheckListID: "", CheckListTitle: "", CheckListTypeData: [{ label: "Task", value: "Task" },
        { label: "Defect", value: "Defect" },
        ],
        CheckListType: { 'label': '--Select Type--', 'value': '--Select Type--' } });
        this.form.reset();
    }
    ValidateType=(e) => {
        this.setState({ CheckListTypeErrMsg: '' })
    }
    ValidateCancleType=(e) => {
        this.setState({ CheckListTypeErrMsg: 'required' })
    }
    onStatusChange=(CheckListID) => (Status) => {
        // e.preventDefault();
        debugger;
        var IsStatus=Status.toString();


        var data=JSON.stringify({
            "CheckListID": CheckListID,
            "IsActive": IsStatus
        });
        var config={
            method: 'POST',
            url: services.API_URL+"CheckList/setCheckListIsActiveStatus",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                //this.setState({ data: response.data.data })
                Swal.fire({
                    title: 'Successfully Updated', icon: "success", timer: 1500
                });
            }
            else {
                Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                // this.setState({ data: [] })
            }
            this.GetCheckList();

        }, error => { })
    }
    onTypeChange=(e) => {
        debugger;
        if (e!=null) {
            this.setState({ CheckListType: e }, (e) => { this.ValidateType(); })
        }
        else {
            this.setState({ CheckListType: { 'label': '--Select Type--', 'value': '--Select Type--' } }, () => { this.ValidateCancleType() });
        }
    }
    // ClearData = (e) => { this.form.reset(); e.preventDefault(); }
    handleSubmit=(e) => {
        debugger;
        e.preventDefault();
        this.form.validateFields();

        if (this.form.isValid()) {
            var CompanyID="";
            var ClientID="";
            var UserID="";
            var UserType="";
            if (this.props.allCookies.MainCompanyID!==undefined) {
                CompanyID=this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID!==undefined) {
                ClientID=this.props.allCookies.MainClientID
            }
            if (this.props.allCookies.UserID!==undefined) {
                UserID=this.props.allCookies.UserID
            }
            if (this.props.allCookies.PanelUserType!==undefined) {
                UserType=this.props.allCookies.PanelUserType
            }
            var data="";
            if (this.state.CheckListID!="") {
                data=JSON.stringify({
                    "CheckListID": this.state.CheckListID,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "CheckListType": this.state.CheckListType.value,
                    "CheckListTitle": this.state.CheckListTitle,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
            }
            else {
                data=JSON.stringify({
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "CheckListType": this.state.CheckListType.value,
                    "CheckListTitle": this.state.CheckListTitle,
                    "EntryByUserType": "",
                    "EntryByUserID": ""
                });
            }

            var config={
                method: 'POST',
                url: services.API_URL+"CheckList/setCheckList",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                debugger;
                if (response.data.status) {
                    debugger;
                    if (this.state.CheckListID!="") {
                        // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                        Swal.fire({
                            title: 'Successfully Updated', icon: "success", timer: 1500
                        });
                    }
                    else {
                        Swal.fire({
                            title: 'Successfully Inserted', icon: "success", timer: 1500
                        });
                    }
                    this.ClearData();
                    this.GetCheckList();
                }
                else {
                    Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                }
            }, error => { })
        } else {
            //Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: 'something went wrong', showConfirmButton: false, timer: 3000 });
        }

        var fields="";

    }
    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.data)
    }
    render() {
        const { cookies }=this.props
        debugger;
        var Permission=cookies.cookies.CheckList;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <div>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row">
                        <div className="col-md-5">
                            {
                                this.state.AddStatus==="AddStatus:true"&&
                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header">
                                        <h3 className="card-title">Add CheckList</h3>
                                    </div>
                                    {/* <form onSubmit={this.handleSubmit} ref={(c) => { this.form=c; }}> */}
                                    <FormWithConstraints
                                        ref={form => this.form=form}
                                        onSubmit={this.handleSubmit}
                                        noValidate>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Type <span className="text-danger">*</span></label>
                                                        <Select isClearable={true} options={this.state.CheckListTypeData} value={this.state.CheckListType} onChange={this.onTypeChange} />
                                                        {this.state.CheckListTypeErrMsg&&<span className="text-danger">{this.state.CheckListTypeErrMsg==='required'? 'Please select type':''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Title <span className="text-danger">*</span></label>
                                                        <input name="CheckListTitle" required type="text" onKeyPress={this.allowOnlyCharacters} value={this.state.CheckListTitle} onChange={(e) => this.setState({ CheckListTitle: e.target.value })} className="form-control" placeholder="Enter Title" />
                                                        <FieldFeedbacks for="CheckListTitle">
                                                            <FieldFeedback when="*"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" onSubmit={this.handleSubmit} className="btn btn-primary mr-2">
                                                Submit
                                            </button>
                                            <button type="button" onClick={this.ClearData} className="btn btn-secondary">
                                                Cancel
                                            </button>
                                        </div>
                                    </FormWithConstraints>
                                </div>
                            }
                        </div>
                        <div className="col-md-7">
                            {
                                this.state.ViewStatus==="ViewStatus:true"&&
                                <div className="card card-custom">
                                    {/* <div className="card-header flex-wrap border-0 pt-6 pb-0">
                                    <div className="card-title">
                                        <h3 className="card-label">View Country</h3>
                                    </div>
                                </div> */}
                                    <div className="card-body">
                                        <MaterialTable title="View Check List" columns={this.state.cols} data={this.state.data}
                                            actions={[{
                                                hidden: this.state.UpdateStatus=="UpdateStatus:true"? false:true,
                                                icon: 'edit', tooltip: 'Edit', onClick: (e, r) => this.EditCheckList(r.CheckListID)
                                            },
                                            {
                                                hidden: this.state.DeleteStatus=="DeleteStatus:true"? false:true,
                                                icon: 'delete', tooltip: 'Delete', onClick: (e, r) => this.CheckAlert(r.CheckListID, r.CheckListTitle)
                                            }
                                            ]}
                                            options={{
                                                headerStyle: { color: 'black' }, toolbar: true, search: false, sorting: false, draggable: false,
                                                paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                            }}
                                            components={{

                                                Toolbar: props => (
                                                    <div className="row" style={{ marginBottom: '2%' }}>
                                                        <div className="col-md-8" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h3 className="tabletitle">View CheckList</h3>

                                                        </div>
                                                        <div className="col-md-4" style={{ textAlign: 'right' }}>
                                                            {
                                                                this.state.ViewStatus==="ViewStatus:true"&&
                                                                <>
                                                                    {this.state.ExcelStatus==="ExcelStatus:true"&&
                                                                        <a className="btn btn-outline-success font-weight-bolder ml-4" onClick={this.state.data.length>0? this.ExcelClick:''}>
                                                                            <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                                        </a>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        />

                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { user }=state.auth;
    return { user };
}
export default withCookies(CheckList);