import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { render } from "@testing-library/react";
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

const headerTblStyle={ color: 'black' };
class companyclientselection extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            companies: [], clients: [],
            CompanyID: { 'label': '--Select Company--', 'value': '--Select Company--' },
            ClientID: { 'label': '--Select Client--', 'value': '--Select Client--' },
            CompanyErrMsg: "", ClientErrMsg: ""
        }
    }
    componentDidMount() {
        debugger;
        this.GetCompany();
        window.$(this.Modal).modal('show')
    }
    GetCompany() {

        var data=JSON.stringify({
            "CompanyID": ""
        });
        var config={
            method: 'POST',
            url: services.API_URL+"Company/getCompany",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            this.setState({ companies: response.data.data.map(item => ({ value: item.CompanyID, label: item.CompanyName })) });
        }, error => { })
    }
    onCompanyChange=(e) => {
        if (e!=null) {
            this.setState({ CompanyID: e });
            var data=JSON.stringify({
                "CompanyID": e.value
            });

            var config={
                method: 'POST',
                url: services.API_URL+"Client/getClient",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                if (response.data.status) {
                    this.setState({ ClientID: { 'label': '--Select Client--', 'value': '--Select Client--' } });
                    this.setState({ clients: response.data.data.map(item => ({ value: item.ClientID, label: item.ClientName })) });

                }
                else {
                    this.setState({ clients: [] })
                    this.setState({ ClientID: { 'label': '--Select Client--', 'value': '--Select Client--' } });

                }

            }, error => { })

        }
        else {
            this.setState({ CompanyID: { 'label': '--Select Company--', 'value': '--Select Company--' } });
            this.setState({ clients: [] })
            this.setState({ ClientID: { 'label': '--Select Client--', 'value': '--Select Client--' } });

        }
    }
    onClientChange=(e) => {
        if (e!=null) {
            this.setState({ ClientID: e });
        }
        else {
            this.setState({ ClientID: { 'label': '--Select Client--', 'value': '--Select Client--' } });
        }
    }
    OnhandleSubmit=(e) => {
        debugger;
        e.preventDefault();
        var allValid=true;
        if (this.state.CompanyID.value=="--Select Company--") {
            this.setState({ CompanyErrMsg: 'required' });
            allValid=false;
        }

        if (this.state.ClientID.value=="--Select Client--") {
            this.setState({ ClientErrMsg: 'required' });
            allValid=false;
        }
        if (allValid==true) {
            const { cookies }=this.props;
            cookies.set('MainCompanyID', this.state.CompanyID.value, { path: '/' });
            cookies.set('MainCompanyName', this.state.CompanyID.label, { path: '/' });
            this.setState({ MainCompanyID: { 'label': this.state.CompanyID.label, 'value': this.state.CompanyID.value } });

            cookies.set('MainClientID', this.state.ClientID.value, { path: '/' });
            cookies.set('MainClientName', this.state.ClientID.label, { path: '/' });
            this.setState({ MainClientID: { 'label': this.state.ClientID.label, 'value': this.state.ClientID.value } });

            window.location.href="/vehicledashboard";
        }

    }
    onCancelSubmit=(e) => {
        e.preventDefault();
        this.setState({
            clients: [],
            CompanyID: { 'label': '--Select Company--', 'value': '--Select Company--' },
            ClientID: { 'label': '--Select Client--', 'value': '--Select Client--' },
            CompanyErrMsg: "", ClientErrMsg: ""
        });
        this.GetCompany();
    }
    render() {
        return (
            <div>
                <div className="modal fade" ref={modal => this.Modal=modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <FormWithConstraints
                                ref={form => this.form=form}
                                onSubmit={this.OnhandleSubmit}
                                noValidate>
                                <div className="modal-header">

                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">Company<span className="text-danger">*</span></label>
                                                <Select options={this.state.companies} value={this.state.CompanyID} onChange={this.onCompanyChange} />
                                                {this.state.CompanyErrMsg&&<span className="text-danger">{this.state.CompanyErrMsg==='required'? '*':''}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">Client<span className="text-danger">*</span></label>
                                                <Select options={this.state.clients} value={this.state.ClientID} onChange={this.onClientChange} />
                                                {this.state.ClientErrMsg&&<span className="text-danger">{this.state.ClientErrMsg==='required'? '*':''}</span>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" onSubmit={this.OnhandleSubmit} className="btn btn-primary font-weight-bold">
                                        Submit
                                    </button>
                                    <button type="button" onClick={this.onCancelSubmit} className="btn btn-light-danger font-weight-bold">
                                        Cancel
                                    </button>
                                </div>
                            </FormWithConstraints>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withCookies(companyclientselection);