import React, { Component } from "react";
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import services from "../services/services";
import Swal from "sweetalert2";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import MultiSelect from "@khanacademy/react-multi-select";
import Select, { components } from 'react-select'
import { ReactFlvPlayer } from 'react-flv-player'
import { data } from "jquery";
import XLSX from "xlsx-color";

const headerTblStyle = { color: 'black' };
const exportToExcel = (columns, data) => {
    // debugger;
    const header = ["Sr.No", "VehicleNo", "ZoneName", "DistrictName", "MuncipalityName", "WardName", "Locality", "BookingNo", "BookingDate"];
    const headertemp = ["Sr.No", "Vehicle No", "Zone Name", "DistrictName", "Muncipality Name", "Ward Name", "Location", "Task No", "Task Date"];
    // debugger
    const compatibleData = data.map((row, i) => {
        const obj = {};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col === "Sr.No") {
                obj[col] = i + 1;
            }
            else if (col == "VehicleNo") {
                obj["Vehicle No"] = row[col];
            }
            else if (col == "ZoneName") {
                obj["Zone Name"] = row[col];
            }
            else if (col == "DistrictName") {
                obj["District Name"] = row[col];
            }
            else if (col == "MuncipalityName") {
                obj["Muncipality Name"] = row[col];
            }
            else if (col == "WardName") {
                obj["Ward Name"] = row[col];
            }
            else if (col == "Locality") {
                obj["Location"] = row[col];
            }
            else if (col == "BookingNo") {
                obj["Task No"] = row[col];
            }
            else if (col == "BookingDate") {
                obj["Task Date"] = row[col];
            }
            else {
                obj[col] = row[col];
            }
        });
        return obj;
    });

    const ws1 = XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb = XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s = {
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols'] = [
        { width: 6 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
    ];
    let cell = {
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s = cell;
    ws1['B1'].s = cell;
    ws1['C1'].s = cell;
    ws1['D1'].s = cell;
    ws1['E1'].s = cell;
    ws1['F1'].s = cell;
    ws1['G1'].s = cell;
    ws1['H1'].s = cell;
    ws1['I1'].s = cell;



    var RowCont = {
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins = i + 2;
        ws1['A' + ins].s = RowCont;
        ws1['B' + ins].s = RowCont;
        ws1['C' + ins].s = RowCont;
        ws1['D' + ins].s = RowCont;
        ws1['E' + ins].s = RowCont;
        ws1['F' + ins].s = RowCont;
        ws1['G' + ins].s = RowCont;
        ws1['H' + ins].s = RowCont;
        ws1['I' + ins].s = RowCont;


        return true;
    });
    // XLSX.utils.book_append_sheet(wb, ws1, "VehicleDashboardList");
    XLSX.writeFile(wb, "VehicleDashboardList_" + moment().format('DD-MM-YYYY_HH:mm') + ".xlsx");
}
class vehicledashboard extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [], zones: [], districts: [], municipalities: [], wards: [],
            ZoneID: [], DistrictID: [], MunicipalityID: [], WardID: [],
            BookingNo: "",
            status: [{ 'label': 'New', 'value': 'New' }, { 'label': 'Assigned', 'value': 'Assigned' },
            { 'label': 'Started', 'value': 'Started' }, { 'label': 'Finished', 'value': 'Finished' },
            { 'label': 'Completed', 'value': 'Completed' }, { 'label': 'Approved', 'value': 'Approved' },
            { 'label': 'Referred Back', 'value': 'Referred Back' }],
            StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' },
            StartDate: new Date(), EndDate: new Date(),
            faicon: "fa fa-plus", isFilterVisible: false, iconFilter: "fa fa-plus",
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.tableData.id + 1}</p>) } },
                { title: 'Vehicle No', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left' }}>{rowData.VehicleNo}</p>) } },
                { title: 'Zone Name', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.ZoneName}</p>) } },
                { title: 'District Name', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.DistrictName}</p>) } },
                { title: 'Municipality Name', width: '15%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.MuncipalityName}</p>) } },
                { title: 'Ward Name', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.WardName}</p>) } },
                { title: 'Location', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Locality}</p>) } },
                { title: 'Task No', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingNo}</p>) } },
                { title: 'Task Date', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingDate}</p>) } },
                { title: 'Map', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<img src="Images/map.png" height={30}></img>) } },
            ],
        }
    }
    componentDidMount() {
        this.GetZone();
        this.GetDistrict();
        this.GetMunicipality();
        this.GetWard();
        this.GetVehicleDashboard();
    }

    GetVehicleDashboard() {
        // debugger;
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var ZoneID = "";
        var DistrictID = "";
        var MunicipalityID = "";
        var WardID = "";
        // var Status="";
        var StartDate = "";
        var EndDate = "";
        var ckZoneID = "";
        var ckMunicipalityID = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.ZoneIDs !== undefined) {
            ckZoneID = this.props.allCookies.ZoneIDs
        }
        if (this.props.allCookies.MunicipalityID !== undefined) {
            ckMunicipalityID = this.props.allCookies.MunicipalityID
        }

        if (this.state.ZoneID != null) {
            var tempZoneID = this.state.ZoneID.map(

                item => (
                    ZoneID += item + ","
                )
            );
            ZoneID = ZoneID.slice(0, -1)
        }
        if (this.state.DistrictID != null) {
            var tempDistrictID = this.state.DistrictID.map(

                item => (
                    DistrictID += item + ","
                )
            );
            DistrictID = DistrictID.slice(0, -1)
        }
        if (this.state.MunicipalityID != null) {
            var tempMunicipalityID = this.state.MunicipalityID.map(

                item => (
                    MunicipalityID += item + ","
                )
            );
            MunicipalityID = MunicipalityID.slice(0, -1)
        }
        if (this.state.WardID != null) {
            var tempWardID = this.state.WardID.map(

                item => (
                    WardID += item + ","
                )
            );
            WardID = WardID.slice(0, -1)
        }

        // if (this.state.StatusID.value!="--Select Status--") {
        //     Status=this.state.StatusID.value
        // }

        var data = "";
        data = JSON.stringify({
            CompanyID: CompanyID,
            ClientID: ClientID,
            ZoneID: ZoneID ? ZoneID : ckZoneID ? ckZoneID : '',
            DistrictID: DistrictID,
            MuncipalityID: MunicipalityID ? MunicipalityID : ckMunicipalityID ? ckMunicipalityID : '',
            WardID: WardID,
            // BookingStatus: Status,
            StartDate: moment(this.state.StartDate, "DD-MM-YYYY"),
            EndDate: moment(this.state.EndDate, "DD-MM-YYYY"),
        });


        var config = {
            method: 'POST',
            url: services.API_URL + "Report/getVehicleDashboardReport",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                this.setState({ data: response.data.data })
            }
            else {
                this.setState({ data: [] })
            }

        }, error => { })
    }

    GetZone() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var ZoneID = "";
        var tempPanelUserType = "";
        var tempUrl = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.ZoneIDs !== undefined) {
            ZoneID = this.props.allCookies.ZoneIDs
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            tempPanelUserType = this.props.allCookies.PanelUserType
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }

        var data = "";
        // data = JSON.stringify({
        //     ZoneID: "",
        //     CompanyID: CompanyID,
        //     ClientID: ClientID
        // });

        if (tempPanelUserType == "Admin") {
            data = JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "Zone/getZoneDropDownData";
        } else {
            data = JSON.stringify({
                UserID: UserID,
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "User/getUserZoneDetail";
        }

        var config = {
            method: 'POST',
            url: services.API_URL + tempUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //// debugger;

            if (response.data.status) {
                this.setState({ zones: response.data.data.map(item => ({ value: item.ZoneID, label: item.ZoneName })) });
                this.setState({ ZoneID: [] })

            }
            else {
                this.setState({ zones: [] })
            }
        }, error => { })
    }

    GetDistrict() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var ZoneID = "";
        var tempPanelUserType = "";
        var tempUrl = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.ZoneIDs !== undefined) {
            ZoneID = this.props.allCookies.ZoneIDs
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            tempPanelUserType = this.props.allCookies.PanelUserType
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }

        var data = "";
        // data = JSON.stringify({
        //     "DistrictID": "",
        //     "CompanyID": CompanyID,
        //     "ClientID": ClientID,
        //     "ZoneID": ""
        // });

        if (tempPanelUserType == "Admin") {
            data = JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "District/getDistrictDropDownData";
        } else {
            data = JSON.stringify({
                UserID: UserID,
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "User/getUserDistrictDetail";
        }

        var config = {
            method: 'POST',
            url: services.API_URL + tempUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //// debugger;

            if (response.data.status) {
                this.setState({ districts: response.data.data.map(item => ({ value: item.DistrictID, label: item.DistrictName })) });
                this.setState({ DistrictID: [] })

            }
            else {
                this.setState({ districts: [] })
            }
        }, error => { })
    }

    GetMunicipality() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var ZoneID = "";
        var tempPanelUserType = "";
        var tempUrl = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.ZoneIDs !== undefined) {
            ZoneID = this.props.allCookies.ZoneIDs
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            tempPanelUserType = this.props.allCookies.PanelUserType
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }

        var data = "";
        // data = JSON.stringify({
        //     "MuncipalityID": "",
        //     "CompanyID": CompanyID,
        //     "ClientID": ClientID,
        //     "DistrictID": "",
        //     "ZoneID": ""
        // });

        if (tempPanelUserType == "Admin") {
            data = JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "Muncipality/getMuncipalityDropDownData";
        } else {
            data = JSON.stringify({
                UserID: UserID,
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "User/getUserMuncipalityDetail";
        }

        var config = {
            method: 'POST',
            url: services.API_URL + tempUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //// debugger;

            if (response.data.status) {
                this.setState({ municipalities: response.data.data.map(item => ({ value: item.MuncipalityID, label: item.MuncipalityName })) });
                this.setState({ MuncipalityID: [] })

            }
            else {
                this.setState({ municipalities: [] })
            }
        }, error => { })
    }

    GetWard() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var ckMunicipalityID = "";
        var tempPanelUserType = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.MunicipalityID !== undefined) {
            ckMunicipalityID = this.props.allCookies.MunicipalityID
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            tempPanelUserType = this.props.allCookies.PanelUserType
        }

        var data = "";
        // data = JSON.stringify({
        //     "WardID": "",
        //     "CompanyID": CompanyID,
        //     "ClientID": ClientID,
        //     "MuncipalityID": ""
        // });

        if (tempPanelUserType == "Admin") {
            data = JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID
            });
        } else {
            data = JSON.stringify({
                MuncipalityID: ckMunicipalityID,
                CompanyID: CompanyID,
                ClientID: ClientID
            });
        }

        var config = {
            method: 'POST',
            url: services.API_URL + "Ward/getWard",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //// debugger;

            if (response.data.status) {
                this.setState({ wards: response.data.data.map(item => ({ value: item.WardID, label: item.WardName })) });
                this.setState({ WardID: [] })

            }
            else {
                this.setState({ wards: [] })
            }
        }, error => { })
    }
    // Event Change
    onStartDateChange = (e) => {
        this.setState({ StartDate: e });
        this.setState({ EndDate: e });
    }
    onZoneChange = (value) => {
        //// debugger;
        this.setState({ ZoneID: value });

    }
    onDistrictChange = (value) => {
        //// debugger;
        this.setState({ DistrictID: value });

    }
    onMunicipalityChange = (value) => {
        //// debugger;
        this.setState({ MunicipalityID: value });
    }
    onWardChange = (value) => {
        //// debugger;
        this.setState({ WardID: value });
    }
    // onStatusChange=(e) => {
    //     if (e!==null) {
    //         this.setState({ StatusID: e });
    //     }
    //     else {
    //         this.setState({ StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' } });
    //     }
    // }
    // Click Events
    OnFilterClick = (e) => {
        e.preventDefault();
        if (this.state.isFilterVisible === false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }
    onSearchClick = (e) => {
        e.preventDefault();
        this.GetVehicleDashboard();
    }
    OnSearchCancelClick = (e) => {
        this.setState({
            data: [], zones: [], districts: [], municipalities: [], wards: [],
            ZoneID: [], DistrictID: [], MunicipalityID: [], WardID: [],
            BookingNo: "",
            status: [{ 'label': 'New', 'value': 'New' }, { 'label': 'Assigned', 'value': 'Assigned' },
            { 'label': 'Started', 'value': 'Started' }, { 'label': 'Finished', 'value': 'Finished' },
            { 'label': 'Completed', 'value': 'Completed' }, { 'label': 'Approved', 'value': 'Approved' },
            { 'label': 'Referred Back', 'value': 'Referred Back' }],
            StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' },
            StartDate: new Date(), EndDate: new Date(),

        }, () => { this.GetVehicleDashboard(); });
        this.GetZone();
        this.GetDistrict();
        this.GetMunicipality();
        this.GetWard();

    }
    ShowCamera1 = () => e => {
        e.preventDefault();
        window.$(this.Camera1Modal).modal('show');
    }
    ExcelClick = () => {
        exportToExcel(this.state.cols, this.state.data)
    }
    render() {
        const { cookies } = this.props
        // debugger;
        // var Permission=cookies.cookies.GUDMDashboard;
        var Permission = cookies.cookies.VehicleDashboard;
        var TotalPermission = "";
        if (Permission !== undefined) {
            TotalPermission = Permission.split(',');
            if (this.state.AddStatus == "") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row" style={{ marginBottom: '1%' }}>
                        {(this.props.allCookies.Dashboard !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                <NavLink to={"/dashboard"} className="btn btn-outline-primary font-weight-bolds">Statistics
                                </NavLink>
                            </div>) : ''}
                        {(this.props.allCookies.GudmDashboard !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                {/* <NavLink to={"/gudmdashboard"} className="btn btn-outline-primary font-weight-bolds">Gudm
                                </NavLink> */}
                                <NavLink to={"/gudmdashboard"} className="btn btn-outline-primary font-weight-bolds">Tasks
                                </NavLink>
                            </div>) : ''}
                        {(this.props.allCookies.GudmSummary !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                {/* <NavLink to={"/gudmsummary"} className="btn btn-outline-primary font-weight-bolds">Gudm Summary
                                </NavLink> */}
                                <NavLink to={"/gudmsummary"} className="btn btn-outline-primary font-weight-bolds">Tasks Summary
                                </NavLink>
                            </div>) : ''}
                        {(this.props.allCookies.WorkInProgressDashboard !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                <NavLink to={"/vehicledashboard"} className="btn btn-primary font-weight-bolds">Work In Progress
                                </NavLink>
                            </div>) : ''}
                        <div className="col-md-3" style={{ maxWidth: 'fit-content' }}>
                            {/* <button type="button" className="btn btn-outline-primary font-weight-bolds" onClick={this.ShowCamera1()}>
                                Live Streaming
                            </button> */}
                            {/* <a className="btn btn-outline-primary font-weight-bolds" target="_blank" href="http://mwhdia.in:3073/">
                                Live Streaming
                            </a> */}
                        </div>
                        {/* <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                            <NavLink to={"/livegps"} className="btn btn-outline-primary font-weight-bolds">Live GPS Tracking
                            </NavLink>
                        </div> */}
                    </div>
                    {this.state.isFilterVisible &&
                        <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Start Date </label>
                                    <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.StartDate} onChange={this.onStartDateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>End Date </label>
                                    <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.EndDate} minDate={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.EndDate} onChange={(e) => {
                                        this.setState({ EndDate: e });
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Zone </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.zones} selected={this.state.ZoneID} checked={false}
                                        onSelectedChanged={this.onZoneChange}
                                        overrideStrings={{
                                            selectSomeItems: "Zones", allItemsAreSelected: "All Zones are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>District </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.districts} selected={this.state.DistrictID} checked={false}
                                        onSelectedChanged={this.onDistrictChange}
                                        overrideStrings={{
                                            selectSomeItems: "Districts", allItemsAreSelected: "All Districts are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Municipality </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.municipalities} selected={this.state.MunicipalityID} checked={false}
                                        onSelectedChanged={this.onMunicipalityChange}
                                        overrideStrings={{
                                            selectSomeItems: "Municipalities", allItemsAreSelected: "All Municipalities are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Ward </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.wards} selected={this.state.WardID} checked={false}
                                        onSelectedChanged={this.onWardChange}
                                        overrideStrings={{
                                            selectSomeItems: "Wards", allItemsAreSelected: "All Wards are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            {/* <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Status </label>
                                    <Select isClearable={true} options={this.state.status} value={this.state.StatusID} onChange={this.onStatusChange} />
                                </div>
                            </div> */}
                            <div className="col-md-3">
                                <button type="button" onClick={this.onSearchClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                <button type="button" onClick={this.OnSearchCancelClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-body">
                                    <MaterialTable columns={this.state.cols} data={this.state.data}

                                        options={{
                                            headerStyle: { color: 'black' }, toolbar: true, search: false, draggable: false,
                                            paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                            detailPanelType: "single",
                                            // rowStyle: rowData => {
                                            //     if (rowData.IsRefferBack=="True") {
                                            //         return { backgroundColor: '#F64E60' };
                                            //     }
                                            //     return {};
                                            // }
                                        }}
                                        components={{

                                            Toolbar: props => (
                                                <div className="row" style={{ marginBottom: '2%' }}>
                                                    <div className="col-md-8" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h3 className="tabletitle">Work In Progress</h3>

                                                    </div>
                                                    <div className="col-md-4" style={{ textAlign: 'right' }}>
                                                        <a className="btn btn-outline-dark font-weight-bolder" onClick={this.OnFilterClick}>
                                                            <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                        </a>
                                                        {this.state.ExcelStatus === "ExcelStatus:true" &&
                                                            <a className="btn btn-outline-success font-weight-bolder ml-4" onClick={this.state.data.length > 0 ? this.ExcelClick : ''}>
                                                                <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                            </a>
                                                        }

                                                    </div>
                                                </div>
                                            )
                                        }}


                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.Camera1Modal = modal} id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog mapdialog" role="document">
                        <div className="modal-content" style={{ height: '700px' }}>
                            <div className="modal-body" style={{ paddingLeft: '0', paddingTop: '0', paddingRight: '0' }}>
                                <div className="row" style={{ display: 'block', width: '100%', marginLeft: '0', marginRight: '0' }}>
                                    <ReactFlvPlayer
                                        url="http://14.99.68.5:12060/live.flv?devid=00BD001497&chl=2&svrid=127.0.0.1&svrport=17891&st=1&audio=1"
                                        height="100%"
                                        width="100%"
                                        isMuted={true}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default withCookies(vehicledashboard)