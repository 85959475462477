import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { render } from "@testing-library/react";
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

const headerTblStyle = { color: 'black' };
class usercompanyclientselection extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            companies: [], clients: [],
            CompanyID: { 'label': '--Select Company--', 'value': '--Select Company--' },
            ClientID: { 'label': '--Select Client--', 'value': '--Select Client--' },
            CompanyErrMsg: "", ClientErrMsg: ""
        }
    }
    componentDidMount() {
        debugger;
        this.GetCompany();
        window.$(this.Modal).modal('show')
    }
    GetCompany() {
        debugger;
        var MobileNo = "";
        var UserName = "";
        if (this.props.allCookies.MobileNo !== undefined) {
            MobileNo = this.props.allCookies.MobileNo;
        }
        if (this.props.allCookies.UserName !== undefined) {
            UserName = this.props.allCookies.UserName;
        }
        if (UserName == "null") {
            UserName = ""
        }
        if (MobileNo == "null") {
            MobileNo = ""
        }
        var data = JSON.stringify({
            "CompanyID": "",
            "ClientID": "",
            "UserName": UserName,
            "MobileNo": MobileNo
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "User/getUserCompanyData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            this.setState({ companies: response.data.data.map(item => ({ value: item.CompanyID, label: item.CompanyName })) });

        }, error => { })
    }
    GetUserData() {
        debugger;
        var CompanyID = "";
        var ClientID = "";
        var UserName = "";
        var MobileNo = "";
        var UserID = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserName !== undefined) {
            UserName = this.props.allCookies.UserName;
        }
        if (this.props.allCookies.MobileNo !== undefined) {
            MobileNo = this.props.allCookies.MobileNo;
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID;
        }
        var data = JSON.stringify({
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "UserName": UserName,
            "MobileNo": MobileNo,
            "UserID": UserID
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "User/getUserCompanyClientData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                const { cookies } = this.props;
                cookies.set('UserImage', response.data.data[0].UserImage, { path: '/' });
                cookies.set('UserName', response.data.data[0].UserName, { path: '/' });
            }
        }, error => { })
    }
    onCompanyChange = (e) => {
        debugger;
        if (e != null) {
            var MobileNo = "";
            var UserName = "";
            if (this.props.allCookies.MobileNo !== undefined) {
                MobileNo = this.props.allCookies.MobileNo;
            }
            if (this.props.allCookies.UserName !== undefined) {
                UserName = this.props.allCookies.UserName;
            }
            if (UserName == "null") {
                UserName = ""
            }
            if (MobileNo == "null") {
                MobileNo = ""
            }
            this.setState({ CompanyID: e });
            var data = JSON.stringify({
                "UserName": UserName,
                "CompanyID": e.value,
                "ClientID": "",
                "MobileNo": MobileNo
            });

            var config = {
                method: 'POST',
                url: services.API_URL + "User/getUserClientData",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                if (response.data.status) {
                    this.setState({ ClientID: { 'label': '--Select Client--', 'value': '--Select Client--' } });
                    this.setState({ clients: response.data.data.map(item => ({ value: item.ClientID, label: item.ClientName })) });

                }
                else {
                    this.setState({ clients: [] })
                    this.setState({ ClientID: { 'label': '--Select Client--', 'value': '--Select Client--' } });

                }

            }, error => { })

        }
        else {
            this.setState({ CompanyID: { 'label': '--Select Company--', 'value': '--Select Company--' } });
            this.setState({ clients: [] })
            this.setState({ ClientID: { 'label': '--Select Client--', 'value': '--Select Client--' } });

        }
    }
    onClientChange = (e) => {
        if (e != null) {
            this.setState({ ClientID: e });
        }
        else {
            this.setState({ ClientID: { 'label': '--Select Client--', 'value': '--Select Client--' } });
        }
    }
    OnhandleSubmit = async (e) => {
        debugger;
        e.preventDefault();
        var allValid = true;
        if (this.state.CompanyID.value == "--Select Company--") {
            this.setState({ CompanyErrMsg: 'required' });
            allValid = false;
        }

        if (this.state.ClientID.value == "--Select Client--") {
            this.setState({ ClientErrMsg: 'required' });
            allValid = false;
        }
        if (allValid == true) {
            const { cookies } = this.props;
            cookies.set('MainCompanyID', this.state.CompanyID.value, { path: '/' });
            cookies.set('MainCompanyName', this.state.CompanyID.label, { path: '/' });
            this.setState({ MainCompanyID: { 'label': this.state.CompanyID.label, 'value': this.state.CompanyID.value } });

            cookies.set('MainClientID', this.state.ClientID.value, { path: '/' });
            cookies.set('MainClientName', this.state.ClientID.label, { path: '/' });
            this.setState({ MainClientID: { 'label': this.state.ClientID.label, 'value': this.state.ClientID.value } });

            var CompanyID = "";
            var ClientID = "";
            var UserName = "";
            var MobileNo = "";

            var CompanyID = this.state.CompanyID.value;
            var ClientID = this.state.ClientID.value;
            // if (this.props.allCookies.MainCompanyID!==undefined) {
            //     CompanyID=cmp;
            // }
            // if (this.props.allCookies.MainClientID!==undefined) {
            //     ClientID=clnt
            // }
            if (this.props.allCookies.UserName !== undefined) {
                UserName = this.props.allCookies.UserName;
            }
            if (this.props.allCookies.MobileNo !== undefined) {
                MobileNo = this.props.allCookies.MobileNo;
            }
            if (UserName == "null") {
                UserName = ""
            }
            if (MobileNo == "null") {
                MobileNo = ""
            }
            var data = JSON.stringify({
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "UserName": UserName,
                "MobileNo": MobileNo
            });

            const response1 = await fetch(`${services.API_URL}User/getUserCompanyClientData`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: data
            });
            const response = await response1.json();
            if (response.status) {
                const { cookies } = this.props;
                cookies.set('UserName', response.data[0].UserName, { path: '/' });
                cookies.set('FullName', response.data[0].FullName, { path: '/' });
                cookies.set('UserImage', response.data[0].UserImage, { path: '/' });
                cookies.set('UserID', response.data[0].UserID, { path: '/' });

                if (response.data[0].UserType != "Admin") {
                    //#region Bind Muncipality and Zone Data Company & Client Wise
                    var tempUserID = response?.data?.[0].UserID || '';

                    var tempdata = {
                        "CompanyID": CompanyID,
                        "ClientID": ClientID,
                        "UserID": tempUserID
                    };

                    const res = await fetch(`${services.API_URL}User/getUserCompanyClientData`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ...tempdata
                        })
                    })
                    const tempresponse = await res.json()

                    if (tempresponse.status) {
                        var MunicipalityID = "";
                        for (let i = 0; i < tempresponse.data[0].UserMuncipalityDetail.length; i++) {
                            //  const element = array[index];
                            MunicipalityID += tempresponse.data[0].UserMuncipalityDetail[i].MuncipalityID + ",";
                        }
                        MunicipalityID = MunicipalityID.substring(0, MunicipalityID.length - 1);
                        cookies.set('MunicipalityID', MunicipalityID, { path: '/' });

                        var tempZoneDetail = "";
                        for (let i = 0; i < tempresponse.data[0].UserZoneDetail.length; i++) {
                            //  const element = array[index];
                            tempZoneDetail += tempresponse.data[0].UserZoneDetail[i].ZoneID + ",";
                        }
                        tempZoneDetail = tempZoneDetail.substring(0, tempZoneDetail.length - 1);
                        cookies.set('ZoneIDs', tempZoneDetail, { path: '/' });
                    }
                    //#endregion Bind Muncipality and Zone Data Company & Client Wise
                }

                if (response.data[0].UserDefaultPageDetail != null) {
                    cookies.set('RedirectPage', response.data[0].UserDefaultPageDetail[0].FunctionalityPage, { path: '/' });
                }
                if (response.data[0].UserPermissionDetail != null) {
                    for (let i = 0; i < response.data[0].UserPermissionDetail.length; i++) {
                        if (response.data[0].UserPermissionDetail[i].FunctionalityPage === "ViewAssignTask" || response.data[0].UserPermissionDetail[i].FunctionalityPage === "ViewStartTask" || response.data[0].UserPermissionDetail[i].FunctionalityPage === "ViewFinishTask" || response.data[0].UserPermissionDetail[i].FunctionalityPage === "ViewCompleteTask" || response.data[0].UserPermissionDetail[i].FunctionalityPage === "ViewApprovalTask" || response.data[0].UserPermissionDetail[i].FunctionalityPage === "ViewTask") {
                            if (response.data[0].UserType == "Sub Admin" && response.data[0].UserPermissionDetail[i].FunctionalityPage == "ViewTask") {
                                cookies.set("ViewSubAdminTask",
                                    "AddStatus:" + response.data[0].UserPermissionDetail[i].AddStatus +
                                    ",UpdateStatus:" + response.data[0].UserPermissionDetail[i].UpdateStatus +
                                    ",DeleteStatus:" + response.data[0].UserPermissionDetail[i].DeleteStatus +
                                    ",ViewStatus:" + response.data[0].UserPermissionDetail[i].ViewStatus +
                                    ",PrintStatus:" + response.data[0].UserPermissionDetail[i].PrintStatus +
                                    ",ExcelStatus:" + response.data[0].UserPermissionDetail[i].ExcelStatus, { path: '/' });

                                cookies.set(response.data[0].UserPermissionDetail[i].FunctionalityPage,
                                    "AddStatus:" + response.data[0].UserPermissionDetail[i].AddStatus +
                                    ",UpdateStatus:" + response.data[0].UserPermissionDetail[i].UpdateStatus +
                                    ",DeleteStatus:" + response.data[0].UserPermissionDetail[i].DeleteStatus +
                                    ",ViewStatus:" + response.data[0].UserPermissionDetail[i].ViewStatus +
                                    ",PrintStatus:" + response.data[0].UserPermissionDetail[i].PrintStatus +
                                    ",ExcelStatus:" + response.data[0].UserPermissionDetail[i].ExcelStatus, { path: '/' });
                            }
                            else {
                                cookies.set("ViewTask",
                                    "AddStatus:" + response.data[0].UserPermissionDetail[i].AddStatus +
                                    ",UpdateStatus:" + response.data[0].UserPermissionDetail[i].UpdateStatus +
                                    ",DeleteStatus:" + response.data[0].UserPermissionDetail[i].DeleteStatus +
                                    ",ViewStatus:" + response.data[0].UserPermissionDetail[i].ViewStatus +
                                    ",PrintStatus:" + response.data[0].UserPermissionDetail[i].PrintStatus +
                                    ",ExcelStatus:" + response.data[0].UserPermissionDetail[i].ExcelStatus, { path: '/' });

                                cookies.set(response.data[0].UserPermissionDetail[i].FunctionalityPage,
                                    "AddStatus:" + response.data[0].UserPermissionDetail[i].AddStatus +
                                    ",UpdateStatus:" + response.data[0].UserPermissionDetail[i].UpdateStatus +
                                    ",DeleteStatus:" + response.data[0].UserPermissionDetail[i].DeleteStatus +
                                    ",ViewStatus:" + response.data[0].UserPermissionDetail[i].ViewStatus +
                                    ",PrintStatus:" + response.data[0].UserPermissionDetail[i].PrintStatus +
                                    ",ExcelStatus:" + response.data[0].UserPermissionDetail[i].ExcelStatus, { path: '/' });
                            }

                        }
                        cookies.set(response.data[0].UserPermissionDetail[i].FunctionalityPage,
                            "AddStatus:" + response.data[0].UserPermissionDetail[i].AddStatus +
                            ",UpdateStatus:" + response.data[0].UserPermissionDetail[i].UpdateStatus +
                            ",DeleteStatus:" + response.data[0].UserPermissionDetail[i].DeleteStatus +
                            ",ViewStatus:" + response.data[0].UserPermissionDetail[i].ViewStatus +
                            ",PrintStatus:" + response.data[0].UserPermissionDetail[i].PrintStatus +
                            ",ExcelStatus:" + response.data[0].UserPermissionDetail[i].ExcelStatus, { path: '/' });
                    }
                }

                if (response.data[0].UserDefaultPageDetail !== null) {
                    if (response.data[0].UserDefaultPageDetail[0].FunctionalityPage === "ViewAssignTask" || response.data[0].UserDefaultPageDetail[0].FunctionalityPage === "ViewStartTask" || response.data[0].UserDefaultPageDetail[0].FunctionalityPage === "ViewFinishTask" || response.data[0].UserDefaultPageDetail[0].FunctionalityPage === "ViewCompleteTask" || response.data[0].UserDefaultPageDetail[0].FunctionalityPage === "ViewApprovalTask" || response.data[0].UserDefaultPageDetail[0].FunctionalityPage === "ViewTask") {
                        if (response.data[0].UserType == "Sub Admin") {
                            window.location.href = "/viewsubadmintask";
                        }
                        else {
                            window.location.href = "/" + response.data[0].UserDefaultPageDetail[0].FunctionalityPage;
                        }
                    }
                    else {
                        window.location.href = "/" + response.data[0].UserDefaultPageDetail[0].FunctionalityPage;
                    }
                }
                else {
                    window.location.href = "/dashboard";
                }
            }
            else {
                if (response.data[0].UserDefaultPageDetail !== null) {
                    window.location.href = "/" + response.data[0].UserDefaultPageDetail[0].FunctionalityPage;
                }
                else {
                    window.location.href = "/dashboard";
                }
            }
        }
    }
    onCancelSubmit = (e) => {
        e.preventDefault();
        this.setState({
            clients: [],
            CompanyID: { 'label': '--Select Company--', 'value': '--Select Company--' },
            ClientID: { 'label': '--Select Client--', 'value': '--Select Client--' },
            CompanyErrMsg: "", ClientErrMsg: ""
        });
        this.GetCompany();
    }
    render() {
        return (
            <div>
                <div className="modal fade" ref={modal => this.Modal = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <FormWithConstraints
                                ref={form => this.form = form}
                                onSubmit={this.OnhandleSubmit}
                                noValidate>
                                <div className="modal-header">

                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">Company<span className="text-danger">*</span></label>
                                                <Select options={this.state.companies} value={this.state.CompanyID} onChange={this.onCompanyChange} />
                                                {this.state.CompanyErrMsg && <span className="text-danger">{this.state.CompanyErrMsg === 'required' ? '*' : ''}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">Client<span className="text-danger">*</span></label>
                                                <Select options={this.state.clients} value={this.state.ClientID} onChange={this.onClientChange} />
                                                {this.state.ClientErrMsg && <span className="text-danger">{this.state.ClientErrMsg === 'required' ? '*' : ''}</span>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" onSubmit={this.OnhandleSubmit} className="btn btn-primary font-weight-bold">
                                        Submit
                                    </button>
                                    <button type="button" onClick={this.onCancelSubmit} className="btn btn-light-danger font-weight-bold">
                                        Cancel
                                    </button>
                                </div>
                            </FormWithConstraints>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withCookies(usercompanyclientselection);