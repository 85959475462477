import axios from "axios";
const config = { headers: { 'Content-Type': `multipart/form-data` } }
//const API_URL = "http://192.168.0.107:1342/api/";
//  const API_URL = "https://mwhdia.in:3071/api/";
// const API_URL = "http://mwhdia.in:1342/api/";

class services {
  // API_URL = "http://192.168.0.107:1342/api/";

  // API_URL = "http://192.168.0.102:1342/api/";

  API_URL = "https://mwhdia.in:3071/api/";
  // API_URL = "http://mwhdia.in:1342/api/";

  // setClientLogin(data) { return axios(data); }
  // setUserLogin(data) { return axios(data); }
  // Country(data) { return axios(data); }
  // State(data) { return axios(data); }
  // City(data) { return axios(data); }
  // Company(data) { return axios(data); }
  // Client(data) { return axios(data); }
  // Zone(data) { return axios(data); }
  // District(data) { return axios(data); }
  // Muncipality(data) { return axios(data); }
  // BookingType(data) { return axios(data); }
  // Ward(data) { return axios(data); }
  // Booking(data) { return axios(data); }

  JsonValue(data) { return axios(data) }

  async AysncJsonValue(data) { return await axios(data) }
  // FormValue(BookingTaskDetailID, BookingID, ParkingLocationID, VehicleLeaveParkingTime, VehicleReachedLocationTime, VehicleLeaveParkingOdometer,JettingStartMeterReading,SuctionPumpStartMeterReading,BookingStartLatitude,BookingStartLongitude,BookingStartAddress,CheckListData,TaskImages) {
  //     return axios.post(API_URL + "Booking/setStartBooking", { BookingTaskDetailID, BookingID, ParkingLocationID, VehicleLeaveParkingTime, VehicleReachedLocationTime, VehicleLeaveParkingOdometer,JettingStartMeterReading,SuctionPumpStartMeterReading,BookingStartLatitude,BookingStartLongitude,BookingStartAddress,CheckListData,TaskImages });
  // }
  FormValue(form) { return axios.post(this.API_URL + "Booking/setStartBooking", form, config); }
  SubAdminFormValue(form) { return axios.post(this.API_URL + "Booking/setStartBookingBySubAdmin", form, config); }
  FinishFormValue(form) { return axios.post(this.API_URL + "Booking/setFinishBooking", form, config); }
  SubAdminFinishFormValue(form) { return axios.post(this.API_URL + "Booking/setFinishBookingBySubAdmin", form, config); }
  // CompleteFormValue(form) { return axios.post(this.API_URL + "Booking/setCompleteBooking", form, config); }
  CompleteFormValue(form) { return axios.post(this.API_URL + "Booking/setCompleteBooking2", form, config); }
  // SubAdminCompleteFormValue(form) { return axios.post(this.API_URL + "Booking/setCompleteBookingBySubAdmin", form, config); }
  SubAdminCompleteFormValue(form) { return axios.post(this.API_URL + "Booking/setCompleteBookingBySubAdmin2", form, config); }
  UserFormValue(form) { return axios.post(this.API_URL + "User/setUser", form, config); }
  setVehicle(form) { return axios.post(this.API_URL + "Vehicle/setVehicle", form, config); }
  // FormValue(datas) {
  //     return axios.post(API_URL + "Booking/setStartBooking", { datas });
  // }
}
export default new services();