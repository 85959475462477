import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class AutoSendMail extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.GetAutoSendMail();
        //    setInterval(() => {
        //     this.GetAutoSendMail()
        // }, 1000);
    }
    GetAutoSendMail() {
        var data=JSON.stringify({

        });
        var config={
            method: 'POST',
            url: services.API_URL+"RemainderMail/getDailyRemainderMail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            if (response.data.status) {
                // Swal.fire({
                //     title: 'Successfully Called every 10 seconds', icon: "success", timer: 1500
                // });
                //  this.setState({ data: response.data.data })
            }
            else {
                // Swal.fire({
                //     title: 'No Data Found', icon: "success", timer: 1500
                // });
                // this.setState({ data: [] });
            }

        }, error => { })
    }
    render() {
        return (
            <>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>

                </div>
            </>
        );
    }
}
export default AutoSendMail;