import React, { Component } from "react";
import services from "../services/services";
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import * as htmlToImage from 'html-to-image';
import { history } from '../helpers/history';
import { withCookies, Cookies } from 'react-cookie';
import download from 'downloadjs';
const A4_PAPER_DIMENSIONS={
    width: 210,
    height: 295,
};

const A4_PAPER_RATIO=A4_PAPER_DIMENSIONS.width/A4_PAPER_DIMENSIONS.height;

export const imageDimensionsOnA4=() => {
    debugger;
    const isLandscapeImage=210>=297;

    if (isLandscapeImage) {
        return {
            width: A4_PAPER_DIMENSIONS.width,
            height:
                A4_PAPER_DIMENSIONS.width/(610/97),
        };
    }

    const imageRatio=600/297;
    if (imageRatio>A4_PAPER_RATIO) {
        const imageScaleFactor=
            (A4_PAPER_RATIO*260)/600; // for space all doc

        const scaledImageHeight=A4_PAPER_DIMENSIONS.height*imageScaleFactor;

        return {
            // height: scaledImageHeight*imageRatio,
            height: "295",
            width: scaledImageHeight*imageRatio,
        };
    }

    return {
        width: A4_PAPER_DIMENSIONS.height/(600/297),
        height: A4_PAPER_DIMENSIONS.height,
        
    };
};

class pdfdemo extends Component {
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            BookingDate:"",BookingNo:"",Shift:"",ZoneName:"",WardName:"",Locality:"",ParkingLocationName:"",
            DistanceFromParkingLocationToLocationOfWork:"",VehicleLeaveParkingTime:"",VehicleReachedLocationTime:"",
            VehicleReturnLocationTime:"",VehicleReachedParkingTime:"",VehicleLeaveParkingKiloMeter:"",
            VehicleReachedLocationKiloMeter:"",VehicleReturnLocationKiloMeter:"",VehicleReachedParkingKiloMeter:"",
            JettingStartMeterReading:"",SuctionPumpStartMeterReading:"",JettingFinishMeterReading:"",SuctionPumpFinishMeterReading:"",
            TotalHours:"",TotalMinutes:"",TotalTime:"",TotalKilometer:"",TotalJettingUsageTime:"",TotalSuctionPumpUsageTime:"",
            TotalUsageJettingHour:"",TotalUsageJettingMinutes:"",TotalSuctionPumpUsageHour:"",TotalSuctionPumpUsageMinutes:"",
            TotalNoOfDranageLine:"",TotalDrainageMeter:"",DrainageLine150to300mmDiaMeter:"",DrainageLine301to600mmDiaMeter:"",
            DrainageLine601to900mmDiaMeter:"",DrainageLine901to1200mmDiaMeter:"",JettingSystem:"",SuctionSystem:"",
            SuctionHose5Diameter3MeterLong:"",JettingHose32Diameter120MeterLong:"",JettingNozzleDeChockingNozzle:"",
            DeSiltingNozzleFlatNozzle:"",BalanceNozzleBombNozzle:"",DriverName:"",SupervisorName:"",
            IsSafetyShoesChecked:false,IsHandGlovesChecked:false,IsHelmetChecked:false,IsMaskChecked:false,
            IsLightReflectiveJacketChecked:false,IsUniformChecked:false,IsIDCardChecked:false

        }
    }
    componentDidMount() {
        const search=history.location.search;
        const params=new URLSearchParams(search);
        const id=params.get('id')
        if(id != null)
        {
            this.GetBooking(id);
        }
        
    }

    GetBooking(id) {
        //debugger;
        //   window.location.reload(true);
        var CompanyID="";
        var ClientID="";
        var UserID="";
        var MunicipalityID="";
        var UserType="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }


        var APIName="";
        var data="";

        APIName="Report/getBookingLogSheetData"
        data=JSON.stringify({
            BookingID: id,
            CompanyID: CompanyID,
            ClientID: ClientID,
            UserID: UserID,
            UserTypeID: "",
            UserType: "",
            BookingTypeID: "",
            BookingNo: "",
            ZoneID: "",
            DistrictID: "",
            MuncipalityID: "",
            WardID: "",
            Priority: "",
            BookingStatus: "",
            StartDate: "",
            EndDate: ""
        });


       // console.log("data", data);
        var config={
            method: 'POST',
            url: services.API_URL+APIName,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                this.setState({BookingDate:response.data.data[0].BookingDate});
                this.setState({BookingNo:response.data.data[0].BookingNo});
                this.setState({Shift:response.data.data[0].Shift});
                this.setState({ZoneName:response.data.data[0].ZoneName});
                this.setState({WardName:response.data.data[0].WardName});
                this.setState({Locality:response.data.data[0].Locality});
                this.setState({ParkingLocationName:response.data.data[0].ParkingLocationName});
                this.setState({DistanceFromParkingLocationToLocationOfWork:response.data.data[0].DistanceFromParkingLocationToLocationOfWork});
                this.setState({VehicleLeaveParkingTime:response.data.data[0].VehicleLeaveParkingTime});
                this.setState({VehicleReachedLocationTime:response.data.data[0].VehicleReachedLocationTime});
                this.setState({VehicleReturnLocationTime:response.data.data[0].VehicleReturnLocationTime});
                this.setState({VehicleReachedParkingTime:response.data.data[0].VehicleReachedParkingTime});
                this.setState({VehicleLeaveParkingKiloMeter:response.data.data[0].VehicleLeaveParkingKiloMeter});
                this.setState({VehicleReturnLocationKiloMeter:response.data.data[0].VehicleReturnLocationKiloMeter});
                this.setState({VehicleReachedParkingKiloMeter:response.data.data[0].VehicleReachedParkingKiloMeter});
                this.setState({JettingStartMeterReading:response.data.data[0].JettingStartMeterReading});
                this.setState({SuctionPumpStartMeterReading:response.data.data[0].SuctionPumpStartMeterReading});
                this.setState({SuctionPumpFinishMeterReading:response.data.data[0].SuctionPumpFinishMeterReading});
                this.setState({JettingFinishMeterReading:response.data.data[0].JettingFinishMeterReading});
                this.setState({TotalTime:response.data.data[0].TotalTime});
                this.setState({TotalKilometer:response.data.data[0].TotalKiloMeter});
                
                
                if(response.data.data[0].TotalTime != null)
                {
                   var TotalTime =  response.data.data[0].TotalTime.split(':');
                   this.setState({TotalHours:TotalTime[1]});
                   this.setState({TotalMinutes:TotalTime[2]});
                }

                if(response.data.data[0].TotalJettingUsageTime != null)
                {
                   var TotalJettingUsageTime =  response.data.data[0].TotalJettingUsageTime.split(':');
                   this.setState({TotalUsageJettingHour:TotalJettingUsageTime[1]});
                   this.setState({TotalUsageJettingMinutes:TotalJettingUsageTime[2]});
                }

                if(response.data.data[0].TotalSuctionPumpUsageTime != null)
                {
                   var TotalSuctionPumpUsageTime =  response.data.data[0].TotalSuctionPumpUsageTime.split(':');
                   this.setState({TotalSuctionPumpUsageHour:TotalSuctionPumpUsageTime[1]});
                   this.setState({TotalSuctionPumpUsageMinutes:TotalSuctionPumpUsageTime[2]});
                }

                this.setState({TotalNoOfDranageLine:response.data.data[0].TotalNoOfDranageLine});
                this.setState({TotalDrainageMeter:response.data.data[0].TotalMeter});
                this.setState({DrainageLine150to300mmDiaMeter:response.data.data[0].DrainageLine150to300mmDiaMeter});
                this.setState({DrainageLine301to600mmDiaMeter:response.data.data[0].DrainageLine301to600mmDiaMeter});
                this.setState({DrainageLine601to900mmDiaMeter:response.data.data[0].DrainageLine601to900mmDiaMeter});
                this.setState({DrainageLine901to1200mmDiaMeter:response.data.data[0].DrainageLine901to1200mmDiaMeter});
                this.setState({JettingSystem:response.data.data[0].JettingSystem});
                this.setState({SuctionSystem:response.data.data[0].SuctionSystem});
                this.setState({SuctionHose5Diameter3MeterLong:response.data.data[0].SuctionHose5Diameter3MeterLong});
                this.setState({JettingHose32Diameter120MeterLong:response.data.data[0].JettingHose32Diameter120MeterLong});
                this.setState({JettingNozzleDeChockingNozzle:response.data.data[0].JettingNozzleDeChockingNozzle});
                this.setState({DeSiltingNozzleFlatNozzle:response.data.data[0].DeSiltingNozzleFlatNozzle});
                this.setState({BalanceNozzleBombNozzle:response.data.data[0].BalanceNozzleBombNozzle});
                this.setState({DriverName:response.data.data[0].DriverFullName});
                this.setState({SupervisorName:response.data.data[0].AssignSupervisorFullName});
                var BookingTaskCheckListDetail = response.data.data[0].BookingTaskCheckListDetail;
                if(BookingTaskCheckListDetail != null)
                {
                    debugger;
                    for (let i = 0; i < response.data.data[0].BookingTaskCheckListDetail.length; i++) {
                        if(response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Safety Shoes")
                        {
                            this.setState({IsSafetyShoesChecked:true});
                        }
                        if(response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Hand Gloves")
                        {
                            this.setState({IsHandGlovesChecked:true});
                        }
                        if(response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Helmet")
                        {
                            this.setState({IsHelmetChecked:true});
                        }
                        if(response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Mask")
                        {
                            this.setState({IsMaskChecked:true});
                        }
                        if(response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Light Reflective Jacket")
                        {
                            this.setState({IsLightReflectiveJacketChecked:true});
                        }
                        if(response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Uniform")
                        {
                            this.setState({IsUniformChecked:true});
                        }
                        if(response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "I-Card")
                        {
                            this.setState({IsIDCardChecked:true});
                        }
                        
                    }
                }
                
               // this.generateImage();
                //this.setState({ data: response.data.data })
            }
            else {
               // this.setState({ data: [] })
            }

        }, error => { })
    }

    printDocument() {
        //const input = document.getElementById('divToPrint');

        const doc=new jsPDF();

        //get table html
        const pdfTable=document.getElementById('divToPrint');
        //html to pdf format
        var html=htmlToPdfmake(pdfTable.innerHTML);

        const documentDefinition={ content: html };
        pdfMake.vfs=pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(documentDefinition).open();

    }

    generatePDF=() => {

        const report=new jsPDF('portrait', 'pt', 'a4');
        report.html(document.querySelector('#divToPrint')).then(() => {
            report.save('report.pdf');
        });
    }

    generateImage=() => {
        debugger;
        const img=document.getElementById('divToPrint');
        htmlToImage.toJpeg(img, { quality: 1, backgroundColor: "#FFFFFF", height: img.clientHeight, width: img.clientWidth })
            .then(function (dataUrl) {
                debugger;
                const doc=new jsPDF();
                doc.deletePage(1);

                const imageDimensions=imageDimensionsOnA4({
                    width: 300,
                    height: 597,
                });

                doc.addPage();
                doc.addImage(
                    dataUrl,
                    //image.imageType,
                    (A4_PAPER_DIMENSIONS.width-imageDimensions.width)/2,
                    (A4_PAPER_DIMENSIONS.height-imageDimensions.height)/2,
                    imageDimensions.width,
                    imageDimensions.height
                );
                const pdfURL=doc.output("bloburl");
                //window.open(pdfURL, "_blank");
                let alink = document.createElement('a');
                alink.href = pdfURL;
                alink.download = 'Logsheet.pdf';
                alink.click();
                //download(dataUrl,'image.png')
                // const file = new Blob([pdfURL], {type: 'application/pdf'});
                // const fileURL = URL.createObjectURL(file);
                // download(fileURL,'logsheet.pdf')
                // var tempLink = "";
                // var data=new Blob([doc.output], { type: 'application/pdf' });
                // var csvURL=window.URL.createObjectURL(data);
                // tempLink=document.createElement('a');
                // tempLink.href=csvURL;
                // tempLink.setAttribute('download', 'filename.pdf');
                // tempLink.click();
                // var blob=new Blob([doc], { type: "application/pdf" }), // or application/pdf
                //     url=window.URL.createObjectURL(blob);
                // a.href=url;
                // a.download= "filename.pdf";
                // a.click();
                // window.URL.revokeObjectURL(url);
                //const pdfURLs=doc.output("bloburl");
                // window.open(pdfURL , "_blank");
                //download(pdfURL,'logsheet.pdf')
                // download(dataUrl, 'image.jpeg');
                // var img=new Image();
                // img.src=dataUrl;
                // var div=document.getElementById("real")
                // div.appendChild(img)
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    render() {

        return (
            <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                <div className="App container mt-5">

                    <div id="divToPrint">

                        <div className="row">
                            <div className="col-md-1" >
                                <img src="/Images/Amclogo.png" style={{ height: '80px', textAlign: 'left' }}></img>
                            </div>
                            <div className="col-md-6" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <h3 style={{ width: '40%',fontWeight:'bold' }}>અમદાવાદ મ્યુનિસિપલ કોર્પોરેશન પર્ફોમન્સ સર્ટીફીકેટ</h3>
                            </div>
                            <div className="col-md-2" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <span style={{ fontWeight: 'bold', fontSize: '20px' }}>No.</span>
                                <span style={{fontSize:'18px',borderBottom:'2px dotted',width:'100%'}}>{this.state.BookingNo}</span>
                            </div>
                            <div className="col-md-3">
                                <img src="/Images/azadi.png" style={{ height: '80px' }}></img>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontWeight: '500', fontSize: '18px' }}>આથી મે. મેટ્રો વેસ્ટ હેન્ડલીંગ પ્રા. લી.ને સર્ટીફીકેટ આપવામાં આવે છે કે, ડીશીલ્ટીંગ મશીન રજીસ્‍ટ્રેશન નંબર GJ-01-J-4782 દ્રારા તા <span style={{borderBottom:'2px dotted'}}>{this.state.BookingDate}</span> </span>
                            </div>
                            {/* <div className="col-md-12" >
<span style={{ fontWeight: 'normal', fontSize: '18px' }}>આથી મે. મેટ્રો વેસ્ટ હેન્ડલીંગ પ્રા. લી.ને સર્ટીફીકેટ આપવામાં આવે છે કે, ડીશીલ્ટીંગ મશીન રજીસ્‍ટ્રેશન નંબર GJ-01-J-4782 દ્રારા તા.  ..............</span>

</div> */}
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontWeight: '500', fontSize: '18px' }}>ના રોજ <span style={{ fontWeight: '500', fontSize: '18px',borderBottom:'2px dotted' }}>{this.state.Shift}</span> શીફ્ટમાં <span style={{fontSize:'18px',borderBottom:'2px dotted',width:'100%'}}>{this.state.TotalTime}</span> કલાક માટે નીચે મુજબની વિગતથી કામગીરી કરેલ છે.</span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontWeight: '500', fontSize: '18px' }}><span style={{ paddingRight: '5%' }}>૧.</span> ઝોનઃ <span style={{ fontWeight: '500', fontSize: '18px',borderBottom:'2px dotted' }}>{this.state.ZoneName}</span> વોર્ડ : <span style={{ fontWeight: '500', fontSize: '18px',borderBottom:'2px dotted' }}>{this.state.WardName}</span> કીમિંગીરીનું સથળ: <span style={{ fontWeight: '500', fontSize: '18px',borderBottom:'2px dotted' }}>{this.state.Locality}</span></span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontWeight: '500', fontSize: '18px' }}><span style={{ paddingRight: '5%' }}>ર.</span> પાર્કિંગનું સ્થળ: <span style={{ fontWeight: '500', fontSize: '18px',borderBottom:'2px dotted' }}>{this.state.ParkingLocationName}</span> પાર્કિગના સ્થળ અને કામગીરીના સ્થળ વચ્ચેનું અંતર <span style={{ fontWeight: '500', fontSize: '18px',borderBottom:'2px dotted' }}>{this.state.DistanceFromParkingLocationToLocationOfWork}</span></span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontSize: '18px' }}><span style={{ paddingRight: '5%' }}>૩.</span> કામગીરીની વિગત : ??</span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%' }}>
                                <table style={{ border: '1px solid' }} className="table table-bordered">
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>અનુ ક્રમાંક</span></td>
                                        <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '18px' }}>વિગત</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '18px' }}>સમય</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '18px' }}>કિલોમીટર</span></td>
                                        <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '18px' }}>વિગત</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '18px' }}>સમય</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '18px' }}>કિલોમીટર</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૧</span></td>
                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>મશીન પાર્કિંગ થી નિકળ્યાનો સમય</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.VehicleLeaveParkingTime}</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.VehicleLeaveParkingKiloMeter}</span></td>
                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>મશીન કામગીરીના સ્થળ ઉપર આવ્યાનો સમય</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.VehicleReachedLocationTime}</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.VehicleReachedLocationKiloMeter}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>ર</span></td>
                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>મશીન કામગીરીના સ્થળ ઉપરથી નિકળ્યાનો સમય</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.VehicleReturnLocationTime}</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.VehicleReturnLocationKiloMeter}</span></td>
                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>મશીન પાર્કિંગ ઉપર પહોચ્યાંનો સમય</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.VehicleReachedParkingTime}</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.VehicleReachedParkingKiloMeter}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૩</span></td>
                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>કુલ સમય</span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '18px' }}>કલાક </span><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.TotalHours}</span> <span style={{ fontSize: '18px', paddingLeft: '120px' }}>મીનીટ </span><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.TotalMinutes}</span> </td>
                                        {/* <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '18px' }}>મીનીટ</span></td> */}
                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>કુલ કિલોમીટર રીડીગ  </span></td>
                                        <td style={{ border: '1px solid black', width: '13.75%' }} colspan='2'><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.TotalKilometer}</span></td>
                                        {/* <td style={{ border: '1px solid black', width: '15%' }}><span style={{ fontSize: '18px' }}></span></td> */}
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૪</span></td>
                                        <td style={{ border: '1px solid black', textAlign: 'center' }} colspan='2'><span style={{ fontSize: '18px' }}>વિગત</span></td>
                                        <td style={{ border: '1px solid black', textAlign: 'center' }}><span style={{ fontSize: '18px' }}>રનીગ અવર્સના રીડીગ </span></td>
                                        <td style={{ border: '1px solid black', textAlign: 'center' }} colspan='2'><span style={{ fontSize: '18px' }}>વિગત</span></td>
                                        <td style={{ border: '1px solid black', textAlign: 'center' }}><span style={{ fontSize: '18px' }}>રનીગ અવર્સના રીડીગ </span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>પ</span></td>
                                        <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '18px' }}>મશીનની જેટિગની કામગીરી શરુ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                        <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.JettingStartMeterReading}</span></td>
                                        <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '18px' }}>મશીનની જેટિગની કામગીરી પૂર્ણ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                        <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.JettingFinishMeterReading}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૬</span></td>
                                        <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '18px' }}>મશીનની સકશનની કામગીરી શરુ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                        <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.SuctionPumpStartMeterReading}</span></td>
                                        <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '18px' }}>મશીનની સકશનની કામગીરી પૂર્ણ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                        <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.SuctionPumpFinishMeterReading}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૭</span></td>
                                        <td style={{ border: '1px solid black', textAlign: 'justify' }} colspan='3'><span style={{ fontSize: '18px' }}>કુલ જેટિગ સમય:</span><span style={{ fontSize: '18px', paddingLeft: '120px' }}>કલાક </span> <span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.TotalUsageJettingHour}</span> <span style={{ fontSize: '18px', paddingLeft: '120px' }}>મીનીટ </span> <span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.TotalUsageJettingMinutes}</span> </td>
                                        <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='3'><span style={{ fontSize: '18px' }}>કુલ સકશન સમય:</span><span style={{ fontSize: '18px', paddingLeft: '120px' }}>કલાક </span> <span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.TotalSuctionPumpUsageHour}</span> <span style={{ fontSize: '18px', paddingLeft: '120px' }}>મીનીટ </span> <span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.TotalSuctionPumpUsageMinutes}</span> </td>

                                    </tr>
                                </table>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontSize: '18px' }}><span style={{ paddingRight: '5px' }}>૪.</span>સદર વોર્ડની ડ્રેનેજ લાઇનની ડિશીલ્ટીંગ ડિચોકીંગની નીચે મુજબની કામગીરી કરેલ છે.તથા કુલ <span style={{ fontWeight: '500', fontSize: '18px',borderBottom:'2px dotted'}}>{this.state.TotalDrainageMeter}</span> મીટર ડ્રેનેજલાઈન અને <span style={{ fontWeight: '500', fontSize: '18px',borderBottom:'2px dotted'}}>{this.state.TotalNoOfDranageLine}</span> નંગ
                                    મશીનનહોલની સફાઈની કામગીરી કરેલ છે.</span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%' }}>
                                <table style={{ border: '1px solid' }} className="table table-bordered">
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>અનુ ક્રમાંક</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '18px' }}>ડ્રેનેજ લાઇન સાઈઝ ટેન્ડર</span></td>
                                        <td style={{ border: '1px solid black', width: '25%' }}><span style={{ fontSize: '18px' }}>ટેન્ડરના <span style={{ fontWeight: 'bold', fontSize: '18px' }}>KPI</span> મુજબ નો ટાર્ગેટ</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '18px' }}>કામગીરીના સ્થળ ઉપર થયેલ ખરેખર કામગીરી</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૧</span></td>
                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૧૫૦ એમ.એમ. થી ૩૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૬૦ મીટર પ્રતિ કલાક</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.DrainageLine150to300mmDiaMeter}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૨</span></td>
                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૩૦૧ એમ.એમ. થી ૬૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૪૦ મીટર પ્રતિ કલાક</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.DrainageLine301to600mmDiaMeter}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૩</span></td>
                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૬૦૧ એમ.એમ. થી ૯૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૩૦ મીટર પ્રતિ કલાક</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.DrainageLine601to900mmDiaMeter}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૪</span></td>
                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૯૦૧ એમ.એમ. થી ૧૨૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૧૦ મીટર પ્રતિ કલાક</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.DrainageLine901to1200mmDiaMeter}</span></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontSize: '18px' }}><span style={{ paddingRight: '5px' }}>૫.</span>કામગીરીના સ્થળ ઉપર જોવા મળેલ મશીનના ટેકનીકલ પેરામીટરની વિગત</span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%' }}>
                                <table style={{ border: '1px solid' }} className="table table-bordered">
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>અનુ ક્રમાંક</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '18px' }}>ટેકનીકલ પેરામીટર</span></td>
                                        <td style={{ border: '1px solid black', width: '25%' }}><span style={{ fontSize: '18px' }}>ટેન્ડર મુજબ જરૂરિયાત કેપેસીટી વૅલ્યુ </span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '18px' }}>કામગીરીના સ્થળ ઉપર ખરેખર મળેલ કેપેસીટી</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૧</span></td>
                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>જેટિગ સીસ્ટમ</span></td>
                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૧૫૦ બાર</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.JettingSystem}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૨</span></td>
                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>સકશન સીસ્ટમ</span></td>
                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૫૦૦૦ ક્યુબીક મી કલાક</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.SuctionSystem}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૩</span></td>
                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>સકશન હોઝ ૫ ડાયા મીટર - ૩ મીટર લંબાઈ</span></td>
                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૧૦ નંગ</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.SuctionHose5Diameter3MeterLong}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૪</span></td>
                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>જેટિગ હોઝ - ૩૨ એમ.એમ. ડાયા, - ૧૨૦ મી લંબાઈ </span></td>
                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૧ નંગ</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.JettingHose32Diameter120MeterLong}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>પ</span></td>
                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>જેટિગ નોઝલ : ડીચોકીગ નોઝલ</span></td>
                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૧ નંગ</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.JettingNozzleDeChockingNozzle}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૬</span></td>
                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>ડી શીલ્ટીગ નોઝલ - ફ્લેટ નોઝલ</span></td>
                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૧ નંગ</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.DeSiltingNozzleFlatNozzle}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '18px' }}>૭</span></td>
                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>બેલેન્સ નોઝલ બોમ્બ નોઝલ</span></td>
                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '18px' }}>૧ નંગ</span></td>
                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '18px'}}>{this.state.BalanceNozzleBombNozzle}</span></td>
                                    </tr>

                                </table>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontSize: '18px' }}><span style={{ paddingRight: '5px' }}>૬.</span>સાઈટ ઉપર હાજર સ્ટાફની વિગત</span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontSize: '18px' }}>(i)ડ્રાઇવરનું નામ : <span style={{ fontWeight: '500', fontSize: '18px',borderBottom:'2px dotted'}}>{this.state.DriverName}</span> (ii)ઓપરેટરનું નામ : ..........................?? (iii)સુપરવાઇઝરનું નામ : <span style={{ fontWeight: '500', fontSize: '18px',borderBottom:'2px dotted'}}>{this.state.SupervisorName}</span></span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontSize: '18px' }}><span>૬.</span>સ્ટાફને આપેલ સુરક્ષા સાધનો(PPE) અને અન્ય વિગત, જો હોય તો (✔) અને જો ન હોય તો (✘) માર્ક કરવુ.</span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontSize: '18px' }}>(i) સેફટી શુઝ : {(this.state.IsSafetyShoesChecked) ?  (<i style={{color:'black'}} className="icon-xl far fa-check-square"></i>) : (<i style={{color:'black'}} className="icon-xl far fa-window-close"></i>)} (ii) હેન્ડ ગ્લોવ્ઝ : {(this.state.IsHandGlovesChecked) ?  (<i style={{color:'black'}} className="icon-xl far fa-check-square"></i>) : (<i style={{color:'black'}} className="icon-xl far fa-window-close"></i>)} (iii) હેલ્મેટ : {(this.state.IsHelmetChecked) ?  (<i style={{color:'black'}} className="icon-xl far fa-check-square"></i>) : (<i style={{color:'black'}} className="icon-xl far fa-window-close"></i>)} (iv) માસ્ક : {(this.state.IsMaskChecked) ?  (<i style={{color:'black'}} className="icon-xl far fa-check-square"></i>) : (<i style={{color:'black'}} className="icon-xl far fa-window-close"></i>)} (v) લાઈટ રીફ્લેકટીવ જેકેટ : {(this.state.IsLightReflectiveJacketChecked) ?  (<i style={{color:'black'}} className="icon-xl far fa-check-square"></i>) : (<i style={{color:'black'}} className="icon-xl far fa-window-close"></i>)} (vi) યુનિફોર્મ : {(this.state.IsUniformChecked) ?  (<i style={{color:'black'}} className="icon-xl far fa-check-square"></i>) : (<i style={{color:'black'}} className="icon-xl far fa-window-close"></i>)} (vii) આઈ કાર્ડ : {(this.state.IsIDCardChecked) ?  (<i style={{color:'black'}} className="icon-xl far fa-check-square"></i>) : (<i style={{color:'black'}} className="icon-xl far fa-window-close"></i>)} </span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontSize: '18px' }}><span style={{ paddingRight: '5px' }}>૭.</span>રીમાર્કસ(અન્ય ફોલ્ટની કામગીરીની વિગત):-</span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                <span style={{ fontSize: '18px' }}><span style={{ paddingRight: '5px' }}>૮.</span>ટેન્ડર લીકવીડીટી ડેમેજીસની કન્ડિશન મુજબ (પાના નં. ૧૦) જો કપાત હોય તો તેની વિગત :</span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '5%', textAlign: 'left' }}>
                                <span style={{ fontSize: '18px', paddingLeft: '1%' }}>મશીન સુપરવાઇઝર<span style={{ fontWeight: 'bold', fontSize: '18px', paddingLeft: '5px' }}>(MWHPL)</span></span>
                                <span style={{ fontSize: '18px', paddingLeft: '1%' }}>ટેકનીકલ સુપરવાઇઝર<span style={{ fontWeight: 'bold', fontSize: '18px', paddingLeft: '5px' }}>(........Ward)</span></span>
                                <span style={{ fontSize: '18px', paddingLeft: '1%' }}>આસી. એનજીનીઅર<span style={{ fontWeight: 'bold', fontSize: '18px', paddingLeft: '5px' }}>(........Ward)</span></span>
                                <span style={{ fontSize: '18px', paddingLeft: '1%' }}>આસી.સીટી.એન્જીનીઅર<span style={{ fontWeight: 'bold', fontSize: '18px', paddingLeft: '5px' }}>(........Ward)</span></span>
                            </div>
                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'center' }}>
                                <span style={{ fontSize: '16px' }}> <span style={{ fontWeight: 'bold' }}>Special Note :</span><span style={{ paddingLeft: '5px' }}>As per Hon. Court order in any case, contractor is not allowed to enter/work inside the machinehole or sewer lines. Proper trainin & work knowledge shall be provided to all personals engaged for work by contractor.</span></span>
                            </div>
                            {/* <div className="col-md-12" style={{ marginTop: '1%' ,textAlign: 'left' }}>
        <table>
            <tr>
                <td style={{ width: '15%' }}><span style={{ fontSize: '17px', fontWeight: 'bold' }}>Special Note :</span></td>
                <td style={{ width: '85%' }}><span style={{ fontSize: '17px' }}>As per Hon. Court order in any case, contractor is not allowed to enter/work inside the machinehole or sewer lines. Proper trainin & work knowledge shall be provided to all personals engaged for work by contractor.</span></td>
            
            </tr>
        </table>
    </div> */}
                        </div>
                    </div>
                    <div id="real">
                    </div>
                    {/* <button class="btn btn-primary" onClick={this.printDocument}>Export To PDF</button>
                    <button class="btn btn-primary" onClick={this.generatePDF}>Export To PDF New</button> */}
                    <button class="btn btn-primary" onClick={this.generateImage}>Export To Image</button>
                </div>
            </div>
        )
    };
}
export default withCookies(pdfdemo);