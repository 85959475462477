import React from 'react'
import { PrivateRoute } from 'react-auth-kit'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Login from './components/login.component'
import Dashboard from './components/dashboard.component'
import Country from './components/country.component'
import State from './components/state.component'
import City from './components/city.component'
import Company from './components/company.component'
import Client from './components/client.component'
import ViewClient from './components/viewclient.component'
import Zone from './components/zone.component'
import { createBrowserHistory } from 'history';
import District from './components/district.component'
import Municipality from './components/municipality.component'
import Adminlogin from './components/adminlogin.component'
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import Booking from './components/booking.component'
import ViewBooking from './components/viewbooking.component'
import ParkingLocation from './components/parkinglocation.component'
import CheckList from './components/checklist.component'
import UserType from './components/usertype.component'
import VehicleType from './components/vehicletype.component'
import User from './components/user.component'
import Functionality from './components/functionality.component'
import Userpermission from './components/userpermission.component'
import VehicleBrand from './components/vehiclebrand.component'
import VehicleModel from './components/vehiclemodel.component'
import VehicleMaster from './components/vehiclemaster.component'
import WardMaster from './components/wardmaster.component'
import TaskType from './components/tasktype.component'
import TabularReport from './components/tabularreport.component'
import MessageTextConfiguration from './components/messagetextconfiguration.component'
import EmailTextConfiguration from './components/emailtextconfiguration.component'
import ViewAdminTask from './components/viewadmintask.component'
import NoAccess from './components/noaccess.component'
import { Redirect } from 'react-router';
import Companyclientselection from './components/companyclientselection.component'
import GudmDashboard from './components/gudmdashboard.component'
import GudmDashboard2 from './components/gudmdashboard2.component'
import VehicleDashboard from './components/vehicledashboard.component'
import UserCompanyClientSelection from './components/usercompanyclientselection.component'
import PDFDemo from './components/pdfdemo.component'
import AuditLog from './components/auditlog.component'
import ViewMultipleLogBook from './components/viewmultiplelogbook.component'
import Livegps from './components/livegps.component'
import ViewSubAdminTask from './components/viewsubadmintask.component'
import SubAdminBooking from './components/subadminbooking.component'
import AutoApprove from './components/autoapprove.component'
import AutoSendMail from './components/autosendmail.component'
import AdminReport from './components/adminreport.component'
import OverallMap from './components/overallmap.component'
import SummaryReport from './components/gudmsummary.component'
import PrivacyAndPolicy from './components/privacyandpolicy.component'
import VehicleRemarks from './components/vehicleremarks.component'
import MultipleDemo from './components/multipledemo'


const Routing = () => {
    const history = createBrowserHistory();
    const cookies = new Cookies();
    const propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    var PanelUserType = cookies.get('PanelUserType')
    console.log("cookies", PanelUserType);

    return (

        // <BrowserRouter history={history}>
        (PanelUserType !== undefined) ? (
            <Routes>
                <Route path={'/'} element={<Adminlogin />} exact />
                <Route path={'/login'} element={<Login />} exact />
                <Route path={'/adminlogin'} element={<Adminlogin />} />
                <Route path={'/dashboard'} element={<Dashboard />} loginPath={'/login'} />
                <Route path={'/country'} element={<Country />} render={(props) =>
                    <Country {...props} />
                } />
                <Route path={'/state'} element={<State />} >
                </Route>
                <Route path={'/city'} element={<City />} />
                <Route exact path={'/company'} element={<Company />} />
                <Route path={'/client'} element={<Client />} />
                <Route path={'/viewclient'} element={<ViewClient />} />
                <Route path={'/zone'} element={<Zone />} />
                {/* <Route path={'/zone'} render={() => (
                    PanelUserType? (
                        <Adminlogin />
                    ):(
                        <Zone />
                    )
                )} /> */}
                <Route path={'/district'} element={<District />} />
                <Route path={'/municipality'} element={<Municipality />} />
                <Route path={'/task'} element={<Booking />} />
                <Route path={'/viewtask'} element={<ViewBooking />} />
                <Route path={'/parkinglocation'} element={<ParkingLocation />} />
                <Route path={'/checklist'} element={<CheckList />} />
                <Route path={'/usertype'} element={<UserType />} />
                <Route path={'/vehicletype'} element={<VehicleType />} />
                <Route path={'/user'} element={<User />} />
                <Route path={'/functionality'} element={<Functionality />} />
                <Route path={'/userpermission'} element={<Userpermission />} />
                <Route path={'/vehiclebrand'} element={<VehicleBrand />} />
                <Route path={'/vehicleModel'} element={<VehicleModel />} />
                <Route path={'/vehicleMaster'} element={<VehicleMaster />} />
                <Route path={'/wardmaster'} element={<WardMaster />} />
                <Route path={'/TaskType'} element={<TaskType />} />
                <Route path={'/TabularReport'} element={<TabularReport />} />
                <Route path={'/MessageTextConfiguration'} element={<MessageTextConfiguration />} />
                <Route path={'/EmailTextConfiguration'} element={<EmailTextConfiguration />} />
                <Route path={'/viewadmintask'} element={<ViewAdminTask />} />
                <Route path={'/openpopup'} element={<Companyclientselection />} />
                <Route path={'/openuserpopup'} element={<UserCompanyClientSelection />} />
                <Route path={'/gudmdashboard'} element={<GudmDashboard />} />
                <Route path={'/gudmdashboard2'} element={<GudmDashboard2 />} />
                <Route path={'/vehicledashboard'} element={<VehicleDashboard />} />
                <Route path={'/pdfdemo'} element={<PDFDemo />} />
                <Route path={'/viewauditlog'} element={<AuditLog />} />
                <Route path={'/viewmultiplelogbook'} element={<ViewMultipleLogBook />} />
                <Route path={'/livegps'} element={<Livegps />} />
                <Route path={'/viewsubadmintask'} element={<ViewSubAdminTask />} />
                <Route path={'/subadminbooking'} element={<SubAdminBooking />} />
                <Route path={'/autoapprove'} element={<AutoApprove />} />
                <Route path={'/autosendmail'} element={<AutoSendMail />} />
                <Route path={'/adminreport'} element={<AdminReport />} />
                <Route path={'/overallmap'} element={<OverallMap />} />
                <Route path={'/gudmsummary'} element={<SummaryReport />} />
                <Route path={'/privacyandpolicy'} element={<PrivacyAndPolicy />} />
                <Route path={'/vehicleremarks'} element={<VehicleRemarks />} />
                <Route path={'/multipledemo'} element={<MultipleDemo />} />

            </Routes>

        ) :

            (
                (history.location.pathname !== "/login" && history.location.pathname !== "/autoapprove" && history.location.pathname !== "/autosendmail" && history.location.pathname !== "/privacyandpolicy") ?
                    (<Adminlogin />) :
                    (history.location.pathname == "/autoapprove") ?
                        (<AutoApprove />) :
                        (history.location.pathname == "/autosendmail") ?
                            (<AutoSendMail />) :
                            (history.location.pathname == "/privacyandpolicy") ?
                                (<PrivacyAndPolicy />) :
                                (<Login />)
                // (<Login />)
            )
        // </BrowserRouter>
    )
}

export default Routing