import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class AutoApprove extends Component {
  
    constructor(props) {
        super(props);
       
    }
    componentDidMount() { 
        // debugger;
        // setInterval(() => {
        //     this.GetAutoApprove()
        // }, 900000);
        this.GetAutoApprove();
    }
    GetAutoApprove()
    {
        var data=JSON.stringify({
           
        });
        var config={
            method: 'POST',
            url: services.API_URL+"Booking/getBookingDataForAutomaticUpdateApprovedStatus",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            if(response.data.status)
            {
                Swal.fire({
                    title: 'Successfully Called every 10 seconds', icon: "success", timer: 1500
                });
              //  this.setState({ data: response.data.data })
            }
            else{
               // this.setState({ data: [] });
            }
            
        }, error => { })
    }
    render() {
        return (
            <>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>

                </div>
            </>
        );
    }
}
export default AutoApprove;