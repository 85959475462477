import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { render } from "@testing-library/react";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import XLSX from "xlsx-color";
import moment from 'moment';
const headerTblStyle={ color: 'black' };
const exportToExcel=(columns, data) => {
    // debugger;
    const header=["Sr.No", "CountryName", "StateName", "CityName", "ParkingLocationName", "Latitude", "Longitude", "Address"];
    const headertemp=["Sr.No", "Country Name", "State Name", "City Name", "Parking Location Name", "Latitude", "Longitude", "Address"];
    // debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else if (col=="CountryName") {
                obj["Country Name"]=row[col];
            }
            else if (col=="StateName") {
                obj["State Name"]=row[col];
            }
            else if (col=="CityName") {
                obj["City Name"]=row[col];
            }
            else if (col=="ParkingLocationName") {
                obj["Parking Location Name"]=row[col];
            }
            else {
                obj[col]=row[col];
            }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    // XLSX.utils.book_append_sheet(wb, ws1, 'Country List');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols']=[
        { width: 6 },
        { width: 18 },
        { width: 18 },
        { width: 18 },
        { width: 18 },
        { width: 18 },
        { width: 18 },
        { width: 50 },
    ];
    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;
    ws1['D1'].s=cell;
    ws1['E1'].s=cell;
    ws1['F1'].s=cell;
    ws1['G1'].s=cell;
    ws1['H1'].s=cell;


    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;
        ws1['D'+ins].s=RowCont;
        ws1['E'+ins].s=RowCont;
        ws1['F'+ins].s=RowCont;
        ws1['G'+ins].s=RowCont;
        ws1['H'+ins].s=RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "ParkingLocationList");
    XLSX.writeFile(wb, "ParkingLocationList_"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}
class ParkingLocation extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            name: cookies.get('name')||'Ben'
        };
        this.state={
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [], countries: [], states: [], cities: [],
            CountryID: { 'label': '--Select Country--', 'value': '--Select Country--' },
            StateID: { 'label': '--Select State--', 'value': '--Select State--' },
            CityID: { 'label': '--Select City--', 'value': '--Select City--' },
            SearchStateID: { 'label': '--Select State--', 'value': '--Select State--' },
            SearchCityID: { 'label': '--Select City--', 'value': '--Select City--' },
            ParkingLocationID: "", ParkingLocationName: "", Latitude: "", Longitude: "", Address: "",
            StateErrMsg: "", CityErrMsg: "",
            AddLocationVisible: false, iconAdd: "fa fa-plus", isFilterVisible: false, iconFilter: "fa fa-plus",
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'Status', width: '7%', field: 'IsActive', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<BootstrapSwitchButton onlabel='Yes' offlabel='No' onChange={this.onStatusChange(rowData.ParkingLocationID)} checked={rowData.IsActive} size="md" onstyle="success" offstyle="danger" />) } },
                { title: (<div style={{ width: '100%' }}><span>Country Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>State Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>City Name</span></div>), width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CountryName}<hr />{rowData.StateName}<hr />{rowData.CityName}</p>) } },
                { title: 'Company Name', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CompanyName}</p>) } },
                { title: 'Parking Location Name', width: '15%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.ParkingLocationName}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Latitude</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Longitude</span></div>), width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Latitude}<hr />{rowData.Longitude}</p>) } },
                { title: 'Address', width: '30%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Address}</p>) } }
            ]
        }

    }
    componentDidMount() {
        // debugger
        this.GetParkingLocation();
        this.GetCountry();
        this.onCountryChange(1);
        this.setState({ CountryID: { 'label': 'India', 'value': '1' } })

    }
    GetParkingLocation() {
        // debugger;
        var SearchStateID=""
        var SearchCityID=""
        var CompanyID="";
        var ClientID="";

        if (this.state.SearchStateID.value!="--Select State--") {
            SearchStateID=this.state.SearchStateID.value;
        }
        else {
            SearchStateID="";
        }

        if (this.state.SearchCityID.value!="--Select City--") {
            SearchCityID=this.state.SearchCityID.value;
        }
        else {
            SearchCityID="";
        }

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }

        if (CompanyID!="") {
            var data=JSON.stringify({
                "ParkingLocationID": "",
                "CompanyID": CompanyID,
                "StateID": SearchStateID,
                "CityID": SearchCityID,
                "IsActive": "",
                "IsDelete": "false"
            });
            var config={
                method: 'POST',
                url: services.API_URL+"ParkingLoction/getParkingLocation",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                if (response.data.status) {
                    if (this.state.ViewStatus==="ViewStatus:true") {
                        this.setState({ data: response.data.data })
                    }
                    else {
                        this.setState({ data: [] })
                    }
                }
                else {
                    this.setState({ data: [] })
                }

            }, error => { })
        }
        else {
            if (CompanyID=="") {
                Swal.fire({
                    title: 'Please Select Company ', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }
    EditParkingLocation(id) {
        var CompanyID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        this.setState({ AddLocationVisible: true });
        this.setState({ iconAdd: "fa fa-minus" });
        var data=JSON.stringify({
            "ParkingLocationID": id,
            "CompanyID": CompanyID
        });
        var config={
            method: 'POST',
            url: services.API_URL+"ParkingLoction/getParkingLocation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            this.setState({ IsEdit: true });
            var data=JSON.stringify({
                "CountryID": response.data.data[0].CountryID
            });

            var config={
                method: 'POST',
                url: services.API_URL+"State/getState",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                // debugger;

                if (response.data.status) {
                    this.setState({ states: response.data.data.map(item => ({ value: item.StateID, label: item.StateName })) });
                }
                else {

                }
            }, error => { })

            var data=JSON.stringify({
                "StateID": response.data.data[0].StateID
            });

            var config={
                method: 'POST',
                url: services.API_URL+"City/getCity",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                // debugger;
                this.setState({ cities: response.data.data.map(item => ({ value: item.CityID, label: item.CityName })) });
            }, error => { })

            this.setState({
                ParkingLocationID: response.data.data[0].ParkingLocationID,
                CountryID: { 'label': response.data.data[0].CountryName, 'value': response.data.data[0].CountryID },
                StateID: { 'label': response.data.data[0].StateName, 'value': response.data.data[0].StateID },
                CityID: { 'label': response.data.data[0].CityName, 'value': response.data.data[0].CityID },
                ParkingLocationName: response.data.data[0].ParkingLocationName,
                Latitude: response.data.data[0].Latitude,
                Longitude: response.data.data[0].Longitude,
                Address: response.data.data[0].Address
            });
        }, error => { })
    }
    GetCountry() {
        var data=JSON.stringify({
            "CountryID": ""
        });
        var config={
            method: 'POST',
            url: services.API_URL+"Country/getCountry",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            // debugger;
            this.setState({ countries: response.data.data.map(item => ({ value: item.CountryID, label: item.CountryName })) });
        }, error => { })
    }
    onCountryChange=(e) => {
        // debugger;
        this.setState({ CountryID: e });
        if (e.value!="--Select Country--") {
            var data="";
            data=JSON.stringify({
                "CountryID": e.value
            });

            var config={
                method: 'POST',
                url: services.API_URL+"State/getState",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                // debugger;
                var checkstatus=this.state.IsEdit
                if (response.data.status) {
                    // debugger;

                    this.setState({ states: response.data.data.map(item => ({ value: item.StateID, label: item.StateName })) });

                    this.setState({ StateID: { 'label': '--Select State--', 'value': '--Select State--' } })
                    this.setState({ cities: [] })
                    this.setState({ CityID: { 'label': '--Select City--', 'value': '--Select City--' } })
                }
                else {
                    this.setState({ states: [] })
                    this.setState({ StateID: { 'label': '--Select State--', 'value': '--Select State--' } })
                    this.setState({ cities: [] })
                    this.setState({ CityID: { 'label': '--Select City--', 'value': '--Select City--' } })
                }
            }, error => { })
        }
        else {
            this.setState({ CountryID: { 'label': '--Select Country--', 'value': '--Select Country--' } });
        }
    }
    ValidateState=(e) => {
        this.setState({ StateErrMsg: '' })
    }
    ValidateCancelState=(e) => {
        this.setState({ StateErrMsg: 'required' })
    }
    onStateChange=(e) => {
        // debugger;
        if (e!=null) {
            this.setState({ StateID: e }, () => { this.ValidateState() });
            var data="";
            if (e.value!="--Select State--") {
                data=JSON.stringify({
                    "StateID": e.value
                });

                var config={
                    method: 'POST',
                    url: services.API_URL+"City/getCity",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    // debugger;

                    if (response.data.status) {
                        this.setState({ CityID: { 'label': '--Select City--', 'value': '--Select City--' } })
                        this.setState({ cities: response.data.data.map(item => ({ value: item.CityID, label: item.CityName })) });

                    }
                    else {
                        this.setState({ cities: [] })
                        this.setState({ CityID: { 'label': '--Select City--', 'value': '--Select City--' } })
                    }

                }, error => { })
            }
            else {
                this.setState({ StateID: { 'label': '--Select State--', 'value': '--Select State--' } });
            }
        }
        else {
            this.setState({ StateID: { 'label': '--Select State--', 'value': '--Select State--' } }, () => { this.ValidateCancelState() });
            this.setState({ cities: [] })
            this.setState({ CityID: { 'label': '--Select City--', 'value': '--Select City--' } }, () => { this.ValidateCancelCity() })
        }


    }
    ValidateCity=(e) => {
        this.setState({ CityErrMsg: '' })
    }
    ValidateCancelCity=(e) => {
        this.setState({ CityErrMsg: 'required' })
    }
    onCityChange=(e) => {
        if (e!==null) {
            this.setState({ CityID: e }, () => { this.ValidateCity() });
        }
        else {
            this.setState({ CityID: { 'label': '--Select City--', 'value': '--Select City--' } }, () => { this.ValidateCancelCity() });
        }
    }
    onStatusChange=(ParkingLocationID) => (Status) => {
        // e.preventDefault();
        // debugger;
        var IsStatus=Status.toString();
        var data=JSON.stringify({
            "ParkingLocationID": ParkingLocationID,
            "IsActive": IsStatus
        });
        var config={
            method: 'POST',
            url: services.API_URL+"ParkingLoction/setParkingLocationIsActiveStatus",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                //this.setState({ data: response.data.data })
                Swal.fire({
                    title: 'Successfully Updated', icon: "success", timer: 1500
                });
            }
            else {
                Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                // this.setState({ data: [] })
            }
            this.GetParkingLocation();

        }, error => { })
    }
    CheckAlert(id, parkinglocationname) {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                var CompanyID="";
                var ClientID="";
                var UserID="";
                var UserType="";
                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                var data=JSON.stringify({
                    "ParkingLocationID": id,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "ParkingLocationName": parkinglocationname,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+"ParkingLoction/removeParkingLocation",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {

                        this.GetParkingLocation();
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        })
                    } else {
                        Swal.fire({ icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }
    onAddClientClick=(e) => {
        if (this.state.AddLocationVisible==false) {
            this.setState({ AddLocationVisible: true });
            this.setState({ iconAdd: "fa fa-minus" });
        }
        else {
            this.setState({ AddLocationVisible: false });
            this.setState({ iconAdd: "fa fa-plus" });
        }
    }
    OnFilterClick=(e) => {

        e.preventDefault();
        if (this.state.isFilterVisible===false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }

    onSearchStateChange=(e) => {
        // debugger;
        if (e!=null) {
            this.setState({ SearchStateID: e });
            var data="";
            if (e.value!="--Select State--") {
                data=JSON.stringify({
                    "StateID": e.value
                });

                var config={
                    method: 'POST',
                    url: services.API_URL+"City/getCity",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    // debugger;

                    if (response.data.status) {
                        this.setState({ SearchCityID: { 'label': '--Select City--', 'value': '--Select City--' } })
                        this.setState({ cities: response.data.data.map(item => ({ value: item.CityID, label: item.CityName })) });
                    }
                    else {
                        this.setState({ cities: [] })
                        this.setState({ SearchCityID: { 'label': '--Select City--', 'value': '--Select City--' } })
                    }

                }, error => { })
            }
            else {
                this.setState({ SearchStateID: { 'label': '--Select State--', 'value': '--Select State--' } });
            }
        }
        else {
            this.setState({ SearchStateID: { 'label': '--Select State--', 'value': '--Select State--' } });
            this.setState({ cities: [] })
            this.setState({ SearchCityID: { 'label': '--Select City--', 'value': '--Select City--' } })
        }


    }
    onSearchCityChange=(e) => {
        if (e!==null) {
            this.setState({ SearchCityID: e });
        }
        else {
            this.setState({ SearchCityID: { 'label': '--Select City--', 'value': '--Select City--' } });
        }
    }
    onSearchParkingLocationClick=(e) => {
        e.preventDefault();
        this.GetParkingLocation();
    }

    OnSearchCancelParkingLocationClick=() => {
        // debugger;

        this.setState({ SearchStateID: { 'label': '--Select State--', 'value': '--Select State--' } });
        this.setState({ SearchCityID: { 'label': '--Select City--', 'value': '--Select City--' } }, () => {
            this.GetParkingLocation();
        });
        //window.location.reload(true);

    }
    ClearData=(e) => {
        // debugger;
        this.setState({
            states: [], cities: [],
            CountryID: { 'label': '--Select Country--', 'value': '--Select Country--' },
            StateID: { 'label': '--Select State--', 'value': '--Select State--' },
            CityID: { 'label': '--Select City--', 'value': '--Select City--' },
            ParkingLocationID: "", StateErrMsg: "", CityErrMsg: "", ParkingLocationName: "", Latitude: "", Longitude: "", Address: ""
        });

        this.onCountryChange(1);
        this.setState({ CountryID: { 'label': 'India', 'value': '1' } })
        this.form.reset();
    }

    allowOnlyCharacters=(event) => {
        const keyCode=event.keyCode||event.which;
        const keyValue=String.fromCharCode(keyCode);
        if (!new RegExp("^[a-zA-Z ]+$").test(keyValue)) event.preventDefault();
        return;
    };
    allowOnlyNumbers=(event) => {
        const keyCode=event.keyCode||event.which;
        const keyValue=String.fromCharCode(keyCode);
        if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
        return;
    };

    handleSubmit=(e) => {
        // debugger;
        e.preventDefault();

        this.form.validateFields();
        if (this.state.StateID.value=="--Select State--") {
            this.setState({ StateErrMsg: 'required' });
        }

        if (this.state.CityID.value=="--Select City--") {
            this.setState({ CityErrMsg: 'required' });
        }

        if (this.form.isValid()) {
            if (this.props.allCookies.MainCompanyID!==undefined) {
                var CompanyID = "";
                var ClientID="";
                var UserID="";
                var UserType="";
                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                var data="";
                if (this.state.CompanyID!="") {
                    data=JSON.stringify({
                        "ParkingLocationID": this.state.ParkingLocationID,
                        "CompanyID": CompanyID,
                        "ClientID":ClientID,
                        "CountryID": this.state.CountryID.value,
                        "StateID": this.state.StateID.value,
                        "CityID": this.state.CityID.value,
                        "ParkingLocationName": this.state.ParkingLocationName,
                        "Latitude": this.state.Latitude,
                        "Longitude": this.state.Longitude,
                        "Address": this.state.Address,
                        "EntryByUserType": UserType,
                        "EntryByUserID": UserID
                    });
                }
                else {
                    data=JSON.stringify({
                        "CompanyID": this.props.allCookies.MainCompanyID,
                        "CountryID": this.state.CountryID.value,
                        "StateID": this.state.StateID.value,
                        "CityID": this.state.CityID.value,
                        "ParkingLocationName": this.state.ParkingLocationName,
                        "Latitude": this.state.Latitude,
                        "Longitude": this.state.Longitude,
                        "Address": this.state.Address,
                        "EntryByUserType": "",
                        "EntryByUserID": ""
                    });
                }

                var config={
                    method: 'POST',
                    url: services.API_URL+"ParkingLoction/setParkingLocation",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    //// debugger;
                    if (response.data.status) {
                        // debugger;
                        if (this.state.ParkingLocationID!="") {
                            // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                            Swal.fire({
                                title: 'Successfully Updated', icon: "success", timer: 1500
                            });
                        }
                        else {
                            Swal.fire({
                                title: 'Successfully Inserted', icon: "success", timer: 1500
                            });
                        }
                        this.ClearData();
                        this.GetParkingLocation();
                    }
                    else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                }, error => { })
            }
            else {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
        }


    }
    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.data)
    }
    render() {
        const { cookies }=this.props
        // debugger;
        var Permission=cookies.cookies.ParkingLocation;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <div>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row">
                        {this.state.AddLocationVisible&&
                            <div className="col-md-12">
                                {
                                    this.state.AddStatus==="AddStatus:true"&&
                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header">
                                            <h3 className="card-title">Add Parking Location</h3>
                                        </div>
                                        <FormWithConstraints
                                            ref={form => this.form=form}
                                            onSubmit={this.handleSubmit}
                                            noValidate>
                                            <div className="card-body">
                                                <div className="row">

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Country </label>
                                                            <Select options={this.state.countries} value={this.state.CountryID} onChange={this.onCountryChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>State<span className="text-danger">*</span>  </label>
                                                            <Select options={this.state.states} value={this.state.StateID} onChange={this.onStateChange} isClearable={true} />
                                                            {this.state.StateErrMsg&&<span className="text-danger">{this.state.StateErrMsg==='required'? 'Please Select State':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>City <span className="text-danger">*</span> </label>
                                                            <Select options={this.state.cities} value={this.state.CityID} onChange={this.onCityChange} isClearable={true} />
                                                            {this.state.CityErrMsg&&<span className="text-danger">{this.state.CityErrMsg==='required'? 'Please Select City':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Parking Location Name <span className="text-danger">*</span> </label>
                                                            <input type="text" name="ParkingLocationName" required value={this.state.ParkingLocationName} onChange={(e) => this.setState({ ParkingLocationName: e.target.value }, () => { this.form.validateFields(e.target) })} onKeyPress={this.allowOnlyCharacters} className="form-control" placeholder="Enter Parking Location Name" />
                                                            <FieldFeedbacks for="ParkingLocationName">
                                                                <FieldFeedback when="valueMissing">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Latitude </label>
                                                            <input type="text" value={this.state.Latitude} onChange={(e) => this.setState({ Latitude: e.target.value })} className="form-control" placeholder="Enter Latitude" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Longitude </label>
                                                            <input type="text" value={this.state.Longitude} onChange={(e) => this.setState({ Longitude: e.target.value })} className="form-control" placeholder="Enter Longitude" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Address </label>
                                                            <textarea rows={3} name="Address" required className="form-control" value={this.state.Address} onChange={(e) => this.setState({ Address: e.target.value })} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" onSubmit={this.handleSubmit} className="btn btn-primary mr-2">
                                                    Submit
                                                </button>
                                                <button type="button" onClick={this.ClearData} className="btn btn-secondary">
                                                    Cancel
                                                </button>
                                            </div>
                                        </FormWithConstraints>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {this.state.isFilterVisible&&
                        this.state.ViewStatus==="ViewStatus:true"&&
                        <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>State </label>
                                    <Select isClearable={true} options={this.state.states} value={this.state.SearchStateID} onChange={this.onSearchStateChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>City </label>
                                    <Select isClearable={true} options={this.state.cities} value={this.state.SearchCityID} onChange={this.onSearchCityChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button type="button" onClick={this.onSearchParkingLocationClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                <button type="button" onClick={this.OnSearchCancelParkingLocationClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-body">
                                    <MaterialTable columns={this.state.cols} data={this.state.data}
                                        actions={[{
                                            hidden: this.state.UpdateStatus=="UpdateStatus:true"? false:true,
                                            icon: 'edit', tooltip: 'Edit', onClick: (e, r) => this.EditParkingLocation(r.ParkingLocationID)
                                        },
                                        {
                                            hidden: this.state.DeleteStatus=="DeleteStatus:true"? false:true,
                                            icon: 'delete', tooltip: 'Delete', onClick: (e, r) => this.CheckAlert(r.ParkingLocationID,r.ParkingLocationName)
                                        }
                                        ]}
                                        options={{
                                            headerStyle: { color: 'black' }, toolbar: true, search: false,draggable:false,
                                            paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                        }}
                                        components={{

                                            Toolbar: props => (
                                                <div className="row" style={{ marginBottom: '2%' }}>
                                                    <div className="col-md-8 mobpb-5" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h3 className="tabletitle">View Parking Location</h3>

                                                    </div>
                                                    <div className="col-md-4 mobta-left mobpl-0 mobpr-0" style={{ textAlign: 'right' }}>
                                                        {
                                                            this.state.AddStatus==="AddStatus:true"&&
                                                            <a onClick={this.onAddClientClick} className="btn btn-outline-primary font-weight-bolder mr-5 mobmr-1">
                                                                <span className="svg-icon svg-icon-md">
                                                                    <i id="btnAdd" className={this.state.iconAdd} />
                                                                </span>Add Parking Location</a>
                                                        }
                                                        {
                                                            this.state.ViewStatus==="ViewStatus:true"&&
                                                            <>
                                                                <a className="btn btn-outline-dark font-weight-bolder mr-5 mobmr-1" onClick={this.OnFilterClick}>
                                                                    <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                                </a>
                                                                {
                                                                    this.state.ExcelStatus==="ExcelStatus:true"&&
                                                                    <a className="btn btn-outline-success font-weight-bolder" onClick={this.state.data.length>0? this.ExcelClick:''}>
                                                                        <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                                    </a>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withCookies(ParkingLocation);