import React, { Component } from "react";
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import services from "../services/services";
import Swal from "sweetalert2";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { data } from "jquery";
import { ReactFlvPlayer } from 'react-flv-player'
const headerTblStyle = { color: 'black' };


class dashboard extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            isLoading: true,
            ViewStatus: "",
            data: [],
            iconAdd: "fa fa-plus", iconFilter: "fa fa-plus", isFilterVisible: false,
            StartDate: new Date(), EndDate: new Date(),
            // cols: [
            //     { title: 'ZoneName', width: '30%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.ZoneName}</p>) } },
            //     { title: 'CodeName', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CodeName}</p>) } },
            //     { title: 'TaskReceived', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TaskReceived}</p>) } },
            //     { title: 'Assigned', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Assigned}</p>) } },
            //     { title: 'Completed', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Completed}</p>) } },
            //     { title: 'PendingForApproval', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.PendingForApproval}</p>) } },
            //     { title: 'Approved', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Approved}</p>) } },
            //     { title: 'ReferredBack', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.ReferredBack}</p>) } },
            //     { title: 'Finished', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Finished}</p>) } },
            // ]
        }
    }
    componentDidMount() {
        this.GetDashboardReport();
    }

    GetDashboardReport() {
        debugger;
        var CompanyID = "";
        var ClientID = "";
        var StartDate = "";
        var EndDate = "";
        var ZoneID = "";
        var tempPanelUserType = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.ZoneIDs !== undefined) {
            ZoneID = this.props.allCookies.ZoneIDs
        }
        // if (this.state.ZoneID != null) {
        //     var tempzoneid = this.state.ZoneID.map(JSON.parse);
        //     ZoneID = tempzoneid.slice(0, -1)
        // }
        var dateString = this.state.StartDate;
        var dateMomentObject = moment(dateString, "DD-MM-YYYY"); // 1st argument - string, 2nd argument - format
        var date = dateMomentObject.toDate();

        if (CompanyID != "" && ClientID != "") {
            // debugger;
            // var data = JSON.stringify({
            //     // "BookingTypeID": "",
            //     "CompanyID": CompanyID,
            //     "ClientID": ClientID,
            //     "StartDate": date,
            //     "EndDate": this.state.EndDate,
            //     "ZoneID": ZoneID
            // });

            if (tempPanelUserType == "Admin") {
                data = JSON.stringify({
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "StartDate": date,
                    "EndDate": this.state.EndDate
                });
            } else {
                var data = JSON.stringify({
                    // "BookingTypeID": "",
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "StartDate": date,
                    "EndDate": this.state.EndDate,
                    "ZoneID": ZoneID
                });
            }

            var config = {
                method: 'POST',
                url: services.API_URL + "Report/getDashboardReport",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                // debugger;
                if (response.data.status) {
                    this.setState({ data: response.data.data })
                }
                else {
                    this.setState({ data: [] })
                }

            }, error => { })
        }
        else {
            if (CompanyID == "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID != "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID == "" && ClientID != "") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }
    }
    ShowCamera1 = () => e => {
        e.preventDefault();
        window.$(this.Camera1Modal).modal('show');
    }
    onSearchDashboardReportClick = (e) => {
        e.preventDefault();
        this.GetDashboardReport();
    }
    OnSearchCancelDashboardReportClick = () => {
        //// debugger;
        this.setState({
            StartDate: new Date(), EndDate: new Date()
        }, () => {
            this.GetDashboardReport();
        });

    }
    onStartDateChange = (e) => {
        this.setState({ StartDate: e });
        this.setState({ EndDate: e });
    }

    OnFilterClick = (e) => {
        e.preventDefault();
        if (this.state.isFilterVisible === false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }

    render() {
        const { cookies } = this.props
        //// debugger;
        var Permission = cookies.cookies.Dashboard;
        var TotalPermission = "";
        if (Permission !== undefined) {
            TotalPermission = Permission.split(',');
            if (this.state.ViewStatus == "") {
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <div>
                {
                    this.state.ViewStatus === "ViewStatus:true" &&
                    <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                        <div className="row">
                            {(this.props.allCookies.Dashboard !== undefined) ? (
                                <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                    <NavLink to={"/dashboard"} className="btn btn-primary font-weight-bolds">Statistics
                                    </NavLink>
                                </div>) : ''}
                            {(this.props.allCookies.GudmDashboard !== undefined) ? (
                                <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                    {/* <NavLink to={"/gudmdashboard"} className="btn btn-outline-primary font-weight-bolds">Gudm
                                    </NavLink> */}
                                    <NavLink to={"/gudmdashboard"} className="btn btn-outline-primary font-weight-bolds">Tasks
                                    </NavLink>
                                </div>) : ''}
                            {(this.props.allCookies.GudmSummary !== undefined) ? (
                                <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                    {/* <NavLink to={"/gudmsummary"} className="btn btn-outline-primary font-weight-bolds">Gudm Summary
                                    </NavLink> */}
                                    <NavLink to={"/gudmsummary"} className="btn btn-outline-primary font-weight-bolds">Tasks Summary
                                    </NavLink>
                                </div>) : ''}
                            {(this.props.allCookies.WorkInProgressDashboard !== undefined) ? (
                                <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                    <NavLink to={"/vehicledashboard"} className="btn btn-outline-primary font-weight-bolds">Work In Progress
                                    </NavLink>
                                </div>) : ''}
                            <div className="col-md-3" style={{ maxWidth: 'fit-content' }}>
                                {/* <button type="button" className="btn btn-outline-primary font-weight-bolds" onClick={this.ShowCamera1()}>
                                    Live Streaming
                                </button> */}
                                {/* <a className="btn btn-outline-primary font-weight-bolds" target="_blank" href="http://mwhdia.in:3073/">
                                    Live Streaming
                                </a> */}
                            </div>
                            {/* <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                <NavLink to={"/livegps"} className="btn btn-outline-primary font-weight-bolds">Live GPS Tracking
                                </NavLink>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-3 offset-md-9 mobmt-1" style={{ marginBottom: '1%', textAlign: 'right' }}>
                                <a className="btn btn-outline-dark font-weight-bolder" onClick={this.OnFilterClick}>
                                    <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                </a>
                                {/* <button type="button" className="btn btn-primary" >
                                Filter <i id="btnFilter" className="fa fa-plus" />
                            </button> */}
                            </div>
                        </div>
                        {this.state.isFilterVisible &&
                            <div className="row" style={{ marginBottom: '1%', }} id="divFilter">
                                <div className="col-md-3">
                                    <div className="form-group formgroupcss">
                                        <label>Start Date </label>
                                        <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.StartDate} onChange={this.onStartDateChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group formgroupcss">
                                        <label>End Date </label>
                                        <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.EndDate} minDate={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.EndDate} onChange={(e) => {
                                            this.setState({ EndDate: e });
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <button type="button" onClick={this.onSearchDashboardReportClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search</button>
                                    <button type="button" onClick={this.OnSearchCancelDashboardReportClick} className="btn btn-danger mt-12 mobmt-1">Cancel</button>
                                </div>
                                {/* <div className="col-md-3">
                            <button type="button" className="btn btn-primary mt-8">Search </button>
                            <button type="button" className="btn btn-danger mt-8">Cancel </button>
                        </div> */}
                            </div>
                        }


                        {/* data={this.state.data} */}
                        {/* {this.state.data != null && */}
                        {/* if(this.state){
                            return ( */}
                        {/* render: ({rowData}) => {
                                return( */}
                        <div className="row">
                            {
                                this.state.data.map((value, inx1) => {
                                    return (
                                        <div className="col-md-4">
                                            {/* <span></span> */}
                                            <div className="card card-custom gutter-b card-stretch">
                                                <div className="card-header border-0">
                                                    <div className="card-title font-weight-bolder">
                                                        <div className="card-label">
                                                            {value.ZoneName}
                                                            <div className="font-size-sm text-muted mt-2">Task Details</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body pt-0">
                                                    <div className="d-flex align-items-center flex-wrap mb-2">
                                                        <div className="symbol symbol-50 symbol-light mr-5">
                                                            <span className="symbol-label">
                                                                {/* <i class="fa fa-list"></i> */}
                                                                <img src="Images/booking.png" className="h-50 align-self-center" />
                                                            </span>
                                                        </div>
                                                        <div className="d-flex flex-column flex-grow-1 mr-2">
                                                            <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Task
                                                                Received</a>
                                                        </div>
                                                        <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">{value.TaskReceived}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-wrap mb-2">
                                                        <div className="symbol symbol-50 symbol-light mr-5">
                                                            <span className="symbol-label">
                                                                <img src="https://preview.keenthemes.com/metronic/theme/html/demo7/dist/assets/media/svg/misc/015-telegram.svg" className="h-50 align-self-center" />
                                                            </span>
                                                        </div>
                                                        <div className="d-flex flex-column flex-grow-1 mr-2 my-2">
                                                            <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Assigned</a>
                                                        </div>
                                                        <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">{value.Assigned}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-wrap mb-2">
                                                        <div className="symbol symbol-50 symbol-light mr-5">
                                                            <span className="symbol-label">
                                                                <img src="Images/finish.png" className="h-50 align-self-center" />
                                                            </span>
                                                        </div>
                                                        <div className="d-flex flex-column flex-grow-1 mr-2 my-2">
                                                            <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Finished</a>
                                                        </div>
                                                        <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">{value.Finished}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-wrap mb-2">
                                                        <div className="symbol symbol-50 symbol-light mr-5">
                                                            <span className="symbol-label">
                                                                <img src="Images/complete.png" className="h-50 align-self-center" />
                                                            </span>
                                                        </div>
                                                        <div className="d-flex flex-column flex-grow-1 mr-2 my-2">
                                                            <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Completed</a>
                                                        </div>
                                                        <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">{value.Completed}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-wrap mb-2">
                                                        <div className="symbol symbol-50 symbol-light mr-5">
                                                            <span className="symbol-label">
                                                                <img src="Images/pending.png" className="h-50 align-self-center" />
                                                            </span>
                                                        </div>
                                                        <div className="d-flex flex-column flex-grow-1 mr-2 my-2">
                                                            <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Pending
                                                                for Approval</a>
                                                        </div>
                                                        <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">{value.PendingForApproval}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-wrap mb-2">
                                                        <div className="symbol symbol-50 symbol-light mr-5">
                                                            <span className="symbol-label">
                                                                <img src="Images/approved4.png" className="h-50 align-self-center" />
                                                            </span>
                                                        </div>
                                                        <div className="d-flex flex-column flex-grow-1 mr-2 my-2">
                                                            <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Approved</a>
                                                        </div>
                                                        <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">{value.Approved}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-wrap">
                                                        <div className="symbol symbol-50 symbol-light mr-5">
                                                            <span className="symbol-label">
                                                                <img src="Images/referral.png" className="h-50 align-self-center" />
                                                            </span>
                                                        </div>
                                                        <div className="d-flex flex-column flex-grow-1 mr-2 my-2">
                                                            <a href="#" className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1">Referred
                                                                Back</a>
                                                        </div>
                                                        <span className="label label-xl label-light label-inline my-lg-0 my-2 text-dark-50 font-weight-bolder">{value.ReferredBack}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                })
                            }
                        </div>
                        {/* )
                            } */}

                        {/* )} */}
                        {/* } */}
                    </div>
                }
                <div className="modal fade" ref={modal => this.Camera1Modal = modal} id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog mapdialog" role="document">
                        <div className="modal-content" style={{ height: '700px' }}>
                            <div className="modal-body" style={{ paddingLeft: '0', paddingTop: '0', paddingRight: '0' }}>
                                <div className="row" style={{ display: 'block', width: '100%', marginLeft: '0', marginRight: '0' }}>
                                    <ReactFlvPlayer
                                        url="http://14.99.68.5:12060/live.flv?devid=00BD001497&chl=2&svrid=127.0.0.1&svrport=17891&st=1&audio=1"
                                        height="100%"
                                        width="100%"
                                        isMuted={true}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >

        );
    }
}

export default withCookies(dashboard)