import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { WithRouter } from "react-router";
import { connect } from "react-redux";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import MultiSelect from "@khanacademy/react-multi-select";
import Select, { components } from 'react-select'
import '../style.css';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import { instanceOf } from 'prop-types';
import DatePicker from 'react-datepicker';
import { withCookies, Cookies } from 'react-cookie';
import XLSX from "xlsx-color";
import { Map, GoogleApiWrapper, Marker, Polyline, GoogleMap, withScriptjs, withGoogleMap, InfoWindow } from 'google-maps-react';
import moment from 'moment';
import LoadingSpinner from "./LoadingSpinner.component";
const mapStyles={
    width: '100%',
    height: '100%',
};
let map;
const exportToExcel=(columns, data) => {
    debugger;
    const header=["Sr.No", "BookingDate", "MuncipalityName", "VehicleNo", "VehicleReachedLocationDateTime", "Shift", "BookingStartLatitude", "BookingStartLongitude", "BookingStartAddress"];
    const headertemp=["Sr.No", "Date", "Muncipality Name", "Vehicle No", "Start Date & Time", "Shift", "Latitude", "Longitude", "Address"];
    //debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else if (col=="BookingDate") {
                obj["Date"]=row[col];
            }
            else if (col=="MuncipalityName") {
                obj["Muncipality Name"]=row[col];
            }
            else if (col=="VehicleNo") {
                obj["Vehicle No"]=row[col];
            }
            else if (col=="VehicleReachedLocationDateTime") {
                obj["Start Date & Time"]=row[col];
            }
            else if (col=="Shift") {
                obj["Shift"]=row[col];
            }
            else if (col=="BookingStartLatitude") {
                obj["Latitude"]=row[col];
            }
            else if (col=="BookingStartLongitude") {
                obj["Longitude"]=row[col];
            }
            else if (col=="BookingStartAddress") {
                obj["Address"]=row[col];
            }
            else {
                obj[col]=row[col];
            }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };


    ws1['!cols']=[
        { width: 6 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },


    ];

    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: false, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;
    ws1['D1'].s=cell;
    ws1['E1'].s=cell;
    ws1['F1'].s=cell;
    ws1['G1'].s=cell;
    ws1['H1'].s=cell;
    ws1['I1'].s=cell;




    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;
        ws1['D'+ins].s=RowCont;
        ws1['E'+ins].s=RowCont;
        ws1['F'+ins].s=RowCont;
        ws1['G'+ins].s=RowCont;
        ws1['H'+ins].s=RowCont;
        ws1['I'+ins].s=RowCont;


        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "OverAllMapList");
    XLSX.writeFile(wb, "OverAllMapList_"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}
const cameraOptions={
    tilt: 0,
    heading: 0,
    zoom: 3,
    center: { lat: 35.6594945, lng: 139.6999859 },
};
const mapOptions=Object.assign(Object.assign({}, cameraOptions), { mapId: "15431d2b469f209e" });
const headerTblStyle={ color: 'black' };
class OverAllMap extends Component {
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.tableRef=React.createRef();
        this.state={
            isLoading: false,
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [], vehicledata: [], TableData: [], StartDate: new Date(), EndDate: new Date(),
            currentLocation: [], DataBind: [], LiveLocation: "", LiveLocation: "", VehicleErrMsg: "",
            faicon: "fa fa-plus", isFilterVisible: true, iconFilter: "fa fa-minus", NoDataAvailable: false,
            MapTaskNo: "", MapTaskDate: "", MapMuniciplaity: "", MapLocation: "", WaitMsg: false, isCancel: false,
            zones: [], districts: [], municipalities: [], wards: [], IsDisable: true, CheckedRows: [],
            MapVehicleNo: "", vehicles: [], ZoneID: [], DistrictID: [], MunicipalityID: [], WardID: [],
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'Date', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left' }}>{rowData.BookingDate}</p>) } },
                { title: 'Municipality', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left' }}>{rowData.MuncipalityName}</p>) } },
                { title: 'Vehicle No', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left' }}>{rowData.VehicleNo}</p>) } },
                { title: 'Start Date & Time', width: '15%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left' }}>{rowData.VehicleReachedLocationDateTime}</p>) } },
                { title: 'Shift', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left' }}>{rowData.Shift}</p>) } },
                { title: 'Latitude', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left' }}>{(rowData.BookingStartLatitude=="null")? '':rowData.BookingStartLatitude}</p>) } },
                { title: 'Longitude', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left' }}>{(rowData.BookingStartLongitude=="null")? '':rowData.BookingStartLongitude}</p>) } },
                { title: 'Address', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left' }}>{rowData.BookingStartAddress}</p>) } },
            ]
        }
    }
    componentDidMount() {
        //  this.GetOverAllMapReport();
        this.GetZone();
        this.GetDistrict();
        this.GetMunicipality();
        this.GetWard();
        this.GetVehicle();
        // this.GetTableData();
    }
    GetTableData() {
        debugger;
        var allvalid=true;
        var CompanyID="";
        var ClientID="";
        var ZoneID="";
        var DistrictID="";
        var MunicipalityID="";
        var WardID="";
        var VehicleID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.state.ZoneID!=null) {
            var tempZoneID=this.state.ZoneID.map(

                item => (
                    ZoneID+=item+","
                )
            );
            ZoneID=ZoneID.slice(0, -1)
        }
        if (this.state.DistrictID!=null) {
            var tempDistrictID=this.state.DistrictID.map(

                item => (
                    DistrictID+=item+","
                )
            );
            DistrictID=DistrictID.slice(0, -1)
        }
        if (this.state.MunicipalityID!=null) {
            var tempMunicipalityID=this.state.MunicipalityID.map(

                item => (
                    MunicipalityID+=item+","
                )
            );
            MunicipalityID=MunicipalityID.slice(0, -1)
        }
        if (this.state.WardID!=null) {
            var tempWardID=this.state.WardID.map(

                item => (
                    WardID+=item+","
                )
            );
            WardID=WardID.slice(0, -1)
        }


        if (this.state.VehicleID.value!="--Select Vehicle--") {
            VehicleID=this.state.VehicleID.value;
        }


        if (this.state.VehicleID.value=="--Select Vehicle--") {
            if (this.state.isCancel==false) {
                this.setState({ VehicleErrMsg: 'required' });
            }
            else {
                this.setState({ VehicleErrMsg: '' }, () => { console.log("data") });
            }
            this.setState({ isCancel: false });
            allvalid=false;
        }
        if (allvalid==true) {
            var data=JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID,
                ZoneID: ZoneID,
                DistrictID: DistrictID,
                MuncipalityID: MunicipalityID,
                WardID: WardID,
                VehicleID: VehicleID,
                StartDate: moment(this.state.StartDate, "DD-MM-YYYY"),
                EndDate: moment(this.state.EndDate, "DD-MM-YYYY"),
                // StartDate: "12-04-2023",
                // EndDate: "12-04-2023",
            });

            var config={
                method: 'POST',
                url: services.API_URL+"Report/getOverAllMapReportBookingData",
                //url: "http://192.168.0.119:1342/api/LocationDevice/getLocationDeviceInformation",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            services.JsonValue(config).then(response => {
                //debugger;
                if (response.data.status) {

                    this.setState({ TableData: response.data.data });
                }
                else {
                    this.setState({ TableData: [] });
                }
            });
        }
        else {
            //this.setState({ VehicleErrMsg: "" });
            this.setState({ TableData: [] });
        }
    }
    GetOverAllSelectedMapReport=() => e => {
        debugger;
        e.preventDefault();
        var allvalid=true;
        this.setState({});
        this.setState({ isLoading: true }, () => { console.log("data") })
        this.setState({ WaitMsg: true });
        // this.setState({ currentLocation: [] });
        this.setState({ LiveLocation: "" });
        // const clonedState=this.state;
        // const keys=Object.keys(clonedState);
        // keys.map((value, index) => {
        //     var abc = "";
        // })

        // this.setState({})
        var CompanyID="";
        var ClientID="";
        var ZoneID="";
        var DistrictID="";
        var MunicipalityID="";
        var WardID="";
        var VehicleID="";
        var BookingID="";

        this.state.CheckedRows.map((value, index) => {
            BookingID+=value.BookingID+",";
        });
        BookingID=BookingID.slice(0, -1);


        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.state.ZoneID!=null) {
            var tempZoneID=this.state.ZoneID.map(

                item => (
                    ZoneID+=item+","
                )
            );
            ZoneID=ZoneID.slice(0, -1)
        }
        if (this.state.DistrictID!=null) {
            var tempDistrictID=this.state.DistrictID.map(

                item => (
                    DistrictID+=item+","
                )
            );
            DistrictID=DistrictID.slice(0, -1)
        }
        if (this.state.MunicipalityID!=null) {
            var tempMunicipalityID=this.state.MunicipalityID.map(

                item => (
                    MunicipalityID+=item+","
                )
            );
            MunicipalityID=MunicipalityID.slice(0, -1)
        }
        if (this.state.WardID!=null) {
            var tempWardID=this.state.WardID.map(

                item => (
                    WardID+=item+","
                )
            );
            WardID=WardID.slice(0, -1)
        }


        if (this.state.VehicleID.value!="--Select Vehicle--") {
            VehicleID=this.state.VehicleID.value;
        }


        if (this.state.VehicleID.value=="--Select Vehicle--") {
            this.setState({ VehicleErrMsg: 'required' });
            allvalid=false;
        }
        if (allvalid==true) {
            var data=JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID,
                BookingID: BookingID,
                ZoneID: ZoneID,
                DistrictID: DistrictID,
                MuncipalityID: MunicipalityID,
                WardID: WardID,
                VehicleID: VehicleID,
                StartDate: moment(this.state.StartDate, "DD-MM-YYYY"),
                EndDate: moment(this.state.EndDate, "DD-MM-YYYY"),
                // StartDate: "12-04-2023",
                // EndDate: "12-04-2023",
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Report/getOverAllMapReportWithCoordinatesData",
                //url: "http://192.168.0.119:1342/api/LocationDevice/getLocationDeviceInformation",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                if (response.data.status) {
                    var cnt=0;
                    window.$(this.MapModal).modal('show');
                    this.setState({ NoDataAvailable: false });
                    this.setState({ data: response.data.data });

                    var checkdata=[];
                    
                    response.data.data.map((value, index) => {
                        var abc = value.DeviceLocationDetail.includes("error");
                        if (value.DeviceLocationDetail!=null&&value.DeviceLocationDetail.length>0 && abc == false) {
                            checkdata=[];
                            cnt=cnt+1;
                            this.setState({
                                ["currentLocation"+index]: response.data.data[index].DeviceLocationDetail
                            })
                            this.setState({
                                ["currentLocation"]: response.data.data[index].DeviceLocationDetail
                            })
                            value.DeviceLocationDetail.map((items, idx) => {

                                checkdata.push(
                                    { lat: parseFloat(items.latitude), lng: parseFloat(items.langitude) }
                                )
                            })

                            var last=parseInt(checkdata.length)-1;

                            this.setState({ ["Lastlong"+index]: last });
                            this.setState({ ["parentLocation"+index]: checkdata });
                            const path=JSON.stringify(checkdata);
                            let final=path.replace(/,/g, ',')
                            final=final.replaceAll("\"", "");
                            this.setState({ ["DataBind"]: checkdata })
                            this.setState({ ["DataBind"+index]: checkdata })
                            this.setState({ ["LiveLocation"+index]: checkdata[0] });
                            this.setState({ LiveLocation: checkdata[0] });
                            // this.setState({ LiveLocation: { lat: 23.0204741, lng: 72.4149305 } });

                        }



                    })
                    if (cnt==0) {
                        this.setState({ NoDataAvailable: true });
                        Swal.fire({ position: 'top-end', toast: true, title: 'No Tracking Data Found', icon: "error", showConfirmButton: false, timer: 1500 })
                    }
                    else {
                        this.setState({ NoDataAvailable: false });

                    }
                    this.setState({ WaitMsg: false });
                    this.setState({ isLoading: false })
                    //this.setState({ pntmap: "a" });




                }
                else {
                    debugger;
                    const clonedState=this.state;
                    const keys=Object.keys(clonedState);
                    keys.map((value, index) => {
                        if (value=="data") {
                            clonedState[value]=[]
                        }
                        else if (value=="StartDate") {
                            //  clonedState[value] = new Date()
                        }
                        else if (value=="EndDate") {
                            // clonedState[value] = new Date()
                        }
                        else if (value=="NoDataAvailable") {
                            clonedState[value]=true
                        }
                        else if (value=="DataBind") {
                            clonedState[value]=[]
                        }
                        else if (value=="faicon") {
                            //  clonedState[value] = "fa fa-plus"
                        }
                        else if (value=="isFilterVisible") {
                            // clonedState[value] = false
                        }
                        else if (value=="iconFilter"||value=="zones"||value=="districts"||value=="municipalities"||value=="wards"||value=="vehicles"
                            ||value=="ZoneID"||value=="DistrictID"||value=="MunicipalityID"||value=="WardID"||value=="VehicleID"||value=="MuncipalityID") {
                            //   clonedState[value] = "fa fa-plus"
                        }
                        else {
                            clonedState[value]=""
                        }
                        // var abc = clonedState[value]

                    });


                    this.setState(clonedState);
                    this.setState({ WaitMsg: false });
                    this.setState({ NoDataAvailable: true });
                    this.setState({ isLoading: false })
                    this.setState({ LiveLocation: { lat: 23.0204741, lng: 72.4149305 } });
                    //this.setState({ LiveLocation: "" });
                    this.setState({ currentLocation: [] });
                    Swal.fire({ position: 'top-end', toast: true, title: 'No Tracking Data Found', icon: "error", showConfirmButton: false, timer: 1500 })
                }



            }, error => { })
        }
        else {
            this.setState({ WaitMsg: false });
            this.setState({ isLoading: false })
        }

    }
    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.TableData)
    }
    GetOverAllMapReport() {
        debugger;
        var allvalid=true;
        this.setState({});
        this.setState({ isLoading: true }, () => { console.log("data") })
        this.setState({ WaitMsg: true });
        // this.setState({ currentLocation: [] });
        this.setState({ LiveLocation: "" });
        // const clonedState=this.state;
        // const keys=Object.keys(clonedState);
        // keys.map((value, index) => {
        //     var abc = "";
        // })

        // this.setState({})
        var CompanyID="";
        var ClientID="";
        var ZoneID="";
        var DistrictID="";
        var MunicipalityID="";
        var WardID="";
        var VehicleID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.state.ZoneID!=null) {
            var tempZoneID=this.state.ZoneID.map(

                item => (
                    ZoneID+=item+","
                )
            );
            ZoneID=ZoneID.slice(0, -1)
        }
        if (this.state.DistrictID!=null) {
            var tempDistrictID=this.state.DistrictID.map(

                item => (
                    DistrictID+=item+","
                )
            );
            DistrictID=DistrictID.slice(0, -1)
        }
        if (this.state.MunicipalityID!=null) {
            var tempMunicipalityID=this.state.MunicipalityID.map(

                item => (
                    MunicipalityID+=item+","
                )
            );
            MunicipalityID=MunicipalityID.slice(0, -1)
        }
        if (this.state.WardID!=null) {
            var tempWardID=this.state.WardID.map(

                item => (
                    WardID+=item+","
                )
            );
            WardID=WardID.slice(0, -1)
        }


        if (this.state.VehicleID.value!="--Select Vehicle--") {
            VehicleID=this.state.VehicleID.value;
        }


        if (this.state.VehicleID.value=="--Select Vehicle--") {
            this.setState({ VehicleErrMsg: 'required' });
            allvalid=false;
        }
        if (allvalid==true) {
            var data=JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID,
                ZoneID: ZoneID,
                DistrictID: DistrictID,
                MuncipalityID: MunicipalityID,
                WardID: WardID,
                VehicleID: VehicleID,
                StartDate: moment(this.state.StartDate, "DD-MM-YYYY"),
                EndDate: moment(this.state.EndDate, "DD-MM-YYYY"),
                // StartDate: "12-04-2023",
                // EndDate: "12-04-2023",
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Report/getOverAllMapReportNew",
                //url: "http://192.168.0.119:1342/api/LocationDevice/getLocationDeviceInformation",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                if (response.data.status) {
                    var cnt=0;

                    this.setState({ NoDataAvailable: false });
                    this.setState({ data: response.data.data });

                    var checkdata=[];
                    response.data.data.map((value, index) => {
                        var abc = value.DeviceLocationDetail.includes("error");
    
                        if (value.DeviceLocationDetail!=null&&value.DeviceLocationDetail.length>0 && abc == false) {
                            checkdata=[];
                            cnt=cnt+1;
                            this.setState({
                                ["currentLocation"+index]: response.data.data[index].DeviceLocationDetail
                            })
                            this.setState({
                                ["currentLocation"]: response.data.data[index].DeviceLocationDetail
                            })
                            value.DeviceLocationDetail.map((items, idx) => {

                                checkdata.push(
                                    { lat: parseFloat(items.latitude), lng: parseFloat(items.langitude) }
                                )
                            })

                            var last=parseInt(checkdata.length)-1;

                            this.setState({ ["Lastlong"+index]: last });
                            this.setState({ ["parentLocation"+index]: checkdata });
                            const path=JSON.stringify(checkdata);
                            let final=path.replace(/,/g, ',')
                            final=final.replaceAll("\"", "");
                            this.setState({ ["DataBind"]: checkdata })
                            this.setState({ ["DataBind"+index]: checkdata })
                            this.setState({ ["LiveLocation"+index]: checkdata[0] });
                            this.setState({ LiveLocation: checkdata[0] });
                            // this.setState({ LiveLocation: { lat: 23.0204741, lng: 72.4149305 } });

                        }



                    })
                    if (cnt==0) {
                        this.setState({ NoDataAvailable: true });
                        Swal.fire({ position: 'top-end', toast: true, title: 'No Tracking Data Found', icon: "error", showConfirmButton: false, timer: 1500 })
                    }
                    else {
                        this.setState({ NoDataAvailable: false });

                    }
                    this.setState({ WaitMsg: false });
                    this.setState({ isLoading: false })
                    //this.setState({ pntmap: "a" });




                }
                else {
                    debugger;
                    const clonedState=this.state;
                    const keys=Object.keys(clonedState);
                    keys.map((value, index) => {
                        if (value=="data") {
                            clonedState[value]=[]
                        }
                        else if (value=="StartDate") {
                            //  clonedState[value] = new Date()
                        }
                        else if (value=="EndDate") {
                            // clonedState[value] = new Date()
                        }
                        else if (value=="NoDataAvailable") {
                            clonedState[value]=true
                        }
                        else if (value=="DataBind") {
                            clonedState[value]=[]
                        }
                        else if (value=="faicon") {
                            //  clonedState[value] = "fa fa-plus"
                        }
                        else if (value=="isFilterVisible") {
                            // clonedState[value] = false
                        }
                        else if (value=="iconFilter"||value=="zones"||value=="districts"||value=="municipalities"||value=="wards"||value=="vehicles"
                            ||value=="ZoneID"||value=="DistrictID"||value=="MunicipalityID"||value=="WardID"||value=="VehicleID"||value=="MuncipalityID") {
                            //   clonedState[value] = "fa fa-plus"
                        }
                        else {
                            clonedState[value]=""
                        }
                        // var abc = clonedState[value]

                    });


                    this.setState(clonedState);
                    this.setState({ WaitMsg: false });
                    this.setState({ NoDataAvailable: true });
                    this.setState({ isLoading: false })
                    this.setState({ LiveLocation: { lat: 23.0204741, lng: 72.4149305 } });
                    //this.setState({ LiveLocation: "" });
                    this.setState({ currentLocation: [] });
                    Swal.fire({ position: 'top-end', toast: true, title: 'No Tracking Data Found', icon: "error", showConfirmButton: false, timer: 1500 })
                }



            }, error => { })
        }
        else {
            this.setState({ WaitMsg: false });
            this.setState({ isLoading: false })
        }

    }
    GetZone() {
        var CompanyID="";
        var ClientID="";
        var UserID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        var data="";
        data=JSON.stringify({
            ZoneID: "",
            CompanyID: CompanyID,
            ClientID: ClientID
        });

        var config={
            method: 'POST',
            url: services.API_URL+"Zone/getZoneDropDownData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ zones: response.data.data.map(item => ({ value: item.ZoneID, label: item.ZoneName })) });
                this.setState({ ZoneID: [] })

            }
            else {
                this.setState({ zones: [] })
            }
        }, error => { })
    }
    GetDistrict() {
        var CompanyID="";
        var ClientID="";
        var UserID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID!==undefined) {
            UserID=this.props.allCookies.UserID
        }
        var data="";
        data=JSON.stringify({
            "DistrictID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "ZoneID": ""
        });

        var config={
            method: 'POST',
            url: services.API_URL+"District/getDistrictDropDownData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ districts: response.data.data.map(item => ({ value: item.DistrictID, label: item.DistrictName })) });
                this.setState({ DistrictID: [] })

            }
            else {
                this.setState({ districts: [] })
            }
        }, error => { })
    }
    GetMunicipality() {
        var CompanyID="";
        var ClientID="";
        var UserID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        var data="";
        data=JSON.stringify({
            "MuncipalityID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "DistrictID": "",
            "ZoneID": ""
        });

        var config={
            method: 'POST',
            url: services.API_URL+"Muncipality/getMuncipalityDropDownData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ municipalities: response.data.data.map(item => ({ value: item.MuncipalityID, label: item.MuncipalityName })) });
                this.setState({ MuncipalityID: [] })

            }
            else {
                this.setState({ municipalities: [] })
            }
        }, error => { })
    }
    GetWard() {
        var CompanyID="";
        var ClientID="";
        var UserID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        var data="";
        data=JSON.stringify({
            "WardID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "MuncipalityID": ""
        });

        var config={
            method: 'POST',
            url: services.API_URL+"Ward/getWard",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ wards: response.data.data.map(item => ({ value: item.WardID, label: item.WardName })) });
                this.setState({ WardID: [] })

            }
            else {
                this.setState({ wards: [] })
            }
        }, error => { })
    }
    GetVehicle() {
        var CompanyID="";
        var UserID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }

        var data="";
        data=JSON.stringify({
            "VehicleID": "",
            "CompanyID": CompanyID,
            "IsActive": "true",
            "IsDelete": "false"
        });

        var config={
            method: 'POST',
            url: services.API_URL+"Vehicle/getVehicle",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ vehicles: response.data.data.map(item => ({ value: item.VehicleID, label: item.VehicleNo })) });


            }
            else {
                this.setState({ vehicles: [] })
            }
        }, error => { })
    }
    onStartDateChange=(e) => {
        this.setState({ StartDate: e });
        this.setState({ EndDate: e });
    }
    onZoneChange=(value) => {
        ////debugger;
        this.setState({ ZoneID: value });

    }
    onDistrictChange=(value) => {
        ////debugger;
        this.setState({ DistrictID: value });

    }
    onMunicipalityChange=(value) => {
        ////debugger;
        this.setState({ MunicipalityID: value });
    }
    onWardChange=(value) => {
        ////debugger;
        this.setState({ WardID: value });
    }

    ValidateVehicle=(e) => {
        this.setState({ VehicleErrMsg: '' })
    }
    ValidateCancleVehicle=(e) => {
        this.setState({ VehicleErrMsg: 'required' })
    }
    onVehicleChange=(e) => {
        if (e!==null) {
            this.setState({ VehicleID: e }, (e) => { this.ValidateVehicle(); })
        }
        else {
            this.setState({ VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' } }, () => { this.ValidateCancleVehicle() });
        }
    }
    markerClicked(props, marker, activemarker) {
        debugger;
        //e.preventDefault();
        this.setState({
            showInfoWindow: true,
            activeMarker: activemarker,
        });
        debugger;


        this.setState({ MapTaskNo: props.BookingNo });
        this.setState({ MapTaskDate: props.BookingDate });
        this.setState({ MapMuniciplaity: props.MuncipalityName });
        this.setState({ MapLocation: props.Locality });
        this.setState({ MapVehicleNo: props.VehicleNo });
        // this.setState({ MapLatitude: props.Latitude })
        // this.setState({ MapLongitude: props.Longitude })
        // this.setState({ MapRemarks: props.Remarks });
        // debugger;
        // var checklistdetail=props;
        // if (checklistdetail.BookingDefectImageDetail!=null) {
        //     this.setState({ MapImages: checklistdetail.BookingDefectImageDetail });
        // }

        // if (checklistdetail.BookingDefectCheckListDetail!=null) {
        //     var check="";
        //     checklistdetail.BookingDefectCheckListDetail.map((value, index) => {
        //         check+=value.CheckListTitle+",";
        //     })
        //     check=check.slice(0, -1);
        //     this.setState({ MapChecklistDetail: check });

        //     //this.setState({DefectLocation:checklistdetail});
        // }
    }
    showMarkers=() => {
        debugger;
        // this.state.data.map((value, indexs) => {
        // return <Marker
        //     icon={
        //         { url: "/Images/locationreach.png" }

        //     }

        //     // label={{
        //     //     text: (index==0)? 'Start':(index==this.state.Lastlong)? 'Last':''
        //     //   }}
        //     // title={(index==0)? 'Start':(index==this.state.Lastlong)? 'Last':''}
        //     // label={
        //     //     (index==0)? 'Start':(index==this.state.Lastlong)? 'Last':''
        //     // }
        //     // key={index} id={index} position={
        //     //     ((index==0)||(index==this.state["Lastlong"+indexs]))? {
        //     //         lat: store.GpsLat,
        //     //         lng: store.GpsLng
        //     //     }
        //     key={0} id={0} position={
        //         // ((index==0)||(index==this.state["Lastlong"+indexs]))? {
        //         //     lat: store.GpsLat,
        //         //     lng: store.GpsLng
        //         // }

        //         //     :''
        //         {
        //             lat: 23.23954,
        //             lng: 72.487821
        //         }
        //     }
        //     onClick={() => console.log("Clicked")}
        // //onClick={this.markerClicked.bind(this)}
        // >
        // </Marker>
        // });

        return this.state.data.map((value, indexs) => {
            var abc = value.DeviceLocationDetail.includes("error");
           
            if (value.DeviceLocationDetail!=null&&value.DeviceLocationDetail.length>0 && abc == false) {
                return value.DeviceLocationDetail.map((store, index) => {
                    // return <Marker label={index.toString()} key={index} id={index} position={{
                    //     lat: store.GpsLat,
                    //     lng: store.GpsLng
                    // }}
                    return <Marker
                        icon={
                            (index==0)?
                                {

                                    url: "/Images/locationreach.png"
                                    // ,
                                    // scaledSize: new this.props.google.maps.Size(100, 100)
                                }:
                                (index==this.state["Lastlong"+indexs])?
                                    {
                                        url: "/Images/locationleave.png"
                                    }:
                                    ''

                        }

                        // label={{
                        //     text: (index==0)? 'Start':(index==this.state.Lastlong)? 'Last':''
                        //   }}
                        // title={(index==0)? 'Start':(index==this.state.Lastlong)? 'Last':''}
                        // label={
                        //     (index==0)? 'Start':(index==this.state.Lastlong)? 'Last':''
                        // }
                        // key={index} id={index} position={
                        //     ((index==0)||(index==this.state["Lastlong"+indexs]))? {
                        //         lat: store.GpsLat,
                        //         lng: store.GpsLng
                        //     }
                        key={index} id={index} position={
                            ((index==0)||(index==this.state["Lastlong"+indexs]))? {
                                lat: store.latitude,
                                lng: store.langitude
                            }

                                :''}
                        // {lat: "23.23954",
                        //     lng: "72.487821"}
                        // }
                        //onClick={() => console.log("Clicked")}
                        onClick={this.markerClicked.bind(this, value)}
                    >
                    </Marker>
                })
            }
        })


    }
    OnFilterClick=(e) => {
        debugger;
        e.preventDefault();
        if (this.state.isFilterVisible===false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }
    }
    onSearchClick=(e) => {
        e.preventDefault();
        // window.$(this.MapModal).modal('show');
        this.GetTableData();
        //this.GetOverAllMapReport();
    }
    OnSearchCancelClick=(e) => {
        this.setState({
            isLoading: false,
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [], vehicledata: [], StartDate: new Date(), EndDate: new Date(),
            currentLocation: [], DataBind: [], LiveLocation: "", LiveLocation: "",
            faicon: "fa fa-plus", isFilterVisible: true, iconFilter: "fa fa-minus", NoDataAvailable: false,
            MapTaskNo: "", MapTaskDate: "", MapMuniciplaity: "", MapLocation: "", WaitMsg: false,
            zones: [], districts: [], municipalities: [], wards: [],
            MapVehicleNo: "", vehicles: [], ZoneID: [], DistrictID: [], MunicipalityID: [], WardID: [],
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' }, isCancel: true

        }, () => { this.GetTableData(); });
        this.GetZone();
        this.GetDistrict();
        this.GetMunicipality();
        this.GetWard();
        this.GetVehicle();

    }
    GetCheckboxData(rows) {
        debugger;
        //e.preventDefault();
        if (rows.length>0) {
            this.setState({ IsDisable: false });
            this.setState({ CheckedRows: rows });
        }
        else {
            this.setState({ IsDisable: true });
        }
    }
    closeMapModal=(e) => {
        debugger;
        e.preventDefault();

        this.setState({
            currentLocation: [], DataBind: [], LiveLocation: "", LiveLocation: "", CheckedRows: []

        })
        if (this.tableRef.current) {
            this.tableRef.current.onAllSelected(false)
        }
        window.$(this.MapModal).modal('hide');
        // window.location.reload(false);
    }
    render() {
        const { showInfoWindow, activeMarker }=this.state;
        var maps=this.map;
        return (
            // this.state.isLoading?
            //     <div className="loader-container">
            //         <div className="spinner"></div>
            //     </div>

            //     :(
            <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                {this.state.isFilterVisible&&
                    <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Start Date </label>
                                <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.StartDate} onChange={this.onStartDateChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>End Date </label>
                                <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.EndDate} minDate={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.EndDate} onChange={(e) => {
                                    this.setState({ EndDate: e });
                                }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Zone </label>
                                <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                    options={this.state.zones} selected={this.state.ZoneID} checked={false}
                                    onSelectedChanged={this.onZoneChange}
                                    overrideStrings={{
                                        selectSomeItems: "Zones", allItemsAreSelected: "All Zones are Selected",
                                        selectAll: "Select All", search: "Search"
                                    }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>District </label>
                                <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                    options={this.state.districts} selected={this.state.DistrictID} checked={false}
                                    onSelectedChanged={this.onDistrictChange}
                                    overrideStrings={{
                                        selectSomeItems: "Districts", allItemsAreSelected: "All Districts are Selected",
                                        selectAll: "Select All", search: "Search"
                                    }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Municipality </label>
                                <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                    options={this.state.municipalities} selected={this.state.MunicipalityID} checked={false}
                                    onSelectedChanged={this.onMunicipalityChange}
                                    overrideStrings={{
                                        selectSomeItems: "Municipalities", allItemsAreSelected: "All Municipalities are Selected",
                                        selectAll: "Select All", search: "Search"
                                    }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Ward </label>
                                <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                    options={this.state.wards} selected={this.state.WardID} checked={false}
                                    onSelectedChanged={this.onWardChange}
                                    overrideStrings={{
                                        selectSomeItems: "Wards", allItemsAreSelected: "All Wards are Selected",
                                        selectAll: "Select All", search: "Search"
                                    }} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Vehicle <span className="text-danger">*</span></label>
                                <Select isClearable={true} options={this.state.vehicles} value={this.state.VehicleID} onChange={this.onVehicleChange} />
                                {this.state.VehicleErrMsg&&<span className="text-danger">{this.state.VehicleErrMsg==='required'? 'Please select Vehicle':''}</span>}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <button type="button" onClick={this.onSearchClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                            <button type="button" onClick={this.OnSearchCancelClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                            <a className="btn btn-success font-weight-bolder mt-12 ml-4" onClick={this.state.TableData.length>0? this.ExcelClick:''}>
                                <i id="btnExcel" className="fa fa-file-excel" /> Excel
                            </a>

                        </div>
                    </div>

                }
                <div className="row">

                    <div className="col-md-12" style={{ textAlign: 'center' }}>
                        {this.state.WaitMsg&&
                            <span style={{ fontFamily: '"Poppins"', color: 'red', fontWeight: 'bold', fontSize: 16, textDecoration: 'blink' }}>Please Wait for map to reload</span>
                        }
                    </div>
                    {/* <div className="col-md-2" style={{ textAlign: 'right' }}>
                        <a className="btn btn-outline-dark font-weight-bolder" onClick={this.OnFilterClick}>
                            <i id="btnFilter" className={this.state.iconFilter} /> Filter
                        </a>
                    </div> */}

                </div>
                <div className="row" style={{ marginTop: '1%' }}>
                    <div className="col-md-12">
                        <div className="card card-custom">
                            <div className="card-body">
                                <MaterialTable tableRef={this.tableRef} columns={this.state.cols} data={this.state.TableData}
                                    actions={[
                                        {
                                            tooltip: 'Remove All Selected Users',
                                            icon: 'delete',
                                            onClick: (evt, data) => alert('You want to delete '+data.length+' rows')
                                        }
                                    ]}
                                    options={{
                                        headerStyle: { color: 'black' }, toolbar: true, search: false, draggable: false,
                                        selection: true,

                                        // selectionProps: rowData => ({
                                        //     onClick: (() => {
                                        //         this.GetCheckboxData(rowData.BookingID)
                                        //     }),
                                        //     //checked: true,
                                        // }),
                                        paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20]

                                    }}
                                    onSelectionChange={(rows) => this.GetCheckboxData(rows)}
                                    components={{

                                        Toolbar: props => (
                                            <div className="row" style={{ marginBottom: '2%' }}>
                                                <div className="col-md-8" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h3 className="tabletitle">OverAll Map Report</h3>

                                                </div>
                                                <div className="col-md-4" style={{ textAlign: 'right' }}>
                                                    <button disabled={this.state.IsDisable} type="submit" onClick={this.GetOverAllSelectedMapReport()} className="btn btn-outline-primary font-weight-bolder">
                                                        {this.state.isLoading? <><i id="btnsave" className="fa fa-spinner" /> Loading..</>:<><i id="btnsave" className="fa fa-save" />Submit</>}
                                                    </button>
                                                    {/* <a  className="btn btn-outline-primary font-weight-bolder ">
                                                                <i id="btnsave" className="fa fa-save" /> Submit
                                                            </a>     */}
                                                    <a className="btn btn-outline-dark font-weight-bolder ml-4" onClick={this.OnFilterClick}>
                                                        <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                    </a>


                                                </div>
                                            </div>
                                        )
                                    }}
                                />
                                {/* <MaterialTable
      title="Basic Selection Preview"
      columns={[
        { title: 'Name', field: 'name' },
        { title: 'Surname', field: 'surname' },
        { title: 'Birth Year', field: 'birthYear', type: 'numeric' },
        {
          title: 'Birth Place',
          field: 'birthCity',
          lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' },
        },
      ]}
      data={[
        {
            tableData: { checked: true },
            name: 'Mehmet',
            surname: 'Baran',
            birthYear: 1987,
            birthCity: 63 },
        {
           tableData: { checked: true },
           checked: true,
           name: 'Zerya Betül',
           surname: 'Baran', 
           birthYear: 2017, 
           birthCity: 34 },
      ]}      
      options={{
        selection: true
      }}
    /> */}
                            </div>
                        </div>
                    </div></div>
                {/* <div className="modal fade" ref={modal => this.MapModal=modal} data-bs-keyboard="false" data-bs-backdrop="static" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog mapdialog" role="document">
                        <div className="modal-content" style={{ height: '700px' }}>
                            <div className="modal-header" style={{ display: 'block' }}>

                            </div>
                            <div className="modal-body" style={{ paddingLeft: '12px', paddingTop: '0' }}>
                                <div className="row" style={{ display: 'block' }}>
                                    {
                                        ((this.state.DataBind!==null)? (
                                            <Map
                                                google={this.props.google}
                                                zoom={18}
                                                style={mapStyles}
                                                center={this.state.LiveLocation}
                                            // center={{ lat: 23.0204741, lng: 72.4149305 }}
                                            >
                                                {/* {this.state.LiveLocation} *}
                                                {this.showMarkers()}

                                                {this.state.data.map((value, index) => {
                                                    return (
                                                        (value.DeviceLocationDetail!=null)? (
                                                            <Polyline

                                                                options={{
                                                                    strokeColor: "blue",
                                                                    strokeOpacity: 0.75,
                                                                    strokeWeight: 2,
                                                                }}
                                                                path={
                                                                    this.state["DataBind"+index]
                                                                }
                                                            />):''
                                                    );

                                                })}

                                                <InfoWindow
                                                    marker={activeMarker}
                                                    visible={showInfoWindow}>
                                                    <div className="row">
                                                        <div className="col-md-12" style={{ marginBottom: '2%' }}>
                                                            <strong>Task No : </strong>
                                                            <div style={{ marginTop: '2%' }}>
                                                                {this.state.MapTaskNo}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12" style={{ marginBottom: '2%' }}>
                                                            <strong>Task Date : </strong>
                                                            <div style={{ marginTop: '2%' }}>
                                                                {this.state.MapTaskDate}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12" style={{ marginBottom: '2%' }}>
                                                            <strong>Municipality : </strong>
                                                            <div style={{ marginTop: '2%' }}>
                                                                {this.state.MapMuniciplaity}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12" style={{ marginBottom: '2%' }}>
                                                            <strong>Location : </strong>
                                                            <div style={{ marginTop: '2%' }}>
                                                                {this.state.MapLocation}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </InfoWindow>
                                            </Map>):'')
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}

                {
                    <>
                        <div className="modal fade" ref={modal => this.MapModal=modal} data-bs-keyboard="false" data-bs-backdrop="static" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                            <div className="modal-dialog mapdialog" role="document">
                                <div className="modal-content" style={{ height: '700px' }}>
                                    <div className="modal-header" style={{ display: 'block' }}>
                                        <img src="/Images/locationreach.png" style={{ height: '30px' }}></img>
                                        <span style={{ fontWeight: 'bold', fontFamily: '"Poppins"' }}>Location Reached</span>
                                        <img src="/Images/locationleave.png" style={{ height: '30px' }}></img>
                                        <span style={{ fontWeight: 'bold', fontFamily: '"Poppins"' }}>Location Leave</span>

                                        <button className="btn btn-danger float-lg-right" onClick={this.closeMapModal}>Close</button>
                                    </div>
                                    <div className="modal-body" style={{ paddingLeft: '12px', paddingTop: '0' }}>
                                        <div className="row" style={{ display: 'block', marginTop: '2%' }}>
                                            <div className="overallmap" style={{ height: '400px' }}>
                                                {
                                                    ((this.state.DataBind!==null)? (
                                                        <Map
                                                            google={this.props.google}
                                                            zoom={18}
                                                            style={mapStyles}
                                                            center={this.state.LiveLocation}
                                                            height={500}>

                                                            {this.showMarkers()}

                                                            {this.state.data.map((value, index) => {
                                                                   var abc = value.DeviceLocationDetail.includes("error");
      
                                                                return (
                                                                    (value.DeviceLocationDetail!=null&&value.DeviceLocationDetail.length>0 && abc == false)? (
                                                                        <Polyline

                                                                            options={{
                                                                                strokeColor: "blue",
                                                                                strokeOpacity: 0.75,
                                                                                strokeWeight: 2,
                                                                            }}
                                                                            path={
                                                                                this.state["DataBind"+index]
                                                                            }
                                                                        />):''
                                                                );

                                                            })}


                                                            <InfoWindow
                                                                marker={activeMarker}
                                                                visible={showInfoWindow}>
                                                                <div className="row">
                                                                    <div className="col-md-12" style={{ marginBottom: '2%' }}>
                                                                        <strong>Task No : </strong>
                                                                        <div style={{ marginTop: '2%' }}>
                                                                            {this.state.MapTaskNo}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12" style={{ marginBottom: '2%' }}>
                                                                        <strong>Task Date : </strong>
                                                                        <div style={{ marginTop: '2%' }}>
                                                                            {this.state.MapTaskDate}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12" style={{ marginBottom: '2%' }}>
                                                                        <strong>Municipality : </strong>
                                                                        <div style={{ marginTop: '2%' }}>
                                                                            {this.state.MapMuniciplaity}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12" style={{ marginBottom: '2%' }}>
                                                                        <strong>Location : </strong>
                                                                        <div style={{ marginTop: '2%' }}>
                                                                            {this.state.MapLocation}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12" style={{ marginBottom: '2%' }}>
                                                                        <strong>Vehicle No : </strong>
                                                                        <div style={{ marginTop: '2%' }}>
                                                                            {this.state.MapVehicleNo}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </InfoWindow>
                                                        </Map>):'')
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

            </div>
            //)
        );
    }
}
export default withCookies(GoogleApiWrapper({
    apiKey: 'AIzaSyA8Io_sD3d5uTz6ZTj4FKae_I7mdBVjX9o'
})(OverAllMap));