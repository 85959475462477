import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import XLSX from "xlsx-color";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { history } from '../helpers/history';

const headerTblStyle = { color: 'black' };

const exportToExcel = (columns, data) => {
    const header = ["Sr.No", "RemarkDate", "VehicleNo", "Remarks"];
    const headertemp = ["Sr.No", "Remark Date", "Vehicle No", "Remarks"];
    const compatibleData = data.map((row, i) => {
        const obj = {};
        header.forEach((col, index) => {
            if (col === "Sr.No") {
                obj[col] = i + 1;
            }
            else if (col == "RemarkDate") {
                if (row[col] != "" || row[col] != "null" || row[col] != null) {
                    obj["Remark Date"] = row[col];
                } else {
                    obj["Remark Date"] = "";
                }
            }
            else if (col == "VehicleNo") {
                if (row[col] != "" || row[col] != "null" || row[col] != null) {
                    obj["Vehicle No"] = row[col];
                } else {
                    obj["Vehicle No"] = "";
                }
            }
            else if (col == "Remarks") {
                if (row[col] != "" || row[col] != "null" || row[col] != null) {
                    obj["Remarks"] = row[col];
                } else {
                    obj["Remarks"] = "";
                }
            }
        });
        return obj;
    });

    const ws1 = XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb = XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s = {
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols'] = [
        { width: 6 },
        { width: 20 },
        { width: 20 },
        { width: 70 }
    ];
    let cell = {
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s = cell;
    ws1['B1'].s = cell;
    ws1['C1'].s = cell;
    ws1['D1'].s = cell;

    var RowCont = {
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins = i + 2;
        ws1['A' + ins].s = RowCont;
        ws1['B' + ins].s = RowCont;
        ws1['C' + ins].s = RowCont;
        ws1['D' + ins].s = RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "BookingVehicleRemarkList");
    XLSX.writeFile(wb, "BookingVehicleRemarkList_" + moment().format('DD-MM-YYYY_HH:mm') + ".xlsx");
}
class vehicleremarks extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            name: cookies.get('name') || 'Ben'
        };
        this.state = {
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            activeItem: 'vehicleremarks',
            AddVehicleRemarkVisible: false,
            VehicleRemarksDate: new Date(), StartDate: new Date(), EndDate: new Date(),
            BookingVehicleRemarkID: '', Remarks: '',
            data: [], vehicles: [], searchvehicles: [],
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            SearchVehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            VehicleRemarksDateErrMsg: '', VehicleErrMsg: '', RemarksErrMsg: '',
            iconAdd: "fa fa-plus", iconFilter: "fa fa-plus", isFilterVisible: false, isClear: false,
            IsEdit: false,

            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.tableData.id + 1}</p>) } },
                { title: 'Remark Date', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.RemarkDate}</p>) } },
                { title: 'Vehicle', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.VehicleNo}</p>) } },
                { title: 'Remarks', width: '50%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Remarks}</p>) } },
            ]
        }
    }

    componentDidMount() {
        this.GetVehicle();
        this.GetVehicleRemarksData();
    }

    GetVehicle() {
        var CompanyID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }

        var data = "";
        data = JSON.stringify({
            "VehicleID": "",
            "CompanyID": CompanyID,
            "IsActive": "true",
            "IsDelete": "false"
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Vehicle/getVehicle",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                this.setState({ vehicles: response.data.data.map(item => ({ value: item.VehicleID, label: item.VehicleNo })) });

                this.setState({ searchvehicles: response.data.data.map(item => ({ value: item.VehicleID, label: item.VehicleNo })) });
            }
            else {
                this.setState({ vehicles: [] })
                this.setState({ searchvehicles: [] })
            }
        }, error => { })
    }

    GetVehicleRemarksData = () => {
        var CompanyID = "";
        var ClientID = "";
        var VehicleID = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.state.SearchVehicleID.value != "--Select Vehicle--") {
            VehicleID = this.state.SearchVehicleID.value;
        }

        if (CompanyID != "" && ClientID != "") {
            var data = JSON.stringify({
                "BookingVehicleRemarkID": this.state.BookingVehicleRemarkID,
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "UserID": "",
                "UserTypeID": "",
                "VehicleID": VehicleID,
                "StartDate": this.state.StartDate,
                "EndDate": this.state.EndDate

            });
            var config = {
                method: 'POST',
                url: services.API_URL + "Booking/getBookingVehicleRemarks",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                if (response.data.status) {
                    this.setState({ data: response.data.data })
                }
                else {
                    this.setState({ data: [] })
                }
            }, error => { })
        }
        else {
            if (CompanyID == "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID != "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID == "" && ClientID != "") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }
    }

    onAddVehicleRemarkClick = (e) => {
        if (this.state.AddVehicleRemarkVisible == false) {
            this.setState({ AddVehicleRemarkVisible: true });
            this.setState({ iconAdd: "fa fa-minus" });
        }
        else {
            this.setState({ AddVehicleRemarkVisible: false });
            this.setState({ iconAdd: "fa fa-plus" });
        }
    }

    OnFilterClick = (e) => {
        e.preventDefault();
        if (this.state.isFilterVisible === false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }
    }

    ExcelClick = () => {
        exportToExcel(this.state.cols, this.state.data)
    }

    handleSubmit = (e) => {
        e.preventDefault();

        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var UserType = "";
        var allvalid = true;

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            UserType = this.props.allCookies.PanelUserType
        }

        if (this.state.VehicleRemarksDate == null || this.state.VehicleRemarksDate == '') {
            this.setState({ VehicleRemarksDateErrMsg: 'required' });
            allvalid = false;
        }

        if (this.state.VehicleID == null || this.state.VehicleID.value == '--Select Vehicle--') {
            this.setState({ VehicleErrMsg: 'required' });
            allvalid = false;
        }
        if (this.state.Remarks == '') {
            this.setState({ RemarksErrMsg: 'required' });
            allvalid = false;
        }

        if (this.form.isValid()) {
            if (CompanyID != "" && ClientID != "") {
                if (allvalid == true) {
                    var data = "";
                    if (this.state.BookingVehicleRemarkID != "") {
                        data = JSON.stringify({
                            "BookingVehicleRemarkID": this.state.BookingVehicleRemarkID,
                            "CompanyID": CompanyID,
                            "ClientID": ClientID,
                            "UserID": UserID,
                            "VehicleID": this.state.VehicleID.value,
                            "VehicleNo": this.state.VehicleID.label,
                            "RemarkDate": moment(this.state.VehicleRemarksDate).format('DD-MM-YYYY'),
                            "Remarks": this.state.Remarks,
                            "EntryByUserType": UserType,
                            "EntryByUserID": UserID
                        });
                    } else {
                        data = JSON.stringify({
                            "CompanyID": CompanyID,
                            "ClientID": ClientID,
                            "UserID": UserID,
                            "VehicleID": this.state.VehicleID.value,
                            "VehicleNo": this.state.VehicleID.label,
                            "RemarkDate": moment(this.state.VehicleRemarksDate).format('DD-MM-YYYY'),
                            "Remarks": this.state.Remarks,
                            "EntryByUserType": UserType,
                            "EntryByUserID": UserID
                        });
                    }

                    var config = {
                        method: 'POST',
                        url: services.API_URL + "Booking/setBookingVehicleRemarks",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };

                    services.JsonValue(config).then(response => {
                        if (response.data.status) {
                            if (this.state.BookingVehicleRemarkID != "") {
                                Swal.fire({
                                    title: 'Successfully Updated', icon: "success", timer: 1500
                                }).then(function () {
                                    history.push('/vehicleremarks');
                                    window.location.href = "vehicleremarks";
                                });
                            }
                            else {
                                Swal.fire({
                                    title: 'Successfully Inserted', icon: "success", timer: 1500
                                });
                            }
                            this.ClearData();
                        }
                        else {
                            Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                        }
                    }, error => { })
                }
            }
        }
    }

    EditVehicleRemark(id) {
        this.setState({ AddVehicleRemarkVisible: true });
        this.setState({ iconAdd: "fa fa-minus" });

        var CompanyID = "";
        var ClientID = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        var data = JSON.stringify({
            "BookingVehicleRemarkID": id,
            "CompanyID": CompanyID,
            "ClientID": ClientID
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getBookingVehicleRemarks",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            this.setState({ IsEdit: true });
            this.GetVehicle();

            var dateString = response.data.data[0].RemarkDate;
            var dateMomentObject = moment(dateString, "DD-MM-YYYY");
            var date = dateMomentObject.toDate();

            this.setState({
                BookingVehicleRemarkID: response.data.data[0].BookingVehicleRemarkID,
                VehicleRemarksDate: date,
                VehicleID: { 'label': response.data.data[0].VehicleNo, 'value': response.data.data[0].VehicleID },
                Remarks: response.data.data[0].Remarks
            });
        }, error => { })
    }

    CheckAlert(BookingVehicleRemarkID, RemarkDate, VehicleNo) {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                var CompanyID = "";
                var ClientID = "";
                var UserID = "";
                var UserType = "";
                if (this.props.allCookies.MainCompanyID !== undefined) {
                    CompanyID = this.props.allCookies.MainCompanyID
                }
                if (this.props.allCookies.MainClientID !== undefined) {
                    ClientID = this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID !== undefined) {
                    UserID = this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType !== undefined) {
                    UserType = this.props.allCookies.PanelUserType
                }
                var data = JSON.stringify({
                    "BookingVehicleRemarkID": BookingVehicleRemarkID,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "UserID": UserID,
                    "RemarkDate": RemarkDate,
                    "VehicleNo": VehicleNo,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
                var config = {
                    method: 'POST',
                    url: services.API_URL + "Booking/removeBookingVehicleRemarks",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        this.GetVehicleRemarksData();
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        });
                    } else {
                        Swal.fire({ title: response.data.message, icon: 'error', showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })


            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }

    onSearchVehicleRemarkClick = (e) => {
        e.preventDefault();
        this.GetVehicleRemarksData();
    }

    ClearData = (e) => {
        this.setState({
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            activeItem: 'vehicleremarks',
            AddVehicleRemarkVisible: false,
            VehicleRemarksDate: new Date(), StartDate: new Date(), EndDate: new Date(),
            BookingVehicleRemarkID: '', Remarks: '',
            data: [], vehicles: [], searchvehicles: [],
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            SearchVehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            VehicleRemarksDateErrMsg: '', VehicleErrMsg: '', RemarksErrMsg: '',
            iconAdd: "fa fa-plus", iconFilter: "fa fa-plus", isFilterVisible: false, isClear: false,
            IsEdit: false,
        });
        this.form.reset();
        this.GetVehicle();
        this.GetVehicleRemarksData();
    }

    OnSearchCancelVehicleRemarksClick = () => {
        this.setState({
            StartDate: new Date(), EndDate: new Date(),
            SearchVehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' }
        }, () => {
            this.GetVehicleRemarksData();
        });
    }

    render() {
        const { cookies } = this.props
        var Permission = cookies.cookies.VehicleRemarks;
        var TotalPermission = "";
        if (Permission !== undefined) {
            TotalPermission = Permission.split(',');
            if (this.state.AddStatus == "") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <div>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row">
                        {this.state.AddVehicleRemarkVisible &&
                            <div className="col-md-12">
                                {
                                    this.state.AddStatus === "AddStatus:true" &&
                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header">
                                            <h3 className="card-title">Add Vehicle Remarks</h3>
                                        </div>
                                        <FormWithConstraints
                                            ref={form => this.form = form}
                                            onSubmit={this.handleSubmit}
                                            noValidate>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Date <span className="text-danger">*</span></label>
                                                            <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.VehicleRemarksDate} autoComplete="off" className="form-control readonly" id="txtVehicleRemarksDate" name="txtVehicleRemarksDate" value={this.state.VehicleRemarksDate} onChange={(e) => {
                                                                this.setState({ VehicleRemarksDate: e }, () => { this.setState({ VehicleRemarksDateErrMsg: '' }) });
                                                            }} />
                                                            {this.state.VehicleRemarksDateErrMsg && <span className="text-danger">{this.state.VehicleRemarksDateErrMsg === 'required' ? 'Please select Date' : ''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Vehicle <span className="text-danger">*</span></label>
                                                            <Select isClearable={true} options={this.state.vehicles} value={this.state.VehicleID} onChange={(e) => {
                                                                this.setState({ VehicleID: e }, () => {
                                                                    this.setState({ VehicleErrMsg: '' })
                                                                })
                                                            }} />
                                                            {this.state.VehicleErrMsg && <span className="text-danger">{this.state.VehicleErrMsg === 'required' ? 'Please Select Vehicle' : ''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Remarks <span className="text-danger">*</span></label>
                                                            <textarea rows={3} className="form-control" name="txtRemarks" value={this.state.Remarks} onChange={(e) => {
                                                                this.setState({ Remarks: e.target.value }, () => {
                                                                    this.setState({ RemarksErrMsg: '' })
                                                                })
                                                            }}
                                                            />
                                                            {this.state.RemarksErrMsg && <span className="text-danger">{this.state.RemarksErrMsg === 'required' ? 'Please Enter Remarks' : ''}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" onSubmit={this.handleSubmit} className="btn btn-primary mr-2">
                                                    {this.state.IsEdit ? 'Update' : 'Submit'}
                                                </button>
                                                <button type="button" onClick={this.ClearData} className="btn btn-secondary">
                                                    Cancel
                                                </button>
                                            </div>
                                        </FormWithConstraints>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {this.state.isFilterVisible &&
                        this.state.ViewStatus === "ViewStatus:true" &&
                        <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Start Date </label>
                                    <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtStartDate" value={this.state.StartDate}
                                        onChange={(e) => {
                                            this.setState({ StartDate: e });
                                            this.setState({ EndDate: e });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>End Date </label>
                                    <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.EndDate} minDate={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtEndDate" value={this.state.EndDate} onChange={(e) => {
                                        this.setState({ EndDate: e });
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Vehicle </label>
                                    <Select isClearable={true} options={this.state.searchvehicles} value={this.state.SearchVehicleID} onChange={(e) => {
                                        if (e !== null) {
                                            this.setState({ SearchVehicleID: e });
                                        }
                                        else {
                                            this.setState({ SearchVehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' } });
                                        }
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button type="button" onClick={this.onSearchVehicleRemarkClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                <button type="button" onClick={this.OnSearchCancelVehicleRemarksClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-body">
                                    <MaterialTable columns={this.state.cols} data={this.state.data}
                                        actions={[{
                                            hidden: this.state.UpdateStatus == "UpdateStatus:true" ? false : true,
                                            icon: 'edit', tooltip: 'Edit', onClick: (e, r) => this.EditVehicleRemark(r.BookingVehicleRemarkID)
                                        },
                                        {
                                            hidden: this.state.DeleteStatus == "DeleteStatus:true" ? false : true,
                                            icon: 'delete', tooltip: 'Delete', onClick: (e, r) => this.CheckAlert(r.BookingVehicleRemarkID, r.RemarkDate, r.VehicleNo)
                                        }
                                        ]}
                                        options={{
                                            headerStyle: { color: 'black' }, toolbar: true, search: false, draggable: false,
                                            paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                        }}
                                        components={{

                                            Toolbar: props => (
                                                <div className="row" style={{ marginBottom: '2%' }}>
                                                    <div className="col-md-8 mobpb-5" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h3 className="tabletitle">View Vehicle Remarks</h3>

                                                    </div>
                                                    <div className="col-md-4 mobta-left" style={{ textAlign: 'right' }}>
                                                        {
                                                            this.state.AddStatus === "AddStatus:true" &&
                                                            <a onClick={this.onAddVehicleRemarkClick} className="btn btn-outline-primary font-weight-bolder mr-5 mobmr-1">
                                                                <span className="svg-icon svg-icon-md">
                                                                    <i id="btnAdd" className={this.state.iconAdd} />
                                                                </span>Add Vehicle Remarks</a>
                                                        }
                                                        {
                                                            this.state.ViewStatus === "ViewStatus:true" &&
                                                            <>
                                                                <a className="btn btn-outline-dark font-weight-bolder mr-5 mobmr-1" onClick={this.OnFilterClick}>
                                                                    <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                                </a>
                                                                {this.state.ExcelStatus === "ExcelStatus:true" &&
                                                                    <a className="btn btn-outline-success font-weight-bolder" onClick={this.state.data.length > 0 ? this.ExcelClick : ''}>
                                                                        <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                                    </a>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withCookies(vehicleremarks);