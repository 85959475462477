import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { NavLink } from "react-router-dom";
import { history } from '../helpers/history';


class subadminbooking extends Component {

    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            name: cookies.get('name')||'Ben'
        };
        this.state={
            AddStatus: "",
            data: [], bookingtypes: [], zones: [], districts: [], municipalities: [], wards: [], countries: [], states: [], cities: [],
            priorities: [{ 'label': 'High', 'value': 'High' }, { 'label': 'Medium', 'value': 'Medium' }, { 'label': 'Low', 'value': 'Low' }],
            BookingTypeID: { 'label': '--Select Task Type--', 'value': '--Select Task Type--' },
            ZoneID: { 'label': '--Select Zone--', 'value': '--Select Zone--' },
            DistrictID: { 'label': '--Select District--', 'value': '--Select District--' },
            MuncipalityID: { 'label': '--Select Municipality--', 'value': '--Select Municipality--' },
            WardID: { 'label': '--Select Ward--', 'value': '--Select Ward--' },
            CountryID: { 'label': '--Select Country--', 'value': '--Select Country--' },
            StateID: { 'label': '--Select State--', 'value': '--Select State--' },
            CityID: { 'label': '--Select City--', 'value': '--Select City--' },
            Priority: { 'label': '--Select Priority--', 'value': '--Select Priority--' },
            BookingTypeErrMsg: "", TaskDateErrMsg: "", ZoneErrMsg: "", DistrictErrMsg: "", MunicipalityErrMsg: "", WardErrMsg: "", StateErrMsg: "", CityErrMsg: "",
            PriorityErrMsg: "", AlreadyBookingNoErrMsg: "",DrainageLine150to300mmDiaMeter: "0", DrainageLine301to600mmDiaMeter: "0",
            DrainageLine601to900mmDiaMeter: "0", DrainageLine901to1200mmDiaMeter: "0",
            is150to300Checked: false, is301to600Checked: false,
            is601to900Checked: false, is901to1200Checked: false,
            BookingID: "", BookingNo: "", BookingDate: new Date(), Locality: "", Remarks: "", isChanging: false
        }
    }
    componentDidMount() {
        debugger;
        this.GetBookingNo();
        this.GetBookingType();
        this.GetZone();
        this.GetCountry();
        this.onCountryChange(1);
        this.setState({ CountryID: { 'label': 'India', 'value': '1' } })
        const search=history.location.search;
        const params=new URLSearchParams(search);
        const id=params.get('id')
        if (id!=null) {
            this.EditBooking(id);
        }
        else {

        }

    }
    EditBooking(id) {
       // debugger;
        var CompanyID="";
        var ClientID="";
        var UserID="";
        var UserType="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID!==undefined) {
            UserID=this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType!==undefined) {
            UserType=this.props.allCookies.PanelUserType
        }
        if (UserType=="Sub Admin") {
            //UserID="";
        }
        if (CompanyID!==""&&ClientID!=="") {
            var data=JSON.stringify({
                "BookingID": id,
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "UserID": ""
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Booking/getBooking",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
               // debugger;
                var data="";

                data=JSON.stringify({
                    "ZoneID": response.data.data[0].ZoneID,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "UserID": UserID
                });

                var configDistrict={
                    method: 'POST',
                    url: services.API_URL+"User/getUserDistrictDetail",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(configDistrict).then(response => {
                   // debugger;

                    if (response.data.status) {
                        //debugger;

                        this.setState({ districts: response.data.data.map(item => ({ value: item.DistrictID, label: item.DistrictName })) });

                    }
                    else {

                    }
                }, error => { })

                var data="";
                data=JSON.stringify({
                    "DistrictID": response.data.data[0].DistrictID,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "UserID": UserID
                });

                var configMuncipality={
                    method: 'POST',
                    url: services.API_URL+"User/getUserMuncipalityDetail",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(configMuncipality).then(response => {
                    //debugger;

                    if (response.data.status) {
                        //debugger;

                        this.setState({ municipalities: response.data.data.map(item => ({ value: item.MuncipalityID, label: item.MuncipalityName })) });

                    }
                    else {

                    }
                }, error => { })

                var data="";
                data=JSON.stringify({
                    "MuncipalityID": response.data.data[0].MunicipalityID,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "UserID": UserID
                });

                var configWard={
                    method: 'POST',
                    url: services.API_URL+"Ward/getWard",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(configWard).then(response => {
                    //debugger;

                    if (response.data.status) {
                        //debugger;

                        this.setState({ wards: response.data.data.map(item => ({ value: item.WardID, label: item.WardName })) });
                    }
                    else {

                    }
                }, error => { })

                var data=JSON.stringify({
                    "CountryID": response.data.data[0].CountryID
                });

                var config={
                    method: 'POST',
                    url: services.API_URL+"State/getState",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    debugger;

                    if (response.data.status) {
                        this.setState({ states: response.data.data.map(item => ({ value: item.StateID, label: item.StateName })) });
                    }
                    else {

                    }
                }, error => { })

                var data=JSON.stringify({
                    "StateID": response.data.data[0].StateID
                });

                var config={
                    method: 'POST',
                    url: services.API_URL+"City/getCity",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    debugger;
                    this.setState({ cities: response.data.data.map(item => ({ value: item.CityID, label: item.CityName })) });
                }, error => { })
                debugger;

                var dateString=response.data.data[0].BookingDate;

                var dateMomentObject=moment(dateString, "DD-MM-YYYY"); // 1st argument - string, 2nd argument - format
                var date=dateMomentObject.toDate(); // convert moment.js object to Date object
                //var dt = new Date();
                // var dts = moment(new Date, 'DD-MM-YYYY').format();
                //  var abc = dateMomentObject.toLocaleDateString();
                // var dt="2020-06-11";
                //var strDate=new Date(dt);
                // var abc = new Date();
                // var date=moment(dateObject).format('DD-MM-YYYY');
                // var date = "01-02-2023";
                //console.log("valdate",tddate);
                this.setState({
                    BookingID: response.data.data[0].BookingID,
                    BookingTypeID: { 'label': response.data.data[0].BookingType, 'value': response.data.data[0].BookingTypeID },
                    BookingNo: response.data.data[0].BookingNo,
                    BookingDate: date,
                    ZoneID: { 'label': response.data.data[0].ZoneName, 'value': response.data.data[0].ZoneID },
                    DistrictID: { 'label': response.data.data[0].DistrictName, 'value': response.data.data[0].DistrictID },
                    MuncipalityID: { 'label': response.data.data[0].MuncipalityName, 'value': response.data.data[0].MuncipalityID },
                    WardID: { 'label': response.data.data[0].WardName, 'value': response.data.data[0].WardID },
                    CountryID: { 'label': response.data.data[0].CountryName, 'value': response.data.data[0].CountryID },
                    StateID: { 'label': response.data.data[0].StateName, 'value': response.data.data[0].StateID },
                    CityID: { 'label': response.data.data[0].CityName, 'value': response.data.data[0].CityID },
                    Locality: response.data.data[0].Locality,
                    Priority: { 'label': response.data.data[0].Priority, 'value': response.data.data[0].Priority },
                    Remarks: response.data.data[0].Remarks,
                });
                if(response.data.data[0].DrainageLine150to300mmDiaMeter != "0")
                {
                    this.setState({is150to300Checked:true,DrainageLine150to300mmDiaMeter:"480"});
                }
                else{
                    this.setState({is150to300Checked:false,DrainageLine150to300mmDiaMeter:"0"});
                }
                if(response.data.data[0].DrainageLine301to600mmDiaMeter != "0")
                {
                    this.setState({is301to600Checked:true,DrainageLine301to600mmDiaMeter:"320"});
                }
                else{
                    this.setState({is301to600Checked:false,DrainageLine301to600mmDiaMeter:"0"});
                }
                if(response.data.data[0].DrainageLine601to900mmDiaMeter != "0")
                {
                    this.setState({is601to900Checked:true,DrainageLine601to900mmDiaMeter:"240"});
                }
                else{
                    this.setState({is601to900Checked:false,DrainageLine601to900mmDiaMeter:"0"});
                }
                if(response.data.data[0].DrainageLine901to1200mmDiaMeter != "0")
                {
                    this.setState({is901to1200Checked:true,DrainageLine901to1200mmDiaMeter:"80"});
                }
                else{
                    this.setState({is901to1200Checked:false,DrainageLine901to1200mmDiaMeter:"0"});
                }
            });
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }
    GetBookingNo=() => {
        var CompanyID="";
        var ClientID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "CompanyID": CompanyID,
                "ClientID": ClientID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Booking/getTop1BookingNo",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                if (response.data.status) {
                    var NewBookingNo=parseInt(response.data.data[0].BookingNo)+parseInt(1);
                    this.setState({ BookingNo: NewBookingNo });
                    this.setState({ AlreadyBookingNoErrMsg: "" });
                }
                else {
                    this.setState({ BookingNo: "1" });
                }

                //this.setState({ bookingtypes: response.data.data.map(item => ({ value: item.BookingTypeID, label: item.BookingType })) });
            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }
    }
    CheckBookingNo=(e) => {
        debugger;
        this.setState({ BookingNo: e.target.value }, () => { this.form.validateFields(e.target) });
        var CompanyID="";
        var ClientID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "BookingID": this.state.BookingID,
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "BookingNo": e.target.value
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Booking/CheckBookingNo",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                if (response.data.status) {
                    this.setState({ AlreadyBookingNoErrMsg: "" });
                    // var NewBookingNo=parseInt(response.data.data[0].BookingNo)+parseInt(1);
                    // this.setState({ BookingNo: NewBookingNo });

                }
                else {
                    if (e.target.value!=='') {
                        this.setState({ AlreadyBookingNoErrMsg: "already" });
                    }
                    else {
                        this.setState({ AlreadyBookingNoErrMsg: "" });
                    }

                    //this.setState({ BookingNo: "1" });
                }

                //this.setState({ bookingtypes: response.data.data.map(item => ({ value: item.BookingTypeID, label: item.BookingType })) });
            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }
    }
    GetBookingType() {
        var CompanyID="";
        var ClientID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "BookingTypeID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "IsActive": "true",
                "IsDelete": "false"
            });
            var config={
                method: 'POST',
                url: services.API_URL+"BookingType/getBookingType",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                this.setState({ bookingtypes: response.data.data.map(item => ({ value: item.BookingTypeID, label: item.BookingType })) });
            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }


    }
    GetZone() {
        var CompanyID="";
        var ClientID="";
        var UserID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID!==undefined) {
            UserID=this.props.allCookies.UserID
        }

        var data=JSON.stringify({
            "ZoneID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "UserID": UserID
        });
        var config={
            method: 'POST',
            url: services.API_URL+"User/getUserZoneDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            this.setState({ zones: response.data.data.map(item => ({ value: item.ZoneID, label: item.ZoneName })) });
        }, error => { })
    }
    ValidateBookingType=(e) => {
        this.setState({ BookingTypeErrMsg: '' });
    }
    ValidateCancelZone=(e) => {
        this.setState({ ZoneErrMsg: 'required' })
    }
    ValidateZone=(e) => {
        this.setState({ ZoneErrMsg: '' })
    }
    ValidateCancelDistrict=(e) => {
        this.setState({ DistrictErrMsg: 'required' })
    }
    ValidateDistrict=(e) => {
        this.setState({ DistrictErrMsg: '' });
    }
    ValidateCancelMunicipality=(e) => {
        this.setState({ MunicipalityErrMsg: 'required' })
    }
    ValidateMunicipality=(e) => {
        this.setState({ MunicipalityErrMsg: '' });
    }
    ValidateCancelWard=(e) => {
        this.setState({ WardErrMsg: 'required' })
    }
    ValidateWard=(e) => {
        this.setState({ WardErrMsg: '' });
    }
    ValidateCancelPriority=(e) => {
        this.setState({ PriorityErrMsg: 'required' })
    }
    ValidatePriority=(e) => {
        this.setState({ PriorityErrMsg: '' });
    }
    onZoneChange=(e) => {
        //debugger;
        if (e!=null) {
            var CompanyID="";
            var ClientID="";
            var UserID="";

            if (this.props.allCookies.MainCompanyID!==undefined) {
                CompanyID=this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID!==undefined) {
                ClientID=this.props.allCookies.MainClientID
            }
            if (this.props.allCookies.UserID!==undefined) {
                UserID=this.props.allCookies.UserID
            }

            this.setState({ ZoneID: e }, () => { this.ValidateZone() });
            var data="";
            data=JSON.stringify({
                "ZoneID": e.value,
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "UserID": UserID
            });

            var config={
                method: 'POST',
                url: services.API_URL+"User/getUserDistrictDetail",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;

                if (response.data.status) {
                    //debugger;

                    this.setState({ districts: response.data.data.map(item => ({ value: item.DistrictID, label: item.DistrictName })) });
                    this.setState({ DistrictID: { 'label': '--Select District--', 'value': '--Select District--' } })
                    this.setState({ municipalities: [] });
                    this.setState({ MuncipalityID: { 'label': '--Select Municipality--', 'value': '--Select Municipality--' } })
                    this.setState({ wards: [] });
                    this.setState({ WardID: { 'label': '--Select Ward--', 'value': '--Select Ward--' } });
                }
                else {
                    this.setState({ districts: [] })
                    this.setState({ DistrictID: { 'label': '--Select District--', 'value': '--Select District--' } })
                    this.setState({ municipalities: [] });
                    this.setState({ MuncipalityID: { 'label': '--Select Municipality--', 'value': '--Select Municipality--' } })
                    this.setState({ wards: [] });
                    this.setState({ WardID: { 'label': '--Select Ward--', 'value': '--Select Ward--' } });
                }
            }, error => { })
        }
        else {
            this.setState({ ZoneID: { 'label': '--Select Zone--', 'value': '--Select Zone--' } }, () => { this.ValidateCancelZone() })
            this.setState({ districts: [] });
            this.setState({ DistrictID: { 'label': '--Select District--', 'value': '--Select District--' } }, () => { this.ValidateCancelDistrict() })
            this.setState({ municipalities: [] });
            this.setState({ MuncipalityID: { 'label': '--Select Municipality--', 'value': '--Select Municipality--' } }, () => { this.ValidateCancelMunicipality() })
            this.setState({ wards: [] });
            this.setState({ WardID: { 'label': '--Select Ward--', 'value': '--Select Ward--' } }, () => { this.ValidateCancelWard() });
        }


    }
    onDistrictChange=(e) => {
        debugger
        if (e!=null) {
            this.setState({ DistrictID: e }, () => { this.ValidateDistrict() });
            var CompanyID="";
            var ClientID="";
            var UserID="";

            if (this.props.allCookies.MainCompanyID!==undefined) {
                CompanyID=this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID!==undefined) {
                ClientID=this.props.allCookies.MainClientID
            }
            if (this.props.allCookies.UserID!==undefined) {
                UserID=this.props.allCookies.UserID
            }

            // this.setState({ ZoneID: e });
            var data="";
            data=JSON.stringify({
                "DistrictID": e.value,
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "UserID": UserID
            });

            var config={
                method: 'POST',
                url: services.API_URL+"User/getUserMuncipalityDetail",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;

                if (response.data.status) {
                    //debugger;

                    this.setState({ municipalities: response.data.data.map(item => ({ value: item.MuncipalityID, label: item.MuncipalityName })) });
                    this.setState({ MuncipalityID: { 'label': '--Select Municipality--', 'value': '--Select Municipality--' } })
                    this.setState({ wards: [] });
                    this.setState({ WardID: { 'label': '--Select Ward--', 'value': '--Select Ward--' } });
                }
                else {
                    this.setState({ municipalities: [] })
                    this.setState({ MuncipalityID: { 'label': '--Select Municipality--', 'value': '--Select Municipality--' } });
                    this.setState({ wards: [] });
                    this.setState({ WardID: { 'label': '--Select Ward--', 'value': '--Select Ward--' } });
                }
            }, error => { })
        }
        else {
            this.setState({ DistrictID: { 'label': '--Select District--', 'value': '--Select District--' } }, () => { this.ValidateCancelDistrict() });
            this.setState({ municipalities: [] })
            this.setState({ MuncipalityID: { 'label': '--Select Municipality--', 'value': '--Select Municipality--' } }, () => { this.ValidateCancelMunicipality() });
            this.setState({ wards: [] });
            this.setState({ WardID: { 'label': '--Select Ward--', 'value': '--Select Ward--' } }, () => { this.ValidateCancelWard() });
        }
    }
    onMunicipalityChange=(e) => {
        if (e!=null) {
            this.setState({ MuncipalityID: e }, () => { this.ValidateMunicipality() });
            var CompanyID="";
            var ClientID="";
            var UserID="";

            if (this.props.allCookies.MainCompanyID!==undefined) {
                CompanyID=this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID!==undefined) {
                ClientID=this.props.allCookies.MainClientID
            }
            if (this.props.allCookies.UserID!==undefined) {
                UserID=this.props.allCookies.UserID
            }

            //this.setState({ ZoneID: e });
            var data="";
            data=JSON.stringify({
                "MuncipalityID": e.value,
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "UserID": UserID
            });

            var config={
                method: 'POST',
                url: services.API_URL+"Ward/getWard",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;

                if (response.data.status) {
                    //debugger;

                    this.setState({ wards: response.data.data.map(item => ({ value: item.WardID, label: item.WardName })) });
                }
                else {
                    this.setState({ wards: [] })
                    this.setState({ WardID: { 'label': '--Select Ward--', 'value': '--Select Ward--' } });
                }
            }, error => { })
        }
        else {
            this.setState({ MuncipalityID: { 'label': '--Select Municipality--', 'value': '--Select Municipality--' } }, () => { this.ValidateCancelMunicipality() });
            this.setState({ wards: [] });
            this.setState({ WardID: { 'label': '--Select Ward--', 'value': '--Select Ward--' } }, () => { this.ValidateCancelWard() });
        }
    }
    onWardChange=(e) => {
        if (e!=null) {
            this.setState({ WardID: e }, () => { this.ValidateWard() });
        }
        else {
            this.setState({ WardID: { 'label': '--Select Ward--', 'value': '--Select Ward--' } }, () => { this.ValidateCancelWard() });
        }
    }
    onBookingTypeChange=(e) => {
        this.setState({ BookingTypeID: e }, () => { this.ValidateBookingType() });
    }
    GetCountry() {
        var data=JSON.stringify({
            "CountryID": ""
        });
        var config={
            method: 'POST',
            url: services.API_URL+"Country/getCountry",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            this.setState({ countries: response.data.data.map(item => ({ value: item.CountryID, label: item.CountryName })) });
        }, error => { })
    }
    onCountryChange=(e) => {
        //debugger;
        this.setState({ CountryID: e });
        if (e.value!="--Select Country--") {
            var data="";
            data=JSON.stringify({
                "CountryID": e.value
            });

            var config={
                method: 'POST',
                url: services.API_URL+"State/getState",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;

                if (response.data.status) {
                    this.setState({ states: response.data.data.map(item => ({ value: item.StateID, label: item.StateName })) });
                    this.setState({ StateID: { 'label': '--Select State--', 'value': '--Select State--' } })
                    this.setState({ cities: [] })
                    this.setState({ CityID: { 'label': '--Select City--', 'value': '--Select City--' } })
                }
                else {
                    this.setState({ states: [] })
                    this.setState({ StateID: { 'label': '--Select State--', 'value': '--Select State--' } })
                    this.setState({ cities: [] })
                    this.setState({ CityID: { 'label': '--Select City--', 'value': '--Select City--' } })
                }
            }, error => { })
        }
        else {
            this.setState({ CountryID: { 'label': '--Select Country--', 'value': '--Select Country--' } });
        }
    }
    ValidateState=(e) => {
        this.setState({ StateErrMsg: '' })
    }
    ValidateCancelState=(e) => {
        this.setState({ StateErrMsg: 'required' })
    }
    onStateChange=(e) => {
        //debugger;
        if (e!=null) {
            this.setState({ StateID: e }, () => { this.ValidateState() });
            var data="";
            if (e.value!="--Select State--") {
                data=JSON.stringify({
                    "StateID": e.value
                });

                var config={
                    method: 'POST',
                    url: services.API_URL+"City/getCity",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    //debugger;

                    if (response.data.status) {
                        this.setState({ CityID: { 'label': '--Select City--', 'value': '--Select City--' } })
                        this.setState({ cities: response.data.data.map(item => ({ value: item.CityID, label: item.CityName })) });

                    }
                    else {
                        this.setState({ cities: [] })
                        this.setState({ CityID: { 'label': '--Select City--', 'value': '--Select City--' } })
                    }

                }, error => { })
            }
            else {
                this.setState({ StateID: { 'label': '--Select State--', 'value': '--Select State--' } });
            }
        }
        else {
            this.setState({ StateID: { 'label': '--Select State--', 'value': '--Select State--' } }, () => { this.ValidateCancelState() });
            this.setState({ cities: [] })
            this.setState({ CityID: { 'label': '--Select City--', 'value': '--Select City--' } }, () => { this.ValidateCancelCity() })
        }


    }
    ValidateCity=(e) => {
        this.setState({ CityErrMsg: '' })
    }
    ValidateCancelCity=(e) => {
        this.setState({ CityErrMsg: 'required' })
    }
    onCityChange=(e) => {
        if (e!==null) {
            this.setState({ CityID: e }, () => { this.ValidateCity() });
        }
        else {
            this.setState({ CityID: { 'label': '--Select City--', 'value': '--Select City--' } }, () => { this.ValidateCancelCity() });
        }
    }
    onPriorityChange=(e) => {
        if (e!==null) {
            this.setState({ Priority: e }, () => { this.ValidatePriority() });
        }
        else {
            this.setState({ Priority: { 'label': '--Select Priority--', 'value': '--Select Priority--' } }, () => { this.ValidateCancelPriority() });
        }
    }
    ClearData=(e) => {
        debugger;
        this.setState({
            data: [], bookingtypes: [], zones: [], districts: [], municipalities: [], wards: [], countries: [], states: [], cities: [],
            priorities: [{ 'label': 'High', 'value': 'High' }, { 'label': 'Medium', 'value': 'Medium' }, { 'label': 'Low', 'value': 'Low' }],
            BookingTypeID: { 'label': '--Select Task Type--', 'value': '--Select Task Type--' },
            ZoneID: { 'label': '--Select Zone--', 'value': '--Select Zone--' },
            DistrictID: { 'label': '--Select District--', 'value': '--Select District--' },
            MuncipalityID: { 'label': '--Select Municipality--', 'value': '--Select Municipality--' },
            WardID: { 'label': '--Select Ward--', 'value': '--Select Ward--' },
            CountryID: { 'label': '--Select Country--', 'value': '--Select Country--' },
            StateID: { 'label': '--Select State--', 'value': '--Select State--' },
            CityID: { 'label': '--Select City--', 'value': '--Select City--' },
            Priority: { 'label': '--Select Priority--', 'value': '--Select Priority--' },
            BookingTypeErrMsg: "", TaskDateErrMsg: "", ZoneErrMsg: "", DistrictErrMsg: "", MunicipalityErrMsg: "", WardErrMsg: "", StateErrMsg: "", CityErrMsg: "",
            PriorityErrMsg: "", DrainageLine150to300mmDiaMeter: "0", DrainageLine301to600mmDiaMeter: "0",
            DrainageLine601to900mmDiaMeter: "0", DrainageLine901to1200mmDiaMeter: "0",
            is150to300Checked: false, is301to600Checked: false,
            is601to900Checked: false, is901to1200Checked: false,
            BookingID: "", BookingNo: "", BookingDate: new Date(), Locality: "", Remarks: "",
        });
        this.form.reset();
        this.GetBookingNo();
        this.GetBookingType();
        this.GetZone();
        this.GetCountry();
        this.onCountryChange(1);
        this.setState({ CountryID: { 'label': 'India', 'value': '1' } })
    }
    onBookingDateChange=(e) => {
        debugger
        this.setState({ BookingDate: e }, () => { this.form.validateFields(e.target) })
    }
    on150to300Change=(e, action) => {
        debugger;
        if (e.target.checked==true) {
            this.setState({ is150to300Checked: true });
            this.setState({ DrainageLine150to300mmDiaMeter: "480" })
        }
        else {
            this.setState({ is150to300Checked: false });
            this.setState({ DrainageLine150to300mmDiaMeter: "0" })
        }
    }
    on301to600Change=(e, action) => {
        debugger;
        if (e.target.checked==true) {
            this.setState({ is301to600Checked: true });
            this.setState({ DrainageLine301to600mmDiaMeter: "320" })
        }
        else {
            this.setState({ is301to600Checked: false });
            this.setState({ DrainageLine301to600mmDiaMeter: "0" })
        }
    }
    on601to900Change=(e, action) => {
        debugger;
        if (e.target.checked==true) {
            this.setState({ is601to900Checked: true });
            this.setState({ DrainageLine601to900mmDiaMeter: "240" })
        }
        else {
            this.setState({ is601to900Checked: false });
            this.setState({ DrainageLine601to900mmDiaMeter: "0" })
        }
    }
    on901to1200Change=(e, action) => {
        debugger;
        if (e.target.checked==true) {
            this.setState({ is901to1200Checked: true });
            this.setState({ DrainageLine901to1200mmDiaMeter: "80" })
        }
        else {
            this.setState({ is901to1200Checked: false });
            this.setState({ DrainageLine901to1200mmDiaMeter: "0" })
        }
    }
    ValidateTaskDate=(e) => {
        this.setState({ TaskDateErrMsg: '' })
    }
    ValidateCancelTaskDate=(e) => {
        this.setState({ TaskDateErrMsg: 'required' })
    }
    CheckBookingDate=(e) => {
        this.setState({ isChanging: true });
    }
    handleSubmit=(e) => {
        debugger;
        e.preventDefault();


        this.form.validateFields();

        var CompanyID="";
        var ClientID="";
        var UserID="";
        var UserType="";
        var allvalid=true;

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID!==undefined) {
            UserID=this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType!==undefined) {
            UserType=this.props.allCookies.PanelUserType
        }

        if (this.state.BookingTypeID.value=="--Select Task Type--") {
            this.setState({ BookingTypeErrMsg: 'required' });
            allvalid=false;
        }
        if (this.state.AlreadyBookingNoErrMsg==="already") {
            allvalid=false;
        }

        if (this.state.is150to300Checked==false&&this.state.is301to600Checked==false&&this.state.is601to900Checked==false&&this.state.is901to1200Checked==false) {
            this.setState({ DrainageErrMsg: 'required' });
            allvalid=false;
        }
        else {
            this.setState({ DrainageErrMsg: '' });
        }

        if (this.state.BookingDate=="") {
            this.setState({ TaskDateErrMsg: 'required' });
            allvalid=false;
        }

        if (this.state.ZoneID.value=="--Select Zone--") {
            this.setState({ ZoneErrMsg: 'required' });
            allvalid=false;
        }

        if (this.state.DistrictID.value=="--Select District--") {
            this.setState({ DistrictErrMsg: 'required' });
            allvalid=false;
        }

        if (this.state.MuncipalityID.value=="--Select Municipality--") {
            this.setState({ MunicipalityErrMsg: 'required' });
            allvalid=false;
        }

        if (this.state.WardID.value=="--Select Ward--") {
            this.setState({ WardErrMsg: 'required' });
            allvalid=false;
        }

        if (this.state.StateID.value=="--Select State--") {
            this.setState({ StateErrMsg: 'required' });
            allvalid=false;
        }

        if (this.state.CityID.value=="--Select City--") {
            this.setState({ CityErrMsg: 'required' });
            allvalid=false;
        }

        if (this.state.Priority.value=="--Select Priority--") {
            this.setState({ PriorityErrMsg: 'required' });
            allvalid=false;
        }

        if (this.form.isValid()) {
            if (CompanyID!=""&&ClientID!="") {
                if (allvalid==true) {
                    var data="";
                    if (this.state.BookingID!="") {
                        data=JSON.stringify({
                            "BookingID": this.state.BookingID,
                            "CompanyID": CompanyID,
                            "ClientID": ClientID,
                            //"UserID": UserID,
                            "BookingTypeID": this.state.BookingTypeID.value,
                            "BookingNo": this.state.BookingNo,
                            // "BookingDate": moment(this.state.BookingDate).format('DD-MM-YYYY'),
                            "ZoneID": this.state.ZoneID.value,
                            "DistrictID": this.state.DistrictID.value,
                            "MuncipalityID": this.state.MuncipalityID.value,
                            "WardID": this.state.WardID.value,
                            "CountryID": this.state.CountryID.value,
                            "StateID": this.state.StateID.value,
                            "CityID": this.state.CityID.value,
                            "Locality": this.state.Locality,
                            "Remarks": this.state.Remarks,
                            "Priority": this.state.Priority.value,
                            "DrainageLine150to300mmDiaMeter": this.state.DrainageLine150to300mmDiaMeter,
                            "DrainageLine301to600mmDiaMeter": this.state.DrainageLine301to600mmDiaMeter,
                            "DrainageLine601to900mmDiaMeter": this.state.DrainageLine601to900mmDiaMeter,
                            "DrainageLine901to1200mmDiaMeter": this.state.DrainageLine901to1200mmDiaMeter,
                            "EntryByUserType": UserType,
                            "EntryByUserID": UserID
                        });

                    }
                    else {
                        data=JSON.stringify({
                            "BookingID": "",
                            "CompanyID": CompanyID,
                            "ClientID": ClientID,
                            "UserID": UserID,
                            "BookingTypeID": this.state.BookingTypeID.value,
                            "BookingNo": this.state.BookingNo,
                            "BookingDate": moment(this.state.BookingDate).format('DD-MM-YYYY'),
                            "ZoneID": this.state.ZoneID.value,
                            "DistrictID": this.state.DistrictID.value,
                            "MuncipalityID": this.state.MuncipalityID.value,
                            "WardID": this.state.WardID.value,
                            "CountryID": this.state.CountryID.value,
                            "StateID": this.state.StateID.value,
                            "CityID": this.state.CityID.value,
                            "Locality": this.state.Locality,
                            "Remarks": this.state.Remarks,
                            "Priority": this.state.Priority.value,
                            "DrainageLine150to300mmDiaMeter": this.state.DrainageLine150to300mmDiaMeter,
                            "DrainageLine301to600mmDiaMeter": this.state.DrainageLine301to600mmDiaMeter,
                            "DrainageLine601to900mmDiaMeter": this.state.DrainageLine601to900mmDiaMeter,
                            "DrainageLine901to1200mmDiaMeter": this.state.DrainageLine901to1200mmDiaMeter,
                            "EntryByUserType": UserType,
                            "EntryByUserID": UserID
                        });
                    }
                    var config={
                        method: 'POST',
                        url: services.API_URL+"Booking/setBookingDataBySubAdmin",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    services.JsonValue(config).then(response => {
                        ////debugger;
                        if (response.data.status) {
                            //debugger;
                            if (this.state.BookingID!="") {
                                // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                                if (UserType=="Sub Admin") {
                                    Swal.fire({
                                        title: 'Successfully Updated', icon: "success", timer: 1500
                                    }).then(function () {
                                        history.push('/viewtask');
                                        window.location.href="viewsubadmintask";
                                    });
                                }
                                else {
                                    Swal.fire({
                                        title: 'Successfully Updated', icon: "success", timer: 1500
                                    }).then(function () {
                                        history.push('/viewtask');
                                        window.location.href="viewtask";
                                    });
                                }

                            }
                            else {
                                Swal.fire({
                                    title: 'Successfully Inserted', icon: "success", timer: 1500
                                });
                            }
                            this.ClearData();
                            //this.GetMuncipality();
                        }
                        else {
                            Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                        }
                    }, error => { })

                }

            }
            else {
                if (CompanyID==""&&ClientID=="") {
                    Swal.fire({
                        title: 'Please Select Company & Client', icon: "error", timer: 1500
                    });
                }
                else if (CompanyID!=""&&ClientID=="") {
                    Swal.fire({
                        title: 'Please Select Client', icon: "error", timer: 1500
                    });
                }
                else if (CompanyID==""&&ClientID!="") {
                    Swal.fire({
                        title: 'Please Select Company', icon: "error", timer: 1500
                    });
                }
                else {
                    Swal.fire({
                        title: 'Something went wrong', icon: "error", timer: 1500
                    });
                }
            }

        }

    }
    render() {
        const { cookies }=this.props
        var Permission=cookies.cookies.Task;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
            }
        }
        return (
            <>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row">

                        <div className="col-md-12">
                            {
                                this.state.AddStatus==="AddStatus:true"&&
                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header">
                                        <h3 className="card-title">Add Task</h3>
                                    </div>
                                    <FormWithConstraints
                                        ref={form => this.form=form}
                                        onSubmit={this.handleSubmit}
                                        noValidate>
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Task Type <span className="text-danger">*</span></label>
                                                        <Select options={this.state.bookingtypes} value={this.state.BookingTypeID} onChange={this.onBookingTypeChange} />
                                                        {this.state.BookingTypeErrMsg&&<span className="text-danger">{this.state.BookingTypeErrMsg==='required'? 'Please select booking type':''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Task No <span className="text-danger">*</span> </label>
                                                        <div class="input-group">
                                                            <input type="text" name="BookingNo" required value={this.state.BookingNo} onChange={this.CheckBookingNo} onKeyPress={this.allowOnlyNumbers} className="form-control" placeholder="Enter Task No" />


                                                            <div class="input-group-append">
                                                                <span class="input-group-text line-height-0 py-0">
                                                                    <i className="ki ki-reload" onClick={this.GetBookingNo} ></i>
                                                                </span>
                                                            </div>

                                                        </div>
                                                        <FieldFeedbacks for="BookingNo">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                        {this.state.AlreadyBookingNoErrMsg&&<span className="text-danger">{this.state.AlreadyBookingNoErrMsg==='already'? 'Task no already available':''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Task Date </label>
                                                        {/* <input id="txtTaskDate" name="TaskDate"  required value={this.state.BookingDate} onClick={this.onBookingDateChange} type="text" className="form-control"
                                                        placeholder="Select Booking Date" /> */}
                                                        <DatePicker readOnly={true} dateFormat="dd-MM-yyyy" selected={this.state.BookingDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.BookingDate} onChange={(e) => {
                                                            this.setState({ BookingDate: e }, () => { this.ValidateTaskDate() });
                                                        }} />
                                                        {this.state.TaskDateErrMsg&&<span className="text-danger">{this.state.TaskDateErrMsg==='required'? 'Please select Date':''}</span>}
                                                        {/* <DatePicker dropdownMode="select"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        adjustDateOnChange minDate={new Date()} className="form-control" id="example-datepicker" value={this.state.BookingDate} selected={this.state.BookingDate} /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Zone <span className="text-danger">*</span> </label>
                                                        <Select isClearable={true} options={this.state.zones} value={this.state.ZoneID} onChange={this.onZoneChange} />
                                                        {this.state.ZoneErrMsg&&<span className="text-danger">{this.state.ZoneErrMsg==='required'? 'Please select zone':''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>District <span className="text-danger">*</span> </label>
                                                        <Select isClearable={true} options={this.state.districts} value={this.state.DistrictID} onChange={this.onDistrictChange} />
                                                        {this.state.DistrictErrMsg&&<span className="text-danger">{this.state.DistrictErrMsg==='required'? 'Please select district':''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Municipality <span className="text-danger">*</span> </label>
                                                        <Select isClearable={true} options={this.state.municipalities} value={this.state.MuncipalityID} onChange={this.onMunicipalityChange} />
                                                        {this.state.MunicipalityErrMsg&&<span className="text-danger">{this.state.MunicipalityErrMsg==='required'? 'Please select municipality':''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Ward <span className="text-danger">*</span> </label>
                                                        <Select isClearable={true} options={this.state.wards} value={this.state.WardID} onChange={this.onWardChange} />
                                                        {this.state.WardErrMsg&&<span className="text-danger">{this.state.WardErrMsg==='required'? 'Please select ward':''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Country </label>
                                                        <Select options={this.state.countries} value={this.state.CountryID} onChange={this.onCountryChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>State <span className="text-danger">*</span></label>
                                                        <Select isClearable={true} options={this.state.states} value={this.state.StateID} onChange={this.onStateChange} />
                                                        {this.state.StateErrMsg&&<span className="text-danger">{this.state.StateErrMsg==='required'? 'Please select state':''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>City <span className="text-danger">*</span></label>
                                                        <Select isClearable={true} options={this.state.cities} value={this.state.CityID} onChange={this.onCityChange} />
                                                        {this.state.CityErrMsg&&<span className="text-danger">{this.state.CityErrMsg==='required'? 'Please select city':''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Locality <span className="text-danger">*</span></label>
                                                        <input type="text" name="Locality" required value={this.state.Locality} onChange={(e) => this.setState({ Locality: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Locality" />
                                                        <FieldFeedbacks for="Locality">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Priority <span className="text-danger">*</span></label>
                                                        <Select isClearable={true} options={this.state.priorities} value={this.state.Priority} onChange={this.onPriorityChange} />
                                                        {this.state.PriorityErrMsg&&<span className="text-danger">{this.state.PriorityErrMsg==='required'? 'Please select priority':''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Drainage Lines <span className="text-danger">*</span></label>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="row" style={{ marginLeft: '0', marginRight: '0', marginTop: '5%' }}>
                                                                    <div className="col-md-2" style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <div className="">
                                                                            <label className="checkbox">

                                                                                <input checked={this.state.is150to300Checked} value={this.state.is150to300Checked} style={{ marginRight: 0 }} type="checkbox" onChange={this.on150to300Change} />
                                                                                <span />

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-7" style={{ display: 'flex', alignItems: 'center', paddingTop: '3%' }}>
                                                                        <label>150 to 300 mm Diameter</label>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <input type="text" name="DrainageLine150to300mmDiaMeter" disabled={true} value={this.state.DrainageLine150to300mmDiaMeter} className="form-control" placeholder="" />
                                                                    </div>

                                                                </div>
                                                                <div className="row" style={{ marginLeft: '0', marginRight: '0', marginTop: '5%' }}>
                                                                    <div className="col-md-2" style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <div className="">
                                                                            <label className="checkbox">

                                                                                <input checked={this.state.is301to600Checked} value={this.state.is301to600Checked} style={{ marginRight: 0 }} type="checkbox" onChange={this.on301to600Change} />
                                                                                <span />

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-7" style={{ display: 'flex', alignItems: 'center', paddingTop: '3%' }}>
                                                                        <label>301 to 600 mm Diameter</label>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <input type="text" name="DrainageLine301to600mmDiaMeter" disabled={true} value={this.state.DrainageLine301to600mmDiaMeter} className="form-control" placeholder="" />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="row" style={{ marginLeft: '0', marginRight: '0', marginTop: '5%' }}>
                                                                    <div className="col-md-2" style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <div className="">
                                                                            <label className="checkbox">

                                                                                <input checked={this.state.is601to900Checked} value={this.state.is601to900Checked} style={{ marginRight: 0 }} type="checkbox" onChange={this.on601to900Change} />
                                                                                <span />

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-7" style={{ display: 'flex', alignItems: 'center', paddingTop: '3%' }}>
                                                                        <label>601 to 900 mm Diameter</label>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <input type="text" name="DrainageLine601to900mmDiaMeter" disabled={true} value={this.state.DrainageLine601to900mmDiaMeter} className="form-control" placeholder="" />
                                                                    </div>

                                                                </div>
                                                                <div className="row" style={{ marginLeft: '0', marginRight: '0', marginTop: '5%' }}>
                                                                    <div className="col-md-2" style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <div className="">
                                                                            <label className="checkbox">

                                                                                <input checked={this.state.is901to1200Checked} value={this.state.is901to1200Checked} style={{ marginRight: 0 }} type="checkbox" onChange={this.on901to1200Change} />
                                                                                <span />

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-7" style={{ display: 'flex', alignItems: 'center', paddingTop: '3%' }}>
                                                                        <label>901 to 1200 mm Diameter</label>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <input type="text" name="DrainageLine901to1200mmDiaMeter" disabled={true} value={this.state.DrainageLine901to1200mmDiaMeter} className="form-control" placeholder="" />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                {this.state.DrainageErrMsg&&<span className="text-danger">{this.state.DrainageErrMsg==='required'? 'Please select drainage line':''}</span>}
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Remarks </label>
                                                        <textarea rows={3} className="form-control" value={this.state.Remarks} onChange={(e) => this.setState({ Remarks: e.target.value })} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="row" style={{ maxWidth: 'fit-content',marginLeft:'0' }}>
                                                <button type="submit" onSubmit={this.handleSubmit} className="btn btn-primary mr-2">
                                                    Submit
                                                </button>
                                                <button type="button" onClick={this.ClearData} className="btn btn-secondary">
                                                    Cancel
                                                </button>

                                                {(this.props.allCookies.PanelUserType!==undefined)? (
                                                    (this.props.allCookies.PanelUserType=="Sub Admin")? (
                                                        <div className="" style={{ maxWidth: 'fit-content' }}>
                                                            <NavLink to={"/viewsubadmintask"} className="btn btn-outline-primary font-weight-bolder ml-2">View
                                                            </NavLink>
                                                        </div>):
                                                        <div className="" style={{ maxWidth: 'fit-content' }}>
                                                            <NavLink to={"/viewtask"} className="btn btn-outline-primary font-weight-bolder ml-2">
                                                                View
                                                            </NavLink >
                                                        </div>
                                                ):''
                                                }

                                                {/* <a href="/viewbooking" className="btn btn-outline-primary font-weight-bolder ml-2">
                                            View</a> */}
                                            </div>
                                        </div>
                                    </FormWithConstraints>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default withCookies(subadminbooking);