import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import services from "../services/services";
import Swal from "sweetalert2";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { data } from "jquery";
const headerTblStyle={ color: 'black' };

class noaccess extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "",ExcelStatus: "",
            data: [], municipalities: [],
            MuncipalityID: { 'label': '---Select Muncipality---', 'value': '---Select Muncipality---' },
            SearchMuncipalityID: { 'label': '---Select Muncipality---', 'value': '---Select Muncipality---' },
            WardID: "", WardName: "",
            MuncipalityErrMsg: "",
            iconAdd: "fa fa-plus", iconFilter: "fa fa-plus", isFilterVisible: false,
            // rows: [{ Email: '', DistrictEmailDetailID: '' }],
            
        }

    }



    render() {

        return (
            <>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="d-flex flex-column flex-root">
                        <div className="error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center" style={{ backgroundImage: 'url(../../../../theme/html/demo7/dist/assets/media/error/bg3.jpg)' }}>
                            <div className="px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center">
                                <h1 className="error-title text-stroke text-transparent">404</h1>
                                <p className="display-4 font-weight-boldest text-white mb-12">How did you get here</p>
                                <p className="font-size-h1 font-weight-boldest text-dark-75">Sorry we can't seem to find the page you're looking for.</p>
                                <p className="font-size-h4 line-height-md">There may be a misspelling in the URL entered,or the page you are looking for may no longer exist.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}
export default noaccess