import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { WithRouter } from "react-router";
import { connect } from "react-redux";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import { instanceOf } from 'prop-types';
import DatePicker from 'react-datepicker';
import { withCookies, Cookies } from 'react-cookie';
import XLSX from "xlsx-color";
import moment from 'moment';
const headerTblStyle={ color: 'black' };
const exportToExcel=(columns, data) => {
    debugger;
    const header=["Sr.No", "ModuleName",  "Operation", "Description", "FullName", "PersonalMobileNo", "CreatedDate"];
    const headertemp=["Sr.No", "Module Name", "Operation", "Description", "Full Name", "Mobile No", "Date"];
    debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else if (col=="ModuleName") {
                obj["Module Name"]=row[col];
            }
            else if (col=="FullName") {
                obj["Full Name"]=row[col];
            }
            else if (col=="PersonalMobileNo") {
                obj["Mobile No"]=row[col];
            }
            else if (col=="CreatedDate") {
                obj["Date"]=row[col];
            }
            else {
                obj[col]=row[col];
            }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols']=[
        { width: 6 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        


    ];
    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;
    ws1['D1'].s=cell;
    ws1['E1'].s=cell;
    ws1['F1'].s=cell;
    ws1['G1'].s=cell;

    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;
        ws1['D'+ins].s=RowCont;
        ws1['E'+ins].s=RowCont;
        ws1['F'+ins].s=RowCont;
        ws1['G'+ins].s=RowCont;
        

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "AuditLogReport");
    XLSX.writeFile(wb, "AuditLogReport"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}
class AuditLog extends Component {
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [],
            StartDate: new Date(), EndDate: new Date(),
            iconAdd: "fa fa-plus", iconFilter: "fa fa-plus", isFilterVisible: false,
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'center' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'Module Name', width: '20%', field: 'ModuleName', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px',textAlign:'left' }}>{rowData.ModuleName}</p>) } },
                { title: 'Operation', width: '10%', field: 'Operation', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Operation}</p>) } },
                { title: 'Description', width: '20%', field: 'Description', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Description}</p>) } },
                { title: (<div className="mobauditwidth" style={{ width: '20%' }}><span>Updated By</span></div>), width: '30%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.FullName + ' (' + rowData.EntryByUserType + ' )' }<hr />{rowData.PersonalMobileNo}</p>) } },
                { title: 'Date', width: '10%', field: 'CreatedDate', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CreatedDate}</p>) } },

            ]
        }
    }
    
    componentDidMount() {
        this.GetAuditLog();
    }
    GetAuditLog() {
        debugger;
        var CompanyID="";
        debugger;
        var ClientID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        var dateString=this.state.StartDate;
        var dateMomentObject=moment(dateString, "DD-MM-YYYY"); // 1st argument - string, 2nd argument - format
        var date=dateMomentObject.toDate();
        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "StartDate": date,
                "EndDate": this.state.EndDate,
            });
            var config={
                method: 'POST',
                url: services.API_URL+"AuditLog/getAuditLog",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                if(response.data.status)
                {
                    this.setState({ data: response.data.data })
                }
                else{
                    this.setState({ data: [] });
                }
                
            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }
    }
    onStartDateChange=(e) => {
        this.setState({ StartDate: e });
        this.setState({ EndDate: e });
    }
    onSearchAuditLogClick=(e)=>{
        e.preventDefault();
        this.GetAuditLog();
    }
    OnSearchCancelAuditLogClick=()=>{
    this.setState({data: [],
        StartDate: new Date(), EndDate: new Date()},()=>{this.GetAuditLog()})
    }
    OnFilterClick=(e) => {
        e.preventDefault();
        if (this.state.isFilterVisible===false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }
    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.data)
    }
    render() {
        // const { selected } = this.state;
        const { cookies }=this.props
        debugger;
        var Permission=cookies.cookies.ViewAuditLog;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <>
                {
                    this.state.ViewStatus==="ViewStatus:true"&&
                    <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                        {this.state.isFilterVisible&&
                            <div className="row" style={{ marginBottom: '1%' }} >

                                <div className="col-md-3">
                                    <div className="form-group formgroupcss">
                                        <label>Start Date </label>
                                        <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.StartDate} onChange={this.onStartDateChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group formgroupcss">
                                        <label>End Date </label>
                                        <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.EndDate} minDate={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.EndDate} onChange={(e) => {
                                            this.setState({ EndDate: e });
                                        }} />
                                    </div>
                                </div>
                               

                                <div className="col-md-3">
                                    <button type="button" onClick={this.onSearchAuditLogClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                    <button type="button" onClick={this.OnSearchCancelAuditLogClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-custom">
                                    <div className="card-body">
                                        <MaterialTable columns={this.state.cols} data={this.state.data}
                                            options={{
                                                headerStyle: { color: 'black' }, toolbar: true, search: false,draggable:false,
                                                paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                            }}
                                            components={{
                                                Toolbar: props => (
                                                    <div className="row" style={{ marginBottom: '2%' }}>
                                                        <div className="col-md-8" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h3 className="tabletitle">Audit Log</h3>
                                                        </div>
                                                        <div className="col-md-4" style={{ textAlign: 'right' }}>
                                                            <a className="btn btn-outline-dark font-weight-bolder" onClick={this.OnFilterClick}>
                                                                <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                            </a>
                                                            {this.state.ExcelStatus==="ExcelStatus:true"&&
                                                                <a disabled="disabled" className="btn btn-outline-success font-weight-bolder ml-4" onClick={this.state.data.length>0? this.ExcelClick:''}>
                                                                    <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                                </a>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}
export default withCookies(AuditLog);