import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import XLSX from "xlsx-color";
import moment from 'moment';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';

const headerTblStyle={ color: 'black' };
const exportToExcel=(columns, data) => {
    // debugger;
    const header=["Sr.No", "CountryName", "StateName"];
    const headertemp=["Sr.No", "CountryName", "StateName"];
    // debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else if (col=="CountryName") {
                obj["Country Name"]=row[col];
            }
            else if (col=="StateName") {
                obj["State Name"]=row[col];
            }
            else {
                obj[col]=row[col];
            }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: ["State List"] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    // XLSX.utils.book_append_sheet(wb, ws1, 'Country List');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols']=[
        { width: 6 },
        { width: 18 },
        { width: 18 },
    ];
    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;


    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "StateList");
    XLSX.writeFile(wb, "StateList_"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}
class state extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            name: cookies.get('name')||'Ben'
        };
        this.state={
            pathName: "/state",
            data: [], StateName: "", StateID: "", countries: [], SelectCountryLabel: "", SelectCountryValue: "",
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            CountryID: { 'label': '--Select Country--', 'value': '--Select Country--' },
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'center' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'Country Name', width: '30%', field: 'CountryName', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CountryName}</p>) } },
                { title: 'State Name', width: '50%', field: 'StateName', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.StateName}</p>) } },

            ]
        }

    }
    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.data)
    }
    allowOnlyCharacters=(event) => {
        const keyCode=event.keyCode||event.which;
        const keyValue=String.fromCharCode(keyCode);
        if (!new RegExp("^[a-zA-Z ]+$").test(keyValue)) event.preventDefault();
        return;
    };
    componentDidMount() {
        this.GetCountry();
        this.setState({ CountryID: { 'label': 'India', 'value': '1' } })
        this.GetState();

    }
    GetState() {
        var data=JSON.stringify({
            "StateID": ""
        });
        var config={
            method: 'POST',
            url: services.API_URL+"State/getState",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //// debugger;
            this.setState({ data: response.data.data })
        }, error => { })
    }
    GetCountry() {
        var data=JSON.stringify({
            "CountryID": ""
        });
        var config={
            method: 'POST',
            url: services.API_URL+"Country/getCountry",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            // debugger;
            this.setState({ countries: response.data.data.map(item => ({ value: item.CountryID, label: item.CountryName })) });
        }, error => { })
    }
    EditState(id) {
        var data=JSON.stringify({
            "StateID": id
        });
        var config={
            method: 'POST',
            url: services.API_URL+"State/getState",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            this.setState({
                StateID: response.data.data[0].StateID,
                CountryID: { 'label': response.data.data[0].CountryName, 'value': response.data.data[0].CountryID },
                StateName: response.data.data[0].StateName
            });
        }, error => { })
    }
    CheckAlert(id, statename) {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                var CompanyID="";
                var ClientID="";
                var UserID="";
                var UserType="";
                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                var data=JSON.stringify({
                    "StateID": id,
                    "StateName": statename,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+"State/removeState",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        this.GetState();
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }
    ClearData=(e) => {
        // debugger;
        this.setState({ StateID: "", StateName: "" });
        this.form.reset();
    }

    onChange=(e) => {
        // debugger;
        this.setState({ CountryID: e });
    }
    // ClearData = (e) => { this.form.reset(); e.preventDefault(); }
    handleSubmit=(e) => {
        // debugger;
        var CompanyID="";
        var ClientID="";
        var UserID="";
        var UserType="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID!==undefined) {
            UserID=this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType!==undefined) {
            UserType=this.props.allCookies.PanelUserType
        }
        e.preventDefault();
        this.form.validateFields();

        if (this.form.isValid()) {

            var data="";
            if (this.state.StateID!="") {
                data=JSON.stringify({
                    "StateID": this.state.StateID,
                    "CountryID": this.state.CountryID.value,
                    "StateName": this.state.StateName,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID,
                });
            }
            else {
                data=JSON.stringify({
                    "CountryID": this.state.CountryID.value,
                    "StateName": this.state.StateName
                });
            }

            var config={
                method: 'POST',
                url: services.API_URL+"State/setState",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //// debugger;
                if (response.data.status) {
                    // debugger;
                    if (this.state.StateID!="") {
                        // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                        Swal.fire({
                            title: 'Successfully Updated', icon: "success", timer: 1500
                        });
                    }
                    else {
                        Swal.fire({
                            title: 'Successfully Inserted', icon: "success", timer: 1500
                        });
                    }
                    this.ClearData();
                    this.GetState();
                }
                else {
                    Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                }
            }, error => { })
        }
        else {

        }
        var fields="";

    }

    render() {
        const { cookies }=this.props
        // debugger;
        var Permission=cookies.cookies.State;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <div>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row">
                        <div className="col-md-5">
                            {
                                this.state.AddStatus==="AddStatus:true"&&
                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header">
                                        <h3 className="card-title">Add State</h3>
                                    </div>
                                    <FormWithConstraints
                                        ref={form => this.form=form}
                                        onSubmit={this.handleSubmit}
                                        noValidate>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Country Name </label>
                                                        {/* <select style={{ width: '100%' }} options={this.state.countries} className="form-control select2" id="kt_select2_1" name="param">
                                                        <option value="AK">India</option>
                                                    </select> */}
                                                        <Select options={this.state.countries} value={this.state.CountryID} onChange={this.onChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>State Name <span className="text-danger">*</span></label>
                                                        <input type="text" required name="StateName" onKeyPress={this.allowOnlyCharacters} value={this.state.StateName} onChange={(e) => this.setState({ StateName: e.target.value })} className="form-control" placeholder="Enter State Name" />
                                                        <FieldFeedbacks for="StateName">
                                                            <FieldFeedback when="*"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" onSubmit={this.handleSubmit} className="btn btn-primary mr-2">
                                                Submit
                                            </button>
                                            <button type="button" onClick={this.ClearData} className="btn btn-secondary">
                                                Cancel
                                            </button>
                                        </div>
                                    </FormWithConstraints>
                                </div>
                            }
                        </div>
                        <div className="col-md-7">
                            {
                                this.state.ViewStatus==="ViewStatus:true"&&
                                <div className="card card-custom">

                                    <div className="card-body">
                                        <MaterialTable title="View State" columns={this.state.cols} data={this.state.data}
                                            actions={[{
                                                hidden: this.state.UpdateStatus=="UpdateStatus:true"? false:true,
                                                icon: 'edit', tooltip: 'Edit', onClick: (e, r) => this.EditState(r.StateID)
                                            },
                                            {
                                                hidden: this.state.DeleteStatus=="DeleteStatus:true"? false:true,
                                                icon: 'delete', tooltip: 'Delete', onClick: (e, r) => this.CheckAlert(r.StateID,r.StateName)
                                            }
                                            ]}
                                            options={{
                                                headerStyle: { color: 'black' }, toolbar: true, search: false,draggable: false,
                                                paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                            }}
                                            components={{
                                                Toolbar: props => (
                                                    <div className="row" style={{ marginBottom: '2%' }}>
                                                        <div className="col-md-9" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h3 className="tabletitle">View State</h3>

                                                        </div>
                                                        <div className="col-md-3" style={{ textAlign: 'right' }}>
                                                            {
                                                                this.state.ExcelStatus==="ExcelStatus:true"&&
                                                                <a className="btn btn-outline-success font-weight-bolder" onClick={this.state.data.length>0? this.ExcelClick:''}>
                                                                    <i id="btnFilter" className="fa fa-file-excel" /> Excel
                                                                </a>

                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
export default withCookies(state);