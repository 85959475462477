import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import XLSX from "xlsx-color";
import moment from 'moment';
const headerTblStyle={ color: 'black' };
const exportToExcel=(columns, data) => {
    // debugger;
    const header=["Sr.No", "ZoneName", "DistrictName", "CodeName", "MuncipalityName", "MuncipalityEmailDetail", "MuncipalityMobileNoDetail"];
    const headertemp=["Sr.No", "Zone Name", "District Name", "Code", "Muncipality Name", "Email", "Mobile No"];
    // debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else if (col=="ZoneName") {
                obj["Zone Name"]=row[col];
            }
            else if (col=="DistrictName") {
                obj["District Name"]=row[col];
            }
            else if (col=="CodeName") {
                obj["Code"]=row[col];
            }
            else if (col=="MuncipalityName") {
                obj["Municipality Name"]=row[col];
            }
            else if (col=="MuncipalityEmailDetail") {
                var EmailID="";
                if (row["MuncipalityEmailDetail"]!=null) {
                    for (let i=0; i<row["MuncipalityEmailDetail"].length; i++) {
                        //  const element = array[i];
                        EmailID+=row["MuncipalityEmailDetail"][i]["Email"]+","
                    }
                    EmailID=EmailID.substring(0, EmailID.length-1);
                }
                obj["Email"]=EmailID;
            }
            else if (col=="MuncipalityMobileNoDetail") {
                // debugger;
                var MobileNo="";
                if (row["MuncipalityMobileNoDetail"]!=null) {
                    for (let i=0; i<row["MuncipalityMobileNoDetail"].length; i++) {
                        //  const element = array[i];
                        MobileNo+=row["MuncipalityMobileNoDetail"][i]["MobileNo"]+","
                    }
                    MobileNo=MobileNo.substring(0, MobileNo.length-1);
                }
                obj["MobileNo"]=MobileNo;
            }
            else {
                obj[col]=row[col];
            }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    // XLSX.utils.book_append_sheet(wb, ws1, 'Country List');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols']=[
        { width: 6 },
        { width: 18 },
        { width: 18 },
        { width: 18 },
        { width: 20 },
        { width: 30 },
        { width: 30 },
    ];
    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;
    ws1['D1'].s=cell;
    ws1['E1'].s=cell;
    ws1['F1'].s=cell;
    ws1['G1'].s=cell;


    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;
        ws1['D'+ins].s=RowCont;
        ws1['E'+ins].s=RowCont;
        ws1['F'+ins].s=RowCont;
        ws1['G'+ins].s=RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "MunicipalityList");
    XLSX.writeFile(wb, "MunicipalityList_"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}
class municipality extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [], districts: [],
            DistrictID: { 'label': '--Select District--', 'value': '--Select District--' },
            SearchDistrictID: { 'label': '--Select District--', 'value': '--Select District--' },
            MuncipalityID: "", MuncipalityName: "", CodeName: "",
            DistrictErrMsg: "",
            AddMunicipalityVisible: false, iconAdd: "fa fa-plus", iconFilter: "fa fa-plus", isFilterVisible: false, isClear: false,
            rows: [{ Email: '', MuncipalityEmailDetailID: '' }],
            mobilenorows: [{ MobileNo: "", MuncipalityMobileNoDetailID: "" }],
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'center' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'Status', width: '7%', field: 'IsActive', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<BootstrapSwitchButton onlabel='Yes' offlabel='No' onChange={this.onStatusChange(rowData.MuncipalityID)} checked={rowData.IsActive} size="md" onstyle="success" offstyle="danger" />) } },
                { title: 'Zone Name', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.ZoneName}</p>) } },
                { title: 'District Name', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.DistrictName}</p>) } },
                // { title: 'Company Name', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CompanyName}</p>) } },
                // { title: 'Client Name', width: '15%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.ClientName}</p>) } },
                { title: 'Code', width: '15%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CodeName}</p>) } },
                { title: 'Muncipality Name', width: '30%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.MuncipalityName}</p>) } }
            ]
        }

    }

    componentDidMount() {
        this.GetMuncipality();
        this.GetDistrict();
    }

    GetMuncipality() {
        // debugger;
        var CompanyID="";
        var ClientID="";
        var SearchDistrictID=""

        if (this.state.SearchDistrictID.value!="--Select District--") {
            SearchDistrictID=this.state.SearchDistrictID.value;
        }
        else {
            SearchDistrictID="";
        }


        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "MuncipalityID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "DistrictID": SearchDistrictID,
                "IsActive": "",
                "IsDelete": "false"
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Muncipality/getMuncipality",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                if (response.data.status) {
                    if (this.state.ViewStatus==="ViewStatus:true") {
                        this.setState({ data: response.data.data })
                    }
                    else {
                        this.setState({ data: [] })
                    }
                }
                else {
                    this.setState({ data: [] })
                }

            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }

    GetDistrict() {
        var CompanyID="";
        var ClientID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        var data=JSON.stringify({
            "DistrictID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID
        });
        var config={
            method: 'POST',
            url: services.API_URL+"District/getDistrictDropDownData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            // debugger;
            this.setState({ districts: response.data.data.map(item => ({ value: item.DistrictID, label: item.DistrictName })) });
        }, error => { })
    }
    ValidateDistrict=(e) => {
        this.setState({ DistrictErrMsg: '' })
    }

    onDistrictChange=(e) => {
        // debugger;
        if (e!=null) {
            this.setState({ DistrictID: e }, (e) => { this.ValidateDistrict(); });

        }
        else {
            this.setState({ DistrictID: e }, (e) => { this.ValidateDistrict(); });
        }

    }

    onStatusChange=(MuncipalityID) => (Status) => {
        // e.preventDefault();
        // debugger;
        var IsStatus=Status.toString();


        var data=JSON.stringify({
            "MuncipalityID": MuncipalityID,
            "IsActive": IsStatus
        });
        var config={
            method: 'POST',
            url: services.API_URL+"Muncipality/setMuncipalityIsActiveStatus",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                //this.setState({ data: response.data.data })
                Swal.fire({
                    title: 'Successfully Updated', icon: "success", timer: 1500
                });
            }
            else {
                Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                // this.setState({ data: [] })
            }
            this.GetMuncipality();

        }, error => { })
    }

    EditMuncipality(id) {
        var CompanyID="";
        var ClientID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "MuncipalityID": id,
                "CompanyID": CompanyID,
                "ClientID": ClientID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Muncipality/getMuncipality",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                this.setState({ AddMunicipalityVisible: true });
                this.setState({ iconAdd: "fa fa-minus" });
                // debugger;
                this.setState({
                    MuncipalityID: response.data.data[0].MuncipalityID,
                    DistrictID: { 'label': response.data.data[0].DistrictName, 'value': response.data.data[0].DistrictID },
                    MuncipalityName: response.data.data[0].MuncipalityName,
                    CodeName: response.data.data[0].CodeName,
                    //rows: response.data.data[0].MuncipalityEmailDetail
                });
                if (response.data.data[0].MuncipalityEmailDetail!=null) {
                    this.setState({ rows: response.data.data[0].MuncipalityEmailDetail });
                }
                else {
                    this.setState({ rows: [{ Email: '', MuncipalityEmailDetailID: '' }] });
                }

                if (response.data.data[0].MuncipalityMobileNoDetail!=null) {
                    this.setState({ mobilenorows: response.data.data[0].MuncipalityMobileNoDetail });
                }
                else {
                    this.setState({ mobilenorows: [{ MobileNo: '', MuncipalityMobileNoDetailID: '' }] });
                }

                // this.setState({ IsEdit: false });
            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }

    handleAddRow=() => {
        // debugger;
        const rows=[...this.state.rows]
        const item={
            Email: "",
            MuncipalityEmailDetailID: ""
        };
        this.setState({
            rows: [...this.state.rows, item]
        });
    }

    handleRemoveRow=() => {
        this.setState({
            rows: this.state.rows.slice(0, -1)
        });
    };

    handleRemoveSpecificRow=(idx) => () => {

        if (idx>0) {
            const rows=[...this.state.rows]
            var data=JSON.stringify({
                "MuncipalityEmailDetailID": rows[idx].MuncipalityEmailDetailID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Muncipality/removeMuncipalityEmail",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //// debugger;
                //this.setState({ data: response.data.data })
            }, error => { })
            rows.splice(idx, 1)
            this.setState({ rows })
        }
        else {
            Swal.fire({ icon: 'error', title: 'Cannot Remove Row', showConfirmButton: false, timer: 1500 });
        }

    }

    onEmailChange=idx => e => {
        // debugger;
        const { value }=e.target;
        var rows=[...this.state.rows], result=rows[idx];
        rows[idx]={
            MuncipalityEmailDetailID: rows[idx].MuncipalityEmailDetailID,
            Email: value
        };
        this.setState({ rows }, () => { this.form.validateFields(e.target) });
    }

    handleAddMobileNoRow=() => {
        // debugger;
        const mobilenorows=[...this.state.mobilenorows]
        const item={
            MobileNo: "",
            MuncipalityMobileNoDetailID: ""
        };
        this.setState({
            mobilenorows: [...this.state.mobilenorows, item]
        });
    }

    handleRemoveSpecificMobileNoRow=(idx) => () => {

        if (idx>0) {
            const mobilenorows=[...this.state.mobilenorows]

            var data=JSON.stringify({
                "MuncipalityMobileNoDetailID": mobilenorows[idx].MuncipalityMobileNoDetailID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Muncipality/removeMuncipalityMobileNo",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //// debugger;
                //this.setState({ data: response.data.data })
            }, error => { })
            mobilenorows.splice(idx, 1)
            this.setState({ mobilenorows })
        }
        else {
            Swal.fire({ icon: 'error', title: 'Cannot Remove Mobile No Row', showConfirmButton: false, timer: 1500 });
        }

    }

    onMobileNoChange=idx => e => {
        // debugger;
        const { value }=e.target;
        var mobilenorows=[...this.state.mobilenorows], result=mobilenorows[idx];
        mobilenorows[idx]={
            MuncipalityMobileNoDetailID: mobilenorows[idx].MuncipalityMobileNoDetailID,
            MobileNo: value
        };
        this.setState({ mobilenorows }, () => { this.form.validateFields(e.target) });
    }

    ClearData=(e) => {
        // debugger;
        this.setState({
            districts: [],
            DistrictID: { 'label': '--Select District--', 'value': '--Select District--' },
            MuncipalityID: "", MuncipalityName: "", CodeName: "",
            DistrictErrMsg: "",
            rows: [{ Email: '', MuncipalityEmailDetailID: "" }],
            mobilenorows: [{ MobileNo: "", MuncipalityMobileNoDetailID: "" }],
        });
        this.form.reset();
        this.GetDistrict();
    }

    CheckAlert(id, municipalityname) {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {

            if (result.isConfirmed) {
                var CompanyID="";
                var ClientID="";
                var UserID="";
                var UserType="";
                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                var data=JSON.stringify({
                    "MuncipalityID": id,
                    "MuncipalityName": municipalityname,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID,
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+"Muncipality/removeMuncipality",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        this.GetMuncipality();
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        });
                    } else {
                        Swal.fire({ icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }

    handleSubmit=(e) => {
        // debugger;


        e.preventDefault();

        this.form.validateFields();


        if (this.state.DistrictID.value=="--Select District--") {
            this.setState({ DistrictErrMsg: 'required' });
        }

        if (this.form.isValid()&&this.state.DistrictID.value!="--Select District--") {
            const rows=[...this.state.rows]
            const mobilenorows=[...this.state.mobilenorows]
            var CompanyID="";
            var ClientID="";
            var UserID="";
            var UserType="";

            for (let index=0; index<rows.length; index++) {
                if (rows[index].Email=='') {
                    rows[index]=[]
                }
            }

            for (let index=0; index<mobilenorows.length; index++) {
                if (mobilenorows[index].MobileNo=='') {
                    mobilenorows[index]=[]
                }
            }


            if (this.props.allCookies.MainCompanyID!==undefined) {
                CompanyID=this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID!==undefined) {
                ClientID=this.props.allCookies.MainClientID
            }
            if (this.props.allCookies.UserID!==undefined) {
                UserID=this.props.allCookies.UserID
            }
            if (this.props.allCookies.PanelUserType!==undefined) {
                UserType=this.props.allCookies.PanelUserType
            }

            if (CompanyID!=""&&ClientID!="") {
                var data="";
                if (this.state.MuncipalityID!="") {
                    data=JSON.stringify({
                        "MuncipalityID": this.state.MuncipalityID,
                        "CompanyID": CompanyID,
                        "ClientID": ClientID,
                        "DistrictID": this.state.DistrictID.value,
                        "MuncipalityName": this.state.MuncipalityName,
                        "CodeName": this.state.CodeName,
                        "EntryByUserType": UserType,
                        "EntryByUserID": UserID,
                        "EmailData": rows,
                        "MobileNoData": mobilenorows
                    });
                }
                else {
                    data=JSON.stringify({
                        "MuncipalityID": "",
                        "CompanyID": CompanyID,
                        "ClientID": ClientID,
                        "DistrictID": this.state.DistrictID.value,
                        "MuncipalityName": this.state.MuncipalityName,
                        "CodeName": this.state.CodeName,
                        "EntryByUserType": "",
                        "EntryByUserID": "",
                        "EmailData": rows,
                        "MobileNoData": mobilenorows
                    });
                }

                var config={
                    method: 'POST',
                    url: services.API_URL+"Muncipality/setMuncipality",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    //// debugger;
                    if (response.data.status) {
                        // debugger;
                        if (this.state.MuncipalityID!="") {
                            // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                            Swal.fire({
                                title: 'Successfully Updated', icon: "success", timer: 1500
                            });
                        }
                        else {
                            Swal.fire({
                                title: 'Successfully Inserted', icon: "success", timer: 1500
                            });
                        }
                        this.ClearData();
                        this.GetMuncipality();
                    }
                    else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                }, error => { })
            }
            else {
                if (CompanyID==""&&ClientID=="") {
                    Swal.fire({
                        title: 'Please Select Company & Client', icon: "error", timer: 1500
                    });
                }
                else if (CompanyID!=""&&ClientID=="") {
                    Swal.fire({
                        title: 'Please Select Client', icon: "error", timer: 1500
                    });
                }
                else if (CompanyID==""&&ClientID!="") {
                    Swal.fire({
                        title: 'Please Select Company', icon: "error", timer: 1500
                    });
                }
                else {
                    Swal.fire({
                        title: 'Something went wrong', icon: "error", timer: 1500
                    });
                }
            }
        }

    }

    onAddMunicipalityClick=(e) => {
        if (this.state.AddMunicipalityVisible==false) {
            this.setState({ AddMunicipalityVisible: true });
            this.setState({ iconAdd: "fa fa-minus" });
        }
        else {
            this.setState({ AddMunicipalityVisible: false });
            this.setState({ iconAdd: "fa fa-plus" });
        }
    }
    OnFilterClick=(e) => {

        e.preventDefault();
        if (this.state.isFilterVisible===false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }

    OnSearchMunicipalityClick=(e) => {
        e.preventDefault();
        this.GetMuncipality();
    }

    OnSearchCancelMunicipalityClick=() => {
        // debugger;

        this.setState({ SearchDistrictID: { 'label': '--Select District--', 'value': '--Select District--' } }, () => {
            this.GetMuncipality();
        });


    }

    allowOnlyNumbers=(event) => {
        const keyCode=event.keyCode||event.which;
        const keyValue=String.fromCharCode(keyCode);
        if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
        return;
    };

    allowOnlyCharacters=(event) => {
        const keyCode=event.keyCode||event.which;
        const keyValue=String.fromCharCode(keyCode);
        if (!new RegExp("^[a-zA-Z ]+$").test(keyValue)) event.preventDefault();
        return;
    };

    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.data)
    }

    render() {
        const { cookies }=this.props
        // debugger;
        var Permission=cookies.cookies.Municipalities;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (

            <>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row">
                        {this.state.AddMunicipalityVisible&&
                            <div className="col-md-12">
                                {
                                    this.state.AddStatus==="AddStatus:true"&&
                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header">
                                            <h3 className="card-title">Add Municipality</h3>
                                        </div>
                                        <FormWithConstraints
                                            ref={form => this.form=form}
                                            onSubmit={this.handleSubmit}
                                            noValidate>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>District Name <span className="text-danger">*</span> </label>
                                                            <Select options={this.state.districts} value={this.state.DistrictID} onChange={this.onDistrictChange} />
                                                            {this.state.DistrictErrMsg&&<span className="text-danger">{this.state.DistrictErrMsg==='required'? 'Please Select District':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Code<span className="text-danger">*</span> </label>
                                                            <input type="text" name="CodeName" required value={this.state.CodeName} onChange={(e) => this.setState({ CodeName: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Code" />
                                                            <FieldFeedbacks for="CodeName">
                                                                <FieldFeedback when="valueMissing">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Municipality Name <span className="text-danger">*</span> </label>
                                                            <input type="text" name="MunicipalityName" required onKeyPress={this.allowOnlyCharacters} value={this.state.MuncipalityName} onChange={(e) => this.setState({ MuncipalityName: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Municipality Name" />
                                                            <FieldFeedbacks for="MunicipalityName">
                                                                <FieldFeedback when="valueMissing">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    {this.state.rows.map((item, idx) => (
                                                        <div className="col-md-3" key={idx}>
                                                            <div className="row" >
                                                                <div className="col-md-8" >
                                                                    <div className="form-group">
                                                                        <label>Email ID {idx+1} </label>
                                                                        <label>{ }</label>
                                                                        <input type="text" name={"Email"+idx} value={this.state.rows[idx].Email} onChange={this.onEmailChange(idx)} className="form-control" placeholder="Enter Email ID" />
                                                                        <FieldFeedbacks for={"Email"+idx}>
                                                                            {/* <FieldFeedback when={value => value.length>0? !/\S+@\S+/.test(value):''}>Invalid email address.</FieldFeedback> */}
                                                                            <FieldFeedback when={value => value.length>0? !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value):''}>Invalid email address.</FieldFeedback>
                                                                        </FieldFeedbacks>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <i onClick={this.handleAddRow} className="fa fa-plus-circle pt-10 mobpt-1 mobmr-5" style={{ fontSize: 30, color: '#3699ff', marginRight: '10%' }} />
                                                                        <i onClick={this.handleRemoveSpecificRow(idx)} className="fa fa-minus-circle pt-10 mobpt-1" style={{ fontSize: 30, color: '#3699ff' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    {this.state.mobilenorows.map((item, idx) => (
                                                        <div className="col-md-3" key={idx}>
                                                            <div className="row" >
                                                                <div className="col-md-8" >
                                                                    <div className="form-group">
                                                                        <label>Mobile No {idx+1} </label>
                                                                        <label>{ }</label>
                                                                        <input type="text" name={"MobileNo"+idx} onKeyPress={this.allowOnlyNumbers} maxLength='10' pattern=".{10,}" value={this.state.mobilenorows[idx].MobileNo} onChange={this.onMobileNoChange(idx)} className="form-control" placeholder="Enter Mobile No" />
                                                                        <FieldFeedbacks for={"MobileNo"+idx}>
                                                                            <FieldFeedback when="patternMismatch">
                                                                                Should be of 10 digits
                                                                            </FieldFeedback>
                                                                        </FieldFeedbacks>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <i onClick={this.handleAddMobileNoRow} className="fa fa-plus-circle pt-10 mobpt-1 mobmr-5" style={{ fontSize: 30, color: '#3699ff', marginRight: '10%' }} />
                                                                        <i onClick={this.handleRemoveSpecificMobileNoRow(idx)} className="fa fa-minus-circle pt-10 mobpt-1" style={{ fontSize: 30, color: '#3699ff' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" onSubmit={this.handleSubmit} className="btn btn-primary mr-2">
                                                    Submit
                                                </button>
                                                <button type="button" onClick={this.ClearData} className="btn btn-secondary">
                                                    Cancel
                                                </button>
                                            </div>
                                        </FormWithConstraints>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {this.state.isFilterVisible&&
                        this.state.ViewStatus==="ViewStatus:true"&&
                        <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>District Name </label>
                                    <Select options={this.state.districts} value={this.state.SearchDistrictID} onChange={(e) => this.setState({ SearchDistrictID: e })} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button type="button" onClick={this.OnSearchMunicipalityClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                <button type="button" onClick={this.OnSearchCancelMunicipalityClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-body">
                                    <MaterialTable columns={this.state.cols} data={this.state.data}
                                        detailPanel={[
                                            {
                                                icon: 'add', tooltip: 'Click here to see details', title: 'show',
                                                render: ({ rowData }) => {
                                                    // debugger;
                                                    if (rowData.MuncipalityEmailDetail!=null||rowData.MuncipalityMobileNoDetail!=null) {
                                                        return (
                                                            <>
                                                                <div style={{ width: '100%', padding: '5px', paddingLeft: '35px', display: 'block' }}>
                                                                    <table className="table table-light-dark" cellSpacing="0" rules="all" border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <td>Email ID</td>
                                                                                <td>Mobile No</td>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ width: '50%' }}>
                                                                                    <table cellSpacing="0" rules="all" border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                                                        <tr>
                                                                                            {
                                                                                                rowData.MuncipalityEmailDetail!=null&&
                                                                                                rowData.MuncipalityEmailDetail.map((value, inx1) => {
                                                                                                    return (
                                                                                                        <td style={{ lineHeight: '22px', padding: '4px', borderTop: '1px solid' }} >
                                                                                                            <p className="customnopaddingtd" style={{ padding: 'unset !important' }}>{value.Email}</p>
                                                                                                        </td>
                                                                                                    );
                                                                                                })}
                                                                                        </tr>
                                                                                    </table>

                                                                                </td>
                                                                                <td style={{ width: '50%' }}>
                                                                                    <table cellSpacing="0" rules="all" border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                                                        <tr>
                                                                                            {
                                                                                                rowData.MuncipalityMobileNoDetail!=null&&
                                                                                                rowData.MuncipalityMobileNoDetail.map((value, inx1) => {
                                                                                                    return (
                                                                                                        <td style={{ lineHeight: '22px', padding: '4px', borderTop: '1px solid' }} >
                                                                                                            <p className="customnopaddingtd" style={{ padding: 'unset !important' }}>{value.MobileNo}</p>
                                                                                                        </td>
                                                                                                    );
                                                                                                })}
                                                                                        </tr>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </>
                                                        );
                                                    }

                                                }
                                            }
                                        ]}
                                        actions={[{
                                            hidden: this.state.UpdateStatus=="UpdateStatus:true"? false:true,
                                            icon: 'edit', tooltip: 'Edit', onClick: (e, r) => this.EditMuncipality(r.MuncipalityID)
                                        },
                                        {
                                            hidden: this.state.DeleteStatus=="DeleteStatus:true"? false:true,
                                            icon: 'delete', tooltip: 'Delete', onClick: (e, r) => this.CheckAlert(r.MuncipalityID,r.MuncipalityName)
                                        }
                                        ]}
                                        options={{
                                            headerStyle: { color: 'black' }, toolbar: true, search: false,draggable:false,
                                            paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                        }}
                                        components={{

                                            Toolbar: props => (
                                                <div className="row" style={{ marginBottom: '2%' }}>
                                                    <div className="col-md-8 mobpb-5" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h3 className="tabletitle">View Muncipalities</h3>

                                                    </div>
                                                    <div className="col-md-4 mobta-left mobpl-0 mobpr-0" style={{ textAlign: 'right' }}>
                                                        {
                                                            this.state.AddStatus==="AddStatus:true"&&
                                                            <a onClick={this.onAddMunicipalityClick} className="btn btn-outline-primary font-weight-bolder mr-5 mobmr-1">
                                                                <span className="svg-icon svg-icon-md">
                                                                    <i id="btnAdd" className={this.state.iconAdd} />
                                                                </span>Add Municipality</a>
                                                        }
                                                        {
                                                            this.state.ViewStatus==="ViewStatus:true"&&
                                                            <>
                                                                <a className="btn btn-outline-dark font-weight-bolder mr-5 mobmr-1" onClick={this.OnFilterClick}>
                                                                    <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                                </a>
                                                                {
                                                                    this.state.ExcelStatus==="ExcelStatus:true"&&
                                                                    <a className="btn btn-outline-success font-weight-bolder" onClick={this.state.data.length>0? this.ExcelClick:''}>
                                                                        <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                                    </a>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}
export default withCookies(municipality);