import React, { Component } from "react";
import { connect } from "react-redux";
import './App.css';
import Routes from './Routing';
import { withCookies, Cookies } from 'react-cookie';
import { NavLink, Route, useLocation } from "react-router-dom";
//  import Popper from 'popper.js';
// // import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
// import "./plugins.bundle5883.js"
import { Row, Col, NavDropdown, Container, Navbar, Nav } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap.js';
import { clearMessage } from "./actions/message";
import { history } from './helpers/history';
import { instanceOf } from 'prop-types';
import Select from 'react-select'
import services from "./services/services";



// import { useLocation } from "react-router-dom";


const { REACT_APP_USERIMAGE_URL } = process.env;
class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props);
    const { cookies } = props;

    this.state = {
      name: cookies.get('name') || 'Ben', companies: [], clients: []
    };
    // const { pathname } = location;
    // const splitLocation = pathname.split("/");
    // console.log(props.allCookies.MobileNo);
    // this.logOut = this.logOut.bind(this);
    // this.logOut = this.logOut.bind(this);
    this.state = { currentUser: undefined, URLName: '', pathname: "", MenuName: "" };
    history.listen((location) => { props.dispatch(clearMessage()) });
    console.log(props.allCookies.ClientID);
    console.log("path", window.location.pathname);


    console.log("display", this.displayName);
    console.log("const", this.props.location);
    console.log("history", history);
    if (props.allCookies.PanelUserType === undefined) {
      if (history.location.pathname !== "/login" && history.location.pathname !== "/autoapprove" && history.location.pathname !== "/autosendmail" && history.location.pathname !== "/privacyandpolicy") {
        history.push('/');
      }

      //window.location.href="/";
    }

    //console.log("pathname", history);



  }
  componentDidMount() {
    // debugger;
    var APIName = "";
    var PanelUserType = "";
    var MobileNo = "";
    const { cookies } = this.props;
    console.log("componentDidMount", this.props.location);
    if (this.props.allCookies.PermissionDetail !== undefined) {
      var PerDetail = this.props.allCookies.PermissionDetail


    }

    this.GetCompany();



    if (this.props.allCookies.MainCompanyID !== undefined) {
      this.setState({ MainCompanyID: { 'label': this.props.allCookies.MainCompanyName, 'value': this.props.allCookies.MainCompanyID } });
      var data = JSON.stringify({
        "CompanyID": this.props.allCookies.MainCompanyID,
        "MobileNo": MobileNo
      });

      if (this.props.allCookies.PanelUserType !== undefined) {
        PanelUserType = this.props.allCookies.PanelUserType;

        if (PanelUserType == "Admin") {
          APIName = "Client/getClient";
        }
        else {
          APIName = "User/getUserClientData";
        }
      }
      else {
        APIName = "Client/getClient";
      }

      var config = {
        method: 'POST',
        url: services.API_URL + APIName,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      services.JsonValue(config).then(response => {

        // cookies.remove("MainClientID");
        // cookies.remove("MainClientName");
        // this.setState({ MainClientID: { 'label': '--Select Client--', 'value': '--Select Client--' } });
        if (response.data.status) {
          this.setState({ clients: response.data.data.map(item => ({ value: item.ClientID, label: item.ClientName })) });

        }
        else {
          this.setState({ clients: [] })

        }

      }, error => { })
    }

    if (this.props.allCookies.MainClientID !== undefined) {
      this.setState({ MainClientID: { 'label': this.props.allCookies.MainClientName, 'value': this.props.allCookies.MainClientID } });
    }
    else {
      if (this.props.allCookies.MainCompanyID !== undefined) {
        var data = JSON.stringify({
          "CompanyID": this.props.allCookies.MainCompanyID
        });

        var config = {
          method: 'POST',
          url: services.API_URL + "Client/getClient",
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        services.JsonValue(config).then(response => {

          cookies.remove("MainClientID");
          cookies.remove("MainClientName");
          this.setState({ MainClientID: { 'label': '--Select Client--', 'value': '--Select Client--' } });
          if (response.data.status) {
            this.setState({ clients: response.data.data.map(item => ({ value: item.ClientID, label: item.ClientName })) });

          }
          else {
            this.setState({ clients: [] })

          }

        }, error => { })
      }
    }

  }
  onCompanyChange = (e) => {
    var APIName = "";
    var PanelUserType = "";
    var MobileNo = "";
    const { cookies } = this.props;
    cookies.set('MainCompanyID', e.value, { path: '/' });
    cookies.set('MainCompanyName', e.label, { path: '/' });
    this.setState({ MainCompanyID: { 'label': e.label, 'value': e.value } });

    var data = JSON.stringify({
      "CompanyID": e.value,
      "MobileNo": MobileNo
    });

    if (this.props.allCookies.PanelUserType !== undefined) {
      PanelUserType = this.props.allCookies.PanelUserType;

      if (PanelUserType == "Admin") {
        APIName = "Client/getClient";
      }
      else {
        APIName = "User/getUserClientData";
      }
    }
    else {
      APIName = "Client/getClient";
    }

    var config = {
      method: 'POST',
      url: services.API_URL + APIName,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {

      cookies.remove("MainClientID");
      cookies.remove("MainClientName");
      this.setState({ MainClientID: { 'label': '--Select Client--', 'value': '--Select Client--' } });
      if (response.data.status) {
        this.setState({ clients: response.data.data.map(item => ({ value: item.ClientID, label: item.ClientName })) });

      }
      else {
        this.setState({ clients: [] })

      }

    }, error => { })

  }
  onClientChange = async (e) => {
    debugger;
    const { cookies } = this.props;
    cookies.set('MainClientID', e.value, { path: '/' });
    cookies.set('MainClientName', e.label, { path: '/' });
    this.setState({ MainClientID: { 'label': e.label, 'value': e.value } });

    var UserType = "";
    if (this.props.allCookies.PanelUserType !== undefined) {
      UserType = this.props.allCookies.PanelUserType;
    }


    var CompanyID = "";
    // var ClientID = "";
    var ClientID = e.value;
    var UserName = "";
    var MobileNo = "";

    if (this.props.allCookies.MainCompanyID !== undefined) {
      CompanyID = this.props.allCookies.MainCompanyID;
    }
    if (this.props.allCookies.UserName !== undefined) {
      UserName = this.props.allCookies.UserName;
      if (UserName == "null") {
        UserName = "";
      }
    }
    if (this.props.allCookies.MobileNo !== undefined) {
      MobileNo = this.props.allCookies.MobileNo;
    }

    let data = JSON.stringify({
      "CompanyID": CompanyID,
      // "ClientID": e.value,
      "ClientID": ClientID,
      "UserName": UserName,
      "MobileNo": MobileNo
    });
    // var config = {
    //   method: 'POST',
    //   url: services.API_URL + "User/getUserCompanyClientData",
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   data: data
    // };
    const response1 = await fetch(`${services.API_URL}User/getUserCompanyClientData`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: data
    })
    const response = await response1.json();
    if (response.status) {
      const { cookies } = this.props;
      cookies.set('UserName', response.data[0].UserName, { path: '/' });
      cookies.set('FullName', response.data[0].FullName, { path: '/' });
      cookies.set('UserImage', response.data[0].UserImage, { path: '/' });
      cookies.set('UserID', response.data[0].UserID, { path: '/' });

      if (UserType != "Admin") {
        let alldata = [];
        alldata = cookies.getAll();

        var tifOptions = Object.keys(alldata).map(function (key) {
          // debugger;
          if (key !== "FullName" && key !== "UserName" && key !== "UserImage" && key !== "UserID" && key !== "IsMultiple" &&
            key !== "MainClientID" && key !== "MainClientName" && key !== "MainCompanyID" && key !== "MainCompanyName" && key != "MainCompanyName"
            && key !== "MunicipalityID" && key !== "PanelUserType" && key !== "RedirectPage" && key !== "RedirectPage")
            cookies.remove(key);
        });

        //#region Bind Muncipality and Zone Data Company & Client Wise
        var tempUserID = response?.data?.[0].UserID || '';

        var tempdata = {
          "CompanyID": CompanyID,
          "ClientID": ClientID,
          "UserID": tempUserID
        };

        // var tempconfig = {
        //   method: 'POST',
        //   url: services.API_URL + "User/getUserCompanyClientData",
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        //   data: tempdata
        // };

        const res = await fetch(`${services.API_URL}User/getUserCompanyClientData`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...tempdata
          })
        })
        const tempresponse = await res.json()

        if (tempresponse.status) {
          var MunicipalityID = "";
          for (let i = 0; i < tempresponse.data[0].UserMuncipalityDetail.length; i++) {
            //  const element = array[index];
            MunicipalityID += tempresponse.data[0].UserMuncipalityDetail[i].MuncipalityID + ",";
          }
          MunicipalityID = MunicipalityID.substring(0, MunicipalityID.length - 1);
          cookies.set('MunicipalityID', MunicipalityID, { path: '/' });

          var tempZoneDetail = "";
          for (let i = 0; i < tempresponse.data[0].UserZoneDetail.length; i++) {
            //  const element = array[index];
            tempZoneDetail += tempresponse.data[0].UserZoneDetail[i].ZoneID + ",";
          }
          tempZoneDetail = tempZoneDetail.substring(0, tempZoneDetail.length - 1);
          cookies.set('ZoneIDs', tempZoneDetail, { path: '/' });
        }

        // services.JsonValue(tempconfig).then(tempresponse => {
        //   if (tempresponse.data.status) {
        //     debugger;
        //     var MunicipalityID = "";
        //     for (let i = 0; i < tempresponse.data.data[0].UserMuncipalityDetail.length; i++) {
        //       //  const element = array[index];
        //       MunicipalityID += tempresponse.data.data[0].UserMuncipalityDetail[i].MuncipalityID + ",";
        //     }
        //     MunicipalityID = MunicipalityID.substring(0, MunicipalityID.length - 1);
        //     cookies.set('MunicipalityID', MunicipalityID, { path: '/' });

        //     var tempZoneDetail = "";
        //     for (let i = 0; i < tempresponse.data.data[0].UserZoneDetail.length; i++) {
        //       //  const element = array[index];
        //       tempZoneDetail += tempresponse.data.data[0].UserZoneDetail[i].ZoneID + ",";
        //     }
        //     tempZoneDetail = tempZoneDetail.substring(0, tempZoneDetail.length - 1);
        //     cookies.set('ZoneIDs', tempZoneDetail, { path: '/' });
        //   }
        // });
        //#endregion Bind Muncipality and Zone Data Company & Client Wise

        if (response.data[0].UserPermissionDetail != null) {
          for (let i = 0; i < response.data[0].UserPermissionDetail.length; i++) {
            if (response.data[0].UserPermissionDetail[i].FunctionalityPage === "ViewAssignTask" || response.data[0].UserPermissionDetail[i].FunctionalityPage === "ViewStartTask" || response.data[0].UserPermissionDetail[i].FunctionalityPage === "ViewFinishTask" || response.data[0].UserPermissionDetail[i].FunctionalityPage === "ViewCompleteTask" || response.data[0].UserPermissionDetail[i].FunctionalityPage === "ViewApprovalTask" || response.data[0].UserPermissionDetail[i].FunctionalityPage === "ViewTask") {
              if (response.data[0].UserType == "Sub Admin" && response.data[0].UserPermissionDetail[i].FunctionalityPage == "ViewTask") {
                cookies.set("ViewSubAdminTask",
                  "AddStatus:" + response.data[0].UserPermissionDetail[i].AddStatus +
                  ",UpdateStatus:" + response.data[0].UserPermissionDetail[i].UpdateStatus +
                  ",DeleteStatus:" + response.data[0].UserPermissionDetail[i].DeleteStatus +
                  ",ViewStatus:" + response.data[0].UserPermissionDetail[i].ViewStatus +
                  ",PrintStatus:" + response.data[0].UserPermissionDetail[i].PrintStatus +
                  ",ExcelStatus:" + response.data[0].UserPermissionDetail[i].ExcelStatus, { path: '/' });

                cookies.set(response.data[0].UserPermissionDetail[i].FunctionalityPage,
                  "AddStatus:" + response.data[0].UserPermissionDetail[i].AddStatus +
                  ",UpdateStatus:" + response.data[0].UserPermissionDetail[i].UpdateStatus +
                  ",DeleteStatus:" + response.data[0].UserPermissionDetail[i].DeleteStatus +
                  ",ViewStatus:" + response.data[0].UserPermissionDetail[i].ViewStatus +
                  ",PrintStatus:" + response.data[0].UserPermissionDetail[i].PrintStatus +
                  ",ExcelStatus:" + response.data[0].UserPermissionDetail[i].ExcelStatus, { path: '/' });
              }
              else {
                cookies.set("ViewTask",
                  "AddStatus:" + response.data[0].UserPermissionDetail[i].AddStatus +
                  ",UpdateStatus:" + response.data[0].UserPermissionDetail[i].UpdateStatus +
                  ",DeleteStatus:" + response.data[0].UserPermissionDetail[i].DeleteStatus +
                  ",ViewStatus:" + response.data[0].UserPermissionDetail[i].ViewStatus +
                  ",PrintStatus:" + response.data[0].UserPermissionDetail[i].PrintStatus +
                  ",ExcelStatus:" + response.data[0].UserPermissionDetail[i].ExcelStatus, { path: '/' });

                cookies.set(response.data[0].UserPermissionDetail[i].FunctionalityPage,
                  "AddStatus:" + response.data[0].UserPermissionDetail[i].AddStatus +
                  ",UpdateStatus:" + response.data[0].UserPermissionDetail[i].UpdateStatus +
                  ",DeleteStatus:" + response.data[0].UserPermissionDetail[i].DeleteStatus +
                  ",ViewStatus:" + response.data[0].UserPermissionDetail[i].ViewStatus +
                  ",PrintStatus:" + response.data[0].UserPermissionDetail[i].PrintStatus +
                  ",ExcelStatus:" + response.data[0].UserPermissionDetail[i].ExcelStatus, { path: '/' });
              }

            }
            cookies.set(response.data[0].UserPermissionDetail[i].FunctionalityPage,
              "AddStatus:" + response.data[0].UserPermissionDetail[i].AddStatus +
              ",UpdateStatus:" + response.data[0].UserPermissionDetail[i].UpdateStatus +
              ",DeleteStatus:" + response.data[0].UserPermissionDetail[i].DeleteStatus +
              ",ViewStatus:" + response.data[0].UserPermissionDetail[i].ViewStatus +
              ",PrintStatus:" + response.data[0].UserPermissionDetail[i].PrintStatus +
              ",ExcelStatus:" + response.data[0].UserPermissionDetail[i].ExcelStatus, { path: '/' });
          }
        }
      }

    }
    else {

    }
    window.location.reload(true);

  }
  GetCompany() {
    const { cookies } = this.props;

    var data = "";
    var MobileNo = "";
    var APIName = "";
    var PanelUserType = "";
    var UserName = "";
    if (this.props.allCookies.MobileNo !== undefined) {
      MobileNo = this.props.allCookies.MobileNo;
    }
    if (this.props.allCookies.UserName !== undefined) {
      UserName = this.props.allCookies.UserName;
    }
    if (UserName == "null") {
      UserName = ""
    }
    if (MobileNo == "null") {
      MobileNo = ""
    }
    data = JSON.stringify({
      "CompanyID": "",
      "MobileNo": MobileNo,
      "UserName": UserName,
    });
    if (this.props.allCookies.PanelUserType !== undefined) {
      PanelUserType = this.props.allCookies.PanelUserType;

      if (PanelUserType == "Admin") {
        APIName = "Company/getCompany";
      }
      else {
        APIName = "User/getUserCompanyData";
      }
    }
    else {
      APIName = "Company/getCompany";
    }


    var config = {
      method: 'POST',
      url: services.API_URL + APIName,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    services.JsonValue(config).then(response => {
      //// debugger;
      this.setState({ companies: response.data.data.map(item => ({ value: item.CompanyID, label: item.CompanyName })) });
    }, error => { })
  }
  logout = (e) => {
    // debugger;
    e.preventDefault();
    const { cookies } = this.props;
    let alldata = [];
    alldata = cookies.getAll();

    var tifOptions = Object.keys(alldata).map(function (key) {
      // debugger;
      cookies.remove(key);
    });


    // cookies.remove("MobileNo");
    // cookies.remove("ClientID");
    // cookies.remove("MainCompanyID");
    // cookies.remove("MainCompanyName");
    // cookies.remove("MainClientID");
    // cookies.remove("MainClientName");
    // cookies.remove("UserID");
    // cookies.remove("PanelUserType");
    // cookies.remove("PermissionDetail");
    // cookies.remove("Menu");


    history.push('/');
    window.location.href = "/";
  }
  CompanyClick = (e) => {
    e.preventDefault();
    history.push('/company');
  }
  handleItemClick = (itemName) => {
    this.setState({ activeItem: itemName });
  };
  // clear message when changing location
  render() {
    return (
      <>
        <Routes />
        {(this.props.allCookies.PanelUserType !== undefined && window.location.pathname != "/" && window.location.pathname != "/login") ? (

          <div className="App">

            <div>

              <div id="kt_header_mobile" className="header-mobile bg-primary header-mobile-fixed">
                <a href="">
                  {/* <h3 style={{ color: 'white' }}>Metrro Waste Handling Pvt. Ltd.</h3> */}
                  <h3 style={{ color: 'white' }}>BlueBrigade</h3>
                </a>
                <div className="d-flex align-items-center">
                  <button className="btn p-0 burger-icon burger-icon-left ml-4" id="kt_header_mobile_toggle">
                    <span />
                  </button>
                  <button className="btn p-0 ml-2" id="kt_header_mobile_topbar_toggle">
                    <span className="svg-icon svg-icon-xl">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokewidth="{1}" fill="none" fillrule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillrule="nonzero" opacity="0.3" />
                          <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillrule="nonzero" />
                        </g>
                      </svg>
                    </span>

                  </button>
                </div>
              </div>
              <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-row flex-column-fluid page">
                  <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                    <div id="kt_header" className="header flex-column header-fixed">
                      <div className="header-top">
                        <div className="container">
                          <div className="d-none d-lg-flex align-items-center mr-3">
                            <a href="/dashboard" className="mr-20">
                              {/* <h3 style={{ color: 'white' }}>Metrro Waste Handling Pvt. Ltd.</h3> */}
                              <h3 style={{ color: 'white' }}>BlueBrigade</h3>
                            </a>
                          </div>
                          <div className="topbar bg-primary">
                            <div className="topbar-item mr-1">
                              <div>
                                <label className="companyclientheader">Company</label>
                                <label className="companyclientheader">{this.props.allCookies.MainCompanyName}</label>
                              </div>
                            </div>
                            <div className="topbar-item mr-1">
                              <div>
                                <label className="companyclientheader">Client</label>
                                <label className="companyclientheader">{this.props.allCookies.MainClientName}</label>
                              </div>
                            </div>
                            <div className="topbar-item">
                              <div className="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
                                <div className="d-flex flex-column text-right pr-sm-3">
                                  <span className="text-white font-weight-bolder font-size-sm d-none d-sm-inline">
                                    {/* {this.props.allCookies.PanelUserType} */}
                                    {
                                      this.props.allCookies.PanelUserType === "Admin" &&
                                      this.props.allCookies.PanelUserType}

                                    {
                                      this.props.allCookies.PanelUserType !== "Admin" &&
                                      this.props.allCookies.FullName}
                                  </span>
                                </div>
                                <span className="symbol symbol-35">
                                  <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30">A</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="header-bottom">
                        <div className="container">
                          <div className="header-navs header-navs-left" id="kt_header_navs">
                            <div className="tab-content">
                              <div className="tab-pane py-5 p-lg-0 show active" id="kt_header_tab_1">
                                <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                                  <ul className="menu-nav">
                                    {
                                      (this.props.allCookies.Dashboard !== undefined || this.props.allCookies.GudmDashboard !== undefined || this.props.allCookies.WorkInProgressDashboard !== undefined) ? (
                                        <li className={"menu-item " + (window.location.pathname == "/dashboard" ? "menu-item-here" : "")} aria-haspopup="true">
                                          {
                                            (this.props.allCookies.Dashboard !== undefined) ?
                                              (<NavLink to={"/dashboard"} className="menu-link">
                                                <span className="menu-text">Dashboard</span>
                                              </NavLink>) :
                                              (this.props.allCookies.GudmDashboard !== undefined) ?
                                                (<NavLink to={"/gudmdashboard"} className="menu-link">
                                                  <span className="menu-text">Dashboard</span>
                                                </NavLink>) :
                                                (this.props.allCookies.WorkInProgressDashboard !== undefined) ?
                                                  (<NavLink to={"/vehicledashboard"} className="menu-link">
                                                    <span className="menu-text">Dashboard</span>
                                                  </NavLink>) : ''
                                          }
                                        </li>
                                      ) : ''
                                    }
                                    {

                                      (this.props.allCookies.Country !== undefined || this.props.allCookies.State !== undefined ||
                                        this.props.allCookies.City !== undefined || this.props.allCookies.Zone !== undefined ||
                                        this.props.allCookies.District !== undefined || this.props.allCookies.Municipalities !== undefined ||
                                        this.props.allCookies.ParkingLocation !== undefined || this.props.allCookies.UserType !== undefined ||
                                        this.props.allCookies.FunctionalityPage !== undefined || this.props.allCookies.TaskType !== undefined) ? (
                                        <li className={"menu-item menu-item-submenu menu-item-open menu-item-rel " + (window.location.pathname == "/country" || window.location.pathname == "/state" || window.location.pathname == "/city" || window.location.pathname == "/zone" || window.location.pathname == "/district" || window.location.pathname == "/municipality" || window.location.pathname == "/parkinglocation" || window.location.pathname == "/usertype" || window.location.pathname == "/functionality" || window.location.pathname == "/tasktype" || window.location.pathname == "/wardmaster" ? "menu-item-active" : "")} data-menu-toggle="click" aria-haspopup="true">
                                          <a className="menu-link menu-toggle">
                                            <span className="menu-text">Master</span>
                                            <i className="menu-arrow" />
                                          </a>
                                          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                                            <ul className="menu-subnav">
                                              {this.props.allCookies.Country !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/country" ? "menu-item-here" : "")}
                                                  data-bs-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('country')}>
                                                  <NavLink to={"/country"} className="menu-link">
                                                    <span className="menu-text">Country</span>
                                                  </NavLink >
                                                </li>
                                              }
                                              {this.props.allCookies.State !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/state" ? "menu-item-here" : "")} aria-haspopup="true"
                                                  onClick={() => this.handleItemClick('state')}>
                                                  <NavLink to={"/state"} className="menu-link">
                                                    <span className="menu-text">State</span>
                                                  </NavLink >
                                                </li>
                                              }
                                              {this.props.allCookies.City !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/city" ? "menu-item-here" : "")} aria-haspopup="true"
                                                  onClick={() => this.handleItemClick('city')}>
                                                  <NavLink to={"/city"} className="menu-link">
                                                    <span className="menu-text">City</span>
                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.Zone !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/zone" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('zone')}>
                                                  <NavLink to={"/zone"} className="menu-link">
                                                    <span className="menu-text">Zone</span>
                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.District !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/district" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('district')}>
                                                  <NavLink to={"/district"} className="menu-link">
                                                    <span className="menu-text">District</span>
                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.Municipalities !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/municipality" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('municipality')}>
                                                  <NavLink to={"/municipality"} className="menu-link">
                                                    <span className="menu-text">Municipalities</span>
                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.ParkingLocation !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/parkinglocation" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('parkinglocation')}>
                                                  <NavLink to={"/parkinglocation"} className="menu-link">
                                                    <span className="menu-text">Parking Location</span>
                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.UserType !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/usertype" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('usertype')}>
                                                  <NavLink to={"/usertype"} className="menu-link">
                                                    <span className="menu-text">User Type</span>
                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.FunctionalityPage !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/functionality" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('functionality')}>
                                                  <NavLink to={"/functionality"} className="menu-link">
                                                    <span className="menu-text">Functionality Page</span>
                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.TaskType !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/tasktype" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('tasktype')}>
                                                  <NavLink to={"/tasktype"} className="menu-link">
                                                    <span className="menu-text">Task Type</span>
                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.WardMaster !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/wardmaster" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('wardmaster')}>
                                                  <NavLink to={"/wardmaster"} className="menu-link">
                                                    <span className="menu-text">Ward Master</span>
                                                  </NavLink>
                                                </li>
                                              }
                                            </ul>
                                          </div>
                                        </li>) : ''
                                    }
                                    {
                                      (this.props.allCookies.Company !== undefined || this.props.allCookies.Client !== undefined ||
                                        this.props.allCookies.CheckList !== undefined || this.props.allCookies.Users !== undefined ||
                                        this.props.allCookies.UserPermission !== undefined
                                      ) ? (

                                        <li className={"menu-item menu-item-submenu menu-item-open menu-item-rel " + (window.location.pathname == "/company" || window.location.pathname == "/client" || window.location.pathname == "/user" || window.location.pathname == "/userpermission" || window.location.pathname == "/checklist" || window.location.pathname == "/EmailTextConfiguration" || window.location.pathname == "/MessageTextConfiguration" ? "menu-item-active" : "")} data-menu-toggle="click" aria-haspopup="true">
                                          <a className="menu-link menu-toggle">
                                            <span className="menu-text">Configuration Master</span>
                                            <span className="menu-desc" />
                                            <i className="menu-arrow" />
                                          </a>
                                          <div className="menu-submenu menu-submenu-classic">
                                            <ul className="menu-subnav">
                                              {this.props.allCookies.Company !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/company" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('company')}>
                                                  <NavLink to={"/company"} className="menu-link">
                                                    <span className="menu-text">Company</span>
                                                  </NavLink >
                                                </li>
                                              }
                                              {this.props.allCookies.Client !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/client" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('client')}>
                                                  <NavLink to={"/client"} className="menu-link">
                                                    <span className="menu-text">Client</span>

                                                  </NavLink>
                                                </li>
                                              }

                                              {this.props.allCookies.Users !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/user" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('user')}>
                                                  <NavLink to={"/user"} className="menu-link">
                                                    <span className="menu-text">Users</span>
                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.UserPermission !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/userpermission" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('userpermission')}>
                                                  <NavLink to={"/userpermission"} className="menu-link">
                                                    <span className="menu-text">User Permission</span>
                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.CheckList !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/checklist" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('checklist')}>
                                                  <NavLink to={"/checklist"} className="menu-link">
                                                    <span className="menu-text">CheckList</span>

                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.EmailTextConfiguration !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/EmailTextConfiguration" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('EmailTextConfiguration')}>
                                                  <NavLink to={"/EmailTextConfiguration"} className="menu-link">
                                                    <span className="menu-text">Email Text Configuration</span>

                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.MessageTextConfiguration !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/MessageTextConfiguration" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('MessageTextConfiguration')}>
                                                  <NavLink to={"/MessageTextConfiguration"} className="menu-link">
                                                    <span className="menu-text">Message Text Configuration</span>
                                                  </NavLink>
                                                </li>
                                              }
                                            </ul>
                                          </div>
                                        </li>) : ''
                                    }
                                    {
                                      (this.props.allCookies.AddTask !== undefined || this.props.allCookies.ViewTask !== undefined) ? (
                                        <li className={"menu-item menu-item-submenu menu-item-open menu-item-rel " + (window.location.pathname == "/task" || window.location.pathname == "/viewtask" || window.location.pathname == "/viewsubadmintask" || window.location.pathname == "/viewadmintask" ? "menu-item-active" : "")} data-menu-toggle="click" aria-haspopup="true">
                                          <a className="menu-link menu-toggle">
                                            <span className="menu-text">Task</span>
                                            <span className="menu-desc" />
                                            <i className="menu-arrow" />
                                          </a>
                                          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                                            <ul className="menu-subnav">
                                              {this.props.allCookies.PanelUserType === "Supervisor" &&
                                                this.props.allCookies.AddTask !== undefined &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/task" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('task')}>
                                                  <NavLink to={"/task"} className="menu-link">
                                                    <span className="menu-text">Add Task</span>
                                                  </NavLink>
                                                </li>
                                              }
                                              {this.props.allCookies.ViewTask !== undefined &&
                                                this.props.allCookies.PanelUserType !== "Admin" &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/viewtask" || window.location.pathname == "/viewsubadmintask" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('viewtask')}>
                                                  {/* <NavLink to={"/viewtask"} className="menu-link">
                                                    <span className="menu-text">View Task</span>
                                                  </NavLink> */}
                                                  {this.props.allCookies.PanelUserType == "Sub Admin" &&
                                                    <NavLink to={"/viewsubadmintask"} className="menu-link">
                                                      <span className="menu-text">View Task</span>
                                                    </NavLink>
                                                  }
                                                  {this.props.allCookies.PanelUserType !== "Sub Admin" &&
                                                    <a href="/viewtask" className="menu-link">
                                                      <span className="menu-text">View Task</span>
                                                    </a>
                                                  }

                                                </li>
                                              }
                                              {this.props.allCookies.PanelUserType === "Admin" &&
                                                <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/viewadmintask" ? "menu-item-here" : "")}
                                                  data-menu-toggle="hover" aria-haspopup="true"
                                                  onClick={() => this.handleItemClick('viewadmintask')}>
                                                  <NavLink to={"/viewadmintask"} className="menu-link">
                                                    <span className="menu-text">View Administrator Task</span>
                                                  </NavLink>
                                                </li>
                                              }
                                            </ul>
                                          </div>
                                        </li>
                                      ) : ''
                                    }
                                    {
                                      (this.props.allCookies.VehicleType !== undefined || this.props.allCookies.VehicleBrand !== undefined ||
                                        this.props.allCookies.VehicleModel !== undefined || this.props.allCookies.VehicleMaster !== undefined || this.props.allCookies.VehicleRemarks !== undefined) ?
                                        (
                                          <li className={"menu-item menu-item-submenu menu-item-open menu-item-rel " + (window.location.pathname == "/vehicletype" || window.location.pathname == "/vehiclebrand" || window.location.pathname == "/vehicleModel" || window.location.pathname == "/vehicleMaster" || window.location.pathname == "/vehicleremarks" ? "menu-item-active" : "")} data-menu-toggle="click" aria-haspopup="true">
                                            <a className="menu-link menu-toggle">
                                              <span className="menu-text">Vehicles</span>
                                              <span className="menu-desc" />
                                              <i className="menu-arrow" />
                                            </a>
                                            <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                                              <ul className="menu-subnav">
                                                {this.props.allCookies.VehicleType !== undefined &&
                                                  <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/vehicletype" ? "menu-item-here" : "")}
                                                    data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('vehicletype')}>
                                                    <NavLink to={"/vehicletype"} className="menu-link">
                                                      <span className="menu-text">Vehicle Type</span>
                                                    </NavLink>
                                                  </li>
                                                }
                                                {this.props.allCookies.VehicleBrand !== undefined &&
                                                  <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/vehiclebrand" ? "menu-item-here" : "")}
                                                    data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('vehiclebrand')}>
                                                    <NavLink to={"/vehiclebrand"} className="menu-link">
                                                      <span className="menu-text">Vehicle Brand</span>
                                                    </NavLink>
                                                  </li>
                                                }
                                                {this.props.allCookies.VehicleModel !== undefined &&
                                                  <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/vehicleModel" ? "menu-item-here" : "")}
                                                    data-menu-toggle="hover" aria-haspopup="true">
                                                    <NavLink to={"/vehicleModel"} className="menu-link" onClick={() => this.handleItemClick('vehicleModel')}>
                                                      <span className="menu-text">Vehicle Model</span>
                                                    </NavLink>
                                                  </li>
                                                }
                                                {this.props.allCookies.VehicleMaster !== undefined &&
                                                  <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/vehicleMaster" ? "menu-item-here" : "")}
                                                    data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('vehicleMaster')}>
                                                    <NavLink to={"/vehicleMaster"} className="menu-link">
                                                      <span className="menu-text">Vehicle Master</span>
                                                    </NavLink>
                                                  </li>
                                                }
                                                {this.props.allCookies.VehicleRemarks !== undefined &&
                                                  <li className={"menu-item menu-item-submenu " + (window.location.pathname == "/vehicleremarks" ? "menu-item-here" : "")}
                                                    data-menu-toggle="hover" aria-haspopup="true" onClick={() => this.handleItemClick('vehicleremarks')}>
                                                    <NavLink to={"/vehicleremarks"} className="menu-link">
                                                      <span className="menu-text">Vehicle Remarks</span>
                                                    </NavLink>
                                                  </li>
                                                }
                                              </ul>
                                            </div>
                                          </li>
                                        ) : ''
                                    }
                                    {
                                      (this.props.allCookies.Report !== undefined) ? (
                                        <li className={"menu-item " + (window.location.pathname == "/TabularReport" ? "menu-item-here" : "")}
                                          aria-haspopup="true" onClick={() => this.handleItemClick('TabularReport')}>
                                          <NavLink to={"/TabularReport"} className="menu-link">
                                            <span className="menu-text">Report</span>
                                          </NavLink>
                                        </li>
                                      ) : ''
                                    }
                                    {
                                      (this.props.allCookies.ViewAuditLog !== undefined) ? (
                                        <li className={"menu-item " + (window.location.pathname == "/viewauditlog" ? "menu-item-here" : "")}
                                          aria-haspopup="true" onClick={() => this.handleItemClick('viewauditlog')}>
                                          <NavLink to={"/viewauditlog"} className="menu-link">
                                            <span className="menu-text">Audit log</span>
                                          </NavLink>
                                        </li>
                                      ) : ''
                                    }
                                    {
                                      (this.props.allCookies.ViewMultipleLogBook !== undefined) ? (
                                        <li className={"menu-item " + (window.location.pathname == "/viewmultiplelogbook" ? "menu-item-here" : "")}
                                          aria-haspopup="true" onClick={() => this.handleItemClick('viewmultiplelogbook')}>
                                          <NavLink to={"/viewmultiplelogbook"} className="menu-link">
                                            <span className="menu-text">Multiple Log Book</span>
                                          </NavLink>
                                        </li>
                                      ) : ''
                                    }
                                    {
                                      (this.props.allCookies.OverAllMapReport !== undefined) ? (
                                        <li className={"menu-item " + (window.location.pathname == "/overallmap" ? "menu-item-here" : "")}
                                          aria-haspopup="true" onClick={() => this.handleItemClick('overallmap')}>
                                          <NavLink to={"/overallmap"} className="menu-link">
                                            <span className="menu-text">OverAll Map Report</span>
                                          </NavLink>
                                        </li>
                                      ) : ''
                                    }
                                    {
                                      (this.props.allCookies.AdminReport !== undefined) ? (
                                        <li className={"menu-item " + (window.location.pathname == "/adminreport" ? "menu-item-here" : "")}
                                          aria-haspopup="true" onClick={() => this.handleItemClick('adminreport')}>
                                          <NavLink to={"/adminreport"} className="menu-link">
                                            <span className="menu-text">Admin Report</span>
                                          </NavLink>
                                        </li>
                                      ) : ''
                                    }
                                    {/* <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click" aria-haspopup="true">
                                      <a href="#" className="menu-link menu-toggle">
                                        <span className="menu-text">Booking</span>
                                        <span className="menu-desc" />
                                        <i className="menu-arrow" />
                                      </a>
                                      <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                                        <ul className="menu-subnav">
                                          <li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
                                            <a href="addbooking.html" className="menu-link">
                                              <span className="menu-text">Add Booking</span>
                                            </a>
                                          </li>
                                          <li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
                                            <a href="viewbooking.html" className="menu-link">
                                              <span className="menu-text">View Booking</span>
                                            </a>
                                          </li>
                                          <li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
                                            <a href="viewstartbooking.html" className="menu-link">
                                              <span className="menu-text">View Start Booking</span>
                                            </a>
                                          </li>
                                          <li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
                                            <a href="viewfinishbooking.html" className="menu-link">
                                              <span className="menu-text">View Finish Booking</span>
                                            </a>
                                          </li>
                                          <li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
                                            <a href="viewcompletebooking.html" className="menu-link">
                                              <span className="menu-text">View Complete Booking</span>
                                            </a>
                                          </li>
                                          <li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
                                            <a href="viewapprovalbooking.html" className="menu-link">
                                              <span className="menu-text">View Approval Booking</span>
                                            </a>
                                          </li>
                                          <li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
                                            <a href="viewadministratorbooking.html" className="menu-link">
                                              <span className="menu-text">View Administrator
                                                Booking</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li className="menu-item menu-item-submenu menu-item-rel" data-menu-toggle="click" aria-haspopup="true">
                                      <a href="#" className="menu-link menu-toggle">
                                        <span className="menu-text">Vehicle</span>
                                        <span className="menu-desc" />
                                        <i className="menu-arrow" />
                                      </a>
                                      <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                                        <ul className="menu-subnav">
                                          <li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
                                            <a href="vehicletype.html" className="menu-link">
                                              <span className="menu-text">Vehicle Type</span>
                                            </a>
                                          </li>
                                          <li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
                                            <a href="vehiclebrand.html" className="menu-link">
                                              <span className="menu-text">Vehicle Brand</span>
                                            </a>
                                          </li>
                                          <li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
                                            <a href="vehiclemodel.html" className="menu-link">
                                              <span className="menu-text">Vehicle Model</span>
                                            </a>
                                          </li>
                                          <li className="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
                                            <a href="vehicle.html" className="menu-link">
                                              <span className="menu-text">Vehicle Master</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </li> */}
                                  </ul>
                                  {/* <Navbar collapseOnSelect expand="lg" className="menu-nav">
                                    <Navbar.Brand  className="menu-item">
                                      <NavLink to={"/dashboard"} className="menu-link">
                                        <span className="menu-text">Dashboard</span>
                                      </NavLink>
                                    </Navbar.Brand>
                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                    <Navbar.Collapse id="responsive-navbar-nav">
                                      <Nav className="mr-auto">
                                        
                                        <NavDropdownMenu title="Master" id="collasible-nav-dropdown" className="menu-item menu-item-submenu menu-item-open menu-item-rel">
                                          <NavDropdown.Item href="#action/3.1">Country</NavDropdown.Item>
                                          <NavDropdown.Item href="#action/3.1">State</NavDropdown.Item>
                                          <NavDropdown.Item href="#action/3.1">City</NavDropdown.Item>
                                          <NavDropdown.Item href="#action/3.1">Zone</NavDropdown.Item>
                                          <NavDropdown.Item href="#action/3.1">District</NavDropdown.Item>
                                          <NavDropdown.Item href="#action/3.1">Municipalities</NavDropdown.Item>
                                          <NavDropdown.Item href="#action/3.1">Parking Location</NavDropdown.Item>  
                                          <NavDropdown.Item href="#action/3.1">User Type</NavDropdown.Item>  
                                         
                                        </NavDropdownMenu>
                                      </Nav>
                                    </Navbar.Collapse>
                                  </Navbar> */}
                                </div>
                              </div>
                              <div className="tab-pane p-5 p-lg-0 justify-content-between" id="kt_header_tab_2">
                                <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                                  <a href="#" className="btn btn-light-success font-weight-bold mr-3 my-2 my-lg-0">Latest Orders</a>
                                  <a href="#" className="btn btn-light-primary font-weight-bold my-2 my-lg-0">Customer Service</a>
                                </div>
                                <div className="d-flex align-items-center">
                                  <a href="#" className="btn btn-danger font-weight-bold my-2 my-lg-0">Generate Reports</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content" style={{ marginTopL: '2%' }}>
                      <div className="d-flex flex-column-fluid">
                        <div className="container-fluid">
                          <div className="row">

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div id="kt_quick_user" className="offcanvas offcanvas-right p-10">
                <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                  <h3 className="font-weight-bold m-0">User Profile</h3>
                  <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                    <i className="ki ki-close icon-xs text-muted" />
                  </a>
                </div>
                <div className="offcanvas-content pr-5 mr-n5">
                  <div className="d-flex align-items-center mt-5">
                    <div className="symbol symbol-100 mr-5">
                      {this.props.allCookies.UserImage &&
                        <div className="symbol-label" style={{ backgroundImage: 'url(' + process.env.REACT_APP_USERIMAGE_URL + this.props.allCookies.UserImage + ')' }} />
                      }
                      {
                        this.props.allCookies.UserImage === undefined &&
                        <div className="symbol-label" style={{ backgroundImage: 'url("Images/admin.png")' }} />
                      }
                      <i className="symbol-badge bg-success" />
                    </div>

                    <div className="d-flex flex-column">
                      <a href="#" className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">

                        {
                          this.props.allCookies.PanelUserType === "Admin" &&
                          this.props.allCookies.PanelUserType}

                        {
                          this.props.allCookies.PanelUserType !== "Admin" &&
                          this.props.allCookies.FullName}
                      </a>
                      <div className="navi mt-2">
                        <button type="button" onClick={this.logout} className="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">
                          Sign Out
                        </button>
                        {/* <a href="index.html" className="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">Sign Out</a> */}
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed mt-8 mb-5" />
                  <div className="separator separator-dashed my-7" />
                  {this.props.allCookies.IsMultiple === "True" &&
                    <div className="navi navi-spacer-x-0 p-0">
                      <a className="navi-item">
                        <div className="">
                          <div>
                            <label style={{ width: '100%', textAlign: 'left', fontFamily: '"Poppins"', color: 'black', fontWeight: 500 }}>Company</label>
                            <Select options={this.state.companies} value={this.state.MainCompanyID} onChange={this.onCompanyChange} />
                          </div>

                          <div style={{ marginTop: '7%' }}>
                            <label style={{ width: '100%', textAlign: 'left', fontFamily: '"Poppins"', color: 'black', fontWeight: 500 }}>Client</label>
                            <Select options={this.state.clients} value={this.state.MainClientID} onChange={this.onClientChange} />
                          </div>

                        </div>
                      </a>
                    </div>
                  }
                </div>
              </div>
              <div id="kt_scrolltop" className="scrolltop">
                <span className="svg-icon">

                </span>
              </div>
            </div>

          </div>
        ) : ''
        }
        <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
          <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div className="text-dark order-2 order-md-1">
              <span className="text-muted font-weight-bold mr-2">2023©</span>
              <a href="https://smtechno.com" rel="noreferrer" target="_blank" className="text-dark-75 text-hover-primary">SM Techno
                Consultants PVT LTD</a>
            </div>
          </div>
        </div>
      </>
    );
  }

}
function mapStateToProps(state) { const { user } = state.auth; return { user }; }
export default withCookies(connect(mapStateToProps)(App));
