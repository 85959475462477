import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import XLSX from "xlsx-color";
import moment from 'moment';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';


const headerTblStyle={ color: 'black' };
const exportToExcel=(columns, data) => {
    // debugger;
    const header=["Sr.No", "StateName", "CityName"];
    const headertemp=["Sr.No", "State Name", "City Name"];
    // debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else if (col=="StateName") {
                obj["State Name"]=row[col];
            }
            else if (col=="CityName") {
                obj["City Name"]=row[col];
            }
            else {
                obj[col]=row[col];
            }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    // XLSX.utils.book_append_sheet(wb, ws1, 'Country List');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols']=[
        { width: 6 },
        { width: 18 },
        { width: 18 },
    ];
    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;


    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "CityList");
    XLSX.writeFile(wb, "CityList_"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}
class city extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            name: cookies.get('name')||'Ben'
        };
        this.state={
            pathName: "/city",
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [], CityName: "", CityID: "", states: [],
            StateID: { 'label': '--Select State--', 'value': '--Select State--' },
            SearchStateID: { 'label': '--Select State--', 'value': '--Select State--' },
            formIsValid: false,
            StateErrMsg: '', isFilterVisible: false, iconFilter: "fa fa-plus",
            submitButtonDisabled: false,
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'center' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'State Name', width: '30%', field: 'StateName', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.StateName}</p>) } },
                { title: 'City Name', width: '50%', field: 'CityName', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CityName}</p>) } },

            ]
        }

    }
    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.data)
    }
    allowOnlyCharacters=(event) => {
        const keyCode=event.keyCode||event.which;
        const keyValue=String.fromCharCode(keyCode);
        if (!new RegExp("^[a-zA-Z ]+$").test(keyValue)) event.preventDefault();
        return;
    };
    componentDidMount() {
        this.GetState();
        this.GetCity();
    }
    GetCity() {
        // debugger;
        var SearchStateID=""


        if (this.state.SearchStateID.value!="--Select State--") {
            SearchStateID=this.state.SearchStateID.value;
        }
        else {
            SearchStateID="";
        }

        var data=JSON.stringify({
            "StateID": SearchStateID,
            "CityID": ""
        });
        var config={
            method: 'POST',
            url: services.API_URL+"City/getCity",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //// debugger;
            if (response.data.status) {
                this.setState({ data: response.data.data })
            }
            else {
                this.setState({ data: [] })
            }

        }, error => { })
    }
    GetState() {
        var data=JSON.stringify({
            "StateID": ""
        });
        var config={
            method: 'POST',
            url: services.API_URL+"State/getState",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            // debugger;
            this.setState({ states: response.data.data.map(item => ({ value: item.StateID, label: item.StateName })) });
        }, error => { })
    }
    EditCity(id) {
        var data=JSON.stringify({
            "CityID": id
        });
        var config={
            method: 'POST',
            url: services.API_URL+"City/getCity",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            this.setState({
                CityID: response.data.data[0].CityID,
                StateID: { 'label': response.data.data[0].StateName, 'value': response.data.data[0].StateID },
                CityName: response.data.data[0].CityName
            });
        }, error => { })
    }
    CheckAlert(id, cityname) {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                var CompanyID="";
                var ClientID="";
                var UserID="";
                var UserType="";
                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                var data=JSON.stringify({
                    "CityID": id,
                    "CityName": cityname,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+"City/removeCity",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        this.GetCity();
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }
    ClearData=(e) => {
        // debugger;
        this.setState({ CityID: "", StateErrMsg: "", CityName: "", StateID: { 'label': '--Select State--', 'value': '--Select State--' } });
        this.form.reset();
    }
    ValidateState=(e) => {
        this.setState({ StateErrMsg: '' })
    }
    onChange=(e) => {
        // debugger;
        this.setState({ StateID: e }, () => { this.ValidateState(); });
        // this.setState({ StateErrMsg: '' });
        //this.form.validateFields(e.value);
    }
    onSearchStateChange=(e) => {
        // debugger;
        if (e!=null) {
            this.setState({ SearchStateID: e });
        }
        else {
            this.setState({ SearchStateID: { 'label': '--Select State--', 'value': '--Select State--' } }, () => { this.GetCity() });
        }


    }
    OnFilterClick=(e) => {

        e.preventDefault();
        if (this.state.isFilterVisible===false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }
    handleSubmit=(e) => {
        // debugger;
        var CompanyID="";
        var ClientID="";
        var UserID="";
        var UserType="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID!==undefined) {
            UserID=this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType!==undefined) {
            UserType=this.props.allCookies.PanelUserType
        }
        e.preventDefault();
        // this.form.current.validateForm();
        this.form.validateForm();

        if (this.state.StateID.value=="--Select State--") {
            this.setState({ StateErrMsg: 'required' });
        }


        //const checkState=this.form.isValid();

        //console.log("Check",this.form.isValid());
        var StateID=this.state.StateID.value;

        if (this.form.isValid()&&this.state.StateID.value!="--Select State--") {
            var data="";
            if (this.state.CityID!="") {
                data=JSON.stringify({
                    "CityID": this.state.CityID,
                    "StateID": this.state.StateID.value,
                    "CityName": this.state.CityName,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
            }
            else {
                data=JSON.stringify({
                    "StateID": this.state.StateID.value,
                    "CityName": this.state.CityName
                });
            }

            var config={
                method: 'POST',
                url: services.API_URL+"City/setCity",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //// debugger;
                if (response.data.status) {
                    // debugger;
                    if (this.state.CityID!="") {
                        // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                        Swal.fire({
                            title: 'Successfully Updated', icon: "success", timer: 1500
                        });
                    }
                    else {
                        Swal.fire({
                            title: 'Successfully Inserted', icon: "success", timer: 1500
                        });
                    }
                    this.ClearData();
                    this.GetCity();
                }
                else {
                    Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                }
            }, error => { })
        }
    }
    onSearchCityClick=(e) => {
        e.preventDefault();
        this.GetCity();
    }
    OnSearchCancelCityClick=() => {
        // debugger;

        this.setState({ SearchStateID: { 'label': '--Select State--', 'value': '--Select State--' } }, () => { this.GetCity() });
        //window.location.reload(true);

    }

    render() {
        const { cookies }=this.props
        // debugger;
        var Permission=cookies.cookies.City;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <div>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row">
                        <div className="col-md-5">
                            {
                                this.state.AddStatus==="AddStatus:true"&&
                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header">
                                        <h3 className="card-title">Add City</h3>
                                    </div>
                                    <FormWithConstraints
                                        ref={form => this.form=form}
                                        onSubmit={this.handleSubmit}
                                        noValidate>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>State Name<span className="text-danger">*</span> </label>
                                                        {/* <select style={{ width: '100%' }} options={this.state.countries} className="form-control select2" id="kt_select2_1" name="param">
                                                        <option value="AK">India</option>
                                                    </select> */}
                                                        <Select name="StateName" options={this.state.states} value={this.state.StateID} onChange={this.onChange} />
                                                        {this.state.StateErrMsg&&<span className="text-danger">{this.state.StateErrMsg==='required'? 'Please Select State':''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>City Name <span className="text-danger">*</span></label>
                                                        <input type="text" name="CityName" required onKeyPress={this.allowOnlyCharacters} value={this.state.CityName} onChange={(e) => { this.setState({ CityName: e.target.value }, () => { this.form.validateFields(e.target) }) }} className="form-control" placeholder="Enter City Name" />
                                                        <FieldFeedbacks for="CityName">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary mr-2">
                                                Submit
                                            </button>
                                            <button type="button" onClick={this.ClearData} className="btn btn-secondary">
                                                Cancel
                                            </button>
                                        </div>
                                    </FormWithConstraints>
                                </div>
                            }
                        </div>
                        <div className="col-md-7">
                            {this.state.isFilterVisible&&
                                this.state.ViewStatus==="ViewStatus:true"&&
                                <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                                    <div className="col-md-4">
                                        <div className="form-group formgroupcss">
                                            <label>State </label>
                                            <Select isClearable={true} options={this.state.states} value={this.state.SearchStateID} onChange={this.onSearchStateChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <button type="button" onClick={this.onSearchCityClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                        <button type="button" onClick={this.OnSearchCancelCityClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                                    </div>
                                </div>
                            }
                            {
                                this.state.ViewStatus==="ViewStatus:true"&&
                                <div className="card card-custom">
                                    <div className="card-body">
                                        <MaterialTable columns={this.state.cols} data={this.state.data}
                                            actions={[{
                                                hidden: this.state.UpdateStatus=="UpdateStatus:true"? false:true,
                                                icon: 'edit', tooltip: 'Edit', onClick: (e, r) => this.EditCity(r.CityID)
                                            },
                                            {
                                                hidden: this.state.DeleteStatus=="DeleteStatus:true"? false:true,
                                                icon: 'delete', tooltip: 'Delete', onClick: (e, r) => this.CheckAlert(r.CityID,r.CityName)
                                            }
                                            ]}
                                            options={{
                                                headerStyle: { color: 'black' }, toolbar: true, search: false,draggable: false,
                                                paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                            }}
                                            components={{

                                                Toolbar: props => (
                                                    <div className="row" style={{ marginBottom: '2%' }}>
                                                        <div className="col-md-8" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h3 className="tabletitle">View City</h3>

                                                        </div>
                                                        <div className="col-md-4" style={{ textAlign: 'right' }}>
                                                            {
                                                                this.state.ViewStatus==="ViewStatus:true"&&
                                                                <>

                                                                    <a className="btn btn-outline-dark font-weight-bolder" onClick={this.OnFilterClick}>
                                                                        <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                                    </a>
                                                                    {
                                                                        this.state.ExcelStatus==="ExcelStatus:true"&&
                                                                        <a className="btn btn-outline-success font-weight-bolder ml-4" onClick={this.state.data.length>0? this.ExcelClick:''}>
                                                                            <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                                        </a>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
export default withCookies(city);