import React, { Component } from "react";

import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import '../style.css';
import Select, { components } from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import MultiSelect from "@khanacademy/react-multi-select";
import { NavLink } from "react-router-dom";
import Geocode from "react-geocode";
import moment from 'moment';
import $ from 'jquery';
import dayjs from 'dayjs';
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import isMatch from 'date-fns/isMatch'
// import DateTimePicker from 'react-datetime-picker';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import '../DateTimePicker.css'
import { format } from 'date-fns'


import { de } from "date-fns/locale";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import XLSX from "xlsx-color";
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import { replace } from "formik";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Map, GoogleApiWrapper, Image, Marker, Polyline } from 'google-maps-react';
const headerTblStyle = { color: 'black' };
const images = [
    { src: '//placekitten.com/1500/500' },

];
const mapStyles = {
    width: '100%',
    height: '100%',
};
const exportToExcel = (columns, data) => {
    debugger;
    const header = ["Sr.No", "BookingStatus", "CountryName", "StateName", "CityName", "VehicleNo", "BookingNo", "BookingType", "ZoneName", "DistrictName", "MuncipalityName", "WardName", "Locality", "Priority", "DrainageLine150to300mmDiaMeter", "DrainageLine301to600mmDiaMeter", "DrainageLine601to900mmDiaMeter", "DrainageLine901to1200mmDiaMeter", "Remarks", "BookingDate"];
    const headertemp = ["Sr.No", "Status", "Country Name", "State Name", "City Name", "Vehicle No", "Task No", "Task Type", "Zone Name", "DistrictName", "Muncipality Name", "Ward Name", "Locality", "Priority", "150 to 300mm DiaMeter", "301 to 600mm DiaMeter", "601 to 900mm DiaMeter", "901 to 1200mm DiaMeter", "Remarks", "Task Date"];
    // debugger
    const compatibleData = data.map((row, i) => {
        const obj = {};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col === "Sr.No") {
                obj[col] = i + 1;
            }
            else if (col == "BookingStatus") {
                obj["Status"] = row[col];
            }
            else if (col == "CountryName") {
                obj["Country Name"] = row[col];
            }
            else if (col == "StateName") {
                obj["State Name"] = row[col];
            }
            else if (col == "CityName") {
                obj["City Name"] = row[col];
            }
            else if (col == "VehicleNo") {
                if (row["BookingTaskDetail"] != null) {
                    obj["Vehicle No"] = row["BookingTaskDetail"][0]["VehicleNo"]
                }
                else {
                    obj["Vehicle No"] = "";
                }
                // obj["City Name"]=row[col];
            }
            else if (col == "BookingNo") {
                obj["Task No"] = row[col];
            }
            else if (col == "BookingType") {
                obj["Task Type"] = row[col];
            }
            else if (col == "ZoneName") {
                obj["Zone Name"] = row[col];
            }
            else if (col == "DistrictName") {
                obj["District Name"] = row[col];
            }
            else if (col == "MuncipalityName") {
                obj["Muncipality Name"] = row[col];
            }
            else if (col == "WardName") {
                obj["Ward Name"] = row[col];
            }
            else if (col == "Locality") {
                obj["Locality"] = row[col];
            }
            else if (col == "DrainageLine150to300mmDiaMeter") {
                obj["150 to 300mm DiaMeter"] = row[col];
            }
            else if (col == "DrainageLine301to600mmDiaMeter") {
                obj["301 to 600mm DiaMeter"] = row[col];
            }
            else if (col == "DrainageLine601to900mmDiaMeter") {
                obj["601 to 900mm DiaMeter"] = row[col];
            }
            else if (col == "DrainageLine901to1200mmDiaMeter") {
                obj["901 to 1200mm DiaMeter"] = row[col];
            }
            else if (col == "BookingDate") {
                obj["Task Date"] = row[col];
            }
            else {
                obj[col] = row[col];
            }
        });
        return obj;
    });

    const ws1 = XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb = XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s = {
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols'] = [
        { width: 6 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 }

    ];
    let cell = {
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s = cell;
    ws1['B1'].s = cell;
    ws1['C1'].s = cell;
    ws1['D1'].s = cell;
    ws1['E1'].s = cell;
    ws1['F1'].s = cell;
    ws1['G1'].s = cell;
    ws1['H1'].s = cell;
    ws1['I1'].s = cell;
    ws1['J1'].s = cell;
    ws1['K1'].s = cell;
    ws1['L1'].s = cell;
    ws1['M1'].s = cell;
    ws1['N1'].s = cell;
    ws1['O1'].s = cell;
    ws1['P1'].s = cell;
    ws1['Q1'].s = cell;
    ws1['R1'].s = cell;
    ws1['S1'].s = cell;
    ws1['T1'].s = cell;



    var RowCont = {
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins = i + 2;
        ws1['A' + ins].s = RowCont;
        ws1['B' + ins].s = RowCont;
        ws1['C' + ins].s = RowCont;
        ws1['D' + ins].s = RowCont;
        ws1['E' + ins].s = RowCont;
        ws1['F' + ins].s = RowCont;
        ws1['G' + ins].s = RowCont;
        ws1['H' + ins].s = RowCont;
        ws1['I' + ins].s = RowCont;
        ws1['J' + ins].s = RowCont;
        ws1['K' + ins].s = RowCont;
        ws1['L' + ins].s = RowCont;
        ws1['M' + ins].s = RowCont;
        ws1['N' + ins].s = RowCont;
        ws1['O' + ins].s = RowCont;
        ws1['P' + ins].s = RowCont;
        ws1['Q' + ins].s = RowCont;
        ws1['R' + ins].s = RowCont;
        ws1['S' + ins].s = RowCont;
        ws1['T' + ins].s = RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "TaskList");
    XLSX.writeFile(wb, "TaskList_" + moment().format('DD-MM-YYYY_HH:mm') + ".xlsx");
}
const A4_PAPER_DIMENSIONS = {
    width: 210,
    height: 210,
};
const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;
export const imageDimensionsOnA4 = () => {
    debugger;
    const isLandscapeImage = 210 >= 297;

    if (isLandscapeImage) {
        return {
            width: A4_PAPER_DIMENSIONS.width,
            height:
                A4_PAPER_DIMENSIONS.width / (610 / 97),
        };
    }

    const imageRatio = 600 / 297;
    if (imageRatio > A4_PAPER_RATIO) {
        const imageScaleFactor =
            (A4_PAPER_RATIO * 260) / 600; // for space all doc

        const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

        return {
            height: scaledImageHeight * imageRatio,
            // height: "295",
            width: scaledImageHeight * imageRatio,
        };
    }

    return {
        width: A4_PAPER_DIMENSIONS.height / (600 / 297),
        height: A4_PAPER_DIMENSIONS.height,

    };
};
class viewsubadmintask extends Component {
    constructor(props) {
        super(props);

        const { cookies } = props;
        this.state = {
            name: cookies.get('name') || 'Ben'
        };
        this.tableRef = React.createRef();
        this.state = {
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "", EditAssignedPermission: "",
            EditStartedPermission: "", EditFinishedPermission: "", EditCompletedPermission: "", EditApprovedPermission: "",
            EditPaymentPermission: "", photoIndex: 0, isOpen: false, TempLogsheetImage: "", BookingFinishRemarks: "",
            data: [], VehicleNo: "", municipalities: [], MunicipalityID: [], vehicles: [], drivers: [],
            helpers: [{ 'id': 'No Helpers', 'value': 'No Helpers' }], searchvehicles: [],
            parkinglocations: [], checklists: [{ 'id': 'No Checklist', 'value': 'No Checklist' }],
            HelperData: [{ UserID: '' }], BookedVehicle: [{ Data: '' }], isBookedVehicle: false, BookedDriver: [{ Data: '' }],
            BookingApprovals: [{ 'label': 'Approved', 'value': 'Approved' }, { 'label': 'Referred Back', 'value': 'Referred Back' }],
            isChecked: false, isCheckListChecked: false, NoHelperVisibleMsg: false, HelperDataAvailable: true,
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            DriverID: { 'label': '--Select Driver--', 'value': '--Select Driver--' },
            ParkingLocationID: { 'label': '--Select Parking Location--', 'value': '--Select Parking Location--' },
            BookingApprovalStatus: { 'label': '--Select Booking Status--', 'value': '--Select Booking Status--' },
            SearchVehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            BookingNo: "", StartDate: new Date(), EndDate: new Date(), BookingMunicipalityID: "",
            mobVehicleLeaveParkingTime: new Date(), StartVehicleLeaveParkingTimeErrMsg: "", FullVehicleReachedLocationTime: this.addMinutes(new Date(), 1),
            VehicleLeaveParkingTime: "", VehicleReachedLocationTime: "", VehicleLeaveParkingOdometer: "", JettingStartMeterReading: "",
            SuctionPumpStartMeterReading: "", TaskFile: [], TaskImages: "", Latitude: "", Longitude: "", CurrentAddress: "",
            VehicleReachedLocationOdometer: "", VehicleReturnLocationTime: "", mobVehicleReturnLocationTime: new Date(), VehicleReturnLocationOdometer: "", VehicleReachedParkingTime: "",
            mobVehicleReachedParkingTime: this.addMinutes(new Date(), 1),
            VehicleReachedParkingOdometer: "", JettingFinishMeterReading: "", SuctionPumpFinishMeterReading: "", TotalNoOfDranageLine: "",
            DrainageLine150to300mmDiaMeter: "", DrainageLine301to600mmDiaMeter: "", DrainageLine601to900mmDiaMeter: "",
            DrainageLine901to1200mmDiaMeter: "", JettingSystem: "150", SuctionSystem: "5000", SuctionHose5Diameter3MeterLong: "10",
            JettingHose32Diameter120MeterLong: "1", JettingNozzleDeChockingNozzle: "1", DeSiltingNozzleFlatNozzle: "1",
            BalanceNozzleBombNozzle: "1", BookingFinishLatitude: "", BookingFinishLongitude: "", BookingFinishAddress: "",
            Shift: "", FinishTaskFile: [], FinishTaskImages: "", NoOfCleanMachineHole: "",
            BookingCompleteRemarks: "", BookingCompleteRemarksGujarati: "", TenderLiquidityDamagesCondition: "", BookingCompleteStatus: "",
            LogSheetTaskFile: [], LogSheetImage: "", LogImage: "",
            BookingApprovalRemarks: "", FinishTaskImageText: "", FinishTaskImageTextVisible: true,
            isFilterVisible: false, iconFilter: "fa fa-plus",
            modalvisible: false, IsHelperChange: false, AddTaskHidden: false, getCurrentLocation: false, IsCompleteDisabled: true,
            isFinishEditVisible: true, IsFinishUpdatesVisible: false, IsStateData: false,
            faicon: "fa fa-plus",
            PanelUserType: "", FinishTaskImageErrMsg: "", PreviousID: "",
            RefferBackAssignDate: "", IsReferredAssignDataVisible: false, BookingZoneID: "", BookingDistrictID: "",
            VehicleErrMsg: "", DriverErrMsg: "", HelperErrMsg: "", CheckListErrMsg: "", ParkingLocationErrMsg: "", TaskImageErrMsg: "",
            CompleteTaskErrImage: "", BookingApprovalStatusErrMsg: "", VehicleReachedLocationOdometerErrMsg: "",
            VehicleReachedLocationTimeErrMsg: "", VehicleReturnLocationTimeErrMsg: "",
            VehicleReachedParkingOdometerErrMsg: "", VehicleReachedParkingTimeErrMsg: "", ShiftErrMsg: "",
            VehicleLeaveParkingTimeFormatErrMsg: "", VehicleReachedParkingTimeFormatErrMsg: "",
            BookedTaskNo: "", BookedMunicipality: "", BookedWard: "", BookedLocality: "", BookedAssignedvehicleNo: "", BookedAssignedDriver: "",
            PaymentReceivedDate: "", PaymentAmount: "", BookingID: "", PaymentDateErrMsg: "", BillNo: "", IsStartEditable: false,
            IsFinishEditable: false, IsCompleteEditable: false,
            IsAssignedClick: false, IsStartClick: false, IsFinishClick: false, IsCompleteClick: false, IsApprovalClick: false,
            BookingDate: "", PDFBookingNo: "", Shift: "", ZoneName: "", WardName: "", Locality: "", ParkingLocationName: "",
            DistanceFromParkingLocationToLocationOfWork: "", VehicleLeaveParkingTime: "", VehicleReachedLocationTime: "",
            VehicleLeaveParkingKiloMeter: "",
            VehicleReachedLocationKiloMeter: "", VehicleReturnLocationKiloMeter: "", VehicleReachedParkingKiloMeter: "",
            JettingStartMeterReading: "", SuctionPumpStartMeterReading: "", JettingFinishMeterReading: "", SuctionPumpFinishMeterReading: "",
            TotalHours: "", TotalMinutes: "", TotalTime: "", TotalKilometer: "", TotalJettingUsageTime: "", TotalSuctionPumpUsageTime: "",
            TotalUsageJettingHour: "", TotalUsageJettingMinutes: "", TotalSuctionPumpUsageHour: "", TotalSuctionPumpUsageMinutes: "",
            TotalNoOfDranageLine: "", TotalDrainageMeter: "", DrainageLine150to300mmDiaMeter: "", DrainageLine301to600mmDiaMeter: "",
            DrainageLine601to900mmDiaMeter: "", DrainageLine901to1200mmDiaMeter: "",
            VehicleLeaveParkingTimeFormatErrMsg: "", VehicleReachedParkingTimeFormatErrMsg: "",
            VehicleReturnLocationTimeFormatErrMsg: "", VehicleReachedParkingTimeFormatErrMsg: "",
            DriverName: "", SupervisorName: "", AssignTaskSupervisorName: "",
            PDFStatus: false,
            IsSafetyShoesChecked: false, IsHandGlovesChecked: false, IsHelmetChecked: false, IsMaskChecked: false,
            IsLightReflectiveJacketChecked: false, IsUniformChecked: false, IsIDCardChecked: false, JettingStartMeterReadingErrMsg: "", JettingFinishMeterReadingErrMsg: "",
            SuctionPumpFinishMeterReadingErrMsg: "", SuctionPumpStartMeterReadingErrMsg: "", DifferentLocationOdometer: "", MultipleHelperName: "", changevalue: false,
            Tempvehicleno: "", TempVehicleLeaveParkingDateTime: "", TempVehicleReachedLocationDateTime: "", TempVehicleReturnLocationDateTime: "",
            TempVehicleReachedParkingDateTime: "", TaskCancelRemarks: "", DataVehicleReachedLocationDateTime: "",
            isStartToFinishChecked: true, isFinishToStartChecked: false, isWithinDataChecked: false, LunchStartTime: "", LunchEndTime: "",
            currentLocation: [], LiveLocation: "",
            ShiftData: [{ label: "1", value: "1" },
            { label: "2", value: "2" },
            { label: "3", value: "3" }],
            ShiftID: { 'label': '--Select Shift--', 'value': '--Select Shift--' },

            isGeoLatitudeVisible: false, isGeoLongitudeVisible: false, isGeoAddressVisible: false,
            GeoLatitudeErrMsg: "", GeoLongitudeVErrMsg: "", GeoAddressErrMsg: "",

            isBookingFinishLatitudeVisible: false, isBookingFinishLongitudeVisible: false, isBookingFinishAddressVisible: false,
            BookingFinishLatitudeErrMsg: "", BookingFinishLongitudeErrMsg: "", BookingFinishAddressErrMsg: "",

            LogSheetNo: "", LocationDetail: "", EngineerName: "", EngineerDesignation: "", MachineNotWorkFromTime: "",
            MachineNotWorkToTime: "", MachineNotWorkFromTime2: "", MachineNotWorkToTime2: "",
            MachineNotWorkTotalTime: "", ExtraordinaryDetails: "", ExtraordinaryArea: "", ExtraordinaryMachineHoles: "",
            SludgeDisposalArea: "Yes", IsSludgeDisposalAreaYes: false, IsSludgeDisposalAreaNo: false, LunchStartTime2: "", LunchEndTime2: "", VehicleLeaveParkingTime2: "", VehicleReachedLocationTime2: "", VehicleReturnLocationTime2: "", VehicleReachedParkingTime2: "",

            LunchStartTimeErrMsg: "", LunchEndTimeErrMsg: "", MachineNotWorkFromTimeErrMsg: "", MachineNotWorkToTimeErrMsg: "",

            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.tableData.id + 1}</p>) } },
                {
                    title: 'Details', width: '5%', removable: false, hidden: true, headerStyle: headerTblStyle, render: rowData => {
                        return (<i style={{ color: 'black' }} className={this.state.faicon} onClick={this.onToggleDetailPanel(rowData.BookingID, rowData.tableData.id)}></i>)
                    }
                },
                { title: 'Status', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingStatus}</p>) } },
                {
                    title: 'LogBook', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => {
                        return (<p style={{ marginBottom: '1px' }}>{
                            (this.state.PanelUserType === "Sub Admin") ? (
                                (rowData.LogSheetStatus == "true") ? <a style={{ color: 'black', borderColor: 'white' }} className="btn btn-outline-danger" onClick={this.LogBookClick(rowData.BookingID)}>
                                    <i className="fa fa-file-pdf" style={{ fontSize: 24, color: 'black' }} />Log Book</a>
                                    // :(rowData.BookingStatus=="Approved")? <a style={{ color: 'black', borderColor: 'white' }} className="btn btn-outline-danger" onClick={this.LogBookClick(rowData.BookingID)}>
                                    //     <i className="fa fa-file-pdf" style={{ fontSize: 24, color: 'black' }} />Log Book</a>
                                    : '') : ''
                        }</p>)
                    }
                },
                {
                    title: 'Action', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => {
                        return (
                            rowData.BookingStatus == "Approved" ? (
                                rowData.PaymentReceivedDate === null &&
                                this.props.allCookies.ViewTaskPayment !== undefined &&

                                <button type="button" class="btn btn-primary" onClick={this.onAssignPaymentClick(rowData.BookingID, rowData.BookingNo)}>
                                    Payment
                                </button>) :

                                '')
                    }
                },
                {
                    title: (<div style={{ width: '100%' }}>
                        <span>Country Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} />
                        <span>State Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} />
                        <span>City Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} />
                        <span>Vehicle No</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle,
                    render: rowData => {
                        return (
                            <p style={{ marginBottom: '1px' }}>{rowData.CountryName}<hr />
                                {rowData.StateName}<hr />
                                {rowData.CityName}<hr />
                                {(rowData.BookingTaskDetail) !== null ? (rowData.BookingTaskDetail[0].VehicleNo) : ''}</p>)
                    }
                },
                { title: (<div style={{ width: '100%' }}><span>Task No</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Task Type</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingNo}<hr />{rowData.BookingType}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Zone Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>District Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Municipality Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Ward Name</span></div>), width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.ZoneName}<hr />{rowData.DistrictName}<hr />{rowData.MuncipalityName}<hr />{rowData.WardName}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Locality</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Priority</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<><p style={{ marginBottom: '1px' }}>{rowData.Locality}</p><hr /><p className={rowData.Priority == "High" ? "label font-weight-bold label-lg label-light-danger label-inline" : rowData.Priority == "Medium" ? "label font-weight-bold label-lg label-light-primary label-inline" : "label font-weight-bold label-lg label-light-success label-inline"} style={{ marginBottom: '1px' }}>{rowData.Priority}</p></>) } },
                { title: (<div style={{ width: '100%' }}><span>150 to 300mm </span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>301 to 600mm </span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>601 to 900mm </span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>901 to 1200mm </span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.DrainageLine150to300mmDiaMeter}<hr />{rowData.DrainageLine301to600mmDiaMeter}<hr />{rowData.DrainageLine601to900mmDiaMeter}<hr />{rowData.DrainageLine901to1200mmDiaMeter}</p>) } },
                // { title: 'Locality', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Locality}</p>) } },
                // { title: 'Priority', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p className={rowData.Priority=="High"? "label font-weight-bold label-lg label-light-danger label-inline":rowData.Priority=="Medium"? "label font-weight-bold label-lg label-light-primary label-inline":"label font-weight-bold label-lg label-light-success label-inline"} style={{ marginBottom: '1px' }}>{rowData.Priority}</p>) } },
                { title: 'Remarks', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Remarks}</p>) } },
                {
                    title: 'LogSheet Image', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => {
                        return (<p style={{ marginBottom: '1px' }}>{
                            (rowData.LogSheetImage !== null) ?
                                (<a target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + rowData.LogSheetImage}>
                                    <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + rowData.LogSheetImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + rowData.LogSheetImage}></img>
                                </a>) : ''}</p>)
                    }
                },
                // { title: 'Task Date', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingDate}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Task Date</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Reffer Back Assign Date</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingDate}<hr />{(rowData.BookingTaskDetail !== null) ? rowData.BookingTaskDetail[0].RefferBackAssignDate : ''}</p>) } },
            ],
            detailcols: [
                { title: 'Assign Vehicle', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', fontSize: '12px' }}>{rowData.VehicleNo}</p>) } },
                { title: 'Assign Driver', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left', fontSize: '12px' }}>{rowData.DriverFullName}</p>) } },
                { title: 'Assign Date', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', fontSize: '12px' }}>{rowData.AssignDate}</p>) } },
            ]
        }
    }
    addMinutes = (date, minutes) => {
        date.setMinutes(date.getMinutes() + minutes);

        return date;
    }

    getCurrentTime() {
        debugger;
        var today = new Date(), time = today.getHours() + ':' + (today.getMinutes() < 10 ? '0' : '') + today.getMinutes();
        var tommorow = new Date(), nexttime = today.getHours() + ':' + (today.getMinutes() < 10 ? '0' : '') + (today.getMinutes() + 1);
        this.setState({ VehicleLeaveParkingTime: time });
        // this.setState({ VehicleReturnLocationTime: "" });
        // this.setState({ VehicleReachedParkingTime: nexttime });
    }
    componentDidMount() {
        debugger;
        this.setState({});
        var UserType = "";
        if (this.props.allCookies.PanelUserType !== undefined) {
            UserType = this.props.allCookies.PanelUserType
            this.setState({ PanelUserType: UserType });
            if (UserType == "Vehicle") {
                //this.setState({ AddTaskHidden: false }, () => { this.getCurrentTime() });
                this.GetParkingLocation();
                this.GetCheckList();

            }
        }
        //this.setState({IsStateData:true},()=>{});
        this.GetBooking();
        this.GetMunicipality();
        this.GetSearchVehicle();
    }
    GetSearchVehicle() {
        debugger;
        var CompanyID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }

        var data = "";
        data = JSON.stringify({
            "VehicleID": "",
            "CompanyID": CompanyID,
            "IsActive": "true",
            "IsDelete": "false"
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Vehicle/getVehicle",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ searchvehicles: response.data.data.map(item => ({ value: item.VehicleID, label: item.VehicleNo })) });


            }
            else {
                this.setState({ searchvehicles: [] })
            }
        }, error => { })
    }
    closeMapModal = (e) => {
        e.preventDefault();
        // this.setState({ IsMapVisible: false });
        // this.setState({ counter: null });

        // window.$(this.MapModal).modal('hide');
        //window.location.reload(false);
        this.setState({
            DataBind: [], currentLocation: [], LiveLocation: "", Lastlong: "", isStartToFinishChecked: true,
            isFinishToStartChecked: false, isWithinDataChecked: false
        })
        window.$(this.MapModal).modal('hide');
    }
    onSearchVehicleChange = (e) => {
        if (e !== null) {
            this.setState({ SearchVehicleID: e });
        }
        else {
            this.setState({ SearchVehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' } });
        }
    }
    BindMapData = (bookingid, bookingtaskdetailid, vehicleno, VehicleLeaveParkingDateTime, VehicleReachedLocationDateTime, VehicleReturnLocationDateTime, VehicleReachedParkingDateTime, isstarttofinishcheck, isfinishtostartcheck, iswithincheck) => e => {
        //debugger;
        e.preventDefault();
        var vehiclestarttime = "";
        var vehicleendtime = "";

        window.$(this.MapModal).modal('show');
        if (VehicleReturnLocationDateTime == null) {
            var newDate = new Date();
            // var now = moment(newDate).format('YYYY-MM-DD HH:mm:ss');
            var now = moment(newDate).format('YYYY-MM-DD HH:mm');
            VehicleReturnLocationDateTime = now;
        }

        if (VehicleReachedParkingDateTime == null) {
            var newDate = new Date();
            // var now = moment(newDate).format('YYYY-MM-DD HH:mm:ss');
            var now = moment(newDate).format('YYYY-MM-DD HH:mm');
            VehicleReachedParkingDateTime = now;
        }

        if (isstarttofinishcheck == true) {
            vehiclestarttime = VehicleLeaveParkingDateTime
            // vehicleendtime = VehicleReachedLocationDateTime
            vehicleendtime = VehicleReachedParkingDateTime

            this.setState({ BookingID: bookingid });
            this.setState({ BookingTaskDetailID: bookingtaskdetailid });
            this.setState({ Tempvehicleno: vehicleno });
            this.setState({ TempVehicleLeaveParkingDateTime: VehicleLeaveParkingDateTime });
            this.setState({ TempVehicleReachedLocationDateTime: VehicleReachedLocationDateTime });
            this.setState({ TempVehicleReturnLocationDateTime: VehicleReturnLocationDateTime });
            this.setState({ TempVehicleReachedParkingDateTime: VehicleReachedParkingDateTime });

            this.setState({ isStartToFinishChecked: true });
            this.setState({ isFinishToStartChecked: false });
            this.setState({ isWithinDataChecked: false });
        }
        else if (isfinishtostartcheck == true) {
            vehiclestarttime = VehicleReturnLocationDateTime
            vehicleendtime = VehicleReachedParkingDateTime

            this.setState({ isStartToFinishChecked: false });
            this.setState({ isFinishToStartChecked: true });
            this.setState({ isWithinDataChecked: false });
        }
        else if (iswithincheck == true) {
            vehiclestarttime = VehicleReachedLocationDateTime
            vehicleendtime = VehicleReturnLocationDateTime

            this.setState({ isStartToFinishChecked: false });
            this.setState({ isFinishToStartChecked: false });
            this.setState({ isWithinDataChecked: true });
        }
        else {
            vehiclestarttime = VehicleLeaveParkingDateTime
            vehicleendtime = VehicleReachedLocationDateTime

            this.setState({ isStartToFinishChecked: true });
            this.setState({ isFinishToStartChecked: false });
            this.setState({ isWithinDataChecked: false });
        }

        var data = JSON.stringify({
            BookingID: bookingid,
            BookingTaskDetailID: bookingtaskdetailid,
            VehicleNo: vehicleno,
            VehicleStartTime: vehiclestarttime,
            VehicleEndTime: vehicleendtime
            //VehicleNo: 'GJ01AA0001',
            // VehicleLeaveParkingDateTime: VehicleLeaveParkingDateTime,
            // VehicleReachedLocationDateTime: VehicleReachedLocationDateTime,
            // VehicleReturnLocationDateTime: VehicleReturnLocationDateTime,
            // VehicleReachedParkingDateTime: VehicleReachedParkingDateTime
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "DeviceLocationInformation/getDeviceLocationInformationNew",
            //url: "http://192.168.0.119:1342/api/LocationDevice/getLocationDeviceInformation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            if (response.data.status) {
                //  window.$(this.MapModal).modal('show');
                this.setState({
                    currentLocation: response.data.data

                })
                //this.setState({ pntmap: "a" });
                const checkdata = [];
                response.data.data.map((items, idx) => {
                    checkdata.push(
                        { lat: parseFloat(items.GpsLat), lng: parseFloat(items.GpsLng) }
                    )
                })
                var last = parseInt(checkdata.length) - 1;
                this.setState({ Lastlong: last });
                this.setState({ parentLocation: checkdata });
                const path = JSON.stringify(checkdata);
                let final = path.replace(/,/g, ',')
                final = final.replaceAll("\"", "");
                this.setState({ DataBind: checkdata })
                this.setState({ LiveLocation: checkdata[0] });
            }
            else {
                this.setState({ LiveLocation: { lat: 23.0204741, lng: 72.4149305 } });
                this.setState({ currentLocation: [] });
                Swal.fire({ position: 'top-end', toast: true, title: 'No Tracking Data Found', icon: "error", showConfirmButton: false, timer: 1500 })
            }



        }, error => { })
    }

    showMarkers = () => {
        return this.state.currentLocation.map((store, index) => {
            // return <Marker label={index.toString()} key={index} id={index} position={{
            //     lat: store.GpsLat,
            //     lng: store.GpsLng
            // }}
            return <Marker
                icon={
                    (index == 0 && this.state.isStartToFinishChecked == true) ?
                        {

                            url: "/Images/parkingstart.png"
                            // ,
                            // scaledSize: new this.props.google.maps.Size(100, 100)
                        } :
                        (index == 0 && this.state.isFinishToStartChecked == true) ?
                            {
                                url: "/Images/locationleave.png"
                            } :
                            (index == 0 && this.state.isWithinDataChecked == true) ?
                                {
                                    url: "/Images/locationreach.png"
                                } :
                                (index == this.state.Lastlong && this.state.isStartToFinishChecked == true) ? {
                                    // url: "/Images/locationreach.png"
                                    url: "/Images/parkingend.png"
                                    // ,
                                    // scaledSize: new this.props.google.maps.Size(100, 100)
                                } :
                                    (index == this.state.Lastlong && this.state.isFinishToStartChecked == true) ?
                                        {
                                            url: "/Images/parkingend.png"
                                        } :
                                        (index == this.state.Lastlong && this.state.isWithinDataChecked == true) ?
                                            {
                                                url: "/Images/locationleave.png"
                                            } :
                                            ''}

                // label={{
                //     text: (index==0)? 'Start':(index==this.state.Lastlong)? 'Last':''
                //   }}
                // title={(index==0)? 'Start':(index==this.state.Lastlong)? 'Last':''}
                // label={
                //     (index==0)? 'Start':(index==this.state.Lastlong)? 'Last':''
                // }
                key={index} id={index} position={(index == 0) ? {
                    lat: store.GpsLat,
                    lng: store.GpsLng
                } : (index == this.state.Lastlong) ? {
                    lat: store.GpsLat,
                    lng: store.GpsLng
                } : ''}
                onClick={() => console.log("Clicked")} >
            </Marker>
        })
    }
    GetImages = (bookingid, bookingtaskdetailid, vehicleno, VehicleLeaveParkingDateTime, VehicleReachedLocationDateTime, VehicleReturnLocationDateTime, VehicleReachedParkingDateTime) => e => {
        debugger;
        e.preventDefault();
        var data = JSON.stringify({
            BookingID: bookingid,
            BookingTaskDetailID: bookingtaskdetailid,
            VehicleNo: vehicleno,
            //VehicleNo: 'GJ01AA0001',
            VehicleLeaveParkingDateTime: VehicleLeaveParkingDateTime,
            VehicleReachedLocationDateTime: VehicleReachedLocationDateTime,
            VehicleReturnLocationDateTime: VehicleReturnLocationDateTime,
            VehicleReachedParkingDateTime: VehicleReachedParkingDateTime
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "CCDImages/getCCDImagesData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;

            if (response.data.status) {
                this.setState({ isOpen: true });
                this.setState({ images: response.data.data });
                var data = [];
                for (var i = 0; i < response.data.data.length; i++) {
                    //data += response.data.data[i].path
                    data.push(response.data.data[i].path)
                    //data.concat(response.data.data[i].path)
                }


                this.setState({ images: data });
                var img = data;
                debugger;
                const copyGroups = JSON.parse(JSON.stringify(img));

                //this.setState({ WardID: [] })

            }
            else {
                this.setState({ images: [] })
                Swal.fire({ position: 'top-end', toast: true, title: 'No Images Available', icon: "error", showConfirmButton: false, timer: 1500 })
            }
        }, error => { })
    }
    // componentDidUpdate() {
    //     this.componentDidMount();
    // }
    GetLogsheetData(id) {
        //debugger;
        //   window.location.reload(true);
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var MunicipalityID = "";
        var UserType = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }


        var APIName = "";
        var data = "";

        APIName = "Report/getBookingLogSheetData"
        data = JSON.stringify({
            BookingID: id,
            CompanyID: CompanyID,
            ClientID: ClientID,
            UserID: UserID,
            UserTypeID: "",
            UserType: "",
            BookingTypeID: "",
            BookingNo: "",
            ZoneID: "",
            DistrictID: "",
            MuncipalityID: "",
            WardID: "",
            Priority: "",
            BookingStatus: "",
            StartDate: "",
            EndDate: ""
        });


        // console.log("data", data);
        var config = {
            method: 'POST',
            url: services.API_URL + APIName,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                this.setState({ BookingDate: response.data.data[0].BookingDate });
                this.setState({ VehicleNo: response.data.data[0].VehicleNo });
                this.setState({ PDFBookingNo: response.data.data[0].BookingNo });
                this.setState({ Shift: response.data.data[0].Shift });
                this.setState({ ZoneName: response.data.data[0].ZoneName });
                this.setState({ WardName: response.data.data[0].WardName });
                this.setState({ Locality: response.data.data[0].Locality });
                this.setState({ ParkingLocationName: response.data.data[0].ParkingLocationName });
                this.setState({ DistanceFromParkingLocationToLocationOfWork: response.data.data[0].DistanceFromParkingLocationToLocationOfWork });
                this.setState({ VehicleLeaveParkingTime: response.data.data[0].VehicleLeaveParkingTime });
                this.setState({ VehicleReachedLocationTime: response.data.data[0].VehicleReachedLocationTime });
                this.setState({ VehicleReturnLocationTime: response.data.data[0].VehicleReturnLocationTime });
                this.setState({ VehicleReachedParkingTime: response.data.data[0].VehicleReachedParkingTime });
                this.setState({ VehicleLeaveParkingKiloMeter: response.data.data[0].VehicleLeaveParkingOdometer });
                this.setState({ VehicleReachedLocationKiloMeter: response.data.data[0].VehicleReachedLocationOdometer });
                this.setState({ VehicleReturnLocationKiloMeter: response.data.data[0].VehicleReturnLocationOdometer });
                this.setState({ VehicleReachedParkingKiloMeter: response.data.data[0].VehicleReachedParkingOdometer });
                this.setState({ JettingStartMeterReading: response.data.data[0].JettingStartMeterReading });
                this.setState({ SuctionPumpStartMeterReading: response.data.data[0].SuctionPumpStartMeterReading });
                this.setState({ SuctionPumpFinishMeterReading: response.data.data[0].SuctionPumpFinishMeterReading });
                this.setState({ JettingFinishMeterReading: response.data.data[0].JettingFinishMeterReading });
                this.setState({ TotalTime: response.data.data[0].TotalTime });
                this.setState({ TotalKilometer: response.data.data[0].TotalKiloMeter });

                var VehicleLeaveParkingOdometer = response.data.data[0].VehicleLeaveParkingOdometer;
                var VehicleReachedLocationOdometer = response.data.data[0].VehicleReachedLocationOdometer;

                var TotalDifferenceOdometer = VehicleReachedLocationOdometer - VehicleLeaveParkingOdometer;
                var HelperName = "";
                this.setState({ DifferentLocationOdometer: TotalDifferenceOdometer });


                if (response.data.data[0].TotalTime != null) {
                    var TotalTime = response.data.data[0].TotalTime.split(':');
                    this.setState({ TotalHours: TotalTime[0] });
                    this.setState({ TotalMinutes: TotalTime[1] });
                }

                if (response.data.data[0].TotalJettingUsageTime != null) {
                    var TotalJettingUsageTime = response.data.data[0].TotalJettingUsageTime.split(':');
                    this.setState({ TotalUsageJettingHour: TotalJettingUsageTime[0] });
                    this.setState({ TotalUsageJettingMinutes: TotalJettingUsageTime[1] });
                }

                if (response.data.data[0].TotalSuctionPumpUsageTime != null) {
                    var TotalSuctionPumpUsageTime = response.data.data[0].TotalSuctionPumpUsageTime.split(':');
                    this.setState({ TotalSuctionPumpUsageHour: TotalSuctionPumpUsageTime[0] });
                    this.setState({ TotalSuctionPumpUsageMinutes: TotalSuctionPumpUsageTime[1] });
                }

                this.setState({ TotalNoOfDranageLine: response.data.data[0].TotalNoOfDranageLine });
                this.setState({ TotalDrainageMeter: response.data.data[0].TotalMeter });
                this.setState({ DrainageLine150to300mmDiaMeter: response.data.data[0].DrainageLine150to300mmDiaMeter });
                this.setState({ DrainageLine301to600mmDiaMeter: response.data.data[0].DrainageLine301to600mmDiaMeter });
                this.setState({ DrainageLine601to900mmDiaMeter: response.data.data[0].DrainageLine601to900mmDiaMeter });
                this.setState({ DrainageLine901to1200mmDiaMeter: response.data.data[0].DrainageLine901to1200mmDiaMeter });
                this.setState({ JettingSystem: response.data.data[0].JettingSystem });
                this.setState({ SuctionSystem: response.data.data[0].SuctionSystem });
                this.setState({ SuctionHose5Diameter3MeterLong: response.data.data[0].SuctionHose5Diameter3MeterLong });
                this.setState({ JettingHose32Diameter120MeterLong: response.data.data[0].JettingHose32Diameter120MeterLong });
                this.setState({ JettingNozzleDeChockingNozzle: response.data.data[0].JettingNozzleDeChockingNozzle });
                this.setState({ DeSiltingNozzleFlatNozzle: response.data.data[0].DeSiltingNozzleFlatNozzle });
                this.setState({ BalanceNozzleBombNozzle: response.data.data[0].BalanceNozzleBombNozzle });
                this.setState({ DriverName: response.data.data[0].DriverFullName });
                this.setState({ SupervisorName: response.data.data[0].AssignSupervisorFullName });
                this.setState({ AssignTaskSupervisorName: response.data.data[0].SupervisorFullName });
                var BookingTaskCheckListDetail = response.data.data[0].BookingTaskCheckListDetail;
                if (BookingTaskCheckListDetail != null) {
                    debugger;
                    for (let i = 0; i < response.data.data[0].BookingTaskCheckListDetail.length; i++) {
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Safety Shoes") {
                            this.setState({ IsSafetyShoesChecked: true });
                        }
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Hand Gloves") {
                            this.setState({ IsHandGlovesChecked: true });
                        }
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Helmet") {
                            this.setState({ IsHelmetChecked: true });
                        }
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Mask") {
                            this.setState({ IsMaskChecked: true });
                        }
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Light Reflective Jacket") {
                            this.setState({ IsLightReflectiveJacketChecked: true });
                        }
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "Uniform") {
                            this.setState({ IsUniformChecked: true });
                        }
                        if (response.data.data[0].BookingTaskCheckListDetail[i].CheckListTitle == "I-Card") {
                            this.setState({ IsIDCardChecked: true });
                        }

                    }
                }

                var BookingTaskHelperDetail = response.data.data[0].BookingTaskHelperDetail;
                if (BookingTaskHelperDetail != null) {
                    for (let i = 0; i < response.data.data[0].BookingTaskHelperDetail.length; i++) {
                        HelperName += response.data.data[0].BookingTaskHelperDetail[i].FullName + ",";
                    }
                }
                HelperName = HelperName.slice(0, -1)
                if (HelperName != "") {
                    this.setState({ MultipleHelperName: HelperName });
                }

                this.setState({
                    LogSheetNo: response.data.data[0].LogSheetNo != null ? response.data.data[0].LogSheetNo : '',
                    LocationDetail: response.data.data[0].LocationDetail != null ? response.data.data[0].LocationDetail : '',
                    EngineerName: response.data.data[0].EngineerName != null ? response.data.data[0].EngineerName : '',
                    EngineerDesignation: response.data.data[0].EngineerDesignation != null ? response.data.data[0].EngineerDesignation : '',
                    ExtraordinaryDetails: response.data.data[0].ExtraordinaryDetails != null ? response.data.data[0].ExtraordinaryDetails : '',
                    ExtraordinaryArea: response.data.data[0].ExtraordinaryArea != null ? response.data.data[0].ExtraordinaryArea : '',
                    ExtraordinaryMachineHoles: response.data.data[0].ExtraordinaryMachineHoles != null ? response.data.data[0].ExtraordinaryMachineHoles : '',
                    MachineNotWorkFromTime2: response.data.data[0].MachineNotWorkFromTime != null ? response.data.data[0].MachineNotWorkFromTime : '',
                    MachineNotWorkToTime2: response.data.data[0].MachineNotWorkToTime != null ? response.data.data[0].MachineNotWorkToTime : '',
                    MachineNotWorkTotalTime: response.data.data[0].MachineNotWorkTotalTime != null ? response.data.data[0].MachineNotWorkTotalTime : '',

                    VehicleLeaveParkingTime2: response.data.data[0].VehicleLeaveParkingTime2,
                    VehicleReachedLocationTime2: response.data.data[0].VehicleReachedLocationTime2,
                    VehicleReturnLocationTime2: response.data.data[0].VehicleReturnLocationTime2,
                    VehicleReachedParkingTime2: response.data.data[0].VehicleReachedParkingTime2
                });

                if (response.data.data[0].SludgeDisposalArea == "No") {
                    this.setState({ IsSludgeDisposalAreaNo: true })
                } else {
                    this.setState({ IsSludgeDisposalAreaYes: true })
                }

                this.setState({ ClientName: response.data.data[0].ClientName });
                this.setState({ MuncipalityName: response.data.data[0].MuncipalityName });
                this.setState({ LunchStartTime2: (response.data.data[0].LunchStartTime) ? (response.data.data[0].LunchStartTime) : "" });
                this.setState({ LunchEndTime2: (response.data.data[0].LunchEndTime) ? (response.data.data[0].LunchEndTime) : "" });
                this.setState({ TotalLunchTime: (response.data.data[0].TotalLunchTime) ? (response.data.data[0].TotalLunchTime) : "" });

                this.setState({ PDFStatus: true }, () => { this.generateImage() });

            }
            else {
                // this.setState({ data: [] })
            }

        }, error => { })
    }
    generateImage = () => {
        debugger;
        var cname = this.state.ClientName;
        let img, id;
        // if (cname == "SMT Surat") {
        if (cname == "Gujarat Urban Development Mission") {
            img = document.getElementById('divToGUDMPrint');
            id = document.getElementById('divToGUDMPrint');
            id.style.display = 'block';
        } else {
            img = document.getElementById('divToPrint');
            id = document.getElementById('divToPrint');
            id.style.display = 'block';
        }

        htmlToImage.toJpeg(img, { quality: 1, backgroundColor: "#FFFFFF", height: img.clientHeight, width: img.clientWidth })
            .then(function (dataUrl) {
                debugger;
                const doc = new jsPDF();
                doc.deletePage(1);

                const imageDimensions = imageDimensionsOnA4({
                    width: 210,
                    height: 210,
                });

                doc.addPage();
                doc.addImage(
                    dataUrl,
                    //image.imageType,
                    (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
                    (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
                    imageDimensions.width,
                    //imageDimensions.height
                    280
                );
                const pdfURL = doc.output("bloburl");
                //window.open(pdfURL, "_blank");
                id.style.display = 'none';
                let alink = document.createElement('a');
                alink.href = pdfURL;
                alink.download = "Logsheet_" + moment().format('DD-MM-YYYY_HH:mm') + ".pdf";
                alink.click();

            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }
    setGeoLocation = (e) => {
        debugger;
        e.preventDefault();
        var Lat = ""
        var Lng = ""
        const location = window.navigator && window.navigator.geolocation

        if (location) {
            location.getCurrentPosition((position) => {
                this.setState({
                    Latitude: position.coords.latitude,
                    Longitude: position.coords.longitude,
                })

                Geocode.setApiKey("AIzaSyA8Io_sD3d5uTz6ZTj4FKae_I7mdBVjX9o");
                Geocode.setLanguage("en");
                Geocode.setLocationType("ROOFTOP");
                Geocode.enableDebug();
                Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                    (response) => {
                        const address = response.results[0].formatted_address;
                        this.setState({ CurrentAddress: address });
                        console.log(address);
                    },
                    (error) => {
                        console.error(error);
                    }
                );
            }, (error) => {
                this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
            })
        }
    }
    setFinishGeoLocation = (e) => {
        debugger;
        e.preventDefault();
        var Lat = ""
        var Lng = ""
        const location = window.navigator && window.navigator.geolocation

        if (location) {
            location.getCurrentPosition((position) => {
                this.setState({
                    BookingFinishLatitude: position.coords.latitude,
                    BookingFinishLongitude: position.coords.longitude,
                })

                Geocode.setApiKey("AIzaSyA8Io_sD3d5uTz6ZTj4FKae_I7mdBVjX9o");
                Geocode.setLanguage("en");
                Geocode.setLocationType("ROOFTOP");
                Geocode.enableDebug();
                Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                    (response) => {
                        const address = response.results[0].formatted_address;
                        this.setState({ BookingFinishAddress: address });
                        console.log(address);
                    },
                    (error) => {
                        console.error(error);
                    }
                );
            }, (error) => {
                this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
            })
        }
    }
    GetVehicleOldTaskData = (BookingID, BookingTaskDetailID) => {
        var data = JSON.stringify({
            "BookingTaskDetailID": BookingTaskDetailID,
            "BookingID": BookingID
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getVehicleOldTaskData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            // debugger;
            if (response.data.status) {
                this.setState({ VehicleLeaveParkingOdometer: response.data.data[0].VehicleReachedParkingOdometer });
                this.setState({ JettingStartMeterReading: response.data.data[0].JettingFinishMeterReading });
                this.setState({ SuctionPumpStartMeterReading: response.data.data[0].SuctionPumpFinishMeterReading });
            }

            //this.setState({ parkinglocations: response.data.data.map(item => ({ value: item.ParkingLocationID, label: item.ParkingLocationName })) });
        }, error => { })
    }
    GetMunicipality_Old() {

        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }
        var data = "";
        data = JSON.stringify({
            "UserID": UserID,
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "ZoneID": "",
            "DistrictID": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "User/getUserMuncipalityDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //// debugger;

            if (response.data.status) {
                this.setState({ municipalities: response.data.data.map(item => ({ value: item.MuncipalityID, label: item.MuncipalityName })) });
                this.setState({ MunicipalityID: [] })
            }
            else {
                this.setState({ municipalities: [] })
            }
        }, error => { })
    }

    GetMunicipality() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var ZoneID = "";
        var tempPanelUserType = "";
        var tempUrl = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.ZoneIDs !== undefined) {
            ZoneID = this.props.allCookies.ZoneIDs
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            tempPanelUserType = this.props.allCookies.PanelUserType
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }

        var data = "";
        // data = JSON.stringify({
        //     "MuncipalityID": "",
        //     "CompanyID": CompanyID,
        //     "ClientID": ClientID,
        //     "DistrictID": "",
        //     "ZoneID": "",
        //     "UserID": UserID
        // });

        if (tempPanelUserType == "Admin") {
            data = JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "Muncipality/getMuncipalityDropDownData";
        } else {
            data = JSON.stringify({
                UserID: UserID,
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "User/getUserMuncipalityDetail";
        }

        var config = {
            method: 'POST',
            url: services.API_URL + tempUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ municipalities: response.data.data.map(item => ({ value: item.MuncipalityID, label: item.MuncipalityName })) });
                this.setState({ MuncipalityID: [] })

            }
            else {
                this.setState({ municipalities: [] })
            }
        }, error => { })
    }

    GetParkingLocation() {
        var CompanyID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (CompanyID != "") {
            var data = JSON.stringify({
                "ParkingLocationID": "",
                "CompanyID": CompanyID,
                "CountryID": "",
                "StateID": "",
                "CityID": "",
                "IsActive": "true",
                "IsDelete": "false"
            });
            var config = {
                method: 'POST',
                url: services.API_URL + "ParkingLoction/getParkingLocation",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                // debugger;
                this.setState({ parkinglocations: response.data.data.map(item => ({ value: item.ParkingLocationID, label: item.ParkingLocationName })) });
            }, error => { })
        }
        else {
            Swal.fire({
                title: 'Please Select Company', icon: "error", timer: 1500
            });
        }

    }
    onToggleDetailPanel = (BookingID, id) => (e) => {
        // debugger;
        e.preventDefault();
        // debugger;

        var rowid = id;
        if (!this.tableRef.current) return;

        const { detailPanel } = this.tableRef.current.props;
        let handleShowDetailPanel = detailPanel;

        // If props.detailPanel is an array not a func
        if (typeof detailPanel === 'object') {
            // ~ I AM USING INDEX 0 HERE - CHANGE TO WHATEVER YOU NEED ~
            handleShowDetailPanel = detailPanel[0].render
        }

        //var bid = rowData;
        var data = JSON.stringify({
            "BookingID": BookingID
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getBookingTaskDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                // if(this.state.faicon == "fa fa-plus")
                // {
                //     this.setState({faicon:"fa fa-minus"});
                // }
                // else{
                //     this.setState({faicon:"fa fa-plus"});
                // }
                this.setState({ detaildata: response.data.data })

                const updateddatas = this.state.data.map((c, i) => {
                    // debugger
                    if (c.tableData !== undefined) {
                        // Increment the clicked counter
                        return c
                    } else {
                        // The rest haven't changed
                        return c;
                    }
                });

                // this.setState({ data: newState });


                //const _data=[...this.state.data]; // Make copy of data
                const _data = [...updateddatas]; // Make copy of data

                // If `showDetailPanel` already exists, remove it to close the detailPanel..
                //var check  = _data[id].contains("tableData");

                var datatd = _data[id].tableData;
                if (datatd == undefined) {
                    _data[id].tableData = {
                        ..._data[id],
                        showDetailPanel: handleShowDetailPanel
                    }
                }
                else {
                    if (_data[id].tableData.showDetailPanel) {
                        _data[id].tableData.showDetailPanel = '';
                    } else {

                        // If `showDetailPanel` does not exist, add it to open detailPanel..
                        _data[id].tableData = {
                            ..._data[id],
                            showDetailPanel: handleShowDetailPanel
                        }
                    }
                }

                this.setState({ data: _data });
                // this.tableRef.current.onToggleDetailPanel(
                //         [3],
                //         this.tableRef.current.props.detailPanel[0].render
                //     )
            }
            else {
                this.setState({ detaildata: [] })
            }

        }, error => { })

        // this.tableRef.current.onToggleDetailPanel(
        //     [0],
        //     this.tableRef.current.props.detailPanel[0].render
        // )
    }
    GetBookingTaskDetail = (BookingID) => (e) => {
        // debugger;
        e.preventDefault();
        var data = JSON.stringify({
            "BookingID": BookingID
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getBookingTaskDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                this.setState({ data: response.data.data })
            }
            else {
                this.setState({ data: [] })
            }

        }, error => { })
    }

    GetBooking() {
        debugger;
        //   window.location.reload(true);
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var MunicipalityID = "";
        var UserType = "";
        var VehicleID = "";
        var ckZoneID = "";
        var ckMunicipalityID = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            UserType = this.props.allCookies.PanelUserType
        }
        if (this.props.allCookies.ZoneIDs !== undefined) {
            ckZoneID = this.props.allCookies.ZoneIDs
        }
        if (this.props.allCookies.MunicipalityID !== undefined) {
            ckMunicipalityID = this.props.allCookies.MunicipalityID
        }

        //var MunicipalityID="";
        if (this.state.MunicipalityID != null) {
            var datas = this.state.MunicipalityID.map(

                item => (
                    MunicipalityID += item + ","
                )
            );
            MunicipalityID = MunicipalityID.slice(0, -1)
        }

        if (MunicipalityID == "") {
            if (UserType == "Municipality") {
                if (this.props.allCookies.MunicipalityID !== undefined) {
                    MunicipalityID = this.props.allCookies.MunicipalityID
                }
            }
        }
        if (this.state.SearchVehicleID.value != "--Select Vehicle--") {
            VehicleID = this.state.SearchVehicleID.value;
        }
        
        var APIName = "";
        var data = "";
        if (UserType == "Supervisor") {
            APIName = "Booking/getBooking"
            data = JSON.stringify({
                BookingID: "",
                CompanyID: CompanyID,
                ClientID: ClientID,
                UserID: UserID,
                UserTypeID: "",
                UserType: "",
                BookingTypeID: "",
                BookingNo: this.state.BookingNo,
                ZoneID: "",
                DistrictID: "",
                MuncipalityID: MunicipalityID ? MunicipalityID : ckMunicipalityID ? ckMunicipalityID : '',
                WardID: "",
                Priority: "",
                BookingStatus: "",
                VehicleID: VehicleID,
                StartDate: this.state.StartDate,
                EndDate: this.state.EndDate
            });
        }
        else if (UserType == "Vehicle") {
            APIName = "Booking/getVehicleBooking"
            data = JSON.stringify({
                BookingID: "",
                CompanyID: CompanyID,
                ClientID: ClientID,
                VehicleUserID: UserID,
                UserID: "",
                UserTypeID: "",
                UserType: "",
                BookingTypeID: "",
                BookingNo: "",
                ZoneID: "",
                DistrictID: "",
                MuncipalityID: "",
                WardID: "",
                Priority: "",
                BookingStatus: "",
                VehicleID: VehicleID,
                StartDate: this.state.StartDate,
                EndDate: this.state.EndDate
            });
        }
        else {
            APIName = "Booking/getBooking"
            data = JSON.stringify({
                BookingID: "",
                CompanyID: CompanyID,
                ClientID: ClientID,
                UserID: "",
                UserTypeID: "",
                UserType: "",
                BookingTypeID: "",
                BookingNo: this.state.BookingNo,
                ZoneID: "",
                DistrictID: "",
                MuncipalityID: MunicipalityID ? MunicipalityID : ckMunicipalityID ? ckMunicipalityID : '',
                WardID: "",
                Priority: "",
                BookingStatus: "",
                VehicleID: VehicleID,
                StartDate: this.state.StartDate,
                EndDate: this.state.EndDate
            });
        }

        console.log("data", data);
        var config = {
            method: 'POST',
            url: services.API_URL + APIName,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                // debugger;
                this.setState({ data: response.data.data })
            }
            else {
                this.setState({ data: [] })
            }

        }, error => { })
    }
    GetVehicle(BookingDate, ZoneID, DistrictID, MuncipalityID) {
        // debugger;
        var CompanyID = "";
        var ClientID = "";
        //var BookingDate = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        if (CompanyID != "" && ClientID != "") {
            var data = JSON.stringify({
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "BookingDate": BookingDate,
                "UserType": "Vehicle",
                "BookingStatus": "Finished,Completed,Approved",
                "ZoneID": ZoneID,
                "DistrictID": DistrictID,
                "MuncipalityID": MuncipalityID,
            });
            var config = {
                method: 'POST',
                url: services.API_URL + "Booking/getVehicleDataForAssignBooking",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //// debugger;
                if (response.data.status) {
                    this.setState({ vehicles: response.data.data.map(item => ({ value: item.VehicleID, label: item.VehicleNo })) });
                }
                else {
                    this.setState({ vehicles: [] });
                }

            }, error => { })
        }
        else {
            if (CompanyID == "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID != "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID == "" && ClientID != "") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }


    }
    GetDriver(BookingDate, ZoneID, DistrictID, MuncipalityID) {
        var CompanyID = "";
        var ClientID = "";
        //var BookingDate = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        if (CompanyID != "" && ClientID != "") {
            var data = JSON.stringify({
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "BookingDate": BookingDate,
                "UserType": "Driver",
                "BookingStatus": "Finished,Completed,Approved",
                "ZoneID": ZoneID,
                "DistrictID": DistrictID,
                "MuncipalityID": MuncipalityID
            });
            var config = {
                method: 'POST',
                url: services.API_URL + "Booking/getDriverDataForAssignBooking",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                // debugger;
                if (response.data.status) {
                    this.setState({ drivers: response.data.data.map(item => ({ value: item.UserID, label: item.FullName })) }, () => { this.GetHelper(BookingDate, ZoneID, DistrictID, MuncipalityID) });
                }
                else {
                    this.setState({ drivers: [] });
                    this.setState({ helpers: [] });
                    this.setState({ HelperDataAvailable: false });
                    this.setState({ NoHelperVisibleMsg: true });
                }

            }, error => { })
        }
        else {
            if (CompanyID == "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID != "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID == "" && ClientID != "") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }


    }
    GetHelper(BookingDate, ZoneID, DistrictID, MuncipalityID) {
        var CompanyID = "";
        var ClientID = "";
        //var BookingDate = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        if (CompanyID != "" && ClientID != "") {
            var data = JSON.stringify({
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "BookingDate": BookingDate,
                "UserType": "Helper",
                "BookingStatus": "Finished,Completed,Approved",
                "ZoneID": ZoneID,
                "DistrictID": DistrictID,
                "MuncipalityID": MuncipalityID
            });
            var config = {
                method: 'POST',
                url: services.API_URL + "Booking/getHelperDataForAssignBooking",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                // debugger;
                if (response.data.status) {
                    // debugger;
                    this.setState({ helpers: response.data.data.map(item => ({ id: item.UserID, value: item.FullName, isChecked: false })) });
                    this.setState({ HelperDataAvailable: true });
                    this.setState({ NoHelperVisibleMsg: false });
                }
                else {
                    this.setState({ helpers: [] })
                    this.setState({ HelperDataAvailable: false });
                    this.setState({ NoHelperVisibleMsg: true });
                }
                // var SelectAll={ 'label': 'Select All', 'value': 'Select All' };
                // this.setState({ helpers: [SelectAll, ...response.data.data.map(item => ({ value: item.UserID, label: item.FullName }))] });


            }, error => { })
        }
        else {
            if (CompanyID == "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID != "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID == "" && ClientID != "") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }


    }
    GetCheckList() {
        var CompanyID = "";
        var ClientID = "";
        //var BookingDate = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        if (CompanyID != "" && ClientID != "") {
            var data = JSON.stringify({
                "CheckListID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "CheckListType": "Task",
                "IsActive": "true",
                "IsDelete": "false"
            });
            var config = {
                method: 'POST',
                url: services.API_URL + "CheckList/getCheckList",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //// debugger;
                this.setState({ checklists: response.data.data.map(item => ({ id: item.CheckListID, value: item.CheckListTitle, isCheckListChecked: false })) });
            }, error => { })
        }
        else {
            if (CompanyID == "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID != "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID == "" && ClientID != "") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }


    }
    DeleteCheckAlert(id, bookingno) {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                var CompanyID = "";
                var ClientID = "";
                var UserID = "";
                var UserType = "";
                if (this.props.allCookies.MainCompanyID !== undefined) {
                    CompanyID = this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID !== undefined) {
                    ClientID = this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID !== undefined) {
                    UserID = this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType !== undefined) {
                    UserType = this.props.allCookies.PanelUserType
                }
                var data = JSON.stringify({
                    "BookingID": id,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "BookingNo": bookingno,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
                var config = {
                    method: 'POST',
                    url: services.API_URL + "Booking/removeBooking",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        this.GetBooking();
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }
    LogBookClick = (Bookingid) => (e) => {
        debugger;
        this.setState({});
        //this.setState({PDFBookingNo:2});
        this.setState({ CheckPrintStatus: true }, () => { this.GetLogsheetData(Bookingid); });

        // this.generateImage();

    }
    OnFilterClick = (e) => {

        e.preventDefault();
        if (this.state.isFilterVisible === false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }
    OnAssignClick = (bookingdate, bookingid, ZoneID, DistrictID, MuncipalityID) => e => {
        // debugger;
        e.preventDefault();
        this.setState({ BookingID: bookingid });
        //console.log("Clicked");
        this.setState({ modalvisible: true });
        window.$(this.modal).modal('show');
        this.setState({ BookingMunicipalityID: MuncipalityID });
        this.GetVehicle(bookingdate, ZoneID, DistrictID, MuncipalityID);
        this.GetDriver(bookingdate, ZoneID, DistrictID, MuncipalityID);
        this.GetHelper(bookingdate, ZoneID, DistrictID, MuncipalityID);

    }
    EditAssignedBooking = (bookingid, bookingtaskdetailid, bookingdate, ZoneID, DistrictID, MuncipalityID, IsRefferBack, bookingno) => e => {
        debugger;
        e.preventDefault();
        this.setState({ IsAssignedClick: true });
        this.setState({ BookingNo: bookingno });
        this.CheckAlert(bookingid, bookingtaskdetailid, bookingdate, ZoneID, DistrictID, MuncipalityID, IsRefferBack);
        // e.preventDefault();
        // this.setState({ modalvisible: true });
        // window.$(this.modal).modal('show');
    }
    EditModalAssignedBooking(bookingid, bookingtaskdetailid, bookingdate, ZoneID, DistrictID, MuncipalityID, IsRefferBack) {
        debugger;
        this.setState({ BookingID: bookingid });
        this.setState({ modalvisible: true });

        if (IsRefferBack !== "True") {
            window.$(this.EditAssignmodal).modal('show');

            window.$(this.EditAssignmodal).modal({ backdrop: 'static', keyboard: false })

            this.setState({ BookingMunicipalityID: MuncipalityID });
            this.GetVehicle(bookingdate, ZoneID, DistrictID, MuncipalityID);
            this.GetDriver(bookingdate, ZoneID, DistrictID, MuncipalityID);
            this.GetHelper(bookingdate, ZoneID, DistrictID, MuncipalityID);
        }
        else {
            window.$(this.EditReferredAssignModal).modal('show');
            window.$(this.EditReferredAssignModal).modal({ backdrop: 'static', keyboard: false })
            this.setState({ IsReferredAssignDataVisible: false });
            this.setState({ BookingZoneID: ZoneID });
            this.setState({ BookingDistrictID: DistrictID });
            this.setState({ BookingMunicipalityID: MuncipalityID });
        }




        // window.$(this.EditAssignmodal).modal({ backdrop: 'static' });
        // window.$(this.EditAssignmodal).modal({ keyboard: false });

        // window.$(this.EditAssignmodal).modal('backdrop:static, keyboard: false' );
    }
    UpdateMobileVehicleTime(time) {
        debugger;
        this.setState({ mobVehicleLeaveParkingTime: time })
    }
    EditStartBooking = (bookingid, bookingtaskdetailid, bookingno) => e => {
        debugger;
        e.preventDefault();
        this.GetParkingLocation();
        this.GetCheckList();
        this.setState({ modalvisible: true });
        window.$(this.modals).modal('show');
        this.setState({ BookingID: bookingid });
        this.setState({ BookingTaskDetailID: bookingtaskdetailid });
        this.setState({ IsStartClick: true });
        this.setState({ BookingNo: bookingno });

        var CompanyID = "";
        var ClientID = "";
        var BookingTaskChecklistDetailID = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        var data = JSON.stringify({
            "BookingID": bookingid
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getBookingTaskDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                debugger;
                this.setState({ BookingNo: response.data.data[0].BookingNo });
                this.setState({ ParkingLocationID: { 'label': response.data.data[0].ParkingLocationName, 'value': response.data.data[0].ParkingLocationID } })
                this.setState({ VehicleLeaveParkingOdometer: response.data.data[0].VehicleLeaveParkingOdometer });
                this.setState({ VehicleReachedLocationOdometer: response.data.data[0].VehicleReachedLocationOdometer });
                // this.setState({ VehicleLeaveParkingTime: response.data.data[0].VehicleLeaveParkingTime });
                // this.setState({ VehicleReachedLocationTime: response.data.data[0].VehicleReachedLocationTime });
                this.setState({ JettingStartMeterReading: response.data.data[0].JettingStartMeterReading });
                this.setState({ JettingFinishMeterReading: response.data.data[0].JettingFinishMeterReading });
                this.setState({ SuctionPumpStartMeterReading: response.data.data[0].SuctionPumpStartMeterReading });
                this.setState({ SuctionPumpFinishMeterReading: response.data.data[0].SuctionPumpFinishMeterReading });
                this.setState({ JettingFinishMeterReading: response.data.data[0].JettingFinishMeterReading });
                this.setState({ SuctionPumpFinishMeterReading: response.data.data[0].SuctionPumpFinishMeterReading });
                this.setState({ IsStartEditable: true });
                BookingTaskChecklistDetailID = response.data.data[0].BookingTaskCheckListDetail;

                // var VehicleLeaveParkingTime=response.data.data[0].VehicleLeaveParkingTime;
                // var split=VehicleLeaveParkingTime.split(' ');
                // var datesplit=split[0].split('-');
                // var NewDate=datesplit[2]+'-'+datesplit[1]+'-'+datesplit[0]+'T'+split[1]+'';
                this.setState({ mobVehicleLeaveParkingTime: response.data.data[0].VehicleLeaveParkingTime });

                // var VehicleReachedLocationTime=response.data.data[0].VehicleReachedLocationTime;
                // var split=VehicleReachedLocationTime.split(' ');
                // var datesplit=split[0].split('-');
                // var NewDate=datesplit[2]+'-'+datesplit[1]+'-'+datesplit[0]+'T'+split[1]+'';
                this.setState({ FullVehicleReachedLocationTime: response.data.data[0].VehicleReachedLocationTime });

                this.setState({ VehicleReachedLocationTimeErrMsg: '' })

                this.setState({ isGeoLatitudeVisible: true });
                this.setState({ isGeoLongitudeVisible: true });
                this.setState({ isGeoAddressVisible: true });

                if (response.data.data[0].BookingStartLatitude != "" && response.data.data[0].BookingStartLongitude != "" && response.data.data[0].BookingStartAddress != "") {
                    this.setState({
                        Latitude: response.data.data[0].BookingStartLatitude,
                        Longitude: response.data.data[0].BookingStartLongitude,
                        CurrentAddress: response.data.data[0].BookingStartAddress
                    });
                } else {
                    this.setState({
                        Latitude: "",
                        Longitude: "",
                        CurrentAddress: ""
                    });
                }

                if (CompanyID != "" && ClientID != "") {
                    var data = JSON.stringify({
                        "CheckListID": "",
                        "CompanyID": CompanyID,
                        "ClientID": ClientID,
                        "CheckListType": "Task",
                        "IsActive": "true",
                        "IsDelete": "false"
                    });
                    var config = {
                        method: 'POST',
                        url: services.API_URL + "CheckList/getCheckList",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    services.JsonValue(config).then(response => {
                        debugger;
                        this.setState({ checklists: response.data.data.map(item => ({ id: item.CheckListID, value: item.CheckListTitle, isCheckListChecked: false })) });

                        // let checklists=this.state.checklists;
                        // for (let i=0; i<checklists.length; i++) {
                        //     checklists[i].isCheckListChecked=false;
                        // }
                        // let StoredChecklist=BookingTaskChecklistDetailID
                        // if (StoredChecklist!=null) {
                        //     for (let k=0; k<checklists.length; k++) {
                        //         for (let j=0; j<StoredChecklist.length; j++) {
                        //             // const element = array[index];
                        //             if (checklists[k].id==StoredChecklist[j].CheckListID) {
                        //                 // debugger;
                        //                 checklists[k].isCheckListChecked=true;

                        //             }
                        //             else {
                        //             }
                        //         }
                        //     }

                        //     this.setState({ checklists: checklists });
                        // }

                        let checklists = this.state.checklists;
                        let StoredChecklist = BookingTaskChecklistDetailID
                        if (StoredChecklist != null) {
                            for (let i = 0; i < checklists.length; i++) {
                                // checklists[i].isCheckListChecked=false;
                                for (let j = 0; j < StoredChecklist.length; j++) {
                                    // const element = array[index];
                                    if (checklists[i].id == StoredChecklist[j].CheckListID) {
                                        // debugger;
                                        checklists[i].isCheckListChecked = true;
                                        break;
                                    }
                                    else {
                                    }
                                }
                            }
                        }
                        this.setState({ checklists: checklists });

                    }, error => { })
                }




            } else {
                Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
            }
        }, error => { })
    }
    EditFinishBooking = (bookingid, bookingtaskdetailid, bookingno) => e => {
        debugger;
        e.preventDefault();
        this.setState({ BookingID: bookingid });
        this.setState({ BookingTaskDetailID: bookingtaskdetailid });
        this.setState({ BookingNo: bookingno });
        this.setState({ IsFinishClick: true });
        //console.log("Clicked");
        this.setState({ modalvisible: true });
        window.$(this.FinishModal).modal('show');
        var data = JSON.stringify({
            "BookingID": bookingid
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getBookingTaskDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                debugger;
                this.setState({ BookingNo: response.data.data[0].BookingNo });
                this.setState({

                    VehicleReachedLocationOdometer: response.data.data[0].VehicleReachedLocationOdometer,
                    //VehicleReturnLocationTime: response.data.data[0].VehicleReturnLocationTime,
                    VehicleReturnLocationOdometer: response.data.data[0].VehicleReturnLocationOdometer,
                    // VehicleReachedParkingTime: response.data.data[0].VehicleReachedParkingTime,
                    VehicleReachedParkingOdometer: response.data.data[0].VehicleReachedParkingOdometer,
                    JettingFinishMeterReading: response.data.data[0].JettingFinishMeterReading,
                    JettingStartMeterReading: response.data.data[0].JettingStartMeterReading,
                    SuctionPumpFinishMeterReading: response.data.data[0].SuctionPumpFinishMeterReading,
                    SuctionPumpStartMeterReading: response.data.data[0].SuctionPumpStartMeterReading,
                    TotalNoOfDranageLine: response.data.data[0].TotalNoOfDranageLine,
                    DrainageLine150to300mmDiaMeter: response.data.data[0].DrainageLine150to300mmDiaMeter,
                    DrainageLine301to600mmDiaMeter: response.data.data[0].DrainageLine301to600mmDiaMeter,
                    DrainageLine601to900mmDiaMeter: response.data.data[0].DrainageLine601to900mmDiaMeter,
                    DrainageLine901to1200mmDiaMeter: response.data.data[0].DrainageLine901to1200mmDiaMeter,
                    JettingSystem: response.data.data[0].JettingSystem,
                    SuctionSystem: response.data.data[0].SuctionSystem,
                    SuctionHose5Diameter3MeterLong: response.data.data[0].SuctionHose5Diameter3MeterLong,
                    JettingHose32Diameter120MeterLong: response.data.data[0].JettingHose32Diameter120MeterLong,
                    JettingNozzleDeChockingNozzle: response.data.data[0].JettingNozzleDeChockingNozzle,
                    DeSiltingNozzleFlatNozzle: response.data.data[0].DeSiltingNozzleFlatNozzle,
                    BalanceNozzleBombNozzle: response.data.data[0].BalanceNozzleBombNozzle,
                    BookingFinishLatitude: response.data.data[0].BookingFinishLatitude,
                    BookingFinishLongitude: response.data.data[0].BookingFinishLongitude,
                    BookingFinishAddress: response.data.data[0].BookingFinishAddress,
                    NoOfCleanMachineHole: response.data.data[0].NoOfCleanMachineHole,
                    BookingFinishRemarks: response.data.data[0].BookingFinishRemarks,
                    //Shift: response.data.data[0].Shift,
                    ShiftID: { 'label': response.data.data[0].Shift, 'value': response.data.data[0].Shift },
                    IsFinishEditable: true
                })
                //var VehicleReturnLocationTime=response.data.data[0].VehicleReturnLocationTime;
                // var VehicleReturnLocationTime=response.data.data[0].VehicleReturnLocationTime;
                // var split=VehicleReturnLocationTime.split(' ');
                // var datesplit=split[0].split('-');
                // var NewDate=datesplit[2]+'-'+datesplit[1]+'-'+datesplit[0]+'T'+split[1]+'';
                this.setState({ mobVehicleReturnLocationTime: response.data.data[0].VehicleReturnLocationTime });
                // var VehicleReachedParkingTime=response.data.data[0].VehicleReachedParkingTime;
                // var split=VehicleReachedParkingTime.split(' ');
                // var datesplit=split[0].split('-');
                // var NewDate=datesplit[2]+'-'+datesplit[1]+'-'+datesplit[0]+'T'+split[1]+'';
                this.setState({ mobVehicleReachedParkingTime: response.data.data[0].VehicleReachedParkingTime });
                this.setState({ DataVehicleReachedLocationDateTime: response.data.data[0].VehicleReachedLocationDateTime });

                this.setState({ isBookingFinishLatitudeVisible: true });
                this.setState({ isBookingFinishLongitudeVisible: true });
                this.setState({ isBookingFinishAddressVisible: true });

                if (response.data.data[0].BookingFinishLatitude != "" && response.data.data[0].BookingFinishLongitude != "" && response.data.data[0].BookingFinishAddress != "") {
                    this.setState({
                        BookingFinishLatitude: response.data.data[0].BookingFinishLatitude,
                        BookingFinishLongitude: response.data.data[0].BookingFinishLongitude,
                        BookingFinishAddress: response.data.data[0].BookingFinishAddress
                    });
                } else {
                    this.setState({
                        BookingFinishLatitude: "",
                        BookingFinishLongitude: "",
                        BookingFinishAddress: ""
                    });
                }

                this.setState({ VehicleReachedParkingTimeErrMsg: '' })

                var LunchStartDateTime = response.data.data[0].LunchStartDateTime;
                var split = LunchStartDateTime.split(' ');
                var datesplit = split[0].split('-');
                var NewDate = datesplit[2] + '-' + datesplit[1] + '-' + datesplit[0] + 'T' + split[1] + '';
                this.setState({ LunchStartTime: NewDate });

                var LunchEndDateTime = response.data.data[0].LunchEndDateTime;
                var split = LunchEndDateTime.split(' ');
                var datesplit = split[0].split('-');
                var NewDate = datesplit[2] + '-' + datesplit[1] + '-' + datesplit[0] + 'T' + split[1] + '';
                this.setState({ LunchEndTime: NewDate });
            }
            else {
                this.setState({ data: [] })
            }

        }, error => { })
    }

    EditApprovedBooking = (bookingid, bookingtaskdetailid, bookingno) => e => {
        e.preventDefault();
        this.setState({ BookingID: bookingid });
        this.setState({ BookingTaskDetailID: bookingtaskdetailid });
        //console.log("Clicked");
        this.setState({ modalvisible: true });
        this.setState({ IsApprovalClick: true });
        this.setState({ BookingNo: bookingno });

        //this.GetFinishData(bookingid);
        window.$(this.ApprovedModal).modal('show');
        var CompanyID = "";
        var ClientID = "";
        var BookingTaskChecklistDetailID = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        var data = JSON.stringify({
            "BookingID": bookingid
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getBookingTaskDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                // debugger;
                this.setState({ BookingNo: response.data.data[0].BookingNo });
                this.setState({ BookingApprovalStatus: { 'label': response.data.data[0].BookingApprovalStatus, 'value': response.data.data[0].BookingApprovalStatus } })
                this.setState({ BookingApprovalRemarks: response.data.data[0].BookingApprovalRemarks });
                this.setState({ TempLogsheetImage: response.data.data[0].LogSheetImage });
            } else {
                Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
            }
        }, error => { })

    }
    EditPaymentBooking = (bookingid, bookingtaskdetailid, bookingno) => e => {
        e.preventDefault();
        window.$(this.BookingPaymentModal).modal('show');
        this.setState({ BookingID: bookingid });
        this.setState({ BookingNo: bookingno });
        var CompanyID = "";
        var ClientID = "";


        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        var data = JSON.stringify({
            "BookingID": bookingid,
            "BookingTaskDetailID": bookingtaskdetailid
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getBookingTaskDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {

                this.setState({ BookingNo: response.data.data[0].BookingNo });
                // debugger;
                var dateString = response.data.data[0].PaymentReceivedDate;

                var dateMomentObject = moment(dateString, "DD-MM-YYYY"); // 1st argument - string, 2nd argument - format
                var date = dateMomentObject.toDate(); // convert moment.js object to Date object
                this.setState({ BillNo: response.data.data[0].BillNo });
                this.setState({ PaymentReceivedDate: date });
                this.setState({ PaymentAmount: response.data.data[0].PaymentAmount });
            } else {
                Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
            }
        }, error => { })
    }
    EditCompleteBooking = (bookingid, bookingtaskdetailid, bookingno) => e => {
        debugger;
        e.preventDefault();
        this.setState({ BookingID: bookingid });
        this.setState({ BookingTaskDetailID: bookingtaskdetailid });
        //console.log("Clicked");
        this.setState({ modalvisible: true });

        this.setState({ BookingNo: bookingno });
        this.setState({ IsCompleteClick: true });

        window.$(this.CompleteModal).modal('show');
        var data = JSON.stringify({
            "BookingID": bookingid
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getBookingTaskDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                this.setState({ BookingNo: response.data.data[0].BookingNo });
                this.setState({
                    VehicleReachedLocationOdometer: response.data.data[0].VehicleReachedLocationOdometer,
                    // VehicleReturnLocationTime: response.data.data[0].VehicleReturnLocationTime,
                    VehicleReturnLocationOdometer: response.data.data[0].VehicleReturnLocationOdometer,
                    // VehicleReachedParkingTime: response.data.data[0].VehicleReachedParkingTime,
                    VehicleReachedParkingOdometer: response.data.data[0].VehicleReachedParkingOdometer,
                    JettingFinishMeterReading: response.data.data[0].JettingFinishMeterReading,
                    SuctionPumpFinishMeterReading: response.data.data[0].SuctionPumpFinishMeterReading,
                    TotalNoOfDranageLine: response.data.data[0].TotalNoOfDranageLine,
                    DrainageLine150to300mmDiaMeter: response.data.data[0].DrainageLine150to300mmDiaMeter,
                    DrainageLine301to600mmDiaMeter: response.data.data[0].DrainageLine301to600mmDiaMeter,
                    DrainageLine601to900mmDiaMeter: response.data.data[0].DrainageLine601to900mmDiaMeter,
                    DrainageLine901to1200mmDiaMeter: response.data.data[0].DrainageLine901to1200mmDiaMeter,
                    JettingSystem: response.data.data[0].JettingSystem,
                    SuctionSystem: response.data.data[0].SuctionSystem,
                    SuctionHose5Diameter3MeterLong: response.data.data[0].SuctionHose5Diameter3MeterLong,
                    JettingHose32Diameter120MeterLong: response.data.data[0].JettingHose32Diameter120MeterLong,
                    JettingNozzleDeChockingNozzle: response.data.data[0].JettingNozzleDeChockingNozzle,
                    DeSiltingNozzleFlatNozzle: response.data.data[0].DeSiltingNozzleFlatNozzle,
                    BalanceNozzleBombNozzle: response.data.data[0].BalanceNozzleBombNozzle,
                    BookingFinishLatitude: response.data.data[0].BookingFinishLatitude,
                    BookingFinishLongitude: response.data.data[0].BookingFinishLongitude,
                    BookingFinishAddress: response.data.data[0].BookingFinishAddress,
                    NoOfCleanMachineHole: response.data.data[0].NoOfCleanMachineHole,
                    Shift: response.data.data[0].Shift,
                    BookingCompleteRemarks: response.data.data[0].BookingCompleteRemarks,
                    BookingCompleteRemarksGujarati: response.data.data[0].BookingCompleteRemarksGujarati,
                    TenderLiquidityDamagesCondition: response.data.data[0].TenderLiquidityDamagesCondition,

                    LogSheetNo: response.data.data[0].LogSheetNo,
                    LocationDetail: response.data.data[0].LocationDetail,
                    EngineerName: response.data.data[0].EngineerName,
                    EngineerDesignation: response.data.data[0].EngineerDesignation,
                    ExtraordinaryDetails: response.data.data[0].ExtraordinaryDetails,
                    ExtraordinaryArea: response.data.data[0].ExtraordinaryArea,
                    ExtraordinaryMachineHoles: response.data.data[0].ExtraordinaryMachineHoles,
                    SludgeDisposalArea: response.data.data[0].SludgeDisposalArea,

                    //LogSheetImage:response.data.data[0].LogSheetImage,
                    IsCompleteEditable: true
                });

                var VehicleReturnLocationTime = response.data.data[0].VehicleReturnLocationTime;
                var split = VehicleReturnLocationTime.split(' ');
                var datesplit = split[0].split('-');
                var NewDate = datesplit[2] + '-' + datesplit[1] + '-' + datesplit[0] + 'T' + split[1] + '';
                this.setState({ mobVehicleReturnLocationTime: new Date(NewDate) });
                var VehicleReachedParkingTime = response.data.data[0].VehicleReachedParkingTime;
                var split = VehicleReachedParkingTime.split(' ');
                var datesplit = split[0].split('-');
                var NewDate = datesplit[2] + '-' + datesplit[1] + '-' + datesplit[0] + 'T' + split[1] + '';
                this.setState({ mobVehicleReachedParkingTime: new Date(NewDate) });

                var MachineNotWorkFromTime = response.data.data[0].MachineNotWorkFromDateTime;
                if (MachineNotWorkFromTime != null) {
                    var split = MachineNotWorkFromTime.split(' ');
                    var datesplit = split[0].split('-');
                    var NewDate = datesplit[2] + '-' + datesplit[1] + '-' + datesplit[0] + 'T' + split[1] + '';
                    this.setState({ MachineNotWorkFromTime: NewDate });
                }

                var MachineNotWorkToTime = response.data.data[0].MachineNotWorkToDateTime;
                if (MachineNotWorkToTime != null) {
                    var split = MachineNotWorkToTime.split(' ');
                    var datesplit = split[0].split('-');
                    var NewDate = datesplit[2] + '-' + datesplit[1] + '-' + datesplit[0] + 'T' + split[1] + '';
                    this.setState({ MachineNotWorkToTime: NewDate });
                }

                // var MachineNotWorkTotalTime = response.data.data[0].MachineNotWorkTotalTime;
                // if (MachineNotWorkTotalTime != null) {
                //     var split = MachineNotWorkTotalTime.split(':');
                //     var NewDate = dayjs().set('hour', split[0]).set('minute', split[1]);
                //     this.setState({ MachineNotWorkTotalTime: NewDate });
                // }

                var LogImage = response.data.data[0].LogSheetImage
                if (response.data.data[0].LogSheetImage !== null) {
                    this.setState({ LogImage: response.data.data[0].LogSheetImage })
                }

            }
            else {
                this.setState({ data: [] })
            }

        }, error => { })

    }
    CheckAlert(BookingID, BookingTaskDetailID, bookingdate, ZoneID, DistrictID, MuncipalityID, IsRefferBack) {

        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                // debugger;

                var data = JSON.stringify({
                    "BookingID": BookingID,
                    "BookingTaskDetailID": BookingTaskDetailID
                });
                var config = {
                    method: 'POST',
                    url: services.API_URL + "Booking/removeAssignBooking",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        this.EditModalAssignedBooking(BookingID, BookingTaskDetailID, bookingdate, ZoneID, DistrictID, MuncipalityID, IsRefferBack);
                        // this.GetCity();
                        // Swal.fire({
                        //     title: 'Successfully Deleted', icon: "success", timer: 1500
                        // });
                    } else {
                        if (BookingTaskDetailID == null) {
                            this.EditModalAssignedBooking(BookingID, BookingTaskDetailID, bookingdate, ZoneID, DistrictID, MuncipalityID, IsRefferBack);
                        }
                        else {
                            Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                        }

                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }
    OnRefferedBackAssignClick = (bookingid, ZoneID, DistrictID, MuncipalityID) => e => {
        // debugger;
        e.preventDefault();
        this.setState({ BookingID: bookingid });
        //console.log("Clicked");
        this.setState({ modalvisible: true });
        window.$(this.ReferredAssignModal).modal('show');

        this.setState({ BookingZoneID: ZoneID });
        this.setState({ BookingDistrictID: DistrictID });
        this.setState({ BookingMunicipalityID: MuncipalityID });

    }
    OnAssignStartClick = (bookingdate, bookingid, bookingtaskdetailid) => e => {
        // debugger;
        this.GetParkingLocation();
        this.GetCheckList();
        e.preventDefault();
        this.setState({ BookingID: bookingid });
        this.setState({ BookingTaskDetailID: bookingtaskdetailid });

        this.GetVehicleOldTaskData(bookingid, bookingtaskdetailid)

        //console.log("Clicked");
        this.setState({ modalvisible: true });
        window.$(this.modals).modal('show');

        // this.GetHelper(bookingdate);
    }
    OnAssignFinishClick = (bookingdate, bookingid, bookingtaskdetailid) => e => {
        // debugger;
        e.preventDefault();
        this.setState({ BookingID: bookingid });
        this.setState({ BookingTaskDetailID: bookingtaskdetailid });
        //console.log("Clicked");
        this.setState({ modalvisible: true });
        window.$(this.FinishModal).modal('show');

        // this.GetHelper(bookingdate);
    }
    OnAssignCompleteClick = (bookingdate, bookingid, bookingtaskdetailid) => e => {
        // debugger;
        e.preventDefault();
        this.setState({ BookingID: bookingid });
        this.setState({ BookingTaskDetailID: bookingtaskdetailid });
        //console.log("Clicked");
        this.setState({ modalvisible: true });
        this.GetFinishData(bookingid);
        window.$(this.CompleteModal).modal('show');


        // this.GetHelper(bookingdate);
    }
    OnAssignApprovedClick = (bookingdate, bookingid, bookingtaskdetailid, vehicleno, VehicleLeaveParkingDateTime, VehicleReachedLocationDateTime, VehicleReturnLocationDateTime, VehicleReachedParkingDateTime) => e => {
        debugger;
        e.preventDefault();
        var bdate = bookingdate;
        var bid = bookingid;
        var btd = bookingtaskdetailid;
        var vno = vehicleno;
        var vehicleleave = VehicleLeaveParkingDateTime;
        var vehiclereached = VehicleReachedLocationDateTime;
        var vehiclereturn = VehicleReturnLocationDateTime;
        var vehicleparking = VehicleReachedParkingDateTime;

        this.setState({ BookingID: bookingid });
        this.setState({ BookingTaskDetailID: bookingtaskdetailid });
        this.setState({ Tempvehicleno: vehicleno });
        this.setState({ TempVehicleLeaveParkingDateTime: VehicleLeaveParkingDateTime });
        this.setState({ TempVehicleReachedLocationDateTime: VehicleReachedLocationDateTime });
        this.setState({ TempVehicleReturnLocationDateTime: VehicleReturnLocationDateTime });
        this.setState({ TempVehicleReachedParkingDateTime: VehicleReachedParkingDateTime });
        //console.log("Clicked");
        this.setState({ modalvisible: true });
        //this.GetFinishData(bookingid);
        window.$(this.ApprovedModal).modal('show');


        // this.GetHelper(bookingdate);
    }
    onAssignPaymentClick = (BookingID, bookingno) => e => {
        // debugger;
        e.preventDefault();
        window.$(this.BookingPaymentModal).modal('show');
        this.setState({ BookingID: BookingID });
        this.setState({ BookingNo: bookingno });
    }
    CancelSubAdminTask(bookingid, bookingtaskdetailid) {
        debugger;

        this.setState({ BookingID: bookingid });
        this.setState({ BookingTaskDetailID: bookingtaskdetailid });

        this.setState({ modalvisible: true });
        window.$(this.CancelTaskModal).modal('show');

    }
    UpdateVehicleReturnLocationTime = (VehicleReturnLocationTime) => {
        debugger;
        this.forceUpdate();
        this.setState({ mobVehicleReturnLocationTime: VehicleReturnLocationTime },
            () => console.log(this.state.mobVehicleReturnLocationTime));
    }
    GetFinishData(bookingid) {
        // debugger;
        var data = JSON.stringify({
            "BookingID": bookingid
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getBookingTaskDetail",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                debugger;
                this.setState({

                    VehicleReachedLocationOdometer: response.data.data[0].VehicleReachedLocationOdometer,
                    //mobVehicleReturnLocationTime: response.data.data[0].VehicleReturnLocationTime,
                    VehicleReturnLocationOdometer: response.data.data[0].VehicleReturnLocationOdometer,
                    //mobVehicleReachedParkingTime: response.data.data[0].VehicleReachedParkingTime,
                    VehicleReachedParkingOdometer: response.data.data[0].VehicleReachedParkingOdometer,
                    JettingStartMeterReading: response.data.data[0].JettingStartMeterReading,
                    JettingFinishMeterReading: response.data.data[0].JettingFinishMeterReading,
                    SuctionPumpStartMeterReading: response.data.data[0].SuctionPumpStartMeterReading,
                    SuctionPumpFinishMeterReading: response.data.data[0].SuctionPumpFinishMeterReading,
                    TotalNoOfDranageLine: response.data.data[0].TotalNoOfDranageLine,
                    DrainageLine150to300mmDiaMeter: response.data.data[0].DrainageLine150to300mmDiaMeter,
                    DrainageLine301to600mmDiaMeter: response.data.data[0].DrainageLine301to600mmDiaMeter,
                    DrainageLine601to900mmDiaMeter: response.data.data[0].DrainageLine601to900mmDiaMeter,
                    DrainageLine901to1200mmDiaMeter: response.data.data[0].DrainageLine901to1200mmDiaMeter,
                    JettingSystem: response.data.data[0].JettingSystem,
                    SuctionSystem: response.data.data[0].SuctionSystem,
                    SuctionHose5Diameter3MeterLong: response.data.data[0].SuctionHose5Diameter3MeterLong,
                    JettingHose32Diameter120MeterLong: response.data.data[0].JettingHose32Diameter120MeterLong,
                    JettingNozzleDeChockingNozzle: response.data.data[0].JettingNozzleDeChockingNozzle,
                    DeSiltingNozzleFlatNozzle: response.data.data[0].DeSiltingNozzleFlatNozzle,
                    BalanceNozzleBombNozzle: response.data.data[0].BalanceNozzleBombNozzle,
                    BookingFinishLatitude: response.data.data[0].BookingFinishLatitude,
                    BookingFinishLongitude: response.data.data[0].BookingFinishLongitude,
                    BookingFinishAddress: response.data.data[0].BookingFinishAddress,
                    NoOfCleanMachineHole: response.data.data[0].NoOfCleanMachineHole,
                    TotalJettingUsageTime: response.data.data[0].TotalJettingUsageTime,
                    TotalSuctionPumpUsageTime: response.data.data[0].TotalSuctionPumpUsageTime,
                    Shift: response.data.data[0].Shift
                })

                var VehicleReturnLocationTime = response.data.data[0].VehicleReturnLocationTime;
                var split = VehicleReturnLocationTime.split(' ');
                var datesplit = split[0].split('-');
                var NewDate = datesplit[2] + '-' + datesplit[1] + '-' + datesplit[0] + 'T' + split[1] + '';

                this.setState({ mobVehicleReturnLocationTime: new Date(NewDate) });


                var VehicleReachedParkingTime = response.data.data[0].VehicleReachedParkingTime;
                var split = VehicleReachedParkingTime.split(' ');
                var datesplit = split[0].split('-');
                var NewDate = datesplit[2] + '-' + datesplit[1] + '-' + datesplit[0] + 'T' + split[1] + '';
                this.setState({ mobVehicleReachedParkingTime: new Date(NewDate) });

            }
            else {
                this.setState({ data: [] })
            }

        }, error => { })
    }
    onSearchBookingClick = (e) => {
        e.preventDefault();
        this.GetBooking();
    }
    OnSearchCancelClientClick = () => {
        //// debugger;
        this.setState({ BookingNo: "", SearchVehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' }, MunicipalityID: [], StartDate: new Date(), EndDate: new Date() }, () => {
            this.GetBooking();
        });

    }
    ValidateShift = (e) => {
        this.setState({ ShiftErrMsg: '' })
    }
    ValidateCancleShift = (e) => {
        this.setState({ ShiftErrMsg: 'required' })
    }
    onShiftChange = (e) => {
        debugger;
        if (e != null) {
            this.setState({ ShiftID: e }, (e) => { this.ValidateShift(); })
        }
        else {
            this.setState({ ShiftID: { 'label': '--Select Shift--', 'value': '--Select Shift--' } }, () => { this.ValidateCancleShift() });
        }
    }
    onStartDateChange = (e) => {
        this.setState({ StartDate: e });
        this.setState({ EndDate: e });
    }
    onMunicipalityChange = (value) => {
        //// debugger;
        this.setState({ MunicipalityID: value });

        // }


        // if (event.action === "select-option" && event.option.value === "*") {
        //     this.setState(this.municipalities);
        //   } else if (
        //     event.action === "deselect-option" &&
        //     event.option.value === "*"
        //   ) {
        //     this.setState([]);
        //   } else if (event.action === "deselect-option") {
        //     this.setState(value.filter((o) => o.value !== "*"));
        //   } else if (value.length === this.municipalities.length - 1) {
        //     this.setState(this.options);
        //   } else {
        //     this.setState(value);
        //   }

        // this.setState({ MunicipalityID: e });
    }
    ValidateVehicle = (e) => {
        this.setState({ VehicleErrMsg: "" });
    }
    ValidateCancelVehicle = (e) => {
        this.setState({ VehicleErrMsg: "required" });
    }
    onVehicleChange = (e) => {
        if (e != null) {
            this.setState({ VehicleID: e }, () => { this.ValidateVehicle() });
        }
        else {
            this.setState({ VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' } }, () => { this.ValidateCancelVehicle() });
        }
    }
    ValidateDriver = (e) => {
        this.setState({ DriverErrMsg: "" });
    }
    ValidateCancelDriver = (e) => {
        this.setState({ DriverErrMsg: "required" });
    }
    onDriverChange = (e) => {
        if (e != null) {
            this.setState({ DriverID: e }, () => { this.ValidateDriver() });
        }
        else {
            this.setState({ DriverID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' } }, () => { this.ValidateCancelDriver() });
        }
    }
    allowOnlyNumbers = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
        return;
    };
    allowOnlyNumbersGem = (e) => {
        var code = ("charCode" in e) ? e.charCode : e.keyCode;
        if (!(code > 47 && code < 58) && //numeric (0-9)
            !(code == 58)) { //colon character (:)
            e.preventDefault();
            return
        }
    };
    onDynamicHelper = (e, target) => {
        // debugger;
        if (e.target.value == "Select All") {
            if (target == false) {
                this.setState({
                    HelperID: this.state.helpers.map(item =>
                        ({ value: item.value, label: item.label }))
                });
            }
            else {
                this.setState({ HelperID: "" });
            }

        }
        else {
            this.setState({ HelperID: e.target.value });
        }
    }
    ValidateHelper = (e) => {
        this.setState({ HelperErrMsg: "" });
    }
    ValidateCancelHelper = (e) => {
        this.setState({ HelperErrMsg: "required" });
    }
    onHelperChange = (e, action) => {
        // debugger;

        let helpers = this.state.helpers;
        var allvalueselectted = false;
        var isValid = false;
        helpers.forEach(helpers => {
            // debugger
            if (helpers.id === e.target.value) {
                helpers.isChecked = e.target.checked;
            }
            if (helpers.isChecked == false) {
                allvalueselectted = true;
            }
            if (helpers.isChecked == true) {
                isValid = true;
            }
            // this.setState({helpers:true});
            //this.setState({isChecked:true});
        });
        this.setState({ helpers: helpers });
        if (allvalueselectted == false) {
            this.setState({ isChecked: true });
        }
        else {
            this.setState({ isChecked: false });
        }
        if (isValid == true) {
            this.ValidateHelper();
        }
        else {
            this.ValidateCancelHelper();
        }
        // if (e.target.checked==true) {
        //     this.setState({ isChecked: true });
        // }
        // else{
        //     this.setState({ isChecked: false });
        // }
        // this.setState({
        //     isChecked: this.state.helpers.map(item =>
        //         ({ id: item.value, value: item.label, isChecked: true }))
        // });
        //this.setState({ IsHelperChange: true }, () => { this.onDynamicHelper(e, e.target.checked) });
    }
    onHelperAllChange = (e, action) => {
        // debugger;

        if (e.target.checked == true) {
            this.setState({ isChecked: true });
            this.setState({
                helpers: this.state.helpers.map(item =>
                    ({ id: item.id, value: item.value, isChecked: true }))
            }, () => { this.ValidateHelper() });
        }
        else {
            this.setState({ isChecked: false });
            this.setState({
                helpers: this.state.helpers.map(item =>
                    ({ id: item.id, value: item.value, isChecked: false }))
            }, () => { this.ValidateCancelHelper() });
        }
        //this.setState({ IsHelperChange: true });
        //this.setState({ IsHelperChange: true }, () => { this.onDynamicHelper(e, e.target.checked) });
    }
    ValidateCheckList = (e) => {
        this.setState({ CheckListErrMsg: "" });
    }
    ValidateCancelCheckList = (e) => {
        this.setState({ CheckListErrMsg: "required" });
    }
    isValidFileUploaded = (file) => {
        const validExtensions = ['png', 'jpeg', 'jpg']
        const fileExtension = file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
    }
    OnTaskImageUpload = e => {
        // debugger;
        if (e.target.files.length < 1) {
            this.setState({ TaskImageErrMsg: "" });
            return;
        }
        else {
            this.setState({ TaskImageErrMsg: "*" });
        }
        const file = e.target.files[0];
        if (this.isValidFileUploaded(file)) {
            this.setState({ TaskFile: e.target.files, TaskImages: e.target.files[0].originalname })
        } else {
            this.setState({ TaskImageErrMsg: "Not Valid File" });
            return false;
        }

    };
    OnFinishTaskImageUpload = e => {
        // debugger;
        if (e.target.files.length < 1) {
            this.setState({ FinishTaskImageErrMsg: "*" });
            return;
        }
        else {
            this.setState({ FinishTaskImageErrMsg: "" });
        }
        const file = e.target.files[0];
        if (this.isValidFileUploaded(file)) {
            this.setState({ FinishTaskFile: e.target.files, FinishTaskImages: e.target.files[0].originalname })
        } else {
            this.setState({ FinishTaskImageErrMsg: "Not Valid File" });
            return false;
        }

    };
    OnCompleteTaskImageUpload = e => {
        // debugger;
        if (e.target.files.length < 1) {
            this.setState({ CompleteTaskErrImage: "*" });
            return;
        }
        else {
            this.setState({ CompleteTaskErrImage: "" });
        }
        const file = e.target.files[0];
        if (this.isValidFileUploaded(file)) {
            this.setState({ LogSheetTaskFile: e.target.files, LogSheetImage: e.target.files[0].originalname })
        } else {
            this.setState({ CompleteTaskErrImage: "Not Valid File" });
            return false;
        }

    };
    onCheckListChange = (e, action) => {
        // debugger;

        let checklists = this.state.checklists;
        var allvalueselectted = false;
        var isValid = false;
        checklists.forEach(checklists => {
            // debugger
            if (checklists.id === e.target.value) {
                checklists.isCheckListChecked = e.target.checked;
            }
            if (checklists.isCheckListChecked == false) {
                allvalueselectted = true;
            }
            if (checklists.isCheckListChecked == true) {
                isValid = true;
            }
            // this.setState({helpers:true});
            //this.setState({isChecked:true});
        });
        this.setState({ checklists: checklists });
        if (allvalueselectted == false) {
            this.setState({ isCheckListChecked: true });
        }
        else {
            this.setState({ isCheckListChecked: false });
        }
        if (isValid == true) {
            this.ValidateCheckList();
        }
        else {
            this.ValidateCancelCheckList();
        }
    }
    onCheckListAllChange = (e, action) => {
        // debugger;

        if (e.target.checked == true) {
            this.setState({ isCheckListChecked: true });
            this.setState({
                checklists: this.state.checklists.map(item =>
                    ({ id: item.id, value: item.value, isCheckListChecked: true }))
            }, () => { this.ValidateCheckList() });
        }
        else {
            this.setState({ isCheckListChecked: false });
            this.setState({
                checklists: this.state.checklists.map(item =>
                    ({ id: item.id, value: item.value, isCheckListChecked: false }))
            }, () => { this.ValidateCancelCheckList() });
        }
        //this.setState({ IsHelperChange: true });
        //this.setState({ IsHelperChange: true }, () => { this.onDynamicHelper(e, e.target.checked) });
    }
    handleAssign = (e) => {
        // debugger;
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var UserType = "";
        let helperdatas = [];
        e.preventDefault();
        var allvalid = true;
        if (this.state.VehicleID.value == "--Select Vehicle--") {
            this.setState({ VehicleErrMsg: 'required' });
            allvalid = false;
        }

        if (this.state.DriverID.value == "--Select Driver--") {
            this.setState({ DriverErrMsg: 'required' });
            allvalid = false;
        }

        let helpers = this.state.helpers;
        var allvalueselectted = false;
        helpers.forEach(helpers => {

            // if (helpers.id===e.target.value) {
            //    // helpers.isChecked=e.target.checked;
            //     helperdatas.push(helpers.id)
            // }
            if (helpers.isChecked == true) {
                allvalueselectted = true;
                helperdatas.push({ "UserID": helpers.id })
            }

            // if (allvalueselectted==false) {
            //     this.setState({ HelperErrMsg: 'required' });
            //     allvalid=false;
            // }
            // this.setState({helpers:true});
            //this.setState({isChecked:true});
        });
        if (helperdatas.length === 0) {
            this.setState({ HelperErrMsg: 'required' });
            allvalid = false;
        }
        if (allvalid == true) {
            if (this.props.allCookies.MainCompanyID !== undefined) {
                CompanyID = this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID !== undefined) {
                ClientID = this.props.allCookies.MainClientID
            }
            if (this.props.allCookies.UserID !== undefined) {
                UserID = this.props.allCookies.UserID
            }
            if (this.props.allCookies.PanelUserType !== undefined) {
                UserType = this.props.allCookies.PanelUserType
            }

            if (CompanyID != "" && ClientID != "") {

                var IsUpdateSecondTime = "";
                if (this.state.IsAssignedClick === true) {
                    IsUpdateSecondTime = "true";
                }


                var data = "";
                data = JSON.stringify({
                    "BookingID": this.state.BookingID,
                    "AssignSupervisorID": UserID,
                    "VehicleID": this.state.VehicleID.value,
                    "DriverID": this.state.DriverID.value,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID,
                    "HelperData": helperdatas,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "IsUpdateSecondTime": IsUpdateSecondTime,
                    "BookingNo": this.state.BookingNo
                });
                var config = {
                    method: 'POST',
                    url: services.API_URL + "Booking/setAssignBooking",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    ////// debugger;
                    if (response.data.status) {
                        //// debugger;
                        Swal.fire({
                            title: 'Successfully Inserted', icon: "success", timer: 1500
                        });
                        this.cancelAssign();
                        //this.GetMuncipality();
                    }
                    else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                }, error => { })

            }
            else {
                if (CompanyID == "" && ClientID == "") {
                    Swal.fire({
                        title: 'Please Select Company & Client', icon: "error", timer: 1500
                    });
                }
                else if (CompanyID != "" && ClientID == "") {
                    Swal.fire({
                        title: 'Please Select Client', icon: "error", timer: 1500
                    });
                }
                else if (CompanyID == "" && ClientID != "") {
                    Swal.fire({
                        title: 'Please Select Company', icon: "error", timer: 1500
                    });
                }
                else {
                    Swal.fire({
                        title: 'Something went wrong', icon: "error", timer: 1500
                    });
                }
            }
        }

    }
    cancelAssign = (e) => {
        // debugger;
        this.setState({
            vehicles: [], drivers: [], helpers: [{ 'id': 'No Helpers', 'value': 'No Helpers' }],
            isChecked: false,
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            DriverID: { 'label': '--Select Driver--', 'value': '--Select Driver--' },
            VehicleErrMsg: "", DriverErrMsg: "", HelperErrMsg: "", IsAssignedClick: false, BookingNo: ""
        })
        window.$(this.modal).modal('hide');
        window.$(this.EditAssignmodal).modal('hide');
        this.GetBooking();
    }
    cancelStart = (e) => {
        this.setState({
            parkinglocations: [], checklists: [],
            isCheckListChecked: false,
            ParkingLocationID: { 'label': '--Select Parking Location--', 'value': '--Select Parking Location--' },
            VehicleLeaveParkingTime: "", VehicleReachedLocationTime: "", VehicleLeaveParkingOdometer: "", JettingStartMeterReading: "",
            SuctionPumpStartMeterReading: "", TaskFile: [], TaskImages: "", Latitude: null, Longitude: null, CurrentAddress: "",
            ParkingLocationErrMsg: "", TaskImageErrMsg: "", CheckListErrMsg: "", IsStartEditable: false, IsStartClick: false,
            mobVehicleLeaveParkingTime: new Date(), FullVehicleReachedLocationTime: this.addMinutes(new Date(), 1), mobVehicleReturnLocationTime: new Date(), mobVehicleReachedParkingTime: this.addMinutes(new Date(), 1),
            BookingNo: "",
        }, () => { this.ValidateParkingLocation() })

        this.setState({ BookingNo: '' }, () => {
            console.log(this.state.BookingNo)
        }
        )
        this.forms.reset();
        window.$(this.modals).modal('hide');
        this.setState({ BookingNo: "" }, () => { this.GetBooking(); })
    }
    cancelFinish = (e) => {
        // debugger;
        this.finishform.reset();
        window.$(this.FinishModal).modal('hide');
        this.setState({
            BookingNo: "",
            VehicleReachedLocationOdometer: "", VehicleReturnLocationTime: "", VehicleReturnLocationOdometer: "", VehicleReachedParkingTime: "",
            VehicleReachedParkingOdometer: "", JettingFinishMeterReading: "", SuctionPumpFinishMeterReading: "", TotalNoOfDranageLine: "",
            DrainageLine150to300mmDiaMeter: "", DrainageLine301to600mmDiaMeter: "", DrainageLine601to900mmDiaMeter: "",
            DrainageLine901to1200mmDiaMeter: "", JettingSystem: "150", SuctionSystem: "5000", SuctionHose5Diameter3MeterLong: "10",
            JettingHose32Diameter120MeterLong: "1", JettingNozzleDeChockingNozzle: "1", DeSiltingNozzleFlatNozzle: "1",
            BalanceNozzleBombNozzle: "1", BookingFinishLatitude: "", BookingFinishLongitude: "", BookingFinishAddress: "",
            Shift: "", FinishTaskImageErrMsg: "", IsFinishEditable: false, IsFinishClick: false, BookingNo: "",
            mobVehicleLeaveParkingTime: new Date(), FullVehicleReachedLocationTime: this.addMinutes(new Date(), 1), mobVehicleReturnLocationTime: new Date(), mobVehicleReachedParkingTime: this.addMinutes(new Date(), 1),
            LunchStartTime: "", LunchEndTime: "",
        }, () => { this.GetBooking(); })


    }
    cancelComplete = (e) => {
        this.setState({
            BookingCompleteRemarks: "", BookingCompleteRemarksGujarati: "", TenderLiquidityDamagesCondition: "", BookingCompleteStatus: "",
            LogSheetTaskFile: [], LogSheetImage: "", IsCompleteEditable: false, IsCompleteClick: false, BookingNo: "",

            LogSheetNo: "", LocationDetail: "", EngineerName: "", EngineerDesignation: "", MachineNotWorkFromTime: "", MachineNotWorkToTime: "",
            MachineNotWorkTotalTime: "", ExtraordinaryDetails: "", ExtraordinaryArea: "", ExtraordinaryMachineHoles: "",
            SludgeDisposalArea: "Yes",
        })
        window.$(this.CompleteModal).modal('hide');
        this.setState({ BookingNo: "" }, () => { this.GetBooking(); })
    }
    onCancelApproval = (e) => {
        // debugger;
        //e.preventDefault();
        this.setState({
            BookingApprovalRemarks: "",
            BookingApprovals: [{ 'label': 'Approved', 'value': 'Approved' }, { 'label': 'Referred Back', 'value': 'Referred Back' }],
            BookingApprovalStatus: { 'label': '--Select Booking Status--', 'value': '--Select Booking Status--' },
            IsApprovalClick: false, BookingNo: ""
        })
        window.$(this.ApprovedModal).modal('hide');
        this.setState({ BookingNo: "" }, () => { this.GetBooking(); })
    }
    cancelReferredBackAssign = (e) => {
        this.setState({
            vehicles: [], drivers: [], helpers: [{ 'id': 'No Helpers', 'value': 'No Helpers' }],
            isChecked: false,
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            DriverID: { 'label': '--Select Driver--', 'value': '--Select Driver--' },
            VehicleErrMsg: "", DriverErrMsg: "", HelperErrMsg: "", RefferBackAssignDate: "",
            BookingZoneID: "", BookingDistrictID: "", BookingMunicipalityID: ""
        })
        window.$(this.ReferredAssignModal).modal('hide');
        window.$(this.EditReferredAssignModal).modal('hide');
        this.setState({ BookingNo: "" }, () => { this.GetBooking(); })
    }
    cancelTaskCancelClick = (e) => {
        this.setState({ TaskCancelRemarks: '' })
        this.CancelTask.reset();
        window.$(this.CancelTaskModal).modal('hide');
        this.setState({ BookingNo: "" }, () => { this.GetBooking(); })
    }
    ValidateParkingLocation = (e) => {
        this.setState({ ParkingLocationErrMsg: "" });
    }
    ValidateCancelParkingLocation = (e) => {
        this.setState({ ParkingLocationErrMsg: "required" });
    }
    EditBooking(e) {
        window.location.href = "/subadminbooking?id=" + e;
    }
    onParkingLocationChange = (e) => {
        if (e != null) {
            this.setState({ ParkingLocationID: e }, () => { this.ValidateParkingLocation() });
        }
        else {
            this.setState({ ParkingLocationID: { 'label': '--Select Parking Location--', 'value': '--Select Parking Location--' } }, () => { this.ValidateCancelParkingLocation() });
        }
    }
    ValidateCurrentAddress = (Latitude, Longitude, CurrentAddress) => e => {
        // debugger;
        //this.setState({ ParkingLocationErrMsg: "" });
        this.setState({ Latitude: Latitude });
        this.setState({ Longitude: Longitude });
        this.setState({ CurrentAddress: CurrentAddress });
    }
    onGetGPSLocation = (e) => {
        // debugger;
        // const address = "";
        const lat = "";
        const long = "";
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);


            // this.setState({ Logitudes: position.coords.longitude });

            Geocode.setApiKey("AIzaSyCHWhwpVwgDuwit10HARKJ2eS1IjrSmHeU");
            Geocode.setLanguage("en");
            Geocode.setRegion("es");
            Geocode.setLocationType("ROOFTOP");
            Geocode.enableDebug();
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                (response) => {
                    // debugger;
                    const address = response.results[0].formatted_address;
                    //this.setState({ CurrentAddress: address });
                    // lat=position.coords.latitude;
                    // long=position.coords.longitude;
                    console.log(address);
                },
                (error) => {
                    console.error(error);
                }
            );

        });
        //this.setState({ getCurrentLocation: true }, () => { this.ValidateCurrentAddress(lat,long,address) });
    }
    OnStartTaskSubmit = (e) => {
        debugger;

        // var dt=this.state.mobVehicleLeaveParkingTime;
        // var date=dt;
        // var djs=new Date(date);
        // var now=moment(djs).format('DD-MM-YYYY hh:mm:ss');

        // var VehicleLeaveParkingTime=moment(djs).format('DD-MM-YYYY HH:mm:ss');


        // var locationdate=new Date(this.state.FullVehicleReachedLocationTime);
        // // var VehicleReachedLocationTime = moment(locationdate,"DD-MM-YYYY hh:mm:ss");
        // var VehicleReachedLocationTime=moment(locationdate).format('DD-MM-YYYY HH:mm:ss');
        // var VehicleLeaveParkingTime=moment(this.state.mobVehicleLeaveParkingTime, "DD-MM-YYYY hh:mm:ss");
        let checklistdatas = [];
        e.preventDefault();
        var allvalid = true;
        this.forms.validateFields();
        if (this.state.ParkingLocationID.value == "--Select Parking Location--") {
            this.setState({ ParkingLocationErrMsg: "required" });
            allvalid = false;
        }
        var JetMsg = this.state.JettingStartMeterReadingErrMsg;
        if (this.state.JettingStartMeterReadingErrMsg == "greater") {
            allvalid = false;
        }
        if (this.state.VehicleLeaveParkingTimeFormatErrMsg == "invalid") {
            allvalid = false;
        }
        if (this.state.VehicleReachedParkingTimeFormatErrMsg == "invalid") {
            allvalid = false;
        }
        if (this.state.JettingStartMeterReading == "") {
            allvalid = false;
        }
        if (this.state.SuctionPumpStartMeterReadingErrMsg == "greater") {
            allvalid = false;
        }
        if (this.state.VehicleReachedLocationTimeErrMsg == "greater") {
            allvalid = false;
        }

        if (this.state.IsStartEditable == false) {
            // if (this.state.TaskFile.length==0) {
            //     this.setState({ TaskImageErrMsg: "required" });
            //     allvalid=false;
            // }
        }
        var lat = this.state.Latitude;
        var lng = this.state.Longitude;
        var addr = this.state.CurrentAddress;
        // if (lat===""||lng===""||addr==="") {
        //     allvalid=false;
        //     Swal.fire({ position: 'top-end', toast: true, title: 'Please share your current location', icon: "error", showConfirmButton: false, timer: 1500 })
        // }

        // if (this.state.VehicleReachedParkingTimeErrMsg!=="") {
        //     allvalid=false;
        // }
        // if (this.state.VehicleReachedLocationTimeErrMsg!=="") {
        //     allvalid=false;
        // }

        let checklists = this.state.checklists;
        var allvalueselectted = false;
        checklists.forEach(checklists => {

            // if (helpers.id===e.target.value) {
            //    // helpers.isChecked=e.target.checked;
            //     helperdatas.push(helpers.id)
            // }
            if (checklists.isCheckListChecked == true) {
                allvalueselectted = true;
                checklistdatas.push({ "CheckListID": checklists.id })
            }

            // if (allvalueselectted==false) {
            //     this.setState({ CheckListErrMsg: 'required' });
            //     allvalid=false;
            // }
            // this.setState({helpers:true});
            //this.setState({isChecked:true});
        });
        if (checklistdatas.length == 0) {
            this.setState({ CheckListErrMsg: 'required' });
            allvalid = false;
        }

        if ((lat == "" && lng == "" && addr == "") || (lat != "" && lng != "" && addr != "")) {
            if (lat == "") {
                this.setState({ GeoLatitudeErrMsg: "" });
            }
            if (lng == "") {
                this.setState({ GeoLongitudeVErrMsg: "" });
            }
            if (addr == "") {
                this.setState({ GeoAddressErrMsg: "" });
            }
        } else {
            allvalid = false;
            if (lat == "") {
                this.setState({ GeoLatitudeErrMsg: "required" });
            }
            if (lng == "") {
                this.setState({ GeoLongitudeVErrMsg: "required" });
            }
            if (addr == "") {
                this.setState({ GeoAddressErrMsg: "required" });
            }
        }

        if (this.forms.isValid()) {
            if (allvalid == true) {
                var CompanyID = "";
                var ClientID = "";
                var UserID = "";
                var UserType = "";
                if (this.props.allCookies.MainCompanyID !== undefined) {
                    CompanyID = this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID !== undefined) {
                    ClientID = this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID !== undefined) {
                    UserID = this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType !== undefined) {
                    UserType = this.props.allCookies.PanelUserType
                }
                var IsUpdateSecondTime = "";
                var UpdateBy = "";
                if (this.state.IsStartClick === true) {
                    IsUpdateSecondTime = "true";
                    UpdateBy = "Panel";
                }

                var BookingTaskDetailID = this.state.BookingTaskDetailID;
                var BookingID = this.state.BookingID;
                var ParkingLocationID = this.state.ParkingLocationID.value
                // var VehicleLeaveParkingTime=moment(this.state.mobVehicleLeaveParkingTime,"DD-MM-YYYY hh:mm:ss")
                // var VehicleReachedLocationTime=moment(this.state.FullVehicleReachedLocationTime,"DD-MM-YYYY hh:mm:ss")
                var VehicleLeaveParkingOdometer = this.state.VehicleLeaveParkingOdometer
                var JettingStartMeterReading = this.state.JettingStartMeterReading
                var SuctionPumpStartMeterReading = this.state.SuctionPumpStartMeterReading
                var filess = this.state.TaskFile;
                var Latitude = this.state.Latitude;
                var Longitude = this.state.Longitude;
                var CurrentAddress = this.state.CurrentAddress;
                var TotalJettingUsageTime = this.state.TotalJettingUsageTime;
                var TotalSuctionPumpUsageTime = this.state.TotalSuctionPumpUsageTime;
                var VehicleReachedLocationTime = this.state.FullVehicleReachedLocationTime;
                var VehicleLeaveParkingTime = this.state.mobVehicleLeaveParkingTime;
                // navigator.geolocation.getCurrentPosition(function (position) {
                //     console.log("Latitude is :", position.coords.latitude);
                //     console.log("Longitude is :", position.coords.longitude);
                //     Latitude=position.coords.latitude;
                //     Longitude=position.coords.longitude;

                //     // this.setState({ Logitudes: position.coords.longitude });

                //     Geocode.setApiKey("AIzaSyCHWhwpVwgDuwit10HARKJ2eS1IjrSmHeU");
                //     Geocode.setLanguage("en");
                //     Geocode.setRegion("es");
                //     Geocode.setLocationType("ROOFTOP");
                //     Geocode.enableDebug();
                //     // Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                //     //     (response) => {
                //     //         // debugger;
                //     //         const address=response.results[0].formatted_address;
                //     //         CurrentAddress=address;


                //     //     },
                //     //     (error) => {
                //     //         console.error(error);
                //     //     }
                //     // );


                // });

                var form = new FormData();
                form.append('BookingTaskDetailID', BookingTaskDetailID);
                form.append('BookingID', BookingID);
                form.append('ParkingLocationID', ParkingLocationID);
                form.append('VehicleLeaveParkingTime', VehicleLeaveParkingTime);
                form.append('VehicleReachedLocationTime', VehicleReachedLocationTime);
                form.append('VehicleLeaveParkingOdometer', VehicleLeaveParkingOdometer);
                form.append('VehicleReachedLocationOdometer', this.state.VehicleReachedLocationOdometer);
                form.append('JettingStartMeterReading', JettingStartMeterReading);
                form.append('SuctionPumpStartMeterReading', SuctionPumpStartMeterReading);
                form.append('TotalJettingUsageTime', TotalJettingUsageTime);
                form.append('TotalSuctionPumpUsageTime', TotalSuctionPumpUsageTime);

                form.append('BookingStartLatitude', Latitude);
                form.append('BookingStartLongitude', Longitude);
                form.append('BookingStartAddress', CurrentAddress);

                form.append('CheckListData', JSON.stringify(checklistdatas));
                form.append('CompanyID', CompanyID);
                form.append('ClientID', ClientID);
                form.append('BookingNo', this.state.BookingNo);
                form.append('EntryByUserType', UserType);
                form.append('EntryByUserID', UserID);
                form.append('IsUpdateSecondTime', IsUpdateSecondTime);
                // form.append('BookingStartDataEntryByType', "Panel");
                // form.append('BookingStartDataUpdateByType', UpdateBy);

                for (let i = 0; i < this.state.TaskFile.length; i++) { form.append("TaskImages", this.state.TaskFile[i]); }

                const { cookies } = this.props;
                if (VehicleLeaveParkingTime !== null) {
                    cookies.set('VehicleLeaveParkingTime', VehicleLeaveParkingTime, { path: '/' });
                }
                // if (djs!==null) {
                //     cookies.set('VehicleLeaveParkingDateTime', djs, { path: '/' });
                // }
                if (VehicleReachedLocationTime !== null) {
                    cookies.set('VehicleReachedLocationTime', VehicleReachedLocationTime, { path: '/' });
                }
                // if (locationdate!==null) {
                //     cookies.set('VehicleReachedLocationDateTime', locationdate, { path: '/' });
                // }
                if (this.state.VehicleReachedLocationOdometer !== null) {
                    cookies.set('VehicleReachedLocationOdometer', this.state.VehicleReachedLocationOdometer, { path: '/' });
                }
                if (JettingStartMeterReading !== null) {
                    cookies.set('JettingStartMeterReading', JettingStartMeterReading, { path: '/' });
                }

                if (SuctionPumpStartMeterReading !== null) {
                    cookies.set('SuctionPumpStartMeterReading', SuctionPumpStartMeterReading, { path: '/' });
                }

                // form.append("TaskImages", filess);
                // for (let i = 0; i < this.state.TaskFile.length; i++) 
                // { 
                //     form.append("TaskImages", filess);
                //     // debugger;

                //     // form.append("TaskImages", fs.createReadStream(this.state.TaskFile[i] )); 
                // }
                console.log("formdata", form);
                services.SubAdminFormValue(form).then(response => {
                    if (response.data.status) {
                        Swal.fire({
                            title: 'Successfully Started', icon: "success", timer: 1500
                        });
                        this.cancelStart();
                        this.GetBooking();
                        //UserService.GetServiceData().then(response => { this.setState({ data: response.data.data }) }, error => { })
                    } else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                    this.setState({ CarTypeID: '', Type: '', Title: '', file: [], filename: '', Price: '', Timeline: '', content: '', rows: [{ Title: '', Description: '' }] });
                }, error => { })
            }

        }

    }
    OnFinishTaskSubmit = (e) => {
        debugger;
        e.preventDefault();
        var allvalid = true;
        // var JettingFinishMeterReading=this.state.JettingFinishMeterReading;
        // if (this.props.allCookies.JettingStartMeterReading!==undefined) {
        //     var JettingStartMeterReading=this.props.allCookies.JettingStartMeterReading;

        //     var JettingFinish=this.state.JettingFinishMeterReading.split(':');
        //     var JettingStart=JettingStartMeterReading.split(':');

        //     var Hours=JettingFinish[0]-JettingStart[0];
        //     var Time=JettingFinish[1]-JettingStart[1];

        //     if (Time<0) {
        //         Time=(parseInt(60)+parseInt(JettingFinish[1]))-JettingStart[1];
        //         Hours=JettingFinish[0]-JettingStart[0]-1;
        //     }
        // }
        // var dt=this.state.mobVehicleReturnLocationTime;
        // var date=dt;
        // var djs=new Date(date);
        // var now=moment(djs).format('DD-MM-YYYY hh:mm:ss');

        // var VehicleReturnLocationTime=moment(djs).format('DD-MM-YYYY HH:mm:ss');


        // var locationdate=new Date(this.state.mobVehicleReachedParkingTime);
        // // var VehicleReachedLocationTime = moment(locationdate,"DD-MM-YYYY hh:mm:ss");
        // var VehicleReachedParkingTime=moment(locationdate).format('DD-MM-YYYY HH:mm:ss');

        var LunchStartTime = this.state.LunchStartTime;

        if (LunchStartTime != "") {
            var LunchStart = new Date(this.state.LunchStartTime);
            LunchStartTime = moment(LunchStart).format('HH:mm');
        }

        var LunchEndTime = this.state.LunchEndTime;

        if (LunchEndTime != "") {
            var LunchEnd = new Date(this.state.LunchEndTime);
            LunchEndTime = moment(LunchEnd).format('HH:mm');
        }

        this.finishform.validateFields();
        if (this.state.IsFinishEditable == false) {
            if (this.state.FinishTaskFile.length == 0) {
                this.setState({ FinishTaskImageErrMsg: "required" });
                allvalid = false;
            }
        }

        var dl150to300mmDiaMeter = this.state.DrainageLine150to300mmDiaMeter;
        var dl301to600mmDiaMeter = this.state.DrainageLine301to600mmDiaMeter;
        var dl601to900mmDiaMeter = this.state.DrainageLine601to900mmDiaMeter;
        var dl901to1200mmDiaMeter = this.state.DrainageLine901to1200mmDiaMeter;

        if ((dl150to300mmDiaMeter === "" || dl150to300mmDiaMeter == "null" || dl150to300mmDiaMeter == null) && (dl301to600mmDiaMeter === "" || dl301to600mmDiaMeter == "null" || dl301to600mmDiaMeter == null) && (dl601to900mmDiaMeter === "" || dl601to900mmDiaMeter == "null" || dl601to900mmDiaMeter == null) && (dl901to1200mmDiaMeter === "" || dl901to1200mmDiaMeter == "null" || dl901to1200mmDiaMeter == null)) {
            allvalid = false;
            Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: 'Please Enter Atleast One Drainage', showConfirmButton: false, timer: 3000 });
        }

        var cnt = 0;
        if (this.state.LunchStartTime !== "" && this.state.LunchEndTime !== "") {
            cnt = 0;
        }
        else if (this.state.LunchStartTime == "" && this.state.LunchEndTime == "") {
            cnt = 0;
        }
        else {
            cnt = 1;
        }

        if (cnt == 1) {
            allvalid = false;
            Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: 'Please Enter Both Lunch Start Time & Lunch End Time', showConfirmButton: false, timer: 3000 });
        } else {
            if (this.state.LunchStartTimeErrMsg != "") {
                allvalid = false;
            }
            if (this.state.LunchEndTimeErrMsg != "") {
                allvalid = false;
            }
        }

        if (this.state.VehicleReachedParkingOdometerErrMsg !== "") {
            allvalid = false;
        }
        if (this.state.VehicleReturnLocationTimeFormatErrMsg == "invalid") {
            allvalid = false;
        }
        if (this.state.VehicleReachedParkingTimeFormatErrMsg == "invalid") {
            allvalid = false;
        }
        if (this.state.VehicleReachedParkingTimeErrMsg !== "") {
            allvalid = false;
        }
        if (this.state.VehicleReturnLocationTimeErrMsg !== "") {
            allvalid = false;
        }
        if (this.state.JettingFinishMeterReadingErrMsg !== "") {
            allvalid = false;
        }
        if (this.state.SuctionPumpFinishMeterReadingErrMsg != "") {
            allvalid = false;
        }
        if (this.state.ShiftID.value == "--Select Shift--") {
            this.setState({ ShiftErrMsg: 'required' });
            allvalid = false;
        }

        var lat = this.state.BookingFinishLatitude;
        var lng = this.state.BookingFinishLongitude;
        var addr = this.state.BookingFinishAddress;
        // if (lat===""||lng===""||addr==="") {
        //     allvalid=false;
        //     Swal.fire({ position: 'top-end', toast: true, title: 'Please share your current location', icon: "error", showConfirmButton: false, timer: 1500 })
        // }

        if ((lat == "" && lng == "" && addr == "") || (lat != "" && lng != "" && addr != "")) {
            if (lat == "") {
                this.setState({ BookingFinishLatitudeErrMsg: "" });
            }
            if (lng == "") {
                this.setState({ BookingFinishLongitudeErrMsg: "" });
            }
            if (addr == "") {
                this.setState({ BookingFinishAddressErrMsg: "" });
            }
        } else {
            allvalid = false;
            if (lat == "") {
                this.setState({ BookingFinishLatitudeErrMsg: "required" });
            }
            if (lng == "") {
                this.setState({ BookingFinishLongitudeErrMsg: "required" });
            }
            if (addr == "") {
                this.setState({ BookingFinishAddressErrMsg: "required" });
            }
        }

        if (this.form.isValid()) {
            if (allvalid == true) {
                var CompanyID = "";
                var ClientID = "";
                var UserID = "";
                var UserType = "";
                if (this.props.allCookies.MainCompanyID !== undefined) {
                    CompanyID = this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID !== undefined) {
                    ClientID = this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID !== undefined) {
                    UserID = this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType !== undefined) {
                    UserType = this.props.allCookies.PanelUserType
                }
                var IsUpdateSecondTime = "";
                var UpdateBy = "";
                if (this.state.IsFinishClick === true) {
                    IsUpdateSecondTime = "true";
                    UpdateBy = "Panel";
                }
                var DrainageLine150to300mmDiaMeter = "";
                var DrainageLine301to600mmDiaMeter = "";
                var DrainageLine601to900mmDiaMeter = "";
                var DrainageLine901to1200mmDiaMeter = "";
                var NoOfCleanMachineHole = "";
                if (this.state.DrainageLine150to300mmDiaMeter !== "null") {
                    DrainageLine150to300mmDiaMeter = this.state.DrainageLine150to300mmDiaMeter;
                    if (DrainageLine150to300mmDiaMeter == null) {
                        DrainageLine150to300mmDiaMeter = "";
                    }
                }
                if (this.state.DrainageLine301to600mmDiaMeter !== "null") {
                    DrainageLine301to600mmDiaMeter = this.state.DrainageLine301to600mmDiaMeter;
                    if (DrainageLine301to600mmDiaMeter == null) {
                        DrainageLine301to600mmDiaMeter = "";
                    }
                }
                if (this.state.DrainageLine601to900mmDiaMeter !== "null") {
                    DrainageLine601to900mmDiaMeter = this.state.DrainageLine601to900mmDiaMeter;
                    if (DrainageLine601to900mmDiaMeter == null) {
                        DrainageLine601to900mmDiaMeter = "";
                    }
                }
                if (this.state.DrainageLine901to1200mmDiaMeter !== "null") {
                    DrainageLine901to1200mmDiaMeter = this.state.DrainageLine901to1200mmDiaMeter;
                    if (DrainageLine901to1200mmDiaMeter == null) {
                        DrainageLine901to1200mmDiaMeter = "";
                    }
                }
                if (this.state.NoOfCleanMachineHole !== "null") {
                    NoOfCleanMachineHole = this.state.NoOfCleanMachineHole;
                    if (NoOfCleanMachineHole == null) {
                        NoOfCleanMachineHole = "";
                    }
                }

                var mttime = this.state.mobVehicleReachedParkingTime;
                var data = new FormData();
                data.append('BookingTaskDetailID', this.state.BookingTaskDetailID);
                data.append('BookingID', this.state.BookingID);
                data.append('VehicleReturnLocationTime', this.state.mobVehicleReturnLocationTime);
                data.append('VehicleReturnLocationOdometer', this.state.VehicleReturnLocationOdometer);
                data.append('VehicleReachedParkingTime', this.state.mobVehicleReachedParkingTime);
                data.append('VehicleReachedParkingOdometer', this.state.VehicleReachedParkingOdometer);
                data.append('JettingFinishMeterReading', this.state.JettingFinishMeterReading);
                data.append('SuctionPumpFinishMeterReading', this.state.SuctionPumpFinishMeterReading);
                data.append('TotalNoOfDranageLine', this.state.TotalNoOfDranageLine);
                data.append('DrainageLine150to300mmDiaMeter', DrainageLine150to300mmDiaMeter);
                data.append('DrainageLine301to600mmDiaMeter', DrainageLine301to600mmDiaMeter);
                data.append('DrainageLine601to900mmDiaMeter', DrainageLine601to900mmDiaMeter);
                data.append('DrainageLine901to1200mmDiaMeter', DrainageLine901to1200mmDiaMeter);
                data.append('JettingSystem', this.state.JettingSystem);
                data.append('SuctionSystem', this.state.SuctionSystem);
                data.append('SuctionHose5Diameter3MeterLong', this.state.SuctionHose5Diameter3MeterLong);
                data.append('JettingHose32Diameter120MeterLong', this.state.JettingHose32Diameter120MeterLong);
                data.append('JettingNozzleDeChockingNozzle', this.state.JettingNozzleDeChockingNozzle);
                data.append('DeSiltingNozzleFlatNozzle', this.state.DeSiltingNozzleFlatNozzle);
                data.append('BalanceNozzleBombNozzle', this.state.BalanceNozzleBombNozzle);

                data.append('BookingFinishLatitude', this.state.BookingFinishLatitude);
                data.append('BookingFinishLongitude', this.state.BookingFinishLongitude);
                data.append('BookingFinishAddress', this.state.BookingFinishAddress);

                data.append('NoOfCleanMachineHole', NoOfCleanMachineHole);
                data.append('TotalJettingUsageTime', this.state.TotalJettingUsageTime);
                data.append('TotalSuctionPumpUsageTime', this.state.TotalSuctionPumpUsageTime);
                data.append('Shift', this.state.ShiftID.value);
                data.append('BookingFinishRemarks', this.state.BookingFinishRemarks);
                data.append('CompanyID', CompanyID);
                data.append('ClientID', ClientID);
                data.append('BookingNo', this.state.BookingNo);
                data.append('EntryByUserType', UserType);
                data.append('EntryByUserID', UserID);
                data.append('IsUpdateSecondTime', IsUpdateSecondTime);

                data.append('LunchStartTime', LunchStartTime);
                data.append('LunchEndTime', LunchEndTime);

                // data.append('BookingEndDataEntryByType', "Panel");
                // data.append('BookingEndDataUpdateByType', UpdateBy);
                for (let i = 0; i < this.state.FinishTaskFile.length; i++) { data.append("TaskImages", this.state.FinishTaskFile[i]); }

                services.SubAdminFinishFormValue(data).then(response => {
                    if (response.data.status) {
                        this.setState({ BookingNo: "" }, () => { console.log("check", this.state.BookingNo) });
                        Swal.fire({
                            title: 'Successfully Finished', icon: "success", timer: 1500
                        });
                        this.cancelFinish();
                        //this.GetBooking();
                        //UserService.GetServiceData().then(response => { this.setState({ data: response.data.data }) }, error => { })
                    } else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }


                }, error => { })

            }
        }

    }
    OnCompleteTaskSubmit = (e) => {
        debugger;
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var UserType = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            UserType = this.props.allCookies.PanelUserType
        }
        var IsUpdateSecondTime = "";
        if (this.state.IsCompleteClick === true) {
            IsUpdateSecondTime = "true";
        }
        e.preventDefault();
        var allvalid = true;
        this.completeforms.validateFields();
        if (this.state.IsCompleteEditable == false) {
            if (this.state.LogSheetTaskFile.length == 0) {
                this.setState({ CompleteTaskErrImage: "required" });
                allvalid = false;
            }
        }

        var cnt = 0;
        if (this.state.MachineNotWorkFromTime !== "" && this.state.MachineNotWorkToTime !== "") {
            cnt = 0;
        }
        else if (this.state.MachineNotWorkFromTime == "" && this.state.MachineNotWorkToTime == "") {
            cnt = 0;
        }
        else {
            cnt = 1;
        }

        if (cnt == 1) {
            allvalid = false;
            Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: 'Please Enter Both Machine did not work From Time & To Time', showConfirmButton: false, timer: 3000 });
        } else {
            if (this.state.MachineNotWorkFromTimeErrMsg != "") {
                allvalid = false;
            }
            if (this.state.MachineNotWorkToTimeErrMsg != "") {
                allvalid = false;
            }
        }

        if (this.completeforms.isValid()) {
            if (allvalid == true) {
                var BookingRemarks = "";
                if (this.state.BookingCompleteRemarks !== "null") {
                    BookingRemarks = this.state.BookingCompleteRemarks;
                    if (BookingRemarks == null) {
                        BookingRemarks = "";
                    }
                }
                else {
                    BookingRemarks = "";
                }

                var machineFromTime = this.state.MachineNotWorkFromTime;

                if (machineFromTime != "") {
                    var machineNotFromTime = new Date(this.state.MachineNotWorkFromTime);
                    machineFromTime = moment(machineNotFromTime).format('HH:mm');
                }

                var machineToTime = this.state.MachineNotWorkToTime;

                if (machineToTime != "") {
                    var machineNotToTime = new Date(this.state.MachineNotWorkToTime);
                    machineToTime = moment(machineNotToTime).format('HH:mm');
                }

                // var machineTotalTime = this.state.MachineNotWorkTotalTime;

                // if (machineTotalTime != "") {
                //     var machineNotTotalTime = new Date(this.state.MachineNotWorkTotalTime);
                //     machineTotalTime = moment(machineNotTotalTime).format('HH:mm');
                // }

                var data = new FormData();
                data.append('BookingTaskDetailID', this.state.BookingTaskDetailID);
                data.append('BookingID', this.state.BookingID);
                data.append('BookingCompleteRemarks', BookingRemarks);
                data.append('BookingCompleteRemarksGujarati', (this.state.BookingCompleteRemarksGujarati == null ? '' : this.state.BookingCompleteRemarksGujarati == "null" ? '' : this.state.BookingCompleteRemarksGujarati));
                data.append('TenderLiquidityDamagesCondition', (this.state.TenderLiquidityDamagesCondition == null ? '' : this.state.TenderLiquidityDamagesCondition == "null" ? '' : this.state.TenderLiquidityDamagesCondition));

                data.append('LogSheetNo', (this.state.LogSheetNo == null ? '' : this.state.LogSheetNo == "null" ? '' : this.state.LogSheetNo));
                data.append('LocationDetail', (this.state.LocationDetail == null ? '' : this.state.LocationDetail == "null" ? '' : this.state.LocationDetail));
                data.append('EngineerName', (this.state.EngineerName == null ? '' : this.state.EngineerName == "null" ? '' : this.state.EngineerName));
                data.append('EngineerDesignation', (this.state.EngineerDesignation == null ? '' : this.state.EngineerDesignation == "null" ? '' : this.state.EngineerDesignation));
                data.append('MachineNotWorkFromTime', (this.state.MachineNotWorkFromTime == null ? '' : this.state.MachineNotWorkFromTime == "null" ? '' : machineFromTime));
                data.append('MachineNotWorkToTime', (this.state.MachineNotWorkToTime == null ? '' : this.state.MachineNotWorkToTime == "null" ? '' : machineToTime));
                // data.append('MachineNotWorkTotalTime', (this.state.MachineNotWorkTotalTime == null ? '' : this.state.MachineNotWorkTotalTime == "null" ? '' : machineTotalTime));
                data.append('ExtraordinaryDetails', (this.state.ExtraordinaryDetails == null ? '' : this.state.ExtraordinaryDetails == "null" ? '' : this.state.ExtraordinaryDetails));
                data.append('ExtraordinaryArea', (this.state.ExtraordinaryArea == null ? '' : this.state.ExtraordinaryArea == "null" ? '' : this.state.ExtraordinaryArea));
                data.append('ExtraordinaryMachineHoles', (this.state.ExtraordinaryMachineHoles == null ? '' : this.state.ExtraordinaryMachineHoles == "null" ? '' : this.state.ExtraordinaryMachineHoles));
                data.append('SludgeDisposalArea', (this.state.SludgeDisposalArea == null ? '' : this.state.SludgeDisposalArea == "null" ? '' : this.state.SludgeDisposalArea));

                // data.append('BookingCompleteStatus', 'Completed');
                // data.append('BookingCompleteUserID', UserID);

                data.append('CompanyID', CompanyID);
                data.append('ClientID', ClientID);
                data.append('BookingNo', this.state.BookingNo);
                data.append('EntryByUserType', UserType);
                data.append('EntryByUserID', UserID);
                data.append('IsUpdateSecondTime', IsUpdateSecondTime);

                if (this.state.LogSheetTaskFile.length > 0) {
                    for (let i = 0; i < this.state.LogSheetTaskFile.length; i++) { data.append("LogSheetImage", this.state.LogSheetTaskFile[i]); }
                }
                else {
                    data.append("OldLogSheetImageName", this.state.LogImage);
                }
                services.SubAdminCompleteFormValue(data).then(response => {
                    if (response.data.status) {
                        Swal.fire({
                            title: 'Successfully Completed', icon: "success", timer: 1500
                        });
                        this.cancelComplete();
                        //this.GetBooking();
                        //UserService.GetServiceData().then(response => { this.setState({ data: response.data.data }) }, error => { })
                    } else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                    // this.cancelComplete();

                }, error => { })

            }
        }

    }
    onCancelTaskSubmit = (e) => {
        e.preventDefault();
        var allvalid = true;
        var BookingID = this.state.BookingID;
        var BookingTaskDetailID = this.state.BookingTaskDetailID;
        var Remarks = this.state.TaskCancelRemarks;
        this.CancelTask.validateFields();
        if (this.CancelTask.isValid()) {
            var UserID = "";
            var UserType = "";

            if (this.props.allCookies.UserID !== undefined) {
                UserID = this.props.allCookies.UserID
            }
            var data = JSON.stringify({
                "BookingTaskDetailID": BookingTaskDetailID,
                "BookingID": BookingID,
                "BookingCancelByUserID": UserID,
                "Remarks": Remarks
            });
            var config = {
                method: 'POST',
                url: services.API_URL + "Booking/setCancelBooking",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                if (response.data.status) {
                    Swal.fire({
                        title: 'Task Cancelled', icon: "success", timer: 1500
                    });
                    this.cancelTaskCancelClick()
                } else {
                    Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                }
            }, error => { })
        }
    }
    OnCompleteReferredBackTaskSubmit = (e) => {
        // debugger;
        var UserID = "";
        //var BookingDate = "";
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID;
        }
        e.preventDefault();
        var allvalid = true;
        this.completeforms.validateFields();
        if (this.state.LogSheetTaskFile.length == 0) {
            this.setState({ CompleteTaskErrImage: "required" });
            allvalid = false;
        }

        if (this.completeforms.isValid()) {
            if (allvalid == true) {
                var BookingRemarks = "";
                if (this.state.BookingCompleteRemarks !== "null") {
                    BookingRemarks = this.state.BookingCompleteRemarks;
                    if (BookingRemarks == null) {
                        BookingRemarks = "";
                    }
                }
                else {
                    BookingRemarks = "";
                }
                var data = new FormData();
                data.append('BookingTaskDetailID', this.state.BookingTaskDetailID);
                data.append('BookingID', this.state.BookingID);
                data.append('BookingCompleteRemarks', (this.state.BookingCompleteRemarks == null ? '' : this.state.BookingCompleteRemarks == "null" ? '' : this.state.BookingCompleteRemarks));
                data.append('BookingCompleteRemarksGujarati', (this.state.BookingCompleteRemarksGujarati == null ? '' : this.state.BookingCompleteRemarksGujarati == "null" ? '' : this.state.BookingCompleteRemarksGujarati));
                data.append('TenderLiquidityDamagesCondition', (this.state.TenderLiquidityDamagesCondition == null ? '' : this.state.TenderLiquidityDamagesCondition == "null" ? '' : this.state.TenderLiquidityDamagesCondition));
                data.append('BookingCompleteStatus', 'Referred Back');
                data.append('BookingCompleteUserID', UserID);
                for (let i = 0; i < this.state.LogSheetTaskFile.length; i++) { data.append("LogSheetImage", this.state.LogSheetTaskFile[i]); }

                services.CompleteFormValue(data).then(response => {
                    if (response.data.status) {
                        Swal.fire({
                            title: 'Successfully Completed', icon: "success", timer: 1500
                        });
                        //this.GetBooking();
                        //UserService.GetServiceData().then(response => { this.setState({ data: response.data.data }) }, error => { })
                    } else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                    this.cancelComplete();

                }, error => { })

            }
        }

    }
    ValidateCancelBookingApproval = (e) => {
        this.setState({ BookingApprovalStatusErrMsg: 'required' })
    }
    ValidateBookingApproval = (e) => {
        this.setState({ BookingApprovalStatusErrMsg: '' });
    }
    onBookingApprovalChange = (e) => {
        if (e != null) {
            this.setState({ BookingApprovalStatus: e }, () => { this.ValidateBookingApproval() });
        }
        else {
            this.setState({ Priority: { 'label': '--Select Booking Status--', 'value': '--Select Booking Status--' } }, () => { this.ValidateCancelBookingApproval() });
        }
    }
    OnApprovedTaskSubmit = (e) => {
        // debugger;
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var UserType = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            UserType = this.props.allCookies.PanelUserType
        }
        e.preventDefault();
        var allvalid = true;
        this.form.validateFields();
        if (this.state.BookingApprovalStatus.value == "--Select Booking Status--") {
            this.setState({ BookingApprovalStatusErrMsg: 'required' });
            allvalid = false;
        }

        if (this.form.isValid()) {
            if (allvalid == true) {
                var IsUpdateSecondTime = "";
                if (this.state.IsApprovalClick === true) {
                    IsUpdateSecondTime = "true";
                }
                var data = JSON.stringify({
                    "BookingTaskDetailID": this.state.BookingTaskDetailID,
                    "BookingID": this.state.BookingID,
                    "BookingApprovalRemarks": this.state.BookingApprovalRemarks,
                    // "BookingApprovalUserID": UserID,
                    "IsUpdateSecondTime": IsUpdateSecondTime,
                    // "BookingApprovalStatus": this.state.BookingApprovalStatus.value,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "BookingNo": this.state.BookingNo,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
                var config = {
                    method: 'POST',
                    url: services.API_URL + "Booking/setApprovalBookingBySubAdmin",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    ////// debugger;
                    if (response.data.status) {
                        //// debugger;
                        Swal.fire({
                            title: response.data.message, icon: "success", timer: 1500
                        });
                        this.onCancelApproval();
                    }
                    else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                }, error => { })
            }
        }

    }
    OnReferredVisibleChange() {
        // debugger;
        this.setState({ IsReferredAssignDataVisible: true });
        var ZoneID = this.state.BookingZoneID;
        var DistrictID = this.state.BookingDistrictID;
        var MuncipalityID = this.state.BookingMunicipalityID;
        var ReferredDate = moment(this.state.RefferBackAssignDate).format('DD-MM-YYYY')

        this.GetVehicle(ReferredDate, ZoneID, DistrictID, MuncipalityID);
        this.GetDriver(ReferredDate, ZoneID, DistrictID, MuncipalityID);
        this.GetHelper(ReferredDate, ZoneID, DistrictID, MuncipalityID);
    }
    ValidateCancelReferredBackAssign() {
        this.setState({ vehicles: [] });
        this.setState({ drivers: [] });
        this.setState({ helpers: [] });
        this.setState({ IsReferredAssignDataVisible: false });

    }
    OnReferredBackChange = (e) => {
        // e.preventDefault();
        if (e != null) {
            this.setState({ RefferBackAssignDate: e }, () => { this.OnReferredVisibleChange() });
        }
        else {
            this.setState({ RefferBackAssignDate: "" }, () => { this.ValidateCancelReferredBackAssign() });

        }

    }
    onChangeVehicleReachedLocationOdometer = (e) => {
        // debugger;
        var VehicleLeaveParkingOdometer = this.state.VehicleLeaveParkingOdometer;
        var VehicleReachedLocationOdometer = e.target.value;
        this.setState({ VehicleReachedLocationOdometer: e.target.value }, () => { this.forms.validateFields(e.target) })
        if (parseInt(VehicleReachedLocationOdometer) <= parseInt(VehicleLeaveParkingOdometer)) {
            this.setState({ VehicleReachedLocationOdometerErrMsg: "greater" });
        }
        else {
            this.setState({ VehicleReachedLocationOdometerErrMsg: "" });
        }
    }
    onChangeVehicleReachedLocationTime = (e) => {
        debugger;
        // var VehicleLeaveParkingTime=moment(this.state.VehicleLeaveParkingTime, 'HH:mm').toDate();
        // var VehicleReachedLocationTime=moment(e.target.value, 'HH:mm').toDate();
        // this.setState({ VehicleReachedLocationTime: e.target.value }, () => { this.forms.validateFields(e.target) })


        // if (VehicleReachedLocationTime<=VehicleLeaveParkingTime) {
        //     this.setState({ VehicleReachedLocationTimeErrMsg: "greater" });
        // }
        // else {
        //     this.setState({ VehicleReachedLocationTimeErrMsg: "" });
        // }



        // var VehicleLeaveParkingTime=moment(this.state.mobVehicleLeaveParkingTime).format('DD-MM-YYYY HH:mm:ss')
        //var VehicleLeaveParkingTime=new Date(moment(this.state.VehicleLeaveParkingTime).format('DD-MM-YYYY HH:mm:ss'));




        // var VehicleLeaveParkingTime = moment(this.state.mobVehicleLeaveParkingTime, 'DD-MM-yyyy HH:mm:ss');
        var VehicleLeaveParkingTime = moment(this.state.mobVehicleLeaveParkingTime, 'DD-MM-yyyy HH:mm');
        this.setState({ FullVehicleReachedLocationTime: e.target.value }, () => { this.forms.validateFields(e.target) })
        // var VehicleReachedLocationTime=new Date(e.target.value);
        // var VehicleReachedLocationTime = isMatch(e.target.value, 'dd-MM-yyyy HH:mm:ss');
        var VehicleReachedLocationTime = isMatch(e.target.value, 'dd-MM-yyyy HH:mm');
        if (VehicleReachedLocationTime == true) {
            // var VehicleReachedLocationTime=new Date(moment(e.target.value).format('dd-MM-yyyy HH:mm'));

            // var VehicleReachedLocationTime = moment(e.target.value, 'DD-MM-yyyy HH:mm:ss');
            var VehicleReachedLocationTime = moment(e.target.value, 'DD-MM-yyyy HH:mm');
            //var VehicleLeaveParkingTime=moment(e.target.value, 'DD-MM-YYYY HH:mm').toDate();

            if (VehicleReachedLocationTime > VehicleLeaveParkingTime) {
                this.setState({ VehicleReachedLocationTimeErrMsg: "" });
            }
            else {
                this.setState({ VehicleReachedLocationTimeErrMsg: "greater" });
            }
            this.setState({ VehicleReachedParkingTimeFormatErrMsg: "" });
        }
        else {
            this.setState({ VehicleReachedParkingTimeFormatErrMsg: "invalid" });
        }


        // this.setState({ FullVehicleReachedLocationTime: e.$d });
        // var VehicleLeaveParkingTime=this.state.mobVehicleLeaveParkingTime;
        // var VehicleReachedLocationTime=e.$d;
        // if (VehicleReachedLocationTime<=VehicleLeaveParkingTime) {
        //     this.setState({ VehicleReachedLocationTimeErrMsg: "greater" });
        // }
        // else {
        //     this.setState({ VehicleReachedLocationTimeErrMsg: "" });
        // }
    }
    onChangeVehicleLeaveParkingTime = (e) => {
        debugger;
        // var VehicleReachedLocationTime=moment(this.state.VehicleLeaveParkingTime, 'HH:mm').toDate();
        // var VehicleLeaveParkingTime=moment(e.target.value, 'HH:mm').toDate();
        // this.setState({ VehicleLeaveParkingTime: e.target.value }, () => { this.forms.validateFields(e.target) })
        // var temp=setValue(e);
        // this.setState({ VehicleLeaveParkingTime: temp });
        // this.setState({ VehicleLeaveParkingTime: e.target.value });

        // if (VehicleReachedLocationTime<=VehicleLeaveParkingTime) {
        //     this.setState({ VehicleReachedLocationTimeErrMsg: "greater" });
        // }
        // else {
        //     this.setState({ VehicleReachedLocationTimeErrMsg: "" });
        // }

        this.setState({ mobVehicleLeaveParkingTime: e.target.value }, () => { this.forms.validateFields(e.target) });


        // var VehicleLeaveParkingTime=moment(e.target.value, 'dd-MM-yyyy HH:mm').toDate();
        // var VehicleLeaveParkingTime = isMatch(e.target.value, 'dd-MM-yyyy HH:mm:ss');
        var VehicleLeaveParkingTime = isMatch(e.target.value, 'dd-MM-yyyy HH:mm');
        if (VehicleLeaveParkingTime == true) {

            var VehicleReachedLocationTime = moment(this.state.FullVehicleReachedLocationTime, 'DD-MM-YYYY HH:mm');
            var VehicleLeaveParkingTime = moment(e.target.value, 'DD-MM-YYYY HH:mm');
            if (VehicleReachedLocationTime < VehicleLeaveParkingTime) {
                this.setState({ VehicleReachedLocationTimeErrMsg: "greater" });
            }
            else {
                this.setState({ VehicleReachedLocationTimeErrMsg: "" });
            }
            this.setState({ VehicleLeaveParkingTimeFormatErrMsg: "" });
        }
        else {

            this.setState({ VehicleLeaveParkingTimeFormatErrMsg: "invalid" });
        }

        // var temp=setValue(e);
        // this.setState({ VehicleLeaveParkingTime: temp });
        // this.setState({ VehicleLeaveParkingTime: e.target.value });

        // if (VehicleReachedLocationTime<=VehicleLeaveParkingTime) {
        //     this.setState({ VehicleReachedLocationTimeErrMsg: "greater" });
        // }
        // else {
        //     this.setState({ VehicleReachedLocationTimeErrMsg: "" });
        // }


        //----//

        // this.setState({ mobVehicleLeaveParkingTime: e.$d });
        // var VehicleReachedLocationTime=this.state.FullVehicleReachedLocationTime;
        // var VehicleLeaveParkingTime=e.$d;
        // if (VehicleReachedLocationTime<=VehicleLeaveParkingTime) {
        //     this.setState({ VehicleReachedLocationTimeErrMsg: "greater" });
        // }
        // else {
        //     this.setState({ VehicleReachedLocationTimeErrMsg: "" });
        // }
    }
    onChangeVehicleReachedParkingOdometer = (e) => {
        // debugger;
        var VehicleReturnLocationOdometer = this.state.VehicleReturnLocationOdometer;
        var VehicleReachedParkingOdometer = e.target.value;
        this.setState({ VehicleReachedParkingOdometer: e.target.value }, () => { this.finishform.validateFields(e.target) })
        if (parseInt(VehicleReachedParkingOdometer) <= parseInt(VehicleReturnLocationOdometer)) {
            this.setState({ VehicleReachedParkingOdometerErrMsg: "greater" });
        }
        else {
            this.setState({ VehicleReachedParkingOdometerErrMsg: "" });
        }
    }
    onChangeVehicleReturnLocationOdometer = (e) => {
        // debugger;
        var VehicleReachedParkingOdometer = this.state.VehicleReachedParkingOdometer;
        var VehicleReturnLocationOdometer = e.target.value;
        this.setState({ VehicleReturnLocationOdometer: e.target.value }, () => { this.finishform.validateFields(e.target) })
        if (parseInt(VehicleReachedParkingOdometer) <= parseInt(VehicleReturnLocationOdometer)) {
            this.setState({ VehicleReachedParkingOdometerErrMsg: "greater" });
        }
        else {
            this.setState({ VehicleReachedParkingOdometerErrMsg: "" });
        }

        if (this.props.allCookies.VehicleReachedLocationOdometer !== undefined) {
            // var VehicleReachedLocationTime=moment(this.props.allCookies.VehicleReachedLocationTime,'hh:mm').toDate()
            var VehicleReachedLocationOdometer = this.props.allCookies.VehicleReachedLocationOdometer
            if (parseInt(VehicleReachedLocationOdometer) >= VehicleReturnLocationOdometer) {
                this.setState({ VehicleReachedLocationOdometerErrMsg: "greater" });
            }
            else {
                this.setState({ VehicleReachedLocationOdometerErrMsg: "" });
            }
        }
    }
    onChangeCompleteVehicleReachedParkingOdometer = (e) => {
        // debugger;
        var VehicleReturnLocationOdometer = this.state.VehicleReturnLocationOdometer;
        var VehicleReachedParkingOdometer = e.target.value;
        this.setState({ VehicleReachedParkingOdometer: e.target.value }, () => { this.completeform.validateFields(e.target) })
        if (parseInt(VehicleReachedParkingOdometer) <= parseInt(VehicleReturnLocationOdometer)) {
            this.setState({ VehicleReachedParkingOdometerErrMsg: "greater" });
        }
        else {
            this.setState({ VehicleReachedParkingOdometerErrMsg: "" });
        }
    }

    // onChangeVehicleReachedParkingTime=(e) => {
    //     // debugger;
    //     var VehicleReturnLocationTime=this.state.mobVehicleReturnLocationTime;
    //     var VehicleReachedParkingTime=e.$d;
    //     this.setState({ mobVehicleReachedParkingTime: e.$d }, () => { this.finishform.validateFields(e.target) })

    //     // var tempVehicleReturnLocationTime=moment(VehicleReturnLocationTime, 'HH:mm')
    //     // var tempVehicleReachedParkingTime=moment(VehicleReachedParkingTime, 'HH:mm')

    //     var tempVehicleReturnLocationTime=VehicleReturnLocationTime;
    //     var tempVehicleReachedParkingTime=VehicleReachedParkingTime;

    //     if (tempVehicleReachedParkingTime<=tempVehicleReturnLocationTime) {
    //         this.setState({ VehicleReachedParkingTimeErrMsg: "greater" });
    //     }
    //     else {
    //         this.setState({ VehicleReachedParkingTimeErrMsg: "" });
    //     }
    //     // if (parseInt(VehicleReachedParkingOdometer)<=parseInt(VehicleReturnLocationOdometer)) {
    //     //     this.setState({ VehicleReachedParkingOdometerErrMsg: "greater" });
    //     // }
    //     // else {
    //     //     this.setState({ VehicleReachedParkingOdometerErrMsg: "" });
    //     // }
    // }

    onChangeVehicleReachedParkingTime = (e) => {
        debugger;
        var VehicleReturnLocationTime = this.state.mobVehicleReturnLocationTime;
        this.setState({ mobVehicleReachedParkingTime: e.target.value }, () => { this.finishform.validateFields(e.target) })

        // var VehicleReachedParkingTime = isMatch(e.target.value, 'dd-MM-yyyy HH:mm:ss');
        var VehicleReachedParkingTime = isMatch(e.target.value, 'dd-MM-yyyy HH:mm');
        if (VehicleReachedParkingTime == true) {
            var VehicleReachedParkingTime = e.target.value;


            // var tempVehicleReturnLocationTime=moment(VehicleReturnLocationTime, 'HH:mm')
            // var tempVehicleReachedParkingTime=moment(VehicleReachedParkingTime, 'HH:mm')

            // var tempVehicleReturnLocationTime = moment(VehicleReturnLocationTime, 'DD-MM-yyyy HH:mm:ss');
            // var tempVehicleReachedParkingTime = moment(VehicleReachedParkingTime, 'DD-MM-yyyy HH:mm:ss');

            var tempVehicleReturnLocationTime = moment(VehicleReturnLocationTime, 'DD-MM-yyyy HH:mm');
            var tempVehicleReachedParkingTime = moment(VehicleReachedParkingTime, 'DD-MM-yyyy HH:mm');

            if (tempVehicleReachedParkingTime <= tempVehicleReturnLocationTime) {
                this.setState({ VehicleReachedParkingTimeErrMsg: "greater" });
            }
            else {
                this.setState({ VehicleReachedParkingTimeErrMsg: "" });
            }
            this.setState({ VehicleReachedParkingTimeFormatErrMsg: "" });
        }
        else {
            this.setState({ VehicleReachedParkingTimeFormatErrMsg: "invalid" });
        }

        // if (parseInt(VehicleReachedParkingOdometer)<=parseInt(VehicleReturnLocationOdometer)) {
        //     this.setState({ VehicleReachedParkingOdometerErrMsg: "greater" });
        // }
        // else {
        //     this.setState({ VehicleReachedParkingOdometerErrMsg: "" });
        // }
    }


    // onChangeVehicleReturnLocationTime=(e) => {
    //     debugger;
    //     const { cookies }=this.props;
    //     var VehicleReachedParkingTime=this.state.mobVehicleReachedParkingTime;
    //     var VehicleReturnLocationTime=e.$d;
    //     this.setState({ mobVehicleReturnLocationTime: e.$d }, () => { this.finishform.validateFields(e.target) })

    //     var tempVehicleReturnLocationTime=VehicleReturnLocationTime;
    //     var tempVehicleReachedParkingTime=VehicleReachedParkingTime;

    //     // var tempVehicleReturnLocationTime=moment(VehicleReturnLocationTime, 'HH:mm')
    //     // var tempVehicleReachedParkingTime=moment(VehicleReachedParkingTime, 'HH:mm')

    //     if (tempVehicleReachedParkingTime<=tempVehicleReturnLocationTime) {
    //         this.setState({ VehicleReachedParkingTimeErrMsg: "greater" });
    //     }
    //     else {
    //         this.setState({ VehicleReachedParkingTimeErrMsg: "" });
    //     }
    //     if (this.props.allCookies.VehicleReachedLocationDateTime!==undefined) {
    //         var VehicleReachedLocationTime=new Date(this.props.allCookies.VehicleReachedLocationDateTime);

    //         if (VehicleReachedLocationTime>=tempVehicleReturnLocationTime) {
    //             this.setState({ VehicleReturnLocationTimeErrMsg: "greater" });
    //         }
    //         else {
    //             this.setState({ VehicleReturnLocationTimeErrMsg: "" });
    //         }
    //     }

    //     // if (parseInt(VehicleReachedParkingOdometer)<=parseInt(VehicleReturnLocationOdometer)) {
    //     //     this.setState({ VehicleReachedParkingOdometerErrMsg: "greater" });
    //     // }
    //     // else {
    //     //     this.setState({ VehicleReachedParkingOdometerErrMsg: "" });
    //     // }
    // }

    onChangeVehicleReturnLocationTime = (e) => {
        debugger;
        const { cookies } = this.props;
        var VehicleReachedParkingTime = this.state.mobVehicleReachedParkingTime;
        this.setState({ mobVehicleReturnLocationTime: e.target.value }, () => { this.finishform.validateFields(e.target) })

        // var VehicleReturnLocationTime = isMatch(e.target.value, 'dd-MM-yyyy HH:mm:ss');
        var VehicleReturnLocationTime = isMatch(e.target.value, 'dd-MM-yyyy HH:mm');
        if (VehicleReturnLocationTime == true) {
            var VehicleReturnLocationTime = e.target.value;


            // var tempVehicleReturnLocationTime = moment(VehicleReturnLocationTime, 'DD-MM-yyyy HH:mm:ss');
            // var tempVehicleReachedParkingTime = moment(VehicleReachedParkingTime, 'DD-MM-yyyy HH:mm:ss');

            var tempVehicleReturnLocationTime = moment(VehicleReturnLocationTime, 'DD-MM-yyyy HH:mm');
            var tempVehicleReachedParkingTime = moment(VehicleReachedParkingTime, 'DD-MM-yyyy HH:mm');

            // var tempVehicleReturnLocationTime=moment(VehicleReturnLocationTime, 'HH:mm')
            // var tempVehicleReachedParkingTime=moment(VehicleReachedParkingTime, 'HH:mm')

            if (tempVehicleReachedParkingTime <= tempVehicleReturnLocationTime) {
                this.setState({ VehicleReachedParkingTimeErrMsg: "greater" });
            }
            else {
                this.setState({ VehicleReachedParkingTimeErrMsg: "" });
            }
            // var VehicleReachedLocationTime = moment(this.state.DataVehicleReachedLocationDateTime, 'DD-MM-yyyy HH:mm:ss');
            var VehicleReachedLocationTime = moment(this.state.DataVehicleReachedLocationDateTime, 'DD-MM-yyyy HH:mm');

            if (VehicleReachedLocationTime >= tempVehicleReturnLocationTime) {
                this.setState({ VehicleReturnLocationTimeErrMsg: "greater" });
            }
            else {
                this.setState({ VehicleReturnLocationTimeErrMsg: "" });
            }
            this.setState({ VehicleReturnLocationTimeFormatErrMsg: "" });
        }
        else {
            this.setState({ VehicleReturnLocationTimeFormatErrMsg: "invalid" });
        }


        // if (parseInt(VehicleReachedParkingOdometer)<=parseInt(VehicleReturnLocationOdometer)) {
        //     this.setState({ VehicleReachedParkingOdometerErrMsg: "greater" });
        // }
        // else {
        //     this.setState({ VehicleReachedParkingOdometerErrMsg: "" });
        // }
    }

    onChangeCompleteVehicleReturnLocationTime = (e) => {
        debugger;
        const { cookies } = this.props;
        var VehicleReachedParkingTime = this.state.mobVehicleReachedParkingTime;
        var VehicleReturnLocationTime = e.$d;
        this.setState({ mobVehicleReturnLocationTime: e.$d })

        // var tempVehicleReturnLocationTime = moment(VehicleReturnLocationTime, 'DD-MM-yyyy HH:mm:ss');
        // var tempVehicleReachedParkingTime = moment(VehicleReachedParkingTime, 'DD-MM-yyyy HH:mm:ss');

        var tempVehicleReturnLocationTime = moment(VehicleReturnLocationTime, 'DD-MM-yyyy HH:mm');
        var tempVehicleReachedParkingTime = moment(VehicleReachedParkingTime, 'DD-MM-yyyy HH:mm');

        // var tempVehicleReturnLocationTime=moment(VehicleReturnLocationTime, 'HH:mm')
        // var tempVehicleReachedParkingTime=moment(VehicleReachedParkingTime, 'HH:mm')

        if (tempVehicleReachedParkingTime <= tempVehicleReturnLocationTime) {
            this.setState({ VehicleReachedParkingTimeErrMsg: "greater" });
        }
        else {
            this.setState({ VehicleReachedParkingTimeErrMsg: "" });
        }
        if (this.props.allCookies.VehicleReachedLocationDateTime !== undefined) {
            var VehicleReachedLocationTime = new Date(this.props.allCookies.VehicleReachedLocationDateTime);

            if (VehicleReachedLocationTime >= tempVehicleReturnLocationTime) {
                this.setState({ VehicleReturnLocationTimeErrMsg: "greater" });
            }
            else {
                this.setState({ VehicleReturnLocationTimeErrMsg: "" });
            }
        }

        // if (parseInt(VehicleReachedParkingOdometer)<=parseInt(VehicleReturnLocationOdometer)) {
        //     this.setState({ VehicleReachedParkingOdometerErrMsg: "greater" });
        // }
        // else {
        //     this.setState({ VehicleReachedParkingOdometerErrMsg: "" });
        // }
    }
    onChangeLunchStartTime = (e) => {
        debugger;
        if (e != null) {
            // this.setState({ LunchStartTime: e.$H+':'+e.$m });
            this.setState({ LunchStartTime: e.$d })
            if (e.$d == "Invalid Date") {
                this.setState({ LunchStartTime: '' })
            } else {
                var chkLunchStartTime, chkLunchEndTime;
                chkLunchStartTime = new Date(e.$d);
                chkLunchEndTime = new Date(this.state.LunchEndTime);
                if (chkLunchStartTime != "" && chkLunchEndTime != "") {
                    if (chkLunchEndTime < chkLunchStartTime) {
                        this.setState({ LunchEndTimeErrMsg: "greater" });
                    } else {
                        this.setState({ LunchEndTimeErrMsg: "" });
                    }
                }
            }

            if (chkLunchStartTime != "") {
                this.setState({ LunchStartTimeErrMsg: "" });
            }
            else {
                this.setState({ LunchStartTimeErrMsg: "required" });
            }
        } else {
            this.setState({ LunchStartTime: '' })
        }
    }
    onChangeLunchEndTime = (e) => {
        debugger;
        // this.setState({ LunchStartTime: e.$H+':'+e.$m });
        if (e != null) {
            var chkLunchStartTime, chkLunchEndTime;
            chkLunchStartTime = this.state.LunchStartTime;

            if (chkLunchStartTime != "") {
                this.setState({ LunchStartTimeErrMsg: "" });
            }
            else {
                this.setState({ LunchStartTimeErrMsg: "required" });
            }

            this.setState({ LunchEndTime: e.$d })
            if (e.$d == "Invalid Date") {
                this.setState({ LunchEndTime: '' })
            } else {
                chkLunchStartTime = new Date(chkLunchStartTime);
                chkLunchEndTime = e.$d;
                if (chkLunchStartTime != "" && chkLunchEndTime != "") {
                    if (chkLunchEndTime < chkLunchStartTime) {
                        this.setState({ LunchEndTimeErrMsg: "greater" });
                    } else {
                        this.setState({ LunchEndTimeErrMsg: "" });
                    }
                }
            }
        } else {
            this.setState({ LunchEndTime: '' })
        }
    }
    onChangeCompleteVehicleReachedParkingTime = (e) => {
        debugger;
        var VehicleReturnLocationTime = this.state.mobVehicleReturnLocationTime;
        var VehicleReachedParkingTime = e.$d;
        this.setState({ mobVehicleReachedParkingTime: e.$d })

        // var tempVehicleReturnLocationTime = moment(VehicleReturnLocationTime, 'DD-MM-yyyy HH:mm:ss');
        // var tempVehicleReachedParkingTime = moment(VehicleReachedParkingTime, 'DD-MM-yyyy HH:mm:ss');

        var tempVehicleReturnLocationTime = moment(VehicleReturnLocationTime, 'DD-MM-yyyy HH:mm');
        var tempVehicleReachedParkingTime = moment(VehicleReachedParkingTime, 'DD-MM-yyyy HH:mm');

        if (tempVehicleReachedParkingTime <= tempVehicleReturnLocationTime) {
            this.setState({ VehicleReachedParkingTimeErrMsg: "greater" });
        }
        else {
            this.setState({ VehicleReachedParkingTimeErrMsg: "" });
        }
        // if (parseInt(VehicleReachedParkingOdometer)<=parseInt(VehicleReturnLocationOdometer)) {
        //     this.setState({ VehicleReachedParkingOdometerErrMsg: "greater" });
        // }
        // else {
        //     this.setState({ VehicleReachedParkingOdometerErrMsg: "" });
        // }
    }
    onChangeJettingStartMeterReading = (e) => {
        debugger;
        var JettingStartMeterReading = e.target.value;
        this.setState({ JettingStartMeterReading: e.target.value }, () => { this.forms.validateFields(e.target) })

        var JettingFinishMeterReading = this.state.JettingFinishMeterReading;
        if (JettingFinishMeterReading !== "" && JettingFinishMeterReading !== null) {
            //var JettingStartMeterReading=this.props.allCookies.JettingStartMeterReading

            var JettingFinish = JettingFinishMeterReading.split(':');
            var JettingStart = JettingStartMeterReading.split(':');

            var Hours = JettingFinish[0] - JettingStart[0];
            var Time = JettingFinish[1] - JettingStart[1];

            if (Time < 0) {
                Time = (parseInt(60) + parseInt(JettingFinish[1])) - JettingStart[1];
                Hours = JettingFinish[0] - JettingStart[0] - 1;
            }



            if (Time < 0 || Hours < 0) {
                this.setState({ JettingStartMeterReadingErrMsg: "greater" });
            }
            else if (Time == 0 && Hours == 0) {
                this.setState({ JettingStartMeterReadingErrMsg: "greater" });
            }
            else {

                this.setState({ TotalJettingUsageTime: Hours + ":" + Time });
                this.setState({ JettingStartMeterReadingErrMsg: "" });
            }

            // if (parseInt(JettingFinishMeterReading)>=JettingStartMeterReading) {

        }
    }
    onChangeJettingFinishMeterReading = (e) => {
        debugger;
        //var JettingFinishMeterReading=this.state.JettingFinishMeterReading;
        var JettingFinishMeterReading = e.target.value;
        this.setState({ JettingFinishMeterReading: e.target.value }, () => { this.finishform.validateFields(e.target) })


        if (this.state.JettingStartMeterReading !== undefined) {
            // var VehicleReachedLocationTime=moment(this.props.allCookies.VehicleReachedLocationTime,'hh:mm').toDate()
            var JettingStartMeterReading = this.state.JettingStartMeterReading

            var JettingFinish = JettingFinishMeterReading.split(':');
            var JettingStart = JettingStartMeterReading.split(':');

            var Hours = JettingFinish[0] - JettingStart[0];
            var Time = JettingFinish[1] - JettingStart[1];

            if (Time < 0) {
                Time = (parseInt(60) + parseInt(JettingFinish[1])) - JettingStart[1];
                Hours = JettingFinish[0] - JettingStart[0] - 1;
            }



            if (Time < 0 || Hours < 0) {
                this.setState({ JettingFinishMeterReadingErrMsg: "greater" });
            }
            else if (Time == 0 && Hours == 0) {
                this.setState({ JettingFinishMeterReadingErrMsg: "greater" });
            }
            else {
                this.setState({ TotalJettingUsageTime: Hours + ":" + Time });
                this.setState({ JettingFinishMeterReadingErrMsg: "" });
            }

            // if (parseInt(JettingFinishMeterReading)>=JettingStartMeterReading) {

        }
    }
    onChangeSuctionPumpStartMeterReading = (e) => {
        debugger;
        var SuctionPumpStartMeterReading = e.target.value;
        this.setState({ SuctionPumpStartMeterReading: e.target.value }, () => { this.forms.validateFields(e.target) })

        var SuctionPumpFinishMeterReading = this.state.SuctionPumpFinishMeterReading;
        if (SuctionPumpFinishMeterReading !== "" && SuctionPumpFinishMeterReading !== null) {
            // var VehicleReachedLocationTime=moment(this.props.allCookies.VehicleReachedLocationTime,'hh:mm').toDate()
            //  var SuctionStartMeterReading=this.props.allCookies.SuctionPumpStartMeterReading

            var SuctionFinish = SuctionPumpFinishMeterReading.split(':');
            var SuctionStart = SuctionPumpStartMeterReading.split(':');

            var Hours = SuctionFinish[0] - SuctionStart[0];
            var Time = SuctionFinish[1] - SuctionStart[1];

            if (Time < 0) {
                Time = (parseInt(60) + parseInt(SuctionFinish[1])) - SuctionStart[1];
                Hours = SuctionFinish[0] - SuctionStart[0] - 1;
            }



            if (Time < 0 || Hours < 0) {
                this.setState({ SuctionPumpStartMeterReadingErrMsg: "greater" });
            }
            else if (Time == 0 && Hours == 0) {
                this.setState({ SuctionPumpStartMeterReadingErrMsg: "greater" });
            }
            else {

                this.setState({ TotalSuctionPumpUsageTime: Hours + ":" + Time });
                this.setState({ SuctionPumpStartMeterReadingErrMsg: "" });
            }

            // if (parseInt(JettingFinishMeterReading)>=JettingStartMeterReading) {

        }
    }
    onChangeSuctionFinishMeterReading = (e) => {
        debugger;
        //var JettingFinishMeterReading=this.state.JettingFinishMeterReading;
        var SuctionPumpFinishMeterReading = e.target.value;
        this.setState({ SuctionPumpFinishMeterReading: e.target.value }, () => { this.finishform.validateFields(e.target) })


        if (this.state.SuctionPumpStartMeterReading !== undefined) {
            // var VehicleReachedLocationTime=moment(this.props.allCookies.VehicleReachedLocationTime,'hh:mm').toDate()
            var SuctionStartMeterReading = this.state.SuctionPumpStartMeterReading

            var SuctionFinish = SuctionPumpFinishMeterReading.split(':');
            var SuctionStart = SuctionStartMeterReading.split(':');

            var Hours = SuctionFinish[0] - SuctionStart[0];
            var Time = SuctionFinish[1] - SuctionStart[1];

            if (Time < 0) {
                Time = (parseInt(60) + parseInt(SuctionFinish[1])) - SuctionStart[1];
                Hours = SuctionFinish[0] - SuctionStart[0] - 1;
            }



            if (Time < 0 || Hours < 0) {
                this.setState({ SuctionPumpFinishMeterReadingErrMsg: "greater" });
            }
            else if (Time == 0 && Hours == 0) {
                this.setState({ SuctionPumpFinishMeterReadingErrMsg: "greater" });
            }
            else {
                this.setState({ TotalSuctionPumpUsageTime: Hours + ":" + Time });
                this.setState({ SuctionPumpFinishMeterReadingErrMsg: "" });
            }

            // if (parseInt(JettingFinishMeterReading)>=JettingStartMeterReading) {

        }
    }
    onChangeFinishJettingFinishMeterReading = (e) => {
        debugger;
        //var JettingFinishMeterReading=this.state.JettingFinishMeterReading;
        var JettingFinishMeterReading = e.target.value;
        this.setState({ JettingFinishMeterReading: e.target.value }, () => { this.completeform.validateFields(e.target) })


        if (this.state.JettingStartMeterReading !== undefined) {
            // var VehicleReachedLocationTime=moment(this.props.allCookies.VehicleReachedLocationTime,'hh:mm').toDate()
            var JettingStartMeterReading = this.state.JettingStartMeterReading

            var JettingFinish = JettingFinishMeterReading.split(':');
            var JettingStart = JettingStartMeterReading.split(':');

            var Hours = JettingFinish[0] - JettingStart[0];
            var Time = JettingFinish[1] - JettingStart[1];

            if (Time < 0) {
                Time = (parseInt(60) + parseInt(JettingFinish[1])) - JettingStart[1];
                Hours = JettingFinish[0] - JettingStart[0] - 1;
            }



            if (Time < 0 || Hours < 0) {
                this.setState({ JettingFinishMeterReadingErrMsg: "greater" });
            }
            else if (Time == 0 && Hours == 0) {
                this.setState({ JettingFinishMeterReadingErrMsg: "greater" });
            }
            else {
                this.setState({ TotalJettingUsageTime: Hours + ":" + Time });
                this.setState({ JettingFinishMeterReadingErrMsg: "" });
            }

            // if (parseInt(JettingFinishMeterReading)>=JettingStartMeterReading) {

        }
    }
    onChangeFinishSuctionFinishMeterReading = (e) => {
        //var JettingFinishMeterReading=this.state.JettingFinishMeterReading;
        var SuctionPumpFinishMeterReading = e.target.value;
        this.setState({ SuctionPumpFinishMeterReading: e.target.value }, () => { this.completeform.validateFields(e.target) })


        if (this.state.SuctionPumpStartMeterReading !== undefined) {
            // var VehicleReachedLocationTime=moment(this.props.allCookies.VehicleReachedLocationTime,'hh:mm').toDate()
            var SuctionStartMeterReading = this.state.SuctionPumpStartMeterReading

            var SuctionFinish = SuctionPumpFinishMeterReading.split(':');
            var SuctionStart = SuctionStartMeterReading.split(':');

            var Hours = SuctionFinish[0] - SuctionStart[0];
            var Time = SuctionFinish[1] - SuctionStart[1];

            if (Time < 0) {
                Time = (parseInt(60) + parseInt(SuctionFinish[1])) - SuctionStart[1];
                Hours = SuctionFinish[0] - SuctionStart[0] - 1;
            }



            if (Time < 0 || Hours < 0) {
                this.setState({ SuctionPumpFinishMeterReadingErrMsg: "greater" });
            }
            else if (Time == 0 && Hours == 0) {
                this.setState({ SuctionPumpFinishMeterReadingErrMsg: "greater" });
            }
            else {
                this.setState({ TotalSuctionPumpUsageTime: Hours + ":" + Time });
                this.setState({ SuctionPumpFinishMeterReadingErrMsg: "" });
            }

            // if (parseInt(JettingFinishMeterReading)>=JettingStartMeterReading) {

        }
    }
    handleReferredBackAssign = (e) => {
        // debugger;
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var UserType = "";
        let helperdatas = [];
        e.preventDefault();
        var allvalid = true;
        if (this.state.VehicleID.value == "--Select Vehicle--") {
            this.setState({ VehicleErrMsg: 'required' });
            allvalid = false;
        }

        if (this.state.DriverID.value == "--Select Driver--") {
            this.setState({ DriverErrMsg: 'required' });
            allvalid = false;
        }

        let helpers = this.state.helpers;
        var allvalueselectted = false;
        helpers.forEach(helpers => {

            // if (helpers.id===e.target.value) {
            //    // helpers.isChecked=e.target.checked;
            //     helperdatas.push(helpers.id)
            // }
            if (helpers.isChecked == true) {
                allvalueselectted = true;
                helperdatas.push({ "UserID": helpers.id })
            }

            // if (allvalueselectted==false) {
            //     this.setState({ HelperErrMsg: 'required' });
            //     allvalid=false;
            // }
            // this.setState({helpers:true});
            //this.setState({isChecked:true});
        });
        if (helperdatas.length === 0) {
            this.setState({ HelperErrMsg: 'required' });
            allvalid = false;
        }
        if (allvalid == true) {
            if (this.props.allCookies.MainCompanyID !== undefined) {
                CompanyID = this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID !== undefined) {
                ClientID = this.props.allCookies.MainClientID
            }
            if (this.props.allCookies.UserID !== undefined) {
                UserID = this.props.allCookies.UserID
            }
            if (this.props.allCookies.PanelUserType !== undefined) {
                UserType = this.props.allCookies.PanelUserType
            }

            if (CompanyID != "" && ClientID != "") {
                var IsUpdateSecondTime = "";
                if (this.state.IsAssignedClick === true) {
                    IsUpdateSecondTime = "true";
                }
                var data = "";
                data = JSON.stringify({
                    "BookingID": this.state.BookingID,
                    "AssignSupervisorID": UserID,
                    "VehicleID": this.state.VehicleID.value,
                    "DriverID": this.state.DriverID.value,
                    "RefferBackAssignDate": moment(this.state.RefferBackAssignDate).format('DD-MM-YYYY'),
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID,
                    "HelperData": helperdatas,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "IsUpdateSecondTime": IsUpdateSecondTime,
                    "BookingNo": this.state.BookingNo
                });
                var config = {
                    method: 'POST',
                    url: services.API_URL + "Booking/setAssignBooking",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    ////// debugger;
                    if (response.data.status) {
                        //// debugger;
                        Swal.fire({
                            title: 'Successfully Assign', icon: "success", timer: 1500
                        });
                        this.cancelReferredBackAssign();
                        //this.GetMuncipality();
                    }
                    else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                }, error => { })

            }
            else {
                if (CompanyID == "" && ClientID == "") {
                    Swal.fire({
                        title: 'Please Select Company & Client', icon: "error", timer: 1500
                    });
                }
                else if (CompanyID != "" && ClientID == "") {
                    Swal.fire({
                        title: 'Please Select Client', icon: "error", timer: 1500
                    });
                }
                else if (CompanyID == "" && ClientID != "") {
                    Swal.fire({
                        title: 'Please Select Company', icon: "error", timer: 1500
                    });
                }
                else {
                    Swal.fire({
                        title: 'Something went wrong', icon: "error", timer: 1500
                    });
                }
            }
        }

    }
    onFinishEditClick = (e) => {
        this.setState({ isFinishEditVisible: false });
        this.setState({ IsFinishUpdatesVisible: true });
        this.setState({ IsCompleteDisabled: false });
    }
    onFinishEditCancel = (e) => {

        this.setState({ isFinishEditVisible: true });
        this.setState({ IsFinishUpdatesVisible: false });
        this.setState({ IsCompleteDisabled: true }, () => { this.GetFinishData(this.state.BookingID); });


    }
    OnFinishUpdate = (e) => {
        debugger;
        var TotalJettingUsageTime = "";
        var TotalSuctionPumpUsageTime = "";
        e.preventDefault();
        var allvalid = true;

        var dt = this.state.mobVehicleReturnLocationTime;
        var date = dt;
        var djs = new Date(date);
        // var now = moment(djs).format('DD-MM-YYYY hh:mm:ss');
        var now = moment(djs).format('DD-MM-YYYY hh:mm');

        // var VehicleReturnLocationTime = moment(djs).format('DD-MM-YYYY HH:mm:ss');
        var VehicleReturnLocationTime = moment(djs).format('DD-MM-YYYY HH:mm');


        var locationdate = new Date(this.state.mobVehicleReachedParkingTime);
        // var VehicleReachedLocationTime = moment(locationdate,"DD-MM-YYYY hh:mm:ss");
        // var VehicleReachedParkingTime = moment(locationdate).format('DD-MM-YYYY HH:mm:ss');
        var VehicleReachedParkingTime = moment(locationdate).format('DD-MM-YYYY HH:mm');

        // if (this.state.VehicleReachedParkingTimeErrMsg!=="") {
        //     allvalid=false;
        // }
        if (this.state.VehicleReachedParkingOdometerErrMsg !== "") {
            allvalid = false;
        }
        if (this.state.JettingFinishMeterReadingErrMsg !== "") {
            allvalid = false;
        }
        if (this.state.SuctionPumpFinishMeterReadingErrMsg !== "") {
            allvalid = false;
        }
        if (this.state.JettingStartMeterReading !== undefined) {
            // var VehicleReachedLocationTime=moment(this.props.allCookies.VehicleReachedLocationTime,'hh:mm').toDate()
            var JettingStartMeterReading = this.state.JettingStartMeterReading;

            var JettingFinish = this.state.JettingFinishMeterReading.split(':');
            var JettingStart = JettingStartMeterReading.split(':');

            var Hours = JettingFinish[0] - JettingStart[0];
            var Time = JettingFinish[1] - JettingStart[1];

            if (Time < 0) {
                Time = (parseInt(60) + parseInt(JettingFinish[1])) - JettingStart[1];
                Hours = JettingFinish[0] - JettingStart[0] - 1;
            }



            if (Time < 0 || Hours < 0) {

            }
            else if (Time == 0 && Hours == 0) {

            }
            else {
                TotalJettingUsageTime = Hours + ":" + Time
            }
        }
        if (this.state.SuctionPumpStartMeterReading !== undefined) {
            // var VehicleReachedLocationTime=moment(this.props.allCookies.VehicleReachedLocationTime,'hh:mm').toDate()
            var SuctionStartMeterReading = this.state.SuctionPumpStartMeterReading

            var SuctionFinish = this.state.SuctionPumpFinishMeterReading.split(':');
            var SuctionStart = SuctionStartMeterReading.split(':');

            var Hours = SuctionFinish[0] - SuctionStart[0];
            var Time = SuctionFinish[1] - SuctionStart[1];

            if (Time < 0) {
                Time = (parseInt(60) + parseInt(SuctionFinish[1])) - SuctionStart[1];
                Hours = SuctionFinish[0] - SuctionStart[0] - 1;
            }



            if (Time < 0 || Hours < 0) {

            }
            else if (Time == 0 && Hours == 0) {

            }
            else {
                TotalSuctionPumpUsageTime = Hours + ":" + Time;
            }
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            UserType = this.props.allCookies.PanelUserType
        }
        var IsUpdateSecondTime = "";
        var UpdateBy = "";
        if (this.state.IsFinishClick === true) {
            IsUpdateSecondTime = "true";
            UpdateBy = "Panel";
        }
        this.completeform.validateFields();
        if (this.completeform.isValid()) {
            if (allvalid == true) {
                var data = new FormData();
                var UserID = "";
                var UserType = "";
                data.append('BookingTaskDetailID', this.state.BookingTaskDetailID);
                data.append('BookingID', this.state.BookingID);
                data.append('VehicleReachedLocationOdometer', this.state.VehicleReachedLocationOdometer === null ? '' : this.state.VehicleReachedLocationOdometer);
                data.append('VehicleReturnLocationTime', VehicleReturnLocationTime);
                data.append('VehicleReturnLocationOdometer', this.state.VehicleReturnLocationOdometer === null ? '' : this.state.VehicleReturnLocationOdometer);
                data.append('VehicleReachedParkingTime', VehicleReachedParkingTime);
                data.append('VehicleReachedParkingOdometer', this.state.VehicleReachedParkingOdometer === null ? '' : this.state.VehicleReachedParkingOdometer);
                data.append('JettingFinishMeterReading', this.state.JettingFinishMeterReading === null ? '' : this.state.JettingFinishMeterReading);
                data.append('SuctionPumpFinishMeterReading', this.state.SuctionPumpFinishMeterReading === null ? '' : this.state.SuctionPumpFinishMeterReading);
                data.append('TotalNoOfDranageLine', this.state.TotalNoOfDranageLine === null ? '' : this.state.TotalNoOfDranageLine);
                data.append('DrainageLine150to300mmDiaMeter', this.state.DrainageLine150to300mmDiaMeter === null ? '' : this.state.DrainageLine150to300mmDiaMeter);
                data.append('DrainageLine301to600mmDiaMeter', this.state.DrainageLine301to600mmDiaMeter === null ? '' : this.state.DrainageLine301to600mmDiaMeter);
                data.append('DrainageLine601to900mmDiaMeter', this.state.DrainageLine601to900mmDiaMeter === null ? '' : this.state.DrainageLine601to900mmDiaMeter);
                data.append('DrainageLine901to1200mmDiaMeter', this.state.DrainageLine901to1200mmDiaMeter === null ? '' : this.state.DrainageLine901to1200mmDiaMeter);
                data.append('JettingSystem', this.state.JettingSystem === null ? '' : this.state.JettingSystem);
                data.append('SuctionSystem', this.state.SuctionSystem === null ? '' : this.state.SuctionSystem);
                data.append('SuctionHose5Diameter3MeterLong', this.state.SuctionHose5Diameter3MeterLong === null ? '' : this.state.SuctionHose5Diameter3MeterLong);
                data.append('JettingHose32Diameter120MeterLong', this.state.JettingHose32Diameter120MeterLong === null ? '' : this.state.JettingHose32Diameter120MeterLong);
                data.append('JettingNozzleDeChockingNozzle', this.state.JettingNozzleDeChockingNozzle === null ? '' : this.state.JettingNozzleDeChockingNozzle);
                data.append('DeSiltingNozzleFlatNozzle', this.state.DeSiltingNozzleFlatNozzle === null ? '' : this.state.DeSiltingNozzleFlatNozzle);
                data.append('BalanceNozzleBombNozzle', this.state.BalanceNozzleBombNozzle === null ? '' : this.state.BalanceNozzleBombNozzle);
                data.append('BookingFinishLatitude', this.state.BookingFinishLatitude);
                data.append('BookingFinishLongitude', this.state.BookingFinishLongitude);
                data.append('BookingFinishAddress', this.state.BookingFinishAddress);
                data.append('Shift', this.state.Shift === null ? '' : this.state.Shift);
                data.append('NoOfCleanMachineHole', this.state.NoOfCleanMachineHole === null ? '' : this.state.NoOfCleanMachineHole);
                data.append('TotalJettingUsageTime', TotalJettingUsageTime);
                data.append('TotalSuctionPumpUsageTime', TotalSuctionPumpUsageTime);
                data.append('EntryByUserType', UserType);
                data.append('EntryByUserID', UserID);
                data.append('IsUpdateSecondTime', IsUpdateSecondTime);
                data.append('BookingEndDataEntryByType', "Panel");
                data.append('BookingEndDataUpdateByType', UpdateBy);
                for (let i = 0; i < this.state.FinishTaskFile.length; i++) { data.append("TaskImages", this.state.FinishTaskFile[i]); }
                for (var pair of data.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }
                services.FinishFormValue(data).then(response => {
                    if (response.data.status) {
                        Swal.fire({
                            title: 'Successfully Updated', icon: "success", timer: 1500
                        });
                        this.setState({ isFinishEditVisible: true });
                        this.setState({ IsFinishUpdatesVisible: false });
                        this.setState({ IsCompleteDisabled: true });
                        //this.GetBooking();
                        //UserService.GetServiceData().then(response => { this.setState({ data: response.data.data }) }, error => { })
                    } else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                    //this.cancelFinish();

                }, error => { })
            }

            // for (let i=0; i<this.state.FinishTaskFile.length; i++) { data.append("TaskImages", this.state.FinishTaskFile[i]); }

            // services.FinishFormValue(data).then(response => {
            //     if (response.data.status) {
            //         Swal.fire({
            //             title: 'Successfully Finished', icon: "success", timer: 1500
            //         });
            //         //this.GetBooking();
            //         //UserService.GetServiceData().then(response => { this.setState({ data: response.data.data }) }, error => { })
            //     } else {
            //         Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
            //     }
            //     this.cancelFinish();

            // }, error => { })
        }

    }
    BindBookedVehicleModal = () => {
        // debugger
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        var data = JSON.stringify({
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "NoOfDays": "15"
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getAllocatedVehicleDriverData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                this.setState({ BookedVehicle: response.data.data })
            }
            else {
                this.setState({ BookedVehicle: [] })
            }

        }, error => { })
    }
    BindBookedDriverModal = () => {
        // debugger
        var CompanyID = "";
        var ClientID = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        var data = JSON.stringify({
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "NoOfDays": "15"
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Booking/getAllocatedVehicleDriverData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                this.setState({ BookedDriver: response.data.data })
            }
            else {
                this.setState({ BookedDriver: [] })
            }

        }, error => { })
    }
    GetVehicleBookedModal = (e) => {
        e.preventDefault();
        window.$(this.VehicleBookedModal).modal('show');
        this.setState({ isBookedVehicle: true }, () => { this.BindBookedVehicleModal() });
    }
    GetDriverBookedModal = (e) => {
        e.preventDefault();
        window.$(this.DriverBookedModal).modal('show');
        this.setState({ isBookedVehicle: true }, () => { this.BindBookedDriverModal() });
    }
    onCloseBookedVehicleModal = (e) => {
        e.preventDefault();
        window.$(this.VehicleBookedModal).modal('hide')
    }
    onCloseBookedDriverModal = (e) => {
        e.preventDefault();
        window.$(this.DriverBookedModal).modal('hide')
    }
    GetBookingDetailModal = (taskno, municipalityname, wardname, locality, vehicleno) => e => {
        e.preventDefault();
        window.$(this.BookingDetailModal).modal('show');
        this.setState({ BookedTaskNo: taskno });
        this.setState({ BookedMunicipality: municipalityname });
        this.setState({ BookedWard: wardname });
        this.setState({ BookedLocality: locality });
        this.setState({ BookedAssignedvehicleNo: vehicleno });
    }
    GetBookingDriverDetailModal = (taskno, municipalityname, wardname, locality, drivername) => e => {
        e.preventDefault();
        window.$(this.BookingDriverDetailModal).modal('show');
        this.setState({ BookedTaskNo: taskno });
        this.setState({ BookedMunicipality: municipalityname });
        this.setState({ BookedWard: wardname });
        this.setState({ BookedLocality: locality });
        this.setState({ BookedAssignedDriver: drivername });
    }
    OnBookingPaymentClick = (e) => {
        // debugger;

        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var UserType = "";
        var PaymentReceivedDate = this.state.PaymentReceivedDate;
        var allvalid = true;
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            UserType = this.props.allCookies.PanelUserType
        }
        var IsUpdateSecondTime = "";
        if (this.state.IsUpdateSecondTime !== null) {
            IsUpdateSecondTime = this.state.IsUpdateSecondTime;
        }
        e.preventDefault();
        this.Paymentform.validateFields();
        if (PaymentReceivedDate === "" || PaymentReceivedDate == null) {
            this.setState({ PaymentDateErrMsg: 'required' });
            allvalid = false;
        }
        if (this.Paymentform.isValid()) {
            if (allvalid == true) {
                var data = "";
                data = JSON.stringify({
                    "BookingID": this.state.BookingID,
                    "BillNo": this.state.BillNo,
                    "PaymentReceivedDate": moment(this.state.PaymentReceivedDate).format('DD-MM-YYYY'),
                    "PaymentAmount": this.state.PaymentAmount,
                    "PaymentReceivedByUserID": UserID,
                    "IsUpdateSecondTime": "true",
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "BookingNo": this.state.BookingNo,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });

                var config = {
                    method: 'POST',
                    url: services.API_URL + "Booking/setBookingPayment",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    // debugger;
                    if (response.data.status) {
                        // debugger;
                        Swal.fire({
                            title: 'Successfully Payment Complete', icon: "success", timer: 1500
                        });
                        this.cancelBookingPaymentClick();
                    }
                    else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                }, error => { })
            } else {
                //Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: 'something went wrong', showConfirmButton: false, timer: 3000 });
            }
        }
    }
    cancelBookingPaymentClick = (e) => {

        this.Paymentform.reset();
        this.setState({ BillNo: "", PaymentReceivedDate: "", PaymentAmount: "", PaymentDateErrMsg: "", BookingID: "", BookingTaskDetailID: "" });
        window.$(this.BookingPaymentModal).modal('hide');
        this.GetBooking();
    }
    ValidatePaymentReceivedDate = (e) => {
        // debugger;
        this.setState({ PaymentDateErrMsg: '' })

    }
    ValidateCancelPaymentReceivedDate = (e) => {
        this.setState({ PaymentDateErrMsg: 'required' })
    }
    ExcelClick = () => {
        exportToExcel(this.state.cols, this.state.data)
    }

    onChangeGeoLatitude = (e) => {
        debugger;
        var ChangeLatitude = e.target.value;
        var getLongitude = this.state.Longitude;
        this.setState({ Latitude: e.target.value });
        e.preventDefault();
        if (ChangeLatitude != "") {
            Geocode.setApiKey("AIzaSyA8Io_sD3d5uTz6ZTj4FKae_I7mdBVjX9o");
            Geocode.setLanguage("en");
            Geocode.setLocationType("ROOFTOP");
            Geocode.enableDebug();
            Geocode.fromLatLng(ChangeLatitude, getLongitude).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    this.setState({ CurrentAddress: address });
                    console.log(address);
                },
                (error) => {
                    console.error(error);
                }
            );
        }
    }

    onChangeGeoLongitude = (e) => {
        debugger;
        var ChangeLongitude = e.target.value;
        var getLatitude = this.state.Latitude;
        this.setState({ Longitude: e.target.value });
        e.preventDefault();
        if (ChangeLongitude != "") {
            Geocode.setApiKey("AIzaSyA8Io_sD3d5uTz6ZTj4FKae_I7mdBVjX9o");
            Geocode.setLanguage("en");
            Geocode.setLocationType("ROOFTOP");
            Geocode.enableDebug();
            Geocode.fromLatLng(getLatitude, ChangeLongitude).then(
                (response) => {
                    debugger;
                    const address = response.results[0].formatted_address;
                    this.setState({ CurrentAddress: address });
                    console.log(address);
                },
                (error) => {
                    console.error(error);
                }
            );
        }
    }

    onChangeGeoCurrentAddress = (e) => {
        debugger;
        this.setState({ CurrentAddress: e.target.value });
        e.preventDefault();
    }

    onChangeBookingFinishGeoLatitude = (e) => {
        debugger;
        var bookingFinishChangeLatitude = e.target.value;
        this.setState({ BookingFinishLatitude: e.target.value });
        e.preventDefault();
        if (bookingFinishChangeLatitude != "") {
            Geocode.setApiKey("AIzaSyA8Io_sD3d5uTz6ZTj4FKae_I7mdBVjX9o");
            Geocode.setLanguage("en");
            Geocode.setLocationType("ROOFTOP");
            Geocode.enableDebug();
            Geocode.fromLatLng(bookingFinishChangeLatitude, this.state.BookingFinishLongitude).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    this.setState({ BookingFinishAddress: address });
                    console.log(address);
                },
                (error) => {
                    console.error(error);
                }
            );
        }
    }

    onChangeBookingFinishGeoLongitude = (e) => {
        debugger;
        var bookingFinishChangeLongitude = e.target.value;
        this.setState({ BookingFinishLongitude: e.target.value });
        e.preventDefault();
        if (bookingFinishChangeLongitude != "") {
            Geocode.setApiKey("AIzaSyA8Io_sD3d5uTz6ZTj4FKae_I7mdBVjX9o");
            Geocode.setLanguage("en");
            Geocode.setLocationType("ROOFTOP");
            Geocode.enableDebug();
            Geocode.fromLatLng(this.state.BookingFinishLatitude, bookingFinishChangeLongitude).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    this.setState({ BookingFinishAddress: address });
                    console.log(address);
                },
                (error) => {
                    console.error(error);
                }
            );
        }
    }

    onChangeBookingFinishGeoCurrentAddress = (e) => {
        debugger;
        this.setState({ BookingFinishAddress: e.target.value });
        e.preventDefault();
    }

    onChangeMachineNotWorkFromTime = (e) => {
        if (e != null) {
            this.setState({ MachineNotWorkFromTime: e.$d })
            if (e.$d == "Invalid Date") {
                this.setState({ MachineNotWorkFromTime: '' })
            } else {
                var chkMachineNotWorkFromTime, chkMachineNotWorkToTime;

                chkMachineNotWorkFromTime = new Date(e.$d);
                chkMachineNotWorkToTime = new Date(this.state.MachineNotWorkToTime);

                if (chkMachineNotWorkFromTime != "" && chkMachineNotWorkToTime != "") {
                    if (chkMachineNotWorkToTime < chkMachineNotWorkFromTime) {
                        this.setState({ MachineNotWorkToTimeErrMsg: "greater" });
                    } else {
                        this.setState({ MachineNotWorkToTimeErrMsg: "" });
                    }
                }
            }

            if (chkMachineNotWorkFromTime != "") {
                this.setState({ MachineNotWorkFromTimeErrMsg: "" });
            }
            else {
                this.setState({ MachineNotWorkFromTimeErrMsg: "required" });
            }
        } else {
            this.setState({ MachineNotWorkFromTime: '' })
        }
    }

    onChangeMachineNotWorkToTime = (e) => {
        debugger;
        if (e != null) {
            var chkMachineNotWorkFromTime, chkMachineNotWorkToTime;
            chkMachineNotWorkFromTime = this.state.MachineNotWorkFromTime;

            if (chkMachineNotWorkFromTime != "") {
                this.setState({ MachineNotWorkFromTimeErrMsg: "" });
            }
            else {
                this.setState({ MachineNotWorkFromTimeErrMsg: "required" });
            }

            this.setState({ MachineNotWorkToTime: e.$d })
            if (e.$d == "Invalid Date") {
                this.setState({ MachineNotWorkToTime: '' })
            } else {
                chkMachineNotWorkFromTime = new Date(chkMachineNotWorkFromTime);
                chkMachineNotWorkToTime = e.$d;
                if (chkMachineNotWorkFromTime != "" && chkMachineNotWorkToTime != "") {
                    if (chkMachineNotWorkToTime < chkMachineNotWorkFromTime) {
                        this.setState({ MachineNotWorkToTimeErrMsg: "greater" });
                    } else {
                        this.setState({ MachineNotWorkToTimeErrMsg: "" });
                    }
                }
            }
        } else {
            this.setState({ MachineNotWorkToTime: '' })
        }
    }

    // onChangeMachineNotWorkTotalTime = (e) => {
    //     this.setState({ MachineNotWorkTotalTime: e.$d })
    //     if (e.$d == "Invalid Date") {
    //         this.setState({ MachineNotWorkTotalTime: '' })
    //     }
    // }

    onSludgeDisposalAreaCheckboxChange = (e) => {
        this.setState({ SludgeDisposalArea: e.target.value })
    }

    render() {
        const { photoIndex, isOpen } = this.state;
        const { cookies } = this.props
        debugger;
        var Permission = cookies.cookies.ViewTask;
        var AssignedPermission = cookies.cookies.ViewAssignTask;
        var StartedPermission = cookies.cookies.ViewStartTask;
        var FinishedPermission = cookies.cookies.ViewFinishTask;
        var CompletedPermission = cookies.cookies.ViewCompleteTask;
        var ApprovedPermission = cookies.cookies.ViewApprovalTask;
        var PaymentPermission = cookies.cookies.ViewTaskPayment;
        var TotalPermission = "";
        if (Permission !== undefined) {
            TotalPermission = Permission.split(',');
            if (this.state.AddStatus == "") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        if (this.state.EditAssignedPermission == "") {
            if (AssignedPermission !== undefined) {
                var TotalAssignPermission = "";
                TotalAssignPermission = AssignedPermission.split(',');
                this.setState({ EditAssignedPermission: TotalAssignPermission[1] });
            }
        }

        if (this.state.EditStartedPermission == "") {
            if (StartedPermission !== undefined) {
                var TotalStartedPermission = "";
                TotalStartedPermission = StartedPermission.split(',');
                this.setState({ EditStartedPermission: TotalStartedPermission[1] });
            }
        }

        if (this.state.EditFinishedPermission == "") {
            if (FinishedPermission !== undefined) {
                var TotalFinishedPermission = "";
                TotalFinishedPermission = FinishedPermission.split(',');
                this.setState({ EditFinishedPermission: TotalFinishedPermission[1] });
            }
        }

        if (this.state.EditCompletedPermission == "") {
            if (CompletedPermission !== undefined) {
                var TotalCompletedPermission = "";
                TotalCompletedPermission = CompletedPermission.split(',');
                this.setState({ EditCompletedPermission: TotalCompletedPermission[1] });
            }
        }

        if (this.state.EditApprovedPermission == "") {
            if (ApprovedPermission !== undefined) {
                var TotalApprovedPermission = "";
                TotalApprovedPermission = ApprovedPermission.split(',');
                this.setState({ EditApprovedPermission: TotalApprovedPermission[1] });
            }
        }
        if (this.state.EditPaymentPermission == "") {
            if (PaymentPermission !== undefined) {
                var TotalPaymentPermission = "";
                TotalPaymentPermission = PaymentPermission.split(',');
                this.setState({ EditPaymentPermission: TotalPaymentPermission[1] });
            }
        }

        return (
            <div>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    {this.state.isFilterVisible &&
                        <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Start Date </label>
                                    <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.StartDate} onChange={this.onStartDateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>End Date </label>
                                    <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.EndDate} minDate={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.EndDate} onChange={(e) => {
                                        this.setState({ EndDate: e });
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Task No </label>
                                    <input type="text" value={this.state.BookingNo} onChange={(e) => this.setState({ BookingNo: e.target.value })} onKeyPress={this.allowOnlyNumbers} className="form-control" placeholder="Enter Task No" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Vehicle </label>
                                    <Select isClearable={true} options={this.state.searchvehicles} value={this.state.SearchVehicleID} onChange={this.onSearchVehicleChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Municipality </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.municipalities} selected={this.state.MunicipalityID} checked={false}
                                        onSelectedChanged={this.onMunicipalityChange}
                                        overrideStrings={{
                                            selectSomeItems: "Municipalities", allItemsAreSelected: "All Municipality are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button type="button" onClick={this.onSearchBookingClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                <button type="button" onClick={this.OnSearchCancelClientClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-body">
                                    <MaterialTable columns={this.state.cols} data={this.state.data}
                                        tableRef={this.tableRef}
                                        // detailPanel={[{

                                        //     render: (rowData) => {
                                        //         return (
                                        //             <MaterialTable style={{ border: '1px solid' }} className="DetailGrid" columns={this.state.detailcols} data={this.state.detaildata}
                                        //                 options={{
                                        //                     headerStyle: { color: 'black' }, toolbar: true, search: false,
                                        //                     paging: false, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                        //                 }}
                                        //                 components={{

                                        //                     Toolbar: props => (
                                        //                         <div className="row" style={{ marginBottom: '2%' }}>
                                        //                             <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'center' }}>
                                        //                                 <h3 className="tabletitle">Assign Task</h3>
                                        //                             </div>
                                        //                         </div>

                                        //                     )
                                        //                 }}
                                        //             />
                                        //         )
                                        //     }
                                        // }
                                        // ]}
                                        //  detailPanel={rowData => <div><h1>Hello, mate!</h1></div>}
                                        detailPanel={[
                                            {
                                                icon: 'add', tooltip: 'Click here to see details', title: 'show',
                                                render: ({ rowData }) => {
                                                    if (rowData.BookingTaskDetail != null) {
                                                        return (
                                                            rowData.BookingTaskDetail.map((value) => {
                                                                return (
                                                                    <div className="row" style={{ marginBottom: '2%' }}>
                                                                        <div style={{ border: '1px solid' }}>
                                                                            <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                                <h3 className="tabletitle">Assign Task</h3>
                                                                            </div>
                                                                            <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                                <thead>
                                                                                    <tr>

                                                                                        <td>Vehicle No</td>
                                                                                        <td>Supervisor</td>
                                                                                        <td>Driver</td>
                                                                                        <td>Helper</td>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>

                                                                                        <th>{value.VehicleNo}</th>
                                                                                        <th>{value.SupervisorFullName}</th>
                                                                                        <th>{value.DriverFullName}</th>
                                                                                        <th style={{ paddingTop: '1%', paddingBottom: '1%' }}>{
                                                                                            (value.BookingTaskHelperDetail != null) ?
                                                                                                (
                                                                                                    value.BookingTaskHelperDetail.map((value) => {
                                                                                                        return (
                                                                                                            <span className="permissionborderdata">{value.FullName}</span>
                                                                                                        );
                                                                                                    })
                                                                                                ) : ''
                                                                                        }</th>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                                <h3 className="tabletitle">Start Task</h3>
                                                                            </div>
                                                                            <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <td>Action</td>
                                                                                        <td>Parking Location</td>
                                                                                        <td>Vehicle Leaving Parking Time</td>
                                                                                        <td>Vehicle Reaching Location Time</td>
                                                                                        <td>Odometer of Leaving Parking</td>
                                                                                        <td>Odometer of Vehicle Reaching Work Location</td>
                                                                                        <td>Jetting Starting Meter Reading</td>
                                                                                        <td>Suction Pump Starting Meter Reading</td>
                                                                                        <td>Capture Details</td>
                                                                                        <td>CheckList</td>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>
                                                                                            {

                                                                                                ((value.BookingStatus !== "Cancelled") ?
                                                                                                    (<i hidden={(value.VehicleLeaveParkingTime == null || rowData.BookingStatus == "Cancelled") ? true : false} className="fa fa-edit" style={{ color: 'black' }} onClick={this.EditStartBooking(value.BookingID, value.BookingTaskDetailID, value.BookingNo)}></i>) : '')
                                                                                            }
                                                                                        </th>
                                                                                        <th>{value.ParkingLocationName}</th>
                                                                                        <th>{value.VehicleLeaveParkingTime}</th>
                                                                                        <th>{value.VehicleReachedLocationTime}</th>
                                                                                        <th>{value.VehicleLeaveParkingOdometer}</th>
                                                                                        <th>{value.VehicleReachedLocationOdometer}</th>
                                                                                        <th>{value.JettingStartMeterReading}</th>
                                                                                        <th>{value.SuctionPumpStartMeterReading}</th>
                                                                                        <th>{
                                                                                            (value.BookingTaskImageDetail != null) ?
                                                                                                (
                                                                                                    value.BookingTaskImageDetail.map((value) => {
                                                                                                        if (value.WorkImageType == "Start") {
                                                                                                            return (
                                                                                                                <a href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage} target="_blank">
                                                                                                                    <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage}></img>
                                                                                                                </a>
                                                                                                            );
                                                                                                        }

                                                                                                    })
                                                                                                ) : '-'
                                                                                        }</th>
                                                                                        <th style={{ paddingTop: '1%', paddingBottom: '1%' }}>{
                                                                                            (value.BookingTaskCheckListDetail != null) ?
                                                                                                (
                                                                                                    value.BookingTaskCheckListDetail.map((value) => {
                                                                                                        return (
                                                                                                            <span className="permissionborderdata">{value.CheckListTitle}</span>
                                                                                                        );
                                                                                                    })
                                                                                                ) : ''
                                                                                        }</th>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                                <h3 className="tabletitle">Finish Task</h3>
                                                                            </div>
                                                                            <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <td>Action</td>
                                                                                        <td>Odometer of Vehicle Leaving Work Location<hr></hr>
                                                                                            Odometer Reaching Parking Location </td>
                                                                                        {/* Not */}
                                                                                        <td>Vehicle Leaving Work Location Time<hr></hr>
                                                                                            Vehicle Reaching Parking Location Time</td>

                                                                                        <td>Jetting Finish Meter Reading<hr></hr>
                                                                                            Suction Pump Finish Meter Reading</td>
                                                                                        <td>Lunch Start Time<hr></hr>
                                                                                            Lunch End Time</td>
                                                                                        <td>Total number of drainage line<hr></hr>Task Finish Remarks</td>
                                                                                        <td>150 mm to 300 mm dia meter<hr></hr>
                                                                                            301 mm to 600 mm dia meter</td>
                                                                                        <td>601 mm to 900 mm dia meter<hr></hr>
                                                                                            901 mm to 1200 mm dia meter</td>
                                                                                        <td>Jetting system<hr></hr>
                                                                                            Suction system</td>
                                                                                        <td>Suction hose 5 diameter - 3 meter long</td>
                                                                                        <td>Jetting hose - 32 m.m. dia, 120 m. long</td>
                                                                                        <td>Jetting nozzle: De Chocking nozzle</td>
                                                                                        <td>De Silting nozzle - flat nozzle</td>
                                                                                        <td>Balance nozzle bomb nozzle</td>
                                                                                        <td>Shift<hr></hr>No Of Clean Machine Hole</td>
                                                                                        <td>Images</td>
                                                                                        {/* <td>Upload Multiple Images</td> */}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>
                                                                                            {

                                                                                                (<i hidden={value.VehicleReturnLocationTime == null || rowData.BookingStatus == "Cancelled" ? true : false} className="fa fa-edit" style={{ color: 'black' }} onClick={this.EditFinishBooking(value.BookingID, value.BookingTaskDetailID, value.BookingNo)}></i>)
                                                                                            }
                                                                                        </th>
                                                                                        <th>{value.VehicleReturnLocationOdometer}<hr></hr>{value.VehicleReachedParkingOdometer}</th>
                                                                                        <th>{value.VehicleReturnLocationTime}<hr></hr>{value.VehicleReachedParkingTime}</th>
                                                                                        <th>{value.JettingFinishMeterReading}<hr></hr>{value.SuctionPumpFinishMeterReading}</th>
                                                                                        <th>{value.LunchStartTime}<hr></hr>{value.LunchEndTime}</th>
                                                                                        <th>{value.TotalNoOfDranageLine}<hr></hr>{value.BookingFinishRemarks}</th>
                                                                                        <th>{value.DrainageLine150to300mmDiaMeter}<hr></hr>{value.DrainageLine301to600mmDiaMeter}</th>
                                                                                        <th>{value.DrainageLine601to900mmDiaMeter}<hr></hr>{value.DrainageLine901to1200mmDiaMeter}</th>
                                                                                        <th>{value.JettingSystem}<hr></hr>{value.SuctionSystem}</th>
                                                                                        <th>{value.SuctionHose5Diameter3MeterLong}</th>
                                                                                        <th>{value.JettingHose32Diameter120MeterLong}</th>
                                                                                        <th>{value.JettingNozzleDeChockingNozzle}</th>
                                                                                        <th>{value.DeSiltingNozzleFlatNozzle}</th>
                                                                                        <th>{value.BalanceNozzleBombNozzle}</th>
                                                                                        <th>{value.Shift}<hr />{value.NoOfCleanMachineHole}</th>
                                                                                        <th>{
                                                                                            (value.BookingTaskImageDetail != null) ?
                                                                                                (
                                                                                                    value.BookingTaskImageDetail.map((value) => {
                                                                                                        if (value.WorkImageType == "Finish") {
                                                                                                            return (
                                                                                                                <a href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage} target="_blank">
                                                                                                                    <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage}></img>
                                                                                                                </a>
                                                                                                            );
                                                                                                        }

                                                                                                    })
                                                                                                ) : '-'
                                                                                        }</th>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                                <h3 className="tabletitle">Complete Task</h3>
                                                                            </div>
                                                                            <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Action</th>
                                                                                        <th>Remarks<hr></hr>Remarks in Gujarati</th>
                                                                                        <th>Tender liquidity damages condition</th>
                                                                                        <th>Log Sheet No</th>
                                                                                        <th>Location Detail</th>
                                                                                        <th>Engineer Name<hr></hr>Designation</th>
                                                                                        <th>Machine did not work From Time<hr></hr>Machine did not work To Time<hr></hr>Machine did not work Total Time</th>
                                                                                        <th>Extraordinary Details</th>
                                                                                        <th>Extraordinary Area</th>
                                                                                        <th>Machine Holes<hr></hr>Sludge Disposal Area Allocated By Municipality</th>
                                                                                        <th>Log Sheet Image</th>
                                                                                        {/* <td>Upload Multiple Images</td> */}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            {
                                                                                                (<i hidden={value.BookingCompleteRemarks == null || rowData.BookingStatus == "Cancelled" ? true : false} className="fa fa-edit" style={{ color: 'black' }} onClick={this.EditCompleteBooking(value.BookingID, value.BookingTaskDetailID, value.BookingNo)}></i>)
                                                                                            }
                                                                                        </td>
                                                                                        <td>{value.BookingCompleteRemarks}<hr></hr>{value.BookingCompleteRemarksGujarati}</td>
                                                                                        <td>{value.TenderLiquidityDamagesCondition}</td>
                                                                                        <td>{value.LogSheetNo}</td>
                                                                                        <td>{value.LocationDetail}</td>
                                                                                        <td>{value.EngineerName}<hr></hr>{value.EngineerDesignation}</td>
                                                                                        <td>{value.MachineNotWorkFromTime}<hr></hr>{value.MachineNotWorkToTime}<hr></hr>{value.MachineNotWorkTotalTime}</td>
                                                                                        <td>{value.ExtraordinaryDetails}</td>
                                                                                        <td>{value.ExtraordinaryArea}</td>
                                                                                        <td>{value.ExtraordinaryMachineHoles}<hr></hr>{value.SludgeDisposalArea}</td>
                                                                                        <td>{
                                                                                            (value.LogSheetImage != null) ? (
                                                                                                <a href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.LogSheetImage} target="_blank">
                                                                                                    <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.LogSheetImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + value.LogSheetImage}></img>
                                                                                                </a>
                                                                                            ) : ''
                                                                                        }</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                                <h3 className="tabletitle">Approval Task</h3>
                                                                            </div>
                                                                            <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <td>Action</td>
                                                                                        <td>Status</td>
                                                                                        <td>Remarks</td>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>
                                                                                            {

                                                                                                (<i hidden={value.BookingApprovalStatus == null || rowData.BookingStatus == "Cancelled" ? true : false} className="fa fa-edit" style={{ color: 'black' }} onClick={this.EditApprovedBooking(value.BookingID, value.BookingTaskDetailID, value.BookingNo)}></i>)
                                                                                            }
                                                                                        </th>
                                                                                        <th>{value.BookingApprovalStatus ? value.BookingApprovalStatus : '-'}</th>
                                                                                        <th>{value.BookingApprovalRemarks ? value.BookingApprovalRemarks : '-'}</th>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                                <h3 className="tabletitle">Payment Details</h3>
                                                                            </div>
                                                                            <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <td>Action</td>
                                                                                        <td>Date</td>
                                                                                        <td>Bill No</td>
                                                                                        <td>Amount</td>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>
                                                                                            {


                                                                                                (<i hidden={value.PaymentReceivedDate == null || rowData.BookingStatus == "Cancelled" ? true : false} className="fa fa-edit" style={{ color: 'black' }} onClick={this.EditPaymentBooking(value.BookingID, value.BookingTaskDetailID)}></i>)
                                                                                            }
                                                                                        </th>
                                                                                        <th>{rowData.PaymentReceivedDate ? rowData.PaymentReceivedDate : '-'}</th>
                                                                                        <th>{rowData.BillNo ? rowData.BillNo : '-'}</th>
                                                                                        <th>{rowData.PaymentAmount ? rowData.PaymentAmount : '-'}</th>

                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        )
                                                    } else {
                                                        return false;
                                                    }
                                                }
                                            },
                                            {
                                                icon: 'add', tooltip: 'Click here to see defect details', title: 'show',
                                                render: ({ rowData }) => {
                                                    return (
                                                        <>
                                                            <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                <h3 className="tabletitle">Defect Details</h3>
                                                            </div>
                                                            <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <td style={{ width: '10%' }}>Defect Name</td>
                                                                        <td style={{ width: '20%' }}>Checklist Details</td>
                                                                        <td style={{ width: '30%' }}>Images</td>
                                                                        <td style={{ width: '20%' }}>Latitude <hr style={{ marginTop: '5px', marginBottom: '5px' }} />Longitude<hr style={{ marginTop: '5px', marginBottom: '5px' }} />Address</td>
                                                                        <td style={{ width: '20%' }}>Remarks</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        (rowData.BookingDefectDetail != null) ?
                                                                            (
                                                                                rowData.BookingDefectDetail.map((value) => {
                                                                                    return (
                                                                                        <tr className="mapfonts">
                                                                                            <td>
                                                                                                <span>{value.DefectName}</span>
                                                                                                {value.DefectDataEntryDateTime &&
                                                                                                    <>
                                                                                                        <hr></hr>
                                                                                                        <span>{value.DefectDataEntryDateTime}</span>
                                                                                                    </>
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                {
                                                                                                    (value.BookingDefectCheckListDetail != null) ?
                                                                                                        (
                                                                                                            value.BookingDefectCheckListDetail.map((value) => {
                                                                                                                return (
                                                                                                                    <span className="permissionborderdata">{value.CheckListTitle}</span>
                                                                                                                );
                                                                                                            })
                                                                                                        ) : ''
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="row">
                                                                                                    {
                                                                                                        (value.BookingDefectImageDetail != null) ?
                                                                                                            (
                                                                                                                value.BookingDefectImageDetail.map((value) => {
                                                                                                                    return (
                                                                                                                        <div className="col-md-2">
                                                                                                                            <a href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.DefectImage} target="_blank">
                                                                                                                                <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.DefectImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%', objectFit: 'contain' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + value.DefectImage}></img>
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    );
                                                                                                                })
                                                                                                            ) : ''
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span>{value.Latitude}</span><hr />
                                                                                                <span>{value.Longitude}</span><hr />
                                                                                                <span>{value.Address}</span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span>{value.Remarks}</span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })) : <tr><td className="mapfonts" colSpan={7}><span>No Data Available</span></td>
                                                                            </tr>}
                                                                </tbody>
                                                            </table>
                                                        </>
                                                    )
                                                }
                                            }
                                        ]}
                                        actions={[
                                            rowData => ({
                                                // hidden: this.state.UpdateStatus=="UpdateStatus:true"? (
                                                //     (rowData.BookingStatus=="New"&&this.state.UpdateStatus=="UpdateStatus:true")? false:true):true,
                                                icon: 'edit', tooltip: 'Edit', onClick: (e, r) =>
                                                    this.EditBooking(r.BookingID)
                                                // ((r.BookingStatus=="New"&&r.IsRefferBack==null&&this.state.UpdateStatus=="UpdateStatus:true")?
                                                //     (this.EditBooking(r.BookingID)):'')
                                            }),
                                            rowData => ({
                                                hidden: (rowData.BookingStatus !== "Cancelled") ? false : true,
                                                icon: 'cancel', tooltip: 'Cancel Task', onClick: (e, r) => this.CancelSubAdminTask(r.BookingID, r.BookingTaskDetailID)
                                            })
                                        ]}

                                        options={{
                                            headerStyle: { color: 'black' }, toolbar: true, search: false, draggable: false,
                                            paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                            detailPanelType: "single",
                                            rowStyle: rowData => {
                                                if (rowData.IsRefferBack == "True") {
                                                    return { backgroundColor: '#F64E60' };
                                                }
                                                return {};
                                            }
                                        }}
                                        components={{

                                            Toolbar: props => (
                                                <div className="row" style={{ marginBottom: '2%' }}>
                                                    <div className="col-md-8 mobpb-5" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h3 className="tabletitle">View Task</h3>

                                                    </div>
                                                    <div className="col-md-4 mobta-left" style={{ textAlign: 'right' }}>
                                                        {/* <a href="/booking" className="btn btn-outline-primary font-weight-bolder mr-5">
                                                            <span className="svg-icon svg-icon-md">
                                                                <i className="fa fa-plus" />
                                                            </span>Add Task</a> */}

                                                        <a className="btn btn-outline-dark font-weight-bolder" onClick={this.OnFilterClick}>
                                                            <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                        </a>
                                                        {this.state.ExcelStatus === "ExcelStatus:true" &&
                                                            <a className="btn btn-outline-success font-weight-bolder ml-4" onClick={this.state.data.length > 0 ? this.ExcelClick : ''}>
                                                                <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                            </a>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }}


                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="modal fade" ref={modal => this.modal = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Assign Task
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-11">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Vehicle </label>
                                            <Select isClearable={true} options={this.state.vehicles} value={this.state.VehicleID} onChange={this.onVehicleChange} />
                                            {this.state.VehicleErrMsg && <span className="text-danger">{this.state.VehicleErrMsg === 'required' ? '*' : ''}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-1" style={{ display: 'flex', alignItems: 'center' }}>
                                        <i className="fa fa-calendar-alt " onClick={this.GetVehicleBookedModal}>
                                        </i>
                                    </div>
                                    <div className="col-md-11">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Driver </label>
                                            <Select isClearable={true} options={this.state.drivers} value={this.state.DriverID} onChange={this.onDriverChange} />
                                            {this.state.DriverErrMsg && <span className="text-danger">{this.state.DriverErrMsg === 'required' ? '*' : ''}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-1" style={{ display: 'flex', alignItems: 'center' }}>
                                        <i className="fa fa-calendar-alt " onClick={this.GetDriverBookedModal}>
                                        </i>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Helper </label>
                                            <div className="checkbox-inline">
                                                {
                                                    this.state.HelperDataAvailable &&
                                                    <label className="checkbox" >
                                                        <input checked={this.state.isChecked} style={{ marginRight: 0 }} value="Select All" type="checkbox" onChange={this.onHelperAllChange} />
                                                        <span />Select All
                                                    </label>

                                                }

                                                {
                                                    this.state.helpers.map((item, index) => (

                                                        <label className="checkbox" key={index}>

                                                            <input checked={item.isChecked} style={{ marginRight: 0 }} value={item.id} type="checkbox" onChange={this.onHelperChange} />
                                                            <span />{item.value}

                                                        </label>
                                                    ))
                                                }
                                                {
                                                    this.state.NoHelperVisibleMsg &&
                                                    <label>No Helper Available</label>
                                                }

                                            </div>
                                            {this.state.HelperErrMsg && <span className="text-danger">{this.state.HelperErrMsg === 'required' ? '*' : ''}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" onClick={this.handleAssign} className="btn btn-primary font-weight-bold">
                                    Submit
                                </button>
                                <button type="button" onClick={this.cancelAssign} className="btn btn-light-danger font-weight-bold">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.modals = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog newstartmodaldialog" role="document">
                        <div className="modal-content">
                            <FormWithConstraints
                                ref={form => this.forms = form}
                                onSubmit={this.OnStartTaskSubmit}
                                noValidate>
                                <div className="modal-header">
                                    <div className="row" style={{ width: '100%' }}>
                                        <div className="col-md-2">
                                            <h5 className="modal-title" id="exampleModalLabel">
                                                Start Task
                                            </h5>
                                        </div>
                                        <div className="col-md-3">
                                            <p>Current Latitude</p>
                                            {/* <p>{this.state.Latitude}</p> */}
                                            {this.state.isGeoLatitudeVisible &&
                                                <p><input type="text" id="geoLatitude" name="GeoLocationLatitude" value={this.state.Latitude} onChange={this.onChangeGeoLatitude} onKeyPress={this.allowOnlyNumbersDot} className="form-control" placeholder="" maxLength={15} /></p>
                                            }
                                            {this.state.GeoLatitudeErrMsg && <span className="text-danger">{this.state.GeoLatitudeErrMsg === 'required' ? 'Enter Latitude' : ''}</span>}
                                        </div>
                                        <div className="col-md-3">
                                            <p>Current Longitude</p>
                                            {/* <p>{this.state.Longitude}</p> */}
                                            {this.state.isGeoLongitudeVisible &&
                                                <p><input type="text" id="geoLongitude" name="GeoLocationLongitude" value={this.state.Longitude} onChange={this.onChangeGeoLongitude} onKeyPress={this.allowOnlyNumbersDot} className="form-control" placeholder="" maxLength={15} /></p>
                                            }
                                            {this.state.GeoLongitudeVErrMsg && <span className="text-danger">{this.state.GeoLongitudeVErrMsg === 'required' ? 'Enter Longitudes' : ''}</span>}
                                        </div>
                                        <div className="col-md-4">
                                            <p>Current Address</p>
                                            {/* <p>{this.state.CurrentAddress}</p> */}
                                            {this.state.isGeoAddressVisible &&
                                                <p><textarea id="geoAddress" name="GeoLocationAddress" rows="4" value={this.state.CurrentAddress} onChange={this.onChangeGeoCurrentAddress} className="form-control" placeholder=""></textarea></p>
                                                // <input type="text" id="geoAddress" name="GeoLocationAddress" value={this.state.CurrentAddress} onChange={this.onChangeGeoCurrentAddress} className="form-control" placeholder="" />
                                            }
                                            {this.state.GeoAddressErrMsg && <span className="text-danger">{this.state.GeoAddressErrMsg === 'required' ? 'Enter Address' : ''}</span>}
                                        </div>
                                    </div>




                                </div>
                                <div className="modal-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">Parking Location <span className="text-danger">*</span> </label>
                                                <Select isClearable={true} options={this.state.parkinglocations} value={this.state.ParkingLocationID} onChange={this.onParkingLocationChange} />
                                                {this.state.ParkingLocationErrMsg && <span className="text-danger">{this.state.ParkingLocationErrMsg === 'required' ? '*' : ''}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6"></div>
                                        <div className="col-md-6">
                                            <div className="row" style={{ marginLeft: '0' }}>
                                                <p style={{ color: '#2b7acc' }}>Odometer</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">Odometer of Leaving Parking <span className="text-danger">*</span></label>
                                                        <input type="text" id="VehicleLeaveParkingOdometer" name="VehicleLeaveParkingOdometer" required value={this.state.VehicleLeaveParkingOdometer} onChange={(e) => this.setState({ VehicleLeaveParkingOdometer: e.target.value }, () => { this.forms.validateFields(e.target) })} onKeyPress={this.allowOnlyNumbers} className="form-control" placeholder="" />
                                                        <FieldFeedbacks for="VehicleLeaveParkingOdometer">
                                                            <FieldFeedback when="*"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-7">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">Odometer of Vehicle Reaching Work Location <span className="text-danger">*</span></label>
                                                        <input className="form-control" name="VehicleReachedLocationOdometer" required onKeyPress={this.allowOnlyNumbers} type="text" value={this.state.VehicleReachedLocationOdometer} onChange={this.onChangeVehicleReachedLocationOdometer} id="example-time-input" />
                                                        <FieldFeedbacks for="VehicleReachedLocationOdometer">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                        {this.state.VehicleReachedLocationOdometerErrMsg && <span className="text-danger">{this.state.VehicleReachedLocationOdometerErrMsg === 'greater' ? 'Should be greater than Leaving Parking' : ''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-7">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">Jetting Starting Meter Reading <span className="text-danger">*</span></label>
                                                        <input type="text" name="JettingStartMeterReading" required value={this.state.JettingStartMeterReading} onChange={this.onChangeJettingStartMeterReading} onKeyPress={this.allowOnlyNumbersGem} className="form-control" placeholder="" />
                                                        <span class="form-text text-muted">For Example:
                                                            <code>150:25</code></span>
                                                        <FieldFeedbacks for="JettingStartMeterReading">
                                                            <FieldFeedback when="*"></FieldFeedback>
                                                            <FieldFeedback when={value => !/^[0-9]{1,6}[:]{1}[0-9]{1,4}$/.test(value)}>Invalid Jetting Starting Meter</FieldFeedback>
                                                        </FieldFeedbacks>
                                                        {this.state.JettingStartMeterReadingErrMsg && <span className="text-danger">{this.state.JettingStartMeterReadingErrMsg === 'greater' ? 'Should be greater than jetting ending' : ''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">Suction Pump Starting Meter Reading <span className="text-danger">*</span></label>
                                                        <input type="text" name="SuctionPumpStartMeterReading" required value={this.state.SuctionPumpStartMeterReading} onChange={this.onChangeSuctionPumpStartMeterReading} onKeyPress={this.allowOnlyNumbersGem} className="form-control" placeholder="" />
                                                        <span class="form-text text-muted">For Example:
                                                            <code>150:25</code></span>
                                                        <FieldFeedbacks for="SuctionPumpStartMeterReading">
                                                            <FieldFeedback when="*"></FieldFeedback>
                                                            <FieldFeedback when={value => !/^[0-9]{1,6}[:]{1}[0-9]{1,4}$/.test(value)}>Invalid Suction Pump Starting Meter</FieldFeedback>
                                                        </FieldFeedbacks>
                                                        {this.state.SuctionPumpStartMeterReadingErrMsg && <span className="text-danger">{this.state.SuctionPumpStartMeterReadingErrMsg === 'greater' ? 'Should be greater than suction pump ending' : ''}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row" style={{ marginLeft: '0' }}>
                                                <p style={{ color: '#2b7acc' }}>Time</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">Vehicle Leaving Parking Time <span className="text-danger">*</span></label>
                                                        <input className="form-control" name="VehicleLeaveParkingTime" required type="text" value={this.state.mobVehicleLeaveParkingTime} onChange={this.onChangeVehicleLeaveParkingTime} id="example-time-input" />
                                                        <span class="form-text text-muted">For Example:
                                                            <code>13-01-2023 08:00</code></span>
                                                        <FieldFeedbacks for="VehicleLeaveParkingTime">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                        {this.state.StartVehicleLeaveParkingTimeErrMsg && <span className="text-danger">{this.state.StartVehicleLeaveParkingTimeErrMsg === 'required' ? '*' : ''}</span>}
                                                        {this.state.VehicleLeaveParkingTimeFormatErrMsg && <span className="text-danger">{this.state.VehicleLeaveParkingTimeFormatErrMsg === 'invalid' ? 'Please Enter Valid Input Date Format' : ''}</span>}
                                                    </div>
                                                </div>




                                                <div className="col-md-12 ">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">Vehicle Reaching Location Time <span className="text-danger">*</span></label>
                                                        <input className="form-control" name="VehicleReachedLocationTime" required type="text" value={this.state.FullVehicleReachedLocationTime} onChange={this.onChangeVehicleReachedLocationTime} id="example-time-input" />
                                                        <span class="form-text text-muted">For Example:
                                                            <code>13-01-2023 16:00</code></span>
                                                        <FieldFeedbacks for="VehicleReachedLocationTime">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                        {this.state.VehicleReachedLocationTimeErrMsg && <span className="text-danger">{this.state.VehicleReachedLocationTimeErrMsg === 'greater' ? 'Should be greater than Leaving Parking' : ''}<br /></span>}
                                                        {this.state.VehicleReachedParkingTimeFormatErrMsg && <span className="text-danger">{this.state.VehicleReachedParkingTimeFormatErrMsg === 'invalid' ? 'Please Enter Valid Input Date Format' : ''}</span>}
                                                    </div>
                                                </div>


                                                {/* <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputPassword1">
                                                        </label>

                                                        <button type="button" onClick={this.setGeoLocation} className="btn btn-primary" style={{ width: '100%', marginTop: '6%' }}>Get GPS Location</button>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="mobbottom" htmlFor="exampleInputPassword1" >Capture
                                                            Details </label>
                                                        <input type="file" value={this.state.TaskImages} style={{ height: "37px" }} multiple onChange={this.OnTaskImageUpload} className="form-control" accept="image/png, image/gif, image/jpeg" />
                                                        {this.state.TaskImageErrMsg && <span className="text-danger">{this.state.TaskImageErrMsg === 'required' ? '*' : ''}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="mobbottom" htmlFor="exampleInputPassword1">Check List <span className="text-danger">*</span></label>
                                                <div className="checkbox-inline">
                                                    <div className="row" style={{ width: '100%' }}>
                                                        <div className="col-md-2">
                                                            <label className="checkbox" style={{ marginBottom: '10%' }}>
                                                                <input checked={this.state.isCheckListChecked} style={{ marginRight: 0 }} value="Select All" type="checkbox" onChange={this.onCheckListAllChange} />
                                                                <span />Select All
                                                            </label>
                                                        </div>
                                                        {
                                                            this.state.checklists.map((item, index) => (
                                                                <div className="col-md-2" key={index}>
                                                                    <label className="checkbox" key={index} style={{ marginBottom: '10%' }}>

                                                                        <input checked={item.isCheckListChecked} style={{ marginRight: 0 }} value={item.id} type="checkbox" onChange={this.onCheckListChange} />
                                                                        <span />{item.value}

                                                                    </label>
                                                                </div>
                                                            ))
                                                        }

                                                    </div>
                                                </div>
                                                {this.state.CheckListErrMsg && <span className="text-danger">{this.state.CheckListErrMsg === 'required' ? '*' : ''}</span>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="submit" onSubmit={this.OnStartTaskSubmit} className="btn btn-primary font-weight-bold">
                                        Submit
                                    </button>
                                    <button type="button" onClick={this.cancelStart} className="btn btn-light-danger font-weight-bold" data-dismiss="modal">
                                        Cancel
                                    </button>
                                </div>
                            </FormWithConstraints>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.FinishModal = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog startmodaldialog" role="document">
                        <div className="modal-content">
                            <FormWithConstraints
                                ref={form => this.finishform = form}
                                onSubmit={this.OnFinishTaskSubmit}
                                noValidate>
                                <div className="modal-header">
                                    <div className="col-md-2">
                                        <h5 className="modal-title" id="exampleModalLabel">
                                            Finish Task
                                        </h5>
                                    </div>
                                    <div className="col-md-3">
                                        <p>Current Latitude</p>
                                        {/* <p>{this.state.BookingFinishLatitude}</p> */}
                                        {this.state.isBookingFinishLatitudeVisible &&
                                            <p><input type="text" id="bookingFinishGeoLatitude" name="bookingFinishGeoLatitude" value={this.state.BookingFinishLatitude} onChange={this.onChangeBookingFinishGeoLatitude} onKeyPress={this.allowOnlyNumbersDot} className="form-control" placeholder="" maxLength={15} /></p>
                                        }
                                        {this.state.BookingFinishLatitudeErrMsg && <span className="text-danger">{this.state.BookingFinishLatitudeErrMsg === 'required' ? 'Enter Latitude' : ''}</span>}
                                    </div>
                                    <div className="col-md-3">
                                        <p>Current Longitude</p>
                                        {/* <p>{this.state.BookingFinishLongitude}</p> */}
                                        {this.state.isBookingFinishLongitudeVisible &&
                                            <p><input type="text" id="bookingFinishGeoLongitude" name="bookingFinishGeoLongitude" value={this.state.BookingFinishLongitude} onChange={this.onChangeBookingFinishGeoLongitude} onKeyPress={this.allowOnlyNumbersDot} className="form-control" placeholder="" maxLength={15} /></p>
                                        }
                                        {this.state.BookingFinishLongitudeErrMsg && <span className="text-danger">{this.state.BookingFinishLongitudeErrMsg === 'required' ? 'Enter Longitudes' : ''}</span>}
                                    </div>
                                    <div className="col-md-4">
                                        <p>Current Address</p>
                                        {/* <p>{this.state.BookingFinishAddress}</p> */}
                                        {this.state.isBookingFinishAddressVisible &&
                                            <p><textarea id="bookingFinishGeoAddress" name="bookingFinishGeoAddress" rows="4" value={this.state.BookingFinishAddress} onChange={this.onChangeBookingFinishGeoCurrentAddress} className="form-control" placeholder=""></textarea></p>
                                        }
                                        {this.state.BookingFinishAddressErrMsg && <span className="text-danger">{this.state.BookingFinishAddressErrMsg === 'required' ? 'Enter Address' : ''}</span>}
                                    </div>

                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row" style={{ marginLeft: '0' }}>
                                                <p style={{ color: '#2b7acc' }}>Odometer</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row" style={{ marginLeft: '0' }}>
                                                <p style={{ color: '#2b7acc' }}>Time</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} className="mobbottom" htmlFor="exampleInputPassword1">Odometer of Vehicle Leaving Work Location <span className="text-danger">*</span></label>
                                                        <input className="form-control" name="VehicleReturnLocationOdometer" required type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.VehicleReturnLocationOdometer} onChange={this.onChangeVehicleReturnLocationOdometer} id="example-time-input" />
                                                        <FieldFeedbacks for="VehicleReturnLocationOdometer">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                        {this.state.VehicleReachedLocationOdometerErrMsg && <span className="text-danger">{this.state.VehicleReachedLocationOdometerErrMsg === 'greater' ? 'Should be greater than reached location' : ''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc', marginBottom: '9%' }} htmlFor="exampleInputPassword1">Odometer Reaching Parking Location <span className="text-danger">*</span></label>
                                                        <input name="VehicleReachedParkingOdometer" required className="form-control" type="text" value={this.state.VehicleReachedParkingOdometer} onChange={this.onChangeVehicleReachedParkingOdometer} onKeyPress={this.allowOnlyNumbers} id="example-time-input" />
                                                        <FieldFeedbacks for="VehicleReachedParkingOdometer">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                        {this.state.VehicleReachedParkingOdometerErrMsg && <span className="text-danger">{this.state.VehicleReachedParkingOdometerErrMsg === 'greater' ? 'Should be greater than Reaching Parking' : ''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mt-7">
                                                        <label style={{ color: '#2b7acc' }} className="mobbottom" htmlFor="exampleInputPassword1">Jetting Pump Finish Meter Reading <span className="text-danger">*</span></label>
                                                        <input name="JettingFinishMeterReading" required className="form-control" type="text" onKeyPress={this.allowOnlyNumbersGem} value={this.state.JettingFinishMeterReading} onChange={this.onChangeJettingFinishMeterReading} id="example-time-input" />
                                                        <span class="form-text text-muted">For Example:
                                                            <code>150:25</code></span>
                                                        <FieldFeedbacks for="JettingFinishMeterReading">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                            {/* <FieldFeedback when={value => !/[0-9]+:{1}[0-9]+/.test(value)}>Invalid Jetting Jump</FieldFeedback> */}
                                                            <FieldFeedback when={value => !/^[0-9]{1,6}[:]{1}[0-9]{1,4}$/.test(value)}>Invalid Jetting Pump</FieldFeedback>
                                                        </FieldFeedbacks>
                                                        {this.state.JettingFinishMeterReadingErrMsg && <span className="text-danger">{this.state.JettingFinishMeterReadingErrMsg === 'greater' ? 'Should be greater than jetting starting' : ''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mt-7">
                                                        <label style={{ color: '#2b7acc' }} htmlFor="exampleInputPassword1">Suction Pump Finish Meter Reading <span className="text-danger">*</span></label>
                                                        <input name="SuctionPumpFinishMeterReading" required className="form-control" type="text" onKeyPress={this.allowOnlyNumbersGem} value={this.state.SuctionPumpFinishMeterReading} onChange={this.onChangeSuctionFinishMeterReading} id="example-time-input" />
                                                        <span class="form-text text-muted">For Example:
                                                            <code>150:25</code></span>
                                                        <FieldFeedbacks for="SuctionPumpFinishMeterReading">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                            <FieldFeedback when={value => !/^[0-9]{1,6}[:]{1}[0-9]{1,4}$/.test(value)}>Invalid Suction Pump</FieldFeedback>
                                                        </FieldFeedbacks>
                                                        {this.state.SuctionPumpFinishMeterReadingErrMsg && <span className="text-danger">{this.state.SuctionPumpFinishMeterReadingErrMsg === 'greater' ? 'Should be greater than suction starting' : ''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} htmlFor="exampleInputPassword1">Drainage line 150 mm to 300 mm dia meter</label>
                                                        <input name="DrainageLine150to300mmDiaMeter" className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.DrainageLine150to300mmDiaMeter} onChange={(e) => { this.setState({ DrainageLine150to300mmDiaMeter: e.target.value }) }} id="example-time-input" />

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} htmlFor="exampleInputPassword1">Drainage line 301 mm to 600 mm dia meter</label>
                                                        <input name="DrainageLine301to600mmDiaMeter" className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.DrainageLine301to600mmDiaMeter} onChange={(e) => { this.setState({ DrainageLine301to600mmDiaMeter: e.target.value }) }} id="example-time-input" />

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} htmlFor="exampleInputPassword1">Drainage line 601 mm to 900 mm dia meter</label>
                                                        <input name="DrainageLine601to900mmDiaMeter" className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.DrainageLine601to900mmDiaMeter} onChange={(e) => { this.setState({ DrainageLine601to900mmDiaMeter: e.target.value }) }} id="example-time-input" />

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} htmlFor="exampleInputPassword1">Drainage line 901 mm to 1200 mm dia meter</label>
                                                        <input name="DrainageLine901to1200mmDiaMeter" className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.DrainageLine901to1200mmDiaMeter} onChange={(e) => { this.setState({ DrainageLine901to1200mmDiaMeter: e.target.value }) }} id="example-time-input" />


                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} htmlFor="exampleInputPassword1">Shift <span className="text-danger">*</span></label>
                                                        <Select isClearable={true} options={this.state.ShiftData} value={this.state.ShiftID} onChange={this.onShiftChange} />
                                                        {this.state.ShiftErrMsg && <span className="text-danger">{this.state.ShiftErrMsg === 'required' ? 'Please select Shift' : ''}</span>}
                                                        {/* <input name="Shift" required className="form-control" type="text" value={this.state.Shift} onChange={(e) => { this.setState({ Shift: e.target.value }, () => { this.finishform.validateFields(e.target) }) }} id="example-time-input" placeholder="Enter Shift" />
                                                        <FieldFeedbacks for="Shift">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} className="mobbottom" htmlFor="exampleInputPassword1">Total number of drainage line <span className="text-danger">*</span></label>
                                                        <input name="TotalNoOfDranageLine" required className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.TotalNoOfDranageLine} onChange={(e) => { this.setState({ TotalNoOfDranageLine: e.target.value }, () => { this.finishform.validateFields(e.target) }) }} id="example-time-input" />
                                                        <FieldFeedbacks for="TotalNoOfDranageLine">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} htmlFor="exampleInputPassword1">Upload Multiple Images </label>
                                                        <input type="file" value={this.state.FinishTaskImages} style={{ height: "37px" }} multiple onChange={this.OnFinishTaskImageUpload} className="form-control" accept="image/png, image/gif, image/jpeg" />
                                                        {this.state.FinishTaskImageErrMsg && <span className="text-danger">{this.state.FinishTaskImageErrMsg === 'required' ? 'Please select atleast 1 image' : ''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} className="mobbottom" htmlFor="exampleInputPassword1">No Of Clean Machine Hole </label>
                                                        <input name="NoOfCleanMachineHole" className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.NoOfCleanMachineHole} onChange={(e) => { this.setState({ NoOfCleanMachineHole: e.target.value }, () => { this.finishform.validateFields(e.target) }) }} id="example-time-input" />

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">


                                                {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} htmlFor="exampleInputPassword1">Vehicle Leaving Work Location Time </label>
                                                        <input className="form-control" name="VehicleReturnLocationTime" required type="text" value={this.state.VehicleReturnLocationTime} onChange={this.onChangeVehicleReturnLocationTime} id="example-time-input" />
                                                        <FieldFeedbacks for="VehicleReturnLocationTime">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                        {this.state.VehicleReturnLocationTimeErrMsg&&<span className="text-danger">{this.state.VehicleReturnLocationTimeErrMsg==='greater'? 'Should be greater than reaching time':''}</span>}
                                                    </div>
                                                </div> */}


                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc', marginBottom: '9%' }} className="mobbottom" htmlFor="exampleInputPassword1">Vehicle Leaving Work Location Time <span className="text-danger">*</span> </label>
                                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateTimePicker']}>
                                                             
                                                                <MobileDateTimePicker format="DD-MM-YYYY HH:mm:ss" ampm={false} id="VehicleReturnLocationTime" name="VehicleReturnLocationTime" maxDate={dayjs(new Date())} onChange={this.onChangeVehicleReturnLocationTime} value={dayjs(this.state.mobVehicleReturnLocationTime)} />
                                                            </DemoContainer>
                                                        </LocalizationProvider> */}
                                                        <input className="form-control" name="VehicleReturnLocationTime" required type="text" value={this.state.mobVehicleReturnLocationTime} onChange={this.onChangeVehicleReturnLocationTime} id="example-time-input" />
                                                        <span class="form-text text-muted">For Example:
                                                            <code>13-01-2023 16:00</code></span>
                                                        <FieldFeedbacks for="VehicleReturnLocationTime">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                        {this.state.VehicleReturnLocationTimeErrMsg && <span className="text-danger">{this.state.VehicleReturnLocationTimeErrMsg === 'greater' ? 'Should be greater than reaching time' : ''}</span>}
                                                        {this.state.VehicleReturnLocationTimeFormatErrMsg && <span className="text-danger">{this.state.VehicleReturnLocationTimeFormatErrMsg === 'invalid' ? 'Please Enter Valid Input Date Format' : ''}</span>}
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc', marginBottom: '9%' }} className="mobbottom" htmlFor="exampleInputPassword1">Vehicle Reaching Parking Location Time <span className="text-danger">*</span></label>
                                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateTimePicker']}>
                                                                
                                                                <MobileDateTimePicker format="DD-MM-YYYY HH:mm:ss" ampm={false} id="VehicleReachedParkingTime" name="VehicleReachedParkingTime" maxDate={dayjs(new Date())} onChange={this.onChangeVehicleReachedParkingTime} value={dayjs(this.state.mobVehicleReachedParkingTime)} />
                                                            </DemoContainer>
                                                        </LocalizationProvider> */}
                                                        <input className="form-control" name="VehicleReachedParkingTime" required type="text" value={this.state.mobVehicleReachedParkingTime} onChange={this.onChangeVehicleReachedParkingTime} id="example-time-input" />
                                                        <span class="form-text text-muted">For Example:
                                                            <code>13-01-2023 16:00</code></span>
                                                        {/* <FieldFeedbacks for="VehicleReachedParkingTime">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks> */}
                                                        {this.state.VehicleReachedParkingTimeErrMsg && <span className="text-danger">{this.state.VehicleReachedParkingTimeErrMsg === 'greater' ? 'Should be greater than leaving time' : ''}</span>}
                                                        {this.state.VehicleReachedParkingTimeFormatErrMsg && <span className="text-danger">{this.state.VehicleReachedParkingTimeFormatErrMsg === 'invalid' ? 'Please Enter Valid Input Date Format' : ''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button isDisabled={true} hidden={true} type="button" onClick={this.setFinishGeoLocation} className="btn btn-outline-primary font-weight-bold mt-8" style={{ width: '100%' }}>
                                                        Get GPS Location
                                                    </button>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc', marginTop: '0', marginBottom: '0.5rem' }} className="mobbottom" htmlFor="exampleInputPassword1">Jetting system <span className="text-danger">*</span></label>
                                                        <input name="JettingSystem" required className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.JettingSystem} onChange={(e) => { this.setState({ JettingSystem: e.target.value }, () => { this.finishform.validateFields(e.target) }) }} id="example-time-input" />
                                                        <FieldFeedbacks for="JettingSystem">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc', marginTop: '0', marginBottom: '0.5rem' }} className="mobbottom" htmlFor="exampleInputPassword1">Suction system <span className="text-danger">*</span></label>
                                                        <input name="SuctionSystem" required className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.SuctionSystem} onChange={(e) => { this.setState({ SuctionSystem: e.target.value }, () => { this.finishform.validateFields(e.target) }) }} id="example-time-input" />
                                                        <FieldFeedbacks for="SuctionSystem">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mt-7">
                                                        <label style={{ color: '#2b7acc', marginBottom: '9%' }} htmlFor="exampleInputPassword1">Suction hose 5 diameter - 3 meter long <span className="text-danger">*</span></label>
                                                        <input name="SuctionHose5Diameter3MeterLong" required className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.SuctionHose5Diameter3MeterLong} onChange={(e) => { this.setState({ SuctionHose5Diameter3MeterLong: e.target.value }, () => { this.finishform.validateFields(e.target) }) }} id="example-time-input" />
                                                        <FieldFeedbacks for="SuctionHose5Diameter3MeterLong">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mt-7">
                                                        <label style={{ color: '#2b7acc', marginBottom: '9%' }} htmlFor="exampleInputPassword1">Jetting hose - 32 m.m. dia, 120 m. long <span className="text-danger">*</span></label>
                                                        <input name="JettingHose32Diameter120MeterLong" required className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.JettingHose32Diameter120MeterLong} onChange={(e) => { this.setState({ JettingHose32Diameter120MeterLong: e.target.value }, () => { this.finishform.validateFields(e.target) }) }} id="example-time-input" />
                                                        <FieldFeedbacks for="JettingHose32Diameter120MeterLong">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc', marginBottom: '9%' }} htmlFor="exampleInputPassword1">Jetting nozzle: De
                                                            Chocking nozzle <span className="text-danger">*</span></label>
                                                        <input name="JettingNozzleDeChockingNozzle" required className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.JettingNozzleDeChockingNozzle} onChange={(e) => { this.setState({ JettingNozzleDeChockingNozzle: e.target.value }, () => { this.finishform.validateFields(e.target) }) }} id="example-time-input" />
                                                        <FieldFeedbacks for="JettingNozzleDeChockingNozzle">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc', marginBottom: '9%' }} htmlFor="exampleInputPassword1">De Silting nozzle - flat nozzle <span className="text-danger">*</span></label>
                                                        <input name="DeSiltingNozzleFlatNozzle" required className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.DeSiltingNozzleFlatNozzle} onChange={(e) => { this.setState({ DeSiltingNozzleFlatNozzle: e.target.value }, () => { this.finishform.validateFields(e.target) }) }} id="example-time-input" />
                                                        <FieldFeedbacks for="DeSiltingNozzleFlatNozzle">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} htmlFor="exampleInputPassword1">Balance nozzle bomb nozzle <span className="text-danger">*</span></label>
                                                        <input name="BalanceNozzleBombNozzle" required className="form-control" type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.BalanceNozzleBombNozzle} onChange={(e) => { this.setState({ BalanceNozzleBombNozzle: e.target.value }, () => { this.finishform.validateFields(e.target) }) }} id="example-time-input" />
                                                        <FieldFeedbacks for="BalanceNozzleBombNozzle">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>

                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} htmlFor="exampleInputPassword1">Remarks</label>
                                                        {/* <input name="BookingFinishRemarks" className="form-control" type="text" value={this.state.BookingFinishRemarks} onChange={(e) => { this.setState({ BookingFinishRemarks: e.target.value })}}  /> */}
                                                        <textarea name="BookingFinishRemarks" className="form-control" rows={3} defaultValue={this.state.BookingFinishRemarks} onChange={(e) => { this.setState({ BookingFinishRemarks: e.target.value }) }} />

                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} className="mobbottom" htmlFor="exampleInputPassword1">Lunch Start Time </label>

                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['TimePicker']}>
                                                                <TimePicker ampm={false}
                                                                    onChange={this.onChangeLunchStartTime} value={dayjs(this.state.LunchStartTime)} style={{ width: '100%' }} className="form-control" />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                        {this.state.LunchStartTimeErrMsg && <span className="text-danger">{this.state.LunchStartTimeErrMsg === 'required' ? 'Please Enter Start Time' : ''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label style={{ color: '#2b7acc' }} className="mobbottom" htmlFor="exampleInputPassword1">Lunch End Time </label>

                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['TimePicker']}>
                                                                <TimePicker ampm={false}
                                                                    onChange={this.onChangeLunchEndTime} value={dayjs(this.state.LunchEndTime)} style={{ width: '100%' }} className="form-control" />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                        {this.state.LunchEndTimeErrMsg && <span className="text-danger">{this.state.LunchEndTimeErrMsg === 'greater' ? 'Should be greater than Lunch Start Time' : ''}<br /></span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" onSubmit={this.OnFinishTaskSubmit} className="btn btn-primary font-weight-bold">
                                        Finish
                                    </button>
                                    <button type="button" onClick={this.cancelFinish} className="btn btn-light-danger font-weight-bold" data-dismiss="modal">
                                        Cancel
                                    </button>
                                </div>
                            </FormWithConstraints>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.CompleteModal = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog startmodaldialog" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <div className="col-md-2">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Complete Task
                                    </h5>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="row">

                                    <FormWithConstraints
                                        ref={form => this.completeforms = form}
                                        onSubmit={this.OnCompleteTaskSubmit}
                                        noValidate>
                                        <div className="row" >
                                            <div className="col-md-12">
                                                <hr style={{ marginTop: 5, borderTop: '1px solid black' }} />
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Remarks <span className="text-danger">*</span> </label>
                                                    <textarea name="BookingCompleteRemarks" value={this.state.BookingCompleteRemarks} required className="form-control" rows={3} defaultValue={this.state.BookingCompleteRemarks} onChange={(e) => { this.setState({ BookingCompleteRemarks: e.target.value }, () => { this.completeforms.validateFields(e.target) }) }} />
                                                    <FieldFeedbacks for="BookingCompleteRemarks">
                                                        <FieldFeedback when="valueMissing">
                                                        </FieldFeedback>
                                                    </FieldFeedbacks>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Remarks in Gujarati</label>
                                                    <textarea name="BookingCompleteRemarksGujarati" value={this.state.BookingCompleteRemarksGujarati} className="form-control" rows={3} defaultValue={this.state.BookingCompleteRemarksGujarati} onChange={(e) => { this.setState({ BookingCompleteRemarksGujarati: e.target.value }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Tender liquidity damages condition</label>
                                                    <textarea name="TenderLiquidityDamagesCondition" value={this.state.TenderLiquidityDamagesCondition} className="form-control" rows={3} defaultValue={this.state.TenderLiquidityDamagesCondition} onChange={(e) => { this.setState({ TenderLiquidityDamagesCondition: e.target.value }) }} />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Log Sheet No</label>
                                                    <input type="text" name="LogSheetNo" className="form-control"
                                                        value={this.state.LogSheetNo} defaultValue={this.state.LogSheetNo} onChange={(e) => { this.setState({ LogSheetNo: e.target.value }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Location Detail</label>
                                                    <input type="text" name="LocationDetail" value={this.state.LocationDetail} className="form-control" defaultValue={this.state.LocationDetail} onChange={(e) => { this.setState({ LocationDetail: e.target.value }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Engineer Name</label>
                                                    <input type="text" name="EngineerName" value={this.state.EngineerName} className="form-control" defaultValue={this.state.EngineerName} onChange={(e) => { this.setState({ EngineerName: e.target.value }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Designation</label>
                                                    <input type="text" name="EngineerDesignation" value={this.state.EngineerDesignation} className="form-control" defaultValue={this.state.EngineerDesignation} onChange={(e) => { this.setState({ EngineerDesignation: e.target.value }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Machine did not work From Time</label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['TimePicker']}>
                                                            <TimePicker ampm={false} name="MachineNotWorkFromTime"
                                                                onChange={this.onChangeMachineNotWorkFromTime} value={dayjs(this.state.MachineNotWorkFromTime)} style={{ width: '100%' }} className="form-control" />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                    {this.state.MachineNotWorkFromTimeErrMsg && <span className="text-danger">{this.state.MachineNotWorkFromTimeErrMsg === 'required' ? 'Please Enter Machine did not work From Time' : ''}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Machine did not work To Time</label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['TimePicker']}>
                                                            <TimePicker ampm={false} name="MachineNotWorkToTime"
                                                                onChange={this.onChangeMachineNotWorkToTime} value={dayjs(this.state.MachineNotWorkToTime)} style={{ width: '100%' }} className="form-control" />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                    {this.state.MachineNotWorkToTimeErrMsg && <span className="text-danger">{this.state.MachineNotWorkToTimeErrMsg === 'greater' ? 'Should be greater than Machine did not work From Time' : ''}<br /></span>}
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Machine did not work Total Time</label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['TimePicker']}>
                                                            <TimePicker ampm={false} name="MachineNotWorkTotalTime"
                                                                onChange={this.onChangeMachineNotWorkTotalTime} value={dayjs(this.state.MachineNotWorkTotalTime)} style={{ width: '100%' }} className="form-control" />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                            </div> */}
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Extraordinary Details</label>
                                                    <textarea name="ExtraordinaryDetails" value={this.state.ExtraordinaryDetails} className="form-control" rows={3} defaultValue={this.state.ExtraordinaryDetails} onChange={(e) => { this.setState({ ExtraordinaryDetails: e.target.value }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Extraordinary Area</label>
                                                    <input type="text" name="ExtraordinaryArea" value={this.state.ExtraordinaryArea} className="form-control" defaultValue={this.state.ExtraordinaryArea} onChange={(e) => { this.setState({ ExtraordinaryArea: e.target.value }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Machine Holes</label>
                                                    <input type="text" name="ExtraordinaryMachineHoles" value={this.state.ExtraordinaryMachineHoles} className="form-control" defaultValue={this.state.ExtraordinaryMachineHoles} onChange={(e) => { this.setState({ ExtraordinaryMachineHoles: e.target.value }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Sludge Disposal Area Allocated By Municipality</label>
                                                    <ul className="radio-ul">
                                                        <ol className="radio-ol">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value="Yes"
                                                                    checked={this.state.SludgeDisposalArea === 'Yes'}
                                                                    onChange={this.onSludgeDisposalAreaCheckboxChange}
                                                                />
                                                                <span>&nbsp;Yes</span>
                                                            </label>
                                                        </ol>
                                                        <ol className="radio-ol">
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    value="No"
                                                                    checked={this.state.SludgeDisposalArea === 'No'}
                                                                    onChange={this.onSludgeDisposalAreaCheckboxChange}
                                                                />
                                                                <span>&nbsp;No</span>
                                                            </label>
                                                        </ol>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Upload Log Sheet Image<span className="text-danger">*</span></label>
                                                    <input type="file" name="LogSheetImage" value={this.state.LogSheetImage} style={{ height: "37px" }} multiple onChange={this.OnCompleteTaskImageUpload} className="form-control" accept="image/png, image/gif, image/jpeg" />
                                                    {this.state.CompleteTaskErrImage && <span className="text-danger">{this.state.CompleteTaskErrImage === 'required' ? 'Please upload log sheet image' : ''}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Capture Log Sheet Image</label>
                                                    <br />
                                                    <button type="button" className="btn btn-light-primary font-weight-bold" style={{ width: '100%' }}>
                                                        Capture Image
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{ textAlign: 'right' }}>
                                                <button type="submit" onSubmit={this.OnCompleteTaskSubmit} className="btn btn-light-primary  font-weight-bold">
                                                    Complete
                                                </button>
                                                <button type="button" onClick={this.cancelComplete} className="btn btn-light-danger font-weight-bold ml-4" data-dismiss="modal">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </FormWithConstraints>
                                </div>
                            </div>
                            {/* <div className="modal-footer">

                            </div> */}

                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.ApprovedModal = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog startmodaldialog" role="document">
                        <div className="modal-content">
                            <FormWithConstraints
                                ref={form => this.form = form}
                                onSubmit={this.OnApprovedTaskSubmit}
                                noValidate>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Approval Task
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">LogSheet Image</label><br />
                                                <a href={process.env.REACT_APP_BOOKINGIMAGE_URL + this.state.TempLogsheetImage} target="_blank">
                                                    <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + this.state.TempLogsheetImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + this.state.TempLogsheetImage}></img>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">Status<span className="text-danger">*</span></label>
                                                <Select isDisabled={true} isClearable={true} options={this.state.BookingApprovals} value={this.state.BookingApprovalStatus} onChange={this.onBookingApprovalChange} />
                                                {this.state.BookingApprovalStatusErrMsg && <span className="text-danger">{this.state.BookingApprovalStatusErrMsg === 'required' ? '*' : ''}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">Remarks<span className="text-danger">*</span></label>
                                                <textarea className="form-control" name="BookingApprovalRemarks" required rows={3} defaultValue={this.state.BookingApprovalRemarks} onChange={(e) => { this.setState({ BookingApprovalRemarks: e.target.value }, () => { this.form.validateFields(e.target) }) }} />
                                                <FieldFeedbacks for="BookingApprovalRemarks">
                                                    <FieldFeedback when="valueMissing">
                                                        *
                                                    </FieldFeedback>
                                                </FieldFeedbacks>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-8" style={{ display: 'none' }}>
                                            <div className="form-group">
                                                <button type="button" className="btn btn-primary font-weight-bold" onClick={this.BindMapData(this.state.BookingID, this.state.BookingTaskDetailID, this.state.Tempvehicleno, this.state.TempVehicleLeaveParkingDateTime, this.state.TempVehicleReachedLocationDateTime, this.state.TempVehicleReturnLocationDateTime, this.state.TempVehicleReachedParkingDateTime)}>
                                                    GPS Tracking
                                                </button>
                                                <button type="button" className="btn btn-primary font-weight-bold ml-5" onClick={this.GetImages(this.state.BookingID, this.state.BookingTaskDetailID, this.state.Tempvehicleno, this.state.TempVehicleLeaveParkingDateTime, this.state.TempVehicleReachedLocationDateTime, this.state.TempVehicleReturnLocationDateTime, this.state.TempVehicleReachedParkingDateTime)}>
                                                    CCD Tracking
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" onSubmit={this.OnApprovedTaskSubmit} className="btn btn-primary font-weight-bold">
                                        Submit
                                    </button>
                                    <button type="button" onClick={this.onCancelApproval} className="btn btn-light-danger font-weight-bold" data-dismiss="modal">
                                        Cancel
                                    </button>
                                </div>
                            </FormWithConstraints>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.ReferredAssignModal = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Assign Task (Referred)
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Date</label>
                                            <DatePicker isClearable={true} dateFormat="dd-MM-yyyy" selected={this.state.RefferBackAssignDate} minDate={new Date()} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.RefferBackAssignDate} onChange={this.OnReferredBackChange} />
                                        </div>
                                    </div>
                                    {this.state.IsReferredAssignDataVisible &&
                                        <>
                                            <div className="col-md-11">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Vehicle </label>
                                                    <Select isClearable={true} options={this.state.vehicles} value={this.state.VehicleID} onChange={this.onVehicleChange} />
                                                    {this.state.VehicleErrMsg && <span className="text-danger">{this.state.VehicleErrMsg === 'required' ? '*' : ''}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ display: 'flex', alignItems: 'center' }}>
                                                <i className="fa fa-calendar-alt " onClick={this.GetVehicleBookedModal}>
                                                </i>
                                            </div>
                                            <div className="col-md-11">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Driver </label>
                                                    <Select isClearable={true} options={this.state.drivers} value={this.state.DriverID} onChange={this.onDriverChange} />
                                                    {this.state.DriverErrMsg && <span className="text-danger">{this.state.DriverErrMsg === 'required' ? '*' : ''}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ display: 'flex', alignItems: 'center' }}>
                                                <i className="fa fa-calendar-alt " onClick={this.GetDriverBookedModal}>
                                                </i>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Helper </label>
                                                    <div className="checkbox-inline">
                                                        {
                                                            this.state.HelperDataAvailable &&
                                                            <label className="checkbox" >
                                                                <input checked={this.state.isChecked} style={{ marginRight: 0 }} value="Select All" type="checkbox" onChange={this.onHelperAllChange} />
                                                                <span />Select All
                                                            </label>

                                                        }

                                                        {
                                                            this.state.helpers.map((item, index) => (

                                                                <label className="checkbox" key={index}>

                                                                    <input checked={item.isChecked} style={{ marginRight: 0 }} value={item.id} type="checkbox" onChange={this.onHelperChange} />
                                                                    <span />{item.value}

                                                                </label>
                                                            ))
                                                        }
                                                        {
                                                            this.state.NoHelperVisibleMsg &&
                                                            <label>No Helper Available</label>
                                                        }

                                                    </div>
                                                    {this.state.HelperErrMsg && <span className="text-danger">{this.state.HelperErrMsg === 'required' ? '*' : ''}</span>}
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" onClick={this.handleReferredBackAssign} className="btn btn-primary font-weight-bold">
                                    Submit
                                </button>
                                <button type="submit" onClick={this.cancelReferredBackAssign} className="btn btn-light-danger font-weight-bold" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.VehicleBookedModal = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog startmodaldialog" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    View Booked Vehicle
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    {
                                        this.state.BookedVehicle.map((value, inx1) => {
                                            return (

                                                <div className="col-md-3" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                    <div className="customdate" style={{ padding: '5%' }}>
                                                        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{value.Date}</p>
                                                        {value.BookingAllocateDetail != null ?
                                                            value.BookingAllocateDetail.map((value, ind) => {
                                                                return (
                                                                    <a onClick={this.GetBookingDetailModal(value.BookingNo, value.MuncipalityName, value.WardName, value.Locality, value.VehicleNo)}>
                                                                        <p className="vehicledate"><i className="fa fa-car" style={{ color: 'white', paddingRight: '2%' }}></i>
                                                                            {value.VehicleNo}
                                                                        </p>
                                                                    </a>
                                                                )

                                                            })
                                                            : ''
                                                        }
                                                    </div>
                                                </div>

                                            );
                                        })

                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={this.onCloseBookedVehicleModal} className="btn btn-light-danger font-weight-bold" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.DriverBookedModal = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog startmodaldialog" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    View Booked Driver
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    {
                                        this.state.BookedDriver.map((value, inx1) => {
                                            return (

                                                <div className="col-md-3" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                    <div className="customdate" style={{ padding: '5%' }}>
                                                        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{value.Date}</p>
                                                        {value.BookingAllocateDetail != null ?
                                                            value.BookingAllocateDetail.map((value, ind) => {
                                                                return (
                                                                    <a onClick={this.GetBookingDriverDetailModal(value.BookingNo, value.MuncipalityName, value.WardName, value.Locality, value.DriverFullName)}>
                                                                        <p className="vehicledate"><i className="fa fa-user" style={{ color: 'white', paddingRight: '2%' }}></i>
                                                                            {value.DriverFullName}
                                                                        </p>
                                                                    </a>
                                                                )

                                                            })
                                                            : ''
                                                        }
                                                    </div>
                                                </div>

                                            );
                                        })

                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={this.onCloseBookedDriverModal} className="btn btn-light-danger font-weight-bold" data-dismiss="modal">
                                    Cancel
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.BookingDetailModal = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Detail ({this.state.BookedAssignedvehicleNo})
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <table className="table table-bordered">
                                        <tr>
                                            <th>
                                                Task No
                                            </th>
                                            <th>
                                                Municipality
                                            </th>
                                            <th>
                                                Ward
                                            </th>
                                            <th>
                                                Locality
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>
                                                {this.state.BookedTaskNo}
                                            </td>
                                            <td>
                                                {this.state.BookedMunicipality}
                                            </td>
                                            <td>
                                                {this.state.BookedWard}
                                            </td>
                                            <td>
                                                {this.state.BookedLocality}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.BookingDriverDetailModal = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Detail ({this.state.BookedAssignedDriver})
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <table className="table table-bordered">
                                        <tr>
                                            <th>
                                                Task No
                                            </th>
                                            <th>
                                                Municipality
                                            </th>
                                            <th>
                                                Ward
                                            </th>
                                            <th>
                                                Locality
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>
                                                {this.state.BookedTaskNo}
                                            </td>
                                            <td>
                                                {this.state.BookedMunicipality}
                                            </td>
                                            <td>
                                                {this.state.BookedWard}
                                            </td>
                                            <td>
                                                {this.state.BookedLocality}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.BookingPaymentModal = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <FormWithConstraints
                            ref={form => this.Paymentform = form}
                            onSubmit={this.OnBookingPaymentClick}
                            noValidate>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Payment Details
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Date <span className="text-danger">*</span></label>
                                                {/* <DatePicker isClearable={true} dateFormat="dd-MM-yyyy" selected={this.state.PaymentReceivedDate} minDate={new Date()} autoComplete="off" className="form-control readonly" id="txtPaymentReceivedDate" value={this.state.PaymentReceivedDate} onChange={(e) => {
                                                    this.setState({ PaymentReceivedDate: e }, () => { this.ValidatePaymentReceivedDate() });
                                                }} /> */}
                                                <DatePicker isClearable={true} dateFormat="dd-MM-yyyy" selected={this.state.PaymentReceivedDate} autoComplete="off" className="form-control readonly" id="txtPaymentReceivedDate" value={this.state.PaymentReceivedDate} onChange={(e) => {
                                                    this.setState({ PaymentReceivedDate: e }, () => { this.ValidatePaymentReceivedDate() });
                                                }} />
                                                {this.state.PaymentDateErrMsg && <span className="text-danger">{this.state.PaymentDateErrMsg === 'required' ? 'Please select Date' : ''}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label style={{ color: '#2b7acc' }} className="mobbottom" htmlFor="exampleInputPassword1">Bill No <span className="text-danger">*</span></label>
                                                {/* <input className="form-control" name="BillNo" required type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.BillNo} onChange={(e) => { this.setState({ BillNo: e.target.value }, () => { this.Paymentform.validateFields(e.target) }) }} id="example-time-input" /> */}
                                                <input className="form-control" name="BillNo" required type="text" value={this.state.BillNo} onChange={(e) => { this.setState({ BillNo: e.target.value }, () => { this.Paymentform.validateFields(e.target) }) }} id="example-time-input" />
                                                <FieldFeedbacks for="BillNo">
                                                    <FieldFeedback when="valueMissing"></FieldFeedback>
                                                </FieldFeedbacks>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label style={{ color: '#2b7acc' }} className="mobbottom" htmlFor="exampleInputPassword1">Amount <span className="text-danger">*</span></label>
                                                <input className="form-control" name="PaymentAmount" required type="text" onKeyPress={this.allowOnlyNumbers} value={this.state.PaymentAmount} onChange={(e) => { this.setState({ PaymentAmount: e.target.value }, () => { this.Paymentform.validateFields(e.target) }) }} id="example-time-input" />
                                                <FieldFeedbacks for="PaymentAmount">
                                                    <FieldFeedback when="valueMissing"></FieldFeedback>
                                                </FieldFeedbacks>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" onClick={this.OnBookingPaymentClick} className="btn btn-primary font-weight-bold">
                                        Submit
                                    </button>
                                    <button type="button" onClick={this.cancelBookingPaymentClick} className="btn btn-light-danger font-weight-bold" data-dismiss="modal">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </FormWithConstraints>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.EditAssignmodal = modal} id="exampleModal" data-bs-keyboard="false" data-bs-backdrop="static" role="dialog" aria-labelledby="staticBackdrop">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Assign Task
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-11">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Vehicle </label>
                                            <Select isClearable={true} options={this.state.vehicles} value={this.state.VehicleID} onChange={this.onVehicleChange} />
                                            {this.state.VehicleErrMsg && <span className="text-danger">{this.state.VehicleErrMsg === 'required' ? '*' : ''}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-1" style={{ display: 'flex', alignItems: 'center' }}>
                                        <i className="fa fa-calendar-alt " onClick={this.GetVehicleBookedModal}>
                                        </i>
                                    </div>
                                    <div className="col-md-11">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Driver </label>
                                            <Select isClearable={true} options={this.state.drivers} value={this.state.DriverID} onChange={this.onDriverChange} />
                                            {this.state.DriverErrMsg && <span className="text-danger">{this.state.DriverErrMsg === 'required' ? '*' : ''}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-1" style={{ display: 'flex', alignItems: 'center' }}>
                                        <i className="fa fa-calendar-alt " onClick={this.GetDriverBookedModal}>
                                        </i>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputPassword1">Helper </label>
                                            <div className="checkbox-inline">
                                                {
                                                    this.state.HelperDataAvailable &&
                                                    <label className="checkbox" >
                                                        <input checked={this.state.isChecked} style={{ marginRight: 0 }} value="Select All" type="checkbox" onChange={this.onHelperAllChange} />
                                                        <span />Select All
                                                    </label>

                                                }

                                                {
                                                    this.state.helpers.map((item, index) => (

                                                        <label className="checkbox" key={index}>

                                                            <input checked={item.isChecked} style={{ marginRight: 0 }} value={item.id} type="checkbox" onChange={this.onHelperChange} />
                                                            <span />{item.value}

                                                        </label>
                                                    ))
                                                }
                                                {
                                                    this.state.NoHelperVisibleMsg &&
                                                    <label>No Helper Available</label>
                                                }

                                            </div>
                                            {this.state.HelperErrMsg && <span className="text-danger">{this.state.HelperErrMsg === 'required' ? '*' : ''}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" onClick={this.handleAssign} className="btn btn-primary font-weight-bold">
                                    Submit
                                </button>
                                <button type="button" onClick={this.cancelAssign} className="btn btn-light-danger font-weight-bold">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.EditReferredAssignModal = modal} id="exampleModal" data-bs-keyboard="false" data-bs-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Assign Task (Referred)
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Date</label>
                                            <DatePicker isClearable={true} dateFormat="dd-MM-yyyy" selected={this.state.RefferBackAssignDate} minDate={new Date()} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.RefferBackAssignDate} onChange={this.OnReferredBackChange} />
                                        </div>
                                    </div>
                                    {this.state.IsReferredAssignDataVisible &&
                                        <>
                                            <div className="col-md-11">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Vehicle </label>
                                                    <Select isClearable={true} options={this.state.vehicles} value={this.state.VehicleID} onChange={this.onVehicleChange} />
                                                    {this.state.VehicleErrMsg && <span className="text-danger">{this.state.VehicleErrMsg === 'required' ? '*' : ''}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ display: 'flex', alignItems: 'center' }}>
                                                <i className="fa fa-calendar-alt " onClick={this.GetVehicleBookedModal}>
                                                </i>
                                            </div>
                                            <div className="col-md-11">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Driver </label>
                                                    <Select isClearable={true} options={this.state.drivers} value={this.state.DriverID} onChange={this.onDriverChange} />
                                                    {this.state.DriverErrMsg && <span className="text-danger">{this.state.DriverErrMsg === 'required' ? '*' : ''}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ display: 'flex', alignItems: 'center' }}>
                                                <i className="fa fa-calendar-alt " onClick={this.GetDriverBookedModal}>
                                                </i>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputPassword1">Helper </label>
                                                    <div className="checkbox-inline">
                                                        {
                                                            this.state.HelperDataAvailable &&
                                                            <label className="checkbox" >
                                                                <input checked={this.state.isChecked} style={{ marginRight: 0 }} value="Select All" type="checkbox" onChange={this.onHelperAllChange} />
                                                                <span />Select All
                                                            </label>

                                                        }

                                                        {
                                                            this.state.helpers.map((item, index) => (

                                                                <label className="checkbox" key={index}>

                                                                    <input checked={item.isChecked} style={{ marginRight: 0 }} value={item.id} type="checkbox" onChange={this.onHelperChange} />
                                                                    <span />{item.value}

                                                                </label>
                                                            ))
                                                        }
                                                        {
                                                            this.state.NoHelperVisibleMsg &&
                                                            <label>No Helper Available</label>
                                                        }

                                                    </div>
                                                    {this.state.HelperErrMsg && <span className="text-danger">{this.state.HelperErrMsg === 'required' ? '*' : ''}</span>}
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" onClick={this.handleReferredBackAssign} className="btn btn-primary font-weight-bold">
                                    Submit
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.CancelTaskModal = modal} id="exampleModal" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <FormWithConstraints
                            ref={form => this.CancelTask = form}
                            onSubmit={this.onCancelTaskSubmit}
                            noValidate>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Cancel Task
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label style={{ color: '#2b7acc' }} className="mobbottom" htmlFor="exampleInputPassword1">Remarks <span className="text-danger">*</span></label>
                                                <input className="form-control" name="TaskCancelRemarks" required type="text" value={this.state.TaskCancelRemarks} onChange={(e) => { this.setState({ TaskCancelRemarks: e.target.value }, () => { this.CancelTask.validateFields(e.target) }) }} id="example-time-input" />
                                                <FieldFeedbacks for="TaskCancelRemarks">
                                                    <FieldFeedback when="valueMissing"></FieldFeedback>
                                                </FieldFeedbacks>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" onClick={this.onCancelTaskSubmit} className="btn btn-primary font-weight-bold">
                                        Submit
                                    </button>
                                    <button type="button" onClick={this.cancelTaskCancelClick} className="btn btn-light-danger font-weight-bold" data-dismiss="modal">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </FormWithConstraints>
                    </div>
                </div>
                <div id="divToPrint" className="divToPrint" style={{ display: 'none' }}>

                    <div className="row">
                        <div className="col-md-1" >
                            <img src="/Images/Amclogo.png" style={{ height: '80px', textAlign: 'left' }}></img>
                        </div>
                        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <h3 style={{ width: '60%', fontWeight: 'bold', fontSize: '30px' }}>અમદાવાદ મ્યુનિસિપલ કોર્પોરેશન પર્ફોમન્સ સર્ટીફીકેટ</h3>
                        </div>
                        <div className="col-md-2" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '20px' }}>No.</span>
                            <span style={{ fontSize: '25px', borderBottom: '2px dotted', width: '100%' }}>{this.state.PDFBookingNo}</span>
                        </div>
                        <div className="col-md-3">
                            <img src="/Images/azadi.png" style={{ height: '80px' }}></img>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}>આથી મે. મેટ્રો વેસ્ટ હેન્ડલીંગ પ્રા. લી.ને સર્ટીફીકેટ આપવામાં આવે છે કે, ડીશીલ્ટીંગ મશીન રજીસ્‍ટ્રેશન નંબર <span style={{ borderBottom: '2px dotted' }}>{this.state.VehicleNo}</span> દ્રારા તા <span style={{ borderBottom: '2px dotted' }}>{this.state.BookingDate}</span> </span>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}>ના રોજ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.Shift}</span> શીફ્ટમાં <span style={{ fontSize: '25px', borderBottom: '2px dotted', width: '100%' }}>{this.state.TotalTime}</span> કલાક માટે નીચે મુજબની વિગતથી કામગીરી કરેલ છે.</span>
                        </div>
                        {/* <div className="col-md-12" >
<span style={{ fontWeight: 'normal', fontSize: '25px' }}>આથી મે. મેટ્રો વેસ્ટ હેન્ડલીંગ પ્રા. લી.ને સર્ટીફીકેટ આપવામાં આવે છે કે, ડીશીલ્ટીંગ મશીન રજીસ્‍ટ્રેશન નંબર GJ-01-J-4782 દ્રારા તા.  ..............</span>

</div> */}

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5%' }}>૧.</span> ઝોનઃ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ZoneName}</span> વોર્ડ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.WardName}</span> કામગીરીનું સ્થળ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.Locality}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5%' }}>ર.</span> પાર્કિંગનું સ્થળ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ParkingLocationName}</span> પાર્કિગના સ્થળ અને કામગીરીના સ્થળ વચ્ચેનું અંતર <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.DifferentLocationOdometer}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5%' }}>૩.</span> કામગીરીની વિગત : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.Locality}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%' }}>
                            <table style={{ border: '1px solid' }} className="table table-bordered">
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                    <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>કિલોમીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>કિલોમીટર</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન પાર્કિંગ થી નિકળ્યાનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleLeaveParkingTime}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleLeaveParkingKiloMeter}</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન કામગીરીના સ્થળ ઉપર આવ્યાનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedLocationTime}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedLocationKiloMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>ર</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન કામગીરીના સ્થળ ઉપરથી નિકળ્યાનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReturnLocationTime}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReturnLocationKiloMeter}</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન પાર્કિંગ ઉપર પહોચ્યાંનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedParkingTime}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedParkingKiloMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>કુલ સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>કલાક </span><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalHours}</span> <span style={{ fontSize: '25px', paddingLeft: '120px' }}>મીનીટ </span><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalMinutes}</span> </td>
                                    {/* <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>મીનીટ</span></td> */}
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>કુલ કિલોમીટર રીડીગ  </span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }} colspan='2'><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalKilometer}</span></td>
                                    {/* <td style={{ border: '1px solid black', width: '15%' }}><span style={{ fontSize: '25px' }}></span></td> */}
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૪</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'center' }} colspan='2'><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'center' }}><span style={{ fontSize: '25px' }}>રનીગ અવર્સના રીડીગ </span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'center' }} colspan='2'><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'center' }}><span style={{ fontSize: '25px' }}>રનીગ અવર્સના રીડીગ </span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>પ</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>મશીનની જેટિગની કામગીરી શરુ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                    <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.JettingStartMeterReading}</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>મશીનની જેટિગની કામગીરી પૂર્ણ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                    <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.JettingFinishMeterReading}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૬</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>મશીનની સકશનની કામગીરી શરુ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                    <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.SuctionPumpStartMeterReading}</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>મશીનની સકશનની કામગીરી પૂર્ણ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                    <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.SuctionPumpFinishMeterReading}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૭</span></td>
                                    <td style={{ border: '1px solid black', textAlign: 'justify' }} colspan='3'><span style={{ fontSize: '25px' }}>કુલ જેટિગ સમય:</span><span style={{ fontSize: '25px', paddingLeft: '120px' }}>કલાક </span> <span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalUsageJettingHour}</span> <span style={{ fontSize: '25px', paddingLeft: '120px' }}>મીનીટ </span> <span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalUsageJettingMinutes}</span> </td>
                                    <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='3'><span style={{ fontSize: '25px' }}>કુલ સકશન સમય:</span><span style={{ fontSize: '25px', paddingLeft: '60px' }}>કલાક </span> <span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalSuctionPumpUsageHour}</span> <span style={{ fontSize: '25px', paddingLeft: '60px' }}>મીનીટ </span> <span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalSuctionPumpUsageMinutes}</span> </td>

                                </tr>
                            </table>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૪.</span>સદર વોર્ડની ડ્રેનેજ લાઇનની ડિશીલ્ટીંગ ડિચોકીંગની નીચે મુજબની કામગીરી કરેલ છે.તથા કુલ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.TotalDrainageMeter}</span> મીટર ડ્રેનેજલાઈન અને <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.TotalNoOfDranageLine}</span> નંગ
                                મશીનનહોલની સફાઈની કામગીરી કરેલ છે.</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%' }}>
                            <table style={{ border: '1px solid' }} className="table table-bordered">
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>ડ્રેનેજ લાઇન સાઈઝ ટેન્ડર</span></td>
                                    <td style={{ border: '1px solid black', width: '25%' }}><span style={{ fontSize: '25px' }}>ટેન્ડરના <span style={{ fontWeight: 'bold', fontSize: '25px' }}>KPI</span> મુજબ નો ટાર્ગેટ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>કામગીરીના સ્થળ ઉપર થયેલ ખરેખર કામગીરી</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧૫૦ એમ.એમ. થી ૩૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૬૦ મીટર પ્રતિ કલાક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine150to300mmDiaMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૨</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૩૦૧ એમ.એમ. થી ૬૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૪૦ મીટર પ્રતિ કલાક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine301to600mmDiaMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૬૦૧ એમ.એમ. થી ૯૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૩૦ મીટર પ્રતિ કલાક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine601to900mmDiaMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૪</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૯૦૧ એમ.એમ. થી ૧૨૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧૦ મીટર પ્રતિ કલાક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine901to1200mmDiaMeter}</span></td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૫.</span>કામગીરીના સ્થળ ઉપર જોવા મળેલ મશીનના ટેકનીકલ પેરામીટરની વિગત</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%' }}>
                            <table style={{ border: '1px solid' }} className="table table-bordered">
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>ટેકનીકલ પેરામીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '25%' }}><span style={{ fontSize: '25px' }}>ટેન્ડર મુજબ જરૂરિયાત કેપેસીટી વૅલ્યુ </span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>કામગીરીના સ્થળ ઉપર ખરેખર મળેલ કેપેસીટી</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>જેટિગ સીસ્ટમ</span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧૫૦ બાર</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.JettingSystem}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૨</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>સકશન સીસ્ટમ</span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૫૦૦૦ ક્યુબીક મી કલાક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.SuctionSystem}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>સકશન હોઝ ૫ ડાયા મીટર - ૩ મીટર લંબાઈ</span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧૦ નંગ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.SuctionHose5Diameter3MeterLong}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૪</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>જેટિગ હોઝ - ૩૨ એમ.એમ. ડાયા, - ૧૨૦ મી લંબાઈ </span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧ નંગ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.JettingHose32Diameter120MeterLong}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>પ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>જેટિગ નોઝલ : ડીચોકીગ નોઝલ</span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧ નંગ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.JettingNozzleDeChockingNozzle}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૬</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>ડી શીલ્ટીગ નોઝલ - ફ્લેટ નોઝલ</span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧ નંગ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DeSiltingNozzleFlatNozzle}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૭</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>બેલેન્સ નોઝલ બોમ્બ નોઝલ</span></td>
                                    <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧ નંગ</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.BalanceNozzleBombNozzle}</span></td>
                                </tr>

                            </table>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૬.</span>સાઈટ ઉપર હાજર સ્ટાફની વિગત</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}>(i)ડ્રાઇવરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.DriverName}</span> (ii)ઓપરેટરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.MultipleHelperName}</span> (iii)સુપરવાઇઝરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.SupervisorName}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span>૬.</span>સ્ટાફને આપેલ સુરક્ષા સાધનો(PPE) અને અન્ય વિગત, જો હોય તો (✔) અને જો ન હોય તો (✘) માર્ક કરવુ.</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}>(i) સેફટી શુઝ : {(this.state.IsSafetyShoesChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (ii) હેન્ડ ગ્લોવ્ઝ : {(this.state.IsHandGlovesChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (iii) હેલ્મેટ : {(this.state.IsHelmetChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (iv) માસ્ક : {(this.state.IsMaskChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (v) લાઈટ રીફ્લેકટીવ જેકેટ : {(this.state.IsLightReflectiveJacketChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (vi) યુનિફોર્મ : {(this.state.IsUniformChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (vii) આઈ કાર્ડ : {(this.state.IsIDCardChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} </span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૭.</span>રીમાર્કસ(અન્ય ફોલ્ટની કામગીરીની વિગત):-</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૮.</span>ટેન્ડર લીકવીડીટી ડેમેજીસની કન્ડિશન મુજબ (પાના નં. ૧૦) જો કપાત હોય તો તેની વિગત :</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '5%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px', paddingLeft: '1%' }}>મશીન સુપરવાઇઝર<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}>(MWHPL)</span></span>
                            <span style={{ fontSize: '25px', paddingLeft: '1%' }}>ટેકનીકલ સુપરવાઇઝર<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}>(........Ward)</span></span>
                            <span style={{ fontSize: '25px', paddingLeft: '1%' }}>આસી. એનજીનીઅર<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}>(........Ward)</span></span>
                            <span style={{ fontSize: '25px', paddingLeft: '1%' }}>આસી.સીટી.એન્જીનીઅર<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}>(........Ward)</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'center' }}>
                            <span style={{ fontSize: '25px' }}> <span style={{ fontWeight: 'bold' }}>Special Note :</span><span style={{ paddingLeft: '5px' }}>As per Hon. Court order in any case, contractor is not allowed to enter/work inside the machinehole or sewer lines. Proper training & work knowledge shall be provided to all personals engaged for work by contractor.</span></span>
                        </div>
                    </div>
                </div>

                <div id="divToGUDMPrint" className="divToGUDMPrint" style={{ display: 'none' }}>

                    <div className="row">
                        <div className="col-md-2" >
                            <img src="/Images/GUDM_logo.png" style={{ height: '100px', width: '100px', textAlign: 'left' }}></img>
                        </div>
                        <div className="col-md-7" style={{ textAlign: 'center', marginTop: '10px' }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: '10px' }}>ગુજરાત શહેરી વિકાસ મિશન</h3>
                                </div>
                                <div className="col-md-12">
                                    <h3 style={{ fontWeight: 'bold', fontSize: '25px' }}>પર્ફોમન્સ સર્ટીફીકેટ</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '20px' }}>No.</span>
                            <span style={{ fontSize: '25px', borderBottom: '2px dotted', width: '100%' }}>{this.state.LogSheetNo}</span>
                        </div>
                        <div className="col-md-1">
                            <img src="/Images/azadi.png" style={{ height: '100px', width: '100px' }}></img>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}>આથી મે. મેટ્રો વેસ્ટ હેન્ડલીંગ પ્રા. લી.ને સર્ટીફીકેટ આપવામાં આવે છે કે, ડીશીલ્ટીંગ મશીન રજીસ્‍ટ્રેશન નંબર <span style={{ borderBottom: '2px dotted' }}>{this.state.VehicleNo}</span> દ્રારા તા <span style={{ borderBottom: '2px dotted' }}>{this.state.BookingDate}</span></span>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}> ના રોજ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.MuncipalityName}</span> નગરપાલિકા <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.WardName}</span> શીફ્ટ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.Shift}</span> દરમ્યાન નીચે મુજબની વિગતથી કામગીરી કરેલ છે.</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૧.</span> ઝોનઃ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ZoneName}</span> વોર્ડ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.WardName}</span> કામગીરીનું સ્થળ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.Locality + (this.state.LocationDetail != '' ? ('-' + this.state.LocationDetail) : '')}</span></span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>ર.</span> એન્જીનિયર ઇન્ચાર્જનું નામ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.EngineerName}</span> હોદ્દો <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.EngineerDesignation}</span></span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૩.</span> પાર્કિંગ સ્થળ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ParkingLocationName}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૪.</span> કામગીરીની વિગત : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.Locality}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%' }}>
                            <table style={{ border: '1px solid' }} className="table table-bordered">
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                    <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontSize: '25px' }}>ઓડોમીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontSize: '25px' }}>ઓડોમીટર</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન પાર્કિંગ થી નિકળ્યાનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleLeaveParkingTime2}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleLeaveParkingKiloMeter}</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન કામગીરીના સ્થળ ઉપર પહોંચ્યાનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedLocationTime2}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedLocationKiloMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>ર</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન કામગીરીના સ્થળ ઉપરથી નિકળ્યાનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReturnLocationTime2}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReturnLocationKiloMeter}</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન પાર્કિંગ ઉપર પહોચ્યાંનો સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedParkingTime2}</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.VehicleReachedParkingKiloMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>કુલ સમય</span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>કલાક </span><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalHours}</span> <span style={{ fontSize: '25px', paddingLeft: '120px' }}>મીનીટ </span><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalMinutes}</span> </td>
                                    {/* <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>મીનીટ</span></td> */}
                                    <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>કુલ કિલોમીટર રીડીગ  </span></td>
                                    <td style={{ border: '1px solid black', width: '13.75%' }} colspan='2'><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.TotalKilometer}</span></td>
                                    {/* <td style={{ border: '1px solid black', width: '15%' }}><span style={{ fontSize: '25px' }}></span></td> */}
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૫.</span> કરેલ કામગીરી :</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%' }}>
                            <table style={{ border: '1px solid' }} className="table table-bordered">
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>ડ્રેનેજ લાઇન સાઈઝ ટેન્ડર</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>કામગીરીના સ્થળ ઉપર થયેલ ખરેખર કામગીરી</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૩૦૧ એમ.એમ. થી ૬૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine301to600mmDiaMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૨</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૬૦૧ એમ.એમ. થી ૯૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine601to900mmDiaMeter}</span></td>
                                </tr>
                                <tr>
                                    <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                    <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૯૦૧ એમ.એમ. થી ૧૨૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                    <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{this.state.DrainageLine901to1200mmDiaMeter}</span></td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}>સદર વોર્ડની ડ્રેનેજ લાઇનની ડિશીલ્ટીંગ / ડિચોકીંગની કામગીરી માં કુલ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.TotalDrainageMeter}</span> મીટર ડ્રેનેજલાઈન અને <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.TotalNoOfDranageLine}</span> નંગ મશીનનહોલની સફાઈની કામગીરી સંતોષકારક પૂર્ણ કરેલ છે.</span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૬.</span>કામગીરી બંધ રાખવાની વિગત: રિસેસ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.LunchStartTime2}</span> થી <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.LunchEndTime2}</span> ;કુલ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.TotalLunchTime}</span> કલાક</span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૭.</span>મશીન અન્ય કારણસર બંધ રહ્યાની વિગત <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.MachineNotWorkFromTime2}</span> થી <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.MachineNotWorkToTime2}</span> ;કુલ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.MachineNotWorkTotalTime}</span> કલાક</span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૮.</span>અસાધારણ પરિસ્થિતિ ની વિગત:  અસાધારણ પરિસ્થિતિ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ExtraordinaryDetails}</span> સ્થળ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ExtraordinaryArea}</span> મેન હોલ ની સંખ્યા <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.ExtraordinaryMachineHoles}</span></span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૯.</span>સાઈટ ઉપર હાજર સ્ટાફની વિગત</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}>(i)ડ્રાઇવરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.DriverName}</span> (ii)ઓપરેટરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.MultipleHelperName}</span> (iii)સુપરવાઇઝરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{this.state.AssignTaskSupervisorName}</span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span>૧૦.</span>સ્ટાફને આપેલ સુરક્ષા સાધનો(PPE) અને અન્ય વિગત, જો હોય તો (✔) અને જો ન હોય તો (✘) માર્ક કરવુ.</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}>(i) સેફટી શુઝ : {(this.state.IsSafetyShoesChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (ii) હેન્ડ ગ્લોવ્ઝ : {(this.state.IsHandGlovesChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (iii) હેલ્મેટ : {(this.state.IsHelmetChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (iv) માસ્ક : {(this.state.IsMaskChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (v) લાઈટ રીફ્લેકટીવ જેકેટ : {(this.state.IsLightReflectiveJacketChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (vi) યુનિફોર્મ : {(this.state.IsUniformChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (vii) આઈ કાર્ડ : {(this.state.IsIDCardChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} </span>
                        </div>


                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span>૧૧.</span>Sludge નું ડીસપોઝલ નગરપાલિકા એ બતાવેલ જગ્યાએ ખાલી કરેલ છે હા {(this.state.IsSludgeDisposalAreaYes) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} ના {(this.state.IsSludgeDisposalAreaNo) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)}</span>
                        </div>

                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                            <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૧૨.</span>રીમાર્કસ(અન્ય ફોલ્ટની કામગીરીની વિગત):-</span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '5%', textAlign: 'right' }}>
                            <span style={{ fontSize: '25px', paddingLeft: '1%' }}>મશીન સુપરવાઇઝર ઇન્ચાર્જ ની સહી<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}></span></span>
                            <span style={{ fontSize: '25px', paddingRight: '15%' }}><span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}></span></span>
                            <span style={{ fontSize: '25px', paddingLeft: '1%' }}>ઈન્જીનિયર ઇન્ચાર્જ ની સહી<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}></span></span>
                            <span style={{ fontSize: '25px', paddingRight: '15%' }}><span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}></span></span>
                        </div>
                        <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'center' }}>
                            <span style={{ fontSize: '25px' }}> <span style={{ fontWeight: 'bold' }}>Special Note :</span><span style={{ paddingLeft: '5px' }}>As per Hon. Court order in any case, contractor is not allowed to enter/work inside the machinehole or sewer lines. Proper training & work knowledge shall be provided to all personals engaged for work by contractor.</span></span>
                        </div>
                    </div>
                </div>

                <div className="modal fade" ref={modal => this.MapModal = modal} data-bs-keyboard="false" data-bs-backdrop="static" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog mapdialog" role="document">
                        <div className="modal-content" style={{ height: '700px' }}>
                            <div className="modal-header" style={{ display: 'block' }}>
                                <button className={(this.state.isStartToFinishChecked) == true ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={this.BindMapData(this.state.BookingID, this.state.BookingTaskDetailID, this.state.Tempvehicleno, this.state.TempVehicleLeaveParkingDateTime, this.state.TempVehicleReachedLocationDateTime, this.state.TempVehicleReturnLocationDateTime, this.state.TempVehicleReachedParkingDateTime, true, false, false)}>Start to Finish</button>
                                <button className={(this.state.isFinishToStartChecked) == true ? 'btn btn-primary' : 'btn btn-outline-primary'} style={{ marginLeft: '1%' }} onClick={this.BindMapData(this.state.BookingID, this.state.BookingTaskDetailID, this.state.Tempvehicleno, this.state.TempVehicleLeaveParkingDateTime, this.state.TempVehicleReachedLocationDateTime, this.state.TempVehicleReturnLocationDateTime, this.state.TempVehicleReachedParkingDateTime, false, true, false)}>Finish to Start</button>
                                <button className={(this.state.isWithinDataChecked) == true ? 'btn btn-primary' : 'btn btn-outline-primary'} style={{ marginLeft: '1%', marginRight: '1%' }} onClick={this.BindMapData(this.state.BookingID, this.state.BookingTaskDetailID, this.state.Tempvehicleno, this.state.TempVehicleLeaveParkingDateTime, this.state.TempVehicleReachedLocationDateTime, this.state.TempVehicleReturnLocationDateTime, this.state.TempVehicleReachedParkingDateTime, false, false, true)}>Within Location</button>
                                <img src="/Images/parkingstart.png" style={{ height: '30px' }}></img>
                                <span style={{ fontWeight: 'bold', fontFamily: '"Poppins"' }}>Parking Start</span>
                                <img src="/Images/locationreach.png" style={{ height: '30px' }}></img>
                                <span style={{ fontWeight: 'bold', fontFamily: '"Poppins"' }}>Location Reached</span>
                                <img src="/Images/locationleave.png" style={{ height: '30px' }}></img>
                                <span style={{ fontWeight: 'bold', fontFamily: '"Poppins"' }}>Location Leave</span>
                                <img src="/Images/parkingend.png" style={{ height: '30px' }}></img>
                                <span style={{ fontWeight: 'bold', fontFamily: '"Poppins"' }}>Parking Reached</span>


                                <button className="btn btn-danger float-lg-right" onClick={this.closeMapModal}>Close</button>
                            </div>
                            <div className="modal-body" style={{ paddingLeft: '12px', paddingTop: '0' }}>
                                <div className="row" style={{ display: 'block' }}>
                                    {
                                        ((this.state.DataBind !== null) ? (
                                            <Map
                                                google={this.props.google}
                                                zoom={18}
                                                style={mapStyles}
                                                center={this.state.LiveLocation}
                                            >
                                                {/* {this.state.LiveLocation} */}
                                                {this.showMarkers()}
                                                <Polyline
                                                    options={{
                                                        strokeColor: "blue",
                                                        strokeOpacity: 0.75,
                                                        strokeWeight: 2,
                                                        // icons: [
                                                        //     {
                                                        //         icon: {
                                                        //             path: "M -2,0 0,-2 2,0 0,2 z",
                                                        //             strokeColor: "blue",
                                                        //             fillColor: "red",
                                                        //             fillOpacity: 1,
                                                        //             // scale: 1.1,
                                                        //             // zoom: 16
                                                        //         },
                                                        //         offset: "0",
                                                        //         repeat: "40px"
                                                        //     }
                                                        // ]

                                                    }}
                                                    path={
                                                        this.state.DataBind
                                                    }
                                                />


                                            </Map>) : '')
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    isOpen && (
                        <Lightbox
                            mainSrc={this.state.images[photoIndex]}
                            nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length]}
                            prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + 1) % this.state.images.length,
                                })
                            }
                        />

                    )
                }
            </div>

        );
    }
}
export default withCookies(viewsubadmintask);