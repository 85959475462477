import React, { Component } from "react";
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import services from "../services/services";
import Swal from "sweetalert2";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import MultiSelect from "@khanacademy/react-multi-select";
import Select, { components } from 'react-select'

import { data } from "jquery";
import XLSX from "xlsx-color";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// import Lightbox from "yet-another-react-lightbox";
import { ReactFlvPlayer } from 'react-flv-player'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import * as htmlToImage from 'html-to-image';
import download from "downloadjs";
import jsPDF from 'jspdf';
// import LightGallery from 'lightgallery/react';

// import styles
// import 'lightgallery/css/lightgallery.css';
// import 'lightgallery/css/lg-zoom.css';
// import 'lightgallery/css/lg-thumbnail.css';
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';
//  import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
// import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
// import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
// import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
// import Zoom from "yet-another-react-lightbox/plugins/zoom";
// import "yet-another-react-lightbox/plugins/thumbnails.css";


// import { Map, GoogleApiWrapper, Image, Marker, Polyline } from 'google-maps-react';
import { Map, GoogleApiWrapper, Marker, Polyline, GoogleMap, withScriptjs, withGoogleMap, InfoWindow } from 'google-maps-react';
import FsLightbox from "fslightbox-react";
import Raphael from "raphael";
import { tr } from "date-fns/locale";

// import Slideshow from "yet-another-react-lightbox/plugins/slideshow";

const mapStyles = {
    width: '100%',
    height: '100%',
};
let map;
const cameraOptions = {
    tilt: 0,
    heading: 0,
    zoom: 3,
    center: { lat: 35.6594945, lng: 139.6999859 },
};
const mapOptions = Object.assign(Object.assign({}, cameraOptions), { mapId: "15431d2b469f209e" });
const headerTblStyle = { color: 'black' };
const A4_PAPER_DIMENSIONS = {
    width: 210,
    height: 210,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;
export const imageDimensionsOnA4 = () => {
    debugger;
    const isLandscapeImage = 210 >= 297;

    if (isLandscapeImage) {
        return {
            width: A4_PAPER_DIMENSIONS.width,
            height:
                A4_PAPER_DIMENSIONS.width / (510 / 97),
        };
    }

    const imageRatio = 600 / 297;
    if (imageRatio > A4_PAPER_RATIO) {
        const imageScaleFactor =
            (A4_PAPER_RATIO * 260) / 600; // for space all doc

        const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

        return {
            height: scaledImageHeight * imageRatio,
            //height: "295",
            width: scaledImageHeight * imageRatio,
        };
    }

    return {
        width: A4_PAPER_DIMENSIONS.height / (600 / 297),
        height: A4_PAPER_DIMENSIONS.height,

    };
};
const exportToExcel = (columns, data) => {
    debugger;
    const header = ["Sr.No", "MuncipalityName", "VehicleNo", "Shift", "TotalNoOfDranageLine", "BookingNo", "BookingType", "BookingStatus", "BookingDate", "BookingCompleteDate", "DeviationDays", "TotalMeter", "VehicleLeaveParkingTime", "VehicleReachedParkingTime", "VehicleReachedLocationTime", "VehicleReturnLocationTime", "TotalTime", "LunchStartTime", "LunchEndTime", "ApproveMobileNo", "BookingDrainageLine150to300mmDiaMeter", "BookingDrainageLine301to600mmDiaMeter", "BookingDrainageLine601to900mmDiaMeter", "BookingDrainageLine901to1200mmDiaMeter", "TaskDrainageLine150to300mmDiaMeter", "TaskDrainageLine301to600mmDiaMeter", "TaskDrainageLine601to900mmDiaMeter", "TaskDrainageLine901to1200mmDiaMeter", "Deviation150to300", "Deviation301to600", "Deviation601to900", "Deviation901to1200", "BookingCompleteRemarks", "BookingApprovalRemarks", "DefectName", "CheckListDetails", "DefectLatitude", "DefectLongitude", "DefectAddress", "DefectRemarks"];
    const headertemp = ["Sr.No", "Municipality", "Vehicle No", "Shift", "Total No Of Dranage Line", "Task No", "Task Type", "Status", "Planned Date", "Completion Date", "Deviation in Days", "Distance in Meters", "Parking Location Leaving Time", "Parking Location Reaching Time", "Location Reaching Time", "Location Leaving Time", "Total Time", "Lunch Start Time", "Lunch End Time", "Approve Mobile No", "Planned Drainage Line 150 to 300mm DiaMeter", "Planned Drainage Line 301 to 600mm DiaMeter", "Planned Drainage Line 601 to 900mm DiaMeter", "Planned Drainage Line 901 to 1200mm DiaMeter", "Actual Drainage Line 150 to 300mm DiaMeter", "Actual Drainage Line 301 to 600mm DiaMeter", "Actual Drainage Line 601 to 900mm DiaMeter", "Actual Drainage Line 901 to 1200mm DiaMeter", "Deviation 150 to 300 mm", "Deviation 301 to 600 mm", "Deviation 601 to 900 mm", "Deviation 901 to 1200 mm", "Complete Remarks", "Approval Remarks", "Defect Name", "Check List Details", "Defect Latitude", "Defect Longitude", "Defect Address", "Defect Remarks"];
    //debugger
    const compatibleData = data.map((row, i) => {
        const obj = {};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col === "Sr.No") {
                obj[col] = i + 1;
            }
            else if (col == "MuncipalityName") {
                obj["Municipality"] = row[col];
            }
            else if (col == "VehicleNo") {
                obj["Vehicle No"] = row[col];
            }
            else if (col == "TotalNoOfDranageLine") {
                obj["Total No Of Dranage Line"] = row[col];
            }
            else if (col == "BookingNo") {
                obj["Task No"] = row[col];
            }
            else if (col == "BookingType") {
                obj["Task Type"] = row[col];
            }
            else if (col == "BookingStatus") {
                obj["Status"] = row[col];
            }
            else if (col == "BookingDate") {
                obj["Planned Date"] = row[col];
            }
            else if (col == "BookingCompleteDate") {
                obj["Completion Date"] = row[col];
            }
            else if (col == "DeviationDays") {
                obj["Deviation in Days"] = row[col];
            }
            else if (col == "TotalMeter") {
                obj["Distance in Meters"] = row[col];
            }
            else if (col == "VehicleLeaveParkingTime") {
                obj["Parking Location Leaving Time"] = row[col];
            }
            else if (col == "VehicleReachedParkingTime") {
                obj["Parking Location Reaching Time"] = row[col];
            }
            else if (col == "VehicleReachedLocationTime") {
                obj["Location Reaching Time"] = row[col];
            }
            else if (col == "VehicleReturnLocationTime") {
                obj["Location Leaving Time"] = row[col];
            }
            else if (col == "TotalTime") {
                obj["Total Time"] = row[col];
            }
            else if (col == "LunchStartTime") {
                obj["Lunch Start Time"] = row[col];
            }
            else if (col == "LunchEndTime") {
                obj["Lunch End Time"] = row[col];
            }
            else if (col == "ApproveMobileNo") {
                obj["Approve Mobile No"] = row[col];
            }
            else if (col == "BookingDrainageLine150to300mmDiaMeter") {
                obj["Planned Drainage Line 150 to 300mm DiaMeter"] = row[col];
            }
            else if (col == "BookingDrainageLine301to600mmDiaMeter") {
                obj["Planned Drainage Line 301 to 600mm DiaMeter"] = row[col];
            }
            else if (col == "BookingDrainageLine601to900mmDiaMeter") {
                obj["Planned Drainage Line 601 to 900mm DiaMeter"] = row[col];
            }
            else if (col == "BookingDrainageLine901to1200mmDiaMeter") {
                obj["Planned Drainage Line 901 to 1200mm DiaMeter"] = row[col];
            }
            else if (col == "TaskDrainageLine150to300mmDiaMeter") {
                if (row[col] != null) {
                    obj["Actual Drainage Line 150 to 300mm DiaMeter"] = row[col];
                }
                else {
                    obj["Actual Drainage Line 150 to 300mm DiaMeter"] = "0";
                }
            }
            else if (col == "TaskDrainageLine301to600mmDiaMeter") {
                if (row[col] != null) {
                    obj["Actual Drainage Line 301 to 600mm DiaMeter"] = row[col];
                }
                else {
                    obj["Actual Drainage Line 301 to 600mm DiaMeter"] = "0";
                }
            }
            else if (col == "TaskDrainageLine601to900mmDiaMeter") {
                if (row[col] != null) {
                    obj["Actual Drainage Line 601 to 900mm DiaMeter"] = row[col];
                }
                else {
                    obj["Actual Drainage Line 601 to 900mm DiaMeter"] = "0";
                }
            }
            else if (col == "TaskDrainageLine901to1200mmDiaMeter") {
                if (row[col] != null) {
                    obj["Actual Drainage Line 901 to 1200mm DiaMeter"] = row[col];
                }
                else {
                    obj["Actual Drainage Line 901 to 1200mm DiaMeter"] = "0";
                }
            }
            else if (col == "Deviation150to300") {
                obj["Deviation 150 to 300 mm"] = row["BookingDrainageLine150to300mmDiaMeter"] - row["TaskDrainageLine150to300mmDiaMeter"];
            }
            else if (col == "Deviation301to600") {
                obj["Deviation 301 to 600 mm"] = row["BookingDrainageLine301to600mmDiaMeter"] - row["TaskDrainageLine301to600mmDiaMeter"];
            }
            else if (col == "Deviation601to900") {
                obj["Deviation 601 to 900 mm"] = row["BookingDrainageLine601to900mmDiaMeter"] - row["TaskDrainageLine601to900mmDiaMeter"];
            }
            else if (col == "Deviation901to1200") {
                obj["Deviation 901 to 1200 mm"] = row["BookingDrainageLine901to1200mmDiaMeter"] - row["TaskDrainageLine901to1200mmDiaMeter"];
            }
            else if (col == "BookingCompleteRemarks") {
                obj["Complete Remarks"] = row[col];
            }
            else if (col == "BookingApprovalRemarks") {
                obj["Approval Remarks"] = row[col];
            }
            else if (col == "DefectName") {
                debugger
                if (row["BookingDefectDetail"] != null) {
                    var DefectName = "";
                    row["BookingDefectDetail"].map((value, index) => {
                        // DefectName+=value.DefectName+","
                        DefectName += value.DefectName + "\n"
                    });
                    DefectName = DefectName.slice(0, -1);
                    obj["Defect Name"] = DefectName;
                    //obj["Defect Name"]=row["BookingDefectDetail"][0]["DefectName"]
                }
                else {
                    obj["Defect Name"] = "";
                }
            }
            else if (col == "CheckListDetails") {
                debugger
                if (row["BookingDefectDetail"] != null) {
                    var MainChecklist = "";

                    row["BookingDefectDetail"].map((value, index) => {
                        var checklistdetails = "";
                        if (value.BookingDefectCheckListDetail != null) {
                            value.BookingDefectCheckListDetail.map((values, index) => {
                                checklistdetails += values.CheckListTitle + ","
                            });
                            checklistdetails = checklistdetails.slice(0, -1);
                            MainChecklist += value.DefectName + "\n"
                            MainChecklist += checklistdetails + "\n"
                        }
                    });
                    obj["Check List Details"] = MainChecklist;
                }
                else {
                    obj["Check List Details"] = "";
                }

            }
            else if (col == "DefectLatitude") {
                if (row["BookingDefectDetail"] != null) {
                    var MainLatitude = "";
                    row["BookingDefectDetail"].map((value, index) => {
                        if (value.Latitude != null) {
                            MainLatitude += value.DefectName + "\n"
                            MainLatitude += value.Latitude + "\n"
                        }
                    });
                    obj["Defect Latitude"] = MainLatitude

                }
                else {
                    obj["Defect Latitude"] = "";
                }
            }
            else if (col == "DefectLongitude") {
                if (row["BookingDefectDetail"] != null) {
                    var MainLongitude = "";
                    row["BookingDefectDetail"].map((value, index) => {
                        if (value.Longitude != null) {
                            MainLongitude += value.DefectName + "\n"
                            MainLongitude += value.Longitude + "\n"
                        }
                    });
                    obj["Defect Longitude"] = MainLongitude
                    // obj["Defect Longitude"]=row["BookingDefectDetail"][0]["Longitude"]
                }
                else {
                    obj["Defect Longitude"] = "";
                }
            }
            else if (col == "DefectAddress") {
                if (row["BookingDefectDetail"] != null) {
                    var MainAddress = "";
                    row["BookingDefectDetail"].map((value, index) => {
                        if (value.Longitude != null) {
                            MainAddress += value.DefectName + "\n"
                            MainAddress += value.Address + "\n"
                        }
                    });
                    obj["Defect Address"] = MainAddress
                    // obj["Defect Address"]=row["BookingDefectDetail"][0]["Address"]
                }
                else {
                    obj["Defect Address"] = "";
                }
            }
            else if (col == "DefectRemarks") {
                if (row["BookingDefectDetail"] != null) {
                    var MainRemarks = "";
                    row["BookingDefectDetail"].map((value, index) => {
                        if (value.Longitude != null) {
                            MainRemarks += value.DefectName + "\n"
                            MainRemarks += value.Remarks + "\n"
                        }
                    });
                    obj["Defect Remarks"] = MainRemarks
                    //   obj["Defect Remarks"]=row["BookingDefectDetail"][0]["Remarks"]
                }
                else {
                    obj["Defect Remarks"] = "";
                }
            }
            else {
                obj[col] = row[col];
            }
        });
        return obj;
    });

    const ws1 = XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb = XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s = {
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };


    ws1['!cols'] = [
        { width: 6 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },

    ];

    let cell = {
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: false, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s = cell;
    ws1['B1'].s = cell;
    ws1['C1'].s = cell;
    ws1['D1'].s = cell;
    ws1['E1'].s = cell;
    ws1['F1'].s = cell;
    ws1['G1'].s = cell;
    ws1['H1'].s = cell;
    ws1['I1'].s = cell;
    ws1['J1'].s = cell;
    ws1['K1'].s = cell;
    ws1['L1'].s = cell;
    ws1['M1'].s = cell;
    ws1['N1'].s = cell;
    ws1['O1'].s = cell;
    ws1['P1'].s = cell;
    ws1['Q1'].s = cell;
    ws1['R1'].s = cell;
    ws1['S1'].s = cell;
    ws1['T1'].s = cell;
    ws1['U1'].s = cell;
    ws1['V1'].s = cell;
    ws1['W1'].s = cell;
    ws1['X1'].s = cell;
    ws1['Y1'].s = cell;
    ws1['Z1'].s = cell;
    ws1['AA1'].s = cell;
    ws1['AB1'].s = cell;
    ws1['AC1'].s = cell;
    ws1['AD1'].s = cell;
    ws1['AE1'].s = cell;
    ws1['AF1'].s = cell;
    ws1['AG1'].s = cell;
    ws1['AH1'].s = cell;
    ws1['AI1'].s = cell;
    ws1['AJ1'].s = cell;
    ws1['AK1'].s = cell;
    ws1['AL1'].s = cell;
    ws1['AM1'].s = cell;
    ws1['AN1'].s = cell;



    var RowCont = {
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins = i + 2;
        ws1['A' + ins].s = RowCont;
        ws1['B' + ins].s = RowCont;
        ws1['C' + ins].s = RowCont;
        ws1['D' + ins].s = RowCont;
        ws1['E' + ins].s = RowCont;
        ws1['F' + ins].s = RowCont;
        ws1['G' + ins].s = RowCont;
        ws1['H' + ins].s = RowCont;
        ws1['I' + ins].s = RowCont;
        ws1['J' + ins].s = RowCont;
        ws1['K' + ins].s = RowCont;
        ws1['L' + ins].s = RowCont;
        ws1['M' + ins].s = RowCont;
        ws1['N' + ins].s = RowCont;
        ws1['O' + ins].s = RowCont;
        ws1['P' + ins].s = RowCont;
        ws1['Q' + ins].s = RowCont;
        ws1['R' + ins].s = RowCont;
        ws1['S' + ins].s = RowCont;
        ws1['T' + ins].s = RowCont;
        ws1['U' + ins].s = RowCont;
        ws1['V' + ins].s = RowCont;
        ws1['W' + ins].s = RowCont;
        ws1['X' + ins].s = RowCont;
        ws1['Y' + ins].s = RowCont;
        ws1['Z' + ins].s = RowCont;
        ws1['AA' + ins].s = RowCont;
        ws1['AB' + ins].s = RowCont;
        ws1['AC' + ins].s = RowCont;
        ws1['AD' + ins].s = RowCont;
        ws1['AE' + ins].s = RowCont;
        ws1['AF' + ins].s = RowCont;
        ws1['AG' + ins].s = RowCont;
        ws1['AH' + ins].s = RowCont;
        ws1['AI' + ins].s = RowCont;
        ws1['AJ' + ins].s = RowCont;
        ws1['AK' + ins].s = RowCont;
        ws1['AL' + ins].s = RowCont;
        ws1['AM' + ins].s = RowCont;
        ws1['AN' + ins].s = RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "GUDMDashboardList");
    XLSX.writeFile(wb, "GUDMDashboardList_" + moment().format('DD-MM-YYYY_HH:mm') + ".xlsx");
}
// const images=[
//     '//placekitten.com/1500/500',

// ];
const images = [
    { src: '//placekitten.com/1500/500' },

];
const icon = {
    url: 'Images/marker.png',
    scaledSize: (50, 50)
};
// const images=['https://mwhdia.in:3071/UploadFiles/m_9442/00bd001497/23-03-22/chn01_230322-091704_2_2_6291457.jpeg', 
// 'https://mwhdia.in:3071/UploadFiles/m_9442/00bd001497/23-03-22/chn01_230322-091705_2_2_6291458.jpeg', 
// 'https://mwhdia.in:3071/UploadFiles/m_9442/00bd001497/23-03-22/chn01_230322-091710_2_2_6291459.jpeg', 
// 'https://mwhdia.in:3071/UploadFiles/m_9442/00bd001497/23-03-22/chn01_230322-091755_2_2_6291468.jpeg', 
// 'https://mwhdia.in:3071/UploadFiles/m_9442/00bd001497/23-03-22/chn01_230322-091805_2_2_6291470.jpeg', 
// 'https://mwhdia.in:3071/UploadFiles/m_9442/00bd001497/23-03-22/chn01_230322-091810_2_2_6291471.jpeg', 
// 'https://mwhdia.in:3071/UploadFiles/m_9442/00bd001497/23-03-22/chn01_230322-091820_2_2_6291473.jpeg', 
// 'https://mwhdia.in:3071/UploadFiles/m_9442/00bd001497/23-03-22/chn01_230322-091830_2_2_6291475.jpeg', 
// 'https://mwhdia.in:3071/UploadFiles/m_9442/00bd001497/23-03-22/chn01_230322-091835_2_2_6291476.jpeg', 
// 'https://mwhdia.in:3071/UploadFiles/m_9442/00bd001497/23-03-22/chn01_230322-091859_2_2_6291480.jpeg']
class gudmdashboard extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        // this.map = React.createRef(null);
        const { cookies } = props;
        this.state = {
            isLoading: true,
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "", counter: null,
            data: [], zones: [], districts: [], municipalities: [], wards: [],
            ZoneID: [], DistrictID: [], MunicipalityID: [], WardID: [], BookingID: "", BookingTaskDetailID: "", VehicleNo: "",
            toggler: false, timeslotdata: [], vehicles: [],
            imageData: [
                'https://www.shutterstock.com/image-photo/mountains-under-mist-morning-amazing-260nw-1725825019.jpg',
            ],
            slide: 1,
            centerlat: 0, centerlng: 0, IsMapVisible: false, LiveLocation: "",
            BookingNo: "", images: [], Lastlong: "", animate: false, polyline: null, currentStep: 0, path: [
                {
                    lat: 0,
                    lng: 0
                }
            ],
            status: [{ 'label': 'New', 'value': 'New' }, { 'label': 'Assigned', 'value': 'Assigned' },
            { 'label': 'Started', 'value': 'Started' }, { 'label': 'Finished', 'value': 'Finished' },
            { 'label': 'Completed', 'value': 'Completed' }, { 'label': 'Approved', 'value': 'Approved' },
            { 'label': 'Referred Back', 'value': 'Referred Back' }],
            StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' },
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            StartDate: new Date(), EndDate: new Date(), photoIndex: 0,
            isOpen: false, currentLocation: [], DataBind: [], LiveLocation: "", DefectLocation: [],
            faicon: "fa fa-plus", isFilterVisible: false, iconFilter: "fa fa-plus", PDFStatus: false,
            isStartToFinishChecked: true, isFinishToStartChecked: false, isWithinDataChecked: false, isButtonVisible: true,
            showInfoWindow: false, activeMarker: null, MapTaskNo: "", MapTaskDate: "", MapMuniciplaity: "", MapLocation: "",
            MapChecklistDetail: "", MapLatitude: "", MapLongitude: "", MapImages: [], MapRemarks: "",
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.tableData.id + 1}</p>) } },
                // { title: 'Municipality', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left' }}>{rowData.MuncipalityName}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Municipality</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Vehicle No</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.MuncipalityName}<hr />{rowData.VehicleNo}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Task No</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Task Type</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Status</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingNo}<hr />{rowData.BookingType}<hr />{rowData.BookingStatus}</p>) } },
                // { title: 'Status', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingStatus}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Planned Date</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Completion Date</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Deviation in Days</span></div>), width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingDate}<hr />{rowData.BookingCompleteDateNew}<hr />{rowData.DeviationDays}</p>) } },
                // { title: 'Deviation in Days', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.DeviationDays}</p>) } },
                // { title: 'Distance in Meters', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalMeter}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Shift</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>No Of Drainage Line</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Shift}<hr />{rowData.TotalNoOfDranageLine}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Parking Location Leaving Time</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Parking Location Reaching Time</span></div>), width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', whiteSpace: 'nowrap' }}>{rowData.VehicleLeaveParkingTime}<hr />{rowData.VehicleReachedParkingTime}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Location Reaching Time</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Location Leaving Time</span></div>), width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', whiteSpace: 'nowrap' }}>{rowData.VehicleReachedLocationTime}<hr />{rowData.VehicleReturnLocationTime}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Lunch Start Time</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Lunch End Time</span></div>), width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', whiteSpace: 'nowrap' }}>{rowData.LunchStartTime}<hr />{rowData.LunchEndTime}</p>) } },

                // { title: 'Total Time', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalTime}</p>) } },
                {
                    title: (<div style={{ width: '100%' }}><span>Total Time</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>LogSheet Image</span></div>), width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => {
                        return (<p style={{ marginBottom: '1px' }}>{rowData.TotalTime}<hr />{(rowData.LogSheetImage !== null) ?
                            (
                                <a target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + rowData.LogSheetImage}>
                                    <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + rowData.LogSheetImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + rowData.LogSheetImage}></img>
                                </a>
                            ) : ''}</p>)
                    }
                },
                {
                    title: (<div style={{ width: '100%' }}><span>GPS Track</span></div>), width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => {
                        return (<p style={{ marginBottom: '1px', whiteSpace: 'nowrap' }}>{


                            (<a onClick={this.BindOtherMapData(rowData.VehicleNo, rowData.VehicleLeaveParkingDateTime, rowData.VehicleReachedLocationDateTime, rowData.VehicleReturnLocationDateTime, rowData.VehicleReachedParkingDateTime, true, false, false, rowData.BookingNo, rowData.BookingDate, rowData.MuncipalityName, rowData.Locality, rowData.BookingDefectDetail)}><img src="Images/map.png" height={30}></img></a>)}</p>)
                    }
                },
                // {
                //     title: 'GPS Track', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => {
                //         return (
                //             (rowData.VehicleLeaveParkingDateTime!==null&&rowData.VehicleReachedLocationDateTime!==null)? (<a onClick={this.BindMapData(rowData.BookingID, rowData.BookingTaskDetailID, rowData.VehicleNo, rowData.VehicleLeaveParkingDateTime, rowData.VehicleReachedLocationDateTime, rowData.VehicleReturnLocationDateTime, rowData.VehicleReachedParkingDateTime, true, false, false, rowData.BookingNo, rowData.BookingDate, rowData.MuncipalityName, rowData.Locality, rowData.BookingDefectDetail)}><img src="Images/map.png" height={30}></img></a>):''
                //         )
                //     }
                // },
                // {
                //     title: 'CCD View', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => {
                //         return (
                //             (rowData.VehicleLeaveParkingDateTime!==null&&rowData.VehicleReachedLocationDateTime!==null)?
                //                 (<span>
                //                     {/* <button type="button" className="btn btn-primary" onClick={this.GetImages(rowData.BookingID, rowData.BookingTaskDetailID, rowData.VehicleNo, rowData.VehicleLeaveParkingDateTime, rowData.VehicleReachedLocationDateTime, rowData.VehicleReturnLocationDateTime, rowData.VehicleReachedParkingDateTime)}>
                //                         View
                //                     </button> */}
                //                     {/* <button type="button" className="btn btn-primary" onClick={this.GetTimeModal(rowData.BookingID, rowData.BookingTaskDetailID, rowData.VehicleNo, rowData.VehicleLeaveParkingDateTime, rowData.VehicleReachedLocationDateTime, rowData.VehicleReturnLocationDateTime, rowData.VehicleReachedParkingDateTime)}>
                //                         View
                //                     </button> */}
                //                     <button type="button" className="btn btn-primary" onClick={this.GetTimeSlotModal(rowData.BookingID, rowData.BookingTaskDetailID, rowData.VehicleNo, rowData.VehicleLeaveParkingDateTime, rowData.VehicleReachedParkingDateTime)}>
                //                         View
                //                     </button>
                //                     {/* <button onClick={() => this.setState({ toggler: !this.state.toggler })}>
                //                         Toggle Lightbox
                //                     </button> */}
                //                 </span>):''
                //         )
                //     }
                // },
                { title: 'Drainage Line', width: '30%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<table className="fonttable table table-bordered"><tr><td style={{ width: '25%' }}></td><td style={{ width: '25%' }}>Planned</td><td style={{ width: '25%' }}>Actual</td><td style={{ width: '25%' }}>Deviation</td></tr><tr><td><span>150-300</span></td><td>{rowData.BookingDrainageLine150to300mmDiaMeter}</td><td>{(rowData.TaskDrainageLine150to300mmDiaMeter) != null ? rowData.TaskDrainageLine150to300mmDiaMeter : '0'}</td><td>{rowData.BookingDrainageLine150to300mmDiaMeter - rowData.TaskDrainageLine150to300mmDiaMeter}</td></tr><tr><td><span>301-600</span></td><td>{rowData.BookingDrainageLine301to600mmDiaMeter}</td><td>{(rowData.TaskDrainageLine301to600mmDiaMeter) != null ? rowData.TaskDrainageLine301to600mmDiaMeter : '0'}</td><td>{rowData.BookingDrainageLine301to600mmDiaMeter - rowData.TaskDrainageLine301to600mmDiaMeter}</td></tr><tr><td><span>601-900</span></td><td>{rowData.BookingDrainageLine601to900mmDiaMeter}</td><td>{(rowData.TaskDrainageLine601to900mmDiaMeter) != null ? rowData.TaskDrainageLine601to900mmDiaMeter : '0'}</td><td>{rowData.BookingDrainageLine601to900mmDiaMeter - rowData.TaskDrainageLine601to900mmDiaMeter}</td></tr><tr><td><span>901-1200</span></td><td>{rowData.BookingDrainageLine901to1200mmDiaMeter}</td><td>{(rowData.TaskDrainageLine901to1200mmDiaMeter) != null ? rowData.TaskDrainageLine901to1200mmDiaMeter : '0'}</td><td>{rowData.BookingDrainageLine901to1200mmDiaMeter - rowData.TaskDrainageLine901to1200mmDiaMeter}</td></tr></table>) } },
                // { title: 'Task Drainage Line Task', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<table className="fonttable table table-bordered"><tr><td><span>150-300mm </span></td><td>{rowData.TaskDrainageLine150to300mmDiaMeter}</td></tr><tr><td><span>301 to 600mm </span></td><td>{rowData.TaskDrainageLine301to600mmDiaMeter}</td></tr><tr><td><span>601 to 900mm </span></td><td>{rowData.TaskDrainageLine601to900mmDiaMeter}</td></tr><tr><td><span>901 to 1200mm </span></td><td>{rowData.TaskDrainageLine901to1200mmDiaMeter}</td></tr></table>) } },
                // {
                //     title: 'Live View', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => {
                //         return (<span>
                //             <button type="button" className="btn btn-primary" onClick={this.ShowCamera1()}>
                //                 Cam 1
                //             </button>
                //             <hr />
                //             <button type="button" className="btn btn-primary" onClick={this.ShowCamera2()}>
                //                 Cam 2
                //             </button>
                //         </span>)

                //     }
                // },
                // { title: 'LogBook', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{ }</p>) } },
                { title: 'Approve Mobile No', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{ }</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Complete Remarks</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Approval Remarks</span></div>), width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingCompleteRemarks}<hr />{rowData.BookingApprovalRemarks}</p>) } },
                // { title: 'Remarks', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingApprovalRemarks}</p>) } },
            ]
        }
    }

    componentDidMount() {
        this.GetZone();
        this.GetDistrict();
        this.GetMunicipality();
        this.GetWard();
        this.GetVehicle();
        this.GetGudmDashboard();

        // this.props.centerlat("21.186055")
        // this.props.centerlng("72.794200")
        // this.setState({ centerlat: 23.110185 });
        // this.setState({ centerlng: 72.599895 });
        // const polyline=new window.google.maps.Polyline({
        //     //path: this.state.path,
        //     geodesic: true,
        //     strokeColor: '#FF0000',
        //     strokeOpacity: 1.0,
        //     strokeWeight: 2,
        // });
        // polyline.setMap(this.map);
        // this.setState({ polyline });
        // this.handleToggleAnimate();

    }

    GetVehicle() {
        var CompanyID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }

        var data = "";
        data = JSON.stringify({
            "VehicleID": "",
            "CompanyID": CompanyID,
            "IsActive": "true",
            "IsDelete": "false"
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Vehicle/getVehicle",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ vehicles: response.data.data.map(item => ({ value: item.VehicleID, label: item.VehicleNo })) });


            }
            else {
                this.setState({ vehicles: [] })
            }
        }, error => { })
    }

    BindMapData = (bookingid, bookingtaskdetailid, vehicleno, VehicleLeaveParkingDateTime, VehicleReachedLocationDateTime, VehicleReturnLocationDateTime, VehicleReachedParkingDateTime, isstarttofinishcheck, isfinishtostartcheck, iswithincheck, taskno, taskdate, municipalityname, location, checklistdetail) => e => {
        debugger;
        e.preventDefault();
        var vehiclestarttime = "";
        var vehicleendtime = "";
        window.$(this.MapModal).modal('show');
        if (VehicleReturnLocationDateTime == null) {
            var newDate = new Date();
            var now = moment(newDate).format('YYYY-MM-DD HH:mm:ss');
            VehicleReturnLocationDateTime = now;
        }

        if (VehicleReachedParkingDateTime == null) {
            var newDate = new Date();
            var now = moment(newDate).format('YYYY-MM-DD HH:mm:ss');
            VehicleReachedParkingDateTime = now;
        }
        var chk = checklistdetail;
        if (taskno != null) {
            this.setState({ MapTaskNo: taskno });
        }
        if (taskdate != null) {
            this.setState({ MapTaskDate: taskdate });
        }
        if (municipalityname != null) {
            this.setState({ MapMuniciplaity: municipalityname });
        }
        if (location != null) {
            this.setState({ MapLocation: location });
        }
        if (checklistdetail != null) {
            if (checklistdetail[0].BookingDefectCheckListDetail != null) {
                var check = "";
                checklistdetail[0].BookingDefectCheckListDetail.map((value, index) => {
                    check += value.CheckListTitle + ",";
                })
                check = check.slice(0, -1);
                this.setState({ MapChecklistDetail: check });

                this.setState({ DefectLocation: checklistdetail });
            }

            if (checklistdetail[0].Latitude != null) {
                this.setState({ MapLatitude: checklistdetail[0].Latitude });
            }
            if (checklistdetail[0].Longitude != null) {
                this.setState({ MapLongitude: checklistdetail[0].Longitude });
            }
            if (checklistdetail[0].BookingDefectImageDetail != null) {
                this.setState({ MapImages: checklistdetail[0].BookingDefectImageDetail });
            }
        }

        if (isstarttofinishcheck == true) {
            vehiclestarttime = VehicleLeaveParkingDateTime
            vehicleendtime = VehicleReachedLocationDateTime

            this.setState({ BookingID: bookingid });
            this.setState({ BookingTaskDetailID: bookingtaskdetailid });
            this.setState({ VehicleNo: vehicleno });
            this.setState({ VehicleLeaveParkingDateTime: VehicleLeaveParkingDateTime });
            this.setState({ VehicleReachedLocationDateTime: VehicleReachedLocationDateTime });
            this.setState({ VehicleReturnLocationDateTime: VehicleReturnLocationDateTime });
            this.setState({ VehicleReachedParkingDateTime: VehicleReachedParkingDateTime });

            this.setState({ isStartToFinishChecked: true });
            this.setState({ isFinishToStartChecked: false });
            this.setState({ isWithinDataChecked: false });
        }
        else if (isfinishtostartcheck == true) {
            vehiclestarttime = VehicleReturnLocationDateTime
            vehicleendtime = VehicleReachedParkingDateTime

            this.setState({ isStartToFinishChecked: false });
            this.setState({ isFinishToStartChecked: true });
            this.setState({ isWithinDataChecked: false });
        }
        else if (iswithincheck == true) {
            vehiclestarttime = VehicleReachedLocationDateTime
            vehicleendtime = VehicleReturnLocationDateTime

            this.setState({ isStartToFinishChecked: false });
            this.setState({ isFinishToStartChecked: false });
            this.setState({ isWithinDataChecked: true });
        }
        else {
            vehiclestarttime = VehicleLeaveParkingDateTime
            vehicleendtime = VehicleReachedLocationDateTime

            this.setState({ isStartToFinishChecked: true });
            this.setState({ isFinishToStartChecked: false });
            this.setState({ isWithinDataChecked: false });
        }
        this.setState({ currentLocation: [] });
        this.setState({ LiveLocation: "" });
        var data = JSON.stringify({
            BookingID: bookingid,
            BookingTaskDetailID: bookingtaskdetailid,
            VehicleNo: vehicleno,
            VehicleStartTime: vehiclestarttime,
            VehicleEndTime: vehicleendtime
            //VehicleNo: 'GJ01AA0001',
            // VehicleLeaveParkingDateTime: VehicleLeaveParkingDateTime,
            // VehicleReachedLocationDateTime: VehicleReachedLocationDateTime,
            // VehicleReturnLocationDateTime: VehicleReturnLocationDateTime,
            // VehicleReachedParkingDateTime: VehicleReachedParkingDateTime
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "DeviceLocationInformation/getDeviceLocationInformationNew",
            //url: "http://192.168.0.119:1342/api/LocationDevice/getLocationDeviceInformation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            if (response.data.status) {

                this.setState({
                    currentLocation: response.data.data
                })
                //this.setState({ pntmap: "a" });
                const checkdata = [];
                response.data.data.map((items, idx) => {
                    checkdata.push(
                        { lat: parseFloat(items.GpsLat), lng: parseFloat(items.GpsLng) }
                    )
                })
                var last = parseInt(checkdata.length) - 1;
                this.setState({ Lastlong: last });
                this.setState({ parentLocation: checkdata });
                const path = JSON.stringify(checkdata);
                let final = path.replace(/,/g, ',')
                final = final.replaceAll("\"", "");
                this.setState({ DataBind: checkdata })
                this.setState({ LiveLocation: checkdata[0] });

            }
            else {
                this.setState({ LiveLocation: { lat: 23.0204741, lng: 72.4149305 } });
                this.setState({ currentLocation: [] });
                Swal.fire({ position: 'top-end', toast: true, title: 'No Tracking Data Found', icon: "error", showConfirmButton: false, timer: 1500 })
            }



        }, error => { })
    }
    BindOtherMapData = (vehicleno, VehicleLeaveParkingDateTime, VehicleReachedLocationDateTime, VehicleReturnLocationDateTime, VehicleReachedParkingDateTime, isstarttofinishcheck, isfinishtostartcheck, iswithincheck, taskno, taskdate, municipalityname, location, checklistdetail) => e => {
        debugger;
        e.preventDefault();
        var vehiclestarttime = "";
        var vehicleendtime = "";
        var chk = checklistdetail;
        if (taskno != null) {
            this.setState({ MapTaskNo: taskno });
        }
        if (taskdate != null) {
            this.setState({ MapTaskDate: taskdate });
        }
        if (municipalityname != null) {
            this.setState({ MapMuniciplaity: municipalityname });
        }
        if (location != null) {
            this.setState({ MapLocation: location });
        }
        if (checklistdetail != null) {
            if (checklistdetail[0].BookingDefectCheckListDetail != null) {
                var check = "";
                checklistdetail[0].BookingDefectCheckListDetail.map((value, index) => {
                    check += value.CheckListTitle + ",";
                })
                check = check.slice(0, -1);
                this.setState({ MapChecklistDetail: check });

                this.setState({ DefectLocation: checklistdetail });
            }

            if (checklistdetail[0].Latitude != null) {
                this.setState({ MapLatitude: checklistdetail[0].Latitude });
            }
            if (checklistdetail[0].Longitude != null) {
                this.setState({ MapLongitude: checklistdetail[0].Longitude });
            }
            if (checklistdetail[0].BookingDefectImageDetail != null) {
                this.setState({ MapImages: checklistdetail[0].BookingDefectImageDetail });
            }
            if (checklistdetail[0].Remarks != null) {
                this.setState({ MapRemarks: checklistdetail[0].Remarks });
            }
        }

        if (isstarttofinishcheck == true) {
            vehiclestarttime = VehicleLeaveParkingDateTime
            vehicleendtime = VehicleReachedLocationDateTime

            // this.setState({ BookingID: bookingid });
            // this.setState({ BookingTaskDetailID: bookingtaskdetailid });
            this.setState({ VehicleNo: vehicleno });
            this.setState({ VehicleLeaveParkingDateTime: VehicleLeaveParkingDateTime });
            this.setState({ VehicleReachedLocationDateTime: VehicleReachedLocationDateTime });
            this.setState({ VehicleReturnLocationDateTime: VehicleReturnLocationDateTime });
            this.setState({ VehicleReachedParkingDateTime: VehicleReachedParkingDateTime });

            this.setState({ isStartToFinishChecked: true });
            this.setState({ isFinishToStartChecked: false });
            this.setState({ isWithinDataChecked: false });
        }
        else if (isfinishtostartcheck == true) {
            vehiclestarttime = VehicleReturnLocationDateTime
            vehicleendtime = VehicleReachedParkingDateTime

            this.setState({ isStartToFinishChecked: false });
            this.setState({ isFinishToStartChecked: true });
            this.setState({ isWithinDataChecked: false });
        }
        else if (iswithincheck == true) {
            vehiclestarttime = VehicleReachedLocationDateTime
            vehicleendtime = VehicleReturnLocationDateTime

            this.setState({ isStartToFinishChecked: false });
            this.setState({ isFinishToStartChecked: false });
            this.setState({ isWithinDataChecked: true });
        }
        else {
            vehiclestarttime = VehicleLeaveParkingDateTime
            vehicleendtime = VehicleReachedLocationDateTime

            this.setState({ isStartToFinishChecked: true });
            this.setState({ isFinishToStartChecked: false });
            this.setState({ isWithinDataChecked: false });
        }

        this.setState({ currentLocation: [] });
        this.setState({ LiveLocation: "" });
        window.$(this.MapModal).modal('show');

        // if (vehicleno=="GJ 01 KT 2895") {
        //     vehicleno="GJ01KT2895";
        // }
        // else if (vehicleno=="GJ 01 KT 2793") {
        //     vehicleno="GJ01KT2793";
        // }
        // else {
        //     vehicleno="abc";
        // }

        var data = JSON.stringify({
            sdate: vehiclestarttime,
            edate: vehicleendtime,
            dname: vehicleno
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "Report/getTrackingData",
            //url: "http://192.168.0.119:1342/api/LocationDevice/getLocationDeviceInformation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {

                var iserror = response.data.data.includes("error");
                if (iserror == false) {
                    this.setState({
                        currentLocation: response.data.data
                    })
                    //this.setState({ pntmap: "a" });
                    const checkdata = [];
                    response.data.data.map((items, idx) => {
                        checkdata.push(
                            { lat: parseFloat(items.latitude), lng: parseFloat(items.langitude) }
                        )
                    })
                    var last = parseInt(checkdata.length) - 1;
                    this.setState({ Lastlong: last });
                    this.setState({ parentLocation: checkdata });
                    const path = JSON.stringify(checkdata);
                    let final = path.replace(/,/g, ',')
                    final = final.replaceAll("\"", "");
                    this.setState({ DataBind: checkdata })
                    this.setState({ LiveLocation: checkdata[0] });
                }
                else {
                    this.setState({ LiveLocation: { lat: 23.0204741, lng: 72.4149305 } });
                    this.setState({ currentLocation: [] });
                    Swal.fire({ position: 'top-end', toast: true, title: 'No Tracking Data Found', icon: "error", showConfirmButton: false, timer: 1500 })
                }



            }
            else {
                this.setState({ LiveLocation: { lat: 23.0204741, lng: 72.4149305 } });
                this.setState({ currentLocation: [] });
                Swal.fire({ position: 'top-end', toast: true, title: 'No Tracking Data Found', icon: "error", showConfirmButton: false, timer: 1500 })
            }



        }, error => { })

    }
    showImage = () => {
        this.setState({ toggler: true, slide: this.state.slide + 1 });
    };
    UpdateLatLong(Latitide, Longitude) {
        this.setState({ centerlat: parseFloat(Latitide) });
        this.setState({ centerlng: parseFloat(Longitude) });
    }
    BindpolylineMapData(bookingid, bookingtaskdetailid, vehicleno, VehicleLeaveParkingDateTime, VehicleReachedLocationDateTime, VehicleReturnLocationDateTime, VehicleReachedParkingDateTime) {
        //debugger;


        var data = JSON.stringify({
            BookingID: bookingid,
            BookingTaskDetailID: bookingtaskdetailid,
            VehicleNo: vehicleno,
            //VehicleNo: 'GJ01AA0001',
            VehicleLeaveParkingDateTime: VehicleLeaveParkingDateTime,
            VehicleReachedLocationDateTime: VehicleReachedLocationDateTime,
            VehicleReturnLocationDateTime: VehicleReturnLocationDateTime,
            VehicleReachedParkingDateTime: VehicleReachedParkingDateTime
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "DeviceLocationInformation/getDeviceLocationInformation",
            //url: "http://192.168.0.119:1342/api/LocationDevice/getLocationDeviceInformation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {




                const checkdata = [];
                response.data.data.map((items, idx) => {
                    checkdata.push(
                        { lat: parseFloat(items.GpsLat), lng: parseFloat(items.GpsLng) }
                    )
                })
                this.setState({ path: checkdata });
                this.setState({ centerlat: parseFloat(response.data.data[0].GpsLat) }, () => { this.UpdateLatLong(response.data.data[0].GpsLat, response.data.data[0].GpsLng) });
                this.setState({ centerlng: parseFloat(response.data.data[0].GpsLng) });
                console.log("map", this.map);
                var Startmarker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(parseFloat(response.data.data[0].GpsLat), response.data.data[0].GpsLng),
                    map: this.map,
                    icon: "/Images/StartMarker.png"

                });
                var et = parseInt(checkdata.length) - parseInt(1);
                var Endmarker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(parseFloat(checkdata[et].lat), parseFloat(checkdata[et].lng)),
                    map: this.map,
                    icon: "/Images/EndMarker.png"

                });

                const polyline = new window.google.maps.Polyline({
                    //path: this.state.path,
                    geodesic: true,
                    strokeColor: '#FF0000',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                });

                polyline.setMap(this.map);
                this.setState({ polyline });
                this.handleToggleAnimate();
            }
            else {
                Swal.fire({ position: 'top-end', toast: true, title: 'No Tracking Data Found', icon: "error", showConfirmButton: false, timer: 1500 })
            }



        }, error => { })
    }

    BindAutoData = (bookingid, bookingtaskdetailid, vehicleno, VehicleLeaveParkingDateTime, VehicleReachedLocationDateTime, VehicleReturnLocationDateTime, VehicleReachedParkingDateTime) => e => {
        this.setState({ IsMapVisible: true });
        this.setState({ counter: 1 });
        window.$(this.MapModal).modal('show');
        this.BindpolylineMapData(bookingid, bookingtaskdetailid, vehicleno, VehicleLeaveParkingDateTime, VehicleReachedLocationDateTime, VehicleReturnLocationDateTime, VehicleReachedParkingDateTime);
    }
    handleToggleAnimate = () => {
        //debugger;
        this.setState({ animate: !this.state.animate }, () => {
            if (this.state.animate) {
                this.animatePolyline();
                // this.setState({ polyline });
                // this.state.path;
            }
        });
    };
    animatePolyline = () => {
        //debugger;
        if (this.state.path !== null) {
            if (this.state.currentStep >= this.state.path.length) {
                this.setState({ currentStep: 0 }, () => {
                    this.animatePolyline();
                });
                return;
            }
            var counter = this.state.counter;
            counter = counter + 1;
            this.setState({ counter: counter });


            const nextStep = this.state.currentStep + 1;
            const start = this.state.path[this.state.currentStep];
            var end = this.state.path[nextStep];

            if (end === undefined) {
                end = start;
            }

            const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
                new window.google.maps.LatLng(start),
                new window.google.maps.LatLng(end)
            );
            //debugger;
            // this.setState({ centerlat: end.lat });
            // this.setState({ centerlng: end.lng });
            this.setState({ LiveLocation: end });
            this.forceUpdate();
            // this.setState({ centerlat: 21.1889981 });
            // this.setState({ centerlng: 72.8092666 });


            const speed = 300; // km/h
            const duration = (distance / speed) * 1000; // milliseconds
            //const duration=1;
            if (this.state.polyline !== null) {
                const polylinePath = this.state.polyline.getPath();
                const step = 10; // meters
                let travelled = 0;

                const timer = setInterval(() => {
                    if (!this.state.animate) {
                        ////debugger;
                        clearInterval(timer);
                        return;
                    }

                    travelled += step;
                    const newPosition = window.google.maps.geometry.spherical.interpolate(
                        new window.google.maps.LatLng(start),
                        new window.google.maps.LatLng(end),
                        travelled / distance
                    );

                    polylinePath.push(newPosition);
                    this.setState({ currentStep: nextStep });
                    if (travelled >= distance) {
                        ////debugger;
                        clearInterval(timer);
                        this.animatePolyline();
                    }
                }, duration / (distance / step));
            }
        }
    };
    markerClicked(props, marker, activemarker) {
        debugger;
        //e.preventDefault();
        this.setState({
            showInfoWindow: true,
            activeMarker: activemarker,
        });

        this.setState({ MapLatitude: props.Latitude })
        this.setState({ MapLongitude: props.Longitude })
        this.setState({ MapRemarks: props.Remarks });
        debugger;
        var checklistdetail = props;
        if (checklistdetail.BookingDefectImageDetail != null) {
            this.setState({ MapImages: checklistdetail.BookingDefectImageDetail });
        }

        if (checklistdetail.BookingDefectCheckListDetail != null) {
            var check = "";
            checklistdetail.BookingDefectCheckListDetail.map((value, index) => {
                check += value.CheckListTitle + ",";
            })
            check = check.slice(0, -1);
            this.setState({ MapChecklistDetail: check });

            //this.setState({DefectLocation:checklistdetail});
        }
    }
    showMarkers = () => {
        return this.state.currentLocation.map((store, index) => {
            // return <Marker label={index.toString()} key={index} id={index} position={{
            //     lat: store.GpsLat,
            //     lng: store.GpsLng
            // }}
            return <Marker
                icon={
                    (index == 0 && this.state.isStartToFinishChecked == true) ?
                        {

                            url: "/Images/parkingstart.png"
                            // ,
                            // scaledSize: new this.props.google.maps.Size(100, 100)
                        } :
                        (index == 0 && this.state.isFinishToStartChecked == true) ?
                            {
                                url: "/Images/locationleave.png"
                            } :
                            (index == 0 && this.state.isWithinDataChecked == true) ?
                                {
                                    url: "/Images/locationreach.png"
                                } :
                                (index == this.state.Lastlong && this.state.isStartToFinishChecked == true) ? {
                                    url: "/Images/locationreach.png"
                                    // ,
                                    // scaledSize: new this.props.google.maps.Size(100, 100)
                                } :
                                    (index == this.state.Lastlong && this.state.isFinishToStartChecked == true) ?
                                        {
                                            url: "/Images/parkingend.png"
                                        } :
                                        (index == this.state.Lastlong && this.state.isWithinDataChecked == true) ?
                                            {
                                                url: "/Images/locationleave.png"
                                            } :
                                            ''}

                // label={{
                //     text: (index==0)? 'Start':(index==this.state.Lastlong)? 'Last':''
                //   }}
                // title={(index==0)? 'Start':(index==this.state.Lastlong)? 'Last':''}
                // label={
                //     (index==0)? 'Start':(index==this.state.Lastlong)? 'Last':''
                // }
                key={index} id={index} position={
                    ((index == 0) || (index == this.state.Lastlong)) ?
                        ({
                            lat: store.latitude,
                            lng: store.langitude
                        })


                        // :(index==this.state.Lastlong)? {
                        //     lat: store.GpsLat,
                        //     lng: store.GpsLng
                        // }
                        : ''}
                onClick={() => console.log("Clicked")}
            //onClick={this.markerClicked.bind(this)}
            >
            </Marker>
        })
    }
    showDefectMarkers = () => {
        return this.state.DefectLocation.map((store, index) => {
            // return <Marker label={index.toString()} key={index} id={index} position={{
            //     lat: store.GpsLat,
            //     lng: store.GpsLng
            // }}
            return <Marker
                icon={
                    { url: "/Images/defectlocation.png" }}
                key={index} id={index} position={
                    {
                        lat: store.Latitude,
                        lng: store.Longitude
                    }}
                //onClick={() => console.log("Clicked")} 
                onClick={this.markerClicked.bind(this, store)}
            >
            </Marker>
        })
    }
    GetImages = (bookingid, bookingtaskdetailid, vehicleno, VehicleLeaveParkingDateTime, VehicleReachedLocationDateTime, VehicleReturnLocationDateTime, VehicleReachedParkingDateTime) => e => {
        debugger;
        e.preventDefault();
        var data = JSON.stringify({
            BookingID: bookingid,
            BookingTaskDetailID: bookingtaskdetailid,
            VehicleNo: vehicleno,
            //VehicleNo: 'GJ01AA0001',
            VehicleLeaveParkingDateTime: VehicleLeaveParkingDateTime,
            VehicleReachedLocationDateTime: VehicleReachedLocationDateTime,
            VehicleReturnLocationDateTime: VehicleReturnLocationDateTime,
            VehicleReachedParkingDateTime: VehicleReachedParkingDateTime
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "CCDImages/getCCDImagesData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {

                this.setState({ isOpen: true });
                this.setState({ images: response.data.data });
                var data = [];
                for (var i = 0; i < response.data.data.length; i++) {
                    //data += response.data.data[i].path
                    data.push(response.data.data[i].path)
                    //data.concat(response.data.data[i].path)
                }


                this.setState({ images: data });
                var img = data;
                //debugger;
                const copyGroups = JSON.parse(JSON.stringify(img));

                //this.setState({ WardID: [] })

            }
            else {
                this.setState({ images: [] })
                Swal.fire({ position: 'top-end', toast: true, title: 'No Images Available', icon: "error", showConfirmButton: false, timer: 1500 })
            }
        }, error => { })
    }

    GetTimeSlotModal = (bookingid, bookingtaskdetailid, vehicleno, VehicleLeaveParkingDateTime, VehicleReachedParkingDateTime) => e => {
        debugger;
        e.preventDefault();
        this.setState({ BookingID: bookingid });
        this.setState({ BookingTaskDetailID: bookingtaskdetailid });
        this.setState({ VehicleNo: vehicleno });

        if (VehicleReachedParkingDateTime == null) {
            var newDate = new Date();
            var now = moment(newDate).format('YYYY-MM-DD HH:mm:ss');
            VehicleReachedParkingDateTime = now;
        }

        var data = JSON.stringify({
            //VehicleNo: vehicleno,
            VehicleLeaveParkingDateTime: VehicleLeaveParkingDateTime,
            VehicleReachedParkingDateTime: VehicleReachedParkingDateTime
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "CCDImages/getTimeSlotData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ timeslotdata: response.data.data });
                window.$(this.TimeModal).modal('show');
            }
            else {
                this.setState({ images: [] })
                Swal.fire({ position: 'top-end', toast: true, title: 'No Images Available', icon: "error", showConfirmButton: false, timer: 1500 })
            }
        }, error => { })
    }

    GetImageSlotWise = (StartTimeSlot, EndTimeSlot) => e => {
        e.preventDefault();
        debugger;
        var BookingID = this.state.BookingID;
        var BookingTaskDetailID = this.state.BookingTaskDetailID;
        var VehicleNo = this.state.VehicleNo;
        var data = JSON.stringify({
            "BookingID": BookingID,
            "BookingTaskDetailID": BookingTaskDetailID,
            "VehicleNo": VehicleNo,
            "StartTimeSlot": StartTimeSlot,
            "EndTimeSlot": EndTimeSlot
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "CCDImages/getCCDImagesDataTimeSlotWise",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ toggler: !this.state.toggler });
                this.setState({ isOpen: true });
                this.setState({ images: response.data.data });
                var data = [];
                for (var i = 0; i < response.data.data.length; i++) {
                    //data += response.data.data[i].path
                    data.push(response.data.data[i].path)
                    //data.concat(response.data.data[i].path)
                }


                this.setState({ images: data });
                this.setState({ imageData: data });
                var img = data;
                //debugger;
                const copyGroups = JSON.parse(JSON.stringify(img));

                //this.setState({ WardID: [] })

            }
            else {
                //this.setState({ toggler: !this.state.toggler });
                // this.setState({ toggler: null });
                // this.state({imageData:[]});
                this.setState({ images: [] })
                Swal.fire({ position: 'top-end', toast: true, title: 'No Images Available', icon: "error", showConfirmButton: false, timer: 1500 })
            }
        }, error => { })
    }

    GetTimeModal = (bookingid, bookingtaskdetailid, vehicleno, VehicleLeaveParkingDateTime, VehicleReachedLocationDateTime, VehicleReturnLocationDateTime, VehicleReachedParkingDateTime) => e => {
        debugger;

        e.preventDefault();
        var data = JSON.stringify({
            BookingID: bookingid,
            BookingTaskDetailID: bookingtaskdetailid,
            VehicleNo: vehicleno,
            //VehicleNo: 'GJ01AA0001',
            VehicleLeaveParkingDateTime: VehicleLeaveParkingDateTime,
            VehicleReachedLocationDateTime: VehicleReachedLocationDateTime,
            VehicleReturnLocationDateTime: VehicleReturnLocationDateTime,
            VehicleReachedParkingDateTime: VehicleReachedParkingDateTime
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "CCDImages/getCCDImagesData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                // this.setState({ toggler: !this.state.toggler });
                // this.setState({ images: response.data.data });
                var data = [];
                for (var i = 0; i < response.data.data.length; i++) {
                    //data += response.data.data[i].path
                    data.push(response.data.data[i].path)
                    //data.concat(response.data.data[i].path)
                }

                window.$(this.TimeModal).modal('show');
                window.$(this.TimeModal).modal('backdrop:static, keyboard: false');
                // this.setState({ imageData: data });
                // var img=data;
                // //debugger;
                // const copyGroups=JSON.parse(JSON.stringify(img));

                //this.setState({ WardID: [] })

            }
            else {
                this.setState({ images: [] })
                Swal.fire({ position: 'top-end', toast: true, title: 'No Images Available', icon: "error", showConfirmButton: false, timer: 1500 })
            }
        }, error => { })
    }
    ShowCamera1 = () => e => {
        e.preventDefault();
        window.$(this.Camera1Modal).modal('show');
    }
    ShowCamera2 = () => e => {
        e.preventDefault();
        window.$(this.Camera2Modal).modal('show');
    }
    // Functions
    GetGudmDashboard() {
        try {
            debugger;
            var CompanyID = "";
            var ClientID = "";
            var UserID = "";
            var ZoneID = "";
            var DistrictID = "";
            var MunicipalityID = "";
            var WardID = "";
            var Status = "";
            var UserType = "";
            var VehicleID = "";
            var StartDate = "";
            var EndDate = "";
            if (this.props.allCookies.MainCompanyID !== undefined) {
                CompanyID = this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID !== undefined) {
                ClientID = this.props.allCookies.MainClientID
            }
            if (this.props.allCookies.PanelUserType !== undefined) {
                UserType = this.props.allCookies.PanelUserType
            }

            if (this.state.ZoneID != null) {
                var tempZoneID = this.state.ZoneID.map(

                    item => (
                        ZoneID += item + ","
                    )
                );
                ZoneID = ZoneID.slice(0, -1)
            }
            if (this.state.DistrictID != null) {
                var tempDistrictID = this.state.DistrictID.map(

                    item => (
                        DistrictID += item + ","
                    )
                );
                DistrictID = DistrictID.slice(0, -1)
            }
            if (this.state.MunicipalityID != null) {
                var tempMunicipalityID = this.state.MunicipalityID.map(

                    item => (
                        MunicipalityID += item + ","
                    )
                );
                MunicipalityID = MunicipalityID.slice(0, -1)
            }
            if (this.state.WardID != null) {
                var tempWardID = this.state.WardID.map(

                    item => (
                        WardID += item + ","
                    )
                );
                WardID = WardID.slice(0, -1)
            }

            if (this.state.StatusID.value != "--Select Status--") {
                Status = this.state.StatusID.value
            }
            if (MunicipalityID == "") {
                if (UserType == "Municipality") {
                    if (this.props.allCookies.MunicipalityID !== undefined) {
                        MunicipalityID = this.props.allCookies.MunicipalityID
                    }
                }
            }

            if (this.state.VehicleID.value != "--Select Vehicle--") {
                VehicleID = this.state.VehicleID.value;
            }
            var data = "";
            data = JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID,
                ZoneID: ZoneID,
                DistrictID: DistrictID,
                MuncipalityID: MunicipalityID,
                WardID: WardID,
                VehicleID: VehicleID,
                BookingStatus: Status,
                StartDate: moment(this.state.StartDate, "DD-MM-YYYY"),
                EndDate: moment(this.state.EndDate, "DD-MM-YYYY"),
            });


            var config = {
                method: 'POST',
                url: services.API_URL + "Report/getGUDMDashboardReport",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                if (response.data.status) {
                    this.setState({ data: response.data.data })
                }
                else {
                    this.setState({ data: [] })
                }

            }, error => { })
        } catch (error) {

        }

    }
    GetZone() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        var data = "";
        data = JSON.stringify({
            ZoneID: "",
            CompanyID: CompanyID,
            ClientID: ClientID
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Zone/getZoneDropDownData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ zones: response.data.data.map(item => ({ value: item.ZoneID, label: item.ZoneName })) });
                this.setState({ ZoneID: [] })

            }
            else {
                this.setState({ zones: [] })
            }
        }, error => { })
    }
    GetDistrict() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }
        var data = "";
        data = JSON.stringify({
            "DistrictID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "ZoneID": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "District/getDistrictDropDownData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ districts: response.data.data.map(item => ({ value: item.DistrictID, label: item.DistrictName })) });
                this.setState({ DistrictID: [] })

            }
            else {
                this.setState({ districts: [] })
            }
        }, error => { })
    }
    GetMunicipality() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        var data = "";
        data = JSON.stringify({
            "MuncipalityID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "DistrictID": "",
            "ZoneID": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Muncipality/getMuncipalityDropDownData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ municipalities: response.data.data.map(item => ({ value: item.MuncipalityID, label: item.MuncipalityName })) });
                this.setState({ MuncipalityID: [] })

            }
            else {
                this.setState({ municipalities: [] })
            }
        }, error => { })
    }
    GetWard() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }

        var data = "";
        data = JSON.stringify({
            "WardID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "MuncipalityID": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Ward/getWard",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ wards: response.data.data.map(item => ({ value: item.WardID, label: item.WardName })) });
                this.setState({ WardID: [] })

            }
            else {
                this.setState({ wards: [] })
            }
        }, error => { })
    }
    // Event Change
    onStartDateChange = (e) => {
        this.setState({ StartDate: e });
        this.setState({ EndDate: e });
    }
    onZoneChange = (value) => {
        ////debugger;
        this.setState({ ZoneID: value });

    }
    onDistrictChange = (value) => {
        ////debugger;
        this.setState({ DistrictID: value });

    }
    onMunicipalityChange = (value) => {
        ////debugger;
        this.setState({ MunicipalityID: value });
    }
    onWardChange = (value) => {
        ////debugger;
        this.setState({ WardID: value });
    }
    onStatusChange = (e) => {
        if (e !== null) {
            this.setState({ StatusID: e });
        }
        else {
            this.setState({ StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' } });
        }
    }
    onVehicleChange = (e) => {
        if (e !== null) {
            this.setState({ VehicleID: e });
        }
        else {
            this.setState({ VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' } });
        }
    }
    // Click Events
    OnFilterClick = (e) => {
        e.preventDefault();
        if (this.state.isFilterVisible === false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }
    onSearchClick = (e) => {
        e.preventDefault();
        this.GetGudmDashboard();
    }
    OnSearchCancelClick = (e) => {
        this.setState({
            data: [], zones: [], districts: [], municipalities: [], wards: [],
            ZoneID: [], DistrictID: [], MunicipalityID: [], WardID: [],
            BookingNo: "",
            status: [{ 'label': 'New', 'value': 'New' }, { 'label': 'Assigned', 'value': 'Assigned' },
            { 'label': 'Started', 'value': 'Started' }, { 'label': 'Finished', 'value': 'Finished' },
            { 'label': 'Completed', 'value': 'Completed' }, { 'label': 'Approved', 'value': 'Approved' },
            { 'label': 'Referred Back', 'value': 'Referred Back' }],
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' },
            StartDate: new Date(), EndDate: new Date(),

        }, () => { this.GetGudmDashboard(); });
        this.GetZone();
        this.GetDistrict();
        this.GetMunicipality();
        this.GetWard();

    }
    ExcelClick = () => {
        exportToExcel(this.state.cols, this.state.data)
    }
    UpdateCounter = () => {
        this.setState({ counter: null });
    }
    closeMapModal = (e) => {
        debugger;
        e.preventDefault();
        // this.setState({ IsMapVisible: false });
        // this.setState({ counter: null },()=>{this.UpdateCounter()});
        // this.setState({ currentStep: 0 })
        // this.setState({
        //     path: [
        //         {
        //             lat: 0,
        //             lng: 0
        //         }
        //     ]
        // });
        // const polyline=new window.google.maps.Polyline({
        //     //path: this.state.path,
        //     geodesic: true,
        //     strokeColor: '#FF0000',
        //     strokeOpacity: 1.0,
        //     strokeWeight: 2,
        // });



        //  polyline.setMap(null);
        // this.setState({ polyline });
        //this.setState({ polyline: null });
        this.setState({
            DataBind: [], currentLocation: [], LiveLocation: "", Lastlong: "", isStartToFinishChecked: true,
            isFinishToStartChecked: false, isWithinDataChecked: false,
        })
        window.$(this.MapModal).modal('hide');
        // window.location.reload(false);
    }
    onPrintClick = (e) => {
        this.setState({ isButtonVisible: false });
        this.setState({ PDFStatus: true }, () => { this.generateImage() });
    }
    generateImage = () => {
        debugger;
        const img = document.getElementById('divToPrint');
        var id = document.getElementById('divToPrint');
        id.style.display = 'block';

        htmlToImage.toJpeg(img, { quality: 1, backgroundColor: "#FFFFFF", height: img.clientHeight, width: img.clientWidth })
            .then(function (dataUrl) {
                debugger;
                const doc = new jsPDF();
                doc.deletePage(1);

                const imageDimensions = imageDimensionsOnA4({
                    width: 210,
                    height: 210,
                });

                doc.addPage();
                doc.addImage(
                    dataUrl,
                    //image.imageType,
                    (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
                    (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
                    imageDimensions.width,
                    //imageDimensions.height
                    100
                );
                const pdfURL = doc.output("bloburl");
                //window.open(pdfURL, "_blank");
                id.style.display = 'none';
                let alink = document.createElement('a');
                alink.href = pdfURL;
                alink.download = "GudmDashboard_" + moment().format('DD-MM-YYYY_HH:mm') + ".pdf";
                alink.click();
                //window.location.reload(true);

            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }
    // componentDidUpdate() {
    //     if (this.map !== this.ref.map) {
    //       console.log("ref changed!");
    //     }

    //     this.map = this.ref.map;
    //   }
    render() {
        const { photoIndex, isOpen } = this.state;
        const { cookies } = this.props
        const { showInfoWindow, activeMarker } = this.state;
        //debugger;
        // var Permission=cookies.cookies.GUDMDashboard;
        var Permission = cookies.cookies.GudmDashboard;
        var maps = this.map;
        var TotalPermission = "";
        if (Permission !== undefined) {
            TotalPermission = Permission.split(',');
            if (this.state.AddStatus == "") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row" style={{ marginBottom: '1%' }}>
                        {(this.props.allCookies.Dashboard !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                <NavLink to={"/dashboard"} className="btn btn-outline-primary font-weight-bolds">Statistics
                                </NavLink>
                            </div>) : ''}
                        {(this.props.allCookies.GudmDashboard !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                {/* <NavLink to={"/gudmdashboard"} className="btn btn-primary font-weight-bolds">Gudm
                                </NavLink> */}
                                <NavLink to={"/gudmdashboard"} className="btn btn-primary font-weight-bolds">Tasks
                                </NavLink>
                            </div>) : ''}
                        {(this.props.allCookies.GudmSummary !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                {/* <NavLink to={"/gudmsummary"} className="btn btn-outline-primary font-weight-bolds">Gudm Summary
                                </NavLink> */}
                                <NavLink to={"/gudmsummary"} className="btn btn-outline-primary font-weight-bolds">Tasks Summary
                                </NavLink>
                            </div>) : ''}
                        {(this.props.allCookies.WorkInProgressDashboard !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                <NavLink to={"/vehicledashboard"} className="btn btn-outline-primary font-weight-bolds">Work In Progress
                                </NavLink>
                            </div>) : ''}
                        <div className="col-md-3" style={{ maxWidth: 'fit-content' }}>
                            {/* <button type="button" className="btn btn-outline-primary font-weight-bolds" onClick={this.ShowCamera1()}>
                                Live Streaming
                            </button> */}
                            {/* <a className="btn btn-outline-primary font-weight-bolds" target="_blank" href="http://mwhdia.in:3073/">
                                Live Streaming
                            </a> */}
                        </div>
                        {/* <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                            <NavLink to={"/livegps"} className="btn btn-outline-primary font-weight-bolds">Live GPS Tracking
                            </NavLink>
                        </div> */}
                    </div>
                    {this.state.isFilterVisible &&
                        <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Start Date </label>
                                    <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.StartDate} onChange={this.onStartDateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>End Date </label>
                                    <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.EndDate} minDate={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.EndDate} onChange={(e) => {
                                        this.setState({ EndDate: e });
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Zone </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.zones} selected={this.state.ZoneID} checked={false}
                                        onSelectedChanged={this.onZoneChange}
                                        overrideStrings={{
                                            selectSomeItems: "Zones", allItemsAreSelected: "All Zones are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>District </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.districts} selected={this.state.DistrictID} checked={false}
                                        onSelectedChanged={this.onDistrictChange}
                                        overrideStrings={{
                                            selectSomeItems: "Districts", allItemsAreSelected: "All Districts are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Municipality </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.municipalities} selected={this.state.MunicipalityID} checked={false}
                                        onSelectedChanged={this.onMunicipalityChange}
                                        overrideStrings={{
                                            selectSomeItems: "Municipalities", allItemsAreSelected: "All Municipalities are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Ward </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.wards} selected={this.state.WardID} checked={false}
                                        onSelectedChanged={this.onWardChange}
                                        overrideStrings={{
                                            selectSomeItems: "Wards", allItemsAreSelected: "All Wards are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Vehicle </label>
                                    <Select isClearable={true} options={this.state.vehicles} value={this.state.VehicleID} onChange={this.onVehicleChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Status </label>
                                    <Select isClearable={true} options={this.state.status} value={this.state.StatusID} onChange={this.onStatusChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button type="button" onClick={this.onSearchClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                <button type="button" onClick={this.OnSearchCancelClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-body">
                                    <MaterialTable columns={this.state.cols} data={this.state.data}

                                        options={{
                                            headerStyle: { color: 'black' }, toolbar: true, search: false, draggable: false,
                                            paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                            detailPanelType: "single",
                                            rowStyle: rowData => {
                                                if (rowData.IsRefferBack == "True") {
                                                    return { backgroundColor: '#F64E60' };
                                                }
                                                return {};
                                            }
                                        }}
                                        detailPanel={[
                                            {

                                                icon: 'add', tooltip: '', title: 'show',
                                                render: ({ rowData }) => {
                                                    return (
                                                        <>
                                                            <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                <h3 className="tabletitle">Image Details</h3>
                                                            </div>
                                                            <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <td style={{ width: '50%' }}>Start Task</td>
                                                                        <td style={{ width: '50%' }}>Finish Task</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>
                                                                            <div className="row">
                                                                                {
                                                                                    (rowData.BookingTaskImageDetail != null) ?
                                                                                        (
                                                                                            rowData.BookingTaskImageDetail.map((value) => {
                                                                                                if (value.WorkImageType == "Start") {
                                                                                                    return (
                                                                                                        <div className="col-md-2" style={{ marginBottom: '2%' }}>
                                                                                                            <a href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage} target="_blank">
                                                                                                                <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage}></img>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    );
                                                                                                }

                                                                                            })
                                                                                        ) : '-'
                                                                                }</div></th>
                                                                        <th>
                                                                            <div className="row">
                                                                                {
                                                                                    (rowData.BookingTaskImageDetail != null) ?
                                                                                        (
                                                                                            rowData.BookingTaskImageDetail.map((value) => {
                                                                                                if (value.WorkImageType == "Finish") {
                                                                                                    return (
                                                                                                        <div className="col-md-2" style={{ marginBottom: '2%' }}>
                                                                                                            <a href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage} target="_blank">
                                                                                                                <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + value.WorkImage}></img>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    );
                                                                                                }

                                                                                            })
                                                                                        ) : '-'
                                                                                }</div></th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </>
                                                        // rowData.BookingTaskDetail.map((value) => {

                                                        // })
                                                    )
                                                }

                                            },
                                            {

                                                icon: 'add', tooltip: '', title: 'show',
                                                render: ({ rowData }) => {
                                                    return (
                                                        <>
                                                            <div className="col-md-12" style={{ marginTop: '1%', marginBottom: '1%', textAlign: 'center' }}>
                                                                <h3 className="tabletitle">Defect Details</h3>
                                                            </div>
                                                            <table className="table table-light-dark" align="center" cellSpacing="0" rules="all" border="1" style={{ width: '95%', borderCollapse: 'collapse' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <td style={{ width: '10%' }}>Defect Name</td>
                                                                        <td style={{ width: '20%' }}>Checklist Details</td>
                                                                        <td style={{ width: '30%' }}>Images</td>
                                                                        <td style={{ width: '20%' }}>Latitude <hr style={{ marginTop: '5px', marginBottom: '5px' }} />Longitude<hr style={{ marginTop: '5px', marginBottom: '5px' }} />Address</td>
                                                                        <td style={{ width: '20%' }}>Remarks</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        (rowData.BookingDefectDetail != null) ?
                                                                            (
                                                                                rowData.BookingDefectDetail.map((value) => {
                                                                                    return (
                                                                                        <tr className="mapfonts">
                                                                                            <td>
                                                                                                <span>{value.DefectName}</span>
                                                                                            </td>
                                                                                            <td>
                                                                                                {
                                                                                                    (value.BookingDefectCheckListDetail != null) ?
                                                                                                        (
                                                                                                            value.BookingDefectCheckListDetail.map((value) => {
                                                                                                                return (
                                                                                                                    <span className="permissionborderdata">{value.CheckListTitle}</span>
                                                                                                                );
                                                                                                            })
                                                                                                        ) : ''
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="row">
                                                                                                    {
                                                                                                        (value.BookingDefectImageDetail != null) ?
                                                                                                            (

                                                                                                                value.BookingDefectImageDetail.map((value) => {
                                                                                                                    return (
                                                                                                                        <div className="col-md-2">
                                                                                                                            <a href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.DefectImage} target="_blank">
                                                                                                                                <img className="img-thumbnail" target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.DefectImage} style={{ height: '80px', width: '80px', marginRight: '3%', marginBottom: '3%', objectFit: 'contain' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + value.DefectImage}></img>
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    );
                                                                                                                })
                                                                                                            ) : ''
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span>{value.Latitude}</span><hr />
                                                                                                <span>{value.Longitude}</span><hr />
                                                                                                <span>{value.Address}</span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span>{value.Remarks}</span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })) : <tr><td className="mapfonts" colSpan={7}><span>No Data Available</span></td>

                                                                            </tr>}
                                                                </tbody>
                                                            </table>
                                                        </>
                                                        // rowData.BookingTaskDetail.map((value) => {

                                                        // })
                                                    )
                                                }

                                            }

                                        ]}

                                        components={{

                                            Toolbar: props => (
                                                <div className="row" style={{ marginBottom: '2%' }}>
                                                    <div className="col-md-8" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h3 className="tabletitle">Tasks Dashboard</h3>

                                                    </div>
                                                    <div className="col-md-4" style={{ textAlign: 'right' }}>
                                                        <a className="btn btn-outline-dark font-weight-bolder" onClick={this.OnFilterClick}>
                                                            <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                        </a>
                                                        {this.state.ExcelStatus === "ExcelStatus:true" &&
                                                            <a className="btn btn-outline-success font-weight-bolder ml-4" onClick={this.state.data.length > 0 ? this.ExcelClick : ''}>
                                                                <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                            </a>
                                                        }

                                                    </div>
                                                </div>
                                            )
                                        }}


                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <FsLightbox
                    toggler={this.state.toggler}
                    sources={this.state.imageData}
                /> */}
                {isOpen && (
                    <Lightbox
                        mainSrc={this.state.images[photoIndex]}
                        nextSrc={this.state.images[(photoIndex + 1) % this.state.images.length]}
                        prevSrc={this.state.images[(photoIndex + this.state.images.length - 1) % this.state.images.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + this.state.images.length - 1) % this.state.images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % this.state.images.length,
                            })
                        }
                    />



                )}
                <div className="modal fade" ref={modal => this.MapModal = modal} data-bs-keyboard="false" data-bs-backdrop="static" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog mapdialog" role="document">
                        <div id={'divToPrint'}>
                            <div className="modal-content" style={{ height: '700px' }}>

                                <div className="modal-header" style={{ display: 'block' }}>
                                    {

                                        (
                                            <>
                                                <button className={(this.state.isStartToFinishChecked) == true ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={this.BindOtherMapData(this.state.VehicleNo, this.state.VehicleLeaveParkingDateTime, this.state.VehicleReachedLocationDateTime, this.state.VehicleReturnLocationDateTime, this.state.VehicleReachedParkingDateTime, true, false, false, null, null, null, null, null)}>Start to Finish</button>
                                                <button className={(this.state.isFinishToStartChecked) == true ? 'btn btn-primary' : 'btn btn-outline-primary'} style={{ marginLeft: '1%' }} onClick={this.BindOtherMapData(this.state.VehicleNo, this.state.VehicleLeaveParkingDateTime, this.state.VehicleReachedLocationDateTime, this.state.VehicleReturnLocationDateTime, this.state.VehicleReachedParkingDateTime, false, true, false, null, null, null, null, null)}>Finish to Start</button>
                                                <button className={(this.state.isWithinDataChecked) == true ? 'btn btn-primary' : 'btn btn-outline-primary'} style={{ marginLeft: '1%', marginRight: '1%' }} onClick={this.BindOtherMapData(this.state.VehicleNo, this.state.VehicleLeaveParkingDateTime, this.state.VehicleReachedLocationDateTime, this.state.VehicleReturnLocationDateTime, this.state.VehicleReachedParkingDateTime, false, false, true, null, null, null, null, null)}>Within Location</button>
                                            </>
                                        )
                                    }


                                    <img src="/Images/parkingstart.png" style={{ height: '30px' }}></img>
                                    <span style={{ fontWeight: 'bold', fontFamily: '"Poppins"' }}>Parking Start</span>
                                    <img src="/Images/locationreach.png" style={{ height: '30px' }}></img>
                                    <span style={{ fontWeight: 'bold', fontFamily: '"Poppins"' }}>Location Reached</span>
                                    <img src="/Images/locationleave.png" style={{ height: '30px' }}></img>
                                    <span style={{ fontWeight: 'bold', fontFamily: '"Poppins"' }}>Location Leave</span>
                                    <img src="/Images/parkingend.png" style={{ height: '30px' }}></img>
                                    <span style={{ fontWeight: 'bold', fontFamily: '"Poppins"' }}>Parking Reached</span>
                                    <img src="/Images/defectlocation.png" style={{ height: '30px' }}></img>
                                    <span style={{ fontWeight: 'bold', fontFamily: '"Poppins"' }}>Defect Location</span>

                                    {this.state.isButtonVisible &&
                                        <>
                                            <button className="btn btn-danger float-lg-right" onClick={this.closeMapModal}>Close</button>
                                            <button className="btn btn-primary float-lg-right" onClick={this.onPrintClick} style={{ marginRight: '1%' }}>Print</button>
                                        </>
                                    }
                                    <div className="row mapfonts" style={{ marginTop: '2%' }} >
                                        <div className="col-md-3" style={{ maxWidth: 'max-content' }}>
                                            <span>Task No : {this.state.MapTaskNo}</span>
                                        </div>
                                        <div className="col-md-3" style={{ maxWidth: 'max-content' }}>
                                            <span>Task Date : {this.state.MapTaskDate}</span>
                                        </div>
                                        <div className="col-md-3" style={{ maxWidth: 'max-content' }}>
                                            <span>Municipality : {this.state.MapMuniciplaity}</span>
                                        </div>
                                        <div className="col-md-3">
                                            <span>Location : {this.state.MapLocation}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body" style={{ paddingLeft: '12px', paddingTop: '0' }}>
                                    <div className="row" style={{ display: 'block' }}>
                                        {
                                            ((this.state.DataBind !== null) ? (
                                                <Map
                                                    google={this.props.google}
                                                    zoom={18}
                                                    style={mapStyles}
                                                    center={this.state.LiveLocation}
                                                >
                                                    {/* {this.state.LiveLocation} */}
                                                    {this.showMarkers()}
                                                    {this.showDefectMarkers()}
                                                    <Polyline
                                                        options={{
                                                            strokeColor: "blue",
                                                            strokeOpacity: 0.75,
                                                            strokeWeight: 2,
                                                            // icons: [
                                                            //     {
                                                            //         icon: {
                                                            //             path: "M -2,0 0,-2 2,0 0,2 z",
                                                            //             strokeColor: "blue",
                                                            //             fillColor: "red",
                                                            //             fillOpacity: 1,
                                                            //             // scale: 1.1,
                                                            //             // zoom: 16
                                                            //         },
                                                            //         offset: "0",
                                                            //         repeat: "40px"
                                                            //     }
                                                            // ]

                                                        }}
                                                        path={
                                                            this.state.DataBind
                                                        }
                                                    />

                                                    <InfoWindow
                                                        marker={activeMarker}
                                                        visible={showInfoWindow}>
                                                        <div className="row">
                                                            <div className="col-md-12" style={{ marginBottom: '5%' }}>
                                                                <strong>Checklist : </strong>
                                                                <div style={{ marginTop: '2%' }}>
                                                                    {this.state.MapChecklistDetail}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <strong>Latitude : </strong>
                                                                <div style={{ marginTop: '2%' }}>
                                                                    {this.state.MapLatitude}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <strong>Longitude : </strong>
                                                                <div style={{ marginTop: '2%' }}>
                                                                    {this.state.MapLongitude}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12" style={{ marginTop: '5%' }}>
                                                                {this.state.MapImages.map((value, index) => {
                                                                    return (
                                                                        <a target="_blank" href={process.env.REACT_APP_BOOKINGIMAGE_URL + value.DefectImage}>
                                                                            <img className="img-thumbnail" style={{ height: '40px', width: '40px', marginRight: '3%', marginBottom: '3%' }} src={process.env.REACT_APP_BOOKINGIMAGE_URL + value.DefectImage} />
                                                                        </a>
                                                                    );
                                                                })}
                                                            </div>
                                                            <div className="col-md-12" style={{ marginTop: '5%' }}>
                                                                <strong>Remarks : </strong>
                                                                <div style={{ marginTop: '2%' }}>
                                                                    {this.state.MapRemarks}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </InfoWindow>
                                                </Map>) : '')
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.Camera1Modal = modal} id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog mapdialog" role="document">
                        <div className="modal-content" style={{ height: '700px' }}>
                            <div className="modal-body" style={{ paddingLeft: '0', paddingTop: '0', paddingRight: '0' }}>
                                <div className="row" style={{ display: 'block', width: '100%', marginLeft: '0', marginRight: '0' }}>
                                    {/* <ReactFlvPlayer
                                        url="http://14.99.68.5:12060/live.flv?devid=00BD001497&chl=2&svrid=127.0.0.1&svrport=17891&st=1&audio=1"
                                        height="100%"
                                        width="100%"
                                        isMuted={true}
                                    /> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.Camera2Modal = modal} id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog mapdialog" role="document">
                        <div className="modal-content" style={{ height: '700px' }}>
                            <div className="modal-body" style={{ paddingLeft: '0', paddingTop: '0', paddingRight: '0' }}>
                                <div className="row" style={{ display: 'block', width: '100%', marginLeft: '0', marginRight: '0' }}>
                                    {/* <ReactFlvPlayer
                                        url="https://14.99.68.5:12060/live.flv?devid=00BD001497&chl=2&svrid=127.0.0.1&svrport=17891&st=1&audio=1"
                                        height="100%"
                                        width="100%"
                                        isMuted={true}
                                    /> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={modal => this.TimeModal = modal} id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog startmodaldialog" role="document">
                        <div className="modal-content" style={{ height: '600px' }}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Select Time
                                </h5>
                            </div>
                            <div className="modal-body" style={{ paddingLeft: '0', paddingTop: '0', paddingRight: '0', overflow: 'auto' }}>
                                <div className="row" style={{ width: '100%', marginTop: '1%', marginLeft: '0', marginRight: '0' }}>

                                    {

                                        this.state.timeslotdata.map((data) => {
                                            debugger;
                                            var TimeSlot = data.TimeSlot
                                            var Time = "";
                                            var LeaveTime = "";
                                            var ReachedTime = "";
                                            if (TimeSlot !== null) {
                                                Time = TimeSlot.split('-');
                                                LeaveTime = Time[0] + '-' + Time[1] + '-' + Time[2]
                                                ReachedTime = Time[3].trim(' ') + '-' + Time[4] + '-' + Time[5]
                                            }
                                            return (
                                                <div className="col-md-3" key={data.id} style={{ marginBottom: '5%' }}>
                                                    <a onClick={this.GetImageSlotWise(LeaveTime, ReachedTime)}>
                                                        <label style={{ fontFamily: '"Poppins"', fontWeight: 'bold', padding: '5%', border: '1px solid' }}>{data.TimeSlot}</label>
                                                    </a>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </>
        );
    }
}
export default withCookies(GoogleApiWrapper({
    apiKey: 'AIzaSyA8Io_sD3d5uTz6ZTj4FKae_I7mdBVjX9o'
})(gudmdashboard));