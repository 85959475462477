import React, { Component } from "react";

import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import '../style.css';
import Select, { components } from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import MultiSelect from "@khanacademy/react-multi-select";
import { NavLink } from "react-router-dom";
import Geocode from "react-geocode";
import moment from 'moment';
import $ from 'jquery';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import { de } from "date-fns/locale";

import XLSX from "xlsx-color";
import * as htmlToImage from 'html-to-image';
import download from "downloadjs";
import jsPDF from 'jspdf';
import { RotatingLines } from "react-loader-spinner";
import { Toast } from "react-bootstrap";
import styled from 'styled-components';


const ProgressContainer = styled.div`
    width: 20%;
    margin: 20px auto;
    background: black;
   float : right ;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
`;

const ProgressBar = styled.div`
    height: 20px;
    border-radius: 10px;
    background: linear-gradient(to right, #007bff, #00bcd4);
    width: ${props => props.progress}%;
    transition: width 0.3s ease;
`;

const ProgressText = styled.div`
    
    text-align: center;
    font-size: 16px;
    color: white;
    font-weight :600
`;
const headerTblStyle = { color: 'black' };
const A4_PAPER_DIMENSIONS = {
    width: 210,
    height: 210,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;
export const imageDimensionsOnA4 = () => {
    // debugger;
    const isLandscapeImage = 210 >= 297;

    if (isLandscapeImage) {
        return {
            width: A4_PAPER_DIMENSIONS.width,
            height:
                A4_PAPER_DIMENSIONS.width / (610 / 97),
        };
    }

    const imageRatio = 600 / 297;
    if (imageRatio > A4_PAPER_RATIO) {
        const imageScaleFactor =
            (A4_PAPER_RATIO * 260) / 600; // for space all doc

        const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

        return {
            height: scaledImageHeight * imageRatio,
            //height: "295",
            width: scaledImageHeight * imageRatio,
        };
    }

    return {
        width: A4_PAPER_DIMENSIONS.height / (600 / 297),
        height: A4_PAPER_DIMENSIONS.height,

    };
};
class viewmultiplelogbook extends Component {
    constructor(props) {
        super(props);
        const { cookies } = props;

        this.state = {
            name: cookies.get('name') || 'Ben'
        };
        this.state = {
            loading: false,
            pdfPrecess: false,
            pdfTimer: "",
            data: [], StartDate: new Date(), EndDate: new Date()
            , activeItem: 'viewmultiplelogbook',
            VehicleID:{ 'label': '--Select Vehicle--', 'value': '' },
            vehicles :[] ,
            municipalities :[] ,
            MunicipalityID: [],
            BookingDate: "", PDFBookingNo: "", Shift: "", ZoneName: "", WardName: "", Locality: "", ParkingLocationName: "",
            DistanceFromParkingLocationToLocationOfWork: "", VehicleLeaveParkingTime: "", VehicleReachedLocationTime: "",
            VehicleReturnLocationTime: "", VehicleReachedParkingTime: "", VehicleLeaveParkingKiloMeter: "",
            VehicleReachedLocationKiloMeter: "", VehicleReturnLocationKiloMeter: "", VehicleReachedParkingKiloMeter: "",
            JettingStartMeterReading: "", SuctionPumpStartMeterReading: "", JettingFinishMeterReading: "", SuctionPumpFinishMeterReading: "",
            TotalHours: "", TotalMinutes: "", TotalTime: "", TotalKilometer: "", TotalJettingUsageTime: "", TotalSuctionPumpUsageTime: "",
            TotalUsageJettingHour: "", TotalUsageJettingMinutes: "", TotalSuctionPumpUsageHour: "", TotalSuctionPumpUsageMinutes: "",
            TotalNoOfDranageLine: "", TotalDrainageMeter: "", DrainageLine150to300mmDiaMeter: "", DrainageLine301to600mmDiaMeter: "",
            DrainageLine601to900mmDiaMeter: "", DrainageLine901to1200mmDiaMeter: "", JettingSystem: "", SuctionSystem: "",
            SuctionHose5Diameter3MeterLong: "", JettingHose32Diameter120MeterLong: "", JettingNozzleDeChockingNozzle: "",
            DeSiltingNozzleFlatNozzle: "", BalanceNozzleBombNozzle: "", DriverName: "", SupervisorName: "", PDFStatus: false,
            IsSafetyShoesChecked: false, IsHandGlovesChecked: false, IsHelmetChecked: false, IsMaskChecked: false,
            IsLightReflectiveJacketChecked: false, IsUniformChecked: false, IsIDCardChecked: false,
            LogSheetNo: "", LocationDetail: "", EngineerName: "", EngineerDesignation: "", MachineNotWorkFromTime: "",
            MachineNotWorkToTime: "",
            MachineNotWorkTotalTime: "", ExtraordinaryDetails: "", ExtraordinaryArea: "", ExtraordinaryMachineHoles: "",
            SludgeDisposalArea: "", IsSludgeDisposalAreaYes: false, IsSludgeDisposalAreaNo: false, ClientName: "", LunchStartTime: "", LunchEndTime: "", TotalLunchTime: ""
        }
    }
    componentDidMount() { 
        this.GetVehicle()
        this.GetMunicipality()
            // ReferredDate, ZoneID, DistrictID, MuncipalityID
        // );
    }
    GetMultipleLogBookData() {
        // debugger;
        this.setState(prevState => ({
            ...prevState, // Spread previous state
            loading: true // Update loading property
        }));
        //   window.location.reload(true);
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var MunicipalityID = "";
        var UserType = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        var APIName = "";
        var data = "";
   console.log(this.state.VehicleID ,this.state.MunicipalityID,"this.state.VehicleID ,this.state.MunicipalityID")
        APIName = "Report/getMultipleBookingLogSheetData"
        data = JSON.stringify({
            CompanyID: CompanyID,
            ClientID: ClientID,
            VehicleID : this?.state?.VehicleID?.value || "" ,
            MuncipalityID : this?.state?.MunicipalityID?.join(",") || "" ,
            StartDate: moment(this.state.StartDate, "DD-MM-YYYY"),
            EndDate: moment(this.state.EndDate, "DD-MM-YYYY")
        });


        // console.log("data", data);
        var config = {
            method: 'POST',
            url: services.API_URL + APIName,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            // debugger;
            if (response.data.status) {
                this.setState(prevState => ({
                    ...prevState, // Spread previous state
                    loading: false,
                    data: response.data.data
                    // Update loading property
                }), () => {
                    this.generateImage()
                });

            }
            else {
                this.setState(prevState => ({
                    ...prevState, // Spread previous state
                    loading: false // Update loading property
                }));
                Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
            }

        }, error => { })
    }
    // generateImage = async () => {
    //     const doc = new jsPDF();
    //     const imagesToProcess = this.state.data.length;
    //     const batchSize = 10; // Number of images to process in each batch
    //     let processedImages = 0;

    //     const loadImage = (img, index) => {
    //         return new Promise((resolve, reject) => {
    //             img.onload = () => resolve({ index, img });
    //             img.onerror = () => reject(`Error loading image ${index}`);
    //         });
    //     };

    //     const imageDimensions = imageDimensionsOnA4({
    //         width: 210,
    //         height: 210,
    //     });

    //     const processBatch = async (startIndex) => {
    //         const endIndex = Math.min(startIndex + batchSize, imagesToProcess);
    //         for (let i = startIndex; i < endIndex; i++) {
    //             const cname = this.state.data[i].ClientName;
    //             let img, id;
    //             if (cname === "Gujarat Urban Development Mission") {
    //                 img = document.getElementById('divToGUDMPrint' + i);
    //                 id = document.getElementById('divToGUDMPrint' + i);
    //                 id.style.display = 'block';
    //             } else {
    //                 img = document.getElementById('divToPrint' + i);
    //                 id = document.getElementById('divToPrint' + i);
    //                 id.style.display = 'block';
    //             }
    //             try {
    //                 const dataUrl = await htmlToImage.toJpeg(img, { quality: 1, backgroundColor: "#FFFFFF", height: img.clientHeight, width: img.clientWidth });
    //                 processImage(dataUrl);
    //             } catch (error) {
    //                 console.error('Error processing image:', error);
    //             }
    //         }
    //     };

    //     const processImage = (dataUrl) => {
    //         doc.addImage(
    //             dataUrl,
    //             (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
    //             (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
    //             imageDimensions.width,
    //             280
    //         );
    //         doc.addPage();
    //         processedImages++;
    //         if (processedImages === imagesToProcess) {
    //             doc.deletePage(doc.internal.getNumberOfPages());
    //             const pdfURL = doc.output("bloburl");
    //             let alink = document.createElement('a');
    //             alink.href = pdfURL;
    //             alink.download = "Logsheet_" + moment().format('DD-MM-YYYY_HH:mm') + ".pdf";
    //             alink.click();
    //             setTimeout(function () {
    //                 window.location.reload(1);
    //             }, 2000);
    //         }
    //     };

    //     for (let startIndex = 0; startIndex < imagesToProcess; startIndex += batchSize) {
    //         await processBatch(startIndex);
    //     }
    // }
    generateImage = async () => {
        // this.setState((prev)=>({
        //     ...prev,
        //     pdfPrecess : true,
        //     pdfTimer :`Progress: ${percentage.toFixed(2)}%, Remaining Time: ${remainingTime.toFixed(0)} seconds`
        // }))
        const doc = new jsPDF();
        const imagesToProcess = this.state.data.length;
        const batchSize = 10; // Number of images to process in each batch
        let processedImages = 0;

        const loadImage = (img, index) => {
            return new Promise((resolve, reject) => {
                img.onload = () => resolve({ index, img });
                img.onerror = () => reject(`Error loading image ${index}`);
            });
        };
        const imageDimensions = imageDimensionsOnA4({
            width: 210,
            height: 210,
        })
        const processBatch = async (startIndex) => {
            const endIndex = Math.min(startIndex + batchSize, imagesToProcess);
            for (let i = startIndex; i < endIndex; i++) {
                const cname = this.state.data[i].ClientName;
                let img, id;
                if (cname === "Gujarat Urban Development Mission") {
                    img = document.getElementById('divToGUDMPrint' + i);
                    id = document.getElementById('divToGUDMPrint' + i);
                    id.style.display = 'block';
                } else {
                    img = document.getElementById('divToPrint' + i);
                    id = document.getElementById('divToPrint' + i);
                    id.style.display = 'block';
                }
                try {
                    const dataUrl = await htmlToImage.toJpeg(img, { quality: 1, backgroundColor: "#FFFFFF", height: img.clientHeight, width: img.clientWidth });
                    processImage(dataUrl);
                    updateProgress();
                } catch (error) {
                    console.error('Error processing image:', error);
                }
            }
        };
        const processImage = (dataUrl) => {
            doc.addImage(
                dataUrl,
                (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
                (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
                imageDimensions.width,
                280
            );
            doc.addPage();
            processedImages++;
            if (processedImages === imagesToProcess) {
                doc.deletePage(doc.internal.getNumberOfPages());
                const pdfURL = doc.output("bloburl");
                let alink = document.createElement('a');
                alink.href = pdfURL;
                alink.download = "Logsheet_" + moment().format('DD-MM-YYYY_HH:mm') + ".pdf";
                alink.click();
                this.setState((prev) => ({
                    ...prev,
                    pdfPrecess: false,
                    pdfTimer: ''
                }))
                setTimeout(function () {
                    window.location.reload(1);
                }, 2000);
            }
        };

        const updateProgress = () => {
            const percentage = Math.min((processedImages / imagesToProcess) * 100, 100);
            const remainingImages = imagesToProcess - processedImages;
            const averageTimePerImage = 5; // Assuming it takes 5 seconds to process one image
            const remainingTime = remainingImages * averageTimePerImage;
            this.setState((prev) => ({
                ...prev,
                pdfPrecess: true,
                pdfTimer: `Progress: ${percentage.toFixed(2)}%`
            }))

            // Update UI to display progress and remaining time to the user
        };

        for (let startIndex = 0; startIndex < imagesToProcess; startIndex += batchSize) {
            await processBatch(startIndex);
        }
    }
    onStartDateChange = (e) => {
        this.setState({ StartDate: e });
        this.setState({ EndDate: e });
    }
    onSearchClick = (e) => {
        e.preventDefault();
        this.GetMultipleLogBookData();
    }
    onMunicipalityChange = (value) => {
        this.setState({ MunicipalityID: value });
    }
    GetVehicle() {
        // debugger;
        var CompanyID = "";
        console.log(this.props.allCookies.MainCompanyID ,"enterddd")

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
  

        if (CompanyID != "") {
            var data = JSON.stringify({
                "CompanyID": CompanyID,
             
            });
            var config = {
                method: 'POST',
                url: services.API_URL + "Vehicle/getVehicle",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //// debugger;
                if (response.data.status) {
                    this.setState({ vehicles: response.data.data.map(item => ({ value: item.VehicleID, label: item.VehicleNo })) });
                }
                else {
                    this.setState({ vehicles: [] });
                }

            }, error => { })
        }
        else {
            if (CompanyID == "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID != "" && ClientID == "") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID == "" && ClientID != "") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }


    }
    onVehicleChange = (e) => {
        //debugger;
        this.setState({ VehicleID: e });
    }
    GetMunicipality() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if(this.props.allCookies.UserID !==undefined){
            UserID == this.props.allCookies.UserID
        }
        var data = "";
        data = JSON.stringify({
            UserID :this.props.allCookies.PanelUserType=="Admin" ?  UserID : "" ,  
            "MuncipalityID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "DistrictID": "",
            "ZoneID": ""
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Muncipality/getMuncipalityDropDownData",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;

            if (response.data.status) {
                this.setState({ municipalities: response.data.data.map(item => ({ value: item.MuncipalityID, label: item.MuncipalityName })) });
            }
            else {
                this.setState({ municipalities: [] })
            }
        }, error => { })
    }
    OnSearchCancelTaskClick = (e) => {
        this.setState({
            loading: false,
            pdfPrecess: false,
            pdfTimer: "",
            data: [], StartDate: new Date(), EndDate: new Date()
            , activeItem: 'viewmultiplelogbook',
            VehicleID:{ 'label': '--Select Vehicle--', 'value': '' },
            vehicles :[] ,
            municipalities :[],
            MunicipalityID :[],
            BookingDate: "", PDFBookingNo: "", Shift: "", ZoneName: "", WardName: "", Locality: "", ParkingLocationName: "",
            DistanceFromParkingLocationToLocationOfWork: "", VehicleLeaveParkingTime: "", VehicleReachedLocationTime: "",
            VehicleReturnLocationTime: "", VehicleReachedParkingTime: "", VehicleLeaveParkingKiloMeter: "",
            VehicleReachedLocationKiloMeter: "", VehicleReturnLocationKiloMeter: "", VehicleReachedParkingKiloMeter: "",
            JettingStartMeterReading: "", SuctionPumpStartMeterReading: "", JettingFinishMeterReading: "", SuctionPumpFinishMeterReading: "",
            TotalHours: "", TotalMinutes: "", TotalTime: "", TotalKilometer: "", TotalJettingUsageTime: "", TotalSuctionPumpUsageTime: "",
            TotalUsageJettingHour: "", TotalUsageJettingMinutes: "", TotalSuctionPumpUsageHour: "", TotalSuctionPumpUsageMinutes: "",
            TotalNoOfDranageLine: "", TotalDrainageMeter: "", DrainageLine150to300mmDiaMeter: "", DrainageLine301to600mmDiaMeter: "",
            DrainageLine601to900mmDiaMeter: "", DrainageLine901to1200mmDiaMeter: "", JettingSystem: "", SuctionSystem: "",
            SuctionHose5Diameter3MeterLong: "", JettingHose32Diameter120MeterLong: "", JettingNozzleDeChockingNozzle: "",
            DeSiltingNozzleFlatNozzle: "", BalanceNozzleBombNozzle: "", DriverName: "", SupervisorName: "", PDFStatus: false,
            IsSafetyShoesChecked: false, IsHandGlovesChecked: false, IsHelmetChecked: false, IsMaskChecked: false,
            IsLightReflectiveJacketChecked: false, IsUniformChecked: false, IsIDCardChecked: false,
            LogSheetNo: "", LocationDetail: "", EngineerName: "", EngineerDesignation: "", MachineNotWorkFromTime: "",
            MachineNotWorkToTime: "",
            MachineNotWorkTotalTime: "", ExtraordinaryDetails: "", ExtraordinaryArea: "", ExtraordinaryMachineHoles: "",
            SludgeDisposalArea: "", IsSludgeDisposalAreaYes: false, IsSludgeDisposalAreaNo: false, ClientName: "", LunchStartTime: "", LunchEndTime: "", TotalLunchTime: ""
        });
        this.GetVehicle()
        this.GetMunicipality()

    }
    render() {
        // const { loading} = this.state;
        return (
            <>
                {this.state.loading ?
                    <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                        <div style={{ position: "fixed", zIndex: "1", left: "680px", top: "300px" }}>
                            <RotatingLines
                                strokeColor="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="150"
                                height="10"
                                visible={true}
                            />
                        </div>
                    </div> : <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                        {
                            this.state.pdfPrecess &&
                            <ProgressContainer>

                                <ProgressText id="progress-text">  {this.state.pdfTimer}</ProgressText>
                            </ProgressContainer>
                            //    <h3>
                            //     {this.state.pdfTimer}
                            //    </h3> 
                        }
                        <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Start Date</label>
                                    <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.StartDate} onChange={this.onStartDateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>End Date</label>
                                    <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.EndDate} minDate={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.EndDate} onChange={(e) => {
                                        this.setState({ EndDate: e });
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                        <div className="form-group formgroupcss">
                                            <label htmlFor="exampleInputPassword1">Muncipality </label>
                                            <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                    options={this.state.municipalities} selected={this.state.MunicipalityID} checked={false}
                                    onSelectedChanged ={this.onMunicipalityChange}
                                    
                                    overrideStrings={{
                                        selectSomeItems: "Muncipality", allItemsAreSelected: "All Muncipality are Selected",
                                        selectAll: "Select All", search: "Search"
                                    }}  />
                                            {/* {this.state.VehicleErrMsg && <span className="text-danger">{this.state.VehicleErrMsg === 'required' ? '*' : ''}</span>} */}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Vehicle</label>
                                <Select isClearable={true} options={this.state.vehicles} value={this.state.VehicleID}  onChange={this.onVehicleChange}/>
                            </div>
                        </div>
                            <div className="col-md-3">
                                <button type="button" onClick={this.onSearchClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                <button type="button" onClick={this.OnSearchCancelTaskClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                            </div>
                        </div>
                        {this.state.data.map((value, inx1) => {
                            var TotalTime = value.TotalTime
                            var TotalHours = "";
                            var TotalMinutes = "";
                            if (TotalTime != null) {
                                TotalTime = TotalTime.split(':');
                                TotalHours = TotalTime[0]
                                TotalMinutes = TotalTime[1]
                            }

                            var TotalJettingUsageTime = value.TotalJettingUsageTime;
                            var JettingTotalHours = "";
                            var JettingTotalMinutes = "";

                            if (TotalJettingUsageTime != null) {
                                TotalJettingUsageTime = TotalJettingUsageTime.split(':');
                                JettingTotalHours = TotalJettingUsageTime[0]
                                JettingTotalMinutes = TotalJettingUsageTime[1]
                            }

                            var TotalSuctionPumpUsageTime = value.TotalSuctionPumpUsageTime;
                            var SuctionPumpTotalHours = "";
                            var SuctionPumpTotalMinutes = "";

                            if (TotalSuctionPumpUsageTime != null) {
                                TotalJettingUsageTime = TotalSuctionPumpUsageTime.split(':');
                                SuctionPumpTotalHours = TotalSuctionPumpUsageTime[0]
                                SuctionPumpTotalMinutes = TotalSuctionPumpUsageTime[1]
                            }

                            var VehicleLeaveParkingOdometer = value.VehicleLeaveParkingOdometer;
                            var VehicleReachedLocationOdometer = value.VehicleReachedLocationOdometer;
                            var DifferentLocationOdometer = "";
                            var TotalDifferenceOdometer = VehicleReachedLocationOdometer - VehicleLeaveParkingOdometer;
                            var HelperName = "";
                            DifferentLocationOdometer = TotalDifferenceOdometer;
                            var IsSafetyShoesChecked = false;
                            var IsHandGlovesChecked = false;
                            var IsHelmetChecked = false;
                            var IsMaskChecked = false;
                            var IsLightReflectiveJacketChecked = false;
                            var IsUniformChecked = false;
                            var IsIDCardChecked = false;
                            var MultipleHelperName = "";

                            var IsSludgeDisposalAreaYes = false;
                            var IsSludgeDisposalAreaNo = false;

                            var BookingTaskCheckListDetail = value.BookingTaskCheckListDetail;
                            if (BookingTaskCheckListDetail != null) {
                                // debugger;
                                for (let i = 0; i < value.BookingTaskCheckListDetail.length; i++) {
                                    if (value.BookingTaskCheckListDetail[i].CheckListTitle == "Safety Shoes") {
                                        IsSafetyShoesChecked = true
                                    }
                                    if (value.BookingTaskCheckListDetail[i].CheckListTitle == "Hand Gloves") {
                                        IsHandGlovesChecked = true;
                                    }
                                    if (value.BookingTaskCheckListDetail[i].CheckListTitle == "Helmet") {
                                        IsHelmetChecked = true;
                                    }
                                    if (value.BookingTaskCheckListDetail[i].CheckListTitle == "Mask") {
                                        IsMaskChecked = true;
                                    }
                                    if (value.BookingTaskCheckListDetail[i].CheckListTitle == "Light Reflective Jacket") {
                                        IsLightReflectiveJacketChecked = true;
                                    }
                                    if (value.BookingTaskCheckListDetail[i].CheckListTitle == "Uniform") {
                                        IsUniformChecked = true;
                                    }
                                    if (value.BookingTaskCheckListDetail[i].CheckListTitle == "I-Card") {
                                        IsIDCardChecked = true;
                                    }
                                }
                            }

                            if (value.SludgeDisposalArea == "No") {
                                IsSludgeDisposalAreaNo = true
                            } else {
                                IsSludgeDisposalAreaYes = true
                            }

                            var BookingTaskHelperDetail = value.BookingTaskHelperDetail;
                            if (BookingTaskHelperDetail != null) {
                                for (let i = 0; i < value.BookingTaskHelperDetail.length; i++) {
                                    HelperName += value.BookingTaskHelperDetail[i].FullName + ",";
                                }
                            }
                            HelperName = HelperName.slice(0, -1)
                            if (HelperName != "") {
                                MultipleHelperName = HelperName;
                            }
                            return (
                                <>
                                    <div id={'divToPrint' + inx1} className="divToPrint" style={{ display: 'none' }
                                    }>

                                        <div className="row">
                                            <div className="col-md-2" >
                                                <img src="/Images/Amclogo.png" style={{ height: '100px', width: '100px', textAlign: 'left' }}></img>
                                            </div>
                                            <div className="col-md-7" style={{ textAlign: 'center', marginTop: '10px' }}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h3 style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: '10px' }}>અમદાવાદ મ્યુનિસિપલ કોર્પોરેશન</h3>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <h3 style={{ fontWeight: 'bold', fontSize: '25px' }}>પર્ફોમન્સ સર્ટીફીકેટ</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                                <span style={{ fontWeight: 'bold', fontSize: '20px' }}>No.</span>
                                                <span style={{ fontSize: '25px', borderBottom: '2px dotted', width: '100%' }}>{value.BookingNo}</span>
                                            </div>
                                            <div className="col-md-1">
                                                <img src="/Images/azadi.png" style={{ height: '100px', width: '100px' }}></img>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontWeight: '500', fontSize: '25px' }}>આથી મે. મેટ્રો વેસ્ટ હેન્ડલીંગ પ્રા. લી.ને સર્ટીફીકેટ આપવામાં આવે છે કે, ડીશીલ્ટીંગ મશીન રજીસ્‍ટ્રેશન નંબર <span style={{ borderBottom: '2px dotted' }}>{value.VehicleNo}</span> દ્રારા તા <span style={{ borderBottom: '2px dotted' }}>{value.BookingDate}</span> </span>
                                                <span style={{ fontWeight: '500', fontSize: '25px' }}>ના રોજ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.Shift}</span> શીફ્ટમાં <span style={{ fontSize: '25px', borderBottom: '2px dotted', width: '100%' }}>{value.TotalTime}</span> કલાક માટે નીચે મુજબની વિગતથી કામગીરી કરેલ છે.</span>
                                            </div>
                                            {/* <div className="col-md-12" >
<span style={{ fontWeight: 'normal', fontSize: '25px' }}>આથી મે. મેટ્રો વેસ્ટ હેન્ડલીંગ પ્રા. લી.ને સર્ટીફીકેટ આપવામાં આવે છે કે, ડીશીલ્ટીંગ મશીન રજીસ્‍ટ્રેશન નંબર GJ-01-J-4782 દ્રારા તા.  ..............</span>

</div> */}

                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5%' }}>૧.</span> ઝોનઃ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.ZoneName}</span> વોર્ડ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.WardName}</span> કામગીરીનું સ્થળ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.Locality}</span></span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5%' }}>ર.</span> પાર્કિંગનું સ્થળ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.ParkingLocationName}</span> પાર્કિગના સ્થળ અને કામગીરીના સ્થળ વચ્ચેનું અંતર <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{DifferentLocationOdometer}</span></span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5%' }}>૩.</span> કામગીરીની વિગત : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.Locality}</span></span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%' }}>
                                                <table style={{ border: '1px solid' }} className="table table-bordered">
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>કિલોમીટર</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>કિલોમીટર</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન પાર્કિંગ થી નિકળ્યાનો સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleLeaveParkingTime}</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleLeaveParkingOdometer}</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન કામગીરીના સ્થળ ઉપર આવ્યાનો સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleReachedLocationTime}</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleReachedLocationOdometer}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>ર</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન કામગીરીના સ્થળ ઉપરથી નિકળ્યાનો સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleReturnLocationTime}</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleReturnLocationOdometer}</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન પાર્કિંગ ઉપર પહોચ્યાંનો સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleReachedParkingTime}</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleReachedParkingOdometer}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>કુલ સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>કલાક </span><span style={{ fontWeight: '500', fontSize: '25px' }}>{TotalHours}</span> <span style={{ fontSize: '25px', paddingLeft: '120px' }}>મીનીટ </span><span style={{ fontWeight: '500', fontSize: '25px' }}>{TotalMinutes}</span> </td>
                                                        {/* <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>મીનીટ</span></td> */}
                                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>કુલ કિલોમીટર રીડીગ  </span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }} colspan='2'><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.TotalKiloMeter}</span></td>
                                                        {/* <td style={{ border: '1px solid black', width: '15%' }}><span style={{ fontSize: '25px' }}></span></td> */}
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૪</span></td>
                                                        <td style={{ border: '1px solid black', textAlign: 'center' }} colspan='2'><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                                        <td style={{ border: '1px solid black', textAlign: 'center' }}><span style={{ fontSize: '25px' }}>રનીગ અવર્સના રીડીગ </span></td>
                                                        <td style={{ border: '1px solid black', textAlign: 'center' }} colspan='2'><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                                        <td style={{ border: '1px solid black', textAlign: 'center' }}><span style={{ fontSize: '25px' }}>રનીગ અવર્સના રીડીગ </span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>પ</span></td>
                                                        <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>મશીનની જેટિગની કામગીરી શરુ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                                        <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.JettingStartMeterReading}</span></td>
                                                        <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>મશીનની જેટિગની કામગીરી પૂર્ણ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                                        <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.JettingFinishMeterReading}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૬</span></td>
                                                        <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>મશીનની સકશનની કામગીરી શરુ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                                        <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.SuctionPumpStartMeterReading}</span></td>
                                                        <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>મશીનની સકશનની કામગીરી પૂર્ણ કર્યા ના રનીગ અવર્સના રીડીગ</span></td>
                                                        <td style={{ border: '1px solid black' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.SuctionPumpFinishMeterReading}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૭</span></td>
                                                        <td style={{ border: '1px solid black', textAlign: 'justify' }} colspan='3'><span style={{ fontSize: '25px' }}>કુલ જેટિગ સમય:</span><span style={{ fontSize: '25px', paddingLeft: '120px' }}>કલાક </span> <span style={{ fontWeight: '500', fontSize: '25px' }}>{JettingTotalHours}</span> <span style={{ fontSize: '25px', paddingLeft: '120px' }}>મીનીટ </span> <span style={{ fontWeight: '500', fontSize: '25px' }}>{JettingTotalMinutes}</span> </td>
                                                        <td style={{ border: '1px solid black', textAlign: 'left' }} colspan='3'><span style={{ fontSize: '25px' }}>કુલ સકશન સમય:</span><span style={{ fontSize: '25px', paddingLeft: '60px' }}>કલાક </span> <span style={{ fontWeight: '500', fontSize: '25px' }}>{SuctionPumpTotalHours}</span> <span style={{ fontSize: '25px', paddingLeft: '60px' }}>મીનીટ </span> <span style={{ fontWeight: '500', fontSize: '25px' }}>{SuctionPumpTotalMinutes}</span> </td>

                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૪.</span>સદર વોર્ડની ડ્રેનેજ લાઇનની ડિશીલ્ટીંગ ડિચોકીંગની નીચે મુજબની કામગીરી કરેલ છે.તથા કુલ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.TotalMeter}</span> મીટર ડ્રેનેજલાઈન અને <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.TotalNoOfDranageLine}</span> નંગ
                                                    મશીનનહોલની સફાઈની કામગીરી કરેલ છે.</span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%' }}>
                                                <table style={{ border: '1px solid' }} className="table table-bordered">
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>ડ્રેનેજ લાઇન સાઈઝ ટેન્ડર</span></td>
                                                        <td style={{ border: '1px solid black', width: '25%' }}><span style={{ fontSize: '25px' }}>ટેન્ડરના <span style={{ fontWeight: 'bold', fontSize: '25px' }}>KPI</span> મુજબ નો ટાર્ગેટ</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>કામગીરીના સ્થળ ઉપર થયેલ ખરેખર કામગીરી</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧૫૦ એમ.એમ. થી ૩૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૬૦ મીટર પ્રતિ કલાક</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.DrainageLine150to300mmDiaMeter}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૨</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૩૦૧ એમ.એમ. થી ૬૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૪૦ મીટર પ્રતિ કલાક</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.DrainageLine301to600mmDiaMeter}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૬૦૧ એમ.એમ. થી ૯૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૩૦ મીટર પ્રતિ કલાક</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.DrainageLine601to900mmDiaMeter}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૪</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૯૦૧ એમ.એમ. થી ૧૨૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧૦ મીટર પ્રતિ કલાક</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.DrainageLine901to1200mmDiaMeter}</span></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૫.</span>કામગીરીના સ્થળ ઉપર જોવા મળેલ મશીનના ટેકનીકલ પેરામીટરની વિગત</span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%' }}>
                                                <table style={{ border: '1px solid' }} className="table table-bordered">
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>ટેકનીકલ પેરામીટર</span></td>
                                                        <td style={{ border: '1px solid black', width: '25%' }}><span style={{ fontSize: '25px' }}>ટેન્ડર મુજબ જરૂરિયાત કેપેસીટી વૅલ્યુ </span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>કામગીરીના સ્થળ ઉપર ખરેખર મળેલ કેપેસીટી</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>જેટિગ સીસ્ટમ</span></td>
                                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧૫૦ બાર</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.JettingSystem}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૨</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>સકશન સીસ્ટમ</span></td>
                                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૫૦૦૦ ક્યુબીક મી કલાક</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.SuctionSystem}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>સકશન હોઝ ૫ ડાયા મીટર - ૩ મીટર લંબાઈ</span></td>
                                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧૦ નંગ</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.SuctionHose5Diameter3MeterLong}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૪</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>જેટિગ હોઝ - ૩૨ એમ.એમ. ડાયા, - ૧૨૦ મી લંબાઈ </span></td>
                                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧ નંગ</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.JettingHose32Diameter120MeterLong}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>પ</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>જેટિગ નોઝલ : ડીચોકીગ નોઝલ</span></td>
                                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧ નંગ</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.JettingNozzleDeChockingNozzle}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૬</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>ડી શીલ્ટીગ નોઝલ - ફ્લેટ નોઝલ</span></td>
                                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧ નંગ</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.DeSiltingNozzleFlatNozzle}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૭</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>બેલેન્સ નોઝલ બોમ્બ નોઝલ</span></td>
                                                        <td style={{ border: '1px solid black', width: '25%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૧ નંગ</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.BalanceNozzleBombNozzle}</span></td>
                                                    </tr>

                                                </table>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૬.</span>સાઈટ ઉપર હાજર સ્ટાફની વિગત</span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}>(i)ડ્રાઇવરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.DriverFullName}</span> (ii)ઓપરેટરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{MultipleHelperName}</span> (iii)સુપરવાઇઝરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.AssignSupervisorFullName}</span></span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span>૬.</span>સ્ટાફને આપેલ સુરક્ષા સાધનો(PPE) અને અન્ય વિગત, જો હોય તો (✔) અને જો ન હોય તો (✘) માર્ક કરવુ.</span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}>(i) સેફટી શુઝ : {(IsSafetyShoesChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (ii) હેન્ડ ગ્લોવ્ઝ : {(IsHandGlovesChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (iii) હેલ્મેટ : {(IsHelmetChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (iv) માસ્ક : {(IsMaskChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (v) લાઈટ રીફ્લેકટીવ જેકેટ : {(IsLightReflectiveJacketChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (vi) યુનિફોર્મ : {(IsUniformChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (vii) આઈ કાર્ડ : {(IsIDCardChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} </span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૭.</span>રીમાર્કસ(અન્ય ફોલ્ટની કામગીરીની વિગત):-</span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૮.</span>ટેન્ડર લીકવીડીટી ડેમેજીસની કન્ડિશન મુજબ (પાના નં. ૧૦) જો કપાત હોય તો તેની વિગત :</span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '5%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px', paddingLeft: '1%' }}>મશીન સુપરવાઇઝર<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}>(MWHPL)</span></span>
                                                <span style={{ fontSize: '25px', paddingLeft: '1%' }}>ટેકનીકલ સુપરવાઇઝર<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}>(........Ward)</span></span>
                                                <span style={{ fontSize: '25px', paddingLeft: '1%' }}>આસી. એનજીનીઅર<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}>(........Ward)</span></span>
                                                <span style={{ fontSize: '25px', paddingLeft: '1%' }}>આસી.સીટી.એન્જીનીઅર<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}>(........Ward)</span></span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'center' }}>
                                                <span style={{ fontSize: '25px' }}> <span style={{ fontWeight: 'bold' }}>Special Note :</span><span style={{ paddingLeft: '5px' }}>As per Hon. Court order in any case, contractor is not allowed to enter/work inside the machinehole or sewer lines. Proper training & work knowledge shall be provided to all personals engaged for work by contractor.</span></span>
                                            </div>
                                        </div>


                                    </div>


                                    <div id={'divToGUDMPrint' + inx1} className="divToGUDMPrint" style={{ display: 'none' }}>

                                        <div className="row">
                                            <div className="col-md-2" >
                                                <img src="/Images/GUDM_logo.png" style={{ height: '100px', width: '100px', textAlign: 'left' }}></img>
                                            </div>
                                            <div className="col-md-7" style={{ textAlign: 'center', marginTop: '10px' }}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h3 style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: '10px' }}>ગુજરાત શહેરી વિકાસ મિશન</h3>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <h3 style={{ fontWeight: 'bold', fontSize: '25px' }}>પર્ફોમન્સ સર્ટીફીકેટ</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                                <span style={{ fontWeight: 'bold', fontSize: '20px' }}>No.</span>
                                                <span style={{ fontSize: '25px', borderBottom: '2px dotted', width: '100%' }}>{value.LogSheetNo}</span>
                                            </div>
                                            <div className="col-md-1">
                                                <img src="/Images/azadi.png" style={{ height: '100px', width: '100px' }}></img>
                                            </div>

                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontWeight: '500', fontSize: '25px' }}>આથી મે. મેટ્રો વેસ્ટ હેન્ડલીંગ પ્રા. લી.ને સર્ટીફીકેટ આપવામાં આવે છે કે, ડીશીલ્ટીંગ મશીન રજીસ્‍ટ્રેશન નંબર <span style={{ borderBottom: '2px dotted' }}>{value.VehicleNo}</span> દ્રારા તા <span style={{ borderBottom: '2px dotted' }}>{value.BookingDate}</span></span>
                                                <span style={{ fontWeight: '500', fontSize: '25px' }}> ના રોજ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.MuncipalityName}</span> નગરપાલિકા <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.WardName}</span> શીફ્ટ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.Shift}</span> દરમ્યાન નીચે મુજબની વિગતથી કામગીરી કરેલ છે.</span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૧.</span> ઝોનઃ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.ZoneName}</span> વોર્ડ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.WardName}</span> કામગીરીનું સ્થળ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.Locality + (this.state.LocationDetail != '' ? ('-' + this.state.LocationDetail) : '')}</span></span>
                                            </div>

                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>ર.</span> એન્જીનિયર ઇન્ચાર્જનું નામ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.EngineerName}</span> હોદ્દો <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.EngineerDesignation}</span></span>
                                            </div>

                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontWeight: '500', fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૩.</span> પાર્કિંગ સ્થળ: <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.ParkingLocationName}</span></span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૪.</span> કામગીરીની વિગત : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.Locality}</span></span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%' }}>
                                                <table style={{ border: '1px solid' }} className="table table-bordered">
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontSize: '25px' }}>ઓડોમીટર</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%' }}><span style={{ fontSize: '25px' }}>વિગત</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontSize: '25px' }}>ઓડોમીટર</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન પાર્કિંગ થી નિકળ્યાનો સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleLeaveParkingTime2}</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleLeaveParkingOdometer}</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન કામગીરીના સ્થળ ઉપર પહોંચ્યાનો સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleReachedLocationTime2}</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleReachedLocationOdometer}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>ર</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન કામગીરીના સ્થળ ઉપરથી નિકળ્યાનો સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleReturnLocationTime2}</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleReturnLocationOdometer}</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>મશીન પાર્કિંગ ઉપર પહોચ્યાંનો સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleReachedParkingTime2}</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'center' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.VehicleReachedParkingOdometer}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>કુલ સમય</span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%', textAlign: 'left' }} colspan='2'><span style={{ fontSize: '25px' }}>કલાક </span><span style={{ fontWeight: '500', fontSize: '25px' }}>{TotalHours}</span> <span style={{ fontSize: '25px', paddingLeft: '120px' }}>મીનીટ </span><span style={{ fontWeight: '500', fontSize: '25px' }}>{TotalMinutes}</span> </td>
                                                        {/* <td style={{ border: '1px solid black', width: '13.75%' }}><span style={{ fontSize: '25px' }}>મીનીટ</span></td> */}
                                                        <td style={{ border: '1px solid black', width: '20%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>કુલ કિલોમીટર રીડીગ  </span></td>
                                                        <td style={{ border: '1px solid black', width: '13.75%' }} colspan='2'><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.TotalKiloMeter}</span></td>
                                                        {/* <td style={{ border: '1px solid black', width: '15%' }}><span style={{ fontSize: '25px' }}></span></td> */}
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૫.</span> કરેલ કામગીરી :</span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%' }}>
                                                <table style={{ border: '1px solid' }} className="table table-bordered">
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>અનુ ક્રમાંક</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>ડ્રેનેજ લાઇન સાઈઝ ટેન્ડર</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontSize: '25px' }}>કામગીરીના સ્થળ ઉપર થયેલ ખરેખર કામગીરી</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૧</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૩૦૧ એમ.એમ. થી ૬૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.DrainageLine301to600mmDiaMeter}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '10%' }}><span style={{ fontSize: '25px' }}>૨</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૬૦૧ એમ.એમ. થી ૯૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.DrainageLine601to900mmDiaMeter}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid black', width: '5%' }}><span style={{ fontSize: '25px' }}>૩</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%', textAlign: 'left' }}><span style={{ fontSize: '25px' }}>૯૦૧ એમ.એમ. થી ૧૨૦૦ એમ.એમ. ડાયા મીટર</span></td>
                                                        <td style={{ border: '1px solid black', width: '35%' }}><span style={{ fontWeight: '500', fontSize: '25px' }}>{value.DrainageLine901to1200mmDiaMeter}</span></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}>સદર વોર્ડની ડ્રેનેજ લાઇનની ડિશીલ્ટીંગ / ડિચોકીંગની કામગીરી માં કુલ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.TotalMeter}</span> મીટર ડ્રેનેજલાઈન અને <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.TotalNoOfDranageLine}</span> નંગ મશીનનહોલની સફાઈની કામગીરી સંતોષકારક પૂર્ણ કરેલ છે.</span>
                                            </div>

                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૬.</span>કામગીરી બંધ રાખવાની વિગત: રિસેસ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.LunchStartTime}</span> થી <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.LunchEndTime}</span> ;કુલ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.TotalLunchTime}</span> કલાક</span>
                                            </div>

                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૭.</span>મશીન અન્ય કારણસર બંધ રહ્યાની વિગત <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.MachineNotWorkFromTime}</span> થી <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.MachineNotWorkToTime}</span> ;કુલ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.MachineNotWorkTotalTime}</span> કલાક</span>
                                            </div>

                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૮.</span>અસાધારણ પરિસ્થિતિ ની વિગત:  અસાધારણ પરિસ્થિતિ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.ExtraordinaryDetails}</span> સ્થળ <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.ExtraordinaryArea}</span> મેન હોલ ની સંખ્યા <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.ExtraordinaryMachineHoles}</span></span>
                                            </div>

                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૯.</span>સાઈટ ઉપર હાજર સ્ટાફની વિગત</span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}>(i)ડ્રાઇવરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.DriverFullName}</span> (ii)ઓપરેટરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{MultipleHelperName}</span> (iii)સુપરવાઇઝરનું નામ : <span style={{ fontWeight: '500', fontSize: '25px', borderBottom: '2px dotted' }}>{value.SupervisorFullName}</span></span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span>૧૦.</span>સ્ટાફને આપેલ સુરક્ષા સાધનો(PPE) અને અન્ય વિગત, જો હોય તો (✔) અને જો ન હોય તો (✘) માર્ક કરવુ.</span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}>(i) સેફટી શુઝ : {(IsSafetyShoesChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (ii) હેન્ડ ગ્લોવ્ઝ : {(IsHandGlovesChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (iii) હેલ્મેટ : {(IsHelmetChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (iv) માસ્ક : {(IsMaskChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (v) લાઈટ રીફ્લેકટીવ જેકેટ : {(IsLightReflectiveJacketChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (vi) યુનિફોર્મ : {(IsUniformChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} (vii) આઈ કાર્ડ : {(IsIDCardChecked) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} </span>
                                            </div>


                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span>૧૧.</span>Sludge નું ડીસપોઝલ નગરપાલિકા એ બતાવેલ જગ્યાએ ખાલી કરેલ છે હા {(IsSludgeDisposalAreaYes) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)} ના {(IsSludgeDisposalAreaNo) ? (<i style={{ color: 'black' }} className="icon-xl far fa-check-square"></i>) : (<i style={{ color: 'black' }} className="icon-xl far fa-window-close"></i>)}</span>
                                            </div>

                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'left' }}>
                                                <span style={{ fontSize: '25px' }}><span style={{ paddingRight: '5px' }}>૧૨.</span>રીમાર્કસ(અન્ય ફોલ્ટની કામગીરીની વિગત):-</span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '5%', textAlign: 'right' }}>
                                                <span style={{ fontSize: '25px', paddingLeft: '1%' }}>મશીન સુપરવાઇઝર ઇન્ચાર્જ ની સહી<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}></span></span>
                                                <span style={{ fontSize: '25px', paddingRight: '15%' }}><span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}></span></span>
                                                <span style={{ fontSize: '25px', paddingLeft: '1%' }}>ઈન્જીનિયર ઇન્ચાર્જ ની સહી<span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}></span></span>
                                                <span style={{ fontSize: '25px', paddingRight: '15%' }}><span style={{ fontWeight: 'bold', fontSize: '25px', paddingLeft: '5px' }}></span></span>
                                            </div>
                                            <div className="col-md-12" style={{ marginTop: '1%', textAlign: 'center' }}>
                                                <span style={{ fontSize: '25px' }}> <span style={{ fontWeight: 'bold' }}>Special Note :</span><span style={{ paddingLeft: '5px' }}>As per Hon. Court order in any case, contractor is not allowed to enter/work inside the machinehole or sewer lines. Proper training & work knowledge shall be provided to all personals engaged for work by contractor.</span></span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        })
                        }
                    </div>}
            </>
        );
    }
}
export default withCookies(viewmultiplelogbook);