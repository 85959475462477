import React, { Component } from "react";

class privacyandpolicy extends Component {

    render() {
        return (
            <>
                <div className="ppsbody">
                    <style>
                        {`
                          .ppsbody {
                            margin: 0;
                            padding: 0;
                        }
                        
                        .navbar {
                            display: block;
                            align-items: center;
                            justify-content: center;
                            position: sticky;
                            top: 0;
                            cursor: pointer;
                            padding: 0px 20px;
                        }
                        
                        .background {
                            background: #3699FF;
                            background-blend-mode: darken;
                            background-size: cover;
                        }
                        
                        .nav-list {
                            width: 100%;
                            display: flex;
                            align-items: center;
                        }
                        
                        .nvalistcss{
                            padding-left: 0px !important;
                        }
                        
                        .logo {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        
                        .logo img {
                            width: 150px;
                            /* background: white; */
                            padding: 9px;
                            height: 85px !important;
                        }
                        
                        .nav-list li {
                            list-style: none;
                            padding: 18px 19px;
                            font-weight: 500;
                            color: #ffffff !important;
                            font-size: 19px;
                            font-family: 'Times New Roman';
                        }
                        
                        .wrapper {
                            margin-top: 15px;
                        }
                        
                        .container {
                            width: 95%;
                            padding-right: 15px;
                            padding-left: 15px;
                            margin-right: auto;
                            margin-left: auto;
                        }
                        
                        body {
                            /* font-family: 'Times New Roman', Times, serif; */
                            font-family: 'Roboto', sans-serif;
                        }
                        
                        h2 {
                            font-size: 21px;
                            font-weight: bold;
                            color: #505458;
                            margin: 10px 0;
                            line-height: 35px;
                        }
                        
                        .pheading {
                            font-weight: bold;
                        }
                        
                        p {
                            color: #000;
                            text-align: justify !important;
                            line-height: 1.6;
                            margin: 0 0 10px;
                            font-size: 14px;
                        }
                        
                        #kt_footer,
                        #kt_header {
                            display: none !important;
                        }
                        `}
                    </style>

                    <nav className="navbar background">
                        <ul className="nav-list nvalistcss">
                            <div className="logo">
                                <img src="/Images/PPLogo.png" />
                            </div>
                            <li>BlueBrigade</li>
                        </ul>
                    </nav>

                    {/* <div className="wrapper">
                    <div className="container"> */}
                    <div className="">
                        <div className="">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="btn-group pull-right m-t-25"></div>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="page-title">
                                                    <h2>BlueBrigade</h2>
                                                </div>
                                                <p className="pheading">
                                                    Information we collect
                                                </p>
                                                <p>
                                                    We obtain the information you provide when you register the Application.
                                                    Registration with us is optional for some of our applications. However, please keep
                                                    in mind that you may not be able to use some of the features offered by the
                                                    Application unless you register with us. This Privacy Policy (“Privacy Policy”)
                                                    applies to our <span className="pheading">BlueBrigade </span> application.
                                                </p>
                                                <p className="pheading">
                                                    Security
                                                </p>
                                                <p>
                                                    We are concerned about safeguarding the confidentiality of your information. We
                                                    provide physical, electronic, and procedural safeguards to protect information we
                                                    process and maintain. For example, we limit access to this information to authorized
                                                    employees and contractors who need to know that information in order to operate,
                                                    develop or improve our Application. Please be aware that, although we endeavor
                                                    provide reasonable security for information we process.
                                                </p>
                                                <p>
                                                    If we decide to change our privacy policy, we will post those changes on this page
                                                    so that you are always aware of what information we collect, how we use it, and
                                                    under what circumstances we disclose it.
                                                </p>
                                                <p>
                                                    If you choose to use our Service, then you agree to the collection and use of
                                                    information in relation to this policy. The Personal Information that we collect is
                                                    used for providing and improving the Service. We will not use or share your
                                                    information with anyone except as described in this Privacy Policy.
                                                </p>
                                                <p className="pheading">
                                                    Contact us
                                                </p>
                                                <p>
                                                    If you have any questions regarding privacy while using the Application, or have
                                                    questions about our practices, please contact us via mail at <span
                                                        className="pheading">info@smtechno.com</span>.
                                                </p>
                                                <p>
                                                    All rights are reserved.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default privacyandpolicy;