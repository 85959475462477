import React, { Component } from "react";

import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import '../style.css';
import Select, { components } from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import MultiSelect from "@khanacademy/react-multi-select";
import { NavLink } from "react-router-dom";
import Geocode from "react-geocode";
import moment from 'moment';
import $ from 'jquery';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import { de } from "date-fns/locale";

import XLSX from "xlsx-color";
import * as htmlToImage from 'html-to-image';
import download from "downloadjs";
import jsPDF from 'jspdf';
const headerTblStyle = { color: 'black' };

const A4_PAPER_DIMENSIONS = {
    width: 210,
    height: 210,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;
export const imageDimensionsOnA4 = () => {
    debugger;
    const isLandscapeImage = 210 >= 297;

    if (isLandscapeImage) {
        return {
            width: A4_PAPER_DIMENSIONS.width,
            height:
                A4_PAPER_DIMENSIONS.width / (610 / 97),
        };
    }

    const imageRatio = 600 / 297;
    if (imageRatio > A4_PAPER_RATIO) {
        const imageScaleFactor =
            (A4_PAPER_RATIO * 260) / 600; // for space all doc

        const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

        return {
            height: scaledImageHeight * imageRatio,
            //height: "295",
            width: scaledImageHeight * imageRatio,
        };
    }

    return {
        width: A4_PAPER_DIMENSIONS.height / (600 / 297),
        height: A4_PAPER_DIMENSIONS.height,

    };
};
class multipledemo extends Component {
    constructor(props) {
        super(props);
        const { cookies } = props;
    }

    componentDidMount() {
        // this.generateImage();
    }

    generateImage = () => {
        debugger;
        const doc = new jsPDF();
        var img = document.getElementById('divToPrint');
        var id = document.getElementById('divToPrint');

        htmlToImage.toJpeg(img, { quality: 1, backgroundColor: "#FFFFFF", height: img.clientHeight, width: img.clientWidth })
            .then(function (dataUrl) {

                // download(dataUrl,'image.jpg')

                doc.deletePage(1);

                const imageDimensions = imageDimensionsOnA4({
                    width: 210,
                    height: 210,
                });

                doc.addPage();
                doc.addImage(
                    dataUrl,
                    //image.imageType,
                    (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
                    (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
                    imageDimensions.width,
                    //imageDimensions.height
                    280
                );

                const pdfURL = doc.output("bloburl");
                //window.open(pdfURL, "_blank");
                id.style.display = 'none';
                let alink = document.createElement('a');
                alink.href = pdfURL;
                alink.download = "Logsheet_" + moment().format('DD-MM-YYYY_HH:mm') + ".pdf";
                alink.click();

                setTimeout(function () {
                    window.location.reload(1);
                }, 5000);
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }

    render() {

        const styles = {
            page: {
                marginTop: '200px',
                marginLeft: '5rem',
                marginRight: '5rem',
                'page-break-after': 'always',
            },
            columnLayout: {
                display: 'flex',
                justifyContent: 'space-between',
                margin: '3rem 0 5rem 0',
                gap: '2rem',
            },

            column: {
                display: 'flex',
                flexDirection: 'column',
            },

            spacer2: {
                height: '2rem',
            },

            fullWidth: {
                // width: '100%',
                height: '100px',
                width: '100px'
            },

            marginb0: {
                marginBottom: 0,
            }
        }
        return (
            <>
                <div id="divToPrint" style={styles.page}>
                    <div>
                        <h1 style={styles.introText}>
                            Report Heading That Spans More Than Just One Line
                        </h1>
                    </div>

                    <div style={styles.spacer2}></div>

                    <img style={styles.fullWidth} src="/Images/Amclogo.png" />
                </div>
            </>
        )
    }
}
export default withCookies(multipledemo);