import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { withIsAuthenticated } from 'react-auth-kit';

import { withSignIn } from 'react-auth-kit'
import services from "../services/services";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import Swal from "sweetalert2";

class adminlogin extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            name: cookies.get('name') || 'Ben'
        };
        this.state = {
            UserID: "", ClientID: "", CompanyID: "", UserName: "", Password: "", MobileNo: "", OTP: "",
            OTPValue: "", IsOTPVisible: false, IsMobileNoVisible: true, IsError: false, IsMobileRequired: false, IsMultiple: false,
            ZoneDetail: ""
        };

        // if (this.props.isAuthenticated()) {
        //     // If authenticated user, then redirect to secure dashboard
        //     // return (
        //     //     <Redirect to={'/secure'} />
        //     // )
        // } else {

        //     // return (
        //     //     <button onClick={loginHandler}>Log In!!</button>
        //     // )
        // }
    }
    allowOnlyNumbers = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
        return;
    };
    onSubmit = (e) => {
        debugger;
        e.preventDefault()
        var data = JSON.stringify({
            "UserName": this.state.UserName,
            "Password": this.state.Password
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "User/setUserLoginUserNameWise",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                debugger;
                const { cookies } = this.props;
                let alldata = [];
                alldata = cookies.getAll();

                var tifOptions = Object.keys(alldata).map(function (key) {
                    // debugger;
                    cookies.remove(key);
                });

                cookies.set('UserID', response.data.data[0].UserID, { path: '/' });

                if (response.data.data[0].UserType != "Admin") {
                    cookies.set('MainClientID', response.data.data[0].ClientID, { path: '/' });
                    cookies.set('MainCompanyID', response.data.data[0].CompanyID, { path: '/' });
                    cookies.set('UserName', response.data.data[0].UserName, { path: '/' });

                    var MunicipalityID = "";
                    for (let i = 0; i < response.data.data[0].UserMuncipalityDetail.length; i++) {
                        //  const element = array[index];
                        MunicipalityID += response.data.data[0].UserMuncipalityDetail[i].MuncipalityID + ",";
                    }
                    MunicipalityID = MunicipalityID.substring(0, MunicipalityID.length - 1);

                    cookies.set('MunicipalityID', MunicipalityID, { path: '/' });

                    var tempZoneDetail = "";
                    for (let i = 0; i < response.data.data[0].UserZoneDetail.length; i++) {
                        //  const element = array[index];
                        tempZoneDetail += response.data.data[0].UserZoneDetail[i].ZoneID + ",";
                    }
                    tempZoneDetail = tempZoneDetail.substring(0, tempZoneDetail.length - 1);
                    this.setState({ ZoneDetail: tempZoneDetail });
                    cookies.set('ZoneIDs', tempZoneDetail, { path: '/' });

                    //cookies.set('Menu', response.data.data[0].FunctionalityPageDetail, { path: '/' });
                    if (response.data.data[0].UserPermissionDetail != null) {
                        cookies.set('PanelUserType', response.data.data[0].UserType);
                        for (let j = 0; j < response.data.data[0].UserPermissionDetail.length; j++) {

                            if (response.data.data[0].UserPermissionDetail[j].FunctionalityPage === "ViewAssignTask" || response.data.data[0].UserPermissionDetail[j].FunctionalityPage === "ViewStartTask" || response.data.data[0].UserPermissionDetail[j].FunctionalityPage === "ViewFinishTask" || response.data.data[0].UserPermissionDetail[j].FunctionalityPage === "ViewCompleteTask" || response.data.data[0].UserPermissionDetail[j].FunctionalityPage === "ViewApprovalTask" || response.data.data[0].UserPermissionDetail[j].FunctionalityPage === "ViewTask") {
                                if (response.data.data[0].UserType == "Sub Admin" && response.data.data[0].UserPermissionDetail[j].FunctionalityPage == "ViewTask") {
                                    cookies.set("ViewSubAdminTask",
                                        "AddStatus:" + response.data.data[0].UserPermissionDetail[j].AddStatus +
                                        ",UpdateStatus:" + response.data.data[0].UserPermissionDetail[j].UpdateStatus +
                                        ",DeleteStatus:" + response.data.data[0].UserPermissionDetail[j].DeleteStatus +
                                        ",ViewStatus:" + response.data.data[0].UserPermissionDetail[j].ViewStatus +
                                        ",PrintStatus:" + response.data.data[0].UserPermissionDetail[j].PrintStatus +
                                        ",ExcelStatus:" + response.data.data[0].UserPermissionDetail[j].ExcelStatus, { path: '/' });

                                    cookies.set(response.data.data[0].UserPermissionDetail[j].FunctionalityPage,
                                        "AddStatus:" + response.data.data[0].UserPermissionDetail[j].AddStatus +
                                        ",UpdateStatus:" + response.data.data[0].UserPermissionDetail[j].UpdateStatus +
                                        ",DeleteStatus:" + response.data.data[0].UserPermissionDetail[j].DeleteStatus +
                                        ",ViewStatus:" + response.data.data[0].UserPermissionDetail[j].ViewStatus +
                                        ",PrintStatus:" + response.data.data[0].UserPermissionDetail[j].PrintStatus +
                                        ",ExcelStatus:" + response.data.data[0].UserPermissionDetail[j].ExcelStatus, { path: '/' });
                                }
                                else {
                                    cookies.set("ViewTask",
                                        "AddStatus:" + response.data.data[0].UserPermissionDetail[j].AddStatus +
                                        ",UpdateStatus:" + response.data.data[0].UserPermissionDetail[j].UpdateStatus +
                                        ",DeleteStatus:" + response.data.data[0].UserPermissionDetail[j].DeleteStatus +
                                        ",ViewStatus:" + response.data.data[0].UserPermissionDetail[j].ViewStatus +
                                        ",PrintStatus:" + response.data.data[0].UserPermissionDetail[j].PrintStatus +
                                        ",ExcelStatus:" + response.data.data[0].UserPermissionDetail[j].ExcelStatus, { path: '/' });

                                    cookies.set(response.data.data[0].UserPermissionDetail[j].FunctionalityPage,
                                        "AddStatus:" + response.data.data[0].UserPermissionDetail[j].AddStatus +
                                        ",UpdateStatus:" + response.data.data[0].UserPermissionDetail[j].UpdateStatus +
                                        ",DeleteStatus:" + response.data.data[0].UserPermissionDetail[j].DeleteStatus +
                                        ",ViewStatus:" + response.data.data[0].UserPermissionDetail[j].ViewStatus +
                                        ",PrintStatus:" + response.data.data[0].UserPermissionDetail[j].PrintStatus +
                                        ",ExcelStatus:" + response.data.data[0].UserPermissionDetail[j].ExcelStatus, { path: '/' });
                                }

                            }
                            cookies.set(response.data.data[0].UserPermissionDetail[j].FunctionalityPage,
                                "AddStatus:" + response.data.data[0].UserPermissionDetail[j].AddStatus +
                                ",UpdateStatus:" + response.data.data[0].UserPermissionDetail[j].UpdateStatus +
                                ",DeleteStatus:" + response.data.data[0].UserPermissionDetail[j].DeleteStatus +
                                ",ViewStatus:" + response.data.data[0].UserPermissionDetail[j].ViewStatus +
                                ",PrintStatus:" + response.data.data[0].UserPermissionDetail[j].PrintStatus +
                                ",ExcelStatus:" + response.data.data[0].UserPermissionDetail[j].ExcelStatus, { path: '/' });
                            //const element = array[index];

                        }
                        //if (response.data.data[0].UserType=="Sub Admin") {
                        if (response.data.data.length > 1) {

                            window.location.href = "/openuserpopup";
                            cookies.set('IsMultiple', "True", { path: '/' });
                        }
                        else {
                            cookies.set('MainClientName', response.data.data[0].ClientName, { path: '/' });
                            cookies.set('MainCompanyName', response.data.data[0].CompanyName, { path: '/' });
                            cookies.set('UserImage', response.data.data[0].UserImage, { path: '/' });
                            cookies.set('UserName', response.data.data[0].UserName, { path: '/' });
                            cookies.set('FullName', response.data.data[0].FullName, { path: '/' });

                            // if (response.data.data[0].UserPermissionDetail[0].FunctionalityPage==="ViewAssignTask"||response.data.data[0].UserPermissionDetail[0].FunctionalityPage==="ViewStartTask"||response.data.data[0].UserPermissionDetail[0].FunctionalityPage==="ViewFinishTask"||response.data.data[0].UserPermissionDetail[0].FunctionalityPage==="ViewCompleteTask"||response.data.data[0].UserPermissionDetail[0].FunctionalityPage==="ViewApprovalTask"||response.data.data[0].UserPermissionDetail[0].FunctionalityPage==="ViewTaskPayment") {
                            //     window.location.href="/ViewTask";
                            // }
                            // else {
                            //     window.location.href="/"+response.data.data[0].UserPermissionDetail[0].FunctionalityPage;
                            // }
                            if (response.data.data[0].UserType == "Sub Admin" && response.data.data[0].UserPermissionDetail[0].DefaultFunctionalityPage == "ViewTask") {
                                cookies.set('RedirectPage', "ViewSubAdminTask", { path: '/' });
                                window.location.href = "/viewsubadmintask";
                            }
                            else {
                                cookies.set('RedirectPage', response.data.data[0].UserPermissionDetail[0].DefaultFunctionalityPage, { path: '/' });
                                window.location.href = "/" + response.data.data[0].UserPermissionDetail[0].DefaultFunctionalityPage;
                            }


                        }
                        // }
                        // else {
                        //     cookies.set('MainClientName', response.data.data[0].ClientName, { path: '/' });
                        //     cookies.set('MainCompanyName', response.data.data[0].CompanyName, { path: '/' });
                        //     cookies.set('UserImage', response.data.data[0].UserImage, { path: '/' });
                        //     cookies.set('UserName', response.data.data[0].UserName, { path: '/' });
                        //     cookies.set('FullName', response.data.data[0].FullName, { path: '/' });
                        //     // if (response.data.data[0].UserPermissionDetail[0].FunctionalityPage==="ViewAssignTask"||response.data.data[0].UserPermissionDetail[0].FunctionalityPage==="ViewStartTask"||response.data.data[0].UserPermissionDetail[0].FunctionalityPage==="ViewFinishTask"||response.data.data[0].UserPermissionDetail[0].FunctionalityPage==="ViewCompleteTask"||response.data.data[0].UserPermissionDetail[0].FunctionalityPage==="ViewApprovalTask"||response.data.data[0].UserPermissionDetail[0].FunctionalityPage==="ViewTaskPayment") {
                        //     //     window.location.href="/ViewTask";
                        //     // }
                        //     // else {
                        //     //     window.location.href="/"+response.data.data[0].UserPermissionDetail[0].FunctionalityPage;
                        //     // }
                        //     cookies.set('RedirectPage', response.data.data[0].UserPermissionDetail[0].DefaultFunctionalityPage, { path: '/' });
                        //     window.location.href="/"+response.data.data[0].UserPermissionDetail[0].DefaultFunctionalityPage;
                        // }



                        //cookies.set('PermissionDetail', response.data.data[0].UserPermissionDetail, { path: '/' });

                    }
                    else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: 'Please give access to user', showConfirmButton: false, timer: 3000 });
                    }
                }
                else {
                    if (response.data.data[0].FunctionalityPageDetail != null) {
                        cookies.set('PanelUserType', response.data.data[0].UserType);
                        for (let i = 0; i < response.data.data[0].FunctionalityPageDetail.length; i++) {
                            if (response.data.data[0].FunctionalityPageDetail[i].FunctionalityPage !== "AddTask") {
                                cookies.set(response.data.data[0].FunctionalityPageDetail[i].FunctionalityPage,
                                    "AddStatus:true" +
                                    ",UpdateStatus:true" +
                                    ",DeleteStatus:true" +
                                    ",ViewStatus:true" +
                                    ",PrintStatus:false" +
                                    ",ExcelStatus:true", { path: '/' });
                            }
                            //const element = array[index];
                        }
                        cookies.set('IsMultiple', "True", { path: '/' });
                        window.location.href = "/openpopup";
                        //cookies.set('PermissionDetail', response.data.data[0].UserPermissionDetail, { path: '/' });
                        //window.location.href="/"+response.data.data[0].UserPermissionDetail[0].FunctionalityPage;
                    }
                }


            }
            else {
                Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
            }
        }, error => { })
    }
    render() {
        return (
            <>

                <div style={{ height: '100%' }}>
                    <div className="d-flex flex-column flex-root" style={{ height: '100%' }}>
                        <div className="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
                            {/* <div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat" style={{ backgroundImage: 'linear-gradient(to right top, #3699ff, #47a1ff, #56a8ff, #64b0ff, #72b7ff)' }}> */}
                            <div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat" style={{ backgroundImage: 'url(Images/LoginBanner.jpeg)', backgroundSize: '100% 100%' }}>
                                <div className="login-form text-center p-7 position-relative overflow-hidden" style={{ width: '60%', border: '1px solid' }}>
                                    <div className="d-flex flex-center ">
                                        {/* <a href="#">
                                            <img src="Images/logo2.png" />
                                        </a> */}
                                    </div>
                                    <div className="login-signin">
                                        <form className="form" id="kt_login" onSubmit={this.onSubmit}>
                                            {/* <FormWithConstraints
                                        ref={form => this.form=form}
                                        onSubmit={this.onSubmit}
                                        noValidate id=""> */}
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group mb-5">
                                                        <input id="txtUserName" className="form-control h-auto form-control-solid py-4 px-8" type="text" value={this.state.UserName} onChange={(e) => this.setState({ UserName: e.target.value })} placeholder="Enter UserName" name="UserName" autoComplete="off" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group mb-5">
                                                        <input id="txtPassword" className="form-control h-auto form-control-solid py-4 px-8" type="password" value={this.state.Password} onChange={(e) => this.setState({ Password: e.target.value })} placeholder="Enter Password" name="Password" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4" style={{ textAlign: 'left' }}>
                                                    <button type="submit" className="btn btn-primary font-weight-bold px-9 py-4 mx-4" onClick={this.onSubmit}>
                                                        Login
                                                    </button>
                                                </div>
                                            </div>
                                            {/* </FormWithConstraints> */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );

    }
}
export default withCookies(adminlogin);