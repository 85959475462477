import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';

import XLSX from "xlsx-color";
import moment from 'moment';
const headerTblStyle={ color: 'black' };
const exportToExcel=(columns, data) => {
    debugger;
    const header=["Sr.No", "Status", "Description"];
    debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else {
                obj[col]=row[col];
            }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { header });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols']=[
        { width: 6 },
        { width: 20 },
        { width: 20 },

    ];
    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;



    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "MessageTextConfiguration");
    XLSX.writeFile(wb, "MessageTextConfigurationList_"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}
class MessageTextConfiguration extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [],
            MessageTextConfigurationID: "", Description: "",
            Status: "",
            // MessageTextConfigurationID: "", Status: "", CodeName: "",
            statusdatas: [{ label: "New", value: "New" },
            { label: "Assigned", value: "Assigned" },
            { label: "Started", value: "Started" },
            { label: "Finished", value: "Finished" },
            { label: "Completed", value: "Completed" },
            { label: "Approved", value: "Approved" },
            { label: "ReferredBack", value: "ReferredBack" },
            ],
            StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' },
            SearchStatusID: { 'label': '--Select Status--', 'value': '--Select Status--' },
            StatusMsg: "",
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'center' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'Status', width: '40%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Status}</p>) } },
                { title: 'Description', width: '40%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Description}</p>) } }
            ]
        }
    }

    componentDidMount() {
        this.GetMessageTextConfiguration();
    }
    GetMessageTextConfiguration() {
        debugger;
        var CompanyID="";
        var ClientID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "MessageTextConfigurationID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"MessageTextConfiguration/getMessageTextConfiguration",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                if (response.data.status) {
                    this.setState({ data: response.data.data })
                }
                else {
                    this.setState({ data: [] })
                }
            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }
    EditMessageid(id) {
        var CompanyID="";
        var ClientID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "MessageTextConfigurationID": id,
                "CompanyID": CompanyID,
                "ClientID": ClientID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"MessageTextConfiguration/getMessageTextConfiguration",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {

                debugger;
                this.setState({
                    MessageTextConfigurationID: response.data.data[0].MessageTextConfigurationID,
                    StatusID: { 'label': response.data.data[0].Status, 'value': response.data.data[0].Status },
                    Description: response.data.data[0].Description
                    //rows: response.data.data[0].ZoneEmailDetail
                });

                // this.setState({ IsEdit: false });
            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }
    }
    ValidateStatusShip=(e) => {
        this.setState({ StatusMsg: '' })
    }
    ValidateCancleStatusShip=(e) => {
        this.setState({ StatusMsg: 'required' })
    }
    onStatusChange=(e) => {
        debugger;
        if (e!=null) {
            this.setState({ StatusID: e }, (e) => { this.ValidateStatusShip(); })
        }
        else {
            this.setState({ StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' } }, () => { this.ValidateCancleStatusShip() });
        }
    }
    handleSubmit=(e) => {
        debugger;
        e.preventDefault();
        this.form.validateFields();

        var CompanyID="";
        var ClientID="";
        var UserID="";
        var UserType="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID!==undefined) {
            UserID=this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType!==undefined) {
            UserType=this.props.allCookies.PanelUserType
        }
        if (this.state.StatusID.value=="--Select Status--") {
            this.setState({ StatusMsg: 'required' });
        }
        // for(let i=0; i< rows.length, i++)
        // {
        // }
        if (CompanyID!=""&&ClientID!="") {
            if (this.form.isValid()&&this.state.StatusID.value!=="--Select Status--") {
                var statusbind=this.state.StatusID.value;
                var data="";
                if (this.state.MessageTextConfigurationID!="") {
                    data=JSON.stringify({
                        "MessageTextConfigurationID": this.state.MessageTextConfigurationID,
                        "CompanyID": CompanyID,
                        "ClientID": ClientID,
                        "Status": statusbind,
                        "MobileNo": this.state.MobileNo,
                        "Description": this.state.Description,
                        "EntryByUserType": UserType,
                        "EntryByUserID": UserID
                    });
                }
                else {
                    data=JSON.stringify({
                        "MessageTextConfigurationID": "",
                        "CompanyID": CompanyID,
                        "ClientID": ClientID,
                        "Status": statusbind,
                        "MobileNo": this.state.MobileNo,
                        "Description": this.state.Description,
                        "EntryByUserType": "",
                        "EntryByUserID": "",
                    });
                }

                var config={
                    method: 'POST',
                    url: services.API_URL+"MessageTextConfiguration/setMessageTextConfiguration",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    //debugger;
                    if (response.data.status) {
                        debugger;
                        if (this.state.MessageTextConfigurationID!="") {
                            // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                            Swal.fire({
                                title: 'Successfully Updated', icon: "success", timer: 1500
                            });
                        }
                        else {
                            Swal.fire({
                                title: 'Successfully Inserted', icon: "success", timer: 1500
                            });
                        }
                        this.ClearData();
                        this.GetMessageTextConfiguration();
                    }
                    else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                }, error => { })
            }

        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }
    }
    ClearData=(e) => {
        debugger;
        this.form.reset();
        this.setState({
            StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' }, MessageTextConfigurationID: "", Description: "", Subject: "", StatusMsg: "",
        });
        this.GetMessageTextConfiguration();
    }
    CheckAlert(id,status) {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                var CompanyID="";
                var ClientID="";
                var UserID="";
                var UserType="";
                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                var data=JSON.stringify({
                    "MessageTextConfigurationID": id,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "Status": status,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+"MessageTextConfiguration/removeMessageTextConfiguration",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        this.GetMessageTextConfiguration();
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        });
                        this.GetMessageTextConfiguration();
                    } else {
                        Swal.fire({ icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }
    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.data)
    }
    render() {
        const { cookies }=this.props
        debugger;
        var Permission=cookies.cookies.MessageTextConfiguration;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (

            <>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row">
                        <div className="col-md-5">
                            {
                                this.state.AddStatus==="AddStatus:true"&&
                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header">
                                        <h3 className="card-title">Add Message Text Configuration</h3>
                                    </div>
                                    <FormWithConstraints
                                        ref={form => this.form=form}
                                        onSubmit={this.handleSubmit}
                                        noValidate>
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Status<span className="text-danger">*</span></label>
                                                        <Select isClearable={true} options={this.state.statusdatas} value={this.state.StatusID} onChange={this.onStatusChange} />
                                                        {this.state.StatusMsg&&<span className="text-danger">{this.state.StatusMsg==='required'? 'Please select status':''}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                            <label>Description<span className="text-danger">*</span></label>
                                                            <textarea name="Description" required
                                                                className="form-control" rows={3} value={this.state.Description}
                                                                onChange={(e) => { this.setState({ Description: e.target.value }, () => { this.form.validateFields(e.target) }) }} placeholder="Description" />
                                                            <FieldFeedbacks for="Description">
                                                                <FieldFeedback when="valueMissing">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" onSubmit={this.handleSubmit} className="btn btn-primary mr-2">
                                                Submit
                                            </button>
                                            <button type="button" onClick={this.ClearData} className="btn btn-secondary">
                                                Cancel
                                            </button>
                                        </div>
                                    </FormWithConstraints>
                                </div>
                            }
                        </div>
                        <div className="col-md-7">
                            {
                                this.state.ViewStatus==="ViewStatus:true"&&
                                <div className="card card-custom">
                                    <div className="card-body">
                                        <MaterialTable title="" columns={this.state.cols} data={this.state.data}

                                            actions={[{
                                                hidden: this.state.UpdateStatus=="UpdateStatus:true"? false:true,
                                                icon: 'edit', tooltip: 'Edit', onClick: (e, r) => this.EditMessageid(r.MessageTextConfigurationID)
                                            },
                                            {
                                                hidden: this.state.DeleteStatus=="DeleteStatus:true"? false:true,
                                                icon: 'delete', tooltip: 'Delete', onClick: (e, r) => this.CheckAlert(r.MessageTextConfigurationID,r.Status)
                                            }
                                            ]}
                                            options={{
                                                headerStyle: { color: 'black' }, toolbar: true, search: false,draggable:false,
                                                paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                            }}
                                            components={{

                                                Toolbar: props => (
                                                    <div className="row" style={{ marginBottom: '2%' }}>
                                                        <div className="col-md-8  mobpb-5" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h3 className="tabletitle">View Message Text Configuration</h3>

                                                        </div>
                                                        <div className="col-md-4" style={{ textAlign: 'right' }}>
                                                            {
                                                                this.state.ViewStatus==="ViewStatus:true"&&
                                                                <>
                                                                    {this.state.ExcelStatus==="ExcelStatus:true"&&
                                                                        <a className="btn btn-outline-success font-weight-bolder ml-4" onClick={this.state.data.length>0? this.ExcelClick:''}>
                                                                            <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                                        </a>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </>
        );
    }
}
export default withCookies(MessageTextConfiguration);