import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { render } from "@testing-library/react";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import XLSX from "xlsx-color";
import moment from 'moment';
const headerTblStyle={ color: 'black' };
const exportToExcel=(columns, data) => {
    debugger;
    const header=["Sr.No", "UserType", "FullName", "UserPermissionDetail", "Permission"];
    const headertemp=["Sr.No", "User Type", "Full Name", "Functionality", "Permission"];
    debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);

            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else if (col=="UserType") {
                obj["User Type"]=row[col];
            }
            else if (col=="FullName") {
                obj["Full Name"]=row[col];
            }
            else if (col=="UserPermissionDetail") {
                var Functionality="";
                var Permission="";
                if (row["UserPermissionDetail"]!=null) {
                    // for (let i=0; i<row["UserPermissionDetail"].length; i++) {
                    //     Functionality+=row["UserPermissionDetail"][i]["FunctionalityPage"]+","
                    // }
                    for (let i=0; i<row["UserPermissionDetail"].length; i++) {
                        Functionality+=row["UserPermissionDetail"][i]["FunctionalityPageForFront"]+","
                    }
                    if (row["UserPermissionDetail"][0]["AddStatus"]==true) {
                        Permission+="Add"+",";
                    }
                    if (row["UserPermissionDetail"][0]["UpdateStatus"]==true) {
                        Permission+="Update"+",";
                    }
                    if (row["UserPermissionDetail"][0]["DeleteStatus"]==true) {
                        Permission+="Delete"+",";
                    }
                    if (row["UserPermissionDetail"][0]["ViewStatus"]==true) {
                        Permission+="View"+",";
                    }
                    Functionality=Functionality.substring(0, Functionality.length-1);
                    Permission=Permission.substring(0, Permission.length-1);
                }
                obj["Functionality"]=Functionality;
                obj["Permission"]=Permission;
            }
            // else {
            //     obj[col] = row[col];
            // }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols']=[
        { width: 6 },
        { width: 20 },
        { width: 20 },
        { width: 40 },
        { width: 40 },

    ];
    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;
    ws1['D1'].s=cell;
    ws1['E1'].s=cell;

    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;
        ws1['D'+ins].s=RowCont;
        ws1['E'+ins].s=RowCont;


        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "UserPermissionList");
    XLSX.writeFile(wb, "UserPermissionList_"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}
class UserPermission extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            name: cookies.get('name')||'Ben'
        };
        this.state={
            activeItem: 'userpermission',
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [], usertypes: [], users: [], searchusers: [], functionalities: [{ 'id': 'No Functionality', 'value': 'No Functionality' }],
            defaultpage:[],
            UserTypeID: { 'label': '--Select User Type--', 'value': '--Select User Type--' },
            SearchUserTypeID: { 'label': '--Select User Type--', 'value': '--Select User Type--' },
            UserID: { 'label': '--Select User--', 'value': '--Select User--' },
            DefaultFunctionalityPageID:{ 'label': '--Select Default Page--', 'value': '--Select Default Page--' },
            SearchUserID: { 'label': '--Select User--', 'value': '--Select User--' },
            UserPermissionMasterID: "",IsDefaultPageDisable:false,
            UserErrMsg: "", UserTypeErrMsg: "", FunctionalityErrMsg: "",DefaultPageErrMsg:"", isAddChecked: false, isUpdateChecked: false, isDeleteChecked: false,
            isViewChecked: false, isExcelChecked: false, UserPermissionErrMsg: "", IsUserDisable: false, IsUserDisable: false,
            isFunctionalityChecked: false, FunctionalityDataAvailable: true, NoFunctionalityVisibleMsg: false,
            AddUserPermissionVisible: false, iconAdd: "fa fa-plus", isFilterVisible: false, iconFilter: "fa fa-plus",
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'center' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'User Type', width: '15%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.UserType}</p>) } },
                { title: 'User Name', width: '25%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.FullName}</p>) } },
                // { title: 'Default Page', width: '25%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.FunctionalityPage}</p>) } },
                { title: 'Default Page', width: '25%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.FunctionalityPageForFront}</p>) } },
                { title: 'Date', width: '25%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CreatedDate}</p>) } },


            ]
        }

    }
    componentDidMount() {
        this.GetUserType();
        this.GetFunction();
        this.GetUserPermission();

    }
    //Functions
    GetUserPermission() {
        debugger;

        var CompanyID="";
        var ClientID="";
        var SearchUserTypeID="";
        var SearchUserID="";

        if (this.state.SearchUserTypeID.value!="--Select User Type--") {
            SearchUserTypeID=this.state.SearchUserTypeID.value;
        }
        else {
            SearchUserTypeID="";
        }

        if (this.state.SearchUserID.value!="--Select User--") {
            SearchUserID=this.state.SearchUserID.value;
        }
        else {
            SearchUserID="";
        }
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "UserPermissionMasterID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "UserID": SearchUserID,
                "UserTypeID": SearchUserTypeID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"UserPermission/getUserPermission",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                debugger;
                if (response.data.status) {
                    if (this.state.ViewStatus==="ViewStatus:true") {
                        this.setState({ data: response.data.data })
                    }
                    else {
                        this.setState({ data: [] })
                    }
                }
                else {
                    this.setState({ data: [] })
                }

            }, error => { })
        }
        else {
            if (CompanyID=="") {
                Swal.fire({
                    title: 'Please Select Company ', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }
    GetUserType() {
        var CompanyID="";
        var ClientID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (CompanyID!=""&&ClientID) {
            var data=JSON.stringify({
                "UserTypeID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "IsActive": "true",
                "IsDelete": "false"
            });
            var config={
                method: 'POST',
                url: services.API_URL+"UserType/getUserType",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                debugger;
                this.setState({ usertypes: response.data.data.map(item => ({ value: item.UserTypeID, label: item.UserType })) });
            }, error => { })
        }
        else {

        }

    }
    GetFunction() {
        var data=JSON.stringify({
            "FunctionalityPageID": ""
        });
        var config={
            method: 'POST',
            url: services.API_URL+"FunctionalityPage/getFunctionalityPage",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                // this.setState({ functionalities: response.data.data.map(item => ({ id: item.FunctionalityPageID, value: item.FunctionalityPage, isFunctionalityChecked: false })) });
                // this.setState({ defaultpage: response.data.data.map(item => ({ value: item.FunctionalityPageID, label: item.FunctionalityPage })) });

                this.setState({ functionalities: response.data.data.map(item => ({ id: item.FunctionalityPageID, value: item.FunctionalityPageForFront, isFunctionalityChecked: false })) });
                this.setState({ defaultpage: response.data.data.map(item => ({ value: item.FunctionalityPageID, label: item.FunctionalityPageForFront })) });
                
                this.setState({ FunctionalityDataAvailable: true });
                this.setState({ NoFunctionalityVisibleMsg: false });
            }
            else {
                this.setState({ functionalities: [] });
                this.setState({ defaultpage: [] });
                this.setState({ isFunctionalityChecked: false });
                this.setState({ FunctionalityDataAvailable: false });
                this.setState({ NoFunctionalityVisibleMsg: true });
            }

        }, error => { })

    }
    EditUserPermission(id) {
        var CompanyID="";
        var ClientID="";

        debugger;

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "UserPermissionMasterID": id,
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "UserID": ""
            });
            var config={
                method: 'POST',
                url: services.API_URL+"UserPermission/getUserPermission",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                if (response.data.status) {
                    this.setState({ AddUserPermissionVisible: true });
                    var CompanyID="";
                    var ClientID="";

                    if (this.props.allCookies.MainCompanyID!==undefined) {
                        CompanyID=this.props.allCookies.MainCompanyID;
                    }
                    if (this.props.allCookies.MainClientID!==undefined) {
                        ClientID=this.props.allCookies.MainClientID
                    }

                    if (CompanyID!=""&&ClientID) {
                        var data=JSON.stringify({
                            "UserID": "",
                            "CompanyID": CompanyID,
                            "ClientID": ClientID,
                            "UserTypeID": response.data.data[0].UserTypeID,
                            "MobileNo": "",
                            "CountryID": "",
                            "StateID": "",
                            "CityID": ""
                        });

                        var config={
                            method: 'POST',
                            url: services.API_URL+"User/getUser",
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data: data
                        };
                        services.JsonValue(config).then(response => {
                            debugger;
                            if (response.data.status) {

                                this.setState({ users: response.data.data.map(item => ({ value: item.UserID, label: item.FullName })) });
                            }
                        }, error => { })
                    }
                    this.setState({ IsUserTypeDisable: true });
                    this.setState({ IsUserDisable: true });
                    this.setState({
                        UserPermissionMasterID: response.data.data[0].UserPermissionMasterID,
                        UserTypeID: { 'label': response.data.data[0].UserType, 'value': response.data.data[0].UserTypeID },
                        UserID: { 'label': response.data.data[0].FullName, 'value': response.data.data[0].UserID },

                        // DefaultFunctionalityPageID: { 'label': response.data.data[0].FunctionalityPage, 'value': response.data.data[0].DefaultFunctionalityPageID },

                        DefaultFunctionalityPageID: { 'label': response.data.data[0].FunctionalityPageForFront, 'value': response.data.data[0].DefaultFunctionalityPageID },

                        isAddChecked: response.data.data[0].UserPermissionDetail[0].AddStatus,
                        isUpdateChecked: response.data.data[0].UserPermissionDetail[0].UpdateStatus,
                        isDeleteChecked: response.data.data[0].UserPermissionDetail[0].DeleteStatus,
                        isViewChecked: response.data.data[0].UserPermissionDetail[0].ViewStatus,
                        isExcelChecked: response.data.data[0].UserPermissionDetail[0].ExcelStatus,
                    })
                    let functionalities=this.state.functionalities;
                    for (let i=0; i<functionalities.length; i++) {
                        functionalities[i].isFunctionalityChecked=false;

                    }
                    let StoredFunctionalities=response.data.data[0].UserPermissionDetail
                    for (let i=0; i<functionalities.length; i++) {
                        debugger;
                        for (let j=0; j<StoredFunctionalities.length; j++) {
                            // const element = array[index];
                            if (functionalities[i].id==StoredFunctionalities[j].FunctionalityPageID) {
                                debugger;
                                functionalities[i].isFunctionalityChecked=true;

                            }
                            else {

                            }

                        }
                        // const element = zones[index];
                    }
                    this.setState({ functionalities: functionalities });
                }

            }, error => { })
        }
        else {
            if (CompanyID=="") {
                Swal.fire({
                    title: 'Please Select Company ', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }
    }
    // Validation Change
    ValidateUserType=(e) => {
        this.setState({ UserTypeErrMsg: '' })
    }
    ValidateCancelUserType=(e) => {
        this.setState({ UserTypeErrMsg: 'required' })
    }
    ValidateUser=(e) => {
        this.setState({ UserErrMsg: '' })
    }
    ValidateCancelUser=(e) => {
        this.setState({ UserErrMsg: 'required' })
    }

    ValidateDefaultPage=(e) => {
        this.setState({ DefaultPageErrMsg: '' })
    }
    ValidateCancelDefaultPage=(e) => {
        this.setState({ DefaultPageErrMsg: 'required' })
    }
    ValidateFunctionality=(e) => {
        this.setState({ FunctionalityErrMsg: "" });
    }
    ValidateCancelFunctionality=(e) => {
        this.setState({ FunctionalityErrMsg: "required" });
    }
    // onDropDownChange
    onUserTypeChange=(e) => {
        debugger;
        if (e!=null) {
            var CompanyID="";
            var ClientID="";

            if (this.props.allCookies.MainCompanyID!==undefined) {
                CompanyID=this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID!==undefined) {
                ClientID=this.props.allCookies.MainClientID
            }
            this.setState({ UserTypeID: e }, () => { this.ValidateUserType() });
            if (CompanyID!=""&&ClientID) {
                var data=JSON.stringify({
                    "UserID": "",
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "UserTypeID": e.value,
                    "MobileNo": "",
                    "CountryID": "",
                    "StateID": "",
                    "CityID": ""
                });

                var config={
                    method: 'POST',
                    url: services.API_URL+"User/getUser",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    debugger;
                    if (response.data.status) {

                        this.setState({ users: response.data.data.map(item => ({ value: item.UserID, label: item.FullName })) });
                    }
                    else {
                        this.setState({ users: [] })
                        this.setState({ UserID: { 'label': '--Select User--', 'value': '--Select User--' } })
                    }

                }, error => { })
            }
        }
        else {

            this.setState({ UserTypeID: { 'label': '--Select User Type--', 'value': '--Select User Type--' } }, () => { this.ValidateCancelUserType() });
        }
    }
    onSearchUserTypeChange=(e) => {
        debugger;
        if (e!=null) {
            var CompanyID="";
            var ClientID="";

            if (this.props.allCookies.MainCompanyID!==undefined) {
                CompanyID=this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID!==undefined) {
                ClientID=this.props.allCookies.MainClientID
            }
            this.setState({ SearchUserTypeID: e });
            if (CompanyID!=""&&ClientID) {
                var data=JSON.stringify({
                    "UserID": "",
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "UserTypeID": e.value,
                    "MobileNo": "",
                    "CountryID": "",
                    "StateID": "",
                    "CityID": ""
                });

                var config={
                    method: 'POST',
                    url: services.API_URL+"User/getUser",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    debugger;
                    if (response.data.status) {

                        this.setState({ searchusers: response.data.data.map(item => ({ value: item.UserID, label: item.FullName })) });
                    }
                    else {
                        this.setState({ searchusers: [] })
                        this.setState({ SearchUserID: { 'label': '--Select User--', 'value': '--Select User--' } })
                    }

                }, error => { })
            }
        }
        else {
            this.setState({ searchusers: [] })
            this.setState({ SearchUserID: { 'label': '--Select User--', 'value': '--Select User--' } })
            this.setState({ UserTypeID: { 'label': '--Select User Type--', 'value': '--Select User Type--' } }, () => { this.GetUserPermission() });
        }
    }
    onSearchUserChange=(e) => {
        if (e!=null) {
            this.setState({ SearchUserID: e })
        }
        else {
            this.setState({ SearchUserID: { 'label': '--Select User--', 'value': '--Select User--' } });
        }

    }
    onUserChange=(e) => {
        if (e!=null) {
            this.setState({ UserID: e }, () => { this.ValidateUser() });
        }
        else {
            this.setState({ UserID: { 'label': '--Select User--', 'value': '--Select User--' } }, () => { this.ValidateCancelUser() });
        }
    }
    onPageChange=(e) => {
        if (e!=null) {
            this.setState({ DefaultFunctionalityPageID: e }, () => { this.ValidateDefaultPage() });
        }
        else {
            this.setState({ DefaultFunctionalityPageID: { 'label': '--Select Default Page--', 'value': '--Select Default Page--' } }, () => { this.ValidateCancelDefaultPage() });
        }
    }
    onFunctionalityChange=(e, action) => {
        debugger;

        let functionalities=this.state.functionalities;
        var allvalueselectted=false;
        var isValid=false;
        functionalities.forEach(functionalities => {
            debugger
            if (functionalities.id===e.target.value) {
                functionalities.isFunctionalityChecked=e.target.checked;
            }
            if (functionalities.isFunctionalityChecked==false) {
                allvalueselectted=true;
            }
            if (functionalities.isFunctionalityChecked==true) {
                isValid=true;
            }

        });
        this.setState({ functionalities: functionalities });
        if (allvalueselectted==false) {
            this.setState({ isFunctionalityChecked: true });
        }
        else {
            this.setState({ isFunctionalityChecked: false });
        }
        if (isValid==true) {
            this.ValidateFunctionality();
        }
        else {
            this.ValidateCancelFunctionality();
        }

    }
    onFunctionalityAllChange=(e, action) => {
        debugger;

        if (e.target.checked==true) {
            this.setState({ isFunctionalityChecked: true });
            this.setState({
                functionalities: this.state.functionalities.map(item =>
                    ({ id: item.id, value: item.value, isFunctionalityChecked: true }))
            }, () => { this.ValidateFunctionality() });
        }
        else {
            this.setState({ isFunctionalityChecked: false });
            this.setState({
                functionalities: this.state.functionalities.map(item =>
                    ({ id: item.id, value: item.value, isFunctionalityChecked: false }))
            }, () => { this.ValidateCancelFunctionality() });
        }
        //this.setState({ IsHelperChange: true });
        //this.setState({ IsHelperChange: true }, () => { this.onDynamicHelper(e, e.target.checked) });
    }
    onAddChange=(e, action) => {
        if (e.target.checked==true) {
            this.setState({ isAddChecked: true });
        }
        else {
            this.setState({ isAddChecked: false });
        }
    }
    onUpdateChange=(e, action) => {
        if (e.target.checked==true) {
            this.setState({ isUpdateChecked: true });
        }
        else {
            this.setState({ isUpdateChecked: false });
        }
    }
    onDeleteChange=(e, action) => {
        if (e.target.checked==true) {
            this.setState({ isDeleteChecked: true });
        }
        else {
            this.setState({ isDeleteChecked: false });
        }
    }
    onViewChange=(e, action) => {
        if (e.target.checked==true) {
            this.setState({ isViewChecked: true });
        }
        else {
            this.setState({ isViewChecked: false });
        }
    }
    onExcelChange=(e, action) => {
        if (e.target.checked==true) {
            this.setState({ isExcelChecked: true });
        }
        else {
            this.setState({ isExcelChecked: false });
        }
    }
    //on Button Click
    CheckAlert(id,userid,usertype,username) {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                var CompanyID="";
                var ClientID="";
                var UserID="";
                var UserType="";
                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                var data=JSON.stringify({
                    "UserPermissionMasterID": id,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "UserID":userid,
                    "UserName": username,
                    "UserType": usertype,
                    "EntryByUserType": UserType,
                    "EntryByUserID":UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+"UserPermission/removeUserPermissionMaster",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {

                        this.GetUserPermission();
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        })
                    } else {
                        Swal.fire({ icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }
    onAddUserPermissionClick=(e) => {
        if (this.state.AddUserPermissionVisible==false) {
            this.setState({ AddUserPermissionVisible: true });
            this.setState({ iconAdd: "fa fa-minus" });
        }
        else {
            this.setState({ AddUserPermissionVisible: false });
            this.setState({ iconAdd: "fa fa-plus" });
        }
    }
    OnFilterClick=(e) => {

        e.preventDefault();
        if (this.state.isFilterVisible===false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }
    //Javascript Functions
    allowOnlyCharacters=(event) => {
        const keyCode=event.keyCode||event.which;
        const keyValue=String.fromCharCode(keyCode);
        if (!new RegExp("^[a-zA-Z ]+$").test(keyValue)) event.preventDefault();
        return;
    };
    allowOnlyNumbers=(event) => {
        const keyCode=event.keyCode||event.which;
        const keyValue=String.fromCharCode(keyCode);
        if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
        return;
    };
    ClearData=(e) => {
        this.form.reset();
        this.setState({
            usertypes: [], users: [],defaultpage:[], functionalities: [{ 'id': 'No Functionality', 'value': 'No Functionality' }],
            UserTypeID: { 'label': '--Select User Type--', 'value': '--Select User Type--' },
            UserID: { 'label': '--Select User--', 'value': '--Select User--' },
            DefaultFunctionalityPageID:{ 'label': '--Select Default Page--', 'value': '--Select Default Page--' },
            UserPermissionMasterID: "",
            UserErrMsg: "", UserTypeErrMsg: "", FunctionalityErrMsg: "", isAddChecked: false, isUpdateChecked: false, isDeleteChecked: false, isExcelChecked: false,
            isViewChecked: false, UserPermissionErrMsg: "", isExcelChecked: false,
            isFunctionalityChecked: false, FunctionalityDataAvailable: true, NoFunctionalityVisibleMsg: false,
            IsUserDisable: false, IsUserTypeDisable: false
        });
        this.GetUserType();
        this.GetFunction();
    }
    handleSubmit=(e) => {
        debugger;
        e.preventDefault();
        let functionalitiesdatas=[];
        var allvalid=true;
        this.form.validateFields();
        if (this.state.UserTypeID.value=="--Select User Type--") {
            this.setState({ UserTypeErrMsg: 'required' });
            allvalid=false;
        }

        if (this.state.UserID.value=="--Select User--") {
            this.setState({ UserErrMsg: 'required' });
            allvalid=false;
        }
        if (this.state.DefaultFunctionalityPageID.value=="--Please Select Default Page--") {
            this.setState({ DefaultPageErrMsg: 'required' });
            allvalid=false;
        }
        let functionalities=this.state.functionalities;
        functionalities.forEach(functionalities => {
            if (functionalities.isFunctionalityChecked==true) {
                functionalitiesdatas.push({
                    "FunctionalityPageID": functionalities.id, "AddStatus": this.state.isAddChecked,
                    "UpdateStatus": this.state.isUpdateChecked, "DeleteStatus": this.state.isDeleteChecked, "ViewStatus": this.state.isViewChecked,
                    "PrintStatus": false, "ExcelStatus": this.state.isExcelChecked
                })
            }
        });
        if (this.state.isAddChecked==true||this.state.isUpdateChecked==true||this.state.isDeleteChecked==true||this.state.isViewChecked==true||this.state.isExcelChecked==true) {
            allvalid=true;
        }
        else {
            allvalid=false;
            this.setState({ UserPermissionErrMsg: 'required' });
        }
        if (functionalitiesdatas.length===0) {
            this.setState({ FunctionalityErrMsg: 'required' });
            allvalid=false;
        }



        if (this.form.isValid()) {
            if (allvalid==true) {
                if (this.props.allCookies.MainCompanyID!==undefined) {
                    var CompanyID="";
                    var ClientID="";
                    var UserID="";
                    var UserType="";

                    if (this.props.allCookies.MainCompanyID!==undefined) {
                        CompanyID=this.props.allCookies.MainCompanyID;
                    }
                    if (this.props.allCookies.MainClientID!==undefined) {
                        ClientID=this.props.allCookies.MainClientID
                    }
                    if (this.props.allCookies.UserID!==undefined) {
                        UserID=this.props.allCookies.UserID
                    }
                    if (this.props.allCookies.PanelUserType!==undefined) {
                        UserType=this.props.allCookies.PanelUserType
                    }
                    var data="";
                    if (this.state.UserPermissionMasterID!="") {
                        data=JSON.stringify({
                            "UserPermissionMasterID": this.state.UserPermissionMasterID,
                            "CompanyID": CompanyID,
                            "ClientID": ClientID,
                            "UserID": this.state.UserID.value,
                            "DefaultFunctionalityPageID":this.state.DefaultFunctionalityPageID.value,
                            "UserName":this.state.UserID.label,
                            "UserType": this.state.UserTypeID.label,
                            "EntryByUserType": UserType,
                            "EntryByUserID": UserID,
                            "FunctionalityPagePermissionData": JSON.stringify(functionalitiesdatas)
                        });
                    }
                    else {
                        data=JSON.stringify({
                            "CompanyID": CompanyID,
                            "ClientID": ClientID,
                            "UserID": this.state.UserID.value,
                            "DefaultFunctionalityPageID":this.state.DefaultFunctionalityPageID.value,
                            "EntryByUserType": UserType,
                            "EntryByUserID": UserID,
                            "FunctionalityPagePermissionData": JSON.stringify(functionalitiesdatas)
                        });
                    }

                    var config={
                        method: 'POST',
                        url: services.API_URL+"UserPermission/setUserPermission",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    services.JsonValue(config).then(response => {
                        //debugger;
                        if (response.data.status) {
                            debugger;
                            if (this.state.UserPermissionMasterID!="") {
                                // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                                Swal.fire({
                                    title: 'Successfully Updated', icon: "success", timer: 1500
                                });
                            }
                            else {
                                Swal.fire({
                                    title: 'Successfully Inserted', icon: "success", timer: 1500
                                });
                            }
                            this.ClearData();
                            this.GetUserPermission();
                        }
                        else {
                            Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                        }
                    }, error => { })
                }
                else {
                    Swal.fire({
                        title: 'Please Select Company', icon: "error", timer: 1500
                    });
                }
            }

        }


    }
    onSearchUserClick=(e) => {
        e.preventDefault();
        this.GetUserPermission();
    }
    OnSearchCancelUserClick=() => {
        debugger;

        this.setState({ SearchUserTypeID: { 'label': '--Select User Type--', 'value': '--Select User Type--' } });
        this.setState({ searchusers: [] });
        this.setState({ SearchUserID: { 'label': '--Select User--', 'value': '--Select User--' } }, () => {
            this.GetUserPermission();
        });
        //window.location.reload(true);

    }
    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.data)
    }
    render() {
        const { cookies }=this.props
        var Permission=cookies.cookies.UserPermission;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <div>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row">
                        {this.state.AddUserPermissionVisible&&
                            <div className="col-md-12">
                                {
                                    this.state.AddStatus==="AddStatus:true"&&
                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header">
                                            <h3 className="card-title">Add User Permission</h3>
                                        </div>
                                        <FormWithConstraints
                                            ref={form => this.form=form}
                                            onSubmit={this.handleSubmit}
                                            noValidate>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>User Type <span className="text-danger">*</span></label>
                                                            <Select isDisabled={this.state.IsUserTypeDisable} options={this.state.usertypes} value={this.state.UserTypeID} onChange={this.onUserTypeChange} isClearable={true} />
                                                            {this.state.UserTypeErrMsg&&<span className="text-danger">{this.state.UserTypeErrMsg==='required'? 'Please select user type':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>User <span className="text-danger">*</span></label>
                                                            <Select isDisabled={this.state.IsUserDisable} isClearable={true} options={this.state.users} value={this.state.UserID} onChange={this.onUserChange} />
                                                            {this.state.UserErrMsg&&<span className="text-danger">{this.state.UserErrMsg==='required'? 'Please select user':''}</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>User Permission<span className="text-danger">*</span></label>
                                                            <div className="checkbox-inline">
                                                                <label className="checkbox usercheckbox" style={{ padding: '1%' }}>
                                                                    <input checked={this.state.isAddChecked} style={{ marginRight: 0 }} value="Add" type="checkbox" onChange={this.onAddChange} />
                                                                    <span />Add
                                                                </label>
                                                                <label className="checkbox usercheckbox" style={{ padding: '1%' }}>
                                                                    <input checked={this.state.isUpdateChecked} style={{ marginRight: 0 }} value="Update" type="checkbox" onChange={this.onUpdateChange} />
                                                                    <span />Update
                                                                </label>
                                                                <label className="checkbox usercheckbox" style={{ padding: '1%' }}>
                                                                    <input checked={this.state.isDeleteChecked} style={{ marginRight: 0 }} value="Delete" type="checkbox" onChange={this.onDeleteChange} />
                                                                    <span />Delete
                                                                </label>
                                                                <label className="checkbox usercheckbox" style={{ padding: '1%' }}>
                                                                    <input checked={this.state.isViewChecked} style={{ marginRight: 0 }} value="View" type="checkbox" onChange={this.onViewChange} />
                                                                    <span />View
                                                                </label>
                                                                <label className="checkbox usercheckbox" style={{ padding: '1%' }}>
                                                                    <input checked={this.state.isExcelChecked} style={{ marginRight: 0 }} value="Excel" type="checkbox" onChange={this.onExcelChange} />
                                                                    <span />Excel
                                                                </label>
                                                            </div>
                                                            {this.state.UserPermissionErrMsg&&<span className="text-danger">{this.state.UserPermissionErrMsg==='required'? 'Please select atleast 1 permission':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Default Page <span className="text-danger">*</span></label>
                                                            <Select isDisabled={this.state.IsDefaultPageDisable} isClearable={true} options={this.state.defaultpage} value={this.state.DefaultFunctionalityPageID} onChange={this.onPageChange} />
                                                            {this.state.DefaultPageErrMsg&&<span className="text-danger">{this.state.DefaultPageErrMsg==='required'? 'Please Select Default Page':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">

                                                            <label>Functionality <span className="text-danger">*</span></label>
                                                            <div className="checkbox-inline">
                                                                {
                                                                    this.state.FunctionalityDataAvailable&&
                                                                    <label className="checkbox usercheckbox" >
                                                                        <input checked={this.state.isFunctionalityChecked} style={{ marginRight: 0 }} value="Select All" type="checkbox" onChange={this.onFunctionalityAllChange} />
                                                                        <span />Select All
                                                                    </label>

                                                                }

                                                                {
                                                                    this.state.functionalities.map((item, index) => (

                                                                        <label className="checkbox usercheckbox" key={index}>

                                                                            <input checked={item.isFunctionalityChecked} style={{ marginRight: 0 }} value={item.id} type="checkbox" onChange={this.onFunctionalityChange} />
                                                                            <span />{item.value}

                                                                        </label>
                                                                    ))
                                                                }
                                                                {
                                                                    this.state.NoFunctionalityVisibleMsg&&
                                                                    <label>No Functionality Available</label>
                                                                }
                                                            </div>
                                                            {this.state.FunctionalityErrMsg&&<span className="text-danger">{this.state.FunctionalityErrMsg==='required'? 'Please select atleast 1 functionality':''}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" onSubmit={this.handleSubmit} className="btn btn-primary mr-2">
                                                    Submit
                                                </button>
                                                <button type="button" onClick={this.ClearData} className="btn btn-secondary">
                                                    Cancel
                                                </button>
                                            </div>
                                        </FormWithConstraints>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {this.state.isFilterVisible&&
                        this.state.ViewStatus==="ViewStatus:true"&&
                        <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>UserType </label>
                                    <Select isClearable={true} options={this.state.usertypes} value={this.state.SearchUserTypeID} onChange={this.onSearchUserTypeChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>User </label>
                                    <Select isClearable={true} options={this.state.searchusers} value={this.state.SearchUserID} onChange={this.onSearchUserChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button type="button" onClick={this.onSearchUserClick} className="btn btn-primary mt-12 mr-3">Search </button>
                                <button type="button" onClick={this.OnSearchCancelUserClick} className="btn btn-danger mt-12">Cancel </button>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-body">
                                    <MaterialTable columns={this.state.cols} data={this.state.data}
                                        actions={[{
                                            hidden: this.state.UpdateStatus=="UpdateStatus:true"? false:true,
                                            icon: 'edit', tooltip: 'Edit', onClick: (e, r) => this.EditUserPermission(r.UserPermissionMasterID)
                                        },
                                        {
                                            hidden: this.state.DeleteStatus=="DeleteStatus:true"? false:true,
                                            icon: 'delete', tooltip: 'Delete', onClick: (e, r) => this.CheckAlert(r.UserPermissionMasterID,r.UserID,r.UserType,r.FullName)
                                        }
                                        ]}
                                        options={{
                                            headerStyle: { color: 'black' }, toolbar: true, search: false,draggable:false,
                                            paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                        }}
                                        detailPanel={[
                                            {
                                                render: ({ rowData }) => {
                                                    if (rowData.UserPermissionDetail!=null) {
                                                        return (
                                                            <div style={{ width: '100%', padding: '5px', paddingLeft: '35px', display: 'block' }}>
                                                                <table className="table" cellSpacing="0" rules="all" border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>

                                                                    <tbody>
                                                                        <tr>
                                                                            <th className="permissionheader" style={{ borderTop: '1px solid' }}>Functionality</th>
                                                                            <th className="permissionheader" style={{ borderTop: '1px solid' }}>Add</th>
                                                                            <th className="permissionheader" style={{ borderTop: '1px solid' }}>Update</th>
                                                                            <th className="permissionheader" style={{ borderTop: '1px solid' }}>Delete</th>
                                                                            <th className="permissionheader" style={{ borderTop: '1px solid' }}>View</th>
                                                                            <th className="permissionheader" style={{ borderTop: '1px solid' }}>Excel</th>
                                                                        </tr>
                                                                        {rowData.UserPermissionDetail.map((value, inx1) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>
                                                                                        {/* <span className="permissiondata">{value.FunctionalityPage}</span> */}
                                                                                        <span className="permissiondata">{value.FunctionalityPageForFront}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <label className="checkbox usercheckbox">

                                                                                            <input checked={value.AddStatus} style={{ marginRight: 0 }} value="Add" type="checkbox" />
                                                                                            <span />

                                                                                        </label>
                                                                                    </td>
                                                                                    <td>
                                                                                        <label className="checkbox usercheckbox">

                                                                                            <input checked={value.UpdateStatus} style={{ marginRight: 0 }} value="Update" type="checkbox" />
                                                                                            <span />

                                                                                        </label>
                                                                                    </td>
                                                                                    <td>
                                                                                        <label className="checkbox usercheckbox">

                                                                                            <input checked={value.DeleteStatus} style={{ marginRight: 0 }} value="Delete" type="checkbox" />
                                                                                            <span />

                                                                                        </label>
                                                                                    </td>
                                                                                    <td>
                                                                                        <label className="checkbox usercheckbox">

                                                                                            <input checked={value.ViewStatus} style={{ marginRight: 0 }} value="View" type="checkbox" />
                                                                                            <span />

                                                                                        </label>
                                                                                    </td>
                                                                                    <td>
                                                                                        <label className="checkbox usercheckbox">

                                                                                            <input checked={value.ExcelStatus} style={{ marginRight: 0 }} value="Excel" type="checkbox" />
                                                                                            <span />

                                                                                        </label>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        );
                                                    }
                                                }
                                            }
                                        ]}
                                        components={{

                                            Toolbar: props => (
                                                <div className="row" style={{ marginBottom: '2%' }}>
                                                    <div className="col-md-8 mobpb-5" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h3 className="tabletitle">View User Permission</h3>

                                                    </div>
                                                    <div className="col-md-4 mobta-left mobpl-0 mobpr-0" style={{ textAlign: 'right' }}>
                                                        {
                                                            this.state.AddStatus==="AddStatus:true"&&
                                                            <a onClick={this.onAddUserPermissionClick} className="btn btn-outline-primary font-weight-bolder mr-5 mobmr-1">
                                                                <span className="svg-icon svg-icon-md">
                                                                    <i id="btnAdd" className={this.state.iconAdd} />
                                                                </span>Add User Permission</a>
                                                        }
                                                        {
                                                            this.state.ViewStatus==="ViewStatus:true"&&
                                                            <>
                                                                <a className="btn btn-outline-dark font-weight-bolder mr-5 mobmr-1" onClick={this.OnFilterClick}>
                                                                    <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                                </a>
                                                                {this.state.ExcelStatus==="ExcelStatus:true"&&
                                                                    <a className="btn btn-outline-success font-weight-bolder" onClick={this.state.data.length>0? this.ExcelClick:''}>
                                                                        <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                                    </a>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withCookies(UserPermission);