import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable, { MTableToolbar } from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';

import XLSX from "xlsx-color";
import moment from 'moment';
const headerTblStyle={ color: 'black' };
const exportToExcel=(columns, data) => {
    debugger;
    const header=["Sr.No", "MuncipalityName", "WardName"];
    const headertemp=["Sr.No", "Muncipality Name", "Ward Name"];
    debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else if (col=="MuncipalityName") {
                obj["Muncipality Name"]=row[col];
            }
            else if (col=="WardName") {
                obj["Ward Name"]=row[col];
            }
            else {
                obj[col]=row[col];
            }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols']=[
        { width: 6 },
        { width: 30 },
        { width: 30 },
    ];
    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;


    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "WardList");
    XLSX.writeFile(wb, "WardList_"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}
class WardMaster extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [], municipalities: [],
            MuncipalityID: { 'label': '---Select Muncipality---', 'value': '---Select Muncipality---' },
            SearchMuncipalityID: { 'label': '---Select Muncipality---', 'value': '---Select Muncipality---' },
            WardID: "", WardName: "",
            MuncipalityErrMsg: "",
            iconAdd: "fa fa-plus", iconFilter: "fa fa-plus", isFilterVisible: false,
            // rows: [{ Email: '', DistrictEmailDetailID: '' }],
            cols: [
                { title: 'Sr.No', width: '%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'center' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'Status', width: '12%', field: 'IsActive', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<BootstrapSwitchButton onlabel='Yes' offlabel='No' onChange={this.onStatusChange(rowData.WardID)} checked={rowData.IsActive} size="md" onstyle="success" offstyle="danger" />) } },
                { title: 'Muncipality Name', width: '50%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.MuncipalityName}</p>) } },
                // { title: 'Company Name', width: '15%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CompanyName}</p>) } },
                // { title: 'Client Name', width: '15%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.ClientName}</p>) } },
                // { title: 'Code Name', width: '15%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CodeName}</p>) } },
                { title: 'Ward Name', width: '30%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.WardName}</p>) } }
            ]
        }

    }

    componentDidMount() {
        this.GetWardMaster();
        this.GetMuncipality();
    }

    allowOnlyCharacters=(event) => {
        const keyCode=event.keyCode||event.which;
        const keyValue=String.fromCharCode(keyCode);
        if (!new RegExp("^[a-zA-Z ]+$").test(keyValue)) event.preventDefault();
        return;
    };

    GetWardMaster() {
        debugger;
        var CompanyID="";
        var ClientID="";
        var SearchMuncipalityID=""

        if (this.state.SearchMuncipalityID.value!="---Select Muncipality---") {
            SearchMuncipalityID=this.state.SearchMuncipalityID.value;
        }
        else {
            SearchMuncipalityID="";
        }


        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "WardID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "MuncipalityID": SearchMuncipalityID,
                "IsActive": "",
                "IsDelete": "false"
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Ward/getWard",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                if (response.data.status) {
                    this.setState({ data: response.data.data })
                }
                else {
                    this.setState({ data: [] })
                }

            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }

    GetMuncipality() {
        var CompanyID="";
        var ClientID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        var data=JSON.stringify({
            "MuncipalityID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "IsActive": "true",
            "IsDelete": "false"
        });
        var config={
            method: 'POST',
            url: services.API_URL+"Muncipality/getMuncipality",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            this.setState({ municipalities: response.data.data.map(item => ({ value: item.MuncipalityID, label: item.MuncipalityName })) });
        }, error => { })
    }

    ValidateMuncipality=(e) => {
        this.setState({ MuncipalityErrMsg: '' })
    }
    ValidateCancleMuncipality=(e) => {
        this.setState({ MuncipalityErrMsg: 'required' })
    }
    // onMuncipalityChange = (e) => {
    //     debugger;
    //     this.setState({ MuncipalityID: e }, () => { this.ValidateMuncipality(); });

    // }
    onMuncipalityChange=(e) => {
        debugger;
        // this.setState({ MuncipalityID: e }, () => { this.ValidateMuncipality(); });
        if (e!=null) {
            this.setState({ MuncipalityID: e }, () => { this.ValidateMuncipality(); });
        }
        else {
            this.setState({ MuncipalityID: { 'label': '---Select Muncipality---', 'value': '---Select Muncipality---' } }, () => { this.ValidateCancleMuncipality() });
        }
    }
    onStatusChange=(WardID) => (Status) => {
        // e.preventDefault();
        debugger;
        var IsStatus=Status.toString();


        var data=JSON.stringify({
            "WardID": WardID,
            "IsActive": IsStatus
        });
        var config={
            method: 'POST',
            url: services.API_URL+"Ward/setWardIsActiveStatus",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                //this.setState({ data: response.data.data })
                Swal.fire({
                    title: 'Successfully Updated', icon: "success", timer: 1500
                });
            }
            else {
                Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                // this.setState({ data: [] })
            }
            this.GetWardMaster();

        }, error => { })
    }
    EditDistrict(id) {
        var CompanyID="";
        var ClientID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "WardID": id,
                "CompanyID": CompanyID,
                "ClientID": ClientID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Ward/getWard",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {

                debugger;
                this.setState({
                    WardID: response.data.data[0].WardID,
                    MuncipalityID: { 'label': response.data.data[0].MuncipalityName, 'value': response.data.data[0].MuncipalityID },
                    WardName: response.data.data[0].WardName,
                });
            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }

    handleSubmit=(e) => {
        debugger;
        e.preventDefault();

        this.form.validateFields();

        if (this.state.MuncipalityID.value=="---Select Muncipality---") {
            this.setState({ MuncipalityErrMsg: 'required' });
        }

        if (this.form.isValid()) {
            var CompanyID="";
            var ClientID="";
            var UserID="";
            var UserType="";
            if (this.props.allCookies.MainCompanyID!==undefined) {
                CompanyID=this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID!==undefined) {
                ClientID=this.props.allCookies.MainClientID
            }
            if (this.props.allCookies.UserID!==undefined) {
                UserID=this.props.allCookies.UserID
            }
            if (this.props.allCookies.PanelUserType!==undefined) {
                UserType=this.props.allCookies.PanelUserType
            }

            if (CompanyID!=""&&ClientID!="") {
                var data="";
                if (this.state.WardID!="") {
                    data=JSON.stringify({
                        "WardID": this.state.WardID,
                        "CompanyID": CompanyID,
                        "ClientID": ClientID,
                        "MuncipalityID": this.state.MuncipalityID.value,
                        "WardName": this.state.WardName,
                        "EntryByUserType": UserType,
                        "EntryByUserID": UserID
                    });
                }
                else {
                    data=JSON.stringify({
                        "WardID": "",
                        "CompanyID": CompanyID,
                        "ClientID": ClientID,
                        "MuncipalityID": this.state.MuncipalityID.value,
                        "WardName": this.state.WardName,
                        "EntryByUserType": "",
                        "EntryByUserID": "",
                    });
                }

                var config={
                    method: 'POST',
                    url: services.API_URL+"Ward/setWard",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    //debugger;
                    if (response.data.status) {
                        debugger;
                        if (this.state.WardID!="") {
                            // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                            Swal.fire({
                                title: 'Successfully Updated', icon: "success", timer: 1500
                            });
                        }
                        else {
                            Swal.fire({
                                title: 'Successfully Inserted', icon: "success", timer: 1500
                            });
                        }
                        this.ClearData();
                        this.GetWardMaster();
                    }
                    else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                }, error => { })
            }
            else {
                if (CompanyID==""&&ClientID=="") {
                    Swal.fire({
                        title: 'Please Select Company & Client', icon: "error", timer: 1500
                    });
                }
                else if (CompanyID!=""&&ClientID=="") {
                    Swal.fire({
                        title: 'Please Select Client', icon: "error", timer: 1500
                    });
                }
                else if (CompanyID==""&&ClientID!="") {
                    Swal.fire({
                        title: 'Please Select Company', icon: "error", timer: 1500
                    });
                }
                else {
                    Swal.fire({
                        title: 'Something went wrong', icon: "error", timer: 1500
                    });
                }
            }

        }


    }

    ClearData=(e) => {
        debugger;
        this.setState({
            municipalities: [],
            MuncipalityID: { 'label': '---Select Muncipality---', 'value': '---Select Muncipality---' },
            WardID: "", MuncipalityErrMsg: "", WardName: ""
        });
        this.form.reset();
        this.GetMuncipality();
    }

    CheckAlert(id, wardname) {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                var CompanyID="";
                var ClientID="";
                var UserID="";
                var UserType="";
                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                var data=JSON.stringify({
                    "WardID": id,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "WardName": wardname,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+"Ward/removeWard",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        this.GetWardMaster();
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        });
                    } else {
                        Swal.fire({ icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }
    OnSearchWardMasterClick=(e) => {
        debugger;
        e.preventDefault();
        this.GetWardMaster();
    }

    OnSearchCancelWardMasterClick=() => {
        debugger;
        this.setState({
            SearchMuncipalityID: { 'label': '---Select Muncipality---', 'value': '---Select Muncipality---' },
        }, () => {
            this.GetWardMaster();
        });
        //window.location.reload(true);

    }
    OnFilterClick=(e) => {
        e.preventDefault();
        if (this.state.isFilterVisible===false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }
    }
    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.data)
    }
    render() {
        const { cookies }=this.props
        debugger;
        var Permission=cookies.cookies.WardMaster;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row">
                        <div className="col-md-5">
                            {
                                this.state.AddStatus==="AddStatus:true"&&
                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header">
                                        <h3 className="card-title">Add Ward</h3>
                                    </div>
                                    <FormWithConstraints
                                        ref={form => this.form=form}
                                        onSubmit={this.handleSubmit}
                                        noValidate>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Muncipality <span className="text-danger">*</span></label>
                                                        <Select isClearable={true} options={this.state.municipalities} value={this.state.MuncipalityID} onChange={this.onMuncipalityChange} />
                                                        {/* {this.state.MuncipalityErrMsg && <span className="text-danger">{this.state.MuncipalityErrMsg === 'required' ? '*' : ''}</span>} */}
                                                        {this.state.MuncipalityErrMsg&&<span className="text-danger">{this.state.MuncipalityErrMsg==='required'? 'Please Select Municipality':''}</span>}

                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Ward Name <span className="text-danger">*</span></label>
                                                        <input type="text" name="WardName" required onKeyPress={this.allowOnlyCharacters} value={this.state.WardName} onChange={(e) => this.setState({ WardName: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Ward Name" />
                                                        <FieldFeedbacks for="WardName">
                                                            <FieldFeedback when="valueMissing"></FieldFeedback>
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="card-footer">
                                            <button type="submit" onSubmit={this.handleSubmit} className="btn btn-primary mr-2">
                                                Submit
                                            </button>
                                            <button type="button" onClick={this.ClearData} className="btn btn-secondary">
                                                Cancel
                                            </button>
                                        </div>
                                    </FormWithConstraints>
                                </div>
                            }
                        </div>
                        {/* {this.state.isFilterVisible &&
                            <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                                <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group formgroupcss">
                                        <label>Muncipality </label>
                                        <Select options={this.state.municipalities} value={this.state.SearchMuncipalityID} onChange={(e) => this.setState({ SearchMuncipalityID: e })} />
                                    </div>
                                </div>
                                </div>
                            </div>
                        } */}
                        <div className="col-md-7">
                            {this.state.isFilterVisible&&
                                this.state.ViewStatus==="ViewStatus:true"&&
                                <div className="row" style={{ marginBottom: '1%' }} id="divFilter">

                                    <div className="col-md-6">
                                        <div className="form-group formgroupcss">
                                            <label>Muncipality </label>
                                            <Select options={this.state.municipalities} value={this.state.SearchMuncipalityID} onChange={(e) => this.setState({ SearchMuncipalityID: e })} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="button" onClick={this.OnSearchWardMasterClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                        <button type="button" onClick={this.OnSearchCancelWardMasterClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                                    </div>

                                </div>
                            }
                            {
                                this.state.ViewStatus==="ViewStatus:true"&&
                                <div className="card card-custom">
                                    <div className="card-body">
                                        <MaterialTable title="View Ward" columns={this.state.cols} data={this.state.data}

                                            actions={[{
                                                hidden: this.state.UpdateStatus=="UpdateStatus:true"? false:true,
                                                icon: 'edit', tooltip: 'Edit', onClick: (e, r) => this.EditDistrict(r.WardID)
                                            },
                                            {
                                                hidden: this.state.DeleteStatus=="DeleteStatus:true"? false:true,
                                                icon: 'delete', tooltip: 'Delete', onClick: (e, r) => this.CheckAlert(r.WardID,r.WardName)
                                            }
                                            ]}
                                            options={{
                                                headerStyle: { color: 'black' }, toolbar: true, search: false,draggable:false,
                                                paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                            }}
                                            components={{
                                                Toolbar: props => (
                                                    <div className="row" style={{ marginBottom: '2%' }}>
                                                        <div className="col-md-8" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h3 className="tabletitle">View Ward</h3>

                                                        </div>
                                                        <div className="col-md-4" style={{ textAlign: 'right' }}>

                                                            <a className="btn btn-outline-dark font-weight-bolder" onClick={this.OnFilterClick}>
                                                                <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                            </a>
                                                            {
                                                                this.state.ExcelStatus==="ExcelStatus:true"&&
                                                                <a className="btn btn-outline-success font-weight-bolder ml-4" onClick={this.state.data.length>0? this.ExcelClick:''}>
                                                                    <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                                </a>
                                                            }
                                                        </div>
                                                    </div>


                                                )
                                            }}

                                        />
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default withCookies(WardMaster);