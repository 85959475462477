import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import MultiSelect from "@khanacademy/react-multi-select";
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';


import XLSX from "xlsx-color";

const headerTblStyle={ color: 'black' };
const exportToExcel=(columns, data) => {
    debugger;
    const header=["Sr.No", "BookingDate", "BookingNo","VehicleNo", "ZoneName", "DistrictName", "MuncipalityName", "WardName", "Locality", "TotalNoOfDranageLine", "VehicleLeaveParkingTime", "VehicleReachedParkingTime", "VehicleLeaveParkingOdometer", "VehicleReachedParkingOdometer", "JettingStartMeterReading", "JettingFinishMeterReading", "SuctionPumpStartMeterReading", "SuctionPumpFinishMeterReading", "TotalMeter","TotalDifferenceTime", "Remarks"];
    const headertemp=["Sr.No", "Task Date", "Task No","Vehicle No", "Zone Name", "DistrictName", "Muncipality Name", "Ward Name", "Locality", "Total No Of Line Cleaned", "Vehicle Start Time", "Vehicle End Time", "Odometer Start", "Odometer End", "Jetting Start", "Jetting End", "Suction Start", "Suction End", "Total Length","Total Time", "Remarks"];
    debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else if (col=="BookingDate") {
                obj["Task Date"]=row[col];
            }
            else if (col=="BookingNo") {
                obj["Task No"]=row[col];
            }
            else if (col=="VehicleNo") {
                obj["Vehicle No"]=row[col];
            }
            else if (col=="ZoneName") {
                obj["Zone Name"]=row[col];
            }
            else if (col=="DistrictName") {
                obj["District Name"]=row[col];
            }
            else if (col=="MuncipalityName") {
                obj["Muncipality Name"]=row[col];
            }
            else if (col=="WardName") {
                obj["Ward Name"]=row[col];
            }
            else if (col=="Locality") {
                obj["Locality"]=row[col];
            }
            else if (col=="TotalNoOfDranageLine") {
                obj["Total No Of Line Cleaned"]=row[col];
            }
            else if (col=="VehicleLeaveParkingTime") {
                obj["Vehicle Start Time"]=row[col]+"|"+row["VehicleReachedLocationTime"];
            }
            else if (col=="VehicleReachedParkingTime") {
                // obj["Vehicle End Time"]=row[col] + "|" + row["VehicleReachedLocationTime"];
                obj["Vehicle End Time"]=row["VehicleReturnLocationTime"]+"|"+row[col];
            }
            else if (col=="VehicleLeaveParkingOdometer") {
                obj["Odometer Start"]=row[col]+"|"+row["VehicleReachedLocationOdometer"];
            }
            else if (col=="VehicleReachedParkingOdometer") {
                // obj["Odometer End"]=row[col];
                obj["Odometer End"]=row["VehicleReturnLocationOdometer"]+"|"+row[col];
            }
            else if (col=="JettingStartMeterReading") {
                obj["Jetting Start"]=row[col];
            }
            else if (col=="JettingFinishMeterReading") {
                obj["Jetting End"]=row[col];
            }
            else if (col=="SuctionPumpStartMeterReading") {
                obj["Suction Start"]=row[col];
            }
            else if (col=="SuctionPumpFinishMeterReading") {
                obj["Suction End"]=row[col];
            }
            else if (col=="TotalMeter") {
                obj["Total Length"]=row[col];
            }
            else if (col=="TotalDifferenceTime") {
                obj["Total Time"]=row[col];
            }
            else {
                obj[col]=row[col];
            }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols']=[
        { width: 6 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 60 },
        { width: 60 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },


    ];
    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;
    ws1['D1'].s=cell;
    ws1['E1'].s=cell;
    ws1['F1'].s=cell;
    ws1['G1'].s=cell;
    ws1['H1'].s=cell;
    ws1['I1'].s=cell;
    ws1['J1'].s=cell;
    ws1['K1'].s=cell;
    ws1['L1'].s=cell;
    ws1['M1'].s=cell;
    ws1['N1'].s=cell;
    ws1['O1'].s=cell;
    ws1['P1'].s=cell;
    ws1['Q1'].s=cell;
    ws1['R1'].s=cell;
    ws1['S1'].s=cell;
    ws1['T1'].s=cell;
    ws1['U1'].s=cell;



    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;
        ws1['D'+ins].s=RowCont;
        ws1['E'+ins].s=RowCont;
        ws1['F'+ins].s=RowCont;
        ws1['G'+ins].s=RowCont;
        ws1['H'+ins].s=RowCont;
        ws1['I'+ins].s=RowCont;
        ws1['J'+ins].s=RowCont;
        ws1['K'+ins].s=RowCont;
        ws1['L'+ins].s=RowCont;
        ws1['M'+ins].s=RowCont;
        ws1['N'+ins].s=RowCont;
        ws1['O'+ins].s=RowCont;
        ws1['P'+ins].s=RowCont;
        ws1['Q'+ins].s=RowCont;
        ws1['R'+ins].s=RowCont;
        ws1['S'+ins].s=RowCont;
        ws1['T'+ins].s=RowCont;
        ws1['U'+ins].s=RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "TabularReport");
    XLSX.writeFile(wb, "TabularReport_"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}

class TabularReport extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [],
            BookingTypeID: "", BookingType: "", CleaningCapacity: "",
            BookingDate: "", selected: [],
            zones: [], ZoneID: [],vehicles: [],
            districts: [], DistrictID: [],
            wards: [], WardID: [],
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            StartDate: new Date(), EndDate: new Date(),
            // SearchZoneID: { 'label': '--Select Zone--', 'value': '--Select Zone--' },
            SearchZoneID: [],
            ZoneErrMsg: "",
            iconAdd: "fa fa-plus", iconFilter: "fa fa-plus", isFilterVisible: false,
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'center' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'Booking Date', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingDate}</p>) } },
                { title: 'Task No', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingNo}</p>) } },
                { title: 'Vehicle No', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.VehicleNo}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Zone Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>District Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Municipality Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Ward Name</span></div>), width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.ZoneName}<hr />{rowData.DistrictName}<hr />{rowData.MuncipalityName}<hr />{rowData.WardName}</p>) } },
                // { title: 'Loction of Work', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Locality}</p>) } },
                {
                    title: (<div style={{ width: '100%' }}><span>Loction of Work</span>
                        <hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Total No Of Line Cleaned</span>
                    </div>), width: '15%', removable: false, headerStyle: headerTblStyle,
                    render: rowData => {
                        return (
                            <p style={{ marginBottom: '1px' }}>{rowData.Locality}<hr />{rowData.TotalNoOfDranageLine} </p>
                        )
                    }
                },
                {
                    title: (<div style={{ width: '100%' }}><span>Vehicle Start Time</span>
                        <hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Vehicle End Time</span>
                    </div>), width: '15%', removable: false, headerStyle: headerTblStyle,
                    render: rowData => {
                        return (
                            <p style={{ marginBottom: '1px' }}>{rowData.VehicleLeaveParkingTime} |<br /> {rowData.VehicleReachedLocationTime}<hr />{rowData.VehicleReturnLocationTime} |<br /> {rowData.VehicleReachedParkingTime} </p>
                        )
                    }
                },
                {
                    title: (<div style={{ width: '100%' }}><span>Odometer Start</span>
                        <hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Odometer End</span>
                    </div>), width: '15%', removable: false, headerStyle: headerTblStyle,
                    render: rowData => {
                        return (
                            <p style={{ marginBottom: '1px' }}>{rowData.VehicleLeaveParkingOdometer} | {rowData.VehicleReachedLocationOdometer}<hr />{rowData.VehicleReturnLocationOdometer} |  {rowData.VehicleReachedParkingOdometer} </p>
                        )
                    }
                },
                {
                    title: (<div style={{ width: '100%' }}><span>Jetting Start</span>
                        <hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Jetting End</span>
                    </div>), width: '15%', removable: false, headerStyle: headerTblStyle,
                    render: rowData => {
                        return (
                            <p style={{ marginBottom: '1px' }}>{rowData.JettingStartMeterReading}<hr /> {rowData.JettingFinishMeterReading} </p>
                        )
                    }
                },
                {
                    title: (<div style={{ width: '100%' }}><span>Suction Start</span>
                        <hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Suction End</span>
                    </div>), width: '15%', removable: false, headerStyle: headerTblStyle,
                    render: rowData => {
                        return (
                            <p style={{ marginBottom: '1px' }}>{rowData.SuctionPumpStartMeterReading}<hr /> {rowData.SuctionPumpFinishMeterReading} </p>
                        )
                    }
                },
                {
                    title: (<div style={{ width: '100%' }}><span>Total Length</span>
                        <hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Total Time</span>
                    </div>), width: '15%', removable: false, headerStyle: headerTblStyle,
                    render: rowData => {
                        return (
                            <p style={{ marginBottom: '1px' }}>{rowData.TotalMeter}<hr /> {rowData.TotalDifferenceTime} </p>
                        )
                    }
                },
                // { title: 'Total Length', width: '30%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalMeter}</p>) } },
                { title: 'Remarks English', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Remarks}</p>) } },

            ]
        }
    }

    componentDidMount() {
        this.GetTabularReportData();
        this.GetZone();
        this.GetMunicipality();
        this.GetWardMaster();
        this.GetDistrict();
        this.GetVehicle();
    }

    GetTabularReportData() {
        debugger;
        var CompanyID="";
        var ClientID="";
        var StartDate="";
        var EndDate="";
        var ZoneID="";
        var DistrictID="";
        var MunicipalityID="";
        var WardID="";
        var VehicleID="";


        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        // if (this.state.ZoneID != null) {
        //     var tempzoneid = this.state.ZoneID.map(JSON.parse);
        //     ZoneID = tempzoneid.slice(0, -1)
        // }
        if (this.state.ZoneID!=null) {
            var tempzoneid=this.state.ZoneID.map(

                item => (
                    ZoneID+=item+","
                )
            );
            ZoneID=ZoneID.slice(0, -1)
        }
        if (this.state.DistrictID!=null) {
            var tempDistrictID=this.state.DistrictID.map(

                item => (
                    DistrictID+=item+","
                )
            );
            DistrictID=DistrictID.slice(0, -1)
        }
        if (this.state.MunicipalityID!=null) {
            var tempMunicipalityID=this.state.MunicipalityID.map(

                item => (
                    MunicipalityID+=item+","
                )
            );
            MunicipalityID=MunicipalityID.slice(0, -1)
        }
        if (this.state.WardID!=null) {
            var tempWardID=this.state.WardID.map(

                item => (
                    WardID+=item+","
                )
            );
            WardID=WardID.slice(0, -1)
        }
        if (this.state.VehicleID.value!="--Select Vehicle--") {
            VehicleID=this.state.VehicleID.value;
        }

        var dateString=this.state.StartDate;
        var dateMomentObject=moment(dateString, "DD-MM-YYYY"); // 1st argument - string, 2nd argument - format
        var date=dateMomentObject.toDate();

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                // "BookingTypeID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "StartDate": date,
                "EndDate": this.state.EndDate,
                "ZoneID": ZoneID,
                "DistrictID": DistrictID,
                "MuncipalityID": MunicipalityID,
                "WardID": WardID,
                VehicleID: VehicleID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Report/getTabularReport",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                if (response.data.status) {
                    this.setState({ data: response.data.data })
                }
                else {
                    this.setState({ data: [] })
                }

            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }
    }
    GetZone() {
        var CompanyID="";
        var ClientID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        var data=JSON.stringify({
            "ZoneID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "IsActive": "true",
            "IsDelete": "false"
        });
        var config={
            method: 'POST',
            url: services.API_URL+"Zone/getZone",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                this.setState({ zones: response.data.data.map(item => ({ value: item.ZoneID, label: item.ZoneName })) });
                this.setState({ ZoneID: [] })
            } else {
                this.setState({ zones: [] })
            }
        }, error => { })
    }
    GetDistrict() {
        debugger;
        var CompanyID="";
        var ClientID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "DistrictID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "IsActive": "true",
                "IsDelete": "false"
            });
            var config={
                method: 'POST',
                url: services.API_URL+"District/getDistrict",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                if (response.data.status) {
                    this.setState({ districts: response.data.data.map(item => ({ value: item.DistrictID, label: item.DistrictName })) });
                    this.setState({ DistrictID: [] })
                }
                else {
                    this.setState({ districts: [] })
                }

            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }
    GetMunicipality() {
        var CompanyID="";
        var ClientID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        var data="";
        data=JSON.stringify({
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "ZoneID": "",
            "DistrictID": "",
            "IsActive": "true",
            "IsDelete": "false"
        });

        var config={
            method: 'POST',
            url: services.API_URL+"Muncipality/getMuncipality",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;

            if (response.data.status) {
                this.setState({ municipalities: response.data.data.map(item => ({ value: item.MuncipalityID, label: item.MuncipalityName })) });
                this.setState({ MunicipalityID: [] })

            }
            else {
                this.setState({ municipalities: [] })
            }
        }, error => { })
    }
    GetWardMaster() {
        debugger;
        var CompanyID="";
        var ClientID="";
        // var SearchMuncipalityID=""

        // if (this.state.SearchMuncipalityID.value!="--Select Muncipality--") {
        //     SearchMuncipalityID=this.state.SearchMuncipalityID.value;
        // }
        // else {
        //     SearchMuncipalityID="";
        // }

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "WardID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "IsActive": "true",
                "IsDelete": "false"
                // "MuncipalityID": SearchMuncipalityID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Ward/getWard",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                if (response.data.status) {
                    this.setState({ wards: response.data.data.map(item => ({ value: item.WardID, label: item.WardName })) });
                    this.setState({ WardID: [] })
                }
                else {
                    this.setState({ wards: [] })
                }

            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }
    GetVehicle() {
        var CompanyID="";
        var UserID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }

        var data="";
        data=JSON.stringify({
            "VehicleID": "",
            "CompanyID": CompanyID,
            "IsActive": "true",
            "IsDelete": "false"
        });

        var config={
            method: 'POST',
            url: services.API_URL+"Vehicle/getVehicle",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ vehicles: response.data.data.map(item => ({ value: item.VehicleID, label: item.VehicleNo })) });


            }
            else {
                this.setState({ vehicles: [] })
            }
        }, error => { })
    }
    
    onVehicleChange=(e) => {
        if (e!==null) {
            this.setState({ VehicleID: e })
        }
        else {
            this.setState({ VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' } });
        }
    }

    // onZoneChange(SearchZone) {
    //     debugger;
    //     this.setState({ selected: SearchZone })
    //     if (SearchZone.length > 0) {
    //         this.setState({ SearchZoneID: SearchZone })
    //     } else {
    //         this.setState({ SearchZoneID: [] })
    //     }
    // }
    onZoneChange=(value) => {
        this.setState({ ZoneID: value });
    }

    onMunicipalityChange=(value) => {
        //debugger;
        this.setState({ MunicipalityID: value });
    }

    onDistrictChange=(value) => {
        //debugger;
        this.setState({ DistrictID: value });
    }

    onWardChange=(value) => {
        //debugger;
        this.setState({ WardID: value });
    }

    onSearchTabularReportClick=(e) => {
        e.preventDefault();
        this.GetTabularReportData();
    }
    OnSearchCancelTabularReportClick=() => {
        //debugger;
        this.setState({
            MunicipalityID: [], ZoneID: [], DistrictID: [], WardID: [], StartDate: new Date(), EndDate: new Date(),
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
        },
            () => {
                this.GetTabularReportData();
            });

    }
    onStartDateChange=(e) => {
        this.setState({ StartDate: e });
        this.setState({ EndDate: e });
    }

    OnFilterClick=(e) => {
        e.preventDefault();
        if (this.state.isFilterVisible===false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }
    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.data)
    }
    render() {
        // const { selected } = this.state;
        const { cookies }=this.props
        debugger;
        var Permission=cookies.cookies.Report;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <>
                {
                    this.state.ViewStatus==="ViewStatus:true"&&
                    <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                        {this.state.isFilterVisible&&
                            <div className="row" style={{ marginBottom: '1%' }} >

                                <div className="col-md-3">
                                    <div className="form-group formgroupcss">
                                        <label>Start Date </label>
                                        <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.StartDate} onChange={this.onStartDateChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group formgroupcss">
                                        <label>End Date </label>
                                        <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.EndDate} minDate={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.EndDate} onChange={(e) => {
                                            this.setState({ EndDate: e });
                                        }} />
                                    </div>
                                </div>
                                {/* <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Zone Name </label>
                                    
                                    <MultiSelect
                                        options={this.state.zones}
                                        selected={selected}
                                        // showCheckbox={true}
                                        checked={false}
                                        onSelectedChanged={this.onZoneChange}
                                        isMulti
                                        overrideStrings={{
                                            selectSomeItems: "--Select Zone Type--", allItemsAreSelected: "All Zone are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }}
                                    />
                                </div>
                            </div> */}
                                <div className="col-md-3">
                                    <div className="form-group formgroupcss">
                                        <label>Zone </label>
                                        <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                            options={this.state.zones} selected={this.state.ZoneID} checked={false}
                                            onSelectedChanged={this.onZoneChange}
                                            overrideStrings={{
                                                selectSomeItems: "---Select Zones---", allItemsAreSelected: "All Zones are Selected",
                                                selectAll: "Select All", search: "Search"
                                            }} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group formgroupcss">
                                        <label>District </label>
                                        <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                            options={this.state.districts} selected={this.state.DistrictID} checked={false}
                                            onSelectedChanged={this.onDistrictChange}
                                            overrideStrings={{
                                                selectSomeItems: "---Select Districts---", allItemsAreSelected: "All District are Selected",
                                                selectAll: "Select All", search: "Search"
                                            }} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group formgroupcss">
                                        <label>Municipality </label>
                                        <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                            options={this.state.municipalities} selected={this.state.MunicipalityID} checked={false}
                                            onSelectedChanged={this.onMunicipalityChange}
                                            overrideStrings={{
                                                selectSomeItems: "---Select Municipalities---", allItemsAreSelected: "All Municipality are Selected",
                                                selectAll: "Select All", search: "Search"
                                            }} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group formgroupcss">
                                        <label>Ward </label>
                                        <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                            options={this.state.wards} selected={this.state.WardID} checked={false}
                                            onSelectedChanged={this.onWardChange}
                                            overrideStrings={{
                                                selectSomeItems: "---Select Wards---", allItemsAreSelected: "All Wards are Selected",
                                                selectAll: "Select All", search: "Search"
                                            }} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group formgroupcss">
                                        <label>Vehicle </label>
                                        <Select isClearable={true} options={this.state.vehicles} value={this.state.VehicleID} onChange={this.onVehicleChange} />
                                        {this.state.VehicleErrMsg&&<span className="text-danger">{this.state.VehicleErrMsg==='required'? 'Please select Vehicle':''}</span>}
                                    </div>
                                </div>

                                {/* <MultiSelect
                                options={this.state.ZoneData}
                                selected={selected}
                                checked={false}
                                onSelectedChanged={this.ZoneDType}
                                isMulti
                                overrideStrings={{
                                    selectSomeItems: "--Select Zone Type--", allItemsAreSelected: "All Zone are Selected",
                                    selectAll: "Select All", search: "Search"
                                }}
                            /> */}


                                <div className="col-md-3">
                                    <button type="button" onClick={this.onSearchTabularReportClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                    <button type="button" onClick={this.OnSearchCancelTabularReportClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-custom">
                                    <div className="card-body">
                                        <MaterialTable columns={this.state.cols} data={this.state.data}
                                            options={{
                                                headerStyle: { color: 'black' }, toolbar: true, search: false, draggable: false,
                                                paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                            }}
                                            components={{
                                                Toolbar: props => (
                                                    <div className="row" style={{ marginBottom: '2%' }}>
                                                        <div className="col-md-8 mobpb-5" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <h3 className="tabletitle">Tabular Report</h3>
                                                        </div>
                                                        <div className="col-md-4" style={{ textAlign: 'right' }}>
                                                            <a className="btn btn-outline-dark font-weight-bolder" onClick={this.OnFilterClick}>
                                                                <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                            </a>
                                                            {this.state.ExcelStatus==="ExcelStatus:true"&&
                                                                <a disabled="disabled" className="btn btn-outline-success font-weight-bolder ml-4" onClick={this.state.data.length>0? this.ExcelClick:''}>
                                                                    <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                                </a>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }

}
export default withCookies(TabularReport)