import React, { Component } from "react";
import { NavLink, Redirect } from 'react-router-dom';
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import MultiSelect from "@khanacademy/react-multi-select";
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';


import XLSX from "xlsx-color";

const headerTblStyle = { color: 'black' };
class gudmsummary extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        // this.map = React.createRef(null);
        const { cookies } = props;
        this.state = {
            isLoading: true,
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "", counter: null,
            data: [], zones: [], districts: [], municipalities: [], wards: [],
            ZoneID: [], DistrictID: [], MunicipalityID: [], WardID: [], BookingID: "", BookingTaskDetailID: "", VehicleNo: "",
            toggler: false, timeslotdata: [], vehicles: [],
            imageData: [
                'https://www.shutterstock.com/image-photo/mountains-under-mist-morning-amazing-260nw-1725825019.jpg',
            ],
            slide: 1,
            centerlat: 0, centerlng: 0, IsMapVisible: false, LiveLocation: "",
            BookingNo: "", images: [], Lastlong: "", animate: false, polyline: null, currentStep: 0, path: [
                {
                    lat: 0,
                    lng: 0
                }
            ],
            // status: [
            //     { 'label': 'New', 'value': 'New' },
            //     { 'label': 'Assigned', 'value': 'Assigned' },
            //     { 'label': 'Started', 'value': 'Started' },
            //     { 'label': 'Finished', 'value': 'Finished' },
            //     { 'label': 'Completed', 'value': 'Completed' },
            //     { 'label': 'Approved', 'value': 'Approved' },
            //     { 'label': 'Referred Back', 'value': 'Referred Back' },
            //     { 'label': 'Cancelled', 'value': 'Cancelled' }
            // ],

            status: [
                { 'label': 'Completed', 'value': 'Completed' },
                { 'label': 'Approved', 'value': 'Approved' }
            ],

            StatusID: [],
            // StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' },
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            StartDate: new Date(), EndDate: new Date(), photoIndex: 0,
            isOpen: false, currentLocation: [], DataBind: [], LiveLocation: "", DefectLocation: [],
            faicon: "fa fa-plus", isFilterVisible: false, iconFilter: "fa fa-plus", PDFStatus: false,
            isStartToFinishChecked: true, isFinishToStartChecked: false, isWithinDataChecked: false, isButtonVisible: true,
            showInfoWindow: false, activeMarker: null, MapTaskNo: "", MapTaskDate: "", MapMuniciplaity: "", MapLocation: "",
            MapChecklistDetail: "", MapLatitude: "", MapLongitude: "", MapImages: [], MapRemarks: "",
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.tableData.id + 1}</p>) } },
                // { title: 'Municipality', width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left' }}>{rowData.MuncipalityName}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Municipality</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'left' }}>{rowData.MuncipalityName}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Date Range</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.DateRange}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>No Of Shifts</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalNoOfShift}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Total Machine Holes Cleaned</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalNoOfCleanMachineHole}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Total hh:mm:ss</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalTime}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Lunch hh:mm:ss</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalLunchTime}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Total time - Lunch time</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalDifferenceTime}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>150 - 300</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalDrainageLine150to300mmDiaMeter}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>301 - 600</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalDrainageLine301to600mmDiaMeter}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>601 - 900</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalDrainageLine601to900mmDiaMeter}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>901 - 1200</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalDrainageLine901to1200mmDiaMeter}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Total</span></div>), width: '10%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.TotalDrainageLine}</p>) } },

                // { title: 'Remarks', width: '5%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.BookingApprovalRemarks}</p>) } },
            ]
        }
    }

    componentDidMount() {
        this.GetZone();
        this.GetDistrict();
        this.GetMunicipality();
        this.GetWard();
        this.GetVehicle();
        this.GetSummaryReport();

        // this.props.centerlat("21.186055")
        // this.props.centerlng("72.794200")
        // this.setState({ centerlat: 23.110185 });
        // this.setState({ centerlng: 72.599895 });
        // const polyline=new window.google.maps.Polyline({
        //     //path: this.state.path,
        //     geodesic: true,
        //     strokeColor: '#FF0000',
        //     strokeOpacity: 1.0,
        //     strokeWeight: 2,
        // });
        // polyline.setMap(this.map);
        // this.setState({ polyline });
        // this.handleToggleAnimate();

    }

    // Functions
    GetSummaryReport() {
        try {
            debugger;
            var CompanyID = "";
            var ClientID = "";
            var UserID = "";
            var ZoneID = "";
            var DistrictID = "";
            var MunicipalityID = "";
            var WardID = "";
            var StatusID = "";
            // var Status="";
            var UserType = "";
            var VehicleID = "";
            var StartDate = "";
            var EndDate = "";
            var ckZoneID = "";
            var ckMunicipalityID = "";

            if (this.props.allCookies.MainCompanyID !== undefined) {
                CompanyID = this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID !== undefined) {
                ClientID = this.props.allCookies.MainClientID
            }
            if (this.props.allCookies.PanelUserType !== undefined) {
                UserType = this.props.allCookies.PanelUserType
            }
            if (this.props.allCookies.ZoneIDs !== undefined) {
                ckZoneID = this.props.allCookies.ZoneIDs
            }
            if (this.props.allCookies.MunicipalityID !== undefined) {
                ckMunicipalityID = this.props.allCookies.MunicipalityID
            }

            if (this.state.ZoneID != null) {
                var tempZoneID = this.state.ZoneID.map(

                    item => (
                        ZoneID += item + ","
                    )
                );
                ZoneID = ZoneID.slice(0, -1)
            }
            if (this.state.DistrictID != null) {
                var tempDistrictID = this.state.DistrictID.map(

                    item => (
                        DistrictID += item + ","
                    )
                );
                DistrictID = DistrictID.slice(0, -1)
            }
            if (this.state.MunicipalityID != null) {
                var tempMunicipalityID = this.state.MunicipalityID.map(

                    item => (
                        MunicipalityID += item + ","
                    )
                );
                MunicipalityID = MunicipalityID.slice(0, -1)
            }
            if (this.state.WardID != null) {
                var tempWardID = this.state.WardID.map(

                    item => (
                        WardID += item + ","
                    )
                );
                WardID = WardID.slice(0, -1)
            }

            // if (this.state.StatusID.value!="--Select Status--") {
            //     Status=this.state.StatusID.value
            // }

            if (this.state.StatusID != null) {
                debugger;
                var tempStatusID = this.state.StatusID.map(

                    item => (
                        StatusID += item + ","
                    )
                );
                StatusID = StatusID.slice(0, -1)
            }

            if (MunicipalityID == "") {
                if (UserType == "Municipality") {
                    if (this.props.allCookies.MunicipalityID !== undefined) {
                        MunicipalityID = this.props.allCookies.MunicipalityID
                    }
                }
            }

            if (this.state.VehicleID.value != "--Select Vehicle--") {
                VehicleID = this.state.VehicleID.value;
            }
            var data = "";
            data = JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID,
                ZoneID: ZoneID ? ZoneID : ckZoneID ? ckZoneID : '',
                DistrictID: DistrictID,
                MuncipalityID: MunicipalityID ? MunicipalityID : ckMunicipalityID ? ckMunicipalityID : '',
                WardID: WardID,
                VehicleID: VehicleID,
                // BookingStatus: StatusID,
                BookingStatus: StatusID != "" ? StatusID : "Completed,Approved",
                StartDate: moment(this.state.StartDate, "DD-MM-YYYY"),
                EndDate: moment(this.state.EndDate, "DD-MM-YYYY"),
            });


            var config = {
                method: 'POST',
                url: services.API_URL + "Report/getGUDMSummaryReport",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                if (response.data.status) {
                    this.setState({ data: response.data.data })
                }
                else {
                    this.setState({ data: [] })
                }

            }, error => { })
        } catch (error) {

        }

    }

    GetZone() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var ZoneID = "";
        var tempPanelUserType = "";
        var tempUrl = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.ZoneIDs !== undefined) {
            ZoneID = this.props.allCookies.ZoneIDs
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            tempPanelUserType = this.props.allCookies.PanelUserType
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }

        var data = "";
        // data = JSON.stringify({
        //     ZoneID: "",
        //     CompanyID: CompanyID,
        //     ClientID: ClientID
        // });

        if (tempPanelUserType == "Admin") {
            data = JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "Zone/getZoneDropDownData";
        } else {
            data = JSON.stringify({
                UserID: UserID,
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "User/getUserZoneDetail";
        }

        var config = {
            method: 'POST',
            url: services.API_URL + tempUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ zones: response.data.data.map(item => ({ value: item.ZoneID, label: item.ZoneName })) });
                this.setState({ ZoneID: [] })

            }
            else {
                this.setState({ zones: [] })
            }
        }, error => { })
    }

    GetDistrict() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var ZoneID = "";
        var tempPanelUserType = "";
        var tempUrl = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.ZoneIDs !== undefined) {
            ZoneID = this.props.allCookies.ZoneIDs
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            tempPanelUserType = this.props.allCookies.PanelUserType
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }
        var data = "";
        // data = JSON.stringify({
        //     "DistrictID": "",
        //     "CompanyID": CompanyID,
        //     "ClientID": ClientID,
        //     "ZoneID": ""
        // });

        if (tempPanelUserType == "Admin") {
            data = JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "District/getDistrictDropDownData";
        } else {
            data = JSON.stringify({
                UserID: UserID,
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "User/getUserDistrictDetail";
        }

        var config = {
            method: 'POST',
            url: services.API_URL + tempUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ districts: response.data.data.map(item => ({ value: item.DistrictID, label: item.DistrictName })) });
                this.setState({ DistrictID: [] })

            }
            else {
                this.setState({ districts: [] })
            }
        }, error => { })
    }

    GetMunicipality() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var ZoneID = "";
        var tempPanelUserType = "";
        var tempUrl = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.ZoneIDs !== undefined) {
            ZoneID = this.props.allCookies.ZoneIDs
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            tempPanelUserType = this.props.allCookies.PanelUserType
        }
        if (this.props.allCookies.UserID !== undefined) {
            UserID = this.props.allCookies.UserID
        }

        var data = "";
        // data = JSON.stringify({
        //     "MuncipalityID": "",
        //     "CompanyID": CompanyID,
        //     "ClientID": ClientID,
        //     "DistrictID": "",
        //     "ZoneID": ""
        // });

        if (tempPanelUserType == "Admin") {
            data = JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "Muncipality/getMuncipalityDropDownData";
        } else {
            data = JSON.stringify({
                UserID: UserID,
                CompanyID: CompanyID,
                ClientID: ClientID
            });

            tempUrl = "User/getUserMuncipalityDetail";
        }

        var config = {
            method: 'POST',
            url: services.API_URL + tempUrl,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ municipalities: response.data.data.map(item => ({ value: item.MuncipalityID, label: item.MuncipalityName })) });
                this.setState({ MuncipalityID: [] })

            }
            else {
                this.setState({ municipalities: [] })
            }
        }, error => { })
    }

    GetWard() {
        var CompanyID = "";
        var ClientID = "";
        var UserID = "";
        var ckMunicipalityID = "";
        var tempPanelUserType = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.MunicipalityID !== undefined) {
            ckMunicipalityID = this.props.allCookies.MunicipalityID
        }
        if (this.props.allCookies.PanelUserType !== undefined) {
            tempPanelUserType = this.props.allCookies.PanelUserType
        }

        var data = "";
        // data = JSON.stringify({
        //     "WardID": "",
        //     "CompanyID": CompanyID,
        //     "ClientID": ClientID,
        //     "MuncipalityID": ""
        // });

        if (tempPanelUserType == "Admin") {
            data = JSON.stringify({
                CompanyID: CompanyID,
                ClientID: ClientID
            });
        } else {
            data = JSON.stringify({
                MuncipalityID: ckMunicipalityID,
                CompanyID: CompanyID,
                ClientID: ClientID
            });
        }

        var config = {
            method: 'POST',
            url: services.API_URL + "Ward/getWard",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ wards: response.data.data.map(item => ({ value: item.WardID, label: item.WardName })) });
                this.setState({ WardID: [] })

            }
            else {
                this.setState({ wards: [] })
            }
        }, error => { })
    }

    GetVehicle() {
        var CompanyID = "";
        var UserID = "";
        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }

        var data = "";
        data = JSON.stringify({
            "VehicleID": "",
            "CompanyID": CompanyID,
            "IsActive": "true",
            "IsDelete": "false"
        });

        var config = {
            method: 'POST',
            url: services.API_URL + "Vehicle/getVehicle",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            ////debugger;

            if (response.data.status) {
                this.setState({ vehicles: response.data.data.map(item => ({ value: item.VehicleID, label: item.VehicleNo })) });


            }
            else {
                this.setState({ vehicles: [] })
            }
        }, error => { })
    }

    // Event Change
    onStartDateChange = (e) => {
        this.setState({ StartDate: e });
        this.setState({ EndDate: e });
    }
    onZoneChange = (value) => {
        ////debugger;
        this.setState({ ZoneID: value });

    }
    onDistrictChange = (value) => {
        ////debugger;
        this.setState({ DistrictID: value });

    }
    onMunicipalityChange = (value) => {
        ////debugger;
        this.setState({ MunicipalityID: value });
    }
    onWardChange = (value) => {
        ////debugger;
        this.setState({ WardID: value });
    }
    onStatusChange = (value) => {
        debugger;
        // if (e!==null) {
        //     this.setState({ StatusID: e });
        // }
        // else {
        //     this.setState({ StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' } });
        // }
        this.setState({ StatusID: value });
    }
    onVehicleChange = (e) => {
        if (e !== null) {
            this.setState({ VehicleID: e });
        }
        else {
            this.setState({ VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' } });
        }
    }
    // Click Events
    OnFilterClick = (e) => {
        e.preventDefault();
        if (this.state.isFilterVisible === false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }

    onSearchClick = (e) => {
        e.preventDefault();
        this.GetSummaryReport();
    }
    OnSearchCancelClick = (e) => {
        this.setState({
            data: [], zones: [], districts: [], municipalities: [], wards: [],
            ZoneID: [], DistrictID: [], MunicipalityID: [], WardID: [],
            BookingNo: "",
            // status: [{ 'label': 'New', 'value': 'New' }, { 'label': 'Assigned', 'value': 'Assigned' },
            // { 'label': 'Started', 'value': 'Started' }, { 'label': 'Finished', 'value': 'Finished' },
            // { 'label': 'Completed', 'value': 'Completed' }, { 'label': 'Approved', 'value': 'Approved' },
            // { 'label': 'Referred Back', 'value': 'Referred Back' }, { 'label': 'Cancelled', 'value': 'Cancelled' }],

            status: [
                { 'label': 'Completed', 'value': 'Completed' },
                { 'label': 'Approved', 'value': 'Approved' }
            ],


            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            // StatusID: { 'label': '--Select Status--', 'value': '--Select Status--' },
            StatusID: [],
            StartDate: new Date(), EndDate: new Date(),

        }, () => { this.GetSummaryReport(); });
        this.GetZone();
        this.GetDistrict();
        this.GetMunicipality();
        this.GetWard();

    }

    render() {
        const { cookies } = this.props
        debugger;
        var Permission = cookies.cookies.SummaryReport;
        var TotalPermission = "";
        if (Permission !== undefined) {
            TotalPermission = Permission.split(',');
            if (this.state.AddStatus == "") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }

        return (
            <>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row" style={{ marginBottom: '1%' }}>
                        {(this.props.allCookies.Dashboard !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                <NavLink to={"/dashboard"} className="btn btn-outline-primary font-weight-bolds">Statistics
                                </NavLink>
                            </div>) : ''}
                        {(this.props.allCookies.GudmDashboard !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                {/* <NavLink to={"/gudmdashboard"} className="btn btn-outline-primary font-weight-bolds">Gudm
                                </NavLink> */}
                                <NavLink to={"/gudmdashboard"} className="btn btn-outline-primary font-weight-bolds">Tasks
                                </NavLink>
                            </div>) : ''}
                        {(this.props.allCookies.GudmSummary !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                {/* <NavLink to={"/gudmsummary"} className="btn btn-primary font-weight-bolds">Gudm Summary
                                </NavLink> */}
                                <NavLink to={"/gudmsummary"} className="btn btn-primary font-weight-bolds">Tasks Summary
                                </NavLink>
                            </div>) : ''}
                        {(this.props.allCookies.WorkInProgressDashboard !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                <NavLink to={"/vehicledashboard"} className="btn btn-outline-primary font-weight-bolds">Work In Progress
                                </NavLink>
                            </div>) : ''}

                        <div className="col-md-3" style={{ maxWidth: 'fit-content' }}>
                            {/* <button type="button" className="btn btn-outline-primary font-weight-bolds" onClick={this.ShowCamera1()}>
                                Live Streaming
                            </button> */}
                            {/* <a className="btn btn-outline-primary font-weight-bolds" target="_blank" href="http://mwhdia.in:3073/">
                                Live Streaming
                            </a> */}
                        </div>
                        {/* <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                            <NavLink to={"/livegps"} className="btn btn-outline-primary font-weight-bolds">Live GPS Tracking
                            </NavLink>
                        </div> */}
                    </div>
                    {this.state.isFilterVisible &&
                        <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Start Date </label>
                                    <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.StartDate} onChange={this.onStartDateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>End Date </label>
                                    <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.EndDate} minDate={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.EndDate} onChange={(e) => {
                                        this.setState({ EndDate: e });
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Zone </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.zones} selected={this.state.ZoneID} checked={false}
                                        onSelectedChanged={this.onZoneChange}
                                        overrideStrings={{
                                            selectSomeItems: "Zones", allItemsAreSelected: "All Zones are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>District </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.districts} selected={this.state.DistrictID} checked={false}
                                        onSelectedChanged={this.onDistrictChange}
                                        overrideStrings={{
                                            selectSomeItems: "Districts", allItemsAreSelected: "All Districts are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Municipality </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.municipalities} selected={this.state.MunicipalityID} checked={false}
                                        onSelectedChanged={this.onMunicipalityChange}
                                        overrideStrings={{
                                            selectSomeItems: "Municipalities", allItemsAreSelected: "All Municipalities are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Ward </label>
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.wards} selected={this.state.WardID} checked={false}
                                        onSelectedChanged={this.onWardChange}
                                        overrideStrings={{
                                            selectSomeItems: "Wards", allItemsAreSelected: "All Wards are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Vehicle </label>
                                    <Select isClearable={true} options={this.state.vehicles} value={this.state.VehicleID} onChange={this.onVehicleChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Status </label>
                                    {/* <Select isClearable={true} options={this.state.status} value={this.state.StatusID} onChange={this.onStatusChange} /> */}
                                    <MultiSelect style={{ borderColor: 'none', borderWidth: 'noe', borderStyle: 'none' }}
                                        options={this.state.status} selected={this.state.StatusID} checked={false}
                                        onSelectedChanged={this.onStatusChange}
                                        overrideStrings={{
                                            selectSomeItems: "Status", allItemsAreSelected: "All Status are Selected",
                                            selectAll: "Select All", search: "Search"
                                        }} />


                                </div>
                            </div>
                            <div className="col-md-3">
                                <button type="button" onClick={this.onSearchClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                <button type="button" onClick={this.OnSearchCancelClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-body">
                                    <MaterialTable columns={this.state.cols} data={this.state.data}

                                        options={{
                                            headerStyle: { color: 'black' }, toolbar: true, search: false, draggable: false,
                                            paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                            detailPanelType: "single",

                                        }}
                                        components={{

                                            Toolbar: props => (
                                                <div className="row" style={{ marginBottom: '2%' }}>
                                                    <div className="col-md-8" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h3 className="tabletitle">Summary Report</h3>

                                                    </div>
                                                    <div className="col-md-4" style={{ textAlign: 'right' }}>
                                                        <a className="btn btn-outline-dark font-weight-bolder" onClick={this.OnFilterClick}>
                                                            <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        }}


                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withCookies(gudmsummary)