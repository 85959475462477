import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import moment from 'moment';

// import { isNull } from "url/util";

import XLSX from "xlsx-color";

const headerTblStyle={ color: 'black' };
const exportToExcel=(columns, data) => {
    debugger;
    const header=["Sr.No", "VehicleNo", "RegistrationNo", "VehicleType", "VehicleBrand", "VehicleModel", "ParkingLocationName", "Ownership", "EngineNumber", "ChassisNumber", "ManufacturingYear"];
    const headertemp=["Sr.No", "Vehicle No", "Registration No", "Vehicle Type", "Vehicle Brand", "Vehicle Model", "Parking Location Name", "Vehicle Ownership", "Engine No", "Chassis No", "Manufacturing Year"];
    debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else if (col=="VehicleNo") {
                obj["Vehicle No"]=row[col];
            }
            else if (col=="RegistrationNo") {
                obj["Registration No"]=row[col];
            }
            else if (col=="VehicleType") {
                obj["Vehicle Type"]=row[col];
            }
            else if (col=="VehicleBrand") {
                obj["Vehicle Brand"]=row[col];
            }
            else if (col=="VehicleModel") {
                obj["Vehicle Model"]=row[col];
            }
            else if (col=="ParkingLocationName") {
                obj["Parking Location Name"]=row[col];
            }
            else if (col=="Ownership") {
                obj["Vehicle Ownership"]=row[col];
            }
            else if (col=="EngineNumber") {
                obj["Engine No"]=row[col];
            }
            else if (col=="ChassisNumber") {
                obj["Chassis No"]=row[col];
            }
            else if (col=="ManufacturingYear") {
                obj["Manufacturing Year"]=row[col];
            }
            else {
                obj[col]=row[col];
            }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols']=[
        { width: 6 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },
        { width: 30 },

    ];
    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;
    ws1['D1'].s=cell;
    ws1['E1'].s=cell;
    ws1['F1'].s=cell;
    ws1['G1'].s=cell;
    ws1['H1'].s=cell;
    ws1['I1'].s=cell;
    ws1['J1'].s=cell;
    ws1['K1'].s=cell;



    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;
        ws1['D'+ins].s=RowCont;
        ws1['E'+ins].s=RowCont;
        ws1['F'+ins].s=RowCont;
        ws1['G'+ins].s=RowCont;
        ws1['H'+ins].s=RowCont;
        ws1['I'+ins].s=RowCont;
        ws1['J'+ins].s=RowCont;
        ws1['K'+ins].s=RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "VehicleList");
    XLSX.writeFile(wb, "VehicleList_"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}
const APP_URL="https://mwhdia.in:3071/UploadFiles/Vehicle/VehicleImage/";
const APP_URL1="https://mwhdia.in:3071/UploadFiles/Vehicle/RCBookImage/";
const APP_URL2="https://mwhdia.in:3071/UploadFiles/Vehicle/InsuranceImage/";
const APP_URL3="https://mwhdia.in:3071/UploadFiles/Vehicle/PUCImage/";
const APP_URL4="https://mwhdia.in:3071/UploadFiles/Vehicle/FitnessImage/";
const APP_URL5="https://mwhdia.in:3071/UploadFiles/Vehicle/Document1Image/";
const APP_URL6="https://mwhdia.in:3071/UploadFiles/Vehicle/Document2Image/";

class VehicleMaster extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            VehicleID: '', RegistrationNo: '', VehicleNo: '', Ownership: '', EngineNumber: '', ChassisNumber: '',
            ManufacturingYear: '', PassingWeight: '', PurchaseDate: '', VehicleImage: '', RCBook: '',
            Insurance: '', PUC: '', Fitness: '', Document1: '', Document2: '', Remarks: '',
            TaskDateErrMsg: "", vehiclefilename: "", OldVehicleImage: '', OldRCBook: '', OldInsurance: '',
            OldPUC: '', OldFitness: '', OldDocument1: '', OldDocument2: '',
            AddVehicleVisible: false, iconAdd: "fa fa-plus", iconFilter: "fa fa-plus", isFilterVisible: false,
            vehicleFile: [], vehicleFileErr: "",
            rcFile: [], insFile: [], pucFile: [], fitnessFile: [], docFile1: [], docFile2: [], data: [], vehicletypes: [],
            VehicleTypeID: { 'label': '--Select Vehicle Type--', 'value': '--Select Vehicle Type--' },
            SearchVehicleTypeID: { 'label': '--Select Vehicle Type--', 'value': '--Select Vehicle Type--' },
            VehicalTypeErrMsg: "",
            vehiclebrands: [],
            VehicleBrandID: { 'label': '--Select Vehicle Brand--', 'value': '--Select Vehicle Brand--' },
            SearchVehicleBrandID: { 'label': '--Select Vehicle Brand--', 'value': '--Select Vehicle Brand--' },
            VehicalBrandErrMsg: "",
            vehiclemodels: [],
            VehicleModelID: { 'label': '--Select Vehicle Model--', 'value': '--Select Vehicle Model--' },
            SearchVehicleModelID: { 'label': '--Select Vehicle Model--', 'value': '--Select Vehicle Model--' },
            VehicalModelErrMsg: "",
            parkinglocations: [],
            ParkingLocationID: { 'label': '--Select Location--', 'value': '--Select Location--' },
            SearchParkingLocationID: { 'label': '--Select Location--', 'value': '--Select Location--' },
            ParkingLocationErrMsg: "",
            vehicleownerships: [{ label: "Owned", value: "Owned" },
            { label: "Attached", value: "Attached" },
            { label: "Rented", value: "Rented" }],
            VehicleOwnerShipID: { 'label': '--Select Vehicle OwnerShip--', 'value': '--Select Vehicle OwnerShip--' },
            SearchVehicleOwnerShipID: { 'label': '--Select Vehicle OwnerShip--', 'value': '--Select Vehicle OwnerShip--' },
            VehicalOwnerShipErrMsg: "", TaskDateErrMsg: "", SearchVehicleNo: "",
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px', textAlign: 'center' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'Status', width: '7%', field: 'IsActive', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<BootstrapSwitchButton onlabel='Yes' offlabel='No' onChange={this.onStatusChange(rowData.VehicleID)} checked={rowData.IsActive} size="md" onstyle="success" offstyle="danger" />) } },
                {
                    title: (<div style={{ width: '100%' }}><span>Vehicle No</span>
                        <hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Registration No</span>
                    </div>), width: '15%', removable: false, headerStyle: headerTblStyle,
                    render: rowData => {
                        return (
                            <p style={{ marginBottom: '1px' }}>{rowData.VehicleNo}<hr /> {rowData.RegistrationNo} </p>
                        )
                    }
                },
                {
                    title: (<div style={{ width: '100%' }}><span>Vehicle Type</span>
                        <hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Vehicle Brand</span>
                        <hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Vehicle Model</span>
                    </div>), width: '15%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.VehicleType}<hr /> {rowData.VehicleBrand}<hr /> {rowData.VehicleModel}</p>) }
                },
                { title: 'Parking LocationName', width: '15%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.ParkingLocationName}</p>) } },
                { title: 'Vehicle Ownership', width: '25%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.Ownership}</p>) } },
                {
                    title: (<div style={{ width: '100%' }}><span>Engine No</span>
                        <hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Chassis No</span>
                        <hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Manufacturing Year</span>
                    </div>), width: '25%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.EngineNumber} <hr />{rowData.ChassisNumber} <hr /> {rowData.ManufacturingYear}</p>) }
                },
                // { title: 'Manufacturing Year', width: '25%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.ManufacturingYear}</p>) } },
                // { title: 'Location', width: '25%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.EngineNumber}</p>) } },
                // { title: 'Purchase Date', width: '25%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.PurchaseDate}</p>) } },
                {
                    title: 'Vehicle Image', field: 'url', width: '10%',
                    render: rowData =>
                        (rowData.VehicleImage!=""&&rowData.VehicleImage!=null)?
                            <a href={APP_URL+rowData.VehicleImage} target="_blank">
                                <img src={APP_URL+rowData.VehicleImage} alt="Image Not" style={{ width: 80, height: 80 }} /></a>:<img style={{ height: '100px' }} src={APP_URL+"NoImage.png"} />
                },
                // { title: 'Image', width: '25%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.VehicleImage}</p>) } }
            ]
        }

    }

    componentDidMount() {
        this.GetVehicleMaster();
        this.GetVehicleType();
        this.GetVehicleBrand();
        this.GetVehicleModel();
        this.GetParkingLocation();
    }
    GetVehicleMaster() {
        debugger;
        var CompanyID="";
        var SearchVehicleTypeID="";
        var SearchVehicleBrandID="";
        var SearchVehicleModelID="";
        var SearchParkingLocationID="";
        var SearchVehicleOwnerShipID="";
        // var SearchVehicleNo = "";

        if (this.state.SearchVehicleTypeID.value!="--Select Vehicle Type--") {
            SearchVehicleTypeID=this.state.SearchVehicleTypeID.value;
        }
        else {
            SearchVehicleTypeID="";
        }

        if (this.state.SearchVehicleBrandID.value!="--Select Vehicle Brand--") {
            SearchVehicleBrandID=this.state.SearchVehicleBrandID.value;
        }
        else {
            SearchVehicleBrandID="";
        }

        if (this.state.SearchVehicleModelID.value!="--Select Vehicle Model--") {
            SearchVehicleModelID=this.state.SearchVehicleModelID.value;
        }
        else {
            SearchVehicleModelID="";
        }

        if (this.state.SearchParkingLocationID.value!="--Select Location--") {
            SearchParkingLocationID=this.state.SearchParkingLocationID.value;
        }
        else {
            SearchParkingLocationID="";
        }

        if (this.state.SearchVehicleOwnerShipID.value!="--Select Vehicle OwnerShip--") {
            SearchVehicleOwnerShipID=this.state.SearchVehicleOwnerShipID.value;
        }
        else {
            SearchVehicleOwnerShipID="";
        }

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }

        if (CompanyID!="") {
            var data=JSON.stringify({
                "VehicleID": "",
                "CompanyID": CompanyID,
                "VehicleTypeID": SearchVehicleTypeID,
                "VehicleBrandID": SearchVehicleBrandID,
                "VehicleModelID": SearchVehicleModelID,
                "ParkingLocationID": SearchParkingLocationID,
                "Ownership": SearchVehicleOwnerShipID,
                "VehicleNo": this.state.SearchVehicleNo,
                "IsActive": "",
                "IsDelete": "false"
                // "VehicleTypeID": SearchVehicleTypeID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Vehicle/getVehicle",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                if (response.data.status) {
                    if (this.state.ViewStatus==="ViewStatus:true") {
                        this.setState({ data: response.data.data })
                    }
                    else {
                        this.setState({ data: [] })
                    }
                }
                else {
                    this.setState({ data: [] })
                }

            }, error => { })
        } else {
            if (CompanyID=="") {
                Swal.fire({
                    title: 'Please Select Company ', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }
    GetVehicleType() {
        var data=JSON.stringify({
            "VehicleTypeID": "",
            "IsActive": "true",
            "IsDelete": "false"
        });
        var config={
            method: 'POST',
            url: services.API_URL+"VehicleType/getVehicleType",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            this.setState({ vehicletypes: response.data.data.map(item => ({ value: item.VehicleTypeID, label: item.VehicleType })) });
        }, error => { })
    }
    GetVehicleBrand() {
        var data=JSON.stringify({
            "VehicleBrandID": "",
            "IsActive": "true",
            "IsDelete": "false"
        });
        var config={
            method: 'POST',
            url: services.API_URL+"VehicleBrand/getVehicleBrand",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            this.setState({ vehiclebrands: response.data.data.map(item => ({ value: item.VehicleBrandID, label: item.VehicleBrand })) });
        }, error => { })
    }
    GetVehicleModel() {
        var data=JSON.stringify({
            "VehicleModelID": "",
            "IsActive": "true",
            "IsDelete": "false"
        });
        var config={
            method: 'POST',
            url: services.API_URL+"VehicleModel/getVehicleModel",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            this.setState({ vehiclemodels: response.data.data.map(item => ({ value: item.VehicleModelID, label: item.VehicleModel })) });
        }, error => { })
    }
    GetParkingLocation() {
        debugger;
        var CompanyID="";
        var ClientID="";

        // if (this.state.SearchStateID.value != "--Select State--") {
        //     SearchStateID = this.state.SearchStateID.value;
        // }
        // else {
        //     SearchStateID = "";
        // }

        // if (this.state.SearchCityID.value != "--Select City--") {
        //     SearchCityID = this.state.SearchCityID.value;
        // }
        // else {
        //     SearchCityID = "";
        // }

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }

        if (CompanyID!="") {
            var data=JSON.stringify({
                "ParkingLocationID": "",
                "CompanyID": CompanyID,
                "IsActive": "true",
                "IsDelete": "false"
                // "StateID": SearchStateID,
                // "CityID": SearchCityID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"ParkingLoction/getParkingLocation",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                if (response.data.status) {
                    // this.setState({ data: response.data.data })
                    this.setState({ parkinglocations: response.data.data.map(item => ({ value: item.ParkingLocationID, label: item.ParkingLocationName })) });

                }
                else {
                    this.setState({ data: [] })
                }

            }, error => { })
        }
        else {
            if (CompanyID=="") {
                Swal.fire({
                    title: 'Please Select Company ', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }

    }
    AllowNumberKey=(e) => {
        var code=("charCode" in e)? e.charCode:e.keyCode;
        if (!(code>47&&code<58)) { //numeric (0-9)
            e.preventDefault();
        }
    }
    EditVehicleMaster(id) {
        debugger;
        var CompanyID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }

        if (CompanyID!="") {
            var data=JSON.stringify({
                "VehicleID": id,
                "CompanyID":CompanyID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Vehicle/getVehicle",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;
                // this.setState({ data: response.data.data })
                var date="";

                if (response.data.data[0].PurchaseDate!=null) {
                    var dateString=response.data.data[0].PurchaseDate;
                    var dateMomentObject=moment(dateString, "DD-MM-YYYY"); // 1st argument - string, 2nd argument - format
                    date=dateMomentObject.toDate();
                }

                debugger;
                this.setState({
                    VehicleID: response.data.data[0].VehicleID,
                    RegistrationNo: response.data.data[0].RegistrationNo,
                    VehicleTypeID: { 'label': response.data.data[0].VehicleType, 'value': response.data.data[0].VehicleTypeID },
                    VehicleBrandID: { 'label': response.data.data[0].VehicleBrand, 'value': response.data.data[0].VehicleBrandID },
                    VehicleModelID: { 'label': response.data.data[0].VehicleModel, 'value': response.data.data[0].VehicleModelID },
                    VehicleOwnerShipID: { 'label': response.data.data[0].Ownership, 'value': response.data.data[0].Ownership },
                    ParkingLocationID: { 'label': response.data.data[0].ParkingLocationName, 'value': response.data.data[0].ParkingLocationID },
                    VehicleNo: response.data.data[0].VehicleNo,
                    EngineNumber: response.data.data[0].EngineNumber,
                    ChassisNumber: response.data.data[0].ChassisNumber,
                    ManufacturingYear: response.data.data[0].ManufacturingYear,
                    PassingWeight: response.data.data[0].PassingWeight,
                    PurchaseDate: date,
                    Remarks: response.data.data[0].Remarks!=null? response.data.data[0].Remarks:'',
                    OldVehicleImage: response.data.data[0].VehicleImage!=null? response.data.data[0].VehicleImage:'',
                    OldRCBook: response.data.data[0].RCBook!=null? response.data.data[0].RCBook:'',
                    OldInsurance: response.data.data[0].Insurance!=null? response.data.data[0].Insurance:'',
                    OldPUC: response.data.data[0].PUC!=null? response.data.data[0].PUC:'',
                    OldFitness: response.data.data[0].Fitness!=null? response.data.data[0].Fitness:'',
                    OldDocument1: response.data.data[0].Document1!=null? response.data.data[0].Document1:'',
                    OldDocument2: response.data.data[0].Document2!=null? response.data.data[0].Document2:'',

                    // rows: response.data.data[0].DistrictEmailDetail
                });
                this.setState({ AddVehicleVisible: true });
                this.setState({ iconAdd: "fa fa-minus" });
            }, error => { })
        } else {
            if (CompanyID=="") {
                Swal.fire({
                    title: 'Please Select Company ', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }


    }

    // ValidateTaskDate = (e) => {
    //     this.setState({ TaskDateErrMsg: '' })
    // }
    // ValidateCancelTaskDate = (e) => {
    //     this.setState({ TaskDateErrMsg: 'required' })
    // }

    ValidatePakingLocation=(e) => {
        this.setState({ ParkingLocationErrMsg: '' })
    }
    ValidateCancelPakingLocation=(e) => {
        this.setState({ ParkingLocationErrMsg: 'required' })
    }
    onPakingLocationChange=(e) => {
        debugger;
        if (e!=null) {
            this.setState({ ParkingLocationID: e }, (e) => { this.ValidatePakingLocation(); });

        }
        else {
            this.setState({ ParkingLocationID: { 'label': '--Select Location--', 'value': '--Select Location--' } }, () => { this.ValidateCancelPakingLocation() });
        }

    }
    ValidateVehicleType=(e) => {
        this.setState({ VehicalTypeErrMsg: '' })
    }
    ValidateCancleVehicleType=(e) => {
        this.setState({ VehicalTypeErrMsg: 'required' })
    }
    onVehicleTypeChange=(e) => {
        debugger;
        if (e!=null) {
            this.setState({ VehicleTypeID: e }, (e) => { this.ValidateVehicleType(); });

        }
        else {
            this.setState({ VehicleTypeID: { 'label': '--Select Vehicle Type--', 'value': '--Select Vehicle Type--' } }, () => { this.ValidateCancleVehicleType() });
        }

    }
    ValidateVehicleBrand=(e) => {
        this.setState({ VehicalBrandErrMsg: '' })
    }
    ValidateCancleVehicleBrand=(e) => {
        this.setState({ VehicalBrandErrMsg: 'required' })
    }
    onVehicleBrandChange=(e) => {
        debugger;
        if (e!=null) {
            this.setState({ VehicleBrandID: e }, (e) => { this.ValidateVehicleBrand(); });

        }
        else {
            this.setState({ VehicleBrandID: { 'label': '--Select Vehicle Brand--', 'value': '--Select Vehicle Brand--' } }, () => { this.onVehicleBrandChange() });
        }

    }
    ValidateVehicleModel=(e) => {
        this.setState({ VehicalModelErrMsg: '' })
    }
    ValidateCancleVehicleModel=(e) => {
        this.setState({ VehicalModelErrMsg: 'required' })
    }
    onVehicleModelChange=(e) => {
        debugger;
        if (e!=null) {
            this.setState({ VehicleModelID: e }, (e) => { this.ValidateVehicleModel(); });

        }
        else {
            this.setState({ VehicleModelID: { 'label': '--Select Vehicle Model--', 'value': '--Select Vehicle Model--' } }, () => { this.ValidateCancleVehicleModel() });
        }

    }
    ValidateVehicleOwenerShip=(e) => {
        this.setState({ VehicalOwnerShipErrMsg: '' })
    }
    ValidateCancleVehicleOwenerShip=(e) => {
        this.setState({ VehicalOwnerShipErrMsg: 'required' })
    }
    onVehicleOwnerShipChange=(e) => {
        debugger;
        if (e!=null) {
            this.setState({ VehicleOwnerShipID: e }, (e) => { this.ValidateVehicleOwenerShip(); })
        }
        else {
            this.setState({ VehicleOwnerShipID: { 'label': '--Select Vehicle OwnerShip--', 'value': '--Select Vehicle OwnerShip--' } }, () => { this.ValidateCancleVehicleOwenerShip() });
        }
    }

    handleSubmit=(e) => {
        debugger;
        e.preventDefault();
        this.form.validateFields();
        var CompanyID="";
        var ClientID="";
        var UserID="";
        var UserType="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID!==undefined) {
            UserID=this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType!==undefined) {
            UserType=this.props.allCookies.PanelUserType
        }
        if (this.state.VehicleTypeID.value=="--Select Vehicle Type--") {
            this.setState({ VehicalTypeErrMsg: 'required' });
        }
        if (this.state.VehicleOwnerShipID.value=="--Select Vehicle OwnerShip--") {
            this.setState({ VehicalOwnerShipErrMsg: 'required' });
        }
        if (this.state.VehicleBrandID.value=="--Select Vehicle Brand--") {
            this.setState({ VehicalBrandErrMsg: 'required' });
        }
        if (this.state.VehicleModelID.value=="--Select Vehicle Model--") {
            this.setState({ VehicalModelErrMsg: 'required' });
        }
        if (this.state.ParkingLocationID.value=="--Select Location--") {
            this.setState({ ParkingLocationErrMsg: 'required' });
        }

        var allvalid=true;
        if (this.state.vehicleFile.length==0) {
            this.setState({ vehicleFileErr: "required" });
            allvalid=false;
        }

        if (this.form.isValid()) {
            // if (allvalid == true) {
            if (CompanyID!="") {
                // var data = "";
                const {
                    VehicleNo, RegistrationNo, EngineNumber, ChassisNumber, ManufacturingYear, PassingWeight,
                    vehicleFile, rcFile, insFile, pucFile, fitnessFile, docFile1, docFile2,
                    OldVehicleImage, OldRCBook, OldInsurance, OldPUC, OldFitness, OldDocument1, OldDocument2, Remarks
                }=this.state;
                var vehicletypebind=this.state.VehicleTypeID.value;
                var vehiclebrandbind=this.state.VehicleBrandID.value;
                var vehiclemodelbind=this.state.VehicleModelID.value;
                var parkinlocationbind=this.state.ParkingLocationID.value;
                var ownershipbind=this.state.VehicleOwnerShipID.value;
                var PurchaseDate=this.state.PurchaseDate;
                if (PurchaseDate!="") {
                    PurchaseDate=moment(PurchaseDate).format('DD-MM-YYYY');
                }
                else {
                    PurchaseDate="";
                }
                var form=new FormData();
                if (this.state.VehicleID!="") {
                    form.append('VehicleID', this.state.VehicleID);
                    form.append('CompanyID', CompanyID);
                    form.append('ClientID', ClientID);
                    form.append('VehicleTypeID', vehicletypebind);
                    form.append('VehicleBrandID', vehiclebrandbind);
                    form.append('VehicleModelID', vehiclemodelbind);
                    form.append('ParkingLocationID', parkinlocationbind);
                    form.append('VehicleNo', VehicleNo);
                    form.append('RegistrationNo', RegistrationNo);
                    form.append('Ownership', ownershipbind);
                    form.append('EngineNumber', EngineNumber);
                    form.append('ChassisNumber', ChassisNumber);
                    form.append('ManufacturingYear', ManufacturingYear);
                    form.append('PassingWeight', PassingWeight);
                    form.append('PurchaseDate', PurchaseDate);
                    form.append('OldVehicleImage', OldVehicleImage);
                    form.append('EntryByUserType', UserType);
                    form.append('EntryByUserID', UserID);
                    for (let i=0; i<this.state.vehicleFile.length; i++) {
                        form.append("VehicleImage", vehicleFile[i]);
                    }
                    form.append('OldRCBook', OldRCBook);
                    for (let i=0; i<this.state.rcFile.length; i++) {
                        form.append('RCBook', rcFile[i]);
                    }
                    form.append('OldInsurance', OldInsurance);
                    for (let i=0; i<this.state.insFile.length; i++) {
                        form.append('Insurance', insFile[i]);
                    }
                    form.append('OldPUC', OldPUC);
                    for (let i=0; i<this.state.pucFile.length; i++) {
                        form.append('PUC', pucFile[i]);
                    }
                    form.append('OldFitness', OldFitness);
                    for (let i=0; i<this.state.fitnessFile.length; i++) {
                        form.append('Fitness', fitnessFile[i]);
                    }
                    form.append('OldDocument1', OldDocument1);
                    for (let i=0; i<this.state.docFile1.length; i++) {
                        form.append('Document1', docFile1[i]);
                    }
                    form.append('OldDocument2', OldDocument2);
                    for (let i=0; i<this.state.docFile2.length; i++) {
                        form.append('Document2', docFile2[i]);
                    }
                    form.append('Remarks', Remarks);
                } else {
                    // form.append('VehicleID', "");
                    form.append('CompanyID', CompanyID);
                    form.append('VehicleTypeID', vehicletypebind);
                    form.append('VehicleBrandID', vehiclebrandbind);
                    form.append('VehicleModelID', vehiclemodelbind);
                    form.append('ParkingLocationID', parkinlocationbind);
                    form.append('VehicleNo', VehicleNo);
                    form.append('RegistrationNo', RegistrationNo);
                    form.append('Ownership', ownershipbind);
                    form.append('EngineNumber', EngineNumber);
                    form.append('ChassisNumber', ChassisNumber);
                    form.append('ManufacturingYear', ManufacturingYear);
                    form.append('PassingWeight', PassingWeight);
                    form.append('PurchaseDate', PurchaseDate);
                    form.append('VehicleImage', vehicleFile[0]);
                    form.append('RCBook', rcFile[0]);
                    form.append('Insurance', insFile[0]);
                    form.append('PUC', pucFile[0]);
                    form.append('Fitness', fitnessFile[0]);
                    form.append('Document1', docFile1[0]);
                    form.append('Document2', docFile2[0]);
                    form.append('Remarks', Remarks);
                }
                // var config = {
                //     method: 'POST',
                //     url: services.API_URL + "Vehicle/setVehicle",
                //     headers: {
                //         'Content-Type': 'multipart/form-data'
                //     },
                //     data: data
                // };
                services.setVehicle(form).then(response => {
                    //debugger;
                    if (response.data.status) {
                        // this.form.reset();
                        debugger;
                        if (this.state.VehicleID!="") {
                            // Swal.fire({ position: 'top-end', toast: true, icon: 'success', title: 'Successfully Updated', showConfirmButton: false, timer: 1500 });
                            Swal.fire({
                                title: 'Successfully Updated', icon: "success", timer: 1500
                            });
                        }
                        else {
                            Swal.fire({
                                title: 'Successfully Inserted', icon: "success", timer: 1500
                            });
                        }
                        this.ClearData();
                        this.GetVehicleMaster();
                    }
                    else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }
                }, error => { })

            } else {
                if (CompanyID=="") {
                    Swal.fire({
                        title: 'Please Select Company ', icon: "error", timer: 1500
                    });
                }
                else {
                    Swal.fire({
                        title: 'Something went wrong', icon: "error", timer: 1500
                    });
                }
            }
            // }
        }
    }

    handleFile=(e) => {
        this.setState({
            vehicleFile: e.target.files,
            vehiclefilename: e.target.files[0].originalname,
        });
    };

    handleRCFile=(e) => {
        this.setState({
            rcFile: e.target.files,
            rcfilename: e.target.files[0].originalname,
        });
    };

    handleINSFile=(e) => {
        this.setState({
            insFile: e.target.files,
            INSfilename: e.target.files[0].originalname,
        });
    };

    handlePUCFile=(e) => {
        this.setState({
            pucFile: e.target.files,
            PUCfilename: e.target.files[0].originalname,
        });
    };

    handleFITFile=(e) => {
        this.setState({
            fitnessFile: e.target.files,
            FITfilename: e.target.files[0].originalname,
        });
    };

    handleDOC1File=(e) => {
        this.setState({
            docFile1: e.target.files,
            DOC1filename: e.target.files[0].originalname,
        });
    };

    handleDOC2File=(e) => {
        this.setState({
            docFile2: e.target.files,
            DOC2filename: e.target.files[0].originalname,
        });
    };

    onAddVehicleClick=(e) => {
        if (this.state.AddVehicleVisible==false) {
            this.setState({ AddVehicleVisible: true });
            this.setState({ iconAdd: "fa fa-minus" });
        }
        else {
            this.setState({ AddVehicleVisible: false });
            this.setState({ iconAdd: "fa fa-plus" });
        }
    }
    OnFilterClick=(e) => {
        e.preventDefault();
        if (this.state.isFilterVisible===false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }

    OnSearchVehicleClick=(e) => {
        debugger;
        e.preventDefault();
        this.GetVehicleMaster();
    }

    OnSearchCancelVehicleClick=() => {
        debugger;
        this.setState({
            SearchVehicleTypeID: { 'label': '--Select Vehicle Type--', 'value': '--Select Vehicle Type--' },
            SearchVehicleBrandID: { 'label': '--Select Vehicle Brand--', 'value': '--Select Vehicle Brand--' },
            SearchVehicleModelID: { 'label': '--Select Vehicle Model--', 'value': '--Select Vehicle Model--' },
            SearchVehicleOwnerShipID: { 'label': '--Select Vehicle OwnerShip--', 'value': '--Select Vehicle OwnerShip--' },
            SearchParkingLocationID: { 'label': '--Select Location--', 'value': '--Select Location--' },
            SearchVehicleNo: ""
        }, () => {
            this.GetVehicleMaster();
        });
        //window.location.reload(true);

    }
    onStatusChange=(VehicleID) => (Status) => {
        // e.preventDefault();
        debugger;
        var IsStatus=Status.toString();


        var data=JSON.stringify({
            "VehicleID": VehicleID,
            "IsActive": IsStatus
        });
        var config={
            method: 'POST',
            url: services.API_URL+"Vehicle/setVehicleIsActiveStatus",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                //this.setState({ data: response.data.data })
                Swal.fire({
                    title: 'Successfully Updated', icon: "success", timer: 1500
                });
            }
            else {
                Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                // this.setState({ data: [] })
            }
            this.GetVehicleMaster();

        }, error => { })
    }
    ClearData=(e) => {
        debugger;
        this.setState({
            // zones: [],
            // ZoneID: { 'label': '--Select Zone--', 'value': '--Select Zone--' },
            vehicletypes: [],
            VehicleTypeID: { 'label': '--Select Vehicle Type--', 'value': '--Select Vehicle Type--' },
            vehiclebrands: [],
            VehicleBrandID: { 'label': '--Select Vehicle Brand--', 'value': '--Select Vehicle Brand--' },
            vehiclemodels: [],
            VehicleModelID: { 'label': '--Select Vehicle Model--', 'value': '--Select Vehicle Model--' },
            parkinglocations: [],
            ParkingLocationID: { 'label': '--Select Location--', 'value': '--Select Location--' },
            vehicleownerships: [{ label: "Owned", value: "Owned" },
            { label: "Attached", value: "Attached" },
            { label: "Rented", value: "Rented" }],
            VehicleOwnerShipID: { 'label': '--Select Vehicle OwnerShip--', 'value': '--Select Vehicle OwnerShip--' },
            VehicalTypeErrMsg: "", VehicalBrandErrMsg: "", VehicalModelErrMsg: "", VehicalOwnerShipErrMsg: "", SearchVehicleNo: "",
            ParkingLocationErrMsg: "", VehicleNo: "", RegistrationNo: "", Ownership: "", EngineNumber: "",
            ChassisNumber: '', ManufacturingYear: "", PassingWeight: "", PurchaseDate: "", Remarks: "", vehicleFileErr: "",
            OldVehicleImage: '', OldRCBook: '', OldInsurance: '', OldPUC: '', OldFitness: '', OldDocument1: '', OldDocument2: '',
            vehiclefilename: '', rcfilename: '', INSfilename: '', PUCfilename: '', FITfilename: '', DOC1filename: '', DOC2filename: '',
            Remarks: '', vehicleFile: [], rcFile: [], insFile: [], pucFile: [], fitnessFile: [], docFile1: [], docFile2: [],VehicleID:""
        });
        // this.state.vehicleFile.remove();
        this.form.reset();
        this.GetVehicleMaster();
        this.GetVehicleType();
        this.GetVehicleBrand();
        this.GetVehicleModel();
        this.GetParkingLocation();
    }
    CheckAlert(id,vehicleno) {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                var CompanyID="";
                var ClientID="";
                var UserID="";
                var UserType="";
                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                var data=JSON.stringify({
                    "VehicleID": id,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "VehicleNo": vehicleno,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+"Vehicle/removeVehicle",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        this.GetVehicleMaster();
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        });
                    } else {
                        Swal.fire({ icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }
    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.data)
    }
    render() {
        const { cookies }=this.props
        var Permission=cookies.cookies.VehicleMaster;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row">
                        {this.state.AddVehicleVisible&&
                            <div className="col-md-12">
                                {
                                    this.state.AddStatus==="AddStatus:true"&&
                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header">
                                            <h3 className="card-title">Add Vehicle</h3>
                                        </div>
                                        <FormWithConstraints
                                            ref={form => this.form=form}
                                            onSubmit={this.handleSubmit}
                                            noValidate>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Vehicle No<span className="text-danger">*</span></label>
                                                            <input type="text" name="VehicleNo" required value={this.state.VehicleNo} onChange={(e) => this.setState({ VehicleNo: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Vehicle No" />
                                                            <FieldFeedbacks for="VehicleNo">
                                                                <FieldFeedback when="valueMissing">

                                                                </FieldFeedback>
                                                                <FieldFeedback when={value => !/^[A-Z]{2}[\\-]{0,1}[0-9]{2}[\\-]{0,1}[A-Z]{1,2}[\\-]{0,1}[0-9]{4}$/.test(value)}>Invalid Vehicle No</FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Registration No<span className="text-danger">*</span></label>
                                                            <input type="text" name="RegistrationNo" required value={this.state.RegistrationNo} onChange={(e) => this.setState({ RegistrationNo: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Registration No" />
                                                            <FieldFeedbacks for="RegistrationNo">
                                                                <FieldFeedback when="valueMissing">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Vehicle Type<span className="text-danger">*</span></label>
                                                            <Select isClearable={true} options={this.state.vehicletypes} value={this.state.VehicleTypeID} onChange={this.onVehicleTypeChange} />
                                                            {this.state.VehicalTypeErrMsg&&<span className="text-danger">{this.state.VehicalTypeErrMsg==='required'? 'Please select vehicle type':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Vehicle Ownership<span className="text-danger">*</span></label>
                                                            <Select isClearable={true} options={this.state.vehicleownerships} value={this.state.VehicleOwnerShipID} onChange={this.onVehicleOwnerShipChange} />
                                                            {this.state.VehicalOwnerShipErrMsg&&<span className="text-danger">{this.state.VehicalOwnerShipErrMsg==='required'? 'Please select vehicle ownership':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Vehicle Brand<span className="text-danger">*</span></label>
                                                            <Select isClearable={true} options={this.state.vehiclebrands} value={this.state.VehicleBrandID} onChange={this.onVehicleBrandChange} />
                                                            {this.state.VehicalBrandErrMsg&&<span className="text-danger">{this.state.VehicalBrandErrMsg==='required'? 'Please select vehicle brand':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Vehicle Model<span className="text-danger">*</span></label>
                                                            <Select isClearable={true} options={this.state.vehiclemodels} value={this.state.VehicleModelID} onChange={this.onVehicleModelChange} />
                                                            {this.state.VehicalModelErrMsg&&<span className="text-danger">{this.state.VehicalModelErrMsg==='required'? 'Please select vehicle model':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Location<span className="text-danger">*</span></label>
                                                            <Select isClearable={true} options={this.state.parkinglocations} value={this.state.ParkingLocationID} onChange={this.onPakingLocationChange} />
                                                            {this.state.ParkingLocationErrMsg&&<span className="text-danger">{this.state.ParkingLocationErrMsg==='required'? 'Please select parking location':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Engine No <span className="text-danger">*</span></label>
                                                            <input type="text" name="EngineNumber" required value={this.state.EngineNumber} onChange={(e) => this.setState({ EngineNumber: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Engine Number" />

                                                            <FieldFeedbacks for="EngineNumber">
                                                                <FieldFeedback when="*">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Chassis No <span className="text-danger">*</span></label>
                                                            <input type="text" name="ChassisNumber" required value={this.state.ChassisNumber} onChange={(e) => this.setState({ ChassisNumber: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Chassis Number" />

                                                            <FieldFeedbacks for="ChassisNumber">
                                                                <FieldFeedback when="*">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Manufacturing Year <span className="text-danger">*</span></label>
                                                            <input type="text" name="ManufacturingYear" maxLength="10" onKeyPress={this.AllowNumberKey.bind(this)} required value={this.state.ManufacturingYear} onChange={(e) => this.setState({ ManufacturingYear: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Manufacturing Year" />
                                                            <FieldFeedbacks for="ManufacturingYear">
                                                                <FieldFeedback when="*">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Passing Weight  (Ton)<span className="text-danger">*</span></label>
                                                            {/* <input name="PassingWeight" required type="text" value={this.state.PassingWeight} onChange={(e) => this.setState({ PassingWeight: e.target.value })} className="form-control" placeholder="Enter Passing Weight" /> */}
                                                            <input type="text" name="PassingWeight" required value={this.state.PassingWeight} onChange={(e) => this.setState({ PassingWeight: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Passing Weight" />
                                                            <FieldFeedbacks for="PassingWeight">
                                                                <FieldFeedback when="*">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Purchase Date</label>
                                                        <input name="PurchaseDate" type="date" value={this.state.PurchaseDate} onChange={(e) => this.setState({ PurchaseDate: e.target.value })} className="form-control" placeholder="Enter Passing Weight" />
                                                    </div>
                                                </div> */}
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Purchase Date</label>
                                                            <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.PurchaseDate} maxDate={new Date()} autoComplete="off" className="form-control" id="txtTaskDate" value={this.state.PurchaseDate}
                                                                // onChange={(e) => {
                                                                //     this.setState({ PurchaseDate: e }, () => { this.ValidateTaskDate() });
                                                                // }} 
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        PurchaseDate: e,
                                                                    });
                                                                }}

                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Vehicle Picture :<span className="text-danger">*</span></label>
                                                            <input
                                                                name=""
                                                                type="file"
                                                                accept="image/jpeg, image/png, image/jpg"
                                                                value={this.state.vehiclefilename}
                                                                style={{ height: "37px" }}
                                                                onChange={this.handleFile}
                                                                className="form-control"
                                                            />
                                                            {this.state.vehicleFileErr&&<span className="text-danger">{this.state.vehicleFileErr==='required'? 'Please select vehicle image':''}</span>}
                                                        </div>
                                                        {this.state.OldVehicleImage!=""&&(
                                                            <label style={{ color: "red" }}>{this.state.OldVehicleImage}</label>

                                                        )}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>RC Book :</label>
                                                            <input
                                                                type="file"
                                                                accept="image/jpeg, image/png, image/jpg"
                                                                value={this.state.rcfilename}
                                                                style={{ height: "37px" }}
                                                                onChange={this.handleRCFile}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        {this.state.OldRCBook!=""&&(
                                                            <label style={{ color: "red" }}>{this.state.OldRCBook}</label>

                                                        )}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Vehicle Insurance :</label>
                                                            <input
                                                                type="file"
                                                                accept="image/jpeg, image/png, image/jpg"
                                                                value={this.state.INSfilename}
                                                                style={{ height: "37px" }}
                                                                onChange={this.handleINSFile}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        {this.state.OldInsurance!=""&&(
                                                            <label style={{ color: "red" }}>{this.state.OldInsurance}</label>

                                                        )}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>PUC :</label>
                                                            <input
                                                                type="file"
                                                                accept="image/jpeg, image/png, image/jpg"
                                                                value={this.state.PUCfilename}
                                                                style={{ height: "37px" }}
                                                                onChange={this.handlePUCFile}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        {this.state.OldPUC!=""&&(
                                                            <label style={{ color: "red" }}>{this.state.OldPUC}</label>

                                                        )}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Fitness :</label>
                                                            <input
                                                                type="file"
                                                                accept="image/jpeg, image/png, image/jpg"
                                                                value={this.state.FITfilename}
                                                                style={{ height: "37px" }}
                                                                onChange={this.handleFITFile}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        {this.state.OldFitness!=""&&(
                                                            <label style={{ color: "red" }}>{this.state.OldFitness}</label>

                                                        )}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Document 1 :</label>
                                                            <input
                                                                type="file"
                                                                accept="application/pdf"
                                                                value={this.state.DOC1filename}
                                                                style={{ height: "37px" }}
                                                                onChange={this.handleDOC1File}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        {this.state.OldDocument1!=""&&(
                                                            <label style={{ color: "red" }}>{this.state.OldDocument1}</label>

                                                        )}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Document 2 :</label>
                                                            <input
                                                                type="file"
                                                                accept="application/pdf"
                                                                value={this.state.DOC2filename}
                                                                style={{ height: "37px" }}
                                                                onChange={this.handleDOC2File}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        {this.state.OldDocument2!=""&&(
                                                            <label style={{ color: "red" }}>{this.state.OldDocument2}</label>

                                                        )}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group" style={{ width: '100%' }}>
                                                            <label>Remarks :</label>
                                                            <textarea
                                                                rows="3"
                                                                style={{ height: "90px" }}
                                                                value={this.state.Remarks}
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        Remarks: e.target.value,
                                                                    });
                                                                }}
                                                                placeholder="Remarks"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" onSubmit={this.handleSubmit} className="btn btn-primary mr-2">
                                                    Submit
                                                </button>
                                                <button type="button" onClick={this.ClearData} className="btn btn-secondary">
                                                    Cancel
                                                </button>
                                            </div>
                                        </FormWithConstraints>
                                    </div>
                                }

                            </div>
                        }
                    </div>
                    {this.state.isFilterVisible&&
                        this.state.ViewStatus==="ViewStatus:true"&&
                        <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Vehicle No</label>
                                    {/* <input type="text" name="VehicleNo" required value={this.state.SearchVehicleNo} onChange={(e) => this.setState({ SearchVehicleNo: e.target.value })} className="form-control" placeholder="Enter Vehicle No" /> */}
                                    <input type="text" value={this.state.SearchVehicleNo} onChange={(e) => { this.setState({ SearchVehicleNo: e.target.value }) }} className="form-control" name="VehicleNo" placeholder="Enter Vehicle No" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Vehicle Type </label>
                                    <Select options={this.state.vehicletypes} value={this.state.SearchVehicleTypeID} onChange={(e) => this.setState({ SearchVehicleTypeID: e })} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Vehicle Brand </label>
                                    <Select options={this.state.vehiclebrands} value={this.state.SearchVehicleBrandID} onChange={(e) => this.setState({ SearchVehicleBrandID: e })} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Vehicle Model </label>
                                    <Select options={this.state.vehiclemodels} value={this.state.SearchVehicleModelID} onChange={(e) => this.setState({ SearchVehicleModelID: e })} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Vehicle Ownership </label>
                                    <Select options={this.state.vehicleownerships} value={this.state.SearchVehicleOwnerShipID} onChange={(e) => this.setState({ SearchVehicleOwnerShipID: e })} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Location </label>
                                    <Select options={this.state.parkinglocations} value={this.state.SearchParkingLocationID} onChange={(e) => this.setState({ SearchParkingLocationID: e })} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button type="button" onClick={this.OnSearchVehicleClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                <button type="button" onClick={this.OnSearchCancelVehicleClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-body">
                                    <MaterialTable columns={this.state.cols} data={this.state.data}
                                        detailPanel={[
                                            {
                                                icon: 'add', tooltip: 'Click here to see details', title: 'show',
                                                render: ({ rowData }) => {
                                                    return (
                                                        <div style={{ width: '100%', padding: '5px', paddingLeft: '35px', display: 'block' }}>
                                                            <table className="table table-light-dark" cellSpacing="0" rules="all" border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <td>RC Book</td>
                                                                        <td>Vehicle Insurance</td>
                                                                        <td>PUC</td>
                                                                        <td>Fitness</td>
                                                                        <td>Document1</td>
                                                                        <td>Document2</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <th>
                                                                        {
                                                                            (rowData.RCBook!=""&&rowData.RCBook!=null)?
                                                                                <a href={APP_URL1+rowData.RCBook} target="_blank">
                                                                                    <img src={APP_URL1+rowData.RCBook} alt="" style={{ width: '150px', height: '100px', objectFit: 'contain' }} />
                                                                                </a>
                                                                                :
                                                                                <img style={{ height: '100px' }} src={APP_URL1+"NoImage.png"} />
                                                                        }
                                                                    </th>
                                                                    <th>
                                                                        {
                                                                            (rowData.Insurance!=""&&rowData.Insurance!=null)?
                                                                                <a href={APP_URL2+rowData.Insurance} target="_blank">
                                                                                    <img src={APP_URL2+rowData.Insurance} alt="" style={{ width: '150px', height: '100px', objectFit: 'contain' }} />
                                                                                </a>
                                                                                :
                                                                                <img style={{ height: '100px' }} src={APP_URL2+"NoImage.png"} />
                                                                        }
                                                                    </th>
                                                                    <th>
                                                                        {
                                                                            (rowData.PUC!=""&&rowData.PUC!=null)?

                                                                                <a href={APP_URL3+rowData.PUC} target="_blank">
                                                                                    <img src={APP_URL3+rowData.PUC} alt="" style={{ width: '150px', height: '100px', objectFit: 'contain' }} />
                                                                                </a>
                                                                                :
                                                                                <img style={{ height: '100px' }} src={APP_URL3+"NoImage.png"} />
                                                                        }
                                                                    </th>
                                                                    <th>
                                                                        {
                                                                            (rowData.Fitness!=""&&rowData.Fitness!=null)?
                                                                                <a href={APP_URL4+rowData.Fitness} target="_blank">
                                                                                    <img src={APP_URL4+rowData.Fitness} alt="" style={{ width: '150px', height: '100px', objectFit: 'contain' }} />
                                                                                </a>
                                                                                :
                                                                                <img style={{ height: '100px' }} src={APP_URL4+"NoImage.png"} />
                                                                        }
                                                                    </th>
                                                                    <th>
                                                                        {
                                                                            (rowData.Document1!=""&&rowData.Document1!=null)?
                                                                                <a target='_blank'
                                                                                    href={APP_URL5+rowData.Document1}>
                                                                                    <img src="../Images/Pdfdemo.jpg" alt="" width="120" height="90" style={{ objectFit: 'contain' }}></img></a>
                                                                                :
                                                                                <img style={{ height: '100px' }} src={APP_URL5+"NoImage.png"} />
                                                                        }
                                                                    </th>
                                                                    <th>
                                                                        {
                                                                            (rowData.Document2!=""&&rowData.Document2!=null)?
                                                                                <a target='_blank'
                                                                                    href={APP_URL6+rowData.Document2}>
                                                                                    <img src="../Images/Pdfdemo.jpg" alt="" width="120" height="90" style={{ objectFit: 'contain' }}></img></a>
                                                                                :
                                                                                <img style={{ height: '100px' }} src={APP_URL6+"NoImage.png"} />
                                                                        }
                                                                    </th>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                }
                                            }
                                        ]}
                                        actions={[{
                                            hidden: this.state.UpdateStatus=="UpdateStatus:true"? false:true,
                                            icon: 'edit', tooltip: 'Edit', onClick: (e, r) => this.EditVehicleMaster(r.VehicleID)
                                        },
                                        {
                                            hidden: this.state.DeleteStatus=="DeleteStatus:true"? false:true,
                                            icon: 'delete', tooltip: 'Delete', onClick: (e, r) => this.CheckAlert(r.VehicleID,r.VehicleNo)
                                        }
                                        ]}
                                        options={{
                                            headerStyle: { color: 'black' }, toolbar: true, search: false,draggable:false,
                                            paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                        }}
                                        components={{
                                            Toolbar: props => (
                                                <div className="row" style={{ marginBottom: '2%' }}>
                                                    <div className="col-md-8 mobpb-5" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h3 className="tabletitle">View Vehicle</h3>

                                                    </div>
                                                    <div className="col-md-4 mobta-left " style={{ textAlign: 'right' }}>
                                                        {
                                                            this.state.AddStatus==="AddStatus:true"&&
                                                            <a onClick={this.onAddVehicleClick} className="btn btn-outline-primary font-weight-bolder mr-5 mobmr-1">
                                                                <span className="svg-icon svg-icon-md">
                                                                    <i id="btnAdd" className={this.state.iconAdd} />
                                                                </span>Add Vehicle</a>
                                                        }
                                                        {
                                                            this.state.ViewStatus==="ViewStatus:true"&&
                                                            <>
                                                                <a className="btn btn-outline-dark font-weight-bolder mr-5 mobmr-1" onClick={this.OnFilterClick}>
                                                                    <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                                </a>
                                                                {
                                                                    this.state.ExcelStatus==="ExcelStatus:true"&&
                                                                    <a className="btn btn-outline-success font-weight-bolder" onClick={this.state.data.length>0? this.ExcelClick:''}>
                                                                        <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                                    </a>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
            </>
        );
    }
}

export default withCookies(VehicleMaster);