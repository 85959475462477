import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { WithRouter } from "react-router";
import { connect } from "react-redux";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import { instanceOf } from 'prop-types';
import DatePicker from 'react-datepicker';
import { withCookies, Cookies } from 'react-cookie';
import XLSX from "xlsx-color";
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const headerTblStyle = { color: 'black' };
const exportToExcel = (columns, data) => {
    debugger;
    const wb = XLSX.utils.book_new();
    // for (const key of Object.getOwnPropertyNames(this.xlsExportObj)) {
    //   let ws: XLSX.WorkSheet;
    //   if (key === "marksSummary") {
    //     ws = XLSX.utils.json_to_sheet(this.xlsExportObj[key]);
    //   } else {
    //     ws = XLSX.utils.json_to_sheet(this.xlsExportObj[key]);

    //     const merge = [
    //       { s: { r: 0, c: 1 }, e: { r: 0, c: 3 } },
    //       { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
    //     ];
    //     ws["!merges"] = merge;
    //   }
    //   XLSX.utils.book_append_sheet(wb, ws, key);
    // }

}
class AdminReport extends Component {
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.tableRef = React.createRef();
        this.state = {
            isLoading: false, activeItem: 'adminreport',
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [], vehicledata: [], StartDate: new Date(), EndDate: new Date(),
            IsTotalValid: false, IsChanged: false,
            faicon: "fa fa-plus", isFilterVisible: false, iconFilter: "fa fa-plus",
        }
    }
    componentDidMount() {
        this.GetAdminReport();
    }
    GetAdminReport() {
        var CompanyID = "";
        var ClientID = "";
        var StartDate = "";
        var EndDate = "";

        if (this.props.allCookies.MainCompanyID !== undefined) {
            CompanyID = this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID !== undefined) {
            ClientID = this.props.allCookies.MainClientID
        }
        var data = "";
        data = JSON.stringify({
            CompanyID: CompanyID,
            ClientID: ClientID,

            StartDate: moment(this.state.StartDate, "DD-MM-YYYY"),
            //  StartDate: "01-05-2023",
            EndDate: moment(this.state.EndDate, "DD-MM-YYYY"),
            // EndDate: "06-05-2023",
        });


        var config = {
            method: 'POST',
            url: services.API_URL + "Report/getAdminReport",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                debugger;
                this.setState({ data: response.data.data })
                this.setState({ vehicledata: response.data.vehiclecountdata })

                response?.data?.vehiclecountdata?.map((value, index) => {
                    //var name = value.DisplayName
                    this.setState({ [value.DisplayName + "Total"]: "" });
                    this.setState({ [value.DisplayName]: "" });
                })
                this.setState({ isLoading: false })
            }
            else {
                this.setState({ isLoading: false })
                this.setState({ data: [] })
                this.setState({ vehicledata: [] })
            }

        }, error => { })
    }
    onStartDateChange = (e) => {
        this.setState({ StartDate: e });
        this.setState({ EndDate: e });
    }
    OnFilterClick = (e) => {
        debugger;
        e.preventDefault();
        if (this.state.isFilterVisible === false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }
    }
    ExcelClick = () => {
        exportToExcel(this.state.vehicledata, this.state.data)
    }
    onSearchClick = (e) => {
        e.preventDefault();
        this.setState({ isLoading: true }, () => { console.log("data") })
        this.GetAdminReport();

    }
    OnSearchCancelClick = (e) => {
        this.setState({
            data: [], vehicledata: [], StartDate: new Date(), EndDate: new Date(),
            IsTotalValid: false, IsChanged: false,

        }, () => { this.GetAdminReport(); });


    }
    render() {
        var Total = 0;
        var GrandTotal = 0;
        var MonthTotal = 0;
        const { cookies } = this.props;

        return (
            <>

                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>

                    <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>Start Date </label>
                                <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.StartDate} onChange={this.onStartDateChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group formgroupcss">
                                <label>End Date </label>
                                <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.EndDate} minDate={this.state.StartDate} autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.EndDate} onChange={(e) => {
                                    this.setState({ EndDate: e });
                                }} />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <button disabled={this.state.isLoading == true ? true : false} type="button" onClick={this.onSearchClick} className="btn btn-primary mt-12 mr-3 mobmt-1">
                                {this.state.isLoading ? <><i id="btnsave" className="fa fa-spinner" /> Loading..</> : <><i id="btnsave" className="fa fa-search" />Search</>}
                            </button>
                            <button type="button" onClick={this.OnSearchCancelClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>

                            <a style={{ display: 'none' }} className="btn btn-outline-success font-weight-bolder ml-4" onClick={this.state.data.length > 0 ? this.ExcelClick : ''}>
                                <i id="btnExcel" className="fa fa-file-excel" /> Excel
                            </a>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-body">

                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="download-table-xls-button btn btn-success"
                                        table="table-to-xls"
                                        filename="AdminReport"
                                        sheet="AdminReport"
                                        buttonText="Excel" />
                                    <table id="table-to-xls" ref={this.tableRef} className="table table-bordered fonttable admintable" style={{ border: '1px solid' }}>
                                        <thead style={{ textAlign: 'center' }}>
                                            <td colSpan={9} style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid', borderTop: '0' }}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h3><span>Gujarat Project Vehicle Details</span></h3>
                                                    </div>

                                                </div>
                                            </td>
                                        </thead>
                                        <tbody style={{ textAlign: 'center' }} >
                                            <tr>
                                                <td colSpan={9}>
                                                    <div className="row" style={{ textAlign: 'left' }}>
                                                        {
                                                            this?.state?.vehicledata?.map((value, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-md-2">
                                                                            <span>{value.DisplayName}</span> -
                                                                            {this.state.vehicledata.length != index + 1 ?
                                                                                <span>{value.VehicleCount} Vehicles , </span> : <span>{value.VehicleCount} Vehicles</span>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                );

                                                            })
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid', backgroundColor: 'darkgray' }} className="reporttd"><b>Date</b></td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid', backgroundColor: 'darkgray' }} className="reporttd"><b>Vehicle</b></td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid', backgroundColor: 'darkgray' }} className="reporttd"><b>Location (Client)</b></td>
                                                <td style={{ textAlign: 'center', border: '1px solid', backgroundColor: 'darkgray' }} ><b>Shift</b>
                                                    <table style={{ width: '100%', marginTop: '5%' }}>
                                                        <tr>
                                                            <td style={{ width: '33%', textAlign: 'center', border: '1px solid', backgroundColor: 'darkgray' }}><b>1st</b></td>
                                                            <td style={{ width: '33%', textAlign: 'center', border: '1px solid', backgroundColor: 'darkgray' }}><b>2nd</b></td>
                                                            <td style={{ width: '33%', textAlign: 'center', border: '1px solid', backgroundColor: 'darkgray' }}><b>3rd</b></td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid', backgroundColor: 'darkgray' }} className="reporttd"><b>Total</b></td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid', backgroundColor: 'darkgray' }} className="reporttd"><b>Referred Back</b></td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid', backgroundColor: 'darkgray' }} className="reporttd"><b>Total</b></td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid', backgroundColor: 'darkgray' }} className="reporttd"><b>Month to Date</b></td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid', backgroundColor: 'darkgray' }} className="reporttd"><b>Remarks</b></td>
                                            </tr>
                                            {
                                                this.state.IsChanged == false &&
                                                this?.state?.data?.map((Mainvalue, indexfinal) => {

                                                    GrandTotal = 0;
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid' }}>
                                                                    <span>{Mainvalue.ActualBookingDate}</span>
                                                                </td>
                                                                <td >
                                                                    <span>{Mainvalue?.ClientDetail?.map((value, index) => {
                                                                        Total = 0;
                                                                        return (
                                                                            <table style={{ width: '100%' }}>
                                                                                {value?.BookingCountDetail?.map((values, index) => {
                                                                                    return (
                                                                                        <tr >
                                                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid' }}>{values.VehicleNo}</td>
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </table>
                                                                        );
                                                                    })}</span>
                                                                </td>
                                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>

                                                                    {/* {value.ClientDetail.map((value, index) => {

                                                                            return (
                                                                                <tr><td>
                                                                                    <span>{value.DisplayName}</span>
                                                                                </td></tr>
                                                                            );
                                                                        })} */}
                                                                    {Mainvalue?.ClientDetail?.map((value, index) => {

                                                                        return (


                                                                            <table style={{ width: '100%' }}>
                                                                                {value?.BookingCountDetail?.map((values, index) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid' }}><span>{value.DisplayName}</span></td>
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </table>

                                                                        );
                                                                    })}

                                                                </td>
                                                                <td>
                                                                    <span>{Mainvalue?.ClientDetail?.map((value, index) => {

                                                                        return (
                                                                            <div className="row" style={{ marginLeft: '0', marginRight: '0' }}>
                                                                                <table style={{ width: '100%' }}>
                                                                                    {value?.BookingCountDetail?.map((values, index) => {
                                                                                        return (
                                                                                            <tr >
                                                                                                <td style={{ width: '33%', textAlign: 'center', border: '1px solid' }}><span><div className="col-md-12" >{values.FirstShift}</div></span></td>
                                                                                                <td style={{ width: '33%', textAlign: 'center', border: '1px solid' }}><span><div className="col-md-12" >{values.SecondShift}</div></span></td>
                                                                                                <td style={{ width: '33%', textAlign: 'center', border: '1px solid' }}><span><div className="col-md-12" >{values.ThirdShift}</div></span></td>
                                                                                                {/* <td style={{ width: '33%' }}>{values.FirstShift}</td>
                                                                                                <td style={{ width: '33%' }}>{values.SecondShift}</td>
                                                                                                <td style={{ width: '33%' }}>{values.ThirdShift}</td> */}
                                                                                            </tr>
                                                                                        );
                                                                                    })}
                                                                                </table>
                                                                            </div>
                                                                        );
                                                                    })}</span>
                                                                </td>
                                                                <td>
                                                                    <span>{Mainvalue?.ClientDetail?.map((value, index) => {

                                                                        return (
                                                                            <table style={{ width: '100%' }}>
                                                                                {value?.BookingCountDetail?.map((values, index) => {
                                                                                    return (
                                                                                        <tr >
                                                                                            {/* <td>{values.FirstShift+values.SecondShift+values.ThirdShift}</td> */}
                                                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid' }}>{values.TotalCount}</td>
                                                                                            {/* <span style={{ display: 'none' }}> {Total+=values.FirstShift+values.SecondShift+values.ThirdShift}</span> */}

                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </table>
                                                                        );
                                                                    })}</span>
                                                                </td>
                                                                <td>
                                                                    <span>{Mainvalue?.ClientDetail?.map((value, index) => {
                                                                        return (
                                                                            <table style={{ width: '100%' }}>
                                                                                {value?.BookingCountDetail?.map((values, index) => {
                                                                                    return (
                                                                                        <tr >
                                                                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', border: '1px solid' }}>{values.ReferredBack}</td>
                                                                                            {/* <span style={{ display: 'none' }}>{Total=Total-values.ReferredBack}</span> */}
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </table>
                                                                        );
                                                                    })}</span>
                                                                </td>
                                                                <td>
                                                                    {/* <span>{Total}</span> */}
                                                                    <span>{Mainvalue?.ClientDetail?.map((value, indexclient) => {
                                                                        var SubTotal = 0;
                                                                        return (
                                                                            <table style={{ width: '100%' }}>
                                                                                {value?.BookingCountDetail?.map((values, index) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td style={{ fontWeight: 'bold', textAlign: 'center', border: '1px solid' }}>
                                                                                                <span>{index == 0 ? value.Total : ''}</span>
                                                                                                {/* <span>{value.BookingCountDetail.length==index+1? SubTotal:''}</span> */}
                                                                                            </td>

                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </table>
                                                                        );
                                                                    })}</span>
                                                                </td>
                                                                <td>
                                                                    <span>{Mainvalue?.ClientDetail?.map((value, index) => {

                                                                        return (
                                                                            <table style={{ width: '100%' }}>
                                                                                {value?.BookingCountDetail?.map((values, index) => {


                                                                                    return (
                                                                                        <tr>
                                                                                            <td style={{ fontWeight: 'bold', textAlign: 'center', border: '1px solid' }}>
                                                                                                <span>{index == 0 ? value.MonthToDateTotal : ''}</span>
                                                                                            </td>

                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </table>
                                                                        );
                                                                    })}</span>
                                                                </td>
                                                                <td>
                                                                    <span>{Mainvalue?.ClientDetail?.map((value, index) => {
                                                                        return (
                                                                            <table style={{ width: '100%' }}>
                                                                                {value?.BookingCountDetail?.map((values, index) => {
                                                                                    return (
                                                                                        <tr >
                                                                                            <td style={{
                                                                                                textAlign: 'center', verticalAlign: 'middle', border: '1px solid',
                                                                                                width: '250px',
                                                                                                whiteSpace: 'normal',
                                                                                                wordBreak: 'break-word'
                                                                                            }}>{values.BookingVehicleRemarks}</td>
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </table>
                                                                        );
                                                                    })}</span>
                                                                </td>
                                                            </tr >
                                                            <tr bgcolor="#d3d3d3" style={{ background: 'lightgray', fontWeight: '500' }}>
                                                                <td bgcolor="#d3d3d3" colSpan={5} style={{ textAlign: 'right', background: 'lightgray', border: '1px solid' }}>
                                                                    <span><b>Total</b></span>
                                                                </td>
                                                                <td></td>
                                                                <td style={{ fontWeight: 'bold', textAlign: 'center', border: '1px solid' }}>
                                                                    <span>{Mainvalue.MainTotal}</span>
                                                                </td>
                                                                <td style={{ fontWeight: 'bold', textAlign: 'center', border: '1px solid' }}>
                                                                    {Mainvalue.MainMonthToDateTotal}
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }

}
export default withCookies(AdminReport);