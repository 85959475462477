import React, { Component } from "react";
import services from "../services/services";
import Swal from "sweetalert2";
import MaterialTable from '@material-table/core';
import '../style.css';
import Select from 'react-select'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import moment from 'moment';
import {
    Async,
    FieldFeedback,
    FieldFeedbacks,
    FormWithConstraints,
    Input
} from 'react-form-with-constraints';
import { id } from "date-fns/locale";

import XLSX from "xlsx-color";

const headerTblStyle={ color: 'black' };
const exportToExcel=(columns, data) => {
    debugger;
    const header=["Sr.No", "UserCode", "UserType", "FullName", "PersonalMobileNo", "VehicleNo", "UserName", "Password", "DateOfBirth", "UserZoneDetail", "UserDistrictDetail", "UserMuncipalityDetail", "CreatedDate"];
    const headertemp=["Sr.No", "Employee Code", "User Type", "Mobile No", "Vehicle No", "Mobile No", "UserName", "Birth Date", "Zone", "District", "Municipality", "Date"];
    debugger
    const compatibleData=data.map((row, i) => {
        const obj={};
        header.forEach((col, index) => {
            // console.log("=====>Col---->", col);
            if (col==="Sr.No") {
                obj[col]=i+1;
            }
            else if (col=="UserCode") {
                obj["Employee Code"]=row[col];
            }
            else if (col=="UserType") {
                obj["User Type"]=row[col];
            }
            else if (col=="FullName") {
                obj["Full Name"]=row[col];
            }
            else if (col=="PersonalMobileNo") {
                obj["Mobile No"]=row[col];
            }
            else if (col=="VehicleNo") {
                obj["Vehicle No"]=row[col];
            }
            else if (col=="DateOfBirth") {
                obj["Birth Date"]=row[col];
            }
            else if (col=="UserZoneDetail") {
                var ZoneID="";
                if (row["UserZoneDetail"]!=null) {
                    for (let i=0; i<row["UserZoneDetail"].length; i++) {
                        ZoneID+=row["UserZoneDetail"][i]["ZoneName"]+","
                    }
                    ZoneID=ZoneID.substring(0, ZoneID.length-1);
                }
                obj["Zone"]=ZoneID;
            }
            else if (col=="UserDistrictDetail") {
                var DistrictID="";
                if (row["UserDistrictDetail"]!=null) {
                    for (let i=0; i<row["UserDistrictDetail"].length; i++) {
                        DistrictID+=row["UserDistrictDetail"][i]["DistrictName"]+","
                    }
                    DistrictID=DistrictID.substring(0, DistrictID.length-1);
                }
                obj["District"]=DistrictID;
            }
            else if (col=="UserMuncipalityDetail") {
                var MunicipalityID="";
                if (row["UserMuncipalityDetail"]!=null) {
                    for (let i=0; i<row["UserMuncipalityDetail"].length; i++) {
                        MunicipalityID+=row["UserMuncipalityDetail"][i]["MuncipalityName"]+","
                    }
                    MunicipalityID=MunicipalityID.substring(0, MunicipalityID.length-1);
                }
                obj["Municipality"]=MunicipalityID;
            }
            else if (col=="CreatedDate") {
                obj["Date"]=row[col];
            }
            else {
                obj[col]=row[col];
            }
        });
        return obj;
    });

    const ws1=XLSX.utils.json_to_sheet(compatibleData, { headertemp });
    let wb=XLSX.utils.book_new({ Sheets: { data: ws1 }, SheetNames: [""] });
    // XLSX.utils.sheet_add_aoa(ws1, header, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws1, '');
    ws1.A1.s={
        font: { sz: 14, bold: true, color: '#FF00FF' }
    };

    ws1['!cols']=[
        { width: 6 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 50 },
    ];
    let cell={
        font: { bold: true, color: { rgb: "f7f4ef" } },
        fill: { patternType: 'solid', fgColor: { rgb: "54153255" } },
        alignment: { wrapText: true, horizontal: 'center' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } }
        }
    }
    ws1['A1'].s=cell;
    ws1['B1'].s=cell;
    ws1['C1'].s=cell;
    ws1['D1'].s=cell;
    ws1['E1'].s=cell;
    ws1['F1'].s=cell;
    ws1['G1'].s=cell;
    ws1['H1'].s=cell;
    ws1['I1'].s=cell;
    ws1['J1'].s=cell;
    ws1['K1'].s=cell;
    ws1['L1'].s=cell;
    ws1['M1'].s=cell;


    var RowCont={
        alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
        border: {
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            bottom: { style: 'thin', color: { rgb: '000000' } },
        }
    }
    data.map((row, i) => {
        var ins=i+2;
        ws1['A'+ins].s=RowCont;
        ws1['B'+ins].s=RowCont;
        ws1['C'+ins].s=RowCont;
        ws1['D'+ins].s=RowCont;
        ws1['E'+ins].s=RowCont;
        ws1['F'+ins].s=RowCont;
        ws1['G'+ins].s=RowCont;
        ws1['H'+ins].s=RowCont;
        ws1['I'+ins].s=RowCont;
        ws1['J'+ins].s=RowCont;
        ws1['K'+ins].s=RowCont;
        ws1['L'+ins].s=RowCont;
        ws1['M'+ins].s=RowCont;

        return true;
    });
    XLSX.utils.book_append_sheet(wb, ws1, "UserList");
    XLSX.writeFile(wb, "UserList_"+moment().format('DD-MM-YYYY_HH:mm')+".xlsx");
}
const { REACT_APP_USERIMAGE_URL }=process.env;
class User extends Component {
    static propTypes={
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies }=props;
        this.state={
            name: cookies.get('name')||'Ben'
        };
        this.state={
            AddStatus: "", UpdateStatus: "", DeleteStatus: "", ViewStatus: "", ExcelStatus: "",
            data: [], usertypes: [], permanentcountries: [], permanentstates: [], permanentcities: [], localcountries: [], localstates: [], localcities: [], vehicles: [], UserDistrictCheckData: [], UserMuncipalityCheckData: [], zones: [{ 'id': 'No Zones', 'value': 'No Zones' }], districts: [], municipalities: [], EditDistricts: [],
            UserTypeID: { 'label': '--Select User Type--', 'value': '--Select User Type--' },
            SearchUserTypeID: { 'label': '--Select User Type--', 'value': '--Select User Type--' },
            PermanentCountryID: { 'label': '--Select Permanent Country--', 'value': '--Select Permanent Country--' },
            PermanentStateID: { 'label': '--Select Permanent State--', 'value': '--Select Permanent State--' },
            PermanentCityID: { 'label': '--Select Permanent City--', 'value': '--Select Permanent City--' },
            LocalCountryID: { 'label': '--Select Local Country--', 'value': '--Select Local Country--' },
            LocalStateID: { 'label': '--Select Local State--', 'value': '--Select Local State--' },
            LocalCityID: { 'label': '--Select Local City--', 'value': '--Select Local City--' },
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            UserTypeErrMsg: "", UserNameErrMsg: "", PasswordErrMsg: "", ZoneErrMsg: "", DistrictErrMsg: "", MunicipalityErrMsg: "",
            VehicleErrMsg: "",
            isZoneChecked: false, ZoneDataAvailable: true, NoZoneVisibleMsg: false,
            isDistrictAvailable: false, DistrictDataAvailable: false, NoDistrictVisibleMsg: true,
            isMunicipalityAvailable: false, MunicipalityDataAvailable: false, NoMunicipalityVisibleMsg: true, IsVehicleType: false,
            IsUserNamePasswordVisible: false, isEdit: false,
            UserID: "", CompanyID: "", ClientID: "", UserType: "", UserCode: "", FirstName: "", MiddleName: "",
            LastName: "", NameInHindi: "", GuardianName: "", UserName: "", Password: "", DateOfBirth: "", PermanentAddress: "",
            PersonalEmail: "", PersonalMobileNo: "", LocalAddress: "", OfficeMobileNo: "", OfficeEmail: "", LicenseNo: "", IssuingAuthority: "",
            ValidUpto: "", Note: "", Guarantor: "", EntryByUserType: "", EntryByUserID: "", ZoneData: [{ ZoneID: "", }],
            DistrictData: [{ DistrictID: "", }], MuncipalityData: [{ MuncipalityID: "", }],
            OldUserImageName: "", UserImage: "", UserImageFile: [],
            TempDistrictID: "", TempMunicipalitiesID: "", SearchUserName: "", SearchUserMobileNo: "",
            AddUserVisible: false, iconAdd: "fa fa-plus", isFilterVisible: false, iconFilter: "fa fa-plus",
            cols: [
                { title: 'Sr.No', width: '5%', field: 'tableData.id', headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.tableData.id+1}</p>) } },
                { title: 'Status', width: '7%', field: 'IsActive', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<BootstrapSwitchButton onlabel='Yes' offlabel='No' onChange={this.onStatusChange(rowData.UserID)} checked={rowData.IsActive} size="md" onstyle="success" offstyle="danger" />) } },
                { title: (<div style={{ width: '100%' }}><span>Employee Code</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>User Type</span></div>), width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.UserCode}<hr />{rowData.UserType}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>Full Name</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Mobile No</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Vehicle No</span></div>), width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.FullName}<hr />{rowData.PersonalMobileNo}<hr />{rowData.VehicleNo}</p>) } },
                { title: (<div style={{ width: '100%' }}><span>UserName</span><hr style={{ marginTop: '5px', marginBottom: '5px' }} /><span>Password</span></div>), width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.UserName}<hr />{rowData.Password}</p>) } },
                { title: 'Birth Date', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.DateOfBirth}</p>) } },
                { title: 'Image', width: '10%', field: 'BannerImage', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<img src={(rowData.UserImage==null? process.env.REACT_APP_NOIMAGE_URL:process.env.REACT_APP_USERIMAGE_URL+rowData.UserImage)} alt="" style={{ objectFit: 'cover', width: '100px', height: '100px' }} />) } },
                { title: 'Date', width: '20%', removable: false, headerStyle: headerTblStyle, render: rowData => { return (<p style={{ marginBottom: '1px' }}>{rowData.CreatedDate}</p>) } },
            ]
        }
    }
    componentDidMount() {
        this.GetUser();
        this.GetUserType();
        this.GetPermanentCountry();
        this.onPermanentCountryChange(1);
        this.setState({ PermanentCountryID: { 'label': 'India', 'value': '1' } })
        this.GetLocalCountry();
        this.onLocalCountryChange(1);
        this.setState({ LocalCountryID: { 'label': 'India', 'value': '1' } })
        this.GetZone();
        this.GetVehicle();
    }
    UpdateEditStatus() {
        this.setState({ isEdit: true });
    }
    // Functions
    EditUser(id) {
        this.setState({ AddUserVisible: true }, () => { this.UpdateEditStatus() });
        var CompanyID="";
        var ClientID="";
        var UserID="";
        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID!==undefined) {
            UserID=this.props.allCookies.UserID
        }

        if (CompanyID!==""&&ClientID!=="") {
            var data=JSON.stringify({
                "UserID": id,
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "UserTypeID": "",
                "MobileNo": "",
                "CountryID": "",
                "StateID": "",
                "CityID": ""
            });
            var config={
                method: 'POST',
                url: services.API_URL+"User/getUserData",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                debugger;

                if (response.data.data[0].UserType=="Vehicle") {
                    this.setState({ IsVehicleType: true });
                    this.setState({ VehicleID: { 'label': response.data.data[0].VehicleNo, 'value': response.data.data[0].VehicleID } }, () => { this.ValidateVehicle() });

                }
                else {
                    this.setState({ IsVehicleType: false });
                }
                if (response.data.data[0].UserType=="Supervisor") {
                    this.setState({ IsUserNamePasswordVisible: true }, () => { this.ShowUserPasswordVisibility() });
                }
                else if (response.data.data[0].UserType=="Sub Admin") {
                    this.setState({ IsUserNamePasswordVisible: true }, () => { this.ShowUserPasswordVisibility() });
                }
                else {
                    this.setState({ IsUserNamePasswordVisible: false }, () => { this.HideUserPasswordVisibility() });
                }

                var data=JSON.stringify({
                    "CountryID": response.data.data[0].PermanentCountryID
                });

                var config={
                    method: 'POST',
                    url: services.API_URL+"State/getState",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    debugger;

                    if (response.data.status) {
                        this.setState({ permanentstates: response.data.data.map(item => ({ value: item.StateID, label: item.StateName })) });
                    }
                    else {
                        this.setState({ permanentstates: [] });
                    }
                }, error => { })

                var data=JSON.stringify({
                    "StateID": response.data.data[0].PermanentStateID
                });

                var config={
                    method: 'POST',
                    url: services.API_URL+"City/getCity",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    debugger;
                    if (response.data.status) {
                        this.setState({ permanentcities: response.data.data.map(item => ({ value: item.CityID, label: item.CityName })) });
                    }
                    else {
                        this.setState({ permanentcities: [] });
                    }

                }, error => { })

                debugger;
                var dateString=response.data.data[0].DateOfBirth;
                var date="";
                if (dateString!=null) {
                    var dateMomentObject=moment(dateString, "DD-MM-YYYY");
                    date=dateMomentObject.toDate();
                }



                this.setState({
                    UserID: response.data.data[0].UserID,
                    UserCode: response.data.data[0].UserCode,
                    UserTypeID: { 'label': response.data.data[0].UserType, 'value': response.data.data[0].UserTypeID },
                    FirstName: response.data.data[0].FirstName,
                    MiddleName: response.data.data[0].MiddleName,
                    LastName: response.data.data[0].LastName,
                    NameInHindi: response.data.data[0].NameInHindi,
                    GuardianName: response.data.data[0].GuardianName,
                    OldUserImageName: response.data.data[0].UserImage,
                    UserName: response.data.data[0].UserName,
                    Password: response.data.data[0].Password,
                    DateOfBirth: date,
                    PermanentCountryID: { 'label': response.data.data[0].PermanentCountryName, 'value': response.data.data[0].PermanentCountryID },
                    PermanentStateID: { 'label': response.data.data[0].PermanentStateName, 'value': response.data.data[0].PermanentStateID },
                    PermanentCityID: { 'label': response.data.data[0].PermanentCityName, 'value': response.data.data[0].PermanentCityID },
                    LocalCountryID: { 'label': response.data.data[0].LocalCountryName, 'value': response.data.data[0].LocalCountryID },
                    LocalStateID: { 'label': response.data.data[0].LocalStateName, 'value': response.data.data[0].LocalStateID },
                    LocalCityID: { 'label': response.data.data[0].PermanentCityName, 'value': response.data.data[0].LocalCityID },
                    PermanentAddress: response.data.data[0].PermanentAddress,
                    PersonalEmail: response.data.data[0].PersonalEmail,
                    PersonalMobileNo: response.data.data[0].PersonalMobileNo,
                    LocalAddress: response.data.data[0].LocalAddress,
                    OfficeMobileNo: response.data.data[0].OfficeMobileNo,
                    OfficeEmail: response.data.data[0].OfficeEmail,
                    LicenseNo: response.data.data[0].LicenseNo,
                    IssuingAuthority: response.data.data[0].IssuingAuthority,
                    ValidUpto: response.data.data[0].ValidUpto,
                    Note: response.data.data[0].Note,
                    Guarantor: response.data.data[0].Guarantor,
                    UserDistrictCheckData: response.data.data[0].UserDistrictDetail,
                    UserMuncipalityCheckData: response.data.data[0].UserMuncipalityDetail
                });

                var Zid="";
                let zones=this.state.zones;
                for (let i=0; i<zones.length; i++) {
                    zones[i].isZoneChecked=false;

                }
                let StoredZones=response.data.data[0].UserZoneDetail
                for (let i=0; i<zones.length; i++) {
                    debugger;
                    for (let j=0; j<StoredZones.length; j++) {
                        // const element = array[index];
                        if (zones[i].id==StoredZones[j].ZoneID) {
                            debugger;
                            zones[i].isZoneChecked=true;
                            Zid+=zones[i].id+",";
                        }
                        else {

                        }

                    }
                    // const element = zones[index];
                }
                this.setState({ zones: zones });
                Zid=Zid.substring(0, Zid.length-1);
                var CompanyID="";
                var ClientID="";
                var UserType="";
                var UserID="";
                var APIName="";

                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }

                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }

                if (UserType=="Supervisor") {
                    APIName="User/getUserDistrictDetail";
                }
                else {
                    APIName="District/getDistrictDropDownData";
                    UserID="";
                }

                debugger;
                var data=JSON.stringify({
                    "DistrictID": "",
                    "ZoneID": Zid,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "UserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+APIName,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    debugger;
                    if (response.data.status) {
                        this.setState({ districts: response.data.data.map(item => ({ id: item.DistrictID, value: item.DistrictName, isDistrictChecked: false })) });

                        this.setState({ NoDistrictVisibleMsg: false });
                        var DID="";
                        for (let index=0; index<response.data.data.length; index++) {
                            DID+=response.data.data[index].DistrictID+",";
                        }
                        DID=DID.substring(0, DID.length-1);
                        this.setState({ DistrictDataAvailable: true }, () => { this.CheckDistrictData(); });
                        var CompanyID="";
                        var ClientID="";
                        var UserType="";
                        var UserID="";
                        var APIName="";

                        if (this.props.allCookies.MainCompanyID!==undefined) {
                            CompanyID=this.props.allCookies.MainCompanyID;
                        }
                        if (this.props.allCookies.MainClientID!==undefined) {
                            ClientID=this.props.allCookies.MainClientID
                        }

                        if (this.props.allCookies.PanelUserType!==undefined) {
                            UserType=this.props.allCookies.PanelUserType
                        }
                        if (this.props.allCookies.UserID!==undefined) {
                            UserID=this.props.allCookies.UserID
                        }

                        if (UserType=="Supervisor") {
                            APIName="User/getUserMuncipalityDetail";
                        }
                        else {
                            APIName="Muncipality/getMuncipalityDropDownData";
                            UserID="";
                        }

                        var data=JSON.stringify({
                            "MuncipalityID": "",
                            "DistrictID": DID,
                            "CompanyID": CompanyID,
                            "ClientID": ClientID,
                            "UserID": UserID
                        });
                        var config={
                            method: 'POST',
                            url: services.API_URL+APIName,
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data: data
                        };
                        services.JsonValue(config).then(response => {
                            debugger;
                            if (response.data.status) {
                                this.setState({ municipalities: response.data.data.map(item => ({ id: item.MuncipalityID, value: item.MuncipalityName, isMunicipalityChecked: false })) });

                                this.setState({ NoMunicipalityVisibleMsg: false });
                                var MID="";

                                this.setState({ MunicipalityDataAvailable: true }, () => { this.CheckMunicipalityData() });
                            }
                            else {
                                this.setState({ municipalities: [] });
                                this.setState({ isMunicipalityChecked: false });
                                this.setState({ MunicipalityDataAvailable: false });
                                this.setState({ NoMunicipalityVisibleMsg: true });
                            }

                        }, error => { })

                    }
                    else {
                        this.setState({ districts: [] });
                        this.setState({ isDistrictChecked: false });
                        this.setState({ DistrictDataAvailable: false });
                        this.setState({ NoDistrictVisibleMsg: true });

                        this.setState({ municipalities: [] });
                        this.setState({ isMunicipalityChecked: false });
                        this.setState({ MunicipalityDataAvailable: false });
                        this.setState({ NoMunicipalityVisibleMsg: true });
                    }

                }, error => { })

                debugger;


            });
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }
    }
    CheckDistrictData() {
        debugger;
        let districts=this.state.districts;
        let StoredDistricts=this.state.UserDistrictCheckData;
        for (let i=0; i<districts.length; i++) {
            debugger;
            for (let j=0; j<StoredDistricts.length; j++) {
                // const element = array[index];
                if (districts[i].id==StoredDistricts[j].DistrictID) {
                    debugger;
                    districts[i].isDistrictChecked=true;
                }

            }
            // const element = zones[index];
        }
        this.setState({ districts: districts });
    }
    CheckMunicipalityData() {
        debugger;
        let municipalities=this.state.municipalities;
        let StoredMunicipalities=this.state.UserMuncipalityCheckData;
        for (let i=0; i<municipalities.length; i++) {
            debugger;
            for (let j=0; j<StoredMunicipalities.length; j++) {
                // const element = array[index];
                if (municipalities[i].id==StoredMunicipalities[j].MuncipalityID) {
                    debugger;
                    municipalities[i].isMunicipalityChecked=true;
                }

            }
            // const element = zones[index];
        }
        this.setState({ municipalities: municipalities });
    }
    CheckEditDistricts() {
        let OldDistricts=this.state.EditDistricts;
        let districts=this.state.districts;
        for (let i=0; i<districts.length; i++) {
            //const element = array[index];


        }
    }
    GetOnDistrictChange(id) {
        var CompanyID="";
        var ClientID="";
        var UserType="";
        var UserID="";
        var APIName="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }

        if (this.props.allCookies.PanelUserType!==undefined) {
            UserType=this.props.allCookies.PanelUserType
        }
        if (this.props.allCookies.UserID!==undefined) {
            UserID=this.props.allCookies.UserID
        }

        if (UserType=="Supervisor") {
            APIName="User/getUserDistrictDetail";
        }
        else {
            APIName="District/getDistrictDropDownData";
            UserID="";
        }

        var data=JSON.stringify({
            "DistrictID": "",
            "ZoneID": id,
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "UserID": UserID
        });
        var config={
            method: 'POST',
            url: services.API_URL+APIName,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                this.setState({ districts: response.data.data.map(item => ({ id: item.DistrictID, value: item.DistrictName, isDistrictChecked: false })) });
                this.setState({ DistrictDataAvailable: true });
                this.setState({ NoDistrictVisibleMsg: false });
            }
            else {
                this.setState({ districts: [] });
                this.setState({ isDistrictChecked: false });
                this.setState({ DistrictDataAvailable: false });
                this.setState({ NoDistrictVisibleMsg: true });
            }

        }, error => { })
    }
    GetUser() {
        var CompanyID="";
        var ClientID="";
        var SearchUserTypeID="";
        var SearchMobileNo=this.state.SearchUserMobileNo
        var SearchFullName=this.state.SearchUserName

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.state.SearchUserTypeID.value!="--Select User Type--") {
            SearchUserTypeID=this.state.SearchUserTypeID.value;
        }
        else {
            SearchUserTypeID="";
        }
        if (CompanyID!=""&&ClientID!="") {
            var data=JSON.stringify({
                "UserID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "UserTypeID": SearchUserTypeID,
                "MobileNo": SearchMobileNo,
                "FullName": SearchFullName,
                "CountryID": "",
                "StateID": "",
                "CityID": ""
            });
            var config={
                method: 'POST',
                url: services.API_URL+"User/getUserData",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                if (response.data.status) {
                    if (this.state.ViewStatus==="ViewStatus:true") {
                        this.setState({ data: response.data.data })
                    }
                    else {
                        this.setState({ data: [] })
                    }
                }
                else {
                    this.setState({ data: [] })
                }

            }, error => { })
        }
        else {
            if (CompanyID==""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Company & Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID!=""&&ClientID=="") {
                Swal.fire({
                    title: 'Please Select Client', icon: "error", timer: 1500
                });
            }
            else if (CompanyID==""&&ClientID!="") {
                Swal.fire({
                    title: 'Please Select Company', icon: "error", timer: 1500
                });
            }
            else {
                Swal.fire({
                    title: 'Something went wrong', icon: "error", timer: 1500
                });
            }
        }
    }
    GetUserType() {
        var CompanyID="";
        var ClientID="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (CompanyID!=""&&ClientID) {
            var data=JSON.stringify({
                "UserTypeID": "",
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "IsActive": "true",
                "IsDelete": "false"
            });
            var config={
                method: 'POST',
                url: services.API_URL+"UserType/getUserType",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                debugger;
                this.setState({ usertypes: response.data.data.map(item => ({ value: item.UserTypeID, label: item.UserType })) });
            }, error => { })
        }
        else {

        }

    }
    GetVehicle() {
        var CompanyID="";


        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }

        if (CompanyID!="") {
            var data=JSON.stringify({
                "VehicleID": "",
                "CompanyID": CompanyID
            });
            var config={
                method: 'POST',
                url: services.API_URL+"Vehicle/getVehicle",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                debugger;
                this.setState({ vehicles: response.data.data.map(item => ({ value: item.VehicleID, label: item.VehicleNo })) });
            }, error => { })
        }
        else {

        }

    }
    GetPermanentCountry() {
        var data=JSON.stringify({
            "CountryID": ""
        });
        var config={
            method: 'POST',
            url: services.API_URL+"Country/getCountry",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            this.setState({ permanentcountries: response.data.data.map(item => ({ value: item.CountryID, label: item.CountryName })) });
        }, error => { })
    }
    GetLocalCountry() {
        var data=JSON.stringify({
            "CountryID": ""
        });
        var config={
            method: 'POST',
            url: services.API_URL+"Country/getCountry",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            //debugger;
            this.setState({ localcountries: response.data.data.map(item => ({ value: item.CountryID, label: item.CountryName })) });
        }, error => { })
    }
    GetZone() {
        debugger;
        var CompanyID="";
        var ClientID="";
        var UserType="";
        var UserID="";
        var APIName="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.PanelUserType!==undefined) {
            UserType=this.props.allCookies.PanelUserType
        }
        if (this.props.allCookies.UserID!==undefined) {
            UserID=this.props.allCookies.UserID
        }

        if (UserType=="Supervisor") {
            APIName="User/getUserZoneDetail";
        }
        else {
            APIName="Zone/getZoneDropDownData";
            UserID="";
        }

        var data=JSON.stringify({
            "ZoneID": "",
            "CompanyID": CompanyID,
            "ClientID": ClientID,
            "UserID": UserID
        });
        var config={
            method: 'POST',
            url: services.API_URL+APIName,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            this.setState({ zones: response.data.data.map(item => ({ id: item.ZoneID, value: item.ZoneName, isZoneChecked: false })) });
        }, error => { })
    }
    isValidFileUploaded=(file) => {
        const validExtensions=['png', 'jpeg', 'jpg']
        const fileExtension=file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
    }
    // Validation Change

    ValidateUserType=(e) => {
        this.setState({ UserTypeErrMsg: '' })
    }
    ValidateCancelUserType=(e) => {
        this.setState({ UserTypeErrMsg: 'required' })
    }
    ValidateZone=(e) => {
        this.setState({ ZoneErrMsg: "" });
    }
    ValidateCancelZone=(e) => {
        this.setState({ ZoneErrMsg: "required" });
    }
    ValidateDistrict=(e) => {
        this.setState({ DistrictErrMsg: "" });
    }
    ValidateCancelDistrict=(e) => {
        this.setState({ DistrictErrMsg: "required" });
    }
    ValidateMunicipality=(e) => {
        this.setState({ MunicipalityErrMsg: "" });
    }
    ValidateCancelMunicipality=(e) => {
        this.setState({ MunicipalityErrMsg: "required" });
    }
    ValidateVehicle=(e) => {
        this.setState({ VehicleErrMsg: '' })
    }
    ValidateCancelVehicle=(e) => {
        this.setState({ VehicleErrMsg: 'required' })
    }
    ValidateUserName=(e) => {
        this.setState({ UserNameErrMsg: '' })
    }
    ValidatePassword=(e) => {
        this.setState({ PasswordErrMsg: '' });
    }
    ShowUserPasswordVisibility() {
        this.setState({ IsUserNamePasswordVisible: true });
    }
    HideUserPasswordVisibility() {
        this.setState({ IsUserNamePasswordVisible: false });
        this.setState({ UserNameErrMsg: "" });
        this.setState({ PasswordErrMsg: "" });
    }
    // onDropDownChange
    onUserTypeChange=(e) => {
        debugger;
        if (e!=null) {
            if (e.label=="Vehicle") {
                this.setState({ IsVehicleType: true });
                this.setState({ UserNameErrMsg: "" });
                this.setState({ PasswordErrMsg: "" });
            }
            else {
                this.setState({ IsVehicleType: false });
            }
            if (e.label=="Supervisor") {
                this.setState({ IsUserNamePasswordVisible: true }, () => { this.ShowUserPasswordVisibility() });
                this.setState({ VehicleErrMsg: "" });
            }
            else if (e.label=="Sub Admin") {
                this.setState({ IsUserNamePasswordVisible: true }, () => { this.ShowUserPasswordVisibility() });
                this.setState({ VehicleErrMsg: "" });
            }
            else {
                this.setState({ IsUserNamePasswordVisible: false }, () => { this.HideUserPasswordVisibility() });
            }
            this.setState({ UserTypeID: e }, () => { this.ValidateUserType() });
        }
        else {
            this.setState({ IsVehicleType: false });
            this.setState({ UserTypeID: { 'label': '--Select User Type--', 'value': '--Select User Type--' } }, () => { this.ValidateCancelUserType() });
        }
    }
    onVehicleChange=(e) => {
        if (e!=null) {
            this.setState({ VehicleID: e }, () => { this.ValidateVehicle() });
        }
        else {
            this.setState({ VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' } }, () => { this.ValidateCancelVehicle() });
        }

    }
    onPermanentCountryChange=(e) => {
        //debugger;
        this.setState({ PermanentCountryID: e });
        if (e.value!="--Select Permanent Country--") {
            var data="";
            data=JSON.stringify({
                "CountryID": e.value
            });

            var config={
                method: 'POST',
                url: services.API_URL+"State/getState",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;

                if (response.data.status) {
                    this.setState({ permanentstates: response.data.data.map(item => ({ value: item.StateID, label: item.StateName })) });
                    this.setState({ PermanentStateID: { 'label': '--Select Permanent State--', 'value': '--Select Permanent State--' } })
                    this.setState({ permanentcities: [] })
                    this.setState({ PermanentCityID: { 'label': '--Select Permanent City--', 'value': '--Select Permanent City--' } })
                }
                else {
                    this.setState({ permanentstates: [] })
                    this.setState({ PermanentStateID: { 'label': '--Select Permanent State--', 'value': '--Select Permanent State--' } })
                    this.setState({ permanentcities: [] })
                    this.setState({ PermanentCityID: { 'label': '--Select Permanent City--', 'value': '--Select Permanent City--' } })
                }
            }, error => { })
        }
        else {
            this.setState({ PermanentCountryID: { 'label': '--Select Permanent Country--', 'value': '--Select Permanent Country--' } });
        }
    }
    onPermanentStateChange=(e) => {
        //debugger;
        if (e!=null) {
            this.setState({ PermanentStateID: e });
            var data="";
            if (e.value!="--Select Permanent State--") {
                data=JSON.stringify({
                    "StateID": e.value
                });

                var config={
                    method: 'POST',
                    url: services.API_URL+"City/getCity",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    //debugger;

                    if (response.data.status) {
                        this.setState({ PermanentCityID: { 'label': '--Select Permanent City--', 'value': '--Select Permanent City--' } })
                        this.setState({ permanentcities: response.data.data.map(item => ({ value: item.CityID, label: item.CityName })) });

                    }
                    else {
                        this.setState({ permanentcities: [] })
                        this.setState({ PermanentCityID: { 'label': '--Select Permanent City--', 'value': '--Select Permanent City--' } })
                    }

                }, error => { })
            }
            else {
                this.setState({ PermanentStateID: { 'label': '--Select Permanent State--', 'value': '--Select Permanent State--' } });
            }
        }
        else {
            this.setState({ PermanentStateID: { 'label': '--Select Permanent State--', 'value': '--Select Permanent State--' } });
            this.setState({ permanentcities: [] })
            this.setState({ PermanentCityID: { 'label': '--Select Permanent City--', 'value': '--Select Permanent City--' } })
        }


    }
    onPermanentCityChange=(e) => {
        if (e!==null) {
            this.setState({ PermanentCityID: e });
        }
        else {
            this.setState({ PermanentCityID: { 'label': '--Select Permanent City--', 'value': '--Select Permanent City--' } });
        }
    }
    onLocalCountryChange=(e) => {
        //debugger;
        this.setState({ LocalCountryID: e });
        if (e.value!="--Select Local Country--") {
            var data="";
            data=JSON.stringify({
                "CountryID": e.value
            });

            var config={
                method: 'POST',
                url: services.API_URL+"State/getState",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                //debugger;

                if (response.data.status) {
                    this.setState({ localstates: response.data.data.map(item => ({ value: item.StateID, label: item.StateName })) });
                    this.setState({ LocalStateID: { 'label': '--Select Local State--', 'value': '--Select Local State--' } })
                    this.setState({ localcities: [] })
                    this.setState({ LocalCityID: { 'label': '--Select Local City--', 'value': '--Select Local City--' } })
                }
                else {
                    this.setState({ Local: [] })
                    this.setState({ LocalStateID: { 'label': '--Select Local State--', 'value': '--Select Local State--' } })
                    this.setState({ localcities: [] })
                    this.setState({ LocalCityID: { 'label': '--Select Local City--', 'value': '--Select Local City--' } })
                }
            }, error => { })
        }
        else {
            this.setState({ LocalCountryID: { 'label': '--Select Local Country--', 'value': '--Select Local Country--' } });
        }
    }
    onLocalStateChange=(e) => {
        //debugger;
        if (e!=null) {
            this.setState({ LocalStateID: e });
            var data="";
            if (e.value!="--Select Local State--") {
                data=JSON.stringify({
                    "StateID": e.value
                });

                var config={
                    method: 'POST',
                    url: services.API_URL+"City/getCity",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    //debugger;

                    if (response.data.status) {
                        this.setState({ LocalCityID: { 'label': '--Select Local City--', 'value': '--Select Local City--' } })
                        this.setState({ localcities: response.data.data.map(item => ({ value: item.CityID, label: item.CityName })) });

                    }
                    else {
                        this.setState({ localcities: [] })
                        this.setState({ LocalCityID: { 'label': '--Select Local City--', 'value': '--Select Local City--' } })
                    }

                }, error => { })
            }
            else {
                this.setState({ LocalStateID: { 'label': '--Select Local State--', 'value': '--Select Local State--' } });
            }
        }
        else {
            this.setState({ LocalStateID: { 'label': '--Select Local State--', 'value': '--Select Local State--' } });
            this.setState({ localcities: [] })
            this.setState({ LocalCityID: { 'label': '--Select Local City--', 'value': '--Select Local City--' } })
        }


    }
    onLocalCityChange=(e) => {
        if (e!==null) {
            this.setState({ LocalCityID: e });
        }
        else {
            this.setState({ LocalCityID: { 'label': '--Select Local City--', 'value': '--Select Local City--' } });
        }
    }
    onZoneChange=(e, action) => {
        debugger;
        if (this.state.isEdit) {
            let zones=this.state.zones;
            var allvalueselectted=false;
            var isValid=false;
            var Zid="";
            var CurrentZid="";
            var isRemoved=false;
            zones.forEach(zones => {
                debugger
                if (zones.id===e.target.value) {
                    zones.isZoneChecked=e.target.checked;
                    if (e.target.checked==true) {
                        CurrentZid=zones.id;
                    }
                    else {
                        CurrentZid="";
                        isRemoved=true;
                    }

                }
                if (zones.isZoneChecked==false) {
                    allvalueselectted=true;
                }
                if (zones.isZoneChecked==true) {
                    isValid=true;
                    Zid+=zones.id+",";
                }

            });
            Zid=Zid.substring(0, Zid.length-1);
            if (CurrentZid=="") {
                CurrentZid=Zid;
            }

            this.setState({ zones: zones });
            if (allvalueselectted==false) {
                this.setState({ isZoneChecked: true });
            }
            else {
                this.setState({ isZoneChecked: false });
            }

            if (isValid==true) {
                this.ValidateZone();



                var CompanyID="";
                var ClientID="";
                var UserType="";
                var UserID="";
                var APIName="";

                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }

                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }

                if (UserType=="Supervisor") {
                    APIName="User/getUserDistrictDetail";
                }
                else {
                    APIName="District/getDistrictDropDownData";
                    UserID="";
                }

                var data=JSON.stringify({
                    "DistrictID": "",
                    "ZoneID": CurrentZid,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "UserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+APIName,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    debugger;
                    if (response.data.status) {
                        this.setState({ EditDistricts: response.data.data.map(item => ({ id: item.DistrictID, value: item.DistrictName, isDistrictChecked: false })) });
                        const newdatas=response.data.data.map(item => ({ id: item.DistrictID, value: item.DistrictName, isDistrictChecked: false }))
                        const datas=this.state.districts
                        if (isRemoved==false) {
                            var c=[...datas, ...newdatas];
                            this.setState({ districts: c });
                        }
                        else {
                            for (let i=0; i<datas.length; i++) {
                                for (let j=0; j<newdatas.length; j++) {
                                    if (datas[i].id==newdatas[j].id&&datas[i].isDistrictChecked==true) {
                                        newdatas[j].isDistrictChecked=true;
                                    }

                                }
                            }
                            var c=newdatas;
                            this.setState({ districts: c });
                        }

                        this.setState({ DistrictDataAvailable: true }, () => { this.CheckEditDistricts() });
                        this.setState({ NoDistrictVisibleMsg: false });
                    }
                    else {
                        this.setState({ districts: [] });
                        this.setState({ isDistrictChecked: false });
                        this.setState({ DistrictDataAvailable: false });
                        this.setState({ NoDistrictVisibleMsg: true });
                    }

                }, error => { })
            }
            else {
                this.setState({ districts: [] });
                this.setState({ isDistrictChecked: false });
                this.setState({ DistrictDataAvailable: false });
                this.setState({ NoDistrictVisibleMsg: true });
            }

        }
        else {
            let zones=this.state.zones;
            var allvalueselectted=false;
            var isValid=false;
            var Zid="";
            var FinalZid="";
            zones.forEach(zones => {
                debugger
                if (zones.id===e.target.value) {
                    zones.isZoneChecked=e.target.checked;
                    Zid+=zones.id+",";
                }
                if (zones.isZoneChecked==false) {
                    allvalueselectted=true;
                }
                if (zones.isZoneChecked==true) {
                    isValid=true;
                    FinalZid+=zones.id+",";
                }

            });
            Zid=Zid.substring(0, Zid.length-1);
            FinalZid=FinalZid.substring(0, FinalZid.length-1);


            this.setState({ zones: zones });
            if (allvalueselectted==false) {
                this.setState({ isZoneChecked: true });
            }
            else {
                this.setState({ isZoneChecked: false });
            }
            if (isValid==true) {
                this.ValidateZone();

                var CompanyID="";
                var ClientID="";
                var UserType="";
                var UserID="";
                var APIName="";

                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }

                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }

                if (UserType=="Supervisor") {
                    APIName="User/getUserDistrictDetail";
                }
                else {
                    APIName="District/getDistrictDropDownData";
                    UserID="";
                }

                var data=JSON.stringify({
                    "DistrictID": "",
                    "ZoneID": FinalZid,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "UserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+APIName,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    debugger;
                    if (response.data.status) {
                        this.setState({ districts: response.data.data.map(item => ({ id: item.DistrictID, value: item.DistrictName, isDistrictChecked: false })) });
                        this.setState({ DistrictDataAvailable: true });
                        this.setState({ NoDistrictVisibleMsg: false });
                    }
                    else {
                        this.setState({ districts: [] });
                        this.setState({ isDistrictChecked: false });
                        this.setState({ DistrictDataAvailable: false });
                        this.setState({ NoDistrictVisibleMsg: true });
                    }

                }, error => { })
            }
            else {
                this.ValidateCancelZone();
                this.setState({ districts: [] });
                this.setState({ isDistrictChecked: false });
                this.setState({ DistrictDataAvailable: false });
                this.setState({ NoDistrictVisibleMsg: true });
            }
        }
    }
    onZoneAllChange=(e, action) => {
        debugger;

        if (e.target.checked==true) {
            this.setState({ isZoneChecked: true });
            this.setState({
                zones: this.state.zones.map(item =>
                    ({ id: item.id, value: item.value, isZoneChecked: true }))
            }, () => { this.ValidateZone() });
            let zones=this.state.zones;
            var Zid="";
            zones.forEach(zones => {
                Zid+=zones.id+",";
                debugger
            });
            Zid=Zid.substring(0, Zid.length-1);
            // this.setState({ zones: zones });

            var CompanyID="";
            var ClientID="";
            var UserType="";
            var UserID="";
            var APIName="";

            if (this.props.allCookies.MainCompanyID!==undefined) {
                CompanyID=this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID!==undefined) {
                ClientID=this.props.allCookies.MainClientID
            }

            if (this.props.allCookies.PanelUserType!==undefined) {
                UserType=this.props.allCookies.PanelUserType
            }
            if (this.props.allCookies.UserID!==undefined) {
                UserID=this.props.allCookies.UserID
            }

            if (UserType=="Supervisor") {
                APIName="User/getUserDistrictDetail";
            }
            else {
                APIName="District/getDistrictDropDownData";
                UserID="";
            }

            var data=JSON.stringify({
                "DistrictID": "",
                "ZoneID": Zid,
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "UserID": UserID
            });
            var config={
                method: 'POST',
                url: services.API_URL+APIName,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                debugger;
                if (response.data.status) {
                    this.setState({ districts: response.data.data.map(item => ({ id: item.DistrictID, value: item.DistrictName, isDistrictChecked: false })) });
                    this.setState({ DistrictDataAvailable: true });
                    this.setState({ NoDistrictVisibleMsg: false });
                }
                else {
                    this.setState({ districts: [] });
                    this.setState({ isDistrictChecked: false });
                    this.setState({ DistrictDataAvailable: false });
                    this.setState({ NoDistrictVisibleMsg: true });
                }

            }, error => { })

        }
        else {
            this.setState({ isZoneChecked: false });
            this.setState({
                zones: this.state.zones.map(item =>
                    ({ id: item.id, value: item.value, isZoneChecked: false }))
            }, () => { this.ValidateCancelZone() });
            this.setState({ districts: [] });
            this.setState({ isDistrictChecked: false });
            this.setState({ DistrictDataAvailable: false });
            this.setState({ NoDistrictVisibleMsg: true });
        }
    }
    onDistrictChange=(e, action) => {
        debugger;
        if (this.state.isEdit) {
            let districts=this.state.districts;
            var allvalueselectted=false;
            var isValid=false;
            var DistrictId="";
            var CurrentDistrictId="";
            var isRemoved=false;
            districts.forEach(districts => {
                debugger
                if (districts.id===e.target.value) {
                    districts.isDistrictChecked=e.target.checked;
                    if (e.target.checked==true) {
                        CurrentDistrictId=districts.id;
                    }
                    else {
                        CurrentDistrictId="";
                        isRemoved=true;
                    }
                }
                if (districts.isDistrictChecked==false) {
                    allvalueselectted=true;
                }
                if (districts.isDistrictChecked==true) {
                    isValid=true;
                    DistrictId+=districts.id+",";
                }

            });

            DistrictId=DistrictId.substring(0, DistrictId.length-1);
            if (CurrentDistrictId=="") {
                CurrentDistrictId=DistrictId;
            }

            this.setState({ districts: districts });
            if (allvalueselectted==false) {
                this.setState({ isDistrictChecked: true });
            }
            else {
                this.setState({ isDistrictChecked: false });
            }
            if (isValid==true) {
                this.ValidateDistrict();
                var CompanyID="";
                var ClientID="";
                var UserType="";
                var UserID="";
                var APIName="";

                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }

                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }

                if (UserType=="Supervisor") {
                    APIName="User/getUserMuncipalityDetail";
                }
                else {
                    APIName="Muncipality/getMuncipalityDropDownData";
                    UserID="";
                }

                var data=JSON.stringify({
                    "MuncipalityID": "",
                    "DistrictID": CurrentDistrictId,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "UserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+APIName,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    debugger;
                    if (response.data.status) {
                        //this.setState({ municipalities: response.data.data.map(item => ({ id: item.MuncipalityID, value: item.MuncipalityName, isMunicipalityChecked: false })) });
                        const newdatas=response.data.data.map(item => ({ id: item.MuncipalityID, value: item.MuncipalityName, isMunicipalityChecked: false }))
                        const datas=this.state.municipalities
                        if (isRemoved==false) {
                            var c=[...datas, ...newdatas];
                            this.setState({ municipalities: c });
                        }
                        else {
                            for (let i=0; i<datas.length; i++) {
                                for (let j=0; j<newdatas.length; j++) {
                                    if (datas[i].id==newdatas[j].id&&datas[i].isMunicipalityChecked==true) {
                                        newdatas[j].isMunicipalityChecked=true;
                                    }

                                }
                            }
                            var c=newdatas;
                            this.setState({ municipalities: c });
                        }
                        this.setState({ MunicipalityDataAvailable: true });
                        this.setState({ NoMunicipalityVisibleMsg: false });
                    }
                    else {
                        this.setState({ municipalities: [] });
                        this.setState({ isMunicipalityChecked: false });
                        this.setState({ MunicipalityDataAvailable: false });
                        this.setState({ NoMunicipalityVisibleMsg: true });
                    }

                }, error => { })
            }
            else {
                this.ValidateCancelDistrict();
                this.setState({ municipalities: [] });
                this.setState({ isMunicipalityChecked: false });
                this.setState({ MunicipalityDataAvailable: false });
                this.setState({ NoMunicipalityVisibleMsg: true });
            }
        }
        else {
            let districts=this.state.districts;
            var allvalueselectted=false;
            var isValid=false;
            var DistrictId="";
            var FinalDistrictId="";
            districts.forEach(districts => {
                debugger
                if (districts.id===e.target.value) {
                    districts.isDistrictChecked=e.target.checked;
                    DistrictId+=districts.id+",";
                }
                if (districts.isDistrictChecked==false) {
                    allvalueselectted=true;
                }
                if (districts.isDistrictChecked==true) {
                    isValid=true;
                    FinalDistrictId+=districts.id+",";
                }

            });

            DistrictId=DistrictId.substring(0, DistrictId.length-1);
            FinalDistrictId=FinalDistrictId.substring(0, FinalDistrictId.length-1);

            this.setState({ districts: districts });
            if (allvalueselectted==false) {
                this.setState({ isDistrictChecked: true });
            }
            else {
                this.setState({ isDistrictChecked: false });
            }
            if (isValid==true) {
                this.ValidateDistrict();
                var CompanyID="";
                var ClientID="";
                var UserType="";
                var UserID="";
                var APIName="";

                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }

                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }

                if (UserType=="Supervisor") {
                    APIName="User/getUserMuncipalityDetail";
                }
                else {
                    APIName="Muncipality/getMuncipalityDropDownData";
                    UserID="";
                }

                var data=JSON.stringify({
                    "MuncipalityID": "",
                    "DistrictID": FinalDistrictId,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "UserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+APIName,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    debugger;
                    if (response.data.status) {
                        this.setState({ municipalities: response.data.data.map(item => ({ id: item.MuncipalityID, value: item.MuncipalityName, isMunicipalityChecked: false })) });
                        this.setState({ MunicipalityDataAvailable: true });
                        this.setState({ NoMunicipalityVisibleMsg: false });
                    }
                    else {
                        this.setState({ municipalities: [] });
                        this.setState({ isMunicipalityChecked: false });
                        this.setState({ MunicipalityDataAvailable: false });
                        this.setState({ NoMunicipalityVisibleMsg: true });
                    }

                }, error => { })
            }
            else {
                this.ValidateCancelDistrict();
                this.setState({ municipalities: [] });
                this.setState({ isMunicipalityChecked: false });
                this.setState({ MunicipalityDataAvailable: false });
                this.setState({ NoMunicipalityVisibleMsg: true });
            }
        }
    }
    onDistrictAllChange=(e, action) => {
        debugger;

        if (e.target.checked==true) {
            this.setState({ isDistrictChecked: true });
            this.setState({
                districts: this.state.districts.map(item =>
                    ({ id: item.id, value: item.value, isDistrictChecked: true }))
            }, () => { this.ValidateDistrict() });
            let districts=this.state.districts;
            var DistrictId="";
            districts.forEach(districts => {
                DistrictId+=districts.id+",";
                debugger
            });
            DistrictId=DistrictId.substring(0, DistrictId.length-1);
            var CompanyID="";
            var ClientID="";
            var UserType="";
            var UserID="";
            var APIName="";

            if (this.props.allCookies.MainCompanyID!==undefined) {
                CompanyID=this.props.allCookies.MainCompanyID;
            }
            if (this.props.allCookies.MainClientID!==undefined) {
                ClientID=this.props.allCookies.MainClientID
            }

            if (this.props.allCookies.PanelUserType!==undefined) {
                UserType=this.props.allCookies.PanelUserType
            }
            if (this.props.allCookies.UserID!==undefined) {
                UserID=this.props.allCookies.UserID
            }

            if (UserType=="Supervisor") {
                APIName="User/getUserMuncipalityDetail";
            }
            else {
                APIName="Muncipality/getMuncipalityDropDownData";
                UserID="";
            }

            var data=JSON.stringify({
                "MuncipalityID": "",
                "DistrictID": DistrictId,
                "CompanyID": CompanyID,
                "ClientID": ClientID,
                "UserID": UserID
            });
            var config={
                method: 'POST',
                url: services.API_URL+APIName,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            services.JsonValue(config).then(response => {
                debugger;
                if (response.data.status) {
                    this.setState({ municipalities: response.data.data.map(item => ({ id: item.MuncipalityID, value: item.MuncipalityName, isMunicipalityChecked: false })) });
                    this.setState({ MunicipalityDataAvailable: true });
                    this.setState({ NoMunicipalityVisibleMsg: false });
                }
                else {
                    this.setState({ municipalities: [] });
                    this.setState({ isMunicipalityChecked: false });
                    this.setState({ MunicipalityDataAvailable: false });
                    this.setState({ NoMunicipalityVisibleMsg: true });
                }

            }, error => { })
        }
        else {
            this.setState({ isDistrictChecked: false });
            this.setState({
                districts: this.state.districts.map(item =>
                    ({ id: item.id, value: item.value, isDistrictChecked: false }))
            }, () => { this.ValidateCancelDistrict() });
            this.setState({ municipalities: [] });
            this.setState({ isMunicipalityChecked: false });
            this.setState({ MunicipalityDataAvailable: false });
            this.setState({ NoMunicipalityVisibleMsg: true });
        }
    }
    onMunicipalityChange=(e, action) => {
        debugger;

        let municipalities=this.state.municipalities;
        var allvalueselectted=false;
        var isValid=false;
        var MunicipalityId="";
        municipalities.forEach(municipalities => {
            debugger
            if (municipalities.id===e.target.value) {
                municipalities.isMunicipalityChecked=e.target.checked;
                MunicipalityId+=municipalities.id;
            }
            if (municipalities.isMunicipalityChecked==false) {
                allvalueselectted=true;
            }
            if (municipalities.isMunicipalityChecked==true) {
                isValid=true;
            }

        });
        this.setState({ municipalities: municipalities });
        if (allvalueselectted==false) {
            this.setState({ isMunicipalityChecked: true });
        }
        else {
            this.setState({ isMunicipalityChecked: false });
        }
        if (isValid==true) {
            this.ValidateMunicipality();

        }
        else {
            this.ValidateCancelMunicipality();

        }



    }
    onMunicipalityAllChange=(e, action) => {
        debugger;

        if (e.target.checked==true) {
            this.setState({ isMunicipalityChecked: true });
            this.setState({
                municipalities: this.state.municipalities.map(item =>
                    ({ id: item.id, value: item.value, isMunicipalityChecked: true }))
            }, () => { this.ValidateMunicipality() });
        }
        else {
            this.setState({ isMunicipalityChecked: false });
            this.setState({
                municipalities: this.state.municipalities.map(item =>
                    ({ id: item.id, value: item.value, isMunicipalityChecked: false }))
            }, () => { this.ValidateCancelMunicipality() });
        }
        //this.setState({ IsHelperChange: true });
        //this.setState({ IsHelperChange: true }, () => { this.onDynamicHelper(e, e.target.checked) });
    }
    onStatusChange=(UserID) => (Status) => {
        // e.preventDefault();
        debugger;
        var IsStatus=Status.toString();


        var data=JSON.stringify({
            "UserID": UserID,
            "IsActive": IsStatus
        });
        var config={
            method: 'POST',
            url: services.API_URL+"User/setUserIsActiveStatus",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            if (response.data.status) {
                //this.setState({ data: response.data.data })
                Swal.fire({
                    title: 'Successfully Updated', icon: "success", timer: 1500
                });
            }
            else {
                Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                // this.setState({ data: [] })
            }
            this.GetUser();

        }, error => { })
    }
    onSearchUserTypeChange=(e) => {
        debugger;
        if (e!=null) {
            this.setState({ SearchUserTypeID: e });
        }
        else {
            this.setState({ SearchUserTypeID: { 'label': '--Select User Type--', 'value': '--Select User Type--' } }, () => { this.GetUser() });
        }
    }
    // OnFileUpload
    OnUserImageUpload=e => {
        debugger;
        if (e.target.files.length<1) {
            //this.setState({ TaskImageErrMsg: "" });
            return;
        }
        else {
            //this.setState({ TaskImageErrMsg: "*" });
        }
        const file=e.target.files[0];
        if (this.isValidFileUploaded(file)) {
            this.setState({ UserImageFile: e.target.files, UserImage: e.target.files[0].originalname })
        } else {
            // this.setState({ TaskImageErrMsg: "Not Valid File" });
            return false;
        }

    };
    // OnButtonClick
    onAddUserClick=(e) => {
        if (this.state.AddUserVisible==false) {
            this.setState({ AddUserVisible: true });
            this.setState({ iconAdd: "fa fa-minus" });
        }
        else {
            this.setState({ AddUserVisible: false });
            this.setState({ iconAdd: "fa fa-plus" });
        }
    }
    OnFilterClick=(e) => {

        e.preventDefault();
        if (this.state.isFilterVisible===false) {
            this.setState({ isFilterVisible: true });
            this.setState({ iconFilter: "fa fa-minus" });
        }
        else {
            this.setState({ isFilterVisible: false });
            this.setState({ iconFilter: "fa fa-plus" });
        }

    }
    CheckAlert(id, mobileno) {
        Swal.fire({
            title: 'Are you Sure You Want to Delete?', icon: "warning", showCancelButton: true, confirmButtonText: 'Delete', cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                var CompanyID="";
                var ClientID="";
                var UserID="";
                var UserType="";
                if (this.props.allCookies.MainCompanyID!==undefined) {
                    CompanyID=this.props.allCookies.MainCompanyID;
                }
                if (this.props.allCookies.MainClientID!==undefined) {
                    ClientID=this.props.allCookies.MainClientID
                }
                if (this.props.allCookies.UserID!==undefined) {
                    UserID=this.props.allCookies.UserID
                }
                if (this.props.allCookies.PanelUserType!==undefined) {
                    UserType=this.props.allCookies.PanelUserType
                }
                var data=JSON.stringify({
                    "UserID": id,
                    "CompanyID": CompanyID,
                    "ClientID": ClientID,
                    "PersonalMobileNo": mobileno,
                    "EntryByUserType": UserType,
                    "EntryByUserID": UserID
                });
                var config={
                    method: 'POST',
                    url: services.API_URL+"User/removeUser",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                services.JsonValue(config).then(response => {
                    if (response.data.status) {
                        this.GetUser();
                        Swal.fire({
                            title: 'Successfully Deleted', icon: "success", timer: 1500
                        });
                    } else {
                        Swal.fire({ position: 'top-end', icon: 'error', title: response.data.message, showConfirmButton: false, timer: 1500 });
                    }
                }, error => { })
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    }
    // Javascript Functions
    allowOnlyCharacters=(event) => {
        const keyCode=event.keyCode||event.which;
        const keyValue=String.fromCharCode(keyCode);
        if (!new RegExp("^[a-zA-Z ]+$").test(keyValue)) event.preventDefault();
        return;
    };
    allowOnlyNumbers=(event) => {
        const keyCode=event.keyCode||event.which;
        const keyValue=String.fromCharCode(keyCode);
        if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
        return;
    };
    cancelUser=(e) => {
        this.form.reset();
        this.setState({
            usertypes: [], permanentcountries: [], permanentstates: [], permanentcities: [], localcountries: [], localstates: [], localcities: [], vehicles: [], zones: [{ 'id': 'No Zones', 'value': 'No Zones' }], districts: [], municipalities: [],
            UserTypeID: { 'label': '--Select User Type--', 'value': '--Select User Type--' },
            PermanentCountryID: { 'label': '--Select Permanent Country--', 'value': '--Select Permanent Country--' },
            PermanentStateID: { 'label': '--Select Permanent State--', 'value': '--Select Permanent State--' },
            PermanentCityID: { 'label': '--Select Permanent City--', 'value': '--Select Permanent City--' },
            LocalCountryID: { 'label': '--Select Local Country--', 'value': '--Select Local Country--' },
            LocalStateID: { 'label': '--Select Local State--', 'value': '--Select Local State--' },
            LocalCityID: { 'label': '--Select Local City--', 'value': '--Select Local City--' },
            VehicleID: { 'label': '--Select Vehicle--', 'value': '--Select Vehicle--' },
            UserTypeErrMsg: "", UserNameErrMsg: "", PasswordErrMsg: "", ZoneErrMsg: "", DistrictErrMsg: "", MunicipalityErrMsg: "",
            VehicleErrMsg: "",
            isZoneChecked: false, ZoneDataAvailable: true, NoZoneVisibleMsg: false,
            isDistrictAvailable: false, DistrictDataAvailable: false, NoDistrictVisibleMsg: true,
            isMunicipalityAvailable: false, MunicipalityDataAvailable: false, NoMunicipalityVisibleMsg: true, IsVehicleType: false,
            IsUserNamePasswordVisible: false,
            UserID: "", CompanyID: "", ClientID: "", UserType: "", UserCode: "", FirstName: "", MiddleName: "",
            LastName: "", NameInHindi: "", GuardianName: "", UserName: "", Password: "", DateOfBirth: "", PermanentAddress: "",
            PersonalEmail: "", PersonalMobileNo: "", LocalAddress: "", OfficeMobileNo: "", OfficeEmail: "", LicenseNo: "", IssuingAuthority: "",
            ValidUpto: "", Note: "", Guarantor: "", EntryByUserType: "", EntryByUserID: "", ZoneData: [{ ZoneID: "", }],
            DistrictData: [{ DistrictID: "", }], MuncipalityData: [{ MuncipalityID: "", }],
            OldUserImageName: "", UserImage: "", UserImageFile: []
        });
        this.GetUser();
        this.GetUserType();
        this.GetPermanentCountry();
        this.onPermanentCountryChange(1);
        this.setState({ PermanentCountryID: { 'label': 'India', 'value': '1' } })
        this.GetLocalCountry();
        this.onLocalCountryChange(1);
        this.setState({ LocalCountryID: { 'label': 'India', 'value': '1' } })
        this.GetZone();
        this.GetVehicle();
    }
    handleSubmit=(e) => {
        debugger;

        let zonedatas=[];
        let districtdatas=[];
        let municipalitiesdatas=[];
        var allvalid=true;
        var CompanyID="";
        var ClientID="";
        var UserID="";
        var UserType="";

        if (this.props.allCookies.MainCompanyID!==undefined) {
            CompanyID=this.props.allCookies.MainCompanyID;
        }
        if (this.props.allCookies.MainClientID!==undefined) {
            ClientID=this.props.allCookies.MainClientID
        }
        if (this.props.allCookies.UserID!==undefined) {
            UserID=this.props.allCookies.UserID
        }
        if (this.props.allCookies.PanelUserType!==undefined) {
            UserType=this.props.allCookies.PanelUserType
        }

        e.preventDefault();
        this.form.validateForm();
        if (this.state.UserTypeID.value=="--Select User Type--") {
            this.setState({ UserTypeErrMsg: "required" });
        }
        if (this.state.UserTypeID.label=="Supervisor") {
            if (this.state.UserName=="") {
                this.setState({ UserNameErrMsg: "required" });
            }
            if (this.state.Password=="") {
                this.setState({ PasswordErrMsg: "required" });
            }
        }
        if (this.state.UserTypeID.label=="Vehicle") {
            if (this.state.VehicleID.value=="--Select Vehicle--") {
                this.setState({ VehicleErrMsg: "required" });
            }
        }
        let zones=this.state.zones;
        zones.forEach(zones => {
            if (zones.isZoneChecked==true) {
                zonedatas.push({ "ZoneID": zones.id })
            }
        });
        if (zonedatas.length===0) {
            this.setState({ ZoneErrMsg: 'required' });
            allvalid=false;
        }
        let districts=this.state.districts;
        districts.forEach(districts => {
            if (districts.isDistrictChecked==true) {
                districtdatas.push({ "DistrictID": districts.id })
            }
        });
        if (districtdatas.length===0) {
            this.setState({ DistrictErrMsg: 'required' });
            allvalid=false;
        }
        let municipalities=this.state.municipalities;
        municipalities.forEach(municipalities => {
            if (municipalities.isMunicipalityChecked==true) {
                municipalitiesdatas.push({ "MuncipalityID": municipalities.id })
            }
        });
        if (municipalitiesdatas.length===0) {
            this.setState({ MunicipalityErrMsg: 'required' });
            allvalid=false;
        }
        if (this.form.isValid()) {
            if (allvalid==true) {

                const json=zonedatas;
                const obj=JSON.stringify(json);

                var PermanentCountryID="", PermanentStateID="", PermanentCityID="", LocalCountryID="", LocalStateID="", LocalCityID="", VehicleID="", DateOfBirth="";
                if (this.state.PermanentCountryID.value!="--Select Permanent Country--") {
                    PermanentCountryID=this.state.PermanentCountryID.value;
                }
                if (this.state.PermanentStateID.value!="--Select Permanent State--") {
                    PermanentStateID=this.state.PermanentStateID.value;
                }
                if (this.state.PermanentCityID.value!="--Select Permanent City--") {
                    PermanentCityID=this.state.PermanentCityID.value;
                }
                if (this.state.LocalCountryID.value!="--Select Local Country--") {
                    LocalCountryID=this.state.LocalCountryID.value;
                }
                if (this.state.LocalStateID.value!="--Select Local State--") {
                    LocalStateID=this.state.LocalStateID.value;
                }
                if (this.state.LocalCityID.value!="--Select Local City--") {
                    LocalCityID=this.state.LocalCityID.value;
                }
                if (this.state.VehicleID.value!="--Select Vehicle--") {
                    VehicleID=this.state.VehicleID.value;
                }
                if (this.state.DateOfBirth!="") {
                    DateOfBirth=moment(this.state.DateOfBirth).format('DD-MM-YYYY')
                }
                var Password=this.state.Password;
                if (Password==undefined||Password==null||Password=="") {
                    Password="";
                }
                if (PermanentStateID==undefined||PermanentStateID==null) {
                    PermanentStateID="";
                }
                if (PermanentCityID==undefined||PermanentCityID==null) {
                    PermanentCityID="";
                }
                if (LocalStateID==undefined||LocalStateID==null) {
                    LocalStateID="";
                }
                if (LocalCityID==undefined||LocalCityID==null) {
                    LocalCityID="";
                }
                if (VehicleID==undefined||VehicleID==null) {
                    VehicleID="";
                }
                if (this.state.UserID!="") {
                    var data=new FormData();
                    data.append('UserID', this.state.UserID);
                    data.append('CompanyID', CompanyID);
                    data.append('ClientID', ClientID);
                    data.append('UserTypeID', this.state.UserTypeID.value);
                    data.append('UserType', this.state.UserTypeID.label);
                    data.append('UserCode', this.state.UserCode==null? "":this.state.UserCode=="null"? "":this.state.UserCode);
                    data.append('FirstName', this.state.FirstName==null? "":this.state.FirstName=="null"? "":this.state.FirstName);
                    data.append('MiddleName', this.state.MiddleName==null? "":this.state.MiddleName=="null"? "":this.state.MiddleName);
                    data.append('LastName', this.state.LastName==null? "":this.state.LastName=="null"? "":this.state.LastName);
                    data.append('NameInHindi', this.state.NameInHindi==null? "":this.state.NameInHindi=="null"? "":this.state.NameInHindi);
                    data.append('GuardianName', this.state.GuardianName==null? "":this.state.GuardianName=="null"? "":this.state.GuardianName);
                    data.append('UserName', this.state.UserName==null? "":this.state.UserName=="null"? "":this.state.UserName);
                    data.append('Password', Password);
                    data.append('DateOfBirth', DateOfBirth);
                    data.append('PermanentCountryID', PermanentCountryID);
                    data.append('PermanentStateID', PermanentStateID);
                    data.append('PermanentCityID', PermanentCityID);
                    data.append('PermanentAddress', this.state.PermanentAddress==null? "":this.state.PermanentAddress=="null"? "":this.state.PermanentAddress);
                    data.append('PersonalEmail', this.state.PersonalEmail==null? "":this.state.PersonalEmail=="null"? "":this.state.PersonalEmail);
                    data.append('PersonalMobileNo', this.state.PersonalMobileNo==null? "":this.state.PersonalMobileNo=="null"? "":this.state.PersonalMobileNo);
                    data.append('LocalAddress', this.state.LocalAddress==null? "":this.state.LocalAddress=="null"? "":this.state.LocalAddress);
                    data.append('LocalCountryID', LocalCountryID);
                    data.append('LocalStateID', LocalStateID);
                    data.append('LocalCityID', LocalCityID);
                    data.append('OfficeMobileNo', this.state.OfficeMobileNo==null? "":this.state.OfficeMobileNo=="null"? "":this.state.OfficeMobileNo);
                    data.append('OfficeEmail', this.state.OfficeEmail==null? "":this.state.OfficeEmail=="null"? "":this.state.OfficeEmail);
                    data.append('VehicleID', VehicleID);
                    data.append('LicenseNo', this.state.LicenseNo==null? "":this.state.LicenseNo=="null"? "":this.state.LicenseNo);
                    data.append('IssuingAuthority', this.state.IssuingAuthority==null? "":this.state.IssuingAuthority=="null"? "":this.state.IssuingAuthority);
                    data.append('ValidUpto', this.state.ValidUpto==null? "":this.state.ValidUpto=="null"? "":this.state.ValidUpto);
                    data.append('Note', this.state.Note==null? "":this.state.Note=="null"? "":this.state.Note);
                    data.append('Guarantor', this.state.Guarantor==null? "":this.state.Guarantor=="null"? "":this.state.Guarantor);
                    data.append('EntryByUserType', UserType);
                    data.append('EntryByUserID', UserID);
                    data.append('ZoneData', JSON.stringify(zonedatas));
                    data.append('DistrictData', JSON.stringify(districtdatas));
                    data.append('MuncipalityData', JSON.stringify(municipalitiesdatas));
                    data.append('OldUserImageName', this.state.OldUserImageName==null? "":this.state.OldUserImageName=="null"? "":this.state.OldUserImageName);
                    for (let i=0; i<this.state.UserImageFile.length; i++) { data.append("UserImage", this.state.UserImageFile[i]); }
                    // data.append('UserImage', fs.createReadStream('/C:/Users/PC/Downloads/download.jpg'));
                }
                else {
                    var data=new FormData();

                    data.append('CompanyID', CompanyID);
                    data.append('ClientID', ClientID);
                    data.append('UserTypeID', this.state.UserTypeID.value);
                    data.append('UserType', this.state.UserTypeID.label);
                    data.append('UserCode', this.state.UserCode);
                    data.append('FirstName', this.state.FirstName);
                    data.append('MiddleName', this.state.MiddleName);
                    data.append('LastName', this.state.LastName);
                    data.append('NameInHindi', this.state.NameInHindi);
                    data.append('GuardianName', this.state.GuardianName);
                    data.append('UserName', this.state.UserName);
                    data.append('Password', Password);
                    data.append('DateOfBirth', DateOfBirth);
                    data.append('PermanentCountryID', PermanentCountryID);
                    data.append('PermanentStateID', PermanentStateID);
                    data.append('PermanentCityID', PermanentCityID);
                    data.append('PermanentAddress', this.state.PermanentAddress);
                    data.append('PersonalEmail', this.state.PersonalEmail);
                    data.append('PersonalMobileNo', this.state.PersonalMobileNo);
                    data.append('LocalAddress', this.state.LocalAddress);
                    data.append('LocalCountryID', LocalCountryID);
                    data.append('LocalStateID', LocalStateID);
                    data.append('LocalCityID', LocalCityID);
                    data.append('OfficeMobileNo', this.state.OfficeMobileNo);
                    data.append('OfficeEmail', this.state.OfficeEmail);
                    data.append('VehicleID', VehicleID);
                    data.append('LicenseNo', this.state.LicenseNo);
                    data.append('IssuingAuthority', this.state.IssuingAuthority);
                    data.append('ValidUpto', this.state.ValidUpto);
                    data.append('Note', this.state.Note);
                    data.append('Guarantor', this.state.Guarantor);
                    data.append('EntryByUserType', UserType);
                    data.append('EntryByUserID', UserID);
                    data.append('ZoneData', JSON.stringify(zonedatas));
                    data.append('DistrictData', JSON.stringify(districtdatas));
                    data.append('MuncipalityData', JSON.stringify(municipalitiesdatas));
                    data.append('OldUserImageName', '');
                    for (let i=0; i<this.state.UserImageFile.length; i++) { data.append("UserImage", this.state.UserImageFile[i]); }
                }
                services.UserFormValue(data).then(response => {
                    if (response.data.status) {
                        if (this.state.UserID!="") {
                            Swal.fire({
                                title: 'Successfully Updated', icon: "success", timer: 1500
                            });
                        }
                        else {
                            Swal.fire({
                                title: 'Successfully Inserted', icon: "success", timer: 1500
                            });
                        }
                        this.cancelUser();
                        //this.GetBooking();
                        //UserService.GetServiceData().then(response => { this.setState({ data: response.data.data }) }, error => { })
                    } else {
                        Swal.fire({ position: 'top-end', toast: true, icon: 'error', title: response.data.message, showConfirmButton: false, timer: 3000 });
                    }


                }, error => { })
            }
        }
    }
    onSearchUserClick=(e) => {
        e.preventDefault();
        this.GetUser();
    }
    OnSearchCancelUserClick=() => {
        debugger;
        this.setState({ SearchUserTypeID: { 'label': '--Select User Type--', 'value': '--Select User Type--' },SearchUserMobileNo:"",SearchUserName:"" }, () => { this.GetUser() });
        //window.location.reload(true);

    }
    ExcelClick=() => {
        exportToExcel(this.state.cols, this.state.data)
    }
    render() {
        const { cookies }=this.props
        var Permission=cookies.cookies.Users;
        var TotalPermission="";
        if (Permission!==undefined) {
            TotalPermission=Permission.split(',');
            if (this.state.AddStatus=="") {
                this.setState({ AddStatus: TotalPermission[0] });
                this.setState({ UpdateStatus: TotalPermission[1] });
                this.setState({ DeleteStatus: TotalPermission[2] });
                this.setState({ ViewStatus: TotalPermission[3] });
                this.setState({ ExcelStatus: TotalPermission[5] });
            }
        }
        return (
            <div>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row">
                        {this.state.AddUserVisible&&
                            <div className="col-md-12">
                                {
                                    this.state.AddStatus==="AddStatus:true"&&
                                    <div className="card card-custom gutter-b example example-compact">
                                        <div className="card-header">
                                            <h3 className="card-title">Add User</h3>
                                        </div>
                                        <FormWithConstraints
                                            ref={form => this.form=form}
                                            onSubmit={this.handleSubmit}
                                            noValidate>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Employee Code<span className="text-danger">*</span></label>
                                                            <input type="text" name="EmployeeCode" required value={this.state.UserCode} onChange={(e) => this.setState({ UserCode: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Employee Code" />
                                                            <FieldFeedbacks for="EmployeeCode">
                                                                <FieldFeedback when="valueMissing">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>User Type <span className="text-danger">*</span></label>
                                                            <Select options={this.state.usertypes} value={this.state.UserTypeID} onChange={this.onUserTypeChange} isClearable={true} />
                                                            {this.state.UserTypeErrMsg&&<span className="text-danger">{this.state.UserTypeErrMsg==='required'? 'Please Select User Type':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>First Name <span className="text-danger">*</span></label>
                                                            <input type="text" name="FirstName" required value={this.state.FirstName} onChange={(e) => this.setState({ FirstName: e.target.value }, () => { this.form.validateFields(e.target) })} onKeyPress={this.allowOnlyCharacters} className="form-control" placeholder="Enter First Name" />
                                                            <FieldFeedbacks for="FirstName">
                                                                <FieldFeedback when="valueMissing">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Middle Name <span className="text-danger">*</span></label>
                                                            <input type="text" name="MiddleName" required value={this.state.MiddleName} onChange={(e) => this.setState({ MiddleName: e.target.value }, () => { this.form.validateFields(e.target) })} onKeyPress={this.allowOnlyCharacters} className="form-control" placeholder="Enter Middle Name" />
                                                            <FieldFeedbacks for="MiddleName">
                                                                <FieldFeedback when="valueMissing">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Last Name <span className="text-danger">*</span></label>
                                                            <input type="text" name="LastName" required value={this.state.LastName} onChange={(e) => this.setState({ LastName: e.target.value }, () => { this.form.validateFields(e.target) })} onKeyPress={this.allowOnlyCharacters} className="form-control" placeholder="Enter Last Name" />
                                                            <FieldFeedbacks for="LastName">
                                                                <FieldFeedback when="valueMissing">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Name in Gujrati <span className="text-danger">*</span></label>
                                                            <input type="text" name="NameInHindi" required value={this.state.NameInHindi} onChange={(e) => this.setState({ NameInHindi: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Name in Gujrati" />
                                                            <FieldFeedbacks for="NameInHindi">
                                                                <FieldFeedback when="valueMissing">

                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Guardian Name </label>
                                                            <input type="text" value={this.state.GuardianName} onChange={(e) => this.setState({ GuardianName: e.target.value })} onKeyPress={this.allowOnlyCharacters} className="form-control" placeholder="Enter Guardian Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Date Of Birth </label>
                                                            <DatePicker dateFormat="dd-MM-yyyy" selected={this.state.DateOfBirth} maxDate={new Date()} showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select" autoComplete="off" className="form-control readonly" id="txtTaskDate" value={this.state.DateOfBirth} onChange={(e) => {
                                                                    this.setState({ DateOfBirth: e });
                                                                }} />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Image </label>
                                                            <input type="file" value={this.state.UserImage} style={{ height: "37px" }} multiple onChange={this.OnUserImageUpload} className="form-control" accept="image/png, image/gif, image/jpeg" />
                                                            <label>{this.state.OldUserImageName}</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Permanent Country </label>
                                                            <Select options={this.state.permanentcountries} value={this.state.PermanentCountryID} onChange={this.onPermanentCountryChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Permanent State </label>
                                                            <Select isClearable={true} options={this.state.permanentstates} value={this.state.PermanentStateID} onChange={this.onPermanentStateChange} />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Permanent City </label>
                                                            <Select isClearable={true} options={this.state.permanentcities} value={this.state.PermanentCityID} onChange={this.onPermanentCityChange} />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Permanent Address </label>
                                                            <textarea rows={3} className="form-control" value={this.state.PermanentAddress} onChange={(e) => this.setState({ PermanentAddress: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Personal Email ID </label>
                                                            <input type="email" id="PersonalEmail" name="PersonalEmail" value={this.state.PersonalEmail} onChange={(e) => this.setState({ PersonalEmail: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Personal Email ID" />
                                                            <FieldFeedbacks for="PersonalEmail">
                                                                {/* <FieldFeedback when={value => value.length>0? !/\S+@\S+/.test(value):''}>Invalid email address.</FieldFeedback> */}
                                                                <FieldFeedback when={value => value.length>0? !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value):''}>Invalid email address.</FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Personal Mobile No <span className="text-danger">*</span></label>
                                                            <input maxLength={10} pattern=".{10,}" type="text" onKeyPress={this.allowOnlyNumbers} name="PersonalMobileNo" required className="form-control" value={this.state.PersonalMobileNo} onChange={(e) => this.setState({ PersonalMobileNo: e.target.value }, () => { this.form.validateFields(e.target) })} placeholder="Enter Personal Mobile No" />
                                                            <FieldFeedbacks for="PersonalMobileNo">
                                                                <FieldFeedback when="valueMissing">

                                                                </FieldFeedback>
                                                                <FieldFeedback when="patternMismatch">
                                                                    Should be of 10 digits
                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Local Country </label>
                                                            <Select options={this.state.localcountries} value={this.state.LocalCountryID} onChange={this.onLocalCountryChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Local State </label>
                                                            <Select isClearable={true} options={this.state.localstates} value={this.state.LocalStateID} onChange={this.onLocalStateChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Local City </label>
                                                            <Select isClearable={true} options={this.state.localcities} value={this.state.LocalCityID} onChange={this.onLocalCityChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Local Address </label>
                                                            <textarea rows={3} className="form-control" value={this.state.LocalAddress} onChange={(e) => this.setState({ LocalAddress: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Office Email ID </label>
                                                            <input type="email" id="OfficeEmail" name="OfficeEmail" value={this.state.OfficeEmail} onChange={(e) => this.setState({ OfficeEmail: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter Personal Email ID" />
                                                            <FieldFeedbacks for="OfficeEmail">
                                                                {/* <FieldFeedback when={value => value.length>0? !/\S+@\S+/.test(value):''}>Invalid email address.</FieldFeedback> */}
                                                                <FieldFeedback when={value => value.length>0? !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value):''}>Invalid email address.</FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Office Mobile No </label>
                                                            <input maxLength={10} pattern=".{10,}" type="text" onKeyPress={this.allowOnlyNumbers} name="OfficeMobileNo" className="form-control" value={this.state.OfficeMobileNo} onChange={(e) => this.setState({ OfficeMobileNo: e.target.value }, () => { this.form.validateFields(e.target) })} placeholder="Enter Office Mobile No" />
                                                            <FieldFeedbacks for="OfficeMobileNo">
                                                                <FieldFeedback when="patternMismatch">
                                                                    Should be of 10 digits
                                                                </FieldFeedback>
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    {this.state.IsVehicleType&&
                                                        <>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>Vehicle </label>
                                                                    <Select isClearable={true} options={this.state.vehicles} value={this.state.VehicleID} onChange={this.onVehicleChange} />
                                                                    {this.state.VehicleErrMsg&&<span className="text-danger">{this.state.VehicleErrMsg==='required'? 'Please Select Vehicle':''}</span>}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>License No </label>
                                                                    <input type="text" maxLength={16} name="LicenseNo" value={this.state.LicenseNo} onChange={(e) => this.setState({ LicenseNo: e.target.value }, () => { this.form.validateFields(e.target) })} className="form-control" placeholder="Enter License No" />
                                                                    <FieldFeedbacks for="LicenseNo">
                                                                        <FieldFeedback when={value => value.length>0? !/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/.test(value):''}>Invalid License No</FieldFeedback>
                                                                        <FieldFeedback when="patternMismatch">
                                                                            Should be of 15 Characters
                                                                        </FieldFeedback>
                                                                    </FieldFeedbacks>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>Issuing Authority / RTO </label>
                                                                    <input type="text" value={this.state.IssuingAuthority} onChange={(e) => this.setState({ IssuingAuthority: e.target.value })} className="form-control" placeholder="Enter Issuing Authority / RTO" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>Valid Upto </label>
                                                                    <input type="text" value={this.state.ValidUpto} onChange={(e) => this.setState({ ValidUpto: e.target.value })} className="form-control" placeholder="Enter Valid Upto" />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Note </label>
                                                            <input type="text" value={this.state.Note} onChange={(e) => this.setState({ Note: e.target.value })} className="form-control" placeholder="Enter Note" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Guranator </label>
                                                            <input type="text" value={this.state.Guarantor} onChange={(e) => this.setState({ Guarantor: e.target.value })} className="form-control" placeholder="Enter Guranator" />
                                                        </div>
                                                    </div>
                                                    {this.state.IsUserNamePasswordVisible&&
                                                        <>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>UserName </label>
                                                                    <input type="text" value={this.state.UserName} onChange={(e) => this.setState({ UserName: e.target.value }, () => { this.ValidateUserName() })} className="form-control" placeholder="Enter UserName" />
                                                                    {this.state.UserNameErrMsg&&<span className="text-danger">{this.state.UserNameErrMsg==='required'? 'Please Enter UserName':''}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>Password </label>
                                                                    <input type="password" value={this.state.Password} onChange={(e) => this.setState({ Password: e.target.value }, () => { this.ValidatePassword() })} className="form-control" placeholder="Enter Password" />
                                                                    {this.state.PasswordErrMsg&&<span className="text-danger">{this.state.PasswordErrMsg==='required'? 'Please Enter Password':''}</span>}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputPassword1">Zone </label>
                                                            <div className="checkbox-inline">
                                                                {
                                                                    this.state.ZoneDataAvailable&&
                                                                    <label className="checkbox usercheckbox" >
                                                                        <input checked={this.state.isZoneChecked} style={{ marginRight: 0 }} value="Select All" type="checkbox" onChange={this.onZoneAllChange} />
                                                                        <span />Select All
                                                                    </label>

                                                                }

                                                                {
                                                                    this.state.zones.map((item, index) => (

                                                                        <label className="checkbox usercheckbox" key={index}>

                                                                            <input checked={item.isZoneChecked} style={{ marginRight: 0 }} value={item.id} type="checkbox" onChange={this.onZoneChange} />
                                                                            <span />{item.value}

                                                                        </label>
                                                                    ))
                                                                }
                                                                {
                                                                    this.state.NoZoneVisibleMsg&&
                                                                    <label>No Zone Available</label>
                                                                }
                                                            </div>
                                                            {this.state.ZoneErrMsg&&<span className="text-danger">{this.state.ZoneErrMsg==='required'? 'Please select atleast 1 zone':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputPassword1">District </label>
                                                            <div className="checkbox-inline">
                                                                {
                                                                    this.state.DistrictDataAvailable&&
                                                                    <label className="checkbox usercheckbox" >
                                                                        <input checked={this.state.isDistrictChecked} style={{ marginRight: 0 }} value="Select All" type="checkbox" onChange={this.onDistrictAllChange} />
                                                                        <span />Select All
                                                                    </label>

                                                                }

                                                                {
                                                                    this.state.districts.map((item, index) => (

                                                                        <label className="checkbox usercheckbox" key={index}>

                                                                            <input checked={item.isDistrictChecked} style={{ marginRight: 0 }} value={item.id} type="checkbox" onChange={this.onDistrictChange} />
                                                                            <span />{item.value}

                                                                        </label>
                                                                    ))
                                                                }
                                                                {
                                                                    this.state.NoDistrictVisibleMsg&&
                                                                    <label>No District Available</label>
                                                                }
                                                            </div>
                                                            {this.state.DistrictErrMsg&&<span className="text-danger">{this.state.DistrictErrMsg==='required'? 'Please select atleast 1 district':''}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputPassword1"> Municipality</label>
                                                            <div className="checkbox-inline">
                                                                {
                                                                    this.state.MunicipalityDataAvailable&&
                                                                    <label className="checkbox usercheckbox" >
                                                                        <input checked={this.state.isMunicipalityChecked} style={{ marginRight: 0 }} value="Select All" type="checkbox" onChange={this.onMunicipalityAllChange} />
                                                                        <span />Select All
                                                                    </label>

                                                                }

                                                                {
                                                                    this.state.municipalities.map((item, index) => (

                                                                        <label className="checkbox usercheckbox" key={index}>

                                                                            <input checked={item.isMunicipalityChecked} style={{ marginRight: 0 }} value={item.id} type="checkbox" onChange={this.onMunicipalityChange} />
                                                                            <span />{item.value}

                                                                        </label>
                                                                    ))
                                                                }
                                                                {
                                                                    this.state.NoMunicipalityVisibleMsg&&
                                                                    <label>No Municipalities Available</label>
                                                                }
                                                            </div>
                                                            {this.state.MunicipalityErrMsg&&<span className="text-danger">{this.state.MunicipalityErrMsg==='required'? 'Please select atleast 1 municipality':''}</span>}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" onSubmit={this.handleSubmit} className="btn btn-primary mr-2">
                                                    Submit
                                                </button>
                                                <button type="button" onClick={this.cancelUser} className="btn btn-secondary">
                                                    Cancel
                                                </button>

                                                {/* <a href="/viewclient" className="btn btn-outline-primary font-weight-bolder">
                                            <span className="svg-icon svg-icon-md">
                                                <i className="fa fa-plus" />
                                            </span>Add Client</a> */}
                                            </div>
                                        </FormWithConstraints>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {this.state.isFilterVisible&&
                        this.state.ViewStatus==="ViewStatus:true"&&
                        <div className="row" style={{ marginBottom: '1%' }} id="divFilter">
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>UserType </label>
                                    <Select isClearable={true} options={this.state.usertypes} value={this.state.SearchUserTypeID} onChange={this.onSearchUserTypeChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Name</label>
                                    {/* <input type="text" name="VehicleNo" required value={this.state.SearchVehicleNo} onChange={(e) => this.setState({ SearchVehicleNo: e.target.value })} className="form-control" placeholder="Enter Vehicle No" /> */}
                                    <input type="text" value={this.state.SearchUserName} onKeyPress={this.allowOnlyCharacters} onChange={(e) => { this.setState({ SearchUserName: e.target.value }) }} className="form-control" name="SearchMobileNo" placeholder="Enter Name" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group formgroupcss">
                                    <label>Mobile No</label>
                                    {/* <input type="text" name="VehicleNo" required value={this.state.SearchVehicleNo} onChange={(e) => this.setState({ SearchVehicleNo: e.target.value })} className="form-control" placeholder="Enter Vehicle No" /> */}
                                    
                                    <input maxLength={10} pattern=".{10,}" type="text" onKeyPress={this.allowOnlyNumbers} name="SearchUserMobileNo" required className="form-control" value={this.state.SearchUserMobileNo} onChange={(e) => this.setState({ SearchUserMobileNo: e.target.value })} placeholder="Enter Mobile No" />
                                    
                                </div>
                            </div>
                            <div className="col-md-3">
                                <button type="button" onClick={this.onSearchUserClick} className="btn btn-primary mt-12 mr-3 mobmt-1">Search </button>
                                <button type="button" onClick={this.OnSearchCancelUserClick} className="btn btn-danger mt-12 mobmt-1">Cancel </button>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom">
                                <div className="card-body">
                                    <MaterialTable columns={this.state.cols} data={this.state.data}
                                        actions={[{
                                            hidden: this.state.UpdateStatus=="UpdateStatus:true"? false:true,
                                            icon: 'edit', tooltip: 'Edit', onClick: (e, r) => this.EditUser(r.UserID)
                                        },
                                        {
                                            hidden: this.state.DeleteStatus=="DeleteStatus:true"? false:true,
                                            icon: 'delete', tooltip: 'Delete', onClick: (e, r) => this.CheckAlert(r.UserID, r.PersonalMobileNo)
                                        }
                                        ]}
                                        options={{
                                            headerStyle: { color: 'black' }, toolbar: true, search: false, draggable: false,
                                            paging: true, pageSize: 5, emptyRowsWhenPaging: true, pageSizeOptions: [5, 10, 15, 20],
                                        }}
                                        detailPanel={[{
                                            render: ({ rowData }) => {
                                                if (rowData.UserZoneDetail!=null||rowData.UserDistrictDetail!=null||rowData.UserMuncipalityDetail!=null) {
                                                    return (
                                                        <div style={{ width: '100%', padding: '5px', paddingLeft: '35px', display: 'block' }}>
                                                            <table className="table" cellSpacing="0" rules="all" border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="permissionheader" style={{ borderTop: '1px solid' }}>Zone</th>
                                                                        <th className="permissionheader" style={{ borderTop: '1px solid' }}>District</th>
                                                                        <th className="permissionheader" style={{ borderTop: '1px solid' }}>Municipality</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: '33%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                            {rowData.UserZoneDetail.map((value, inx1) => {
                                                                                debugger;
                                                                                return (
                                                                                    <span className="permissionborderdata">{value.ZoneName}</span>
                                                                                )
                                                                            })}
                                                                        </td>
                                                                        <td style={{ width: '33%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                            {rowData.UserDistrictDetail.map((value, inx1) => {
                                                                                debugger;
                                                                                return (
                                                                                    <span className="permissionborderdata">{value.DistrictName}</span>
                                                                                )
                                                                            })}
                                                                        </td>
                                                                        <td style={{ width: '33%', paddingTop: '1%', paddingBottom: '1%' }}>
                                                                            {rowData.UserMuncipalityDetail.map((value, inx1) => {
                                                                                debugger;
                                                                                return (
                                                                                    <span className="permissionborderdata">{value.MuncipalityName}</span>
                                                                                )
                                                                            })}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    );
                                                }
                                            }
                                        }]}
                                        components={{

                                            Toolbar: props => (
                                                <div className="row" style={{ marginBottom: '2%' }}>
                                                    <div className="col-md-8 mobpb-5" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <h3 className="tabletitle">View User</h3>

                                                    </div>
                                                    <div className="col-md-4 mobta-left" style={{ textAlign: 'right' }}>
                                                        {
                                                            this.state.AddStatus==="AddStatus:true"&&
                                                            <a onClick={this.onAddUserClick} className="btn btn-outline-primary font-weight-bolder mr-5 mobmr-1">
                                                                <span className="svg-icon svg-icon-md">
                                                                    <i id="btnAdd" className={this.state.iconAdd} />
                                                                </span>Add User</a>
                                                        }
                                                        {
                                                            this.state.ViewStatus==="ViewStatus:true"&&
                                                            <>
                                                                <a className="btn btn-outline-dark font-weight-bolder mr-5 mobmr-1" onClick={this.OnFilterClick}>
                                                                    <i id="btnFilter" className={this.state.iconFilter} /> Filter
                                                                </a>
                                                                {this.state.ExcelStatus==="ExcelStatus:true"&&
                                                                    <a className="btn btn-outline-success font-weight-bolder" onClick={this.state.data.length>0? this.ExcelClick:''}>
                                                                        <i id="btnExcel" className="fa fa-file-excel" /> Excel
                                                                    </a>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withCookies(User);