import React, { Component } from "react";
import services from "../services/services";
import { NavLink, Redirect } from 'react-router-dom';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { ReactFlvPlayer } from 'react-flv-player'
import { Map, GoogleApiWrapper, Marker, Polyline } from 'google-maps-react';
const mapStyles = {
    width: '100%',
    height: '100%',
};
export class livegps extends Component {

    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            // data: [],
            parentLocation: [],
            currentLocation: [], carlicense: '', pntmap: "", DataBind: [], path: "",
            LiveLocation: "", BindLocation: {}, GpsLat: "", GpsLng: ""
        }
        // this.polyline = null;

    }

    GetMapData() {
        debugger;
        var data = JSON.stringify({
            VehicleNo: "GJ 01 KT 2884"
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "DeviceLocationInformation/getLiveDeviceLocationInformation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {
                this.setState({ GpsLat: parseFloat(response.data.data[0].GpsLat) });
                this.setState({ GpsLng: parseFloat(response.data.data[0].GpsLng) });
                this.setState({
                    currentLocation: response.data.data

                })


                // //this.setState({ pntmap: "a" });
                const checkdata = [];
                response.data.data.map((items, idx) => {
                    checkdata.push(
                        { lat: parseFloat(items.GpsLat), lng: parseFloat(items.GpsLng) }
                    )
                })
                // this.setState({ parentLocation: checkdata });
                // const path=JSON.stringify(checkdata);
                // let final=path.replace(/,/g, ',')
                // final=final.replaceAll("\"", "");
                // this.setState({ DataBind: checkdata })


                this.setState({ LiveLocation: checkdata[0] });

                setInterval(() => {
                    this.CheckMapData()
                }, 10000);
            }
            else {
                this.setState({ currentLocation: [] });
            }
            // const pntmap = JSON.stringify(checkdata[0]);
            // let finalbind = pntmap.replace(/,/g, ',')
            // finalbind = finalbind.replaceAll("\"", "", "");
            // this.setState({ BindLocation: checkdata[0] })

        }, error => { })

    }
    // getLatLngData() {

    // }
    componentDidMount() {
        debugger;
        this.GetMapData();
    }
    CheckMapData() {
        var data = JSON.stringify({
            VehicleNo: "GJ 01 KT 2884"
        });
        var config = {
            method: 'POST',
            url: services.API_URL + "DeviceLocationInformation/getLiveDeviceLocationInformation",
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        services.JsonValue(config).then(response => {
            debugger;
            if (response.data.status) {

                var NewLat = parseFloat(response.data.data[0].GpsLat);
                var NewLng = parseFloat(response.data.data[0].GpsLng);

                var OldLat = this.state.GpsLat;
                var OldLng = this.state.GpsLng;

                if (OldLat !== NewLat && OldLng !== NewLng) {
                    this.setState({ GpsLat: parseFloat(response.data.data[0].GpsLat) });
                    this.setState({ GpsLng: parseFloat(response.data.data[0].GpsLng) });
                    this.setState({
                        currentLocation: response.data.data

                    })


                    // //this.setState({ pntmap: "a" });
                    const checkdata = [];
                    response.data.data.map((items, idx) => {
                        checkdata.push(
                            { lat: parseFloat(items.GpsLat), lng: parseFloat(items.GpsLng) }
                        )
                    })
                    // this.setState({ parentLocation: checkdata });
                    // const path=JSON.stringify(checkdata);
                    // let final=path.replace(/,/g, ',')
                    // final=final.replaceAll("\"", "");
                    // this.setState({ DataBind: checkdata })


                    this.setState({ LiveLocation: checkdata[0] });

                    setInterval(() => {
                        this.CheckMapData()
                    }, 10000);
                }


                //  setInterval(() => {
                //     this.CheckMapData()
                //    }, 5000);
            }
            else {
                this.setState({ currentLocation: [] });
            }
            // const pntmap = JSON.stringify(checkdata[0]);
            // let finalbind = pntmap.replace(/,/g, ',')
            // finalbind = finalbind.replaceAll("\"", "", "");
            // this.setState({ BindLocation: checkdata[0] })

        }, error => { })
    }
    ShowCamera1 = () => e => {
        e.preventDefault();
        window.$(this.Camera1Modal).modal('show');
    }
    showMarkers = () => {
        if (this.state.currentLocation.length)
            return this.state.currentLocation.map((store, index) => {
                return <Marker key={index} id={index} position={{
                    lat: store.GpsLat,
                    lng: store.GpsLng
                }}
                    onClick={() => console.log("Clicked")} />
            })
    }
    // const latLng = new window.google.maps.LatLng(lat, lng);


    render() {
        // const { parentLocation } = this.state;
        // console.log(this.state)
        return (
            <>
                <div className="container-fluid mobfluid" style={{ marginTop: '12%' }}>
                    <div className="row" style={{ marginBottom: '1%' }}>
                        {(this.props.allCookies.Dashboard !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                <NavLink to={"/dashboard"} className="btn btn-outline-primary font-weight-bolds">Statistics
                                </NavLink>
                            </div>) : ''}
                        {(this.props.allCookies.GudmDashboard !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                <NavLink to={"/gudmdashboard"} className="btn btn-outline-primary font-weight-bolds">Gudm
                                </NavLink>
                            </div>) : ''}
                        {(this.props.allCookies.WorkInProgressDashboard !== undefined) ? (
                            <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                                <NavLink to={"/vehicledashboard"} className="btn btn-outline-primary font-weight-bolds">Work In Progress
                                </NavLink>
                            </div>) : ''}
                        <div className="col-md-3" style={{ maxWidth: 'fit-content' }}>
                            {/* <button type="button" className="btn btn-outline-primary font-weight-bolds" onClick={this.ShowCamera1()}>
                                Live Streaming
                            </button> */}
                            <a className="btn btn-outline-primary font-weight-bolds" target="_blank" href="http://mwhdia.in:3073/">
                                Live Streaming
                            </a>
                        </div>
                        <div className="col-md-2" style={{ maxWidth: 'fit-content' }}>
                            <NavLink to={"/livegps"} className="btn btn-outline-primary font-weight-bolds">Live GPS Tracking
                            </NavLink>
                        </div>
                    </div>
                </div>
                {
                    ((this.state.DataBind !== null) ? (
                        <Map
                            google={this.props.google}
                            zoom={18}
                            style={mapStyles}
                            center={
                                this.state.LiveLocation
                            }
                        // initialCenter={
                        //     this.state.LiveLocation
                        //     // lat: 21.184716, lng: 72.793225
                        //     //</>this.state.LiveLocation
                        //     //lat: 57.133449, lng: -3.669651
                        //     //lat: 21.184716, lng: 72.793225
                        //     //lat: 21.184716, lng: 72.793225
                        // }
                        >
                            {/* {this.state.LiveLocation} */}
                            {this.showMarkers()}
                            <Polyline
                                options={{
                                    strokeColor: "blue",
                                    strokeOpacity: 0.75,
                                    strokeWeight: 2,
                                    icons: [
                                        {
                                            icon: {
                                                path: "M -2,0 0,-2 2,0 0,2 z",
                                                strokeColor: "blue",
                                                fillColor: "red",
                                                fillOpacity: 1,
                                                // scale: 1.1,
                                                // zoom: 16
                                            },
                                            offset: "0",
                                            repeat: "40px"
                                        }
                                    ]
                                    // strokeColor: '#FF0000',
                                    // strokeOpacity: 0.8,
                                    // strokeWeight: 2,
                                    // fillColor: '#FF0000',
                                    // fillOpacity: 0.35,
                                    // clickable: false,
                                    // draggable: false,
                                    // editable: false,
                                    // visible: true,
                                    // radius: 30000,
                                }}
                                path={
                                    this.state.DataBind

                                    //{ lat: 21.184716, lng: 72.793225 }, { lat: 21.185631, lng: 72.794444 }
                                    //this.state.DataBind
                                    // {lat: 21.184716, lng: 72.793225 },
                                    // {lat: 21.184754, lng: 72.793467 },
                                    // {lat: 21.184755, lng: 72.793321 }
                                }
                            />


                        </Map>) : '')

                }
                <div className="modal fade" ref={modal => this.Camera1Modal = modal} id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <div className="modal-dialog mapdialog" role="document">
                        <div className="modal-content" style={{ height: '700px' }}>
                            <div className="modal-body" style={{ paddingLeft: '0', paddingTop: '0', paddingRight: '0' }}>
                                <div className="row" style={{ display: 'block', width: '100%', marginLeft: '0', marginRight: '0' }}>
                                    <ReactFlvPlayer
                                        url="http://14.99.68.5:12060/live.flv?devid=00BD001497&chl=2&svrid=127.0.0.1&svrport=17891&st=1&audio=1"
                                        height="100%"
                                        width="100%"
                                        isMuted={true}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default withCookies(GoogleApiWrapper({
    apiKey: 'AIzaSyA8Io_sD3d5uTz6ZTj4FKae_I7mdBVjX9o'
})(livegps));